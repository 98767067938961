import i18n from 'i18next';
import k from '../../../i18n/keys';
import { CONSUMER_URL } from '../../../config';
import HonkioAPI from "../../../middlewares/HonkioAPI";
import { NotificationManager } from "react-notifications";

function setInvite(role_id, email, userData) {

  return async function (dispatch) {
    try {
      let roles = await HonkioAPI().userShop.userFetch('getmerchantroles',
      { 'query_id': role_id, 'query_access': true });
      let hasAccess = false;
      if (roles.roles[0].access && roles.roles[0].access.length > 0) {
        hasAccess = true;
      }

      // console.log('roles');
      // console.log(roles);

      var message = {
        "command": "merchantuserinvite",
        "role_id": role_id,
        "email": email,
        "invite_url": `${window.location.origin.toString()}/invite/` };

      if (hasAccess) {
        message['invite_url'] = `${CONSUMER_URL}/register/`;
        message['redirect_url'] = window.location.origin.toString();
      }
      let response = await HonkioAPI().userShop.userFetch('merchantuserinvite',
      { ...message, ...userData },
      message => {
        setInviteError(message.description);
        return false;
      });
      return dispatch(setInviteSuccess(response));
    } catch (err) {
      return dispatch(setInviteError(err));
    }
  };

}

function setInviteSuccess(json) {
  if (json.user_role) {
    NotificationManager.success(i18n.t(k.USER_ADDED), i18n.t(k.USER_HAS_BEEN_ADDED));
  } else {
    NotificationManager.success(i18n.t(k.ASK_USER_CHECK_INBOX), i18n.t(k.USER_HAS_BEEN_INVITED));
  }
  return {
    type: "SET_INVITE_SUCCESS",
    response: json };

}

function setInviteError(err) {
  NotificationManager.warning(err || i18n.t(k.UNKNOWN_ERROR), i18n.t(k.ERROR_SENDING_INVITE), 5000);
  return {
    type: "SET_INVITE_FAILURE",
    err: err };

}

export { setInvite };

