const dotenv = require('dotenv');
dotenv.config();

// Oauth url in order to authenticate
export const OAUTH_TOKEN_URL = process.env.REACT_APP_OAUTH_TOKEN_URL || 'https://oauth.honkio.com/token';
export const OAUTH_REDIRECT_URL = process.env.REACT_APP_OAUTH_REDIRECT_URL || 'https://property.honkio.com/authorized'

export const OAUTH_CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID || '2dH8uILbFhMdDiH6TNp68QCGk4hDiUDE2IO2Ejla'; // local
export const OAUTH_CLIENT_SECRET = process.env.REACT_APP_OAUTH_CLIENT_SECRET || 'OYePjCaV1pgEk9quEjOjt8VTXyvu8O0FifGbUJpc'; // local
export const OAUTH_URL = process.env.REACT_APP_OAUTH_URL || `https://oauth.honkio.com/authorize?platform=web&response_type=code&client_id=2dH8uILbFhMdDiH6TNp68QCGk4hDiUDE2IO2Ejla&redirect_uri=https://property.honkio.com/authorized&scope=email`;
export const MERCHANT_REGISTER_URL = "https://admin.honkio.com/react/merchant/register/5ec6a05cf7ca396876d24d81"
export const REGISTRATION_URL = "https://admin.honkio.com/react/merchant/register/5ec6a05cf7ca396876d24d81"
// Internal API URL (Should be replaced in production)
export const INTERNAL_API_URL = 'https://property.honkio.com/api' // replace in prod

// Main API URL
export const GOOGLE_ANALYTICS_ID = ''
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'https://property.honkio.com/api';
export const CONSUMER_URL = process.env.REACT_APP_CONSUMER_URL || 'https://consumer.honkio.com/';

// Authentication redirect path
export const REDIRECT_URL = '/landing'

// Google API key for working with google api(maps, geocoding etc)
export const GOOGLE_API_KEY = 'AIzaSyBKnWeTDi3UMgiNnJyPnX9NbW9BlO-imMo'
export const FIREBASE_SENDER_ID = '34912689488'
export const FIREBASE_WEB_KEY = "BCIzft-W530CuPvLUj1hu2aU_WhR_7dx--ARB--snuOoNeEdXRo9VaMfREUW8_kchswzD7iPIGHgSyIdQPZOf0Y";
export const FIREBASE_SETTINGS = {
    apiKey: "AIzaSyCfyhb1X3bLheNhXVt_P3t2-pGKSKRWH_Y",
    authDomain: "plumbers-honkio-dev.firebaseapp.com",
    databaseURL: "https://plumbers-honkio-dev.firebaseio.com",
    projectId: "plumbers-honkio-dev",
    storageBucket: "plumbers-honkio-dev.appspot.com",
    messagingSenderId: FIREBASE_SENDER_ID,
    appId: "1:34912689488:web:e30b9cf7f8f75d3ba36055",
    measurementId: "G-QK0MNSH6KR"
}
export const TRANSLATIONS_SPREADSHEET_ID = "1buVEDW5bwFVFU83N1r8Y7l4tAucbKnOm-EHdhVgrNsI";

export const UPLOAD_URL = process.env.REACT_APP_CONSUMER_URL || 'https://media1.honkio.com/rental_prod/upload';
export const APP_PACKAGE = 'rent'
