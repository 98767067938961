import React from 'react';
import { useEffect, useState } from 'react';
import RoleForm from './RoleForm';
import useSearchString from '../../utils/useSearchString';
import getAdminRole from './../../actions/api/roles/getAdminRole';

const RoleEditor = () => {
    const { appid, role_id } = useSearchString();
    const [role, setRole] = useState();

    useEffect(() => {
        getAdminRole({ id: role_id })
            .then((answer) => {
                setRole(answer.role)
            });
    }, [role_id]);
    return <RoleForm role={role} appid={appid} language={'en'} />;
};

export default RoleEditor;
