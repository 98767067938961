import i18n from "i18next";
import k from "./../../i18n/keys";
import React from "react";
import PropTypes from "prop-types";
import { Navbar, NavbarBrand } from "shards-react";
import { Link } from "react-router-dom";
import Menu from "@material-ui/icons/Menu";
import { connect } from "react-redux";
import { toggleSidebar } from "../../actions/action_utils";

class SidebarMainNavbar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { hideLogoText } = this.props;
        return (
            <div className="main-navbar">
                <Navbar
                    className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
                    type="light"
                >
                    <NavbarBrand
                        className="w-100 mr-0"
                        href="/"
                        style={{ lineHeight: "25px" }}
                    >
                        <div className="d-table m-auto">
                            {!hideLogoText && (
                                <img src={require("./logo.png")} alt="Honkio" />
                            )}
                        </div>
                    </NavbarBrand>
                    <Link
                        to="#"
                        className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
                        // onClick={this.handleToggleSidebar}
                        onClick={this.props.toggleSidebar}
                    >
                        {/* <i className="material-icons">{i18n.t(k._30)}</i> */}
                        <Menu />
                    </Link>
                </Navbar>
            </div>
        );
    }
}

SidebarMainNavbar.propTypes = {
    /**
     * Whether to hide the logo text, or not.
     */
    hideLogoText: PropTypes.bool,
};

SidebarMainNavbar.defaultProps = {
    hideLogoText: false,
};
function mapStateToProps(state) {
    return { state };
}

const mapDispatchToProps = (dispatch) => ({
    toggleSidebar: () => dispatch(toggleSidebar()),
});

// export default SidebarMainNavbar;
export default connect(mapStateToProps, mapDispatchToProps)(SidebarMainNavbar);
