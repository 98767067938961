import i18n from 'i18next';
import k from "./../../../i18n/keys";
import React, {Component} from 'react';
import {Button, Form, FormGroup, FormSelect, Modal, ModalBody, ModalHeader} from "shards-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import {NotificationManager} from "react-notifications";
import Select from "react-select";

class DebtChooseShareholderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shareholderId: "",
      debt: {
        id: ""
      },

      shareholder_debt: {
        id: null
      }
    };


    this.show = this.show.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.debt !== undefined) {
      this.loadShareholders(nextProps.debt.building_id);
      this.loadBuilding(nextProps.debt.building_id);
      this.setState({'debt': nextProps.debt, "buildingId": nextProps.debt.building_id});
    }
  }


  loadShareholders(buildingId) {
    let that = this
    let merchantshareholderlist = async function () {
      let options = []
      let getmerchantroles = await HonkioAPI().userShop.userFetch('getmerchantroles', {
        'query_access': true,
        'query_sort': [{'access': -1}, {'name': 1}]
      })
      let roles = getmerchantroles.roles

      if (roles.length > 0) {
        let roles_ids = []
        roles.forEach(role => {
          if (['Shareholders'].includes(role.name)) {
            roles_ids.push(role.role_id)
            that.setState({"shareholder_role": role.role_id})
          }
        })
        let merchantlistuserroles = await HonkioAPI().userShop.userFetch('merchantlistuserroles',
          {query_roles: true, roles: roles_ids, query_count: 100})
        let shareholders = []
        merchantlistuserroles.consumers.forEach(c => {
          if (c.buildings.includes(buildingId)) {
            shareholders.push(c)
            // console.log(shareholders)
          }
        })
        // console.log("SHAREHOLDERS")
        // console.log(shareholders)
        shareholders.forEach(function (shareholder) {
          options.push({
            value: shareholder['user_id'],
            label: shareholder.settings.str_firstname + " " + shareholder.settings.str_lastname
          });
        });
      that.options = options
      that.setState({"shareholders_loaded": true})
      }
    }
    merchantshareholderlist();
  }


  loadBuilding(buildingId) {
    let that = this;
    let merchantshareholderlist = async function () {
      let response = await HonkioAPI().userShop.userFetch('merchantbuildinglist', {"id": buildingId});
      that.setState({"building": response.buildings[0], buildingId: response.buildings[0].id});

    };
    merchantshareholderlist();
  }

  handleChange(e) {
    this.setState({'shareholderId': e.value});
    localStorage.setItem('shareholderId', e.value);
    this.loadShareholderDebt();

  }

  loadShareholderDebt() {
    let that = this;
    let merchantshantsharelist = async function () {
      that.setState({"loading": true});

      let debt = null;

      let response = await HonkioAPI().userShop.userFetch('merchantshareholderdebtlist', {
        "building_id": that.state.building.id,
        "shareholder_id": localStorage.getItem('shareholderId'),
        "building_debt_id": that.state.debt.id
      });

      if (response.debts[0])
        debt = response.debts[0];

      that.setState({shareholder_debt: debt, loading: false});
    };
    merchantshantsharelist();
  }


  show() {
    if (this.state.shareholder_debt) {
      let debt = this.state.shareholder_debt;
      this.props.push("/shareholderPayment/" + debt.id);
    } else {
      NotificationManager.warning(i18n.t(k.CANT_FIND_PP), i18n.t(k.ERROR));
    }
  }

  render() {
    let select = <CircularProgress size={45}/>;
    let saveButton = <CircularProgress size={45}/>;
    if (!this.state.loading) {
      saveButton = <Button onClick={() => {
        this.show();
      }}>{i18n.t(k.CHOOSE)}</Button>;
    }

    if (this.state.shareholders_loaded) {
      select = <Select id="shareholder"
                       menuPlacement="bottom"
                       menuPosition='fixed'
                       onChange={e => {
                         this.handleChange(e);
                       }}
                       defaultValue={{value: i18n.t(k.NULL), label: i18n.t(k.PLEASE_SELECT_SHAREHOLDER)}}
                       options={this.options}>
      </Select>;
    }

    return (
      <Modal open={this.props.visible}
             toggle={this.props.toggle}
             onRequestClose={this.props.onRequestClose}
             className="w-100 modal-lg modal-dialog-centered modal-dialog-scrollable">
        <ModalHeader>{i18n.t(k.CHOOSE_SHAREHOLDER)}</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              {select}
            </FormGroup>
            {saveButton}
          </Form>
        </ModalBody>
      </Modal>);

  }
}


export default DebtChooseShareholderComponent;
