import React, { Component } from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";

class SecureImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'originalUrl': this.props.src
    };
    this.loadImage = this.loadImage.bind(this);
  }

  componentDidMount() {
    this.loadImage(this.props.src);
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  loadImage(url) {
    let that = this;
    async function loadImageAsync() {
      const response = await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
          // 'Content-Type'          : 'application/x-www-form-urlencoded',
          'Authorization': localStorage.getItem('token')
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });

      if (that.mounted) {
        response.blob().then(blob => {
          that.setState({ 'src': window.URL.createObjectURL(blob) });
        });
      }
    }
    loadImageAsync();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.state.originalUrl !== nextProps.src) {// to avoid re-draws
      this.loadImage(nextProps.src);
      this.setState({ 'originalUrl': nextProps.src });
    }
  }

  render() {
    let img = <CircularProgress size={45} />;
    if (this.state.src) {
      img = <img
        {...this.props}
        src={this.state.src}
        className={this.props.className}
        ref={this.props.imgRef}
        alt="floorplan"
        style={this.props.style} />;
    }
    return (
      <div>
        {img}
      </div>);
  }
}

export default SecureImage;