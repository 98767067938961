import HonkioAPI from '../../../middlewares/HonkioAPI';

const setMerchantUserInvite = ({ role_id, email, message, ...rest }) => {
  const params = {
    role_id: role_id,
    email: email,
    message: message,
    ...rest,
  };
  // console.log(JSON.stringify(params));
  return HonkioAPI().mainShop.userFetch('merchantuserinvite', params);
};
export default setMerchantUserInvite;
