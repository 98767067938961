const initialState = {
  err: null,
  isAuthenticated: false,
  isAuthenticating: false,
  token_data: {} };


function oauthReducer(state = initialState, action) {
  // console.log('OAUTH REDUCER');
  // console.log('ACTION');
  // console.log(action);

  switch (action.type) {
    case "USER_REDIRECTED":
      return {
        ...state,
        isAuthenticating: true,
        err: null,
        isAuthenticated: false,
        token_data: {} };

    case "IS_AUTHENTICATING":
      return {
        ...state,
        isAuthenticating: true,
        err: null,
        isAuthenticated: false,
        token_data: {} };

    case "USER_AUTH_SUCCESS":
      return {
        ...state,
        err: null,
        isAuthenticated: true,
        isAuthenticating: false,
        token_data: action.token };

    case "REFRESH_TOKEN_SUCCESS":
      return {
        ...state,
        err: null,
        isAuthenticated: true,
        isAuthenticating: false,
        token_data: action.token };

    case "USER_AUTH_FAILURE":
      return {
        ...state,
        err: action.err,
        isAuthenticated: false,
        isAuthenticating: false,
        token_data: action.err };

    case "LOGOUT":
      return {
        ...initialState };

    default:
      return state;}

}

export default oauthReducer;