import i18n from "i18next";
import k from "./../../../i18n/keys";
import React, { Component } from "react";
import {
    Button,
    Form,
    FormGroup,
    FormInput,
    Modal,
    ModalBody,
    ModalHeader,
} from "shards-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import Select from "react-select";
import DatePickerComponent from "../../ui_utils/DatePickerComponent";
import getValues from "./../product_type_utils";
import { DateTimePicker } from "react-widgets";
import { NotificationManager } from "react-notifications";
import { getShareHolderRole, roundNumber } from "../../../misc";
import data from "../product_types.json";

import moment from "moment";
import "moment/locale/fi";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

class DebtEditModalComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            debt: {
                total_amount: null,
                start_date: moment()
                    .startOf("day")
                    .format("YYYY-MM-DD HH:MM:SS"),
                end_date: moment()
                    .startOf("day")
                    .add(4, "M")
                    .add(1, "day")
                    .format("YYYY-MM-DD HH:MM:SS"),
            },

            amount_per_month: "",
        };

        this.save = this.save.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (
            nextProps.buildingId !== undefined &&
            nextProps.buildingId !== "merchant"
        ) {
            let that = this;

            this.setState({ buildingId: nextProps.buildingId });

            let merchantbuildinglistlist = async function () {
                let response = await HonkioAPI().userShop.userFetch(
                    "merchantbuildinglist",
                    { id: nextProps.buildingId }
                );
                if (response.buildings[0])
                    that.setState({
                        building: response.buildings[0],
                        total_shares: response.buildings[0].properties.shares,
                    });
            };
            merchantbuildinglistlist();

            let merchantshareholderlist = async function () {
                let sRole = await getShareHolderRole();
                let merchantlistuserroles =
                    await HonkioAPI().userShop.userFetch(
                        "merchantlistuserroles",
                        {
                            query_roles: true,
                            roles: [sRole.role_id],
                            query_building: [nextProps.buildingId],
                        }
                    );
                that.setState({
                    shareholders: merchantlistuserroles.consumers,
                });
            };
            merchantshareholderlist();
        }

        if (nextProps.debt !== undefined) {
            this.setState({ debt: nextProps.debt, type: nextProps.debt.type });
            let that = this;
            let vals = getValues();
            for (let i = 0; i < vals.length; i++) {
                if (vals[i].value === nextProps.debt.class) {
                    that.setState({
                        debt: { ...nextProps.debt, default: vals[i] },
                    });
                }
            }
        }
    }

    componentDidMount() {
        this.loadMerchant();
    }

    save() {
        let that = this;
        this.setState({ loading: true });
        let saveDebt = async function () {
            // create liability  (debt)
            let params = {
                name: that.state.debt.name,
                total_amount: that.state.debt.total_amount,
                months_to_pay_off: localStorage.getItem("diff"),
                amount_per_month: roundNumber(that.state.amount_per_month, 2),
                building_id: that.state.buildingId,
                class: that.state.debt.class,
                type: that.state.type,
                start_date: that.state.debt.start_date,
                end_date: that.state.debt.end_date,
                billing_start_date: moment(
                    that.state.debt.billing_start_date
                ).format("YYYY-MM-DD HH:MM:SS"),
            };

            if (that.state.debt.id) {
                params["id"] = that.state.debt.id;
                params["edit"] = true;
            }

            let response = await HonkioAPI().userShop.userFetch(
                "merchantbuildingdebtset",
                params
            );
            // console.log(response);
            // console.log("debt created, response is");
            // console.log(response);
            let debtId = response.debt.id;

            // create shareholder payment plan
            params = {
                name: that.state.debt.name,
                building_id: that.state.buildingId,
                total_amount: roundNumber(that.state.debt.total_amount, 2),
                amount_per_month: roundNumber(that.state.amount_per_month, 2),
                months_to_pay_off: localStorage.getItem("diff"),
                building_debt_id: debtId,
                type: that.state.type,
                invoice_payment_term: that.state.invoice_payment_term,
                shareholders: that.state.shareholders,
                billing_start_date: moment(
                    that.state.debt.billing_start_date
                ).format("YYYY-MM-DD HH:MM:SS"),
                start_date: moment(that.state.debt.start_date).format(
                    "YYYY-MM-DD HH:MM:SS"
                ),
                total_shares: that.state.total_shares,
                end_date: moment(that.state.debt.end_date).format(
                    "YYYY-MM-DD HH:MM:SS"
                ),
            };

            // console.log("PARAMS");
            // console.log(params);
            response = await HonkioAPI().userShop.userFetch(
                "merchantshareholderdebtset",
                params
            );
            // console.log(response);

            that.setState({ loading: false });
            if (response.status === "accept") {
                NotificationManager.success(
                    i18n.t(k.LOADING),
                    i18n.t(k.LIABILITY_ADDED)
                );
            } else {
                NotificationManager.success(
                    `${response.description}`,
                    i18n.t(k.CREATION_FAILED)
                );
            }
            that.props.onDebtSaved();
        };
        if (this.state.buildingId) {
            saveDebt();
        }
    }

    handleChange(e) {
        // use local storage for instant update values
        // this.setState in this func generate a lot of bugs and wrong amounts
        this.setState({
            debt: { ...this.state.debt, [e.target.id]: e.target.value },
        });
        localStorage.setItem(e.target.id, e.target.value);
        let amount = localStorage.getItem("total_amount");
        let start_date = localStorage.getItem("start_date");
        let end_date = localStorage.getItem("end_date");
        let number_of_month = localStorage.getItem("number_of_month");

        // calc diff in months and amount
        if (amount && start_date && end_date) {
            let diff = moment(end_date).diff(
                moment(start_date),
                "months",
                false
            );
            if (number_of_month) {
                diff = number_of_month;
            }
            localStorage.setItem("diff", diff);
            let amount_per_month = amount / diff;
            this.setState({ amount_per_month: amount_per_month });
        }
    }

    handleChangeDatePicker(date) {
        this.setState({
            debt: { ...this.state.debt, billing_start_date: date },
        });
    }

    getValues() {
        let options = [];
        let data = this.state.columns[0].items;
        // console.log("DATA");
        // console.log(data);
        Object.keys(data).forEach(function (key) {
            options.push({ value: key, label: `${data[key].en} (${key})` });
        });
        return options;
    }

    loadMerchant() {
        let that = this;
        let merchantGet = async function () {
            let response = await HonkioAPI().userShop.userFetch(
                "merchantget",
                {}
            );
            let newState = {
                merchant: response.merchant,
                loading: false,
                columns: response.merchant.list_product_categories_groups[0],
            };

            that.setState(newState);
            console.error("response");
            console.error(response);
        };
        merchantGet();
    }

    render() {
        // Set default start and end time to avoid wrong amount calc
        if (!localStorage.getItem("start_date" && !this.state.debt)) {
            localStorage.setItem(
                "start_date",
                moment().startOf("day").format("YYYY-MM-DD HH:MM:SS")
            );
            localStorage.setItem(
                "end_date",
                moment()
                    .startOf("day")
                    .add(4, "M")
                    .add(1, "day")
                    .format("YYYY-MM-DD HH:MM:SS")
            );
        }
        // set start and end time from our debt (uses if edit)
        if (this.state.debt) {
            localStorage.setItem("start_date", this.state.debt.start_date);
            localStorage.setItem("end_date", this.state.debt.end_date);
        }

        let saveButton = <Button onClick={this.save}>{i18n.t(k.SAVE)}</Button>;
        if (this.state.loading) {
            saveButton = <CircularProgress size={45} />;
        }
        // console.log("THIS STATE")
        // console.log(this.state)
        // get options for product class selector from merchant settings
        let options = [];
        if (this.state.columns) {
            let data = this.state.columns;
            let basic = this.state.columns[0].items;
            let clone = Object.assign({}, data, { 0: undefined });
            Object.keys(basic).forEach(function (key) {
                options.push({
                    value: `${basic[key].id}`,
                    label: `${basic[key].content}`,
                });
            });
            // console.log("AQSDAAD")
            // console.log(options)
            for (const [key, value] of Object.entries(clone)) {
                if (value !== undefined) {
                    options.push({
                        value: key,
                        label: `${value.name} (${key})`,
                    });
                }
            }
        } else {
            options = getValues();
        }
        // console.log("options")
        // console.log(options)

        let that = this;
        return (
            <Modal
                open={this.props.visible}
                toggle={this.props.toggle}
                onRequestClose={this.props.onRequestClose}
                className="w-100 modal-lg modal-dialog-centered modal-dialog-scrollable"
            >
                <ModalHeader>
                    {this.props.debt === null
                        ? i18n.t(k.CREATE)
                        : i18n.t(k.CREATE_LIABILITY)}
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <DatePickerComponent
                            endDateLabel="Due date"
                            startDateLabel="First liability day"
                            dates={false}
                            endSelector={true}
                            start_date={moment(
                                this.state.debt.start_date
                            ).toDate()}
                            end_date={moment(this.state.debt.end_date).toDate()}
                            handleChangeEvent={(e) => {
                                let that = this;
                                e.target["id"] = e.target.name;
                                switch (e.target.name) {
                                    case "start_date":
                                        this.handleChange(e);
                                        break;
                                    case "end_date":
                                        this.handleChange(e);
                                        break;
                                }
                            }}
                        />

                        <FormGroup>
                            <label htmlFor="billing_start_date">
                                {i18n.t(k.BILLING_START_DATE)}
                            </label>
                            <DateTimePicker
                                id="billing_start_date"
                                name="billing_start_date"
                                value={moment(
                                    this.state.debt.billing_start_date
                                ).toDate()}
                                step={1}
                                defaultValue={moment().toDate()}
                                onChange={(e) => {
                                    that.handleChangeDatePicker(
                                        moment(e).toDate()
                                    );
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="invoice_payment_term">
                                {i18n.t(k.INVOICE_PAYMENT_TERM)}
                            </label>
                            <Select
                                id="invoice_payment_term"
                                options={[
                                    { value: 7, label: i18n.t(k.WEEK2) },
                                    { value: 14, label: i18n.t(k.WEEKS) },
                                    { value: 21, label: i18n.t(k.WEEKS1) },
                                    { value: 28, label: i18n.t(k.WEEKS2) },
                                ]}
                                onChange={(e) => {
                                    this.setState({
                                        ...this.state,
                                        invoice_payment_term: e.value,
                                    });
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="name">{i18n.t(k.NAME)}</label>
                            <FormInput
                                id="name"
                                value={this.state.debt.name}
                                onChange={(e) => this.handleChange(e)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="name">
                                {i18n.t(k.SHAREHOLDER_PAYMENT_PLAN_TYPE)}
                            </label>
                            <Select
                                id="shareholderPaymentPlanType"
                                options={[
                                    {
                                        value: i18n.t(k.SHARES1),
                                        label: i18n.t(k.SHARES2),
                                    },
                                    {
                                        value: i18n.t(k.SURFACE_AREA),
                                        label: i18n.t(k.SURFACE_AREA1),
                                    },
                                ]}
                                onChange={(e) => {
                                    this.setState({
                                        ...this.state,
                                        type: e.value,
                                    });
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="type">{i18n.t(k.TYPE)}</label>
                            <Select
                                id="type"
                                options={options}
                                defaultValue={that.state.debt.default}
                                onChange={(e) => {
                                    // console.log(e);
                                    this.setState({
                                        debt: {
                                            ...this.state.debt,
                                            class: e.value,
                                        },
                                    });
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="total_amount">
                                {i18n.t(k.TOTAL_AMOUNT)}
                            </label>
                            <FormInput
                                id="total_amount"
                                value={this.state.debt.total_amount}
                                onChange={(e) => {
                                    this.handleChange(e);
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="number_of_month">
                                {i18n.t(k.NUMBER_OF_MONTHS_TO_PAY_OFF)}
                            </label>
                            <FormInput
                                id="number_of_month"
                                value={localStorage.getItem("number_of_month")}
                                placeholder={localStorage.getItem("diff")}
                                onChange={(e) => {
                                    this.handleChange(e);
                                }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <label htmlFor="amount_per_month">
                                {i18n.t(k.AMOUNT_PER_MONTH)}
                            </label>
                            <FormInput
                                id="amount_per_month"
                                value={this.state.amount_per_month}
                            />
                        </FormGroup>

                        {saveButton}
                    </Form>
                </ModalBody>
            </Modal>
        );
    }
}

export default DebtEditModalComponent;
