import HonkioAPI from "./../../../middlewares/HonkioAPI";

const getAssetType = (options = {}, errorHandler = {}) => {
    const { id, debug, query_name } = options;
    return HonkioAPI().mainShop.userFetch(
        "assettypeget",
        {
            debug,
            id,
            query_name,
        },
        errorHandler
    );
};
export default getAssetType;
