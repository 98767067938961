import i18n from 'i18next';import k from "./../../i18n/keys";import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { connect } from "react-redux";

import {
Form,
FormGroup,
FormInput,
FormTextarea,
Button,
FormSelect,
Container,
Row,
Col,
Modal,
ModalHeader, ModalBody, Popover, PopoverHeader, PopoverBody, Alert } from
"shards-react";
import { setInvite } from "../../actions/api/invite/setInviteActions";
import HonkioAPI from "../../middlewares/HonkioAPI";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";

const Circular = () =>
// we need to add some padding to circular progress to keep it from activating our scrollbar
<div style={{ padding: '24px' }}>
    <CircularProgress size={75} />
  </div>;


class AddPaymentModalComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      'email': {} };

  }


  render() {

    return (

      <Modal open={this.props.visible}
      toggle={this.props.toggle}
      onRequestClose={this.props.onRequestClose}
      className="w-100">
                <ModalHeader>{i18n.t(k.ADD_PAYMENT)}</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <FormGroup>
                                <label htmlFor="id">{i18n.t(k.AMOUNT)}</label>
                                <FormInput id="id" placeholder={i18n.t(k.INVOICE_NUMBER_OR)} type="text"
                onChange={e => this.handleChange(e)} />
                            </FormGroup>
                        <Button className="m-3"><AddIcon />{i18n.t(k.ADD)}</Button>
                        </FormGroup>
                    </Form>
                </ModalBody>

            </Modal>);


  }}


function mapStateToProps(state) {
  return { ...state };
}

const mapDispatchToProps = dispatch => ({
  setInvite: (role_id, email, userData) => dispatch(setInvite(role_id, email, userData)) });

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentModalComponent);
