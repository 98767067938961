import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { Component } from "react";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataTable from "react-data-table-component";
import DeleteForever from "@material-ui/icons/DeleteForever";
import AddIcon from "@material-ui/icons/Add";
import { Button, ButtonGroup, Breadcrumb, BreadcrumbItem } from "shards-react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import NotificationManager from "react-notifications/lib/NotificationManager";
import HonkioAPI from "../../middlewares/HonkioAPI";
import SecureImage from "../ui_utils/SecureImage";
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";
import ListIcon from "@material-ui/icons/List";
import BreadcrumbsComponent from "../breadcrumbs/BreadcrumbsComponent";
// import honkio_logo from '../../image/honkio_logo.png';
import { ASSET_TYPE } from "./../../actions/api/assets/config";
import { Link } from "react-router-dom";

const Circular = () => (
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

class FloorPlansListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            floorplans: [],
            parentFloorplan: null,
            modal: {
                visible: false,
                floorplan: null,
            },
        };
        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.toggle = this.toggle.bind(this);
        this.loadFloorPlans = this.loadFloorPlans.bind(this);
        this.loadParents = this.loadParents.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.isValidHttpUrl = this.isValidHttpUrl.bind(this);
        this.thumbnailUrl = this.thumbnailUrl.bind(this);
    }

    hideModal() {
        this.setState({ modal: { ...this.state.modal, visible: false } });
    }

    showModal(building) {
        this.setState({
            modal: { ...this.state.modal, visible: true, building: building },
        });
    }

    toggle() {
        this.setState({
            modal: { ...this.state.modal, visible: !this.state.modal.visible },
        });
    }

    componentDidMount() {
        this.setState({ building: this.props.match.params.building });
        this.loadFloorPlans();
        this.loadParents();
    }

    confirmDelete(floorplan) {
        let that = this;
        const options = {
            title: i18n.t(k.DESTRUCTIVE_ACTION),
            message: "",
            childrenElement: () => (
                <p>
                    {i18n.t(k.ARE_YOU_SURE_WANT_TO_DELETE1)}{" "}
                    <b>{floorplan.name}</b>
                    {i18n.t(k._4)}
                </p>
            ),
            buttons: [
                {
                    label: i18n.t(k.YES),
                    onClick: () => {
                        const del = async () => {
                            that.setState({ loading: true });
                            let response = await HonkioAPI().userShop.userFetch(
                                "merchantassetset",
                                {
                                    id: floorplan.id,
                                    delete: true,
                                }
                            );
                            if (response.status === "accept") {
                                NotificationManager.success(
                                    i18n.t(k.FLOORPLAN_HAS_BEEN_REMOVED)
                                );
                            } else {
                                NotificationManager.error(
                                    `${response.description}`,
                                    i18n.t(k.DELETE_FAILED)
                                );
                            }
                            that.loadFloorPlans();
                        };
                        del();
                    },
                },
                {
                    label: i18n.t(k.NO),
                    onClick: () => {},
                },
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        };
        confirmAlert(options);
    }

    loadFloorPlans() {
        let that = this;
        const buildingId = this.props.match.params.building;
        let merchantfloorplanlist = async function () {
            let params = {
                query_type: ASSET_TYPE.FLOORPLAN,
                query_merchant: localStorage.getItem("merchant_id"),
                query_count: 50,
            };
            if (buildingId) {
                params["query_parent"] = buildingId;
            }
            let response = await HonkioAPI().userShop.userFetch(
                "assetlist",
                params,
                (error) => {
                    that.setState({
                        loading: false,
                        floorplans: [],
                    });
                }
            );
            if (response?.status === "accept") {
                let floorplans = response.assets ?? [];
                that.setState({
                    floorplans: floorplans,
                    loading: false,
                });
            } else {
                that.setState({
                    loading: false,
                    floorplans: [],
                });
            }
        };
        merchantfloorplanlist();
    }

    loadParents() {
        let that = this;
        that.setState({
            loadingParents: true,
            parents: [],
        });
        const buildingId = this.props.match.params.building;
        let merchantAreaParentList = async function () {
            let params = {
                id: buildingId,
                query_parent_levels: 3,
            };
            let response = await HonkioAPI().userShop.userFetch(
                "assetget",
                params,
                (error) => {
                    that.setState({
                        loadingParents: false,
                        parents: [],
                    });
                }
            );
            if (
                response?.status === "accept" &&
                response?.parents &&
                response?.assets
            ) {
                let parents = response.parents;
                const parentFloorplan = response.asset;
                that.setState({
                    parentFloorplan: {
                        parentFloorplan,
                        parents,
                    },
                    loadingParents: false,
                });
            } else {
                that.setState({
                    loadingParents: false,
                    parents: [],
                });
            }
        };
        merchantAreaParentList();
    }

    isValidHttpUrl(string) {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    }

    thumbnailUrl(string) {
        if (this.isValidHttpUrl(string?.custom_fields?.thumbUrl))
            return string?.custom_fields?.thumbUrl;
        if (this.isValidHttpUrl(string?.url)) return string?.url;
        return "honkio_logo";
    }

    getColumns() {
        let that = this;
        return [
            {
                name: i18n.t(k.THUMBNAIL),
                selector: "",
                sortable: false,
                width: "30%",
                cell: function (d) {
                    return (
                        <img
                            src={that.thumbnailUrl(d.properties)}
                            style={{
                                width: 50, //its same to '20%' of device width
                                aspectRatio: 1, // <-- this
                                resizeMode: "contain", //optional
                            }}
                        />
                    );
                },
            },
            {
                name: i18n.t(k.NAME),
                selector: "name",
                sortable: true,
                style: `cursor: pointer;`,
                width: "30%",
            },
            {
                name: i18n.t(k.BUILDING),
                selector: "parent_name",
                sortable: true,
                style: `cursor: pointer;`,
                width: "30%",
            },
            {
                name: i18n.t(k.ACTION),
                selector: "action",
                width: "10%",
                cell: function (d) {
                    return (
                        <span>
                            <Link
                                to="#"
                                className="pointer"
                                title={i18n.t(k.FLOORPLAN_DELETE)}
                                onClick={() => {
                                    that.confirmDelete(d);
                                }}
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ];
    }

    getParents = () => {
        let that = this;
        let parentsList = [];
        if (that.state.parentFloorplan) {
            const landplotElem = that.state.parentFloorplan.parents
                .filter((asset) => asset.type === ASSET_TYPE.LANDPLOT)
                .map((asset) => ({
                    name: asset.name,
                    linkTo: "/buildings/" + asset.id,
                }));
            const buildingElem = that.state.parentFloorplan.parentFloorplan
                .filter((asset) => asset.type === ASSET_TYPE.BUILDING)
                .map((asset) => ({
                    name: asset.name,
                    linkTo: null,
                }));
            parentsList = [
                {
                    name: i18n.t(k.PROPERTIES),
                    linkTo: "/landplots",
                },
            ];
            if (landplotElem.length > 0) {
                parentsList = [...parentsList, landplotElem[0]];
            }
            if (buildingElem.length > 0) {
                parentsList = [...parentsList, buildingElem[0]];
            }
        }
        return parentsList;
    };

    render() {
        let that = this;
        let getBreadcrumbs = () => {
            let that = this;
            if (that.state.parents) {
                return;
                // return (
                //   <BreadcrumbsComponent
                //     parents={this.getParents()}
                //   />
                // )
            } else {
                return;
            }
        };

        return (
            <div>
                <div className="container-fluid">
                    <PageHeaderComponent title={i18n.t(k.FLOORPLANS_L)} />
                </div>
                <CardWrapperComponent
                    header={true}
                    footer={true}
                    card_title={getBreadcrumbs()}
                >
                    <ButtonGroup>
                        <Button
                            theme="primary"
                            onClick={() =>
                                that.props.history.push(
                                    `/floorplan/new/${this.state.building}`
                                )
                            }
                        >
                            <AddIcon className="mr-2" />
                            {i18n.t(k.IMPORT_FLOORPLAN1)}
                        </Button>
                        <Button
                            theme="info"
                            onClick={() =>
                                that.props.history.push(
                                    `/buildingAreas/${this.state.building}`
                                )
                            }
                        >
                            <ListIcon className="mr-2" />
                            {i18n.t(k.LIST_ALL_AREAS)}
                        </Button>
                    </ButtonGroup>
                    {/*<div className="">*/}
                    <DataTable
                        autoWidth={true}
                        columns={this.getColumns()}
                        background="#ffffff00"
                        data={this.state.floorplans}
                        noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                        paginationComponentOptions={{
                            rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                            rangeSeparatorText: i18n.t(k.ROWS_OF),
                            noRowsPerPage: false,
                            selectAllRowsItem: false,
                            selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                        }}
                        progressPending={this.state.loading}
                        progressComponent={<Circular />}
                        onRowClicked={(e) =>
                            that.props.history.push(`/rooms/floorplan/${e._id}`)
                        }
                    />
                </CardWrapperComponent>
                {/*<BuildingEditModalComponent
          visible={this.state.modal.visible}
          open={this.hideModal}
          toggle={this.toggle}
          onRequestClose={this.props.onRequestClose}
          building={this.state.modal.floorplan}
          onBuildingSaved={() => {
          that.hideModal()
          that.loadBuildings()
          }}/>*/}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FloorPlansListComponent);
