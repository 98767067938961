import data from "./product_types.json";

function getValues() {
  let options = [];
  Object.keys(data).forEach(function (key) {
    options.push({ value: key, label: `${data[key].en} (${key})` });
  });
  return options;
}


export default getValues;