import i18n from 'i18next'; import k from "./../../i18n/keys"; import React, { Component } from 'react';
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import NotificationManager from "react-notifications/lib/NotificationManager";
import HonkioAPI from "../../middlewares/HonkioAPI";
import StepWizard from "react-step-wizard";
import AgreementBasicInfoComponent from "./subcomponents/AgreementBasicInfoComponent";
import AgreementDatesComponent from "./subcomponents/AgreementDatesComponent";
import WizardNavComponent from "./subcomponents/WizardNavComponent";
import AgreementAmountComponent from "./subcomponents/AgreementAmountComponent";
import AgreementRentAreaComponent from "./subcomponents/AgreementRentAreaComponent";
import AgreementPreviewComponent from "./subcomponents/AgreementPreviewComponent";
import AgreementTemplateComponent from "./subcomponents/AgreementTemplateComponent";
import CenteredPageLoader from "../ui_utils/CenteredPageLoader";
import AgreementTenantComponent from "./subcomponents/AgreementTenantComponent";
import { AGREEMENT_FIELDS } from '../../../src/components/agreements/subcomponents/config';
import moment from "moment";
import "moment/locale/fi";

const emptyAgreement = {
  rules: {
    start_date: {
      label: AGREEMENT_FIELDS.START_DATE,
      value: moment().startOf('day').format('YYYY-MM-DD hh:mm:ss')
    },
    end_date: {
      label: AGREEMENT_FIELDS.END_DATE,
      value: moment().endOf('month').format('YYYY-MM-DD hh:mm:ss')
    },
    resign_date: {
      label: AGREEMENT_FIELDS.RESIGN_DATE,
      value: moment().format('YYYY-MM-DD hh:mm:ss')
    },
    billing_start_date: {
      label: AGREEMENT_FIELDS.BILLING_START_DATE,
      value: moment().format('YYYY-MM-DD hh:mm:ss')
    },
  }
}

class AgreementsEditComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      agreement: emptyAgreement,
      validation: {}
    };
    this.loadAgreement = this.loadAgreement.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onChangeTenant = this.onChangeTenant.bind(this);
    this.onSelectTemplate = this.onSelectTemplate.bind(this);
    this.onChangeFromPicker = this.onChangeFromPicker.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
  }

  onSelectTemplate(template) {
    let newState = { 'rules': {} };
    if (template.name) {
      newState['title'] = template.name;
    }
    if (template.description) {
      newState['description'] = template.description;
    }
    if (template.rules) {
      Object.keys(template.rules).forEach(k => {
        newState['rules'][k] = { 'value': template.rules[k].value };
      });
    }
    this.setState({ 'agreement': newState });
  }

  handleValidation(id, valid) {
    this.setState({
      'validation': { [id]: valid }
    });
  }

  onChange(e, label) {
    if (label) {
      let newState = { 'agreement': { ...this.state.agreement } };
      if (!newState.agreement.rules) {
        newState['agreement']['rules'] = {};
      }
      newState['agreement']['rules'][e.target.id] = {
        'value': e.target.value,
        'label': label
      };
      this.setState(newState);
    } else {
      this.setState({
        agreement: {
          ...this.state.agreement,
          [e.target.id]: e.target.value
        }
      });
    }
  }

  onChangeFromPicker(label, total_area_value, reservable, areaId, amount) {
    let newState = { 'agreement': { ...this.state.agreement } };
    if (!newState.agreement.rules) {
      newState['agreement']['rules'] = {};
    }
    newState['agreement']['rules'][label] = {
      'value': total_area_value,
      'label': label
    };
    newState['agreement']['reservable'] = reservable;
    newState['agreement']['area'] = areaId;
    newState['agreement']['amount'] = amount;
    this.setState(newState);
  }

  onChangeTenant(mainTenant, roommates) {
    let newState = { 'agreement': { ...this.state.agreement } };
    if (!newState.agreement.rules) {
      newState['agreement']['rules'] = {};
    }
    newState['agreement']['rules'][AGREEMENT_FIELDS.TENANT_NAME] = {
      'value': mainTenant.name,
      'label': AGREEMENT_FIELDS.TENANT_NAME
    };
    newState['agreement']['rules'][AGREEMENT_FIELDS.TENANT_EMAIL] = {
      'value': mainTenant.email,
      'label': AGREEMENT_FIELDS.TENANT_EMAIL
    };
    newState['agreement']['tenant'] = mainTenant.id;
    newState["agreement"]['roommates'] = roommates;
    this.setState(newState);
  }

  componentDidMount() {
    this.loadAgreement();
  }

  confirmDelete(agreement) {
    let that = this;
    const options = {
      title: 'Destructive action',
      message: '',
      childrenElement: () =>
        <p>
          {i18n.t(k.ARE_YOU_SURE_WANT_TO_DELETE)}
          <b>{agreement.name}</b>{i18n.t(k._4)}
        </p>,
      buttons: [
        {
          label: i18n.t(k.YES),
          onClick: () => {
            const del = async () => {
              that.setState({ 'loading': true });
              let response = await HonkioAPI().userShop.userFetch('merchantagreementset', {
                'id': agreement.id,
                'delete': true
              });
              if (response.status === 'accept') {
                NotificationManager.success(i18n.t(k.LOADING), i18n.t(k.AGREEMENT_HAS_BEEN_REMOVED));
              } else {
                NotificationManager.error(`${response?.description}`, i18n.t(k.DELETE_FAILED));
              }
              that.loadAgreements();
            };
            del();
          }
        },
        {
          label: i18n.t(k.NO), onClick: () => {
          }
        }],
      closeOnEscape: true,
      closeOnClickOutside: true
    };
    confirmAlert(options);
  }

  loadAgreement() {
    let that = this;
    let agreementId = this.props.match.params.agreementID;
    let buildingId = this.props.match.params.buildingId;
    let loadAgreement = async function () {
      let response = await HonkioAPI().userShop.userFetch('merchantagreementlist', {
        'query_id': agreementId
      }, error => {
        that.setState({
          loading: false
        });
      });
      if (response?.status === 'accept' && response.rents && response.rents.length > 0) {
        that.setState({
          agreement: response.rents[0],
          loading: false
        });
      } else {
        that.setState({ 'loading': false });
      }
    };
    if (agreementId && agreementId !== 'new') {
      loadAgreement();
    } else {
      that.setState({
        agreement: emptyAgreement,
        loading: false
      });
      if (buildingId) {
        that.setState({
          agreement: {
            ...this.state.agreement,
            rules: {
              building: {
                value: buildingId,
                label: AGREEMENT_FIELDS.BUILDING
              }
            }
          }
        })
      }
    }
  }

  onSaveClick() {
    let that = this;
    that.setState({ 'loading': true });
    let saveAgreement = async function () {
      let params = {
        ...that.state.agreement
      };
      params['id'] = params._id;
      let response = await HonkioAPI().userShop.userFetch('merchantagreementset', params);
      NotificationManager.success(i18n.t(k.AGREEMENT_SAVED));
      that.setState({ "agreement_new": response });
      if (response && that.state.agreement_new) {
        that.props.history.push({
          pathname: `/summary/` + that.state.agreement_new.rent._id
        });
      }
    };
    saveAgreement();
  }

  render() {
    let that = this;
    let templateComponent;
    if (this.state.loading) {
      return <CenteredPageLoader />;
    }
    if (!this.state.agreement._id) {
      templateComponent = <AgreementTemplateComponent
        agreement={this.state.agreement}
        handleChangeEvent={this.onChange}
        onSelectTemplate={this.onSelectTemplate} />;
    }
    return (
      <div>
        <div className="container-fluid">
          {/*          <PageHeaderComponent*/}
          {/*title={'i18n.t(k.AGREEMENT_CREATE)'}*/}
          {/*subtitle={i18n.t(k.AGREEMENT_CREATE)} */}
          {/*          />*/}
        </div>
        <CardWrapperComponent
          header={true}
          footer={true}
          card_title={i18n.t(k.AGREEMENT_CREATE)}>
          <StepWizard className="wizard" nav={<WizardNavComponent
            validation={this.state.validation}
            onSaveClick={this.onSaveClick}
            agreement={this.state.agreement} />}>
            {templateComponent}
            <AgreementBasicInfoComponent
              agreement={this.state.agreement}
              handleChangeEvent={this.onChange}
              handleValidation={this.handleValidation} />
            <AgreementRentAreaComponent
              agreement={this.state.agreement}
              handleChangeEvent={this.onChange}
              history={this.props.history}
              next={this.props.nextStep}
              handleChangeFromPicker={this.onChangeFromPicker} />
            <AgreementDatesComponent
              agreement={this.state.agreement}
              handleChangeEvent={this.onChange} />
            <AgreementTenantComponent
              agreement={this.state.agreement}
              handleTenantsChange={this.onChangeTenant} />
            <AgreementAmountComponent
              agreement={this.state.agreement}
              handleChangeEvent={this.onChange} />
            <AgreementPreviewComponent
              agreement={this.state.agreement}
              handleChangeEvent={this.onChange} />
          </StepWizard>
        </CardWrapperComponent>
      </div>);
  }
}

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AgreementsEditComponent);