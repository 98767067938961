import i18n from 'i18next';import k from "./../../../i18n/keys";import React, { Component } from 'react';
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Button, Form, FormGroup } from "shards-react";
import { CONSUMER_URL } from "../../../config";
import CheckIcon from "@material-ui/icons/Check";
import FormInputValidation from "../../ui_utils/FormInputValidationComponent";

const Circular = () =>
// we need to add some padding to circular progress to keep it from activating our scrollbar
<div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>;


class WidgetBasicInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      name: '' };


  }



  componentDidMount() {
    if (this.props.asset) {
      this.setState({'name': this.props.asset.name})
    }
  }
  componentWillReceiveProps(nextProps, nextContext) {
      if (nextProps.asset) {
      this.setState({'name': nextProps.asset.name})
    }
  }

  getColumns() {
    let state = this.state;
    let that = this;
    return [
    {
      name: i18n.t(k._5),
      selector: 'id',
      cell: function (d) {
        let avatar_url = `${CONSUMER_URL}/user_photo/${d.id}.jpg`;
        return <img height="64px" width="64px" src={avatar_url} />;
      },
      maxWidth: '64px',
      sortable: false },

    {
      name: i18n.t(k.NAME),
      cell: function (d) {
        return d.settings.str_firstname + ' ' + d.settings.str_lastname;
      },
      sortable: true },

    {
      name: i18n.t(k.ACTION),
      selector: 'action',
      cell: function (d) {
        // console.log('d');
        // console.log(d);

        return <span className="pointer"><CheckIcon onClick={d => {
            that.props.onAssetSelected(d.id);
          }} /></span>;
      } }];


  }

  render() {

    return (
      <Form className="m-3">
                <FormGroup>
                    <label htmlFor="name">{i18n.t(k.NAME)}</label>
                    <FormInputValidation id="name" validationType="notNull"
          value={this.state.name}
          placeholder={i18n.t(k.NAME)}
          onChange={e => this.setState({ name: e.target.value })}
          handleValidation={this.props.handleValidation} />
                </FormGroup>
                {/*<h4>Size</h4>*/}
                    <Button theme="primary" className="float-right" onClick={() => this.props.addWidget(this.state.name)}>{i18n.t(k.ADD)}</Button>
            </Form>);

  }}


function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = dispatch => ({});


export default connect(mapStateToProps, mapDispatchToProps)(WidgetBasicInfo);
