import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { Component } from "react";
// import {getInvite} from "../../actions/inviteActions";
import { connect } from "react-redux";

import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    ButtonGroup,
    Form,
    FormGroup,
    FormSelect,
    InputGroupAddon,
} from "shards-react";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import "react-notifications/dist/react-notifications.css";
import "react-notifications/dist/react-notifications.js";
import DataTable from "react-data-table-component";
import { CONSUMER_URL } from "../../config";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteForever from "@material-ui/icons/DeleteForever";
import HonkioAPI from "../../middlewares/HonkioAPI";
import { confirmAlert } from "react-confirm-alert";
import InputGroup from "react-bootstrap/InputGroup";
import InviteUserModal from "../invite/InviteUserComponent";
import CenteredPageLoader from "../ui_utils/CenteredPageLoader";
import PageableDatatablesComponent from "../ui_utils/PageableDatatablesComponent";
import ShareholderListComponent from "../buildings/ShareholderListComponent";
import TenantsListComponent from "../tenants/TenantsListComponent";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import SimpleUsersListComponent from "./SimpleUsersListComponent";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";

const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

class BuildingUsersListComponent extends Component {
    constructor(props) {
        super(props);
        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.onRequestClose = this.onRequestClose.bind(this);
        this.getMerchantConsumerList = this.getMerchantConsumerList.bind(this);
        this.state = {
            loading: true,
            loadingConsumers: true,
            modal: {
                visible: false,
            },
        };
    }

    hideModal() {
        this.setState({ modal: { ...this.state.modal, visible: false } });
        this.onRequestClose();
    }

    showModal() {
        this.setState({ modal: { ...this.state.modal, visible: true } });
    }

    componentDidMount() {
        let that = this;
        let load = async function () {
            let building = await HonkioAPI().userShop.userFetch(
                "merchantbuildinglist",
                {
                    id: that.props.match.params.buildingId,
                }
            );

            let getmerchantroles = await HonkioAPI().userShop.userFetch(
                "getmerchantroles",
                {
                    query_access: "all",
                    query_sort: [{ access: -1 }, { name: 1 }],
                }
            );
            let roles = getmerchantroles.roles;
            // let consumers = [];
            // let total = 0;
            // if (roles.length > 0) {
            //   let merchantlistuserroles = await HonkioAPI().userShop.userFetch('merchantlistuserroles',
            //   { query_roles: true, roles: [roles[0].role_id] });
            //   consumers = merchantlistuserroles.consumers;
            //   total = merchantlistuserroles.total;
            // }
            that.setState({
                roles: roles,
                building: building.buildings[0] || {},
                loading: false,
                role: roles[0],
            });
        };
        load();
    }

    getMerchantConsumerList(role_id, page) {
        let that = this;
        if (!page) page = 0;
        that.setState({ loadingConsumers: true, role: role_id });
        let load = async function () {
            let merchantlistuserroles = await HonkioAPI().userShop.userFetch(
                "merchantlistuserroles",
                {
                    query_roles: true,
                    roles: [role_id],
                    query_skip: parseInt(page * 20),
                    query_count: 20,
                }
            );
            let consumers = merchantlistuserroles.consumers;
            that.setState({
                consumers: consumers,
                total: merchantlistuserroles.total,
                loadingConsumers: false,
            });
        };
        load();
    }

    onRequestClose() {
        try {
            this.props.getMerchantConsumerList(this.state.role);
        } catch (e) {
            console.error(e);
        }
    }

    getRolesSelectOptions() {
        var roleOptions = [];
        if (this.state.roles && typeof this.state.roles !== "undefined") {
            this.state.roles.forEach(function (role) {
                // console.log(<option key={role.id} value={role.id}>{role.display_name}</option>);
                roleOptions.push(
                    <option key={role._id} value={role._id}>
                        {role.display_name}
                    </option>
                );
            });
            // this.props.getMerchantConsumerList(this.props.access_token || localStorage.getItem('token'), this.state.merchantRoles[0]._id)
        }
        return roleOptions;
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
        this.getMerchantConsumerList(e.target.value);
    };

    mapComponent = (role) => {
        switch (role.name.toLowerCase()) {
            case "tenant":
                return (
                    <TenantsListComponent
                        buildingId={this.props.match.params.buildingId}
                    />
                );
            case "shareholders":
                return (
                    <ShareholderListComponent
                        buildingId={this.props.match.params.buildingId}
                    />
                );
            default:
                return (
                    <SimpleUsersListComponent
                        buildingId={this.props.match.params.buildingId}
                        role={this.state.role}
                    />
                );
        }
    };

    render() {
        if (this.state.loading) {
            return <CenteredPageLoader />;
        }
        let that = this;
        // console.log(this.state)
        return (
            <div
                id="ourteam_button_container"
                className="container-fluid col-md-12"
            >
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to="#">
                            {this.state.building.name || (
                                <CircularProgress size={24} />
                            )}
                        </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                        {i18n.t(k.BUILDING_USERS)}
                    </BreadcrumbItem>
                </Breadcrumb>

                <div className="container-fluid">
                    <ButtonGroup className="display-4">
                        {this.state.roles.map((role) => (
                            <Button
                                onClick={() => this.setState({ role: role })}
                                theme={
                                    this.state.role._id === role._id
                                        ? "secondary"
                                        : "light"
                                }
                                disabled={this.state.role._id === role._id}
                            >
                                {role.display_name}
                            </Button>
                        ))}
                        <Button
                            theme="info"
                            className="ml-2"
                            onClick={() => {
                                window.location =
                                    "/all_shareholders/" +
                                    that.props.match.params.buildingId;
                            }}
                        >
                            <AddIcon className="mr-2" />
                            {i18n.t(k.SCHEDULE_A_MEETING_OF_SHAREHOL)}
                        </Button>
                    </ButtonGroup>
                </div>
                <CardWrapperComponent
                    header={true}
                    footer={true}
                    card_title={
                        this.state.role ? this.state.role.display_name : ""
                    }
                >
                    {this.mapComponent(this.state.role)}
                </CardWrapperComponent>
            </div>
        );
    }
}

function mapStateToProps(state) {
    // return {...state.consumersList, roles: state.merchantRoles.roles || []}
    return state;
}

const mapDispatchToProps = (dispatch) => ({
    // refetchMerchantRoles: (query) => dispatch(refetchMerchantRoles(query)),
    // getMerchantConsumerList: (role_id) => dispatch(getMerchantConsumerList(role_id)),
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BuildingUsersListComponent);
