import i18n from 'i18next';
import k from "./../../../i18n/keys";
import React, { Component } from 'react';
import { Form, FormCheckbox, FormGroup, FormInput, FormSelect } from "shards-react";
import moment from "moment";
import "moment/locale/fi";
import { AGREEMENT_FIELDS } from '../../../../src/components/agreements/subcomponents/config';

class AgreementAmountComponent extends Component {
  constructor(props) {
    super(props);
  }
  // componentWillReceiveProps(nextProps, nextContext){
  //     if (nextProps.tenant !== undefined) {
  //         this.setState({'tenant': nextProps.tenant !== null ? nextProps.tenant : {}})
  //     }
  // }
  handleChange(e) {
    this.setState({ 'tenant': { ...this.state.tenant, [e.target.id]: e.target.value } });
  }

  render() {
    let that = this;
    let getReservedAreaHeader = () => {
      if (that.props.smallerHeader) {
        return (
          <h5>{i18n.t(k.RESERVED_AREA_AMOUNT)}</h5>
        );
      }
      else {
        return (
          <h4>{i18n.t(k.RESERVED_AREA_AMOUNT)}</h4>
        )
      }
    }
    let getPriceHeader = () => {
      if (that.props.smallerHeader) {
        return (
          <h5>{i18n.t(k.RENT_PRICE)}</h5>
        );
      }
      else {
        return (
          <h4>{i18n.t(k.RENT_PRICE)}</h4>
        )
      }
    }
    if (this.props.agreement.reservable === true) {
      return (
        <Form>
          {getReservedAreaHeader()}
          <hr />
          <FormGroup>
            <label htmlFor="rent_amount">{i18n.t(k.AMOUNT)}</label>
            <FormInput id="rent_amount"
              value={this.props.agreement ?
                this.props.agreement.amount : ''}
              onChange={e => this.props.handleChangeEvent(e, AGREEMENT_FIELDS.AMOUNT)} />
          </FormGroup>
          <FormGroup>
            <label htmlFor="text">{i18n.t(k.CURRENCY)}</label>
            <FormSelect>
              <option value="EUR">{i18n.t(k.EUR)}</option>
              <option value="SEK">{i18n.t(k.SEK)}</option>
            </FormSelect>
          </FormGroup>
        </Form>);
    }
    return (
      <Form>
        {getPriceHeader()}
        <hr />
        <FormGroup>
          <label htmlFor="rent_amount">{i18n.t(k.AMOUNT_PER_MONTH)}</label>
          <FormInput id="rent_amount"
            value={this.props.agreement.rules && this.props.agreement.rules.rent_amount ?
              this.props.agreement.rules.rent_amount.value : ''}
            onChange={e => this.props.handleChangeEvent(e, AGREEMENT_FIELDS.AMOUNT_PER_MONTH)} />
        </FormGroup>
        <FormGroup>
          <label htmlFor="amount_maintenance">{i18n.t(k.AMOUNT_MAINTENANCE)}</label>
          <FormInput id="amount_maintenance"
            value={this.props.agreement.rules && this.props.agreement.rules.amount_maintenance ?
              this.props.agreement.rules.amount_maintenance.value : ''}
            onChange={e => this.props.handleChangeEvent(e, AGREEMENT_FIELDS.MAINTENANCE_COST)} />
        </FormGroup>
        <FormGroup>
          <label htmlFor="amount_tax">{i18n.t(k.AMOUNT_RENT_TAX)}</label>
          <FormInput id="amount_tax"
            value={this.props.agreement.rules && this.props.agreement.rules.amount_tax ?
              this.props.agreement.rules.amount_tax.value : ''}
            onChange={e => this.props.handleChangeEvent(e, AGREEMENT_FIELDS.AMOUNT_TAX)} />
        </FormGroup>
        <FormGroup>
          <label htmlFor="amount_annual_increase_min">{i18n.t(k.AMOUNT_RENT_ANNUAL_INCREASE_MIN)}</label>
          <FormInput id="amount_annual_increase_min"
            value={this.props.agreement.rules && this.props.agreement.rules.amount_annual_increase_min ?
              this.props.agreement.rules.amount_annual_increase_min.value : ''}
            onChange={e => this.props.handleChangeEvent(e, AGREEMENT_FIELDS.MINIMAL_ANNUAL_RENT_INCREASE)} />
        </FormGroup>
        <FormGroup>
          <label htmlFor="amount_annual_increase">{i18n.t(k.AMOUNT_RENT_ANNUAL_INCREASE)}</label>
          <FormInput id="amount_annual_increase"
            value={this.props.agreement.rules && this.props.agreement.rules.amount_annual_increase ?
              this.props.agreement.rules.amount_annual_increase.value : ''}
            onChange={e => this.props.handleChangeEvent(e, AGREEMENT_FIELDS.ANNUAL_RENT_INCREASE)} />
        </FormGroup>
        {/*<FormGroup>*/}
        {/*  <FormCheckbox*/}
        {/*    id="rent_amount_per_user"*/}
        {/*    checked={this.props.agreement.rules &&*/}
        {/*    this.props.agreement.rules.rent_amount_per_user &&*/}
        {/*    this.props.agreement.rules.rent_amount_per_user.value === 'on'}*/}
        {/*    onChange={e => this.props.handleChangeEvent(e, 'Per user')}>*/}
        {/*    {i18n.t(k.PER_USER)}*/}
        {/*  </FormCheckbox>*/}
        {/*</FormGroup>*/}
        <FormGroup>
          <label htmlFor="text">{i18n.t(k.CURRENCY)}</label>
          <FormSelect>
            <option value="EUR">{i18n.t(k.EUR)}</option>
            <option value="SEK">{i18n.t(k.SEK)}</option>
          </FormSelect>
        </FormGroup>
      </Form>);
  }
}

export default AgreementAmountComponent;