import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { useState, useEffect } from "react";
import MapComponent from "../ui_utils/MapComponent";
import { GOOGLE_API_KEY } from "../../config";
import { preventRoboto } from "../../misc";

preventRoboto();

const MapStreetView = ({
    name,
    latitude,
    longitude,
    onChangeUrl = () => {},
}) => {
    const [address, setAddress] = useState();
    const [pov, setPov] = useState({ heading: 1, pitch: 1 });
    const [search, setSearch] = useState("");
    const [streetCoords, setStreetCoords] = useState({
        lat: latitude ? latitude : 60.188332,
        lng: longitude ? longitude : 24.7675,
    });
    const [centerCoords, setСenterCoords] = useState({
        lat: latitude ? latitude : 60.188332,
        lng: longitude ? longitude : 24.7675,
    });

    const handleAddressChange = (name, value, lat, lon) => {
        if (name === "address") {
            setAddress(value);
        }
    };

    const handleStreetPositionChange = (event) => {
        if (event) {
            setStreetCoords({ lat: event.lat(), lng: event.lng() });
        }
    };

    const handlePovChange = (pov) => {
        setPov(pov);
    };

    useEffect(() => {
        if (pov && streetCoords && streetCoords.lat && streetCoords.lng) {
            onChangeUrl(
                name,
                `https://maps.googleapis.com/maps/api/streetview?size=710x400&location=${streetCoords.lat},${streetCoords.lng}&fov=85&heading=${pov.heading}&pitch=${pov.pitch}&key=${GOOGLE_API_KEY}`
            );
        }
    }, [streetCoords, pov]);

    const handleCoordinatesChange = (newLat, newLng) => {
        setStreetCoords({ lat: newLat, lng: newLng });
    };

    // console.log(centerCoords,"==================>centerCoords")
    return (
        <div>
            {streetCoords && (
                <div>
                    <MapComponent
                        handleAddressChange={handleAddressChange}
                        handleCoordinatesChange={handleCoordinatesChange}
                        center={centerCoords}
                        // center={{
                        //     "lat": 40.714728,
                        //     "lng": -73.998672
                        // }}
                        search={search}
                        onPovChanged={handlePovChange}
                        onStreetPositionChanged={handleStreetPositionChange}
                        height="600px"
                        streetViewVisibility={true}
                        zoom={15}
                    />
                </div>
            )}
        </div>
    );
};

export default MapStreetView;
