import i18n from 'i18next';
import k from "./../../i18n/keys";
import React, { Component } from 'react';
import { Button, Form, FormGroup, FormSelect, FormInput } from "shards-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import HonkioAPI from "../../middlewares/HonkioAPI";
import MapComponent from "../ui_utils/MapComponent";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import CenteredPageLoader from "../ui_utils/CenteredPageLoader";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { post } from "axios";
import { UPLOAD_URL } from "../../config";
import Select from "react-select";
import { GOOGLE_API_KEY } from "../../config.prod";
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";
import { preventRoboto } from "../../misc";
import { ASSET_TYPE } from './../../actions/api/assets/config';

preventRoboto();

class BuildingEditComponent extends Component {
    constructor(props) {
        super(props);
        this.save = this.save.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.handleChangeProperties = this.handleChangeProperties.bind(this)
        this.handleChangePropertiesProperties = this.handleChangePropertiesProperties.bind(this)
        this.handlePovChange = this.handlePovChange.bind(this);
        this.updatePreview = this.updatePreview.bind(this);
        this.getCompanyData = this.getCompanyData.bind(this);
        this.handleStreetPositionChange = this.handleStreetPositionChange.bind(this);
        this.state = {
            search: '',
            building: {},
            //'landplots': [],
            address: {},
            business_id: "",
            loading: true,
            pov: { 'heading': 1, 'pitch': 1 },
            streetCoords: { 'lat': 0, 'lon': 0 }
        };
    }

    componentDidMount() {
        let that = this;
        let buildingId = this.props.match.params.buildingId;
        let merchantgetbuildingsandprops = async function () {
            let responseBuildings = await HonkioAPI().userShop.userFetch('merchantassetlist', {
                query_id: buildingId,
                query_type: ASSET_TYPE.BUILDING
            }, error => {
                that.setState({
                    loading: false,
                })
              })
            //let landplots = null
            if (responseBuildings?.status === 'accept' && responseBuildings.assets.length > 0) {
                let building = responseBuildings.assets[0];
                /*
                    let responseLandplots = await HonkioAPI().userShop.userFetch('merchantassetlist', {
                        query_type: 'landplot'
                    })
                    if (responseLandplots.status === 'accept' && responseLandplots.assets.length > 0) {
                        landplots = responseLandplots.assets;
                    }
                */
                /*if (landplots.length > 0) {
                    that.handleChange({ 'target': { 'value': landplots[0].id, 'id': 'landplot' } }, 'landplot');
                }*/
                /*let geoState = {
                    'pov': JSON.parse(buildings[0].pov.replace(/'/g, '"')),
                    'streetCoords': {
                        'lat': buildings[0] && buildings[0].latitude ? buildings[0].latitude : 0.0,
                        'lon': buildings[0] && buildings[0].longitude ? buildings[0].longitude : 0.0
                    },
                }*/
                that.setState({
                    'streetCoords': {
                        'lat': building?.latitude ?? 0.0,
                        'lon': building?.longitude ?? 0.0
                    },
                    building: building,
                    //'landplots': landplots,
                    loading: false,
                    address: { address: building.properties.address },
                });
                // let coors = JSON.parse("[" + buildings[0].coordinates + "]");
                //that.updatePreview(geoState);
            } else {
                that.setState({
                    loading: false,
                });
            }
        };
        if (buildingId && buildingId !== 'new') {
            merchantgetbuildingsandprops();
        } else {
            that.setState({ 
                loading: false,
                building:
                {
                    structure: '',
                    type: 'building',
                    parent: that.props.match.params.parent,
                    properties: {
                        properties: {}
                    }
                },
            });
        }
    }

    /*handleChangeProperties(e, label = null) {
        if (e.target) {
            this.setState({
                'properties': {
                    ...this.state.properties,
                    [e.target.id]: e.target.value
                }
            });
        } else {
            this.setState({
                'properties': {
                    ...this.state.properties,
                    [label]: e.value
                }
            });
        }
    }*/

    handleChange = (e) => {
        if (e.target) {
            if (e.target.id === 'business_id' && e.target.value.length >= 6) {
                this.getCompanyData(e.target.value);
            }
            this.setState({
                building: {
                    ...this.state.building,
                    [e.target.id]: e.target.value
                }
            });
        }
    }
    handleChangeProperties(e) {
        if (e.target) {
            this.setState({
                building: {
                    ...this.state.building,
                    properties: {
                        ...this.state.building.properties,
                        [e.target.id]: e.target.value
                    }
                }
            });
            return
        }
    }
    handleChangePropertiesProperties(e, label = null) {
        if (e.target) {
            this.setState({
                building: {
                    ...this.state.building,
                    properties: {
                        ...this.state.building.properties,
                        properties: {
                            ...this.state.building.properties.properties,
                            [e.target.id]: e.target.value
                        }
                    }
                }
            });
        }else {
            this.setState({
                building: {
                    ...this.state.building,
                    properties: {
                        ...this.state.building.properties,
                        properties: {
                            ...this.state.building.properties.properties,
                            [label]: e.value
                        }
                    }
                }
            });
        }
    }
    blobToFile(theBlob, fileName) {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    save() {
        let that = this;
        this.setState({ 'loading': true });
        let savebuilding = async function () {            
            const assetPropertiesProperties = {
                shares: that.state.building.properties.properties.shares,
                marketRentPrice: that.state.building.properties.properties.marketRentPrice,
                totalArea: that.state.building.properties.properties.totalArea,
                buildingRights: that.state.building.properties.properties.buildingRights,
                totalVolume: that.state.building.properties.properties.totalVolume,
                electricityConsumption: that.state.building.properties.properties.electricityConsumption,
                heatingEnergyConsumption: that.state.building.properties.properties.heatingEnergyConsumption,
                coolingEnergyConsumption: that.state.building.properties.properties.coolingEnergyConsumption,
                totalWaterConsumption: that.state.building.properties.properties.totalWaterConsumption,
                planDefinedParkingPlaces: that.state.building.properties.properties.planDefinedParkingPlaces,
                actualParkingPlaces: that.state.building.properties.properties.actualParkingPlaces,
                carPortPlaces: that.state.building.properties.properties.carPortPlaces,
                electricConnectorsToCars: that.state.building.properties.properties.electricConnectorsToCars,
                chargePlacesForCars: that.state.building.properties.properties.chargePlacesForCars,
                buildingYearOfGraduation: that.state.building.properties.properties.buildingYearOfGraduation,
                constructionPeriod: that.state.building.properties.properties.constructionPeriod,
                constructionRegulations: that.state.building.properties.properties.constructionRegulations,
                rentLeaseDate: that.state.building.properties.properties.rentLeaseDate,
                isLeased: that.state.building.properties.properties.isLeased,
                elevators: that.state.building.properties.properties.elevators,
                stairwells: that.state.building.properties.properties.stairwells,

                buildingType: that.state.building.properties.properties.buildingType,
                constructionMaterial: that.state.building.properties.properties.constructionMaterial,
                roofType: that.state.building.properties.properties.roofType,
                roofMaterial: that.state.building.properties.properties.roofMaterial,
                ownOrLeasedLand: that.state.building.properties.properties.ownOrLeasedLand,
                dataCabels: that.state.building.properties.properties.dataCabels,
                coolingSystem: that.state.building.properties.properties.coolingSystem,
                heatingSystem: that.state.building.properties.properties.heatingSystem,
                ventilationSystem: that.state.building.properties.properties.ventilationSystem,
                pov: that.state.pov,
            }
            const assetProperties = {
                description: that.state.building.properties.description,
                business_id: that.state.building.properties.business_id,
                property_manager: that.state.building.properties.property_manager,
                ...that.state.address,
                properties: assetPropertiesProperties,
            }
            let params = {
                merchant: localStorage.getItem('merchant_id'),
                name: that.state.building.name,
                address: that.state.address.address,
                properties: assetProperties,
                longitude: that.state.streetCoords.lon,
                latitude: that.state.streetCoords.lat,
                coordinates: [that.state.streetCoords.lat, that.state.streetCoords.lon],
                structure: that.state.building.structure,
                pov: that.state.pov,
                type: ASSET_TYPE.BUILDING,
                parent: that.state.building.parent
            };
            if (that.state.building.id) {
                params['id'] = that.state.building.id;
            }
            let response = await HonkioAPI().userShop.userFetch('assetset', params, error => {
                that.setState({ loading: false });
            });
            if (response?.status === 'accept') {
                if (that.state.buildingUrl) {
                    let blob = await fetch(that.state.buildingUrl).then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.blob();
                    });
                    blob = that.blobToFile(blob, 'building.jpg');

                    let fileset = await HonkioAPI().userShop.userFetch('userfileset', {
                        'object': response.building.id,
                        'object_type': ASSET_TYPE.BUILDING,
                        'access': 'public'
                    });
                    let data = {
                        'id': fileset.id,
                        'upload_token': fileset.upload_token
                    };
                    const formData = new FormData();
                    formData.append('file', blob, blob.name);
                    formData.append('message', new Blob([JSON.stringify(data)], {
                        type: 'application/json'
                    }));
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    };
                    let url, thumb;
                    await post(UPLOAD_URL, formData, config).then(uploadFile => {
                        url = uploadFile.data.url;
                        thumb = uploadFile.data.thumbnail_url;
                    }, error => {
                        NotificationManager.warning(i18n.t(k.UPLOAD_FAILED_IMAGE), `${error}`);
                        that.setState({ 'loading': false });
                    });
                    let setUrl = await HonkioAPI().userShop.userFetch('merchantassetset',
                        {
                            'id': response.building.id,
                            'custom_fields': { 'thumbUrl': thumb, 'url': url }
                        });
                    that.setState({ 'loading': false });
                    if (setUrl.status === 'accept') {
                        NotificationManager.success(i18n.t(k.BUILDING_SAVED));
                        if (that.props.match.params.buildingId === "new") {
                            that.props.history.push(`/floorplans/${response.building.id}`);
                        }
                        that.props.history.push(`/buildings/${that.props.match.params.parent}`);
                    }
                } else {
                    that.setState({ 'loading': false });
                    NotificationManager.success(i18n.t(k.BUILDING_SAVED));
                    if (that.props.match.params.buildingId === "new") {
                        that.props.history.push(`/buildings/${that.props.match.params.parent}`);
                        // that.props.history.push(`/floorplans/${response.building.id}`);
                    }
                    that.props.history.push(`/buildings/${that.props.match.params.parent}`);
                }
            } else {
                that.setState({ loading: false });
            }
        };
        savebuilding();
    }

    handleAddressChange(name, value, lat, lon) {
        this.setState({ 'address': { ...this.state.address, [name]: value } });
    }

    handleStreetPositionChange(event) {
        let newLat = event.lat(),
            newLng = event.lng();
        let newState = {
            'pov': { ...this.state.pov },
            'streetCoords': { 'lat': newLat, 'lon': newLng }
        };
        this.setState(newState);
        this.updatePreview(newState);
    }

    handlePovChange(pov) {
        let newState = {
            'pov': pov,
            'streetCoords': { ...this.state.streetCoords }
        };
        this.setState(newState);
        this.updatePreview(newState);
    }

    updatePreview(newState) {
        let that = this;
        // if (this.imageTimer) {
        //     clearTimeout(this.imageTimer);
        // }
        // this.imageTimer = setTimeout(function () {
        //     that.setState({'buildingUrl': `https://maps.googleapis.com/maps/api/streetview?size=710x400&location=${newState.streetCoords.lat},${newState.streetCoords.lon}&fov=85&heading=${newState.pov.heading}&pitch=${newState.pov.pitch}&key=${GOOGLE_API_KEY}`});
        //
        // }, 800);
    }

    getCompanyData(id) {
        let that = this;
        let INFO_URL = `https://avoindata.prh.fi/bis/v1/${id}`;
        let requestInfo = async function () {
            let response = await fetch(INFO_URL);
            try {
                let json = await response.json();
                if (json.results.length > 0) {
                    let data = json.results[0];
                    let phone = '';
                    data.contactDetails.forEach(function (contact) {
                        if (contact.value.startsWith('+')) {
                            phone = contact.value;
                        }
                    });
                    let address = data.addresses[data.addresses.length - 1];
                    that.setState({
                        search: [address.street, address.postCode, address.city].join(', '),
                        building: { ...that.state.building, name: data.name }
                    });
                }
            } catch (e) {
                console.error(e);
            }
        };
        requestInfo();
    }

    render() {
        let saveButton = <Button onClick={this.save}>{i18n.t(k.SAVE)}</Button>;
        if (this.state.loading) {
            saveButton = <CircularProgress size={45} />;
        }
        if (this.state.loading) {
            return <CenteredPageLoader />;
        }
        const options = {
            'buildingType': [
                { value: i18n.t(k.COMMERCIAL_BUILDING), label: i18n.t(k.COMMERCIAL_BUILDING) },
                { value: i18n.t(k.HOUSING), label: i18n.t(k.HOUSING) },
                { value: i18n.t(k.ACCOMMODATION_SPACE), label: i18n.t(k.ACCOMMODATION_SPACE) },
                { value: i18n.t(k.ACCOMMODATION), label: i18n.t(k.ACCOMMODATION) },
                { value: i18n.t(k.WORKPLACE_BUILDING), label: i18n.t(k.WORKPLACE_BUILDING) },
                { value: i18n.t(k.NURSING_HOME), label: i18n.t(k.NURSING_HOME) }],

            'constructionMaterial': [
                { value: i18n.t(k.CONCRETE), label: i18n.t(k.CONCRETE) },
                { value: i18n.t(k.BRICK), label: i18n.t(k.BRICK) },
                { value: i18n.t(k.STEEL), label: i18n.t(k.STEEL) },
                { value: i18n.t(k.WOOD), label: i18n.t(k.WOOD) }],


            'roofType': [
                { value: i18n.t(k.CEILING_BRUSH), label: i18n.t(k.CEILING_BRUSH) },
                { value: i18n.t(k.HIP_ROOF), label: i18n.t(k.HIP_ROOF) },
                { value: i18n.t(k.PITCHED_ROOF), label: i18n.t(k.PITCHED_ROOF) },
                { value: i18n.t(k.MANSARD_ROOF), label: i18n.t(k.MANSARD_ROOF) },
                { value: i18n.t(k.FLAT_ROOF), label: i18n.t(k.FLAT_ROOF) },
                { value: i18n.t(k.TOWER_ROOF), label: i18n.t(k.TOWER_ROOF) },
                { value: i18n.t(k.INVERTED_GABLE_ROOF), label: i18n.t(k.INVERTED_GABLE_ROOF) },
                { value: i18n.t(k.SEMI_HIP_ROOF), label: i18n.t(k.SEMI_HIP_ROOF) },
                { value: i18n.t(k.SERIES_SHED_ROOF), label: i18n.t(k.SERIES_SHED_ROOF1) }],

            'roofMaterial': [
                { value: i18n.t(k.SHEET_METAL), label: i18n.t(k.SHEET_METAL) },
                { value: i18n.t(k.CONCRETE_ROOF_TILE), label: i18n.t(k.CONCRETE_ROOF_TILE) },
                { value: i18n.t(k.MACHINE_SEALED_DAMPER), label: i18n.t(k.MACHINE_SEALED_DAMPER) },
                { value: i18n.t(k.ADOBE), label: i18n.t(k.ADOBE) },
                { value: i18n.t(k.BITUMEN_TREATED_FELT), label: i18n.t(k.BITUMEN_TREATED_FELT) },
                { value: i18n.t(k.COPPER), label: i18n.t(k.COPPER) },
                { value: i18n.t(k.SHINGLE), label: i18n.t(k.SHINGLE) }],

            'ownOrLeasedLand': [
                { value: i18n.t(k.LEASEHOLD), label: i18n.t(k.LEASEHOLD) },
                { value: i18n.t(k.OWN_LAND), label: i18n.t(k.OWN_LAND) }],

            'dataCabels': [
                { value: i18n.t(k.DSL), label: i18n.t(k.DSL) },
                { value: i18n.t(k.HOME_PNA), label: i18n.t(k.HOME_PNA) },
                { value: i18n.t(k.OPTICAL_FIBER), label: i18n.t(k.OPTICAL_FIBER) },
                { value: i18n.t(k.ETHERNET), label: i18n.t(k.ETHERNET) }],

            'coolingSystem': [
                { value: i18n.t(k.DISTRICT_COOLING), label: i18n.t(k.DISTRICT_COOLING) },
                { value: i18n.t(k.AIR_CONDITIONING), label: i18n.t(k.AIR_CONDITIONING) }],

            'heatingSystem': [
                { value: i18n.t(k.DISTRICT_HEATING), label: i18n.t(k.DISTRICT_HEATING) },
                { value: i18n.t(k.OIL_HEATING), label: i18n.t(k.OIL_HEATING) },
                { value: i18n.t(k.GEOTHERMAL_HEAT), label: i18n.t(k.GEOTHERMAL_HEAT) },
                { value: i18n.t(k.PEAT), label: i18n.t(k.PEAT) },
                { value: i18n.t(k.BRIQUET), label: i18n.t(k.BRIQUET) },
                { value: i18n.t(k.ELECTRIC), label: i18n.t(k.ELECTRIC) },
                { value: i18n.t(k.WOOD), label: i18n.t(k.WOOD) }],

            'ventilationSystem': [
                { value: i18n.t(k.MECHANICAL), label: i18n.t(k.MECHANICAL) },
                { value: i18n.t(k.MECHANICAL_HEAT_RECOVERY), label: i18n.t(k.MECHANICAL_HEAT_RECOVERY) }]
        };
        let getDefaultValue = (key, value) => {
            let def_value = {};
            options[key].forEach(dict => {
                if (dict['value'] === value)
                    def_value = dict;
            });
            return def_value;
        };
        let getFormSelect = () => {
            let items = [];
            for (const key of Object.keys(options)) {
                let label = key.replace(/([A-Z])/g, ' $1').trim();
                label = label.charAt(0).toUpperCase() + label.slice(1);
                items.push(
                    <FormGroup>
                        <label htmlFor={key}>{label}</label>
                        <Select id={key}
                            onChange={e => {
                                this.handleChangePropertiesProperties(e, key);
                            }}
                            options={options[key]}
                            value={getDefaultValue(key,this.state.building.properties?.properties && this.state.building.properties?.properties[key])} />
                    </FormGroup>);
            }
            return items;
        };
        /*let getLandplotSelector = () => {
            let landplots_options = [];
            if (this.state.landplots && typeof this.state.landplots !== 'undefined') {
                this.state.landplots.forEach(function (landplot) {
                    landplots_options.push({ value: landplot.id, label: landplot.name });
                });
            }
            let default_option = landplots_options.filter(option => option.value === this.state.building.landplot)
            return (
                <FormGroup>
                    <label htmlFor="landplot">{i18n.t(k.PROPERTY)}</label>
                    <Select id="landplot"
                        onChange={e => { this.handleChange(e, 'landplot') }}
                        options={landplots_options}
                        value={default_option[0]} />
                </FormGroup>
            )
        }*/
        let ifNotNew = () => {
            return this.props.match.params.buildingId !== "new";
        };
        return (
            <div>
                <CardWrapperComponent
                    header={true}
                    footer={true}
                    card_title={i18n.t(k.CREATE_A_BUILDING)}>
                    <div className="row">
                        <div className="col-sm-4">
                            <Form>
                                <FormGroup>
                                    <label htmlFor="name">{i18n.t(k.NAME)}</label>
                                    <FormInput id="name"
                                        value={this.state.building.name}
                                        onChange={e => this.handleChange(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor="properties.description">{i18n.t(k.DESCRIPTION)}</label>
                                    <FormInput id="description"
                                        value={this.state.building.properties?.description}
                                        onChange={e => this.handleChangeProperties(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor="properties.business_id">{i18n.t(k.BUSINESS_ID)}</label>
                                    <FormInput id="business_id"
                                        value={this.state.building.properties?.business_id}
                                        onChange={e => this.handleChangeProperties(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor="address">{i18n.t(k.ADDRESS)}</label>
                                    <FormInput id="address"
                                        value={this.state.address.address}
                                        onChange={e => this.handleAddressChange(e.target.id, e.target.value)} />
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor="property_manager">{i18n.t(k.PROPERTY_MANAGER)}
                                    </label>
                                    <FormInput id="property_manager"
                                        value={this.state.building.properties?.property_manager}
                                        onChange={e => this.handleChangeProperties(e)} />
                                </FormGroup>
                                {/*{getLandplotSelector()}*/}
                                {/**/}
                                {/*<div>*/}
                                {/*    <h4>{i18n.t(k.BUILDING_PICTURE)}</h4>*/}
                                {/*    <img className="mw-100"*/}
                                {/*         src={this.state.buildingUrl}/>*/}
                                {/*</div>*/}
                            </Form>
                        </div>
                        <div className="col-sm-8">
                            Map
                            {/* <MapComponent
                                handleAddressChange={this.handleAddressChange}
                                ref={mapRef => this.mapRef = mapRef}
                                // google={this.props.google}
                                search={this.state.search}
                                center={{
                                    lat: this.state.building && this.state.building.latitude ? this.state.building.latitude : 60.188332,
                                    lng: this.state.building && this.state.building.longitude ? this.state.building.longitude : 24.7675
                                }}
                                onPovChanged={this.handlePovChange}
                                onStreetPositionChanged={this.handleStreetPositionChange}
                                height='600px'
                                zoom={15} /> */}
                        </div>
                    </div>
                    <hr />
                    <h4>{i18n.t(k.ADDITIONAL_FIELDS)}</h4>
                    <div className="row">
                        <div className="col-md-6">
                            <Form>
                                <FormGroup>
                                    <label htmlFor="shares">{i18n.t(k.NUMBER_OF_SHARES_TOTAL)}</label>
                                    <FormInput id="shares"
                                        value={this.state.building.properties?.properties?.shares}
                                        disabled={ifNotNew()}
                                        onChange={e => this.handleChangePropertiesProperties(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor="marketRentPrice">{i18n.t(k.MARKET_RENT_PRICE)}</label>
                                    <FormInput id="marketRentPrice"
                                        value={this.state.building.properties?.properties?.marketRentPrice}
                                        onChange={e => this.handleChangePropertiesProperties(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor="totalArea">{i18n.t(k.TOTAL_AREA_M1)}</label>
                                    <FormInput id="totalArea"
                                        value={this.state.building.properties?.properties?.totalArea}
                                        onChange={e => this.handleChangePropertiesProperties(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor="buildingRights">{i18n.t(k.BUILDING_RIGHTS_M)}</label>
                                    <FormInput id="buildingRights"
                                        value={this.state.building.properties?.properties?.buildingRights}
                                        onChange={e => this.handleChangePropertiesProperties(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor="totalVolume">{i18n.t(k.TOTAL_VOLUME_M)}</label>
                                    <FormInput id="totalVolume"
                                        value={this.state.building.properties?.properties?.totalVolume}
                                        onChange={e => this.handleChangePropertiesProperties(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor="electricityConsumption">{i18n.t(k.ELECTRICITY_CONSUMPTION_KWH)}
                                    </label>
                                    <FormInput id="electricityConsumption"
                                        value={this.state.building.properties?.properties?.electricityConsumption}
                                        onChange={e => this.handleChangePropertiesProperties(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor="heatingEnergyConsumption">{i18n.t(k.HEATING_ENERGY_CONSUMPTION_IN)}

                                    </label>
                                    <FormInput id="heatingEnergyConsumption"
                                        value={this.state.building.properties?.properties?.heatingEnergyConsumption}
                                        onChange={e => this.handleChangePropertiesProperties(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor="coolingEnergyConsumption">{i18n.t(k.COOLING_ENERGY_CONSUMPTION_IN)}

                                    </label>
                                    <FormInput id="coolingEnergyConsumption"
                                        value={this.state.building.properties?.properties?.coolingEnergyConsumption}
                                        onChange={e => this.handleChangePropertiesProperties(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor="totalWaterConsumption">{i18n.t(k.TOTAL_WATER_CONSUMPTION)}</label>
                                    <FormInput id="totalWaterConsumption"
                                        value={this.state.building.properties?.properties?.totalWaterConsumption}
                                        onChange={e => this.handleChangePropertiesProperties(e)} />
                                </FormGroup>

                                <FormGroup>
                                    <label
                                        htmlFor="planDefinedParkingPlaces">{i18n.t(k.PLAN_DEFINED_PARKING_PLACES)}</label>
                                    <FormInput id="planDefinedParkingPlaces"
                                        value={this.state.building.properties?.properties?.planDefinedParkingPlaces}
                                        onChange={e => this.handleChangePropertiesProperties(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor="actualParkingPlaces">{i18n.t(k.ACTUAL_PARKING_PLACES)}</label>
                                    <FormInput id="actualParkingPlaces"
                                        value={this.state.building.properties?.properties?.actualParkingPlaces}
                                        onChange={e => this.handleChangePropertiesProperties(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor="carPortPlaces">{i18n.t(k.NUMBER_OF_CAR_PORT_PLACES)}</label>
                                    <FormInput id="carPortPlaces"
                                        value={this.state.building.properties?.properties?.carPortPlaces}
                                        onChange={e => this.handleChangePropertiesProperties(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor="electricConnectorsToCars">{i18n.t(k.NUMBER_OF_ELECTRIC_CONNECTORS)}
                                    </label>
                                    <FormInput id="electricConnectorsToCars"
                                        value={this.state.building.properties?.properties?.electricConnectorsToCars}
                                        onChange={e => this.handleChangePropertiesProperties(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        htmlFor="chargePlacesForCars">{i18n.t(k.NUMBER_OF_CHARGE_PLACES_FOR_CA)}</label>
                                    <FormInput id="chargePlacesForCars"
                                        value={this.state.building.properties?.properties?.chargePlacesForCars}
                                        onChange={e => this.handleChangePropertiesProperties(e)} />
                                </FormGroup>
                            </Form>
                        </div>
                        <div className="col-md-6">
                            <Form>
                                <FormGroup>
                                    <label
                                        htmlFor="buildingYearOfGraduation">{i18n.t(k.BUILDING_YEAR_OF_GRADUATION)}</label>
                                    <FormInput id="buildingYearOfGraduation"
                                        value={this.state.building.properties?.properties?.buildingYearOfGraduation}
                                        onChange={e => this.handleChangePropertiesProperties(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        htmlFor="constructionPeriod">{i18n.t(k.CONSTRUCTION_PERIOD)}</label>
                                    <FormInput id="constructionPeriod"
                                        value={this.state.building.properties?.properties?.constructionPeriod}
                                        onChange={e => this.handleChangePropertiesProperties(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        htmlFor="constructionRegulations">{i18n.t(k.CONSTRUCTION_REGULATIONS)}</label>
                                    <FormInput id="constructionRegulations"
                                        value={this.state.building.properties?.properties?.constructionRegulations}
                                        onChange={e => this.handleChangePropertiesProperties(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        htmlFor="rentLeaseDate">{i18n.t(k.RENT_LEASE_DATE)}</label>
                                    <FormInput id="rentLeaseDate"
                                        value={this.state.building.properties?.properties?.rentLeaseDate}
                                        onChange={e => this.handleChangePropertiesProperties(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        htmlFor="isLeased">{i18n.t(k.IS_LEASED)}</label>
                                    <FormInput id="isLeased"
                                        value={this.state.building.properties?.properties?.isLeased}
                                        onChange={e => this.handleChangePropertiesProperties(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor="elevators">{i18n.t(k.NUMBER_OF_ELEVATORS)}</label>
                                    <FormInput id="elevators"
                                        value={this.state.properties?.properties?.elevators}
                                        onChange={e => this.handleChangePropertiesProperties(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor="stairwells">{i18n.t(k.NUMBER_OF_STAIRWELLS)}</label>
                                    <FormInput id="stairwells"
                                        value={this.state.building.properties?.properties?.stairwells}
                                        onChange={e => this.handleChangePropertiesProperties(e)} />
                                </FormGroup>
                                {getFormSelect()}
                            </Form>
                        </div>
                    </div>
                    {saveButton}
                </CardWrapperComponent>
            </div>);
    }
}

export default BuildingEditComponent;
