import i18n from "i18next";
import k from "./../i18n/keys";
import React, { Component } from "react";
import CardWrapperComponent from "./ui_utils/CardWrapperComponent";
import { OAUTH_URL, REGISTRATION_URL } from "../config";
import { Link } from "react-router-dom";

class LandingComponent extends Component {
    render() {
        //  if(localStorage.getItem('token')){
        //     console.log('TOKEN EXITSTS')
        //     replace("/")
        //     return (<Redirect to="/" />)
        // }
        return (
            <div className="vh-100" style={{ marginTop: 10 + "rem" }}>
                <CardWrapperComponent
                    card_title={i18n.t(k.PROTASK)}
                    style={{ "min-height": "500px" }}
                >
                    <div className="text-center">
                        <h6>{i18n.t(k.WELCOME_TO_RENTAL_MANAGEMENT_P)}</h6>
                        <h4 className="card-text">
                            {i18n.t(k.PLEASE)}{" "}
                            <a href={OAUTH_URL}>{i18n.t(k.LOGIN)}</a>
                        </h4>
                    </div>
                    <div className="text-right w-100">
                        {i18n.t(k.YOU_WOULD_LIKE_TO_START_RENTAL)}
                        <br />
                        <a href={REGISTRATION_URL}>{i18n.t(k.TRY)}</a>{" "}
                        {i18n.t(k.RENTAL_SOLUTION_FOR_FREE)}
                    </div>
                </CardWrapperComponent>
            </div>
        );
    }
}

export default LandingComponent;
