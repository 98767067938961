import i18n from 'i18next';
import k from "./../../i18n/keys";
import React, {Component} from 'react';
import {
    Button,
    Form,
    FormGroup,
    span,
    FormSelect,
    Modal,
    ModalBody,
    ModalHeader,
    InputGroup,
    InputGroupAddon, FormInput, InputGroupText
} from "shards-react";
import HonkioAPI from "../../middlewares/HonkioAPI";
import CenteredLoader from "../ui_utils/CenteredLoader";
import CachedIcon from '@material-ui/icons/Cached';
import NotificationManager from "react-notifications/lib/NotificationManager";

class TenantDetailsModalComponent extends Component {
    constructor(props) {
        super(props);
        this.load = this.load.bind(this);
        this.state = {'tenant': {}, loading: true};
    }

    componentDidMount() {
        this.load(this.props.email);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.email) {
            this.load(nextProps.email);
        }
    }

    load(email) {
        let that = this;
        if (!email || email.length <= 0) {
            return
        }
        this.setState({'loading': true});
        let loadTenant = async function () {
            let response = await HonkioAPI().userShop.userFetch("merchantuserinfo", {email: that.props.email}, (message) => {
                // NotificationManager.warning('User not found');
                console.error(message)
            });
            
            that.setState({loading: false, tenant: {...response}});
        };
        loadTenant();
    }

    render() {
        if (this.state.loading && (this.props.visible || !this.props.modal)) {
            return <CenteredLoader/>
        }
        let details = <Form className={'mt-5'}>

            <InputGroup className="mb-2">
                <InputGroupAddon type="prepend">
                    <InputGroupText>{i18n.t(k.FIRSTNAME)}</InputGroupText>
                </InputGroupAddon>
                <FormInput placeholder={i18n.t(k.FIRSTNAME)} value={this.state.tenant.user_first_name} disabled={true}/>
            </InputGroup>

            <InputGroup className="mb-2">
                <InputGroupAddon type="prepend">
                    <InputGroupText>{i18n.t(k.LASTNAME)}</InputGroupText>
                </InputGroupAddon>
                <FormInput placeholder={i18n.t(k.LASTNAME)} value={this.state.tenant.user_last_name} disabled={true}/>
            </InputGroup>

            <InputGroup className="mb-2">
                <InputGroupAddon type="prepend">
                    <InputGroupText>{i18n.t(k.EMAIL)}</InputGroupText>
                </InputGroupAddon>
                <FormInput placeholder={i18n.t(k.EMAIL)} value={(this.state.tenant.user_contact_data || {}).email}
                           disabled={true}/>
            </InputGroup>

            <InputGroup className="mb-2">
                <InputGroupAddon type="prepend">
                    <InputGroupText>{i18n.t(k.RATING)}</InputGroupText>
                </InputGroupAddon>
                <FormInput placeholder={i18n.t(k.RATING)} value={0} disabled={true}/>
                <InputGroupAddon type="append">
                    <InputGroupText><span className="ml-5 text-primary pointer" onClick={() => {
                        NotificationManager.warning('Not implemented');
                    }}><CachedIcon/></span></InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        </Form>
        if (!this.props.modal) {
            return details
        }
        return (

            <Modal open={this.props.visible}
                   toggle={this.props.toggle}
                   onRequestClose={this.props.onRequestClose}
                   className="w-100 modal-lg modal-dialog-centered">
                <ModalHeader>{this.state.tenant === null ? i18n.t(k.CREATE_TENANT) : i18n.t(k.EDIT_TENANT)}</ModalHeader>
                <ModalBody>
                    {details}
                </ModalBody>
            </Modal>);

    }
}


export default TenantDetailsModalComponent;
