import React, { Component } from 'react';

import { connect } from 'react-redux';

import { replace } from "react-router-redux";
import { LogoutAction } from '../../actions/auth/oauthActions';

class LogoutPageComponent extends Component {
  componentDidMount() {
    this.props.dispatch(LogoutAction());
    // console.log('LOACTION LOGAOUT PAGE');
    localStorage.clear();
    this.props.dispatch(replace('/'));
  }

  render() {
    return null;
  }}


// export default LogoutPageComponent;


export default connect()(LogoutPageComponent);