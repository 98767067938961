import en from "./en.json"
import fi from "./fi.json"
import { timeFormatDefaultLocale } from 'd3-time-format';

const langs = {
    'en': en,
    'fi': fi,
}
const d3Localize = () => {
    timeFormatDefaultLocale({
        ...langs[localStorage.getItem('i18nextLng') || 'en']
    });
}
export {d3Localize};
