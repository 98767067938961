import React from 'react';
const nullFilter = (params) =>
  Object.entries(params).reduce(
    (carry, [key, value]) =>
      [null, undefined, ''].includes(value)
        ? carry
        : { ...carry, [key]: value },
    {}
  );
export default nullFilter;
