import React from 'react';
const ButtonActionForm = ({ label, type, className }) => {
  return (
    <button
      className={className || 'btn btn-primary btn-lg btn-block'}
      type={type}
    >
      {label}
    </button>
  );
};

export default ButtonActionForm;
