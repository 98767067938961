import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Button } from 'react-bootstrap';
import NotificationManager from "react-notifications/lib/NotificationManager";
import { updateAssetData } from '../../actions/api/assets/setAssetData';
import { APP_ID } from './../../config';
import { STORAGE } from './../../actions/auth/oauthActions';

const AssetRemoveLinkModal = ({ assetToRemove, show, onHide, onAssetRemoved }) => {
    const onRemoveLink = () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success("Sensor Link removed")
                onAssetRemoved()
            } else {
                NotificationManager.error("Error")
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000)
        }

        const newAsset = {
            ...assetToRemove,
            parent: "None",
        }

        updateAssetData(newAsset, localStorage.getItem(STORAGE.MERCHANT_ID), APP_ID, errorHandler)
        .then((data) => {
            return resultHandler(data)
        })
    };

    return <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>{`Do you want to remove link with "${assetToRemove?.name}?"`}</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => onHide()}>Close</Button>
            <Button variant="danger" onClick={() => onRemoveLink()}>Remove</Button>
        </Modal.Footer>
    </Modal>
};

export default AssetRemoveLinkModal