import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowLeft from "@material-ui/icons/ChevronLeft";
import ArrowRight from "@material-ui/icons/ChevronRight";
import { Button, ButtonGroup } from "shards-react";

const SafetyNavigation = ({
    step,
    disablePrev = false,
    disableNext = false,
    onPrev = () => console.log("prev"),
    onNext = () => console.log("next"),
}) => {
    const renderTitle = (st) => {
        switch (st) {
            case 0:
                return "1. Select property";
            case 1:
                return "2. Select landplot";
            case 2:
                return "3. Select building";
            case 3:
                return "4. Create track";
            case 4:
                return "5. Track builder";
            case 5:
                return "6. Track created!";
            default:
                return "Loading, please wait...";
        }
    };
    return (
        <ButtonGroup>
            <Button onClick={onPrev} theme="light" disabled={disablePrev}>
                <ArrowLeft />
            </Button>
            <div className="d-flex justify-content-center align-items-center mx-1">
                {renderTitle(step)}
            </div>
            <Button onClick={onNext} theme="light" disabled={disableNext}>
                <ArrowRight />
            </Button>
        </ButtonGroup>
    );
};

export default SafetyNavigation;
