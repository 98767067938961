
import React from 'react';
import { ASSET_TYPE } from './../../actions/api/assets/config';
import { Redirect } from 'react-router-dom';
import useSearchString from './../../utils/useSearchString';

const AssetList = () => {
    const { type, parent } = useSearchString();
    const getUrl = (type, parent) => {
        // console.log(parent,"--------------parent")
        switch (type) {
            case ASSET_TYPE.LANDPLOT:
                return `/landplots?parent=${parent}`
            case ASSET_TYPE.PLOT:
                return `/plots?parent=${parent}`
            case ASSET_TYPE.BUILDING:
                return `/buildings?parent=${parent}`
            case ASSET_TYPE.FLOORPLAN:
                return `/floorplans?parent=${parent}`
            case ASSET_TYPE.ROOM:
                return `/rooms?parent=${parent}`
            case ASSET_TYPE.PARKING_PLACE:
                    return `/rooms?parent=${parent}`
            default:
                return '404'
        }
    }
    return <Redirect
        to={getUrl(type, parent)}
    />

}
export default AssetList;