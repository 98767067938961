import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { Component } from "react";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataTable from "react-data-table-component";
import DeleteForever from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "shards-react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import NotificationManager from "react-notifications/lib/NotificationManager";
import TenantEditModalComponent from "./TenantEditModalComponent";
import HonkioAPI from "../../middlewares/HonkioAPI";
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";
import DescriptionIcon from "@material-ui/icons/Description";
import InviteUserModal from "../invite/InviteUserComponent";
import { Link } from "react-router-dom";

const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

class TenantsListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            tenants: [],
            modal: {
                visible: false,
            },
        };

        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.toggle = this.toggle.bind(this);
        this.loadTenants = this.loadTenants.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
    }

    hideModal() {
        this.setState({ modal: { ...this.state.modal, visible: false } });
    }

    showModal(tenant) {
        this.setState({
            modal: { ...this.state.modal, visible: true, tenant: tenant },
        });
    }

    toggle() {
        this.setState({
            modal: { ...this.state.modal, visible: !this.state.modal.visible },
        });
    }

    componentDidMount() {
        this.loadTenants();
    }

    confirmDelete(tenant) {
        let that = this;
        const options = {
            title: "Destructive action",
            message: "",
            childrenElement: () => (
                <p>
                    {i18n.t(k.ARE_YOU_SURE_WANT_TO_DELETE1)}{" "}
                    <b>{tenant.name}</b>
                    {i18n.t(k._4)}
                </p>
            ),
            buttons: [
                {
                    label: i18n.t(k.YES),
                    onClick: () => {
                        const del = async () => {
                            that.setState({ loading: true });
                            let response = await HonkioAPI().userShop.userFetch(
                                "merchanttenantset",
                                {
                                    id: tenant._id,
                                    delete: true,
                                }
                            );

                            if (response.status === "accept") {
                                NotificationManager.success(
                                    i18n.t(k.LOADING),
                                    i18n.t(k.TENANT_HAS_BEEN_REMOVED)
                                );
                            } else {
                                NotificationManager.success(
                                    `${response.description}`,
                                    i18n.t(k.DELETE_FAILED)
                                );
                            }
                            that.loadTenants();
                        };
                        del();
                    },
                },

                {
                    label: i18n.t(k.NO),
                    onClick: () => {},
                },
            ],

            closeOnEscape: true,
            closeOnClickOutside: true,
        };

        confirmAlert(options);
    }

    loadTenants() {
        let that = this;
        let merchantgettenants = async function () {
            let srole;
            let getmerchantroles = await HonkioAPI().userShop.userFetch(
                "getmerchantroles",
                {
                    query_access: "all",
                    query_sort: [{ access: -1 }, { name: 1 }],
                }
            );
            let roles = getmerchantroles.roles;
            // console.log("ROLES")
            // console.log(roles)
            if (roles.length > 0) {
                let roles_ids = [];
                roles.forEach((role) => {
                    if (["tenant"].includes(role.name)) {
                        srole = role;
                    }
                });
                that.setState({ role: srole });
                // alert(srole)
                let merchantlistuserroles =
                    await HonkioAPI().userShop.userFetch(
                        "merchantlistuserroles",
                        {
                            query_roles: true,
                            roles: [srole.role_id],
                            query_building: that.props.buildingId,
                        }
                    );
                let tenants = merchantlistuserroles.consumers;
                // console.log("TENANTS:")
                // console.log(tenants)
                that.setState({
                    tenants: tenants,
                    role: srole,
                    loading: false,
                });
            }
        };
        merchantgettenants();
    }

    getColumns() {
        let that = this;
        return [
            {
                name: i18n.t(k.FIRST_NAME),
                selector: "settings.str_firstname",
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.LAST_NAME),
                selector: "settings.str_lastname",
                sortable: true,
                style: `cursor: pointer;`,
            },

            {
                name: i18n.t(k.EMAIL),
                selector: "email",
                sortable: true,
                style: `cursor: pointer;`,
            },

            {
                name: i18n.t(k.PHONE),
                selector: "settings.str_telephone",
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.HONKIO_USER),
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (e) {
                    return e.active ? "Yes" : "Not yet";
                },
            },
            {
                name: i18n.t(k.ACTION),
                selector: "action",
                cell: function (d) {
                    return (
                        <span>
                            <Link
                                to={`/tenantInvoices/${d.id}`}
                                className="pointer"
                                target="_blank"
                                data-toggle="tooltip"
                                title={i18n.t(k.VIEW_INVOICES)}
                            >
                                <DescriptionIcon />
                            </Link>

                            <Link
                                to="#"
                                className="pointer"
                                data-toggle="tooltip"
                                title={i18n.t(k.DELETE_ACTION)}
                                onClick={() => {
                                    that.confirmDelete(d);
                                }}
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ];
    }

    render() {
        let that = this;
        return (
            <div>
                {/*<div className="container-fluid">*/}
                {/*  <PageHeaderComponent*/}
                {/*    title="Tenants"*/}
                {/*    subtitle="Overview"/>*/}
                {/*</div>*/}
                {/*<CardWrapperComponent*/}
                {/*  header={true}*/}
                {/*  footer={true}*/}
                {/*  card_title="Tenants">*/}
                <Button
                    theme="info"
                    onClick={() => {
                        that.showModal(null);
                    }}
                >
                    <AddIcon className="mr-2" />
                    {i18n.t(k.ADD)}
                </Button>
                {/*<div className="">*/}
                <DataTable
                    autoWidth={true}
                    columns={this.getColumns()}
                    background="#ffffff00"
                    data={this.state.tenants}
                    noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                    paginationComponentOptions={{
                        rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                        rangeSeparatorText: i18n.t(k.ROWS_OF),
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                    }}
                    progressPending={this.state.loading}
                    progressComponent={<Circular />}
                    onRowClicked={this.rowSelected}
                />

                {/*</CardWrapperComponent>*/}
                <InviteUserModal
                    visible={this.state.modal.visible}
                    open={this.hideModal}
                    toggle={this.hideModal}
                    onRequestClose={this.onRequestClose}
                    buildingId={this.props.buildingId}
                    role_name={this.state.role_name}
                    role={this.state.role}
                    onInviteSuccess={() => {
                        that.setState({ loading: true });
                        that.hideModal();
                        that.loadTenants();
                    }}
                    onInviteError={(response) => {
                        NotificationManager.warning(
                            response.description || i18n.t(k.UNKNOWN_ERROR),
                            i18n.t(k.ERROR_SENDING_INVITE),
                            5000
                        );
                    }}
                />
                {/*<TenantEditModalComponent*/}
                {/*  visible={this.state.modal.visible}*/}
                {/*  open={this.hideModal}*/}
                {/*  toggle={this.toggle}*/}
                {/*  onRequestClose={this.props.onRequestClose}*/}
                {/*  tenant={this.state.modal.tenant}*/}
                {/*  roleId={this.state.tenant_role}*/}
                {/*  buildingId={that.props.buildingId}*/}
                {/*  onTenantSaved={() => {*/}
                {/*    that.setState({'loading': true});*/}
                {/*    that.hideModal();*/}
                {/*    that.loadTenants();*/}
                {/*  }}/>*/}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TenantsListComponent);
