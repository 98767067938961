import React from 'react';
import ReactDOM from 'react-dom';
import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";

import './main.css';

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);
