import HonkioAPI from "./../../../middlewares/HonkioAPI";

const getAssetTypeList = (options = {}, errorHandler = {}) => {
    const { query_application, query_parent } = options;
    return HonkioAPI().mainShop.userFetch(
        "assettypelist",
        {
            query_application,
            query_parent,
        },
        errorHandler
    );
};
export default getAssetTypeList;
