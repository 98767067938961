import React, {Component} from "react";
import ErrorIcon from '@material-ui/icons/Error';
import i18n from "i18next";
import k from "../../i18n/keys";

const DashboardErrorView = () => {
    return <div className="d-flex justify-content-center align-items-center w-100 h-100"><div>
            <ErrorIcon fontSize={'large'}/><br/>
            <p>{i18n.t(k.DASHBOARD_ITEM_CRASH)}</p></div>
    </div>;
};

class DashboardItemErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    componentDidCatch(error, info) {
        console.error(error, info)
        this.setState({hasError: true});
    }

    render() {
        if (this.state.hasError) {
            return <DashboardErrorView/>;
        }
        return this.props.children;
    }
}
class DashboardErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    componentDidCatch(error, info) {
        console.error(error, info)
        this.setState({hasError: true});
    }
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
    render() {
        if (this.state.hasError) {
            return <DashboardErrorView/>;
        }
        return this.props.children;
    }
}

export {DashboardItemErrorBoundary, DashboardErrorView, DashboardErrorBoundary};
