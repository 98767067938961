import i18n from "i18next";
import k from "./../../i18n/keys";
export default function () {
    let items = [];
    let access = localStorage.getItem("access");
    //if (access?.indexOf('orders') >= 0) {
    items.push.apply(items, [
        {
            title: i18n.t(k.AGREEMENTS),
            to: "/agreements/all",
            htmlBefore:
                '<i class="fas fa-handshake"></i>&nbsp;&nbsp;&nbsp;&nbsp;',
            htmlAfter: "",
        },

        // {
        //   title: i18n.t(k.BUILDINGS),
        //   to: "/buildings",
        //   htmlBefore: '<i class="fas fa-building fa-fw"></i>&nbsp;&nbsp;&nbsp;&nbsp;',
        //   htmlAfter: "" },

        {
            title: i18n.t(k.PROPERTIES),
            to: "/landplots",
            htmlBefore:
                '<i class="fas fa-building fa-fw"></i>&nbsp;&nbsp;&nbsp;&nbsp;',
            htmlAfter: "",
        },

        {
            title: i18n.t(k.PORTFOLIOS),
            to: "/portfolios",
            htmlBefore:
                '<i class="fas fa-city fa-fw"></i>&nbsp;&nbsp;&nbsp;&nbsp;',
            htmlAfter: "",
        },

        // {
        //   title: "Tenants",
        //   to: "/tenants",
        //   htmlBefore: '<i class="fas fa-users fa-fw"></i>&nbsp;&nbsp;&nbsp;&nbsp;',
        //   htmlAfter: "" },
        //
        // {
        //   title: "Users",
        //   to: "/users",
        //   htmlBefore: '<i class="fas fa-users-cog fa-fw"></i>&nbsp;&nbsp;&nbsp;&nbsp;',
        //   htmlAfter: "" },

        // {
        //     title: "Shareholders",
        //     to: "/all_shareholders",
        //     htmlBefore: '<i class="fas fa-users-cog fa-fw"></i>&nbsp;&nbsp;&nbsp;&nbsp;',
        //     htmlAfter: ""
        // },
        // {
        //     title: i18n.t(k.ACCOUNTING),
        //     to: "/accounting",
        //     htmlBefore:
        //         '<i class="fas fa-money-bill-wave fa-fw"></i>&nbsp;&nbsp;&nbsp;&nbsp;',
        //     htmlAfter: "",
        // },

        // {
        //     title: i18n.t(k.REPORTS),
        //     to: "/reports",
        //     htmlBefore:
        //         '<i class="fas fa-clipboard fa-fw"></i>&nbsp;&nbsp;&nbsp;&nbsp;',
        //     htmlAfter: "",
        // },
        // {
        //     title: "Safety videos",
        //     to: "/safety-videos",
        //     htmlBefore:
        //         '<i class="fas fa-file-video fa-fw"></i>&nbsp;&nbsp;&nbsp;&nbsp;',
        //     htmlAfter: "",
        // },
        // {
        //     title: "Image gallery",
        //     to: "/images",
        //     htmlBefore:
        //         '<i class="fas fa-file-image fa-fw"></i>&nbsp;&nbsp;&nbsp;&nbsp;',
        //     htmlAfter: "",
        // },
        // {
        //     title: i18n.t(k.RESERVATIONS),
        //     to: "/reservations",
        //     htmlBefore:
        //         '<i class="fas fa-calendar-alt fa-fw"></i>&nbsp;&nbsp;&nbsp;&nbsp;',
        //     htmlAfter: "",
        // },
        // {
        //     title: i18n.t(k.USER_GROUPS),
        //     to: "/roles",
        //     htmlBefore:
        //         '<i class="fas fa-users fa-fw"></i>&nbsp;&nbsp;&nbsp;&nbsp;',
        //     htmlAfter: "",
        // },
        {
            title: i18n.t(k.LOGOUT),
            to: "/logout",
            htmlBefore:
                '<i class="fas fa-sign-out-alt fa-fw"></i>&nbsp;&nbsp;&nbsp;&nbsp;',
            htmlAfter: "",
        },
    ]);

    //  }

    return items;
}
