import i18n from 'i18next';import k from "./../../i18n/keys";import React, { Component } from 'react';
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataTable from "react-data-table-component";
import 'react-confirm-alert/src/react-confirm-alert.css';
import HonkioAPI from "../../middlewares/HonkioAPI";
import { Button } from "shards-react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";

const Circular = () =>
<div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>;


class AgreementListOneComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true };

    this.save = this.save.bind(this);
    this.loadAgreement = this.loadAgreement.bind(this);
  }

  componentDidMount() {
    let agreementId = this.props.match.params.agreementId;
    this.loadAgreement(agreementId);
  }

  loadAgreement(agreementId) {
    let that = this;
    let merchantgetagreement = async function () {
      let response = await HonkioAPI().userShop.userFetch('merchantagreementlist', {
        "query_id": agreementId });

      // console.log(response);
      let agreements = response.rents;
      that.setState({ 'agreement': agreements[0], 'loading': false });
    };
    merchantgetagreement();
  }

  save() {
    let that = this;
    let saveAgreement = async function () {
      // console.log(that.state);
      let params = {
        ...that.state.agreement };

      params['state'] = 'email_sent';
      params['send_email'] = true;
      params['id'] = params._id;
      // console.log(params);
      let response = await HonkioAPI().userShop.userFetch('merchantagreementset', params);
      NotificationManager.success(i18n.t(k.AGREEMENT_SENT));
    };
    saveAgreement();
  }

  render() {
    let that = this;
    if (this.state.agreement) {
      // console.log(this.state);
    }

    if (this.state.agreement) {
      let saveButton = <Button onClick={this.save}>{i18n.t(k.SEND_AGREEMENT_TO_TENANT)}</Button>;
      return (
        <div>
                    <div className="container-fluid">
                    <PageHeaderComponent
            title={`${i18n.t(k.AGREEMENT)}: ${this.state.agreement.name}`}
            subtitle={i18n.t(k.OVERVIEW)} />

                </div>
                    <CardWrapperComponent
          header={true}
          footer={true}
          card_title={`${i18n.t(k.AGREEMENT)}: ${this.state.agreement.name}`}>
                        <div className="row">
                            <div className="list-group col-md-6">
                                <li className="list-group-item pl-3 list-group-item">
                                    {i18n.t(k.AGREEMENT_TITLE)} {this.state.agreement.name}
                                </li>
                                <li className="list-group-item pl-3 list-group-item">
                                    {i18n.t(k.AGREEMENT_DESCRIPTION)} {this.state.agreement.description}
                                </li>
                                <li className="list-group-item pl-3 list-group-item">
                                    {i18n.t(k.AGREEMENT_CONTRACT_TYPE)}: {this.state.agreement.rules.contract_type ?
                  this.state.agreement.rules.contract_type.value : ''}
                                </li>
                                <li className="list-group-item pl-3 list-group-item">
                                    {i18n.t(k.AREA_RESERVABLE)} {this.state.agreement.reservable}
                                </li>
                                <li className="list-group-item pl-3 list-group-item">
                                    {i18n.t(k.AGREEMENT_CREATED_AT)} {this.state.agreement.created_at}
                                </li>
                                <li className="list-group-item pl-3 list-group-item">
                                    {i18n.t(k.AGREEMENT_START_DATE)} {this.state.agreement.rules.start_date ?
                  this.state.agreement.rules.start_date.value : ''}
                                </li>
                                <li className="list-group-item pl-3 list-group-item">
                                    {i18n.t(k.AGREEMENT_END_DATE)} {this.state.agreement.rules.end_date ?
                  this.state.agreement.rules.end_date.value : ''}
                                </li>
                                <li className="list-group-item pl-3 list-group-item">
                                    {i18n.t(k.SIGNED_DATE)} {this.state.agreement.rules.signed_date ?
                  this.state.agreement.rules.signed_date.value : ''}
                                </li>
                                <li className="list-group-item pl-3 list-group-item">
                                    {i18n.t(k.RESIGN_DATE)} {this.state.agreement.rules.resign_date ?
                  this.state.agreement.rules.resign_date.value : ''}
                                </li>
                                <li className="list-group-item pl-3 list-group-item">
                                    {i18n.t(k.RELEASE_PERIOD)} {this.state.agreement.rules.release_period ?
                  this.state.agreement.rules.release_period.value : ''}
                                </li>
                                {saveButton}
                            </div>
                            <div className="list-group col-md-6">
                                <li className="list-group-item pl-3 list-group-item">
                                    {i18n.t(k.AGREEMENT_ADDITIONAL_TEXT)} {this.state.agreement.rules.additional_text ?
                  this.state.agreement.rules.additional_text.value : ''}
                                </li>
                                <li className="list-group-item pl-3 list-group-item">
                                    {i18n.t(k.AGREEMENT_RENT_AMOUNT)} {this.state.agreement.rules.rent_amount ?
                  this.state.agreement.rules.rent_amount.value : ''}
                                </li>
                                <li className="list-group-item pl-3 list-group-item">
                                    {i18n.t(k.AMOUNT_MAINTENANCE)} {this.state.agreement.rules.amount_maintenance ?
                  this.state.agreement.rules.amount_maintenance.value : ''}
                                </li>
                                <li className="list-group-item pl-3 list-group-item">
                                    {i18n.t(k.AMOUNT_RENT_TAX)} {this.state.agreement.rules.amount_tax ?
                  this.state.agreement.rules.amount_tax.value : ''}
                                </li>
                                <li className="list-group-item pl-3 list-group-item">
                                    {i18n.t(k.AMOUNT_RENT_ANNUAL_INCREASE_MIN)} {this.state.agreement.rules.amount_annual_increase_min ?
                  this.state.agreement.rules.amount_annual_increase_min.value : ''}
                                </li>
                                <li className="list-group-item pl-3 list-group-item">
                                    {i18n.t(k.AMOUNT_RENT_ANNUAL_INCREASE)} {this.state.agreement.rules.amount_annual_increase ?
                  this.state.agreement.rules.amount_annual_increase.value : ''}
                                </li>
                                <li className="list-group-item pl-3 list-group-item">
                                    {i18n.t(k.RENT_AREA)} {this.state.agreement.rules.leased_area ?
                  this.state.agreement.rules.leased_area.value : ''}
                                </li>
                                <li className="list-group-item pl-3 list-group-item">
                                    {i18n.t(k.RENTED_AREA)} <ol>{this.state.agreement.rules.rented_areas ?
                                    (this.state.agreement.rules.rented_areas || '').value.split('|').map((item) => <li>{item}</li>)
                                    : ''}</ol>
                                </li>
                                <li className="list-group-item pl-3 list-group-item">
                                    {i18n.t(k.TENANT_EMAIL)} {this.state.agreement.rules.tenant_email ?
                  this.state.agreement.rules.tenant_email.value : ''}
                                </li>
                                <li className="list-group-item pl-3 list-group-item">
                                    {i18n.t(k.TENANT_NAME)} {this.state.agreement.rules.tenant_name ?
                  this.state.agreement.rules.tenant_name.value : ''}
                                </li>
                                <li className="list-group-item pl-3 list-group-item">
                                    {i18n.t(k.BUILDING_NAME)} {this.state.agreement.rules.building_name ?
                  this.state.agreement.rules.building_name.value : ''}
                                </li>
                                 <li className="list-group-item pl-3 list-group-item">
                                    {i18n.t(k.AGREEMENT_STATE)} {this.state.agreement.state}
                                </li>

                            </div>
                        </div>
                    </CardWrapperComponent>
                </div>);

    }
    return (
      <Circular />);

  }}


function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = dispatch => ({});


export default connect(mapStateToProps, mapDispatchToProps)(AgreementListOneComponent);

