import i18n from "i18next";
import k from "./../../../i18n/keys";
import React, { Component } from "react";
import CardWrapperComponent from "../../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataTable from "react-data-table-component";
import DeleteForever from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Button } from "shards-react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import NotificationManager from "react-notifications/lib/NotificationManager";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import ProjectEditModalComponent from "./ProjectEditModalComponent";
import PageHeaderComponent from "../../ui_utils/PageHeaderComponent";
import { Link } from "react-router-dom";

const Circular = () => (
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

class ProjectsListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            projects: [],
            buildingId: this.props.match.params.buildingId,
            modal: {
                visible: false,
                project: null,
            },
        };
        this.loadProjects = this.loadProjects.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        this.loadProjects();
    }

    confirmDelete(buidling) {
        let that = this;
        const options = {
            title: "Destructive action",
            message: "",
            childrenElement: () => (
                <p>
                    {i18n.t(k.ARE_YOU_SURE_WANT_TO_DELETE1)}{" "}
                    <b>{buidling.name}</b>
                    {i18n.t(k._4)}
                </p>
            ),
            buttons: [
                {
                    label: i18n.t(k.YES),
                    onClick: () => {
                        const del = async () => {
                            that.setState({ loading: true });
                            let response = await HonkioAPI().userShop.userFetch(
                                "merchantbuildingprojectset",
                                {
                                    id: buidling.id,
                                    delete: true,
                                }
                            );

                            if (response.status === "accept") {
                                NotificationManager.success(
                                    i18n.t(k.LOADING),
                                    i18n.t(k.PROJECT_HAS_BEEN_REMOVED)
                                );
                            } else {
                                NotificationManager.success(
                                    `${response.description}`,
                                    i18n.t(k.DELETE_FAILED)
                                );
                            }
                            that.loadProjects();
                        };
                        del();
                    },
                },
                {
                    label: i18n.t(k.NO),
                    onClick: () => {},
                },
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        };
        confirmAlert(options);
    }

    loadProjects() {
        let that = this;
        let merchantgetprojects = async function () {
            let query = {
                merchant_id: localStorage.getItem("merchant_id"),
            };
            if (that.props.match.params.buildingId) {
                query["building_id"] = that.props.match.params.buildingId;
            }
            if (that.props.match.params.landplotId) {
                query["landplotId"] = that.props.match.params.landplotId;
            }
            let response = await HonkioAPI().userShop.userFetch(
                "merchantbuildingprojectlist",
                query
            );
            let buildingsResponse = await HonkioAPI().userShop.userFetch(
                "merchantassetlist",
                { query_id: that.props.match.params.buildingId }
            );
            let projects = response.projects;
            that.setState({
                projects: projects,
                building_name: buildingsResponse.assets[0]
                    ? buildingsResponse.assets[0].name
                    : "",
                loading: false,
            });
        };
        merchantgetprojects();
    }

    getColumns() {
        let that = this;
        return [
            {
                name: i18n.t(k.NAME),
                selector: "name",
                sortable: true,
                style: `cursor: pointer;`,
            },

            {
                name: i18n.t(k.DESCRIPTION),
                selector: "description",
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.BUDGET1),
                selector: "budget",
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.ACTION),
                selector: "action",
                cell: function (d) {
                    return (
                        <span>
                            <Link
                                to="#"
                                className="pointer"
                                onClick={() => {
                                    that.showModal(d);
                                }}
                            >
                                <EditIcon />
                            </Link>
                            <Link to={`/project/${d.id}`} className="pointer">
                                <VisibilityIcon />
                            </Link>
                            <Link
                                to="#"
                                className="pointer"
                                onClick={() => {
                                    that.confirmDelete(d);
                                }}
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ];
    }

    hideModal() {
        this.setState({ modal: { ...this.state.modal, visible: false } });
    }

    showModal(d) {
        this.setState({
            modal: { ...this.state.modal, visible: true, project: d },
        });
    }

    toggle() {
        this.setState({
            modal: { ...this.state.modal, visible: !this.state.modal.visible },
        });
    }

    render() {
        let that = this;
        return (
            <div>
                <div className="container-fluid">
                    <PageHeaderComponent title={i18n.t(k.PROJECTS)} />
                </div>
                <CardWrapperComponent
                    header={true}
                    footer={true}
                    card_title={
                        this.state.building_name ? (
                            this.state.building_name
                        ) : (
                            <CircularProgress size={24} />
                        )
                    }
                >
                    <Button
                        theme="info"
                        onClick={() => {
                            that.showModal(null);
                        }}
                    >
                        <AddIcon className="mr-2" />
                        {i18n.t(k.ADD)}
                    </Button>
                    <DataTable
                        autoWidth={true}
                        columns={this.getColumns()}
                        background="#ffffff00"
                        data={this.state.projects}
                        noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                        paginationComponentOptions={{
                            rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                            rangeSeparatorText: i18n.t(k.ROWS_OF),
                            noRowsPerPage: false,
                            selectAllRowsItem: false,
                            selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                        }}
                        progressPending={this.state.loading}
                        progressComponent={<Circular />}
                        onRowClicked={(e) =>
                            this.props.history.push(`/project/${e.id}`)
                        }
                    />
                </CardWrapperComponent>
                <ProjectEditModalComponent
                    visible={this.state.modal.visible}
                    open={this.hideModal}
                    toggle={this.toggle}
                    onRequestClose={this.props.onRequestClose}
                    project={this.state.modal.project}
                    buildingId={this.state.buildingId}
                    onProjectSaved={() => {
                        that.setState({ loading: true });
                        that.hideModal();
                        that.loadProjects();
                    }}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectsListComponent);
