import HonkioAPI from '../../../middlewares/HonkioAPI';
//used to delete user from user role group
const setMerchantUserRole = async function ({
    user_id,
    role_id,
    del
}, errorHandler) {
  const options = {
    user_id,
    role_id,
    delete: del
  };
  return HonkioAPI()
    .mainShop.userFetch('merchantuserroleset', {
      ...options
    }, errorHandler)
};
export default setMerchantUserRole;