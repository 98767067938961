import HonkioAPI from "./HonkioAPI";
import { consumerInfoSuccess } from "../actions/api/consumer/consumerGetActions";
import { APP_PACKAGE } from "../config";


function touMiddleware({ dispatch, getState }) {
  return next => action => {
   
    // if (!localStorage.getItem('token')) {
    //   return next(action);
    // }
    // if (action.type === "@@router/LOCATION_CHANGE") {
    //   if (action.payload.location && action.payload.location.pathname === '/') {

    //     const requestTou = async () => {
    //       let consumerget = await HonkioAPI().mainShop.userFetch('consumerget', {});

    //       // console.log('RESPONSE ORDER LIST JSON');
    //       // console.log(consumerget);

    //       dispatch(consumerInfoSuccess(consumerget));

    //       let consumer_info = consumerget;
    //       let version = 0;
    //       if (consumer_info?.hasOwnProperty('consumer_dict_accepted_tou_version')) {
    //         let accepted = consumer_info.consumer_dict_accepted_tou_version;
    //         if (accepted.hasOwnProperty(APP_PACKAGE) && accepted[APP_PACKAGE].hasOwnProperty('default')) {
    //           version = parseInt(accepted[APP_PACKAGE].default.version);
    //         }
    //       }
    //       let tou = await HonkioAPI().mainShop.userFetch('servergettou', {}, err => {
    //         console.error(err);
    //         return true;
    //       });
    //       let serverVersion = parseInt(tou?.version || 0);
    //       if (version >= serverVersion ) {
    //         localStorage.setItem('touAccepted', true);
    //       } else {
    //         localStorage.setItem('touAccepted', false);
    //         window.location.href = '/tou';
    //       }
    //     };
    //     requestTou();
    //   } else if (action.payload.location && action.payload.location.pathname !== '/tou') {
    //     // alert(typeof localStorage.getItem('touAccepted'))
    //     if ([null, false, 'false'].indexOf(localStorage.getItem('touAccepted')) >= 0) {
    //       console.error('zzzz');

    //       console.error(localStorage.getItem('touAccepted'));
    //       console.error(localStorage.getItem('touAccepted') !== true);
    //       window.location.href = '/tou';
    //     }
    //   }
    // }
    return next(action);
  };
}

export default touMiddleware;