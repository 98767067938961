import React from "react";
import { useMemo } from "react";
import DataTable from "react-data-table-component";
import i18n from "i18next";
import k from "./../../i18n/keys";
import EditIcon from "@material-ui/icons/Edit";
import GroupIcon from "@material-ui/icons/Group";
import Wait from "./../ui_utils/Wait";
import { Link } from "react-router-dom";

const Selector = ({
    items,
    selected,
    appid,
    loading,
    language,
    onClick = () => {},
}) => {
    const columns = useMemo(
        () => [
            {
                name: i18n.t(k.NAME),
                cell: (c) => {
                    const text = <span className="text-muted">-</span>;
                    const langText = c["display_name__" + language];
                    if (langText) {
                        return langText;
                    } else {
                        return c.display_name ?? text;
                    }
                },
            },
            {
                name: i18n.t(k.DESCRIPTION),
                cell: (c) => {
                    const text = (
                        <span className="text-muted">No description yet</span>
                    );
                    const langText = c["description__" + language];
                    if (langText) {
                        return langText;
                    } else {
                        return c.description ?? text;
                    }
                },
            },
            {
                name: i18n.t(k.ACTION),
                cell: (row) => {
                    return (
                        <span>
                            <Link
                                title={i18n.t(k.EDIT)}
                                id={"editTip" + row._id}
                                to={`/role/edit/?role_id=${row._id}`}
                                className="pointer"
                            >
                                <EditIcon />
                            </Link>
                            {/* <Link title={i18n.t(k.USERS)} id={"usersTip" + row._id} to={`/role/users/?role=${row._id}&role_name=${row.name}`} className="pointer"><GroupIcon /></Link> */}
                        </span>
                    );
                },
            },
        ],
        [appid]
    );

    const conditionalRowStyles = useMemo(
        () => [
            {
                when: (row) => row._id === selected?._id,
                style: {
                    backgroundColor: "AntiqueWhite",
                },
            },
        ],
        [selected]
    );
    return (
        <DataTable
            striped
            dense
            noHeader
            highlightOnHover
            data={items ?? []}
            columns={columns}
            conditionalRowStyles={conditionalRowStyles}
            onRowClicked={({ _id, name }) => onClick(_id, name)}
            progressPending={loading}
            progressComponent={
                <div className="p-5">
                    <Wait />
                </div>
            }
        />
    );
};

export default Selector;
