import { BACKEND_URL } from "../../config";
import {
    CLEAR_SAFETY_VIDEO_DATA,
    SET_SAFETY_VIDEO_DATA,
} from "../../reducers/SafetyVideosReducer";

export function isLoadingUtil(data_name) {
    return {
        type: `${data_name}_IS_LOADING`,
    };
}

export function PostRequestSuccessUtil(data_name, payload_name, json) {
    let result_obj = { type: `${data_name}_SUCCESS` };
    result_obj[payload_name] = json;
    return result_obj;
}

export function PostRequestFailureUtil(data_name, err) {
    return {
        type: `${data_name}_FAILURE`,
        err,
    };
}

export function clearStoreUtil(data_name) {
    return {
        type: `${data_name}_CLEAR`,
    };
}

export function PostRequestSendUtil(
    command,
    function_isLoading,
    function_success,
    function_failure,
    args
) {
    // console.log('Sending request to flask api!!!');
    return async function (dispatch) {
        dispatch(function_isLoading());
        try {
            let body_object = {
                ...args,
            };

            let response = await fetch(`${BACKEND_URL}${command}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },

                body: JSON.stringify(body_object),
            });

            if (!response.ok) {
                throw new Error(
                    `An Error during sending post request to ${command} has occurred, please try again.`
                );
            }
            let json = await response.json();
            return dispatch(function_success(json));
        } catch (err) {
            return dispatch(function_failure(err));
        }
    };
}

// export {isLoadingUtil, PostRequestSuccessUtil, PostRequestFailureUtil, clearStoreUtil, PostRequestSendUtil};

export function toggleSidebar() {
    return {
        type: "TOGGLE_SIDEBAR",
    };
}

export function openSafetyVideoModal(data) {
    return {
        type: SET_SAFETY_VIDEO_DATA,
        payload: data,
    };
}

export function closeSafetyVideoModal() {
    return {
        type: CLEAR_SAFETY_VIDEO_DATA,
    };
}
