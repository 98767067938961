import i18n from 'i18next'; import k from "./../../../../i18n/keys"; import React, { Component } from 'react';
import parse from 'html-react-parser';
import CardWrapperComponent from "../../../ui_utils/CardWrapperComponent";
import { Button } from "shards-react";
import PageHeaderComponent from "../../../ui_utils/PageHeaderComponent";

class AgreementReviewPdfComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let text;
    let button = <span />;
    if (this.props.text) {
      text = parse(this.props.text);
    }
    if (this.props.save) {
      button = <Button theme="primary" onClick={this.props.save}>{i18n.t(k.CREATE_TEMPLATE)}</Button>;
    }
    return (
      <div>
        <div className="container-fluid">
          <PageHeaderComponent
            title={i18n.t(k.SUMMARY)}
            subtitle={i18n.t(k.OVERVIEW)} />
        </div>
        <CardWrapperComponent
          header={true}
          footer={true}
          card_title={i18n.t(k.SUMMARY)}>
          {button}
          <hr />
          {text}
        </CardWrapperComponent>
      </div>);
  }
}

export default AgreementReviewPdfComponent;
