import i18n from "i18next";
import k from "./../../../i18n/keys";
import React, { Component } from "react";
import {
    Button,
    Form,
    FormGroup,
    FormInput,
    Modal,
    ModalBody,
    ModalHeader,
} from "shards-react";

import moment from "moment";
import "moment/locale/fi";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

class CommentModalComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        // console.log('cheems')
    }

    render() {
        if (!this.props.field) {
            return null;
        }
        return (
            <Modal
                open={this.props.visible}
                toggle={this.props.toggle}
                onRequestClose={this.props.onRequestClose}
                className="w-100 modal-lg modal-dialog-centered modal-dialog-scrollable"
            >
                <ModalHeader>{this.props.field.name} kommentti</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            {/* <span className={'col-6'}>{field.name} kommenti</span> */}
                            <FormInput
                                id={"comment_" + this.props.field.name}
                                name={this.props.field.name}
                                className="col-5"
                                value={
                                    this.props.data.data &&
                                    this.props.data.data[this.props.field.name]
                                        ? this.props.data.data[
                                              this.props.field.name
                                          ].comment
                                        : ""
                                }
                                onChange={this.props.handleCommentChangeEvent}
                            />
                        </FormGroup>
                    </Form>
                    <Button
                        onClick={() => {
                            this.props.onRequestClose();
                        }}
                    >
                        Save
                    </Button>
                </ModalBody>
            </Modal>
        );
    }
}

export default CommentModalComponent;
