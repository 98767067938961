import HonkioAPI from "../../../middlewares/HonkioAPI";

const isLoadingConsumer = () => ({
    type: "CONSUMER_INFO_IS_LOADING",
});

function consumerInfoSuccess(json) {
    return {
        type: "CONSUMER_INFO_SUCCESS",
        consumer_info: json,
    };
}

const consumerInfoFailure = (err) => ({
    type: "CONSUMER_INFO_FAILURE",
    err,
});

const clearConsumerInfo = () => ({
    type: "CONSUMER_INFO_CLEAR",
});

/*
message: {
    command: "consumerget"
    shop: shop._id
    login_identity: email
    login_password: otp/password (plaintext)
    pin_id: PIN xor bc25 (optional)
    str_pin_delays: delay1,delay2,delay3 (optional)
    device_id: device ID string (optional)
}
 */
function gettingConsumerInfo(access_token) {
    // console.log('Sending request to flask api!!!');
    return async function (dispatch) {
        dispatch(isLoadingConsumer());
        try {
            let response = await HonkioAPI().mainShop.userFetch(
                "consumerget",
                { access_token: access_token },
                (message) => {
                    consumerInfoFailure(message.description);
                    return false;
                }
            );
            return dispatch(consumerInfoSuccess(response));
        } catch (err) {
            return dispatch(consumerInfoFailure(err));
        }
    };
}

export {
    gettingConsumerInfo,
    clearConsumerInfo,
    consumerInfoSuccess,
    consumerInfoFailure,
};
