import i18n from 'i18next';import k from "./../../../../i18n/keys";import React, { Component } from 'react';
import CardWrapperComponent from "../../../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "shards-react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import NotificationManager from "react-notifications/lib/NotificationManager";
import HonkioAPI from "../../../../middlewares/HonkioAPI";
import JoditEditor from "jodit-react";
import PageHeaderComponent from "../../../ui_utils/PageHeaderComponent";

const Circular = () =>
// we need to add some padding to circular progress to keep it from activating our scrollbar
<div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>;


export default class BuildingCertEditComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      content: '' };

    this.loadTemplate = this.loadTemplate.bind(this);
    this.onChange = this.onChange.bind(this);
    this.save = this.save.bind(this);
  }

  onChange(newContent) {
    this.setState({ 'template': { message: newContent } });

  }

  componentDidMount() {
    let buildingId = this.props.match.params.buildingId;
    this.setState({ 'buildingId': buildingId });
    this.loadTemplate();
  }

  loadTemplate() {
    let that = this;
    let merchantbuildingcertset = async function () {
      let templateId = "5fce32aa51ee902d62f7fb8e";
      // let response = await HonkioAPI().userShop.userFetch('merchantmessagetemplateget', { "id": templateId });
      let response = await HonkioAPI().userShop.userFetch('merchantget', {});
      let template = await HonkioAPI().userShop.userFetch('merchantmessagetemplateget', {
          'id': response.merchant.id_building_cert_template
      });

      that.setState({ 'template': template.template, 'loading': false });
    };
    merchantbuildingcertset();
  }

  save = () => {
    let that = this;
    let merchantmessagetemplateset = async function () {
      let query = {};
      query['id'] = that.state.template._id;
      query['message'] = that.state.template.message;
      query['subject'] = "Rental Certificate";
      let response = await HonkioAPI().userShop.userFetch('merchantmessagetemplateset', query);
      // console.log(response);
      let doc = response.template;
      NotificationManager.success(i18n.t(k.BUILDING_CERT_SAVED));
    };
    merchantmessagetemplateset();
  };

  render() {
    let that = this;
    const config = { readonly: false };
    let editor = <Circular />;
    if (this.state.template) {
      editor = <JoditEditor
      value={this.state.template.message}
      config={config}
      tabIndex={1}
      onChange={newContent => that.setState({ 'template': { ...that.state.template, message: newContent } })}
      onBlur={newContent => that.setState({ 'template': { ...that.state.template, message: newContent } })} />;


    }

    return (
      <div>
                 <div className="container-fluid">
                    <PageHeaderComponent
          title={i18n.t(k.EDIT_BUILDING_CERTIFICATE_TEMP)}
          subtitle={i18n.t(k.OVERVIEW)} />

                </div>
                <CardWrapperComponent
        header={true}
        footer={true}
        card_title={i18n.t(k.EDIT_BUILDING_CERTIFICATE_TEMP)}>
                    {editor}
                    <Button className="mr-2 mt-4" theme="primary" onClick={() => that.save()}>{i18n.t(k.SAVE)}</Button>
                </CardWrapperComponent>
            </div>);



  }}
