import React from 'react';
import i18n from 'i18next';
import k from "./../../i18n/keys";
import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { formDataToOptions } from './helpers/form';
import CommonProperties from './elements/CommonProperties';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import setAdminRole from './../../actions/api/roles/setAdminRole';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CardWrapperComponent from './../ui_utils/CardWrapperComponent';

const [privateGroupName, publicGroupName] = ['__private', '__public'];

const Render = ({ defaultValues, role, appid, language }) => {
    const pageTitle = `${i18n.t(k.EDIT_ROLE)}`
    const history = useHistory();

    const { register, handleSubmit, control, reset } = useForm({
        defaultValues,
    });
    const {
        fields: fieldsPublic,
        append: appendPublic,
        remove: removePublic,
    } = useFieldArray({
        control,
        name: publicGroupName,
    });
    const {
        fields: fieldsPrivate,
        append: appendPrivate,
        remove: removePrivate,
    } = useFieldArray({
        control,
        name: privateGroupName,
    });

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    const onSubmit = (data) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success(i18n.t(k.ROLE_SAVED))
                history.goBack()
            } else {
                NotificationManager.error(i18n.t(k.ERROR))
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, i18n.t(k.ERROR) , 4000)
        }

        const multiLangCommonProperties = language
            ? Object.entries({ ...role })
                .filter(([key]) => {
                    return ['display_name', 'description'].includes(key.split('__')[0])
                }
                )
                .reduce((acc, [key, value]) => {
                    if (isArray(acc)) {
                        return { [acc[0]]: acc[1] }
                    }
                    else {
                        return ({ ...acc, [key]: value })
                    }
                })
            : {};
        const {
            description,
            display_name,
            name,
            group_code,
            property,
            access,
        } = data;
        const public_properties = formDataToOptions(data[publicGroupName]);
        const private_properties = formDataToOptions(data[privateGroupName]);
        const localLangCommonProperties = {};
        if (language) {
            localLangCommonProperties['display_name__' + language] = display_name;
            localLangCommonProperties['description__' + language] = description;
        } else {
            localLangCommonProperties['display_name'] = display_name;
            localLangCommonProperties['description'] = description;
        }
        const request = Object.assign(
            {},
            multiLangCommonProperties,
            localLangCommonProperties,
            {
                id: role._id,
                merchant: role.merchant,
                application: appid,
                name,
                property,
                access,
                group_code,
                public_properties,
                private_properties,
            }
        );
        function isArray(obj) {
            return !!obj && obj.constructor === Array;
        }
        setAdminRole(request, errorHandler)
            .then(data => {
                return resultHandler(data)
            })
            .catch(console.error);
    };

    const onDelete = () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success(i18n.t(k.ROLE_REMOVED))
                history.goBack();
            } else {
                NotificationManager.error(i18n.t(k.ERROR))
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, i18n.t(k.ERROR), 4000)
        }

        setAdminRole({ id: role._id, delete: true }, errorHandler)
            .then(data => {
                return resultHandler(data)
            })
            .catch(console.error);
    };
    
    return (<div>
        <NotificationContainer />
        <form onSubmit={handleSubmit(onSubmit)}>
            <CardWrapperComponent
                header={true}
                footer={true}
                card_footer={(
                    <div className="d-flex">
                        <button type="submit" className="btn btn-primary mr-3">
                            Submit
                        </button>
                        {false && <button type="button" className="btn btn-danger disabled"
                            onClick={() => {
                                onDelete()
                            }}>
                            Delete
                        </button>}
                    </div>
                )}
                card_title={pageTitle}>
                <CommonProperties
                    register={register}
                    className="col-md-6 col-lg-3"
                />
                <br />
            </CardWrapperComponent>
        </form>
    </div>
    );
};

export default Render;
