import i18n from 'i18next';import k from "./../../i18n/keys";import React, { Component } from 'react';
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import 'react-confirm-alert/src/react-confirm-alert.css';
import HonkioAPI from "../../middlewares/HonkioAPI";
import {  Bar } from 'react-chartjs-2';
import PageableDatatablesComponent from "../ui_utils/PageableDatatablesComponent";
import moment from "moment";
const Circular = () =>
// we need to add some padding to circular progress to keep it from activating our scrollbar
<div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>;


class AgreementsListNewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      landplotId: this.props.match ? this.props.match.params.landplotId : this.props.landplotId,
      loading: true,
      button_loading: true,
      agreements: [],
      loading_button: true
    };

    this.loadData = this.loadData.bind(this);
    this.rowSelected = this.rowSelected.bind(this);
  }


  componentDidMount() {
    this.loadData(0, this.props.landplotId)
  }

  rowSelected(o) {
    this.props.history.push(`/agreement/${o._id}`);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.landplotId) {

      this.loadData(0, nextProps.landplotId)
    }
  }

  loadData(page, landplotId) {
    let that = this;
    let building = landplotId;

    that.setState({'loading': true, landplotId: building});

    let merchantareaagreementlist = async function () {
      if (!page) page = 0;

      let query = {
        'query_skip': parseInt(page * 20),
        'query_count': 20
      };
      query['query_landplot'] = building

      let response = await HonkioAPI().userShop.userFetch('merchantareaagreementlist', query);
      let areas = response.areas;
      that.setState({ 'areas': areas, 'total': response.total, loading: false });
    };
    merchantareaagreementlist();
  }

  getColumns() {
    let that = this;
    return [
    {
      name: i18n.t(k.NAME),
      selector: 'name',
      sortable: true,
      style: `cursor: pointer;` },

    {
      //Monthly income
      name: i18n.t(k.TOTAL_PASSING_RENT),
      cell: function (d) {
        if (d.agreement) {
          if (d.agreement.rules.rent_amount) {
            return parseFloat(d.agreement.rules.rent_amount.value.replace(',', '.')).toFixed(2)
          }
        }
        return '-'
      },
      sortable: true,
      style: `cursor: pointer;`
    },
    {
      name: i18n.t(k.MARKET_RENT_EUR_M2),
      cell: function (d) {
        if (d.rules) {
          return d.rules.market_rent
        }
        return '-'
      },
      sortable: true,
      style: `cursor: pointer;`
    },
    {
      name: i18n.t(k.TOTAL_AREA),
      cell: function (d) {
        if (d.rules) {
          return d.rules.total_area
        }
        return '-'
      },
      sortable: true,
      style: `cursor: pointer;`
    },
    {
      name: i18n.t(k.MARKET_RENT_EUR),
      cell: function (d) {
        if (d.rules) {
          if (d.rules.market_rent && d.rules.total_area) {
            return parseFloat(d.rules.market_rent.replace(',', '.')) * parseFloat(d.rules.total_area.replace(',', '.'))
          }
        }
        return '-'
      },
      sortable: true,
      style: `cursor: pointer;`
    },
    {
      name: i18n.t(k.OVER_UNDER_RENT),
      cell: function (d) {
        if (d.rules && d.agreement && d.agreement.rules.rent_amount) {
          if (d.rules.market_rent && d.rules.total_area && d.agreement.rules.rent_amount.value) {
            return parseFloat(d.agreement.rules.rent_amount.value.replace(',', '.')) - (parseFloat(d.rules.market_rent.replace(',', '.')) * parseFloat(d.rules.total_area.replace(',', '.')))
          }
        }
        return '-'
      },
      sortable: true,
      style: d => {
        if (d.children[1] && typeof(d.children[1]) == 'number') {
          if (d.children[1] < 0) {
            return(`cursor: pointer;color: red;`)
          }
          else {
            return(`cursor: pointer;color: green;`)
          }
        }
        return(`cursor: pointer;`)
      }
    },
    { 
      name: i18n.t(k.AGREEMENT_LENGTH),
      sortable: true,
      style: `cursor: pointer;`,
      cell: function (d) {
          let length;
          if (d.agreement && d.agreement.rules.start_date && d.agreement.rules.end_date && d.agreement.rules.end_date.value) {
            let start = d.agreement.rules.start_date.value
            let end = d.agreement.rules.end_date.value
            length = parseInt(moment(end).diff(moment(start), 'months', true))
          }
          else if (d.agreement && d.agreement.rules.start_date && d.agreement.rules.notice_date && d.agreement.rules.notice_date.value) {
            let start = d.agreement.rules.start_date.value
            let end = d.agreement.rules.notice_date.value
            length = parseInt(moment(end).diff(moment(start), 'months', true))
          }
        if (length){
          return <span>{length} Month</span>;
        }
        return '-'
        
      } },
    // {
    //   name: i18n.t(k.AGREEMENT_DURATION),
    //   sortable: true,
    //   style: `cursor: pointer;`,
    //   cell: function (d) {
    //       let length;
    //       if (d.agreement && d.agreement.rules.end_date) {
    //         let end = d.agreement.rules.end_date.value
    //         length = parseInt(moment(end).diff(moment(), 'months', true))
    //       }
    //     return <span>{length} Month</span>;
    //   } 
    // },
    {
      name: i18n.t(k.TERMINATION_DATE),
      sortable: true,
      style: `cursor: pointer;`,
      cell: function (d) {
          let length;
          if (d.agreement && d.agreement.rules.notice_date && d.agreement.rules.notice_date.value) { // TERMINATION DATE
            length = moment(d.agreement.rules.notice_date.value).format()
            return <span>{length}</span>;
          }
          return '-'
      } },
    {
      name: i18n.t(k.TENANT_NAME),
      sortable: true,
      style: `cursor: pointer;`,
      cell: function (d) {
          let name = 'N/A';
          let expand;

          if (d.agreement && d.agreement.rules.tenant_name) {
            name = <span className="pointer text-primary" onClick={() => that.props.onTenantsClicked(d.agreement)}>{d.agreement.rules.tenant_name.value}, ...</span>
          } else  // What??
          if (d.agreement && d.agreement.rules.tenant_name && d.agreement.tenant_email) {
            name = <span className="pointer text-primary" onClick={() => that.props.onSingleTenantClicked(d.agreement.tenant_email.value)}>{d.agreement.rules.tenant_name.value}</span>
          }
        return <span>{name}</span>;
      } },
    {
      name: i18n.t(k.TENANT_RATING),
      sortable: true,
      style: `cursor: pointer;`,
      cell: function (d) {
          let name = 'N/A';
        return <span>-</span>;
      } },
    ];


  }

  render() {
    let that = this;

    let chartColors = {
      red: 'rgb(255, 99, 132)',
      orange: 'rgb(255, 159, 64)',
      yellow: 'rgb(255, 205, 86)',
      green: 'rgb(75, 192, 192)',
      blue: 'rgb(54, 162, 235)',
      purple: 'rgb(153, 102, 255)',
      grey: 'rgb(201, 203, 207)' };

    let optionsMain = {
      hover: {
        enabled: false 
      },

      title: {
        display: false,
        lineHeight: 2.5,
        text: ['Transactions orders amount', 'Click on a color box to hide/show a dataset'] 
      },

      legend: {
        display: false 
      },

      tooltips: {
        enabled: true 
      },

      scales: {
        xAxes: [
          {
          display: true,
          scaleLabel: {
            display: false,
            labelString: 'Month' 
            }
          }
        ] 
      }
    };

    let dataMain = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [{
          label: `${i18n.t(k.BUILDING)} 1`,
          backgroundColor: chartColors.red,
          borderColor: chartColors.red,
          data: [
          '4560',
          '3785',
          '5487',
          '4657',
          '2045',
          '9856',
          '10457'],
  
          fill: false },
        {
          label: `${i18n.t(k.BUILDING)} 2`,
          fill: false,
          backgroundColor: chartColors.blue,
          borderColor: chartColors.blue,
          data: [
          '5964',
          '4878',
          '7894',
          '2549',
          '3698',
          '2158',
          '1050'] },
  
        {
          label: `${i18n.t(k.BUILDING)} 3`,
          fill: false,
          backgroundColor: chartColors.green,
          borderColor: chartColors.green,
          data: [
          '9874',
          '8785',
          '8755',
          '6578',
          '8457',
          '9658',
          '5045'] }] };
    let dt = <PageableDatatablesComponent
              // title="Employees"
              className="border"
              noHeader={true}
              autoWidth={true}
              columns={this.getColumns()}
              onRowClicked={(d) => {
                that.props.onRowClicked(d, null);
              }}
              background="#ffffff00"
              items={this.state.areas}
              total={this.state.total}
              itemsPerPage={20}
              progressPending={this.state.loading}
              // onRowClicked={this.rowSelected}
              loadItems={page => {this.loadData(page);}} />;
    if (this.props.dt) {
      return dt
    }
    return (
      <div>
        <CardWrapperComponent
          header={true}
          footer={true}
          card_title="{i18n.t(k.Rentroll)}">
          <Bar
            data={dataMain}
            height={48}
            title="asd"
            options={{ optionsMain }} />
          {dt}
        </CardWrapperComponent>
      </div>);

  }
}


function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = dispatch => ({});


export default connect(mapStateToProps, mapDispatchToProps)(AgreementsListNewComponent);