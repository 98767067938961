import i18n from 'i18next';
import k from "../../i18n/keys";
import React, { Component } from 'react';
import { Button, Form, FormGroup, FormSelect, FormInput } from "shards-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import HonkioAPI from "../../middlewares/HonkioAPI";
import MapComponent from "../ui_utils/MapComponent";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import CenteredPageLoader from "../ui_utils/CenteredPageLoader";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { post } from "axios";
import { UPLOAD_URL } from "../../config";
import Select from "react-select";
import { GOOGLE_API_KEY } from "../../config.prod";
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";
import { preventRoboto } from "../../misc";
import { ASSET_TYPE } from '../../actions/api/assets/config';
//NOT USED
preventRoboto();

class RoomEditComponent extends Component {
    constructor(props) {
        super(props);
        this.save = this.save.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.handleChangeProperties = this.handleChangeProperties.bind(this)
        this.handleChangePropertiesProperties = this.handleChangePropertiesProperties.bind(this)
        this.handlePovChange = this.handlePovChange.bind(this);
        this.updatePreview = this.updatePreview.bind(this);
        this.getCompanyData = this.getCompanyData.bind(this);
        this.handleStreetPositionChange = this.handleStreetPositionChange.bind(this);
        this.state = {
            search: '',
            room: {},
            address: {},
            business_id: "",
            loading: true,
            pov: { 'heading': 1, 'pitch': 1 },
            streetCoords: { 'lat': 0, 'lon': 0 }
        };
    }

    componentDidMount() {
        let that = this;
        let roomId = this.props.match.params.roomId;
        let merchantgetroomsandprops = async function () {
            let responseRooms = await HonkioAPI().userShop.userFetch('merchantassetlist', {
                query_id: roomId,
                query_type: ASSET_TYPE.ROOM
            }, error => {
                that.setState({
                    loading: false,
                })
              })
            //let landplots = null
            if (responseRooms?.status === 'accept' && responseRooms.assets.length > 0) {
                let room = responseRooms.assets[0];
                that.setState({
                    'streetCoords': {
                        'lat': room?.latitude ?? 0.0,
                        'lon': room?.longitude ?? 0.0
                    },
                    room: room,
                    loading: false,
                    address: { address: room.properties.address },
                });
            } else {
                that.setState({
                    loading: false,
                });
            }
        };
        if (roomId && roomId !== 'new') {
            merchantgetroomsandprops();
        } else {
            that.setState({ 
                loading: false,
                room:
                {
                    structure: '',
                    type: 'room',
                    parent: that.props.match.params.parent,
                    properties: {
                        properties: {}
                    }
                },
            });
        }
    }

    /*handleChangeProperties(e, label = null) {
        if (e.target) {
            this.setState({
                'properties': {
                    ...this.state.properties,
                    [e.target.id]: e.target.value
                }
            });
        } else {
            this.setState({
                'properties': {
                    ...this.state.properties,
                    [label]: e.value
                }
            });
        }
    }*/

    handleChange = (e) => {
        if (e.target) {
            if (e.target.id === 'business_id' && e.target.value.length >= 6) {
                this.getCompanyData(e.target.value);
            }
            this.setState({
                room: {
                    ...this.state.room,
                    [e.target.id]: e.target.value
                }
            });
        }
    }
    handleChangeProperties(e) {
        if (e.target) {
            this.setState({
                room: {
                    ...this.state.room,
                    properties: {
                        ...this.state.room.properties,
                        [e.target.id]: e.target.value
                    }
                }
            });
            return
        }
    }
    handleChangePropertiesProperties(e, label = null) {
        if (e.target) {
            this.setState({
                room: {
                    ...this.state.room,
                    properties: {
                        ...this.state.room.properties,
                        properties: {
                            ...this.state.room.properties.properties,
                            [e.target.id]: e.target.value
                        }
                    }
                }
            });
        }else {
            this.setState({
                room: {
                    ...this.state.room,
                    properties: {
                        ...this.state.room.properties,
                        properties: {
                            ...this.state.room.properties.properties,
                            [label]: e.value
                        }
                    }
                }
            });
        }
    }
    blobToFile(theBlob, fileName) {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    save() {
        let that = this;
        this.setState({ 'loading': true });
        let saveroom = async function () {            
            const assetPropertiesProperties = {
                shares: that.state.room.properties.properties.shares,
                marketRentPrice: that.state.room.properties.properties.marketRentPrice,
                totalArea: that.state.room.properties.properties.totalArea,
                roomRights: that.state.room.properties.properties.roomRights,
                totalVolume: that.state.room.properties.properties.totalVolume,
                electricityConsumption: that.state.room.properties.properties.electricityConsumption,
                heatingEnergyConsumption: that.state.room.properties.properties.heatingEnergyConsumption,
                coolingEnergyConsumption: that.state.room.properties.properties.coolingEnergyConsumption,
                totalWaterConsumption: that.state.room.properties.properties.totalWaterConsumption,
                planDefinedrooms: that.state.room.properties.properties.planDefinedrooms,
                actualrooms: that.state.room.properties.properties.actualrooms,
                carPortPlaces: that.state.room.properties.properties.carPortPlaces,
                electricConnectorsToCars: that.state.room.properties.properties.electricConnectorsToCars,
                chargePlacesForCars: that.state.room.properties.properties.chargePlacesForCars,
                roomYearOfGraduation: that.state.room.properties.properties.roomYearOfGraduation,
                constructionPeriod: that.state.room.properties.properties.constructionPeriod,
                constructionRegulations: that.state.room.properties.properties.constructionRegulations,
                rentLeaseDate: that.state.room.properties.properties.rentLeaseDate,
                isLeased: that.state.room.properties.properties.isLeased,
                elevators: that.state.room.properties.properties.elevators,
                stairwells: that.state.room.properties.properties.stairwells,

                roomType: that.state.room.properties.properties.roomType,
                constructionMaterial: that.state.room.properties.properties.constructionMaterial,
                roofType: that.state.room.properties.properties.roofType,
                roofMaterial: that.state.room.properties.properties.roofMaterial,
                ownOrLeasedLand: that.state.room.properties.properties.ownOrLeasedLand,
                dataCabels: that.state.room.properties.properties.dataCabels,
                coolingSystem: that.state.room.properties.properties.coolingSystem,
                heatingSystem: that.state.room.properties.properties.heatingSystem,
                ventilationSystem: that.state.room.properties.properties.ventilationSystem,
                pov: that.state.pov,
            }
            const assetProperties = {
                description: that.state.room.properties.description,
                business_id: that.state.room.properties.business_id,
                property_manager: that.state.room.properties.property_manager,
                ...that.state.address,
                properties: assetPropertiesProperties,
            }
            let params = {
                merchant: localStorage.getItem('merchant_id'),
                name: that.state.room.name,
                address: that.state.address.address,
                properties: assetProperties,
                longitude: that.state.streetCoords.lon,
                latitude: that.state.streetCoords.lat,
                coordinates: [that.state.streetCoords.lat, that.state.streetCoords.lon],
                structure: that.state.room.structure,
                pov: that.state.pov,
                type: ASSET_TYPE.ROOM,
                parent: that.state.room.parent
            };
            if (that.state.room.id) {
                params['id'] = that.state.room.id;
            }
            let response = await HonkioAPI().userShop.userFetch('assetset', params, error => {
                that.setState({ loading: false });
            });
            if (response?.status === 'accept') {
                if (that.state.roomUrl) {
                    let blob = await fetch(that.state.roomUrl).then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.blob();
                    });
                    blob = that.blobToFile(blob, 'room.jpg');

                    let fileset = await HonkioAPI().userShop.userFetch('userfileset', {
                        'object': response.room.id,
                        'object_type': ASSET_TYPE.ROOM,
                        'access': 'public'
                    });
                    let data = {
                        'id': fileset.id,
                        'upload_token': fileset.upload_token
                    };
                    const formData = new FormData();
                    formData.append('file', blob, blob.name);
                    formData.append('message', new Blob([JSON.stringify(data)], {
                        type: 'application/json'
                    }));
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    };
                    let url, thumb;
                    await post(UPLOAD_URL, formData, config).then(uploadFile => {
                        url = uploadFile.data.url;
                        thumb = uploadFile.data.thumbnail_url;
                    }, error => {
                        NotificationManager.warning(i18n.t(k.UPLOAD_FAILED_IMAGE), `${error}`);
                        that.setState({ 'loading': false });
                    });
                    let setUrl = await HonkioAPI().userShop.userFetch('merchantassetset',
                        {
                            'id': response.room.id,
                            'custom_fields': { 'thumbUrl': thumb, 'url': url }
                        });
                    that.setState({ 'loading': false });
                    if (setUrl.status === 'accept') {
                        NotificationManager.success(i18n.t(k.ROOM_SAVED));
                        if (that.props.match.params.roomId === "new") {
                            that.props.history.push(`/floorplans/${response.room.id}`);
                        }
                        that.props.history.push(`/rooms/${that.props.match.params.parent}`);
                    }
                } else {
                    that.setState({ 'loading': false });
                    NotificationManager.success(i18n.t(k.ROOM_SAVED));
                    if (that.props.match.params.roomId === "new") {
                        that.props.history.push(`/rooms/${that.props.match.params.parent}`);
                    }
                    that.props.history.push(`/rooms/${that.props.match.params.parent}`);
                }
            } else {
                that.setState({ loading: false });
            }
        };
        saveroom();
    }

    handleAddressChange(name, value, lat, lon) {
        this.setState({ 'address': { ...this.state.address, [name]: value } });
    }

    handleStreetPositionChange(event) {
        let newLat = event.lat(),
            newLng = event.lng();
        let newState = {
            'pov': { ...this.state.pov },
            'streetCoords': { 'lat': newLat, 'lon': newLng }
        };
        this.setState(newState);
        this.updatePreview(newState);
    }

    handlePovChange(pov) {
        let newState = {
            'pov': pov,
            'streetCoords': { ...this.state.streetCoords }
        };
        this.setState(newState);
        this.updatePreview(newState);
    }

    updatePreview(newState) {
        let that = this;
        // if (this.imageTimer) {
        //     clearTimeout(this.imageTimer);
        // }
        // this.imageTimer = setTimeout(function () {
        //     that.setState({'plotUrl': `https://maps.googleapis.com/maps/api/streetview?size=710x400&location=${newState.streetCoords.lat},${newState.streetCoords.lon}&fov=85&heading=${newState.pov.heading}&pitch=${newState.pov.pitch}&key=${GOOGLE_API_KEY}`});
        //
        // }, 800);
    }

    getCompanyData(id) {
        let that = this;
        let INFO_URL = `https://avoindata.prh.fi/bis/v1/${id}`;
        let requestInfo = async function () {
            let response = await fetch(INFO_URL);
            try {
                let json = await response.json();
                if (json.results.length > 0) {
                    let data = json.results[0];
                    let phone = '';
                    data.contactDetails.forEach(function (contact) {
                        if (contact.value.startsWith('+')) {
                            phone = contact.value;
                        }
                    });
                    let address = data.addresses[data.addresses.length - 1];
                    that.setState({
                        search: [address.street, address.postCode, address.city].join(', '),
                        room: { ...that.state.room, name: data.name }
                    });
                }
            } catch (e) {
                console.error(e);
            }
        };
        requestInfo();
    }

    render() {
        let saveButton = <Button onClick={this.save}>{i18n.t(k.SAVE)}</Button>;
        if (this.state.loading) {
            saveButton = <CircularProgress size={45} />;
        }
        if (this.state.loading) {
            return <CenteredPageLoader />;
        }
        return (
            <div>
                <CardWrapperComponent
                    header={true}
                    footer={true}
                    card_title={i18n.t(k.CREATE_ROOM)}>
                    <div className="row">
                        <div className="col-sm-4">
                            <Form>
                                <FormGroup>
                                    <label htmlFor="name">{i18n.t(k.NAME)}</label>
                                    <FormInput id="name"
                                        value={this.state.room.name}
                                        onChange={e => this.handleChange(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor="properties.description">{i18n.t(k.DESCRIPTION)}</label>
                                    <FormInput id="description"
                                        value={this.state.room.properties?.description}
                                        onChange={e => this.handleChangeProperties(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor="address">{i18n.t(k.ADDRESS)}</label>
                                    <FormInput id="address"
                                        value={this.state.address.address}
                                        onChange={e => this.handleAddressChange(e.target.id, e.target.value)} />
                                </FormGroup>
                            </Form>
                        </div>
                        <div className="col-sm-8">
                            Map
                            {/* <MapComponent
                                handleAddressChange={this.handleAddressChange}
                                ref={mapRef => this.mapRef = mapRef}
                                // google={this.props.google}
                                search={this.state.search}
                                center={{
                                    lat: this.state.room && this.state.room.latitude ? this.state.room.latitude : 60.188332,
                                    lng: this.state.room && this.state.room.longitude ? this.state.room.longitude : 24.7675
                                }}
                                onPovChanged={this.handlePovChange}
                                onStreetPositionChanged={this.handleStreetPositionChange}
                                height='600px'
                                zoom={15} /> */}
                        </div>
                    </div>
                    <hr />
                    {saveButton}
                </CardWrapperComponent>
            </div>);
    }
}

export default RoomEditComponent;
