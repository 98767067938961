import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import { Line, Pie } from "react-chartjs-2";
import {
    Responsive as ResponsiveGridLayout,
    WidthProvider,
} from "react-grid-layout";
import HonkioAPI from "../../middlewares/HonkioAPI";
import WidgetComponent from "./widgets/WidgetComponent";
import TemperatureWidgetComponent from "./widgets/TemperatureWidgetComponent";
import { Button, ButtonGroup } from "shards-react";
import TouchWidgetComponent from "./widgets/TouchWidgetComponent";
import HumidityWidgetComponent from "./widgets/HumidityWidgetComponent";

import ProximityWidgetComponent from "./widgets/ProximityWidgetComponent";
import InvoiceProductEditModal from "../invoices/InvoiceProductAddModal";
import WidgetAddModal from "./addModal/WidgetAddModal";
import ClearIcon from "@material-ui/icons/Clear";
import SettingsIcon from "@material-ui/icons/Settings";
import AverageMaturityWidgetComponent from "./widgets/AverageMaturityWidgetComponent";
import TenantsWidgetComponent from "./widgets/TenantsWidgetComponent";
import TotalSquareWidgetComponent from "./widgets/TotalSquareWidgetComponent";
import RentalCostWidgetComponent from "./widgets/RentalCostWidgetComponent";
import {
    dashboardSuccess,
    loadDashboard,
    saveDashboard,
} from "../../actions/DashboardActions";
import CenteredPageLoader from "../ui_utils/CenteredPageLoader";
import BuildingInvoicesComponent from "./widgets/BuildingInvoicesComponent";
import WidgetSettingsModal from "./addModal/WidgetSettingsModal";
import BuildingIncomePieComponent from "./widgets/BuildingIncomePieComponent";
import {
    DashboardItemErrorBoundary,
    DashboardErrorView,
    DashboardErrorBoundary,
} from "./DashboardItemErrorBoundary";

import moment from "moment";
import "moment/locale/fi";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");
const ResponsiveReactGridLayout = WidthProvider(ResponsiveGridLayout);
const ROW_HEIGHT = 150;
const ROW_WIDTH = 125;
const Components = {
    temperature: TemperatureWidgetComponent,
    humidity: HumidityWidgetComponent,
    touch: TouchWidgetComponent,
    proximity: ProximityWidgetComponent,
    maturity: AverageMaturityWidgetComponent,
    tenants: TenantsWidgetComponent,
    square: TotalSquareWidgetComponent,
    rental_cost: RentalCostWidgetComponent,
    building_invoices: BuildingInvoicesComponent,
    building_income_pie: BuildingIncomePieComponent,
};

class DashboardComponent extends Component {
    constructor(props) {
        super(props);
        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.toggle = this.toggle.bind(this);

        this.showSettingsModal = this.showSettingsModal.bind(this);
        this.hideSettingsModal = this.hideSettingsModal.bind(this);
        this.toggleSettings = this.toggleSettings.bind(this);

        this.addWidget = this.addWidget.bind(this);
        this.loadDashboard = this.loadDashboard.bind(this);
        this.state = {
            loading: true,
            modal: {
                visible: false,
            },
            settingsModal: {
                visible: false,
            },
            dashboard: {
                positions: [],
                items: [],
            },
            period: i18n.t(k.MONTH3),
            start_date: moment()
                .subtract(2, "month")
                .format("YYYY-MM-DD hh:mm:ss"),
            end_date: moment().add(15, "days").format("YYYY-MM-DD hh:mm:ss"),
        };
    }

    showSettingsModal(i) {
        this.setState({
            settingsModal: {
                ...this.state.settingsModal,
                visible: true,
                index: i,
            },
        });
    }

    hideSettingsModal() {
        this.setState({
            settingsModal: { ...this.state.settingsModal, visible: false },
        });
    }

    toggleSettings() {
        this.setState({
            settingsModal: {
                ...this.state.settingsModal,
                visible: !this.state.settingsModal.visible,
            },
        });
    }

    showModal() {
        this.setState({
            ...this.state,
            modal: { ...this.state.modal, visible: true },
        });
    }

    hideModal() {
        this.setState({ modal: { ...this.state.modal, visible: false } });
    }

    toggle() {
        this.setState({
            modal: { ...this.state.modal, visible: !this.state.modal.visible },
        });
    }

    componentDidMount() {
        if (!this.props.dashboard.loaded || !this.props.dashboard.dashboard) {
            this.loadDashboard();
        } else {
            this.setState({
                loading: false,
                dashboard: this.props.dashboard.dashboard,
            });
        }
    }

    loadDashboard = () => {
        let that = this;
        that.setState({ loading: true });
        let usersettingsget = async function () {
            try {
                let json = await HonkioAPI().userShop.userFetch(
                    "usersettingsget",
                    {},
                    (message) => {
                        return false;
                    }
                );
                let dashboard;
                let key = `settings_${localStorage.getItem("merchant_id")}`;
                if (
                    json.settings &&
                    Object.keys(json.settings).length > 0 &&
                    json.settings[key]
                ) {
                    dashboard = { ...json.settings[key] };
                } else {
                    dashboard = {
                        positions: [
                            { w: 3, h: 1, x: 0, y: 0, i: i18n.t(k._3) },
                            { w: 3, h: 1, x: 3, y: 0, i: i18n.t(k._9) },
                            { w: 3, h: 1, x: 6, y: 0, i: i18n.t(k._10) },
                            { w: 3, h: 1, x: 12, y: 0, i: i18n.t(k._11) },
                        ],

                        items: [
                            {
                                type: i18n.t(k.MATURITY),
                                assetId: i18n.t(k.NONE),
                                name: i18n.t(k.AVERAGE_MATURITY),
                            },
                            {
                                type: i18n.t(k.TENANTS1),
                                assetId: i18n.t(k.NONE),
                                name: i18n.t(k.TENANTS),
                            },
                            {
                                type: i18n.t(k.SQUARE),
                                assetId: i18n.t(k.NONE),
                                name: i18n.t(k.RENTED_SQUARE),
                            },
                            {
                                type: i18n.t(k.RENTAL_COST),
                                assetId: i18n.t(k.NONE),
                                name: i18n.t(k.RENTAL_COST1),
                            },
                        ],
                    };
                }
                that.setState({ loading: false, dashboard: dashboard });
                that.props.dispatch(dashboardSuccess({ dashboard: dashboard }));
            } catch (e) {
                console.error(e);
            }
        };
        usersettingsget();
    };

    setPeriod = (period) => {
        let newState = {
            period: period,
        };
        switch (period) {
            case "day":
                newState = {
                    ...newState,
                    start_date: moment()
                        .subtract(1, "days")
                        .format("YYYY-MM-DD hh:mm:ss"),
                    end_date: moment().format("YYYY-MM-DD hh:mm:ss"),
                };
                break;
            case "week":
                newState = {
                    ...newState,
                    start_date: moment()
                        .subtract(7, "days")
                        .format("YYYY-MM-DD hh:mm:ss"),
                    end_date: moment().format("YYYY-MM-DD hh:mm:ss"),
                };
                break;
            case "month":
                newState = {
                    ...newState,
                    start_date: moment()
                        .subtract(1, "month")
                        .format("YYYY-MM-DD hh:mm:ss"),
                    end_date: moment().format("YYYY-MM-DD hh:mm:ss"),
                };
                break;
        }
        this.setState(newState);
    };

    onMoveCard = (newPosition) => {
        this.saveDashboard({
            positions: newPosition,
            items: [...this.state.dashboard.items],
        });
    };

    onResizeCard = (newPosition) => {
        this.saveDashboard({
            positions: newPosition,
            items: [...this.state.dashboard.items],
        });
    };

    removeWidet = (index) => {
        let positions = this.state.dashboard.positions;
        let items = this.state.dashboard.items;
        positions.splice(index, 1);
        items.splice(index, 1);
        this.saveDashboard({
            positions: [...positions],
            items: [...items],
        });
    };

    onWidgetRename = (index, newName) => {
        let items = this.state.dashboard.items;
        items[index]["name"] = newName;
        let newItems = [...items];
        let newDashboard = {
            positions: [...this.state.dashboard.positions],
            items: [...newItems],
        };
        this.saveDashboard(newDashboard);
    };

    addWidget(name, asset) {
        this.saveDashboard({
            positions: [
                ...this.state.dashboard.positions,
                {
                    w: 3,
                    h: 2,
                    x: 0,
                    y: 0,
                    i: this.state.dashboard.positions.length + 1,
                },
            ],
            items: [
                ...this.state.dashboard.items,
                { type: asset.group, assetId: asset.id, name: name },
            ],
        });

        this.hideModal();
    }

    saveDashboard = (dashboard) => {
        try {
            this.setState({ dashboard: dashboard });
            this.props.dispatch(dashboardSuccess({ dashboard: dashboard }));
            this.props.saveDashboard(dashboard);
        } catch (e) {
            console.error(e);
        }
    };

    onSaveSettingsClick = (newItems) => {
        let newDashboard = {
            positions: [...this.state.dashboard.positions],
            items: [...newItems],
        };
        this.saveDashboard(newDashboard);
        this.hideSettingsModal();
        this.props.dispatch(dashboardSuccess({ dashboard: newDashboard }));
    };

    createElem(name, params) {
        return Components[name]
            ? React.createElement(Components[name], { ...params })
            : null;
    }

    render() {
        if (this.state.loading)
            return (
                <DashboardErrorBoundary>
                    <CenteredPageLoader />
                </DashboardErrorBoundary>
            );

        // TODO: REFACTOR THIS!!
        if (this.state.dashboard) {
            return (
                <DashboardErrorBoundary>
                    <div className="m-3">
                        <h3>{i18n.t(k.DASHBOARD)}</h3>
                        <div className="d-flex">
                            <div className="ml-auto p-2">
                                <ButtonGroup className="mr-2">
                                    <Button
                                        disabled={true}
                                        theme={
                                            this.state.period === i18n.t(k.DAY)
                                                ? i18n.t(k.SECONDARY)
                                                : i18n.t(k.LIGHT)
                                        }
                                        onClick={() => this.setPeriod("day")}
                                    >
                                        {i18n.t(k.DAY1)}
                                    </Button>
                                    <Button
                                        disabled={true}
                                        theme={
                                            this.state.period === i18n.t(k.WEEK)
                                                ? i18n.t(k.SECONDARY)
                                                : i18n.t(k.LIGHT)
                                        }
                                        onClick={() => this.setPeriod("week")}
                                    >
                                        {i18n.t(k.WEEK1)}
                                    </Button>
                                    <Button
                                        disabled={true}
                                        theme={
                                            this.state.period ===
                                            i18n.t(k.MONTH3)
                                                ? i18n.t(k.SECONDARY)
                                                : i18n.t(k.LIGHT)
                                        }
                                        onClick={() => this.setPeriod("month")}
                                    >
                                        {i18n.t(k.MONTH4)}
                                    </Button>
                                </ButtonGroup>

                                <Link to="/sensors/none">
                                    <Button
                                        theme="secondary"
                                        className="ml-2 mr-2"
                                    >
                                        <SettingsIcon fontSize="small" />
                                    </Button>
                                </Link>
                                <Button
                                    theme="secondary"
                                    className="right"
                                    onClick={this.showModal}
                                >
                                    {i18n.t(k.CREATE_NEW_CARD)}
                                </Button>
                            </div>
                        </div>

                        <ResponsiveReactGridLayout
                            layouts={
                                this.state.dashboard &&
                                this.state.dashboard.positions
                                    ? this.state.dashboard.positions
                                    : []
                            }
                            // cols={12}
                            //       breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                            // cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
                            rowHeight={ROW_HEIGHT}
                            measureBeforeMount={true}
                            onBreakpointChange={this.onBreakpointChange}
                            onLayoutChange={this.onLayoutChange}
                            onDrop={this.onDrop}
                            isDragable={true}
                            isResizable={true}
                            preventCollision={false}
                            isDroppable={true}
                            onDrag={this.onDragging}
                            onDragStop={this.onMoveCard}
                            onResizeStop={this.onResizeCard}
                            rowHe
                        >
                            {this.state.dashboard.positions.map((p, i) => {
                                return (
                                    <div
                                        key={i}
                                        data-grid={{
                                            x: this.state.dashboard.positions[i]
                                                .x,
                                            y: this.state.dashboard.positions[i]
                                                .y,
                                            w: this.state.dashboard.positions[i]
                                                .w,
                                            h: this.state.dashboard.positions[i]
                                                .h,
                                        }}
                                    >
                                        {this.createElem(
                                            this.state.dashboard.items[i].type,
                                            {
                                                assetId:
                                                    this.state.dashboard.items[
                                                        i
                                                    ].assetId,
                                                name: this.state.dashboard
                                                    .items[i].name,
                                                position:
                                                    this.state.dashboard
                                                        .positions[i],
                                                height:
                                                    this.state.dashboard
                                                        .positions[i].h *
                                                    ROW_HEIGHT,
                                                width:
                                                    this.state.dashboard
                                                        .positions[i].w *
                                                    ROW_WIDTH,
                                                start_date:
                                                    this.state.start_date,
                                                end_date: this.state.end_date,
                                                settings:
                                                    this.state.dashboard.items[
                                                        i
                                                    ],
                                                onRemoveClick: () => {
                                                    this.removeWidet(
                                                        parseInt(i)
                                                    );
                                                },
                                                onSettingsClick: () => {
                                                    this.showSettingsModal(i);
                                                },
                                                onRename: (name) => {
                                                    this.onWidgetRename(
                                                        i,
                                                        name
                                                    );
                                                },
                                            }
                                        )}
                                    </div>
                                );
                                // ;
                            })}
                        </ResponsiveReactGridLayout>
                        <WidgetAddModal
                            visible={this.state.modal.visible}
                            open={this.toggle}
                            toggle={this.hideModal}
                            onRequestClose={this.hideModal}
                            addWidget={this.addWidget}
                        />
                        <WidgetSettingsModal
                            visible={this.state.settingsModal.visible}
                            open={this.toggleSettings}
                            toggle={this.hideSettingsModal}
                            onRequestClose={this.hideSettingsModal}
                            onSaveSettingsClick={this.onSaveSettingsClick}
                            items={this.state.dashboard.items}
                            index={this.state.settingsModal.index}
                        />
                    </div>
                </DashboardErrorBoundary>
            );
        } else {
            return (
                <DashboardErrorBoundary>
                    <div>{i18n.t(k.LOADING1)}</div>
                </DashboardErrorBoundary>
            );
        }
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({
    loadDashboard: () => dispatch(loadDashboard()),
    saveDashboard: (data) => dispatch(saveDashboard(data)),
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
