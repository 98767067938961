import HonkioAPI from './../../../middlewares/HonkioAPI';

const uploadFile = async function (options, errorHandler) {
    return HonkioAPI()
        .mainShop.userFetch('fileupload', {
            ...options,
        }, errorHandler)
};

export { uploadFile };
