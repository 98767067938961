import i18n from "i18next";
import k from "../../i18n/keys";
import React, { useState, useEffect } from "react";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import "react-confirm-alert/src/react-confirm-alert.css";
import DocumentUploadModalComponent from "../buildings/documents/DocumentUploadComponent";
import {
    NotificationManager,
    NotificationContainer,
} from "react-notifications";
import AddIcon from "@material-ui/icons/Add";
import FileBoxComponent from "../ui_utils/FileBoxComponent";
import { getFileList } from "../../actions/api/files/getFileList";
import useSearchString from "./../../utils/useSearchString";
import useLoader from "./../ui_utils/useLoader";
import getAsset from "../../actions/api/assets/getAssets";
import LoadingBar from "./../ui_utils/LoadingBar";
import { Modal, Button } from "react-bootstrap";
import { deleteFile } from "../../actions/api/files/setFile";
import VideoUploadComponent from "./subcomponents/VideoUploadComponent";
import VideoBoxComponent from "../ui_utils/VideoBoxComponent";
import { deleteMerchantFile } from "../../actions/api/files/setMerchantFile";
import { APP_ID } from "../../config";

const AssetVideos = () => {
    const { id: assetId } = useSearchString();
    const [assetInfo, setAssetInfo] = useState({});
    const [fileList, setFileList] = useState({});
    const [progress, done] = useLoader([fileList]);
    const [showAddFileModal, setShowAddFileModal] = useState(false);
    const handleShowAddFileModal = () => setShowAddFileModal(true);
    const handleCloseAddFileModal = () => setShowAddFileModal(false);

    const [showConfirmRemoveDocument, setShowConfirmRemoveDocument] = useState({
        visible: false,
        document: undefined,
    });
    const handleShowConfirmRemoveDocument = (document) =>
        setShowConfirmRemoveDocument({ visible: true, document: document });
    const handleCloseConfirmRemoveDocument = () =>
        setShowConfirmRemoveDocument({ visible: false, document: undefined });

    const [forceRefresh, setForceRefresh] = useState(0);

    let isMounted = true;

    const handleForceRefresh = () => {
        if (forceRefresh <= 1000) {
            setForceRefresh((prev) => prev + 1);
        } else {
            setForceRefresh(0);
        }
    };

    useEffect(() => {
        window.addEventListener("merchant_name", handleForceRefresh);

        return () => {
            window.removeEventListener("merchant_name", handleForceRefresh);
        };
    }, []);

    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        fetchAssetFiles();
    }, [assetId, forceRefresh]);

    const fetchAsset = async () => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    setAssetInfo(data.asset);
                } else {
                    NotificationManager.error("Error");
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setAssetInfo();
        };
        if (assetId) {
            getAsset({ id: assetId }, errorHandler)
                .then((data) => {
                    return resultHandler(data);
                })
                .catch(console.error);
        } else {
            setAssetInfo();
        }
    };

    const fetchAssetFiles = async () => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    setFileList(data.files);
                } else {
                    NotificationManager.error("Error");
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setFileList([]);
        };

        const merchant_id = localStorage.getItem("merchant_id");
        getFileList({ type: "video", object: merchant_id }, errorHandler)
            .then((data) => {
                return resultHandler(data);
            })
            .catch(console.error);
    };

    const deleteVideo = () => {
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                NotificationManager.success("Video deleted");
                onFileRemoved();
            } else {
                NotificationManager.error("Error");
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };
        // deleteMerchantFile(showConfirmRemoveDocument.document._id, errorHandler)
        deleteFile(showConfirmRemoveDocument.document._id, errorHandler)
            .then((data) => {
                return resultHandler(data);
            })
            .catch(console.error);
    };

    const onFileRemoved = () => {
        fetchAssetFiles();
        handleCloseConfirmRemoveDocument();
    };

    const forceDownload = (blob, filename) => {
        let a = document.createElement("a");
        a.download = filename;
        a.href = blob;
        document.body.appendChild(a);
        a.click();
        a.remove();
    };
    const onLoadFile = (file) => {
        NotificationManager.success(
            i18n.t(k.SUCCESS),
            i18n.t(k.DOCUMENT_DOWNLOADING_STARTED)
        );
        forceDownload(file.url, `${file.display_name}.${file.extension}`);
    };

    return (
        <div>
            <NotificationContainer />
            <br />
            <div>
                <CardWrapperComponent
                    add_class="mb-5"
                    header={true}
                    card_title={
                        done
                            ? assetInfo
                                ? `Videos: ${assetInfo.name}`
                                : i18n.t(k.NOT_FOUND)
                            : i18n.t(k.LOADING)
                    }
                >
                    {done ? (
                        <div className="col-lg-12 justify-content-center align-items-center">
                            <Button
                                className="ml-3 mb-4"
                                theme="info"
                                onClick={handleShowAddFileModal}
                            >
                                <AddIcon />
                                Upload video
                            </Button>
                            <VideoBoxComponent
                                data={fileList}
                                onDelete={(file) =>
                                    handleShowConfirmRemoveDocument(file)
                                }
                                onDownload={(file) => onLoadFile(file)}
                                onUploadClick={() => handleShowAddFileModal()}
                            />
                        </div>
                    ) : (
                        <LoadingBar progress={progress} />
                    )}
                </CardWrapperComponent>
                <VideoUploadComponent
                    visible={showAddFileModal}
                    toggle={handleCloseAddFileModal}
                    onRequestClose={handleCloseAddFileModal}
                    objectId={assetId}
                    objectType={"asset"}
                    onMediaUploaded={() => {
                        fetchAssetFiles();
                        handleCloseAddFileModal();
                    }}
                />
                {showConfirmRemoveDocument.document && (
                    <Modal
                        show={showConfirmRemoveDocument.visible}
                        onHide={handleCloseConfirmRemoveDocument}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Warning</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                Do you want to remove document{" "}
                                <b>
                                    {
                                        showConfirmRemoveDocument.document
                                            .display_name
                                    }
                                </b>
                                ?
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={handleCloseConfirmRemoveDocument}
                            >
                                {i18n.t(k.CLOSE)}
                            </Button>
                            <Button
                                variant="danger"
                                onClick={() => deleteVideo()}
                            >
                                {i18n.t(k.REMOVE)}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>
        </div>
    );
};
export default AssetVideos;
