import HonkioAPI from './../../../middlewares/HonkioAPI';

export const setAssetGroup = (options = {}, errorHandler = {}) => {
    const { group, merchantId } = options;
    return HonkioAPI().mainShop.userFetch('assetgroupset', {
        ...group,
        merchant: merchantId,
    }, errorHandler)
};

export const getAssetGroupList = (options = {}, errorHandler = {}) => {
    const { query_parent, query_list_assets, page = 0, query_count = 0 } = options;
    return HonkioAPI().mainShop.userFetch('assetgrouplist', {
        query_parent, query_list_assets,
        query_skip: +page * query_count,
        query_count,
    }, errorHandler)
};

export const getAssetGroup = (options = {}) => {
    const {
        id, query_list_assets
    } = options;
    return HonkioAPI().mainShop.userFetch('assetgroupget', {
        id, query_list_assets
    });
};