import HonkioAPI from "../../../middlewares/HonkioAPI";

/**
 *
 * @param {*} options - {object: string, type: string }
 * @param {*} options.object should be APP_ID for videos and images in gallery
 * @param {*} options.type - specify type to save on asset, used to load list of assets of same type; `image` for Images, `video` for videos, `document` for documents. It is possible to create custom `type` on the fly
 * @param {*} errorHandler - callback function, triggered on error in the response
 * @returns Promise
 */
export const getFileList = (options = {}, errorHandler = {}) => {
    const { object: query_object, type: query_type } = options;
    return HonkioAPI().mainShop.userFetch(
        "filelist",
        {
            query_object: query_object,
            query_type: query_type,
        },
        errorHandler
    );
};
