import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { routerMiddleware } from "react-router-redux";
import * as createHistory from "history";
import rootReducer from "./reducers";

import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import miscAPIRequestsMiddleware from "./middlewares/miscAPIRequestsMiddleware"; // defaults to localStorage for web
import { loadingBarMiddleware } from "react-redux-loading-bar";
import touMiddleware from "./middlewares/touMiddleware";

const DEVTOOLS_ENABLED = true;

const persistConfig = {
    key: "root",
    storage,
    // blacklist: ["safetyVideos"],
    blacklist: ["safetyVideos", "safetyTrack"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const history = createHistory.createBrowserHistory();
const routMiddleware = routerMiddleware(history);

const composeEnhancers =
    (process.env.NODE_ENV !== "production" &&
        DEVTOOLS_ENABLED &&
        typeof window !== "undefined" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

let store = createStore(
    persistedReducer,
    // rootReducer,
    composeEnhancers(
        applyMiddleware(
            routMiddleware,
            loadingBarMiddleware({
                promiseTypeSuffixes: ["LOADING", "SUCCESS", "FAILURE"],
            }),
            thunk,
            // logger,
            miscAPIRequestsMiddleware,
            touMiddleware
        )
    )
);

let persistor = persistStore(store);
export { store, history, persistor };
