import i18n from 'i18next';import k from "./../../i18n/keys";import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";

const CenteredPageLoader = () =>
<div className="container vh-100">
        <div className="row vh-100 justify-content-center align-items-center">
            <div>
                <h3 className="mb-1">{i18n.t(k.LOADING)}</h3>
                <CircularProgress size={75} />
            </div>
        </div>
    </div>;

export default CenteredPageLoader;