import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import * as createHistory from "history";

import oauthReducer from "./auth/oauthReducer";
// import createHistory from "history/createBrowserHistory";
import ConsumerGetReducer from "./api/consumer/ConsumerGetReducer";

import UserAccessListReducer from "./api/user/UserAccessListReducer";
import storage from "redux-persist/lib/storage";
import { loadingBarReducer } from "react-redux-loading-bar";
import editScheduleReducer from "./api/calendar/EditScheduleReducer";
import opentimesReducer from "./OpenTimesReducer";
import merchantGetReducer from "./MerchantGetReducer";
import setInviteReducer from "./setInviteRedurecer";
import dashboardReducer from "./DashboardReducer";
import closedaysReducer from "./CloseDaysReducer";
import navbarReducer from "./NavbarReducer";
import SafetyVideoReducer from "./SafetyVideosReducer";
import DirectoryReducer from "./DirectoryReducer";
import SafetyTrackReducer from "./SafetyTrackReducer";

const history = createHistory.createBrowserHistory();
const appReducer = combineReducers({
    router: connectRouter(history),
    dashboard: dashboardReducer,
    merchantInfo: merchantGetReducer,
    oauth: oauthReducer,
    consumer_info: ConsumerGetReducer,
    user_access: UserAccessListReducer,
    editShedule: editScheduleReducer,
    loadingBar: loadingBarReducer,
    opentimes: opentimesReducer,
    closedays: closedaysReducer,
    inviteResponse: setInviteReducer,
    navbar: navbarReducer,
    safetyVideos: SafetyVideoReducer,
    directory: DirectoryReducer,
    safetyTrack: SafetyTrackReducer,
});

const rootReducer = (state, action) => {
    if (action.type === "LOGOUT") {
        storage.removeItem("persist:root");
        storage.removeItem("root");
        const { router } = state;
        let new_state = { router };
        return appReducer(new_state, action);
    }
    return appReducer(state, action);
};
export default rootReducer;
