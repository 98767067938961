import i18n from 'i18next';
import k from "./../../i18n/keys";
import React, { Component } from 'react';
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import 'react-confirm-alert/src/react-confirm-alert.css';
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";
import data from "../invoices/product_types.json";
import Select from "react-select";
import HonkioAPI from "../../middlewares/HonkioAPI";
import { CircularProgress } from "@material-ui/core";
import { CSVLink } from "react-csv";
import { json2csv } from 'json-2-csv';
import { post } from "axios";
import { UPLOAD_URL } from "../../config";
import NotificationManager from "react-notifications/lib/NotificationManager";
import CenteredPageLoader from "../ui_utils/CenteredPageLoader";
import CenteredLoader from "../ui_utils/CenteredLoader";

export default class ReportsListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.loadBuildings = this.loadBuildings.bind(this);
    this.handleChangeBuilding = this.handleChangeBuilding.bind(this);
    this.upload = this.upload.bind(this);
  }

  componentDidMount() {
    this.loadBuildings();
  }

  loadBuildings() {
    let that = this;
    let merchantgetbuildings = async function () {
      let response = await HonkioAPI().userShop.userFetch('merchantbuildinglist', {});
      let buildings = response.buildings;
      let newState = {
        'buildings': buildings,
        'loading': false,
        'building_loaded': false };


      that.setState(newState);
    };
    merchantgetbuildings();
  }

  handleChangeBuilding(option) {
    this.setState({ 'building': option });
    // console.log(option);
    this.loadReport(option.value);
  }

  getTableShares(options) {
    return <div className="col-6">
      <h4>{i18n.t(k.LIABILITIES)}</h4>
      <table className="table border">
        <thead>
        <tr>
          <th scope="col" width="70%">{i18n.t(k.PRODUCT_CLASS)}</th>
          <th scope="col" width="30%">{i18n.t(k.AMOUNT)}</th>
        </tr>
        </thead>
        <tbody>
        {options}
        </tbody>
      </table>
    </div>;
  }

  getTableAssets(options) {
    return <div className="col-6">
      <h4>{i18n.t(k.ASSETS)}</h4>
      <table className="table border">
        <thead>
        <tr>
          <th scope="col" width="35%">{i18n.t(k.NAME)}</th>
          <th scope="col" width="35%">{i18n.t(k.PRODUCT_CLASS)}</th>
          <th scope="col" width="30%">{i18n.t(k.AMOUNT)}</th>
        </tr>
        </thead>
        <tbody>
        {options}
        </tbody>
      </table>
    </div>;
  }

  getTable(options, title) {
    return <div className="col-6">
      <h4>{title}{i18n.t(k._3)}</h4>
      <table className="table border">
        <thead>
        <tr>
          <th scope="col" width="70%">{i18n.t(k.TITLE1)}</th>
          <th scope="col" width="30%">{i18n.t(k.AMOUNT)}</th>
        </tr>
        </thead>
        <tbody>
        {options}
        </tbody>
      </table>
    </div>;
  }

  loadReport(buildingId) {
    let that = this;
    let report = async function () {
      let response = await HonkioAPI().userShop.userFetch('balancesheetreport', { "building_id": buildingId });
      if (response.warning) {
        return NotificationManager.warning(i18n.t(k.NO_INVOICES));
      }
      // console.log("RESPONSE");
      // console.log(response);
      Object.keys(data).forEach(function (key) {
        // get incomes product label
        response.outcome.forEach(outcome => {
          if (parseInt(key) === parseInt(outcome["Product class"])) {
            outcome["Product class"] = `${data[key].en} (${key})`;
          }
        });

        response.assets.forEach(asset => {
          if (parseInt(key) === parseInt(asset["class"])) {
            asset["class"] = `${data[key].en} (${key})`;
          }
        });
      });
      that.setState({
        ...that.state,
        "outcomes": response.outcome,
        "assets": response.assets,
        "rental": response.rental,
        "other": response.other,
        "building_loaded": true });

    };
    report();
  }

  upload(data) {
    let that = this;
    let callback = (err, csv) => {
      csv = csv.replace(/undefined/g, "");
      let contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      let file = new Blob([csv], { type: contentType });
      file.lastModifiedDate = new Date();
      file.name = i18n.t(k.REPORT_XLSX);

      let savefile = async function () {
        let params = {
          'title': "report",
          'file': file.name,
          'asset': that.state.asset,
          'type': "report" };



        let fileset = await HonkioAPI().userShop.userFetch('userfileset', {
          'object': that.state.building.value,
          'object_type': 'building',
          'access': 'private',
          'properties': params });



        let data = {
          'id': fileset.id,
          'upload_token': fileset.upload_token };


        // console.log(data);
        const formData = new FormData();
        formData.append('file', file, file.name);

        formData.append('message', new Blob([JSON.stringify(data)], {
          type: 'application/json' }));



        const config = {
          headers: {
            'content-type': 'multipart/form-data' } };




        let url, thumb;

        await post(UPLOAD_URL, formData, config).then(uploadFile => {
          url = uploadFile.data.url;
          thumb = uploadFile.data.thumbnail_url;
          NotificationManager.success(`${i18n.t(k.UPLOAD_SUCCESS)} report.csv`, i18n.t(k.SUCCESS));

        }, error => {
          that.setState({ 'loading': false });
          NotificationManager.warning(i18n.t(k.DOCUMENT_UPLOAD_FAILED), `${error}`);
        });
      };
      savefile();
    };

    json2csv(data, callback);
  }

  render() {
    let that = this;
    // console.log(this.state);
    let selectClass = i18n.t(k.D_BLOCK_MX_AUTO_W);
    let assets_table, other_table, rental_table, outcome_table, link, xls, body;
    let assets = [],assets_csv = [],rental = [],rental_csv = [],other = [],other_csv = [],outcomes = [],
    options = [],outcomes_csv = [],csv = [];
    let select = <CenteredLoader />;
    let headers = [
    { label: i18n.t(k.PRODUCT_CLASS), key: "Product class" },
    { label: i18n.t(k.AMOUNT), key: "Amount" }];

    if (this.state.buildings) {
      let buildings = this.state.buildings;
      buildings.forEach(function (building) {
        options.push({ value: building.id, label: building.name });
      });

      select = <Select placeholder={i18n.t(k.PLEASE_SELECT_A_BUILDING)} className={selectClass} id="building"
      onChange={this.handleChangeBuilding} options={options} />;
    }

    if (this.state.outcomes) {
      let totalOutcomes = 0;
      this.state.outcomes.forEach(outcome => {
        totalOutcomes += outcome["Amount"];
        outcomes.push(
        <tr>
            <td>{outcome['Product class']}</td>
            <td>{outcome["Amount"]} {i18n.t(k.EUR)}</td>
          </tr>);
        outcomes_csv.push({ "Amount": outcome['Amount'], "Product class": outcome['Product class'] });
      });
      outcomes.push(
      <tr>
          <td>{i18n.t(k.TOTAL)}</td>
          <td>{totalOutcomes} {i18n.t(k.EUR)}</td>
        </tr>);
    }

    if (this.state.assets) {
      this.state.assets.forEach(asset => {
        assets.push(
        <tr>
            <td>{asset['name']}</td>
            <td>{asset['class']}</td>
            <td>{asset["value"]} {i18n.t(k.EUR)}</td>
          </tr>);
        assets_csv.push({ "Amount": asset['value'], "Product class": asset['name'] });
      });
    }

    if (this.state.rental) {
      this.state.rental.forEach(rent => {
        rental.push(
        <tr>
            <td>{rent['title']}</td>
            <td>{rent['amount']} {i18n.t(k.EUR)}</td>
          </tr>);
        rental_csv.push({ "Amount": rent['amount'], "Product class": rent['title'] });
      });
    }

    if (this.state.other) {
      this.state.other.forEach(oth => {
        if (oth) {
          other.push(
          <tr>
            <td>{oth.products[0] ? oth.products[0].name : 'undefined'}</td>
            <td>{oth['amount']} {i18n.t(k.EUR)}</td>
          </tr>);
          other_csv.push({ "Amount": oth['amount'], "Product class": oth.products[0] ? oth.products[0].name : 'undefined' });
        }
      });
    }

    if (this.state.building_loaded) {
      assets_table = this.getTableAssets(assets);
      outcome_table = this.getTableShares(outcomes);
      rental_table = this.getTable(rental, "Rental invoices");
      other_table = this.getTable(other, "Other invoices");

      csv.push({ "Product class": "Liabilities:" }, ...outcomes_csv, { "": "" },
      { "Product class": "Assets:" }, ...assets_csv, { "": "" },
      { "Product class": "Rental:" }, ...rental_csv, { "": "" },
      { "Product class": "Other:" }, ...other_csv);

      xls =
      <CSVLink onClick={() => this.upload(csv)} className="btn btn-primary mr-2" filename={"report.xlsx"} data={csv}
      headers={headers}>{i18n.t(k.DOWNLOAD_XLSX)}</CSVLink>;
    }
    return (
      <div>
        <div className="container-fluid">
          <PageHeaderComponent
          title={i18n.t(k.REPORTS)} />
        </div>
        <CardWrapperComponent
        header={true}
        footer={true}
        card_title={i18n.t(k.BALANCE_SHEET)}>
          {select}
          <div className="d-flex flex-wrap w-100 pt-4">
            {outcome_table}
            {assets_table}
            {other_table}
            {rental_table}
          </div>
          {xls}
        </CardWrapperComponent>
      </div>);

  }}
