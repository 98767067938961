import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    FormGroup,
    FormInput,
    Button,
    ButtonGroup,
    ListGroup,
    ListGroupItem,
} from "shards-react";
import cn from "classnames";
import ArrowRight from "@material-ui/icons/ChevronRight";
import {
    clearSafetyTrack,
    setSafetyImage,
    setSafetyStep,
    setSelectedBuildingAssets,
} from "../../../actions/safety_track";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import NotificationManager from "react-notifications/lib/NotificationManager";
import i18n from "i18next";
import k, { PRIVATE, STORAGE } from "./../../../i18n/keys";
import ImageUploading from "react-images-uploading";
import ImageIcon from "@material-ui/icons/Image";
import { APP_ID } from "../../../config";
import { updateAssetData } from "../../../actions/api/assets/setAssetData";
import { useHistory } from "react-router-dom";
import ImagesListModal from "./ImagesListModal";

const SafetyTrackBuilder = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const safetyTrack = useSelector((state) => state.safetyTrack);
    const consumer_info = useSelector((state) => state.consumer_info);

    const [activeItem, setActiveItem] = useState(null);

    const [safetyTrackName, setSafetyTrackName] = useState("");

    const [showGalleryModal, setShowGalleryModal] = useState(false);
    const [galleryImage, setGalleryImage] = useState(null);

    const handleMouseOver = (e, item) => {
        setActiveItem(item);
    };

    const handleMouseLeave = (e, item) => {
        setActiveItem(null);
    };

    const checkIfItemActive = (item) => {
        return item.id === activeItem?.id;
    };

    // Function to update list on drop
    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        const updatedList = [...safetyTrack.selectedBuildingAssets];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        dispatch(setSelectedBuildingAssets(updatedList));
    };

    const onImageChange = (imageList, addUpdateIndex) => {
        dispatch(setSafetyImage(imageList));
        setGalleryImage(null);
        setShowGalleryModal(false);
        // setImages(imageList);
    };

    // TODO: Fix this method implementation
    const saveFile = async (file) => {
        const filename = file.name.split(".")[0];
        const filepath = file.path;
        const filetype = "image";
        const fileext = file.name.split(".")[1];
        const consumer_id = consumer_info?.consumer_info?.id || "-1";

        let message = {
            id: consumer_id,
            file: file,
            "content-type": "multipart/form-data",
            object: APP_ID,
            object_type: "asset",
            access: "public",
            file_type: "image",
            extension: fileext,
            display_name: filename,
            metadata: { document_type: "safety_track_map" },
        };

        try {
            const response = await HonkioAPI().userShop.userFetch(
                "fileupload",
                message
            );
            const url = response.file.url;
            const thumb = response.file.url;

            NotificationManager.success(
                `${i18n.t(k.UPLOAD_SUCCESS)} ${filename}`,
                i18n.t(k.SUCCESS)
            );
            return response;
        } catch (error) {
            console.log(`fileUpload error`, error);
            NotificationManager.warning(
                i18n.t(k.UPLOAD_FAILED_IMAGE),
                `${error.description}`
            );
            return {};
        }
    };

    const linkAsset = async (asset) => {
        const errorHandler = (error) => {
            NotificationManager.error(
                error.description,
                "Error: Safety track NOT added",
                4000
            );
        };

        try {
            const response = await updateAssetData(
                asset,
                localStorage.getItem(STORAGE.MERCHANT_ID),
                APP_ID,
                // SAFETY_TRACK_TYPE,
                errorHandler
            );

            NotificationManager.success(
                `Safety track added`,
                i18n.t(k.SUCCESS)
            );
        } catch (error) {
            console.log(`Error linking Safety track to building`, error);
            NotificationManager.error("Error adding Safety track");
        }
    };

    const handleCreateSafetyTrack = async () => {
        const parent = safetyTrack.selectedBuilding?._id || "";
        const imageToUpload = safetyTrack.images?.[0] || null;

        // Safety track object:
        //  - fields with names `1` to `30` store assets' ids
        //  - field image contains Safety track map

        const asset_ids = safetyTrack.selectedBuildingAssets.map(
            (item) => item.id
        );

        let assets = {};
        asset_ids.forEach((item, i) => (assets[i + 1] = item));

        let uploaded_url = "";
        // Upload Safety track map
        if (safetyTrack.images?.length) {
            // Upload only one image; use `map` if need to upload more
            const img = imageToUpload.file || null;
            if (img) {
                const response = await saveFile(img);
                uploaded_url = response.file.url;
            }
        }

        if (galleryImage) uploaded_url = galleryImage.url;

        const SAFETY_TRACK_TYPE = "Safety_track"; // TODO: Load list of types and get type for Safety track
        // N.B: merchant field for Safety track type is null. Use application id only to get

        const trackName =
            safetyTrackName ||
            `${
                safetyTrack.selectedBuilding?.name ||
                safetyTrack.selectedBuilding?._id
            } building Safety track`;

        const data = {
            type: SAFETY_TRACK_TYPE,
            name: trackName,
            parent,
            properties: { ...assets, image: uploaded_url },
        };

        await linkAsset(data);

        const parentBuildingId = safetyTrack.selectedBuilding._id;

        // Clear redux store data and redirect to Properties page
        dispatch(clearSafetyTrack());

        // history.push("/landplots");
        // Set slight delay on redirect to allow state update
        const timeoutId = setTimeout(() => {
            clearTimeout(timeoutId);
            history.push(`/directory/${parentBuildingId}`);
        }, 500);
        // history.push(`/directory/${parentBuildingId}`);
    };

    const handleShowGalleryModal = (e) => {
        setShowGalleryModal(true);
    };

    const handleHideGalleryModal = (e) => {
        setShowGalleryModal(false);
    };

    const handleSelectGalleryImage = (image) => {
        dispatch(setSafetyImage(null));
        setGalleryImage(image);
        setShowGalleryModal(false);
    };

    return (
        <div>
            <FormGroup className="mb-4">
                <label htmlFor="safety_track_name">Safety track name</label>
                <FormInput
                    id="safety_track_name"
                    value={safetyTrackName}
                    onChange={(e) => setSafetyTrackName(e.target.value)}
                />
            </FormGroup>

            <ImageUploading
                value={safetyTrack.images}
                onChange={onImageChange}
                maxNumber={1}
                dataURLKey="data_url"
            >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                        {safetyTrack.images?.length ? null : (
                            <Button
                                style={
                                    isDragging ? { color: "red" } : undefined
                                }
                                onClick={onImageUpload}
                                {...dragProps}
                                title="Drag or click here"
                                className="mb-2"
                            >
                                <ImageIcon className="me-3" />
                                &nbsp; &nbsp; Upload an image
                            </Button>
                        )}
                        &nbsp;
                        {/* <button onClick={onImageRemoveAll}>
                            Remove all images
                        </button> */}
                        {imageList.map((image, index) => (
                            <div
                                key={index}
                                className="image-item d-flex flex-column justify-content-center align-items-start"
                            >
                                <img
                                    src={image["data_url"]}
                                    alt=""
                                    width="100%"
                                />
                                <ButtonGroup
                                    className={cn(
                                        "image-item__btn-wrapper mt-3 mb-2"
                                    )}
                                >
                                    <Button
                                        theme="success"
                                        onClick={() => onImageUpdate(index)}
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        theme="danger"
                                        onClick={() => onImageRemove(index)}
                                    >
                                        Remove
                                    </Button>
                                </ButtonGroup>
                            </div>
                        ))}
                    </div>
                )}
            </ImageUploading>

            <label>or</label>

            <FormGroup className="mb-4">
                <Button className="mb-4" onClick={handleShowGalleryModal}>
                    <ImageIcon className="me-3" />
                    &nbsp; &nbsp; Select from gallery
                </Button>
            </FormGroup>

            {galleryImage ? (
                <img
                    src={galleryImage.url}
                    alt={galleryImage.display_name}
                    className="d-flex mb-4"
                    width="100%"
                />
            ) : null}

            {/* Set assets order inside Safety track */}
            <label htmlFor="safety_assets">Track items</label>
            <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="list-container">
                    {(provided) => (
                        <div
                            className="list-container list-group"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            id="safety_assets"
                        >
                            {safetyTrack.selectedBuildingAssets.map(
                                (item, index) => (
                                    <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={index}
                                    >
                                        {(provided, snapshot) => {
                                            const style = {
                                                backgroundColor:
                                                    snapshot.isDragging
                                                        ? "blue"
                                                        : "grey",
                                                ...provided.draggableProps
                                                    .style,
                                            };
                                            return (
                                                <div
                                                    className={cn(
                                                        "item-container list-group-item",
                                                        {
                                                            active:
                                                                checkIfItemActive(
                                                                    item
                                                                ) ||
                                                                snapshot.isDragging,
                                                        }
                                                    )}
                                                    style={style}
                                                    onMouseOver={(e) =>
                                                        handleMouseOver(e, item)
                                                    }
                                                    onMouseLeave={(e) =>
                                                        handleMouseLeave(
                                                            e,
                                                            item
                                                        )
                                                    }
                                                    ref={provided.innerRef}
                                                    {...provided.dragHandleProps}
                                                    {...provided.draggableProps}
                                                >
                                                    {index + 1}. {item.name}
                                                </div>
                                            );
                                        }}
                                    </Draggable>
                                )
                            )}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <h6 className="text-muted mt-2">
                Move items top to bottom to change order
            </h6>

            <Button className="mt-4" onClick={handleCreateSafetyTrack}>
                Confirm <ArrowRight />
            </Button>

            <ImagesListModal
                currentImage={galleryImage}
                isModalOpen={showGalleryModal}
                onClose={handleHideGalleryModal}
                callback={handleSelectGalleryImage}
            />
        </div>
    );
};

export default SafetyTrackBuilder;
