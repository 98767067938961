import React, {  useState } from 'react';
import { NotificationManager } from "react-notifications";
import InputFieldWithValidation from './../../form/elements/InputFieldWithValidation';
import { GOOGLE_API_KEY } from './../../../config';
import Map from './../../ui_utils/Map';
import AddressTableModal from './../AddressTableModal';

const baseGeoCodeURL = `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_API_KEY}&address=`;


const AddressWithMap = ({ name, label, k, required, address, className, latitude = 0, longitude = 0, onAddressChanged }) => {
    const [showAddressListModal, setShowAddressListModal] = useState(false);
    const handleShowAddressListModal = () => setShowAddressListModal(true);
    const handleCloseAddressListModal = () => setShowAddressListModal(false);
    const [addressList, setAddressList] = useState();
    const [addressInfo, setAddressInfo] = useState({
        address: address,
        latitude: latitude,
        longitude: longitude
    });
    const [prevAddress, setPrevAddress] = useState(address);

    function onAddressSelected(address) {
        handleCloseAddressListModal()
        let data = {
            address: address.formatted_address,
            latitude: address.geometry.location.lat,
            longitude: address.geometry.location.lng
        }
        setAddressInfo(data)
        sendOnAddressChanged(name, data)
    }
    
    const sendOnAddressChanged = (name, data) => {
        setPrevAddress(data ? data.address : '')
        onAddressChanged(name, data)
    }

    const onAddressFieldLostFocus = async (data) => {
        const newAddress = data.target.value.trim()
        if (prevAddress !== newAddress) {
            const requestOptions = {
                method: 'POST'
            };
            const adr = `${baseGeoCodeURL}"${newAddress}"`
            const response = await fetch(adr, requestOptions)
            const result = await response.json();
            //const result = ADDRESS_LIST
            if (result.status === 'OK' && result.results.length > 0) {
                if (result.results.length > 1) {
                    setAddressList(result.results)
                    handleShowAddressListModal()
                } else {
                    onAddressSelected(result.results[0])
                }
            } else {
                NotificationManager.warning(result.error_message || 'Geocode was not successful', 'Geocode error', 5000);
            }
        }
    }
    const onAddressFieldChange = (name, value) => {
        setAddressInfo({
            ...addressInfo,
            address: value
        })
    }
    // console.log(addressInfo,"---------addressInfo")
    return <div className={className} key={k}>
        <InputFieldWithValidation
            value={addressInfo.address}
            name={name}
            required={required}
            label={label}
            type='text'
            onChange={onAddressFieldChange}
            onLostFocus={onAddressFieldLostFocus}
        />
        <div className="card p-2 h-100">
            <Map latitude={addressInfo.latitude} longitude={addressInfo.longitude} />
        </div>
        {showAddressListModal && <AddressTableModal
            addressList={addressList}
            show={showAddressListModal}
            onAddressSelected={onAddressSelected}
            onHide={handleCloseAddressListModal}>
        </AddressTableModal>
        }
    </div>
};

export default AddressWithMap;