import {
    ADD_BREADCRUMB,
    GOTO_BREADCRUMB,
    REMOVE_BREADCRUMB,
    SET_BREADCRUMBS,
} from "../../reducers/DirectoryReducer";

/**
 * @param {*} asset - asset object, may be any property (building, floor, room etc) or sensor
 * @returns action
 */
export const addBreadcrumb = (asset) => {
    return {
        type: ADD_BREADCRUMB,
        payload: asset,
    };
};

/**
 *
 * @param {*} asset_id - id of any asset
 * @returns action
 */
export const removeBreadcrumb = (asset_id) => {
    return {
        type: REMOVE_BREADCRUMB,
        payload: asset_id,
    };
};

/**
 *
 * @param {*} breadcrumbs - array of any assets (building, floor, room etc) or sensor
 * @returns action
 */
export const setBreadcrumbs = (breadcrumbs) => {
    return {
        type: SET_BREADCRUMBS,
        payload: breadcrumbs,
    };
};

/**
 *
 * @param {*} asset_id - id of any asset that is in breadcrumbs
 * @returns action
 */
export const gotoBreadcrumb = (asset_id) => {
    return {
        type: GOTO_BREADCRUMB,
        payload: asset_id,
    };
};
