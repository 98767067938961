export const getExt = (filename) => {
    var ext = filename.split('.').pop();
    if (ext == filename) return "";
    return ext;
}


export const blobToFile = (theBlob, fileName) => {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
}