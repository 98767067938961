import i18n from 'i18next';
import k from "./../../../i18n/keys";
import React from 'react';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import timegrid from '@fullcalendar/timegrid';
import list from '@fullcalendar/list';
import { connect } from "react-redux";
import { getAreaSchedule, setEvent } from "../../../actions/api/calendar/EditScheduleActions";
import { Button, Form, FormGroup, FormInput, FormSelect, Modal, ModalBody, ModalHeader } from "shards-react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-notifications/dist/react-notifications.css';
import 'react-notifications/dist/react-notifications.js';
import HonkioAPI from "../../../middlewares/HonkioAPI";
import DatePickerComponent from "../../ui_utils/DatePickerComponent";
import { DateTimePicker } from "react-widgets";
import NotificationManager from "react-notifications/lib/NotificationManager";
import moment from "moment";
import "moment/locale/fi";
import { AGREEMENT_FIELDS } from '../../../../src/components/agreements/subcomponents/config';

class AgreementDatesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.calendarRef = React.createRef();
    this.hideModal = this.hideModal.bind(this);
    this.applyCallback = this.applyCallback.bind(this);
    this.onEventSubmit = this.onEventSubmit.bind(this);
    this.onCalendarDayClick = this.onCalendarDayClick.bind(this);
    this.onEventClick = this.onEventClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      'modal': {
        visible: false,
        start: moment(),
        end: moment().add(2, 'days'),
        dateStr: '',
        type: 'busy'
      },
      'modalEdit': {
        visible: false,
        start: moment(),
        end: moment().add(2, 'days'),
        dateStr: '',
        type: 'busy'
      },
      'initLoaded': "no",
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.agreement) {
      this.setState({ 'agreement': nextProps.agreement });
    }
    if (nextProps.agreement.reservable) {
      if (this.state.initLoaded === "no") {
        this.initAreaEventsSource();
        if (this.calendarRef.current) {
          let calendarApi = this.calendarRef.current.getApi();
          calendarApi.addEventSource(this.areaEventsSource);
          this.setState({ "initLoaded": "yes" });
        }
      }
    }
    if (nextProps.editShedule.setEventSucess) {
      this.setState({
        setEventSucess: false,
        setEventError: false,
        'modal': { ...this.state.modal, visible: false }
      });
      if (this.calendarRef.current) {
        let calendarApi = this.calendarRef.current.getApi();
        calendarApi.refetchEvents();
      }
    } else {
    }
  }

  onEventSubmit() {
    let areaId = this.props.agreement.area;
    let start = this.state.modal.start.format("YYYY-MM-DD HH:mm:ss");
    let end = this.state.modal.end.format("YYYY-MM-DD HH:mm:ss");
    let product = this.props.agreement.product;
    let type = "busy";
    let description = "Reserved";
    this.props.setEvent(areaId, start, end, type, description, product);
  }

  onEventClick(a) {
  }

  hideModal() {
    this.setState({ ...this.state.modal, visible: false });
  }

  toggle() {
    this.setState({
      'modal': {
        ...this.state.modal,
        visible: !this.state.modal.visible
      }
    });
  }

  applyCallback(start, end) {
    this.setState({
      'modal': {
        ...this.state.modal,
        dateStr: start.format("DD-MM-YYYY HH:mm") + ' - ' + end.format("DD-MM-YYYY HH:mm"),
        end: end,
        start: start
      }
    });
  }

  getRanges() {
    let now = new Date();
    let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    return {
      "Today": [moment(start), moment(end)],
      "Tomorrow": [moment(start).add(1, "days"), moment(end).add(1, "days")],
      "The day after tomorrow": [moment(start).add(2, "days"), moment(end).add(2, "days")],
      "3 Days": [moment(start).add(3, "days"), moment(end)]
    };
  }

  getEventTimeFormat() {
    return { // like '14:30:00'
      hour: '2-digit',
      minute: '2-digit',
      meridiem: false,
      hour12: false
    };
  }

  getEventTimeFormatSlot() {
    return { // like '14:30:00'
      hour: '2-digit',
      minute: '2-digit',
      meridiem: false,
      hour12: false
    };
  }

  onCalendarDayClick(date) {// bind with an arrow function
    let start = moment(date.date);
    let end = start.clone().add(1, 'hour');
    this.setState({
      'modal': {
        ...this.state.modal,
        visible: true,
        header: 'Reserve',
        dateStr: start.format("DD-MM-YYYY HH:mm") + ' - ' + end.format("DD-MM-YYYY HH:mm"),
        start: start,
        end: end
      }
    });
  }

  render() {
    let that = this;
    let getDurationHeader = () => {
      if (that.props.smallerHeader) {
        return (
          <h5>{i18n.t(k.AGREEMENT_DURATION)}</h5>
        );
      }
      else {
        return (
          <h4>{i18n.t(k.AGREEMENT_DURATION)}</h4>
        )
      }
    }
    let getBillingCycleHeader = () => {
      if (that.props.smallerHeader) {
        return (
          <h5>{i18n.t(k.BILLING_CYCLE_START_DATE)}</h5>
        );
      }
      else {
        return (
          <h4>{i18n.t(k.BILLING_CYCLE_START_DATE)}</h4>
        )
      }
    }
    if (this.props.agreement.reservable === true) {
      let cal = <FullCalendar defaultView="listWeek"
        ref={this.calendarRef}
        dateClick={this.onCalendarDayClick}
        eventClick={this.onEventClick}
        headerToolbar={{
          left: i18n.t(k.PREV_NEXT),
          center: i18n.t(k.TITLE1),
          right: i18n.t(k.TIMEGRIDMONTH_TIMEGRIDWEEK_TIM)
        }}
        plugins={[list, interactionPlugin, timegrid]}
        eventTimeFormat={this.getEventTimeFormat()}
        displayEventEnd={true}
        slotMinTime="09:00"
        slotMaxTime="19:00"
        slotLabelFormat={this.getEventTimeFormatSlot()}
        contentHeight="500px"
        allDaySlot={false} />;
      return (
        <div>
          <Form>
            {getDurationHeader()}
            <hr />
            {cal}
          </Form>
          <Modal className="modal-dialog-centered"
            toggle={this.toggle}
            open={this.state.modal.visible}
            onRequestClose={this.hideModal}>
            <ModalHeader>{i18n.t(k.RESERVE)}</ModalHeader>
            <ModalBody>
              <FormGroup>
                <label htmlFor="title">{i18n.t(k.TITLE)}</label>
                <FormInput id="title"
                  placeholder={i18n.t(k.TITLE)}
                  className="mb-2"
                  value="Reserved"
                  disabled="disabled" />
              </FormGroup>
              <FormGroup>
                <FormInput
                  value={this.state.modal.dateStr}
                  disabled="disabled" />
              </FormGroup>
              <Button id="submitEvent"
                onClick={this.onEventSubmit}>{i18n.t(k.RESERVE)}</Button>
            </ModalBody>
          </Modal>
        </div>);
    }
    return (
      <Form>
        <h4>{getDurationHeader()}</h4>
        <hr />
        <DatePickerComponent
          start_date={moment(this.props.agreement.rules?.start_date?.value).toDate()}
          end_date={moment(this.props.agreement.rules?.end_date?.value).toDate()}
          dates={false}
          endSelector={true}
          handleChangeEvent={
            e => {
              e.target['id'] = e.target.name;
              e.target['value'] = e.target.value
              switch (e.target.name) {
                case 'start_date':
                  this.props.handleChangeEvent(e, AGREEMENT_FIELDS.START_DATE);
                  break;
                case 'end_date':
                  this.props.handleChangeEvent(e, AGREEMENT_FIELDS.END_DATE);
                  break;
              }
            }} />
        <hr />
        <h4>{i18n.t(k.RESIGN_DATE)}</h4>
        <DateTimePicker
          id="resign_date"
          name="resign_date"
          value={moment(this.props.agreement.rules?.resign_date?.value).toDate()}
          step={1}
          onChange={e => {
            this.props.handleChangeEvent({
              'target': {
                'id': AGREEMENT_FIELDS.RESIGN_DATE,
                'value': moment(e).format('YYYY-MM-DD hh:mm:ss')
              }
            }, AGREEMENT_FIELDS.RESIGN_DATE);
          }}
        />
        <label htmlFor="release_period">{i18n.t(k.RELEASE_PERIOD)}</label>
        <FormSelect id="release_period" onChange={e => this.props.handleChangeEvent(e, AGREEMENT_FIELDS.RELEASE_PERIOD)}>
          <option value="null" label="Select release period">{i18n.t(k.SELECT_RELEASE_PERIOD)}</option>
          <option value="1" label="1 Month">{i18n.t(k.MONTH)}</option>
          <option value="2" label="2 Month">{i18n.t(k.MONTH1)}</option>
          <option value="3" label="3 Month">{i18n.t(k.MONTH2)}</option>
        </FormSelect>
        <hr />
        {getBillingCycleHeader()}
        <DateTimePicker
          id="billing_start_date"
          name="billing_start_date"
          value={moment(this.props.agreement.rules?.billing_start_date?.value).toDate()}
          step={1}
          onChange={e => {
            this.props.handleChangeEvent({
              'target': {
                'id': AGREEMENT_FIELDS.BILLING_START_DATE,
                'value': moment(e).format('YYYY-MM-DD hh:mm:ss')
              }
            }, AGREEMENT_FIELDS.BILLING_START_DATE);
          }}
        />
      </Form>)
  }

  initAreaEventsSource() {
    if (this.props.agreement.area) {
      let areaId = this.props.agreement.area;
      this.areaEventsSource = function (options, successCallback, failureCallback) {
        const request = async () => {
          let response = await HonkioAPI().userShop.userFetch('merchantgetfullcalendar', {
            query_object: areaId,
            query_object_type: 'asset',
            query_start_date: moment(options.start).format('YYYY-MM-DD HH:mm:ss').toLocaleString(),
            query_end_date: moment(options.end).format('YYYY-MM-DD HH:mm:ss').toLocaleString()
          }, error => {
            NotificationManager.error(`${error?.description}`, i18n.t(k.ERROR));
          })
          if (response?.status === 'accept' && response?.schedule && response?.schedule.length > 0) {
            let schedule = [];
            response.schedule.forEach(item => {
              item['start'] = moment.utc(item['start']).toDate();
              item['end'] = moment.utc(item['end']).toDate();
              schedule.push(item);
            });
            successCallback(schedule);
          } else {
            NotificationManager.error('Error loading schedule for the area');
          }
        };
        request();
      };
    }
  }
}

function mapStateToProps(state) {
  return { ...state };
}

const mapDispatchToProps = dispatch => ({
  getUserSchedule: (areaId, start, end) => dispatch(getAreaSchedule(areaId, start, end)),
  setEvent: (areaId, start, end, type, description, product) => dispatch(setEvent(areaId, start, end, type, description, product))
});

export default connect(mapStateToProps, mapDispatchToProps)(AgreementDatesComponent);
