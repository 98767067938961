import React, { useEffect, useState, useContext } from "react";
import useSearchString from "../../utils/useSearchString";
import {
    NotificationManager,
    NotificationContainer,
} from "react-notifications";
import AssetEditForm from "./AssetEditForm";
import getAssetType from "./../../actions/api/assetTypes/getAssetType";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { updateAssetData } from "./../../actions/api/assets/setAssetData";
import { APP_ID } from "./../../config";
import LoadingBar from "./../ui_utils/LoadingBar";
import useLoader from "./../ui_utils/useLoader";
import k from "../../i18n/keys";
import i18n from "i18next";
import CardWrapperComponent from "./../ui_utils/CardWrapperComponent";
import { getAssetTypeName } from "../../actions/api/assets/config";
import { STORAGE } from "./../../actions/auth/oauthActions";
import { uploadFile } from "../../actions/api/files/uploadFile";
import { blobToFile, getExt } from "./helpers/fileHelper";

const AssetCreate = () => {
    const [selectedAssetType, setSelectedAssetType] = useState([]);
    const [assetInfo, setAssetInfo] = useState({});
    const { type, parent } = useSearchString();
    const history = useHistory();
    const [progress, done] = useLoader([assetInfo, selectedAssetType]);

    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        if (type) {
            fetchAssetType();
        }
    }, [type]);

    useEffect(() => {
        if (selectedAssetType) {
            setAssetInfo({
                type: selectedAssetType?.name,
                parent: parent || selectedAssetType?._id,
                properties: selectedAssetType?.properties || {},
            });
        }
    }, [selectedAssetType]);

    const fetchAssetType = async () => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data?.status === "accept" && data?.asset_type) {
                    setSelectedAssetType(data?.asset_type);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        getAssetType({ query_name: type }, errorHandler).then((data) => {
            return resultHandler(data);
        });
    };

    const onSave = (data, streetUrl, assetInfo) => {
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                NotificationManager.success("Asset saved", "Success", 4000);
                setAssetInfo(data.asset);
                if (streetUrl && data.asset?._id) {
                    saveStreetUrl(streetUrl, data.asset);
                } else {
                    // console.log(
                    //     `/assets/list?type=${selectedAssetType.name}&parent=${data.asset.parent}`,
                    //     "-------------*********"
                    // );
                    if (selectedAssetType?.name === "parking_place") {
                        history.push(
                            `/assets/list?type=room&parent=${data.asset.parent}`
                        );
                    } else {
                        history.push(
                            `/assets/list?type=${selectedAssetType.name}&parent=${data.asset.parent}`
                        );
                    }
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };
        //const assetData = prepareToPublish(assetInfo, data)
        const assetData = {
            asset: data,
            merchant: data.merchant,
        };
        updateAssetData(
            assetData.asset,
            localStorage.getItem(STORAGE.MERCHANT_ID),
            APP_ID,
            errorHandler
        ).then((data) => {
            return resultHandler(data);
        });
    };

    const saveStreetUrl = async (streetUrl, asset) => {
        let blob = await fetch(streetUrl.url).then((response) => {
            if (!response.ok) {
                throw new Error("Network response ERROR");
            }
            return response.blob();
        });
        blob = blobToFile(blob, "streetView.jpg");
        let filename = blob.name.split(".")[0];
        let fileext = getExt(blob.name);

        const resultHandler = (data) => {
            if (data && data.status === "accept" && data.file?.url) {
                NotificationManager.success(
                    "Street image saved",
                    "Success",
                    4000
                );
                const assetToSave = {
                    ...asset,
                    properties: {
                        ...asset.properties,
                        [streetUrl.name]: data.file.url,
                    },
                };
                onSave(assetToSave);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(
                error.description,
                "Error when saving street image",
                4000
            );
        };
        let options = {
            file: blob,
            "content-type": "multipart/form-data",
            object: asset._id,
            object_type: "asset",
            access: "private",
            file_type: "document",
            display_name: filename,
            extension: fileext,
            metadata: { document_type: fileext },
        };

        uploadFile(options, errorHandler).then((data) => {
            return resultHandler(data);
        });
    };
    //    console.log(assetInfo,"==========assetInfo====== assetCreate",parent,"---------type",type)
    return (
        <div>
            <NotificationContainer />
            <br />
            <CardWrapperComponent
                header={true}
                card_title={`${i18n.t(k.ADD_NEW)} ${getAssetTypeName(type)}`}
            >
                {done ? (
                    assetInfo ? (
                        <AssetEditForm
                            onSubmit={onSave}
                            defaultValues={assetInfo}
                            assetType={selectedAssetType}
                        />
                    ) : (
                        "not found"
                    )
                ) : (
                    <LoadingBar progress={progress} />
                )}
            </CardWrapperComponent>
        </div>
    );
};

export default AssetCreate;
