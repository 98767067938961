import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { useEffect, useState, useContext } from "react";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteForever from "@material-ui/icons/DeleteForever";
import AddIcon from "@material-ui/icons/Add";
import { Button, ButtonGroup } from "shards-react";
import "react-confirm-alert/src/react-confirm-alert.css";
import NotificationManager from "react-notifications/lib/NotificationManager";
// import honkio_logo from '../../image/honkio_logo.png';
import { ASSET_TYPE } from "./../../actions/api/assets/config";
import getAsset from "./../../actions/api/assets/getAssets";
import useSearchString from "./../../utils/useSearchString";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getAssetListWithPages } from "./../../actions/api/assets/getAssetList";
import AssetDeleteModal from "./../assets/AssetDeleteModal";
import EditIcon from "@material-ui/icons/Edit";
import PageableDatatablesComponent from "./../ui_utils/PageableDatatablesComponent";
import DescriptionIcon from "@material-ui/icons/Description";
import IotImg from "../../img/icons/iot.png";
import SensorListModal from "../sensors/SensorListModal";
import ListOfSensors from "@material-ui/icons/List";
import { ReactComponent as SafetyVideoIcon } from "../../img/icons/video_icon.svg";
import { updateAssetData } from "../../actions/api/assets/setAssetData";
import { STORAGE } from "./../../actions/auth/oauthActions";
import { APP_ID } from "./../../config";
import getAssetTypeList from "./../../actions/api/assetTypes/getAssetTypeList";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    openSafetyVideoModal,
    closeSafetyVideoModal,
} from "../../actions/action_utils";

const Circular = () => (
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

const PAGE_ITEMS_COUNT = 10;

const FloorPlanList = () => {
    const dispatch = useDispatch();
    const { parent } = useSearchString();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [assetList, setAssetList] = useState([]);
    const [total, setTotal] = useState();
    const [parentInfo, setParentInfo] = useState([]);
    const [parentList, setParentList] = useState([]);
    const [showConfirmRemoveAsset, setShowConfirmRemoveAsset] = useState({
        visible: false,
        asset: undefined,
    });
    const handleShowConfirmRemoveAsset = (asset) =>
        setShowConfirmRemoveAsset({ visible: true, asset: asset });
    const handleCloseConfirmRemoveAsset = () => {
        setShowConfirmRemoveAsset({ visible: false, asset: undefined });
    };

    const [showConfirmLinkAssets, setShowConfirmLinkAssets] = useState(false);
    const [currentFloor, setCurrentFloor] = useState(null);
    const [finished, setFinished] = useState(false);
    const [selectedSensorList, setSelectedSensorList] = useState([]);
    const [typesList, setTypesList] = useState([]);

    let isMounted = true;
    // console.log(ASSET_TYPE, "-------------ASSET_TYPE");
    useEffect(() => {
        fetchAssetList();
        fetchChildrenTypes();
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        if (parent) fetchParent();
    }, [parent]);

    const fetchAssetList = async (page) => {
        setLoading(true);
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    setAssetList(data.assets);
                    setTotal(data.total);
                    setLoading(false);
                } else {
                    NotificationManager.error("Error");
                    setAssetList();
                    setLoading(false);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setAssetList();
            setLoading(false);
        };
        if (parent) {
            getAssetListWithPages(
                {
                    type: ASSET_TYPE.FLOORPLAN,
                    levels: 3,
                    page: page,
                    count: PAGE_ITEMS_COUNT,
                    parent: parent,
                },
                errorHandler
            )
                .then((data) => {
                    return resultHandler(data);
                })
                .catch(console.error);
        } else {
            setAssetList();
        }
    };

    const fetchParent = async () => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    setParentInfo(data.asset);
                    setParentList(data.parents);
                } else {
                    NotificationManager.error("Error");
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setParentInfo();
            setParentList();
        };
        if (parent) {
            getAsset({ id: parent, query_parent_levels: 3 }, errorHandler)
                .then((data) => {
                    return resultHandler(data);
                })
                .catch(console.error);
        } else {
            setParentInfo();
            setParentList();
        }
    };

    const isValidHttpUrl = (string) => {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    };

    const thumbnailUrl = (string) => {
        if (isValidHttpUrl(string?.custom_fields?.thumbUrl))
            return string?.custom_fields?.thumbUrl;
        if (isValidHttpUrl(string?.url)) return string?.url;
        return "honkio_logo";
    };
    const handleShowConfirmLinkAssets = (selected_asset) => {
        setCurrentFloor(selected_asset);
        setShowConfirmLinkAssets(true);
    };
    const handleCloseConfirmLinkAssets = () => {
        setShowConfirmLinkAssets(false);
    };

    const onAssetsLinked = () => {
        fetchAssetList();
        handleCloseConfirmLinkAssets();
    };

    const columns = [
        /*         {
            name: i18n.t(k.THUMBNAIL),
            selector: '',
            sortable: false,
            grow: 1,
            cell: function (d) {
                return <img src={thumbnailUrl(d.properties)}
                    style={{
                        width: 50,  //its same to '20%' of device width
                        aspectRatio: 1, // <-- this
                        resizeMode: 'contain', //optional
                    }}
                />
            }
        }, */
        {
            name: i18n.t(k.NAME),
            selector: "name",
            sortable: true,
            style: `cursor: pointer;`,
            grow: 2,
        },
        {
            name: i18n.t(k.DESCRIPTION),
            selector: "description",
            sortable: true,
            style: `cursor: pointer;`,
            grow: 2,
        },
        {
            name: i18n.t(k.ACTION),
            selector: "action",
            grow: 1,
            cell: function (asset) {
                return (
                    <span>
                        <Link
                            title={i18n.t(k.FLOORPLAN_EDIT)}
                            id="editTip"
                            to={`/assets/edit?asset=${asset._id}`}
                            className="pointer"
                        >
                            <EditIcon />
                        </Link>
                        <Link
                            title={i18n.t(k.DOCUMENTS)}
                            id="documentsTip"
                            to={`/asset/documents?id=${asset._id}`}
                            className="pointer"
                        >
                            <DescriptionIcon />
                        </Link>
                        <Link
                            title={i18n.t(k.IOT_SENSOR)}
                            id="sensorTip2"
                            to={`/sensor/list?parent=${asset._id}&property_type=building`}
                            className="pointer"
                        >
                            <ListOfSensors />
                        </Link>
                        <Link
                            title={i18n.t(k.IOT_SENSOR)}
                            id="sensorTip"
                            to="#"
                            onClick={(e) => handleShowConfirmLinkAssets(asset)}
                            className="pointer"
                        >
                            <img
                                src={IotImg}
                                style={{
                                    width: "1rem",
                                    height: "1rem",
                                }}
                            />
                        </Link>
                        <Link
                            title="Safety video"
                            id="saftyVideoTip"
                            onClick={(e) => handleAttachSafetyVideo(e, asset)}
                            className="pointer"
                            to="#"
                        >
                            <SafetyVideoIcon
                                style={{ width: "1rem", height: "1rem" }}
                            />
                        </Link>
                        <Link
                            title={i18n.t(k.DELETE)}
                            id="deleteTip"
                            to="#"
                            className="pointer"
                            onClick={() => showConfirmRemoveDialog(asset)}
                        >
                            <DeleteForever />
                        </Link>
                    </span>
                );
            },
        },
    ];

    const getParents = () => {
        let parentsList = [];
        if (parent) {
            const landplotElem = parentList
                .filter((asset) => asset.type === ASSET_TYPE.LANDPLOT)
                .map((asset) => ({
                    name: asset.name,
                    linkTo: "/buildings/" + asset.id,
                }));
            const buildingElem = parentList
                .filter((asset) => asset.type === ASSET_TYPE.BUILDING)
                .map((asset) => ({
                    name: asset.name,
                    linkTo: null,
                }));
            parentsList = [
                {
                    name: i18n.t(k.PROPERTIES),
                    linkTo: "/landplots",
                },
            ];
            if (landplotElem.length > 0) {
                parentsList = [...parentsList, landplotElem[0]];
            }
            if (buildingElem.length > 0) {
                parentsList = [...parentsList, buildingElem[0]];
            }
        }
        return parentsList;
    };

    const getBreadcrumbs = () => {
        if (parentList) {
            return;
            // return (
            //   <BreadcrumbsComponent
            //     parents={this.getParents()}
            //   />
            // )
        } else {
            return;
        }
    };

    const showConfirmRemoveDialog = (asset) => {
        handleShowConfirmRemoveAsset(asset);
    };
    const onAssetRemoved = () => {
        fetchAssetList();
        handleCloseConfirmRemoveAsset();
    };
    const handleAttachSafetyVideo = (e, selected_asset) => {
        e.preventDefault();
        const asset = typesList.filter((itm) => itm.name === "safety_video")[0];

        const data = {
            // ...asset,
            type: ASSET_TYPE.BUILDING,
            description: asset.description,
            name: asset.name,
            properties: { ...asset.properties },
            parent: selected_asset._id,
        };

        dispatch(openSafetyVideoModal(data));
    };
    const handleCloseSafetyVideo = (selected_asset) => {
        // N.B: Remove this if not useful
        setCurrentFloor(null);
    };

    const linkAsset = (asset, stop, parent_id) => {
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                if (stop) {
                    setFinished(true);
                    setSelectedSensorList([]);
                }
            } else {
                NotificationManager.error("Error");
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        const newAsset = {
            ...asset,
            parent: parent_id,
        };

        updateAssetData(
            newAsset,
            localStorage.getItem(STORAGE.MERCHANT_ID),
            APP_ID,
            ASSET_TYPE.BUILDING,
            errorHandler
        ).then((data) => {
            NotificationManager.success("Sensors added");
            return resultHandler(data);
        });
    };
    const fetchChildrenTypes = async () => {
        setLoading(true);
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    setTypesList(data.asset_types);
                    setLoading(false);
                } else {
                    NotificationManager.error("Error");
                    setTypesList();
                    setLoading(false);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setTypesList();
            setLoading(false);
        };
        if (parent) {
            getAssetTypeList(
                {
                    query_application: APP_ID,
                    // query_parent: type,
                },
                errorHandler
            )
                .then((data) => {
                    return resultHandler(data);
                })
                .catch((error) => {
                    console.log("getAssetTypeList: modal: error", error);
                });
        } else {
            setAssetList();
        }
    };

    return (
        <div>
            <br />
            <CardWrapperComponent
                header={true}
                footer={true}
                card_title={`${i18n.t(k.FLOORPLANS_L)} (${
                    parentInfo?.name || "-"
                })`}
            >
                <ButtonGroup>
                    <Button
                        theme="primary"
                        onClick={() =>
                            history.push(
                                `/assets/create?type=${ASSET_TYPE.FLOORPLAN}&parent=${parent}`
                            )
                        }
                    >
                        <AddIcon className="mr-2" />
                        {i18n.t(k.FLOORPLAN_CREATE)}
                    </Button>
                    {/*   <Button theme="info"
                        onClick={() => history.push(`/buildingAreas/${parent}`)}><ListIcon
                            className="mr-2" />{i18n.t(k.LIST_ALL_AREAS)}</Button> */}
                </ButtonGroup>
                <PageableDatatablesComponent
                    autoWidth={true}
                    columns={columns}
                    background="#ffffff00"
                    data={assetList}
                    noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                    loadItems={(page) => {
                        fetchAssetList(page);
                    }}
                    // progressPending={loading}
                    progressComponent={<Circular />}
                    paginationTotalRows={total}
                    paginationServer={true}
                    paginationPerPage={PAGE_ITEMS_COUNT}
                    paginationRowsPerPageOptions={[PAGE_ITEMS_COUNT]}
                    onRowClicked={(e) =>
                        history.push(
                            `/assets/list?type=${ASSET_TYPE.ROOM}&parent=${e._id}`
                        )
                    }
                />
                {showConfirmRemoveAsset.asset && (
                    <AssetDeleteModal
                        show={showConfirmRemoveAsset.visible}
                        assetType={ASSET_TYPE.FLOORPLAN}
                        onHide={() => handleCloseConfirmRemoveAsset()}
                        assetToRemove={showConfirmRemoveAsset.asset}
                        onAssetRemoved={onAssetRemoved}
                    />
                )}
                {showConfirmLinkAssets && (
                    <SensorListModal
                        show={showConfirmLinkAssets}
                        onHide={() => handleCloseConfirmLinkAssets()}
                        parent={currentFloor._id}
                        // type={type}
                        type={ASSET_TYPE.BUILDING}
                        // property_type={property_type}
                        property_type={ASSET_TYPE.BUILDING}
                        onAssetsLinked={onAssetsLinked}
                    />
                )}
            </CardWrapperComponent>
        </div>
    );
};

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FloorPlanList);
