import React, { useEffect, useState, useContext } from 'react';
import { NotificationManager, NotificationContainer } from "react-notifications";
import AssetGroupEditForm from './AssetGroupEditForm';
import CardWrapperComponent from './../../ui_utils/CardWrapperComponent';
import i18n from 'i18next';
import k from "../../../i18n/keys";
import useSearchString from './../../../utils/useSearchString';
import { setAssetGroup } from './../../../actions/api/assets/assetGroup';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const AssetGroupCreate = () => {
    const history = useHistory()
    const { landplot: landplotId, landplotname } = useSearchString();
    const [assetGroupInfo, setAssetGroupInfo] = useState({ parent: landplotId, assets: [] });

    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false
        };
    }, []);

    const onSave = (data) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success(i18n.t(k.SAVED), i18n.t(k.SUCCESS), 4000)
                history.push(`/assetGroups?landplot=${landplotId}&landplotname=${landplotname}`)
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000)
        }
        const assetIds = data.assets.map(asset => asset._id)
        const assetGroup = {
            ...data,
            assets: assetIds
        }
        setAssetGroup({ group: assetGroup, merchantId: localStorage.merchant_id }, errorHandler)
            .then(data => {
                return resultHandler(data)
            })
    };

    return <div className='mt-2'>
        <NotificationContainer />
        <CardWrapperComponent
            header={true}
            footer={true}
            card_title={`${i18n.t(k.CREATE_AREA)}  (${landplotname})`}>
            <AssetGroupEditForm
                onSubmit={onSave}
                defaultValues={assetGroupInfo}
            />
        </CardWrapperComponent>
    </div>
};

export default AssetGroupCreate;
