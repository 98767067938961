const initialState = {
    isModalOpen: false,
    data: null,
};

export const SET_SAFETY_VIDEO_DATA = "SET_SAFETY_VIDEO_DATA";
export const CLEAR_SAFETY_VIDEO_DATA = "CLEAR_SAFETY_VIDEO_DATA";

function SafetyVideoReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SAFETY_VIDEO_DATA:
            return {
                ...state,
                isModalOpen: true,
                data: action.payload,
            };
        case CLEAR_SAFETY_VIDEO_DATA:
            return initialState;
        default:
            return state;
    }
}

export default SafetyVideoReducer;
