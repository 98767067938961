import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { Component } from "react";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import HonkioAPI from "../../middlewares/HonkioAPI";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";
import * as qs from "qs";
import InvoicesDatatablesComponent from "./subcomponents/InvoicesDatatablesComponent";
import SettingsIcon from "@material-ui/icons/Settings";
import { NotificationManager } from "react-notifications";

import moment from "moment";
import "moment/locale/fi";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

export const ClearLocalStorage = () => {
    localStorage.removeItem("total_amount");
    localStorage.removeItem("start_date");
    localStorage.removeItem("end_date");
    localStorage.removeItem("number_of_month");
    localStorage.removeItem("diff");
};

class ShareholderInvoices extends Component {
    constructor(props) {
        super(props);
        let today = moment()
            .hours(0)
            .minutes(0)
            .seconds(0)
            .milliseconds(0)
            .startOf("month");
        this.state = {
            loading: true,
            hovering: false,
            modalDebt: {
                visible: false,
                debt: {},
            },

            building: {
                value: i18n.t(k.MERCHANT),
                label: localStorage.getItem("merchant_name"),
            },
            modalAsset: {
                visible: false,
                asset: {},
            },

            filter: {},
            currentDay: today.toDate(),
            currentDayText: today.format("MMMM YYYY"),
            open: null,
            type: "invoice",
        };

        this.hideModalDebt = this.hideModalDebt.bind(this);
        this.showModalDebt = this.showModalDebt.bind(this);
        this.toggleDebt = this.toggleDebt.bind(this);

        this.hideModalAsset = this.hideModalAsset.bind(this);
        this.showModalAsset = this.showModalAsset.bind(this);
        this.toggleAsset = this.toggleAsset.bind(this);

        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
        // this.rowSelected = this.rowSelected.bind(this)
        // this.loadInvoices = this.loadInvoices.bind(this)
        this.loadBuildings = this.loadBuildings.bind(this);
        this.prevMonth = this.prevMonth.bind(this);
        this.nextMonth = this.nextMonth.bind(this);

        this.handleChangeBuilding = this.handleChangeBuilding.bind(this);
    }

    hideModalDebt() {
        this.setState({
            modalDebt: { ...this.state.modalDebt, visible: false },
        });
        ClearLocalStorage();
    }

    showModalDebt(d) {
        if (this.state.building.value === "merchant") {
            NotificationManager.warning(
                i18n.t(k.LIABILITIES_MERCHANT_IN_PROGRESS),
                i18n.t(k.WARNING)
            );
        } else {
            this.setState({
                modalDebt: { ...this.state.modalDebt, visible: true, debt: d },
            });
            ClearLocalStorage();
        }
    }

    toggleDebt() {
        this.setState({
            modalDebt: {
                ...this.state.modalDebt,
                visible: !this.state.modalDebt.visible,
            },
        });
        ClearLocalStorage();
    }

    hideModalAsset() {
        this.setState({
            modalAsset: { ...this.state.modalAsset, visible: false },
        });
    }

    showModalAsset(d) {
        this.setState({
            modalAsset: { ...this.state.modalAsset, visible: true, asset: d },
        });
    }

    toggleAsset() {
        this.setState({
            modalAsset: {
                ...this.state.modalAsset,
                visible: !this.state.modalAsset.visible,
            },
        });
    }

    handleMouseOver() {
        // console.log('Mouse Over');
        this.setState({ hovering: true });
    }

    handleChangeBuilding(option) {
        this.setState({ building: option });
    }

    handleMouseOut() {
        // console.log('Mouse Out');
        this.setState({ hovering: false });
    }

    componentDidMount() {
        this.loadBuildings();
    }

    loadBuildings() {
        let that = this;
        let type = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
        }).type;
        let buildingArg = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
        }).building;

        let merchantgetbuildings = async function () {
            let response = await HonkioAPI().userShop.userFetch(
                "merchantbuildinglist",
                {}
            );
            let buildings = [
                { id: "merchant", name: localStorage.getItem("merchant_name") },
                ...response.buildings,
            ];
            let newState = {
                buildings: buildings,
                loading: false,
                building: {
                    value: i18n.t(k.MERCHANT),
                    label: localStorage.getItem("merchant_name"),
                },
            };

            if (type) newState["type"] = type;
            that.setState(newState);
        };
        merchantgetbuildings();
    }

    toggle(id) {
        this.setState((prevState) => {
            return { open: id };
        });
    }

    nextMonth() {
        let tomorrow = moment(this.state.currentDay)
            .add(1, "month")
            .hours(0)
            .minutes(0)
            .seconds(0)
            .milliseconds(0);
        this.setState({
            currentDay: tomorrow.toDate(),
            currentDayText: tomorrow.format("MMMM YYYY"),
        });
        console.error(tomorrow.format("MMMM YYYY"));
        console.error(tomorrow.toDate());
    }

    prevMonth() {
        let tomorrow = moment(this.state.currentDay)
            .subtract(1, "month")
            .hours(0)
            .minutes(0)
            .seconds(0)
            .milliseconds(0);
        this.setState({
            currentDay: tomorrow.toDate(),
            currentDayText: tomorrow.format("MMMM YYYY"),
        });
    }

    render() {
        let shareholderId = this.props.match.params.shareholderId;

        return (
            <div>
                <div className="container-fluid">
                    <PageHeaderComponent
                        title={i18n.t(k.SHAREHOLDER_INVOICES)}
                        subtitle={i18n.t(k.OVERVIEW)}
                    />
                </div>
                <CardWrapperComponent
                    header={true}
                    footer={true}
                    card_title={i18n.t(k.SHAREHOLDER_INVOICES)}
                    rightIcon={
                        <SettingsIcon
                            className="float-right pointer"
                            onClick={() => {
                                this.props.history.push(
                                    `${i18n.t(k.ACCOUNTING)}`
                                );
                            }}
                        />
                    }
                >
                    <div className="d-flex mb-3">
                        <span className="my-auto p-1 border d-flex">
                            <ChevronLeftIcon
                                className="pointer mr-3 my-auto"
                                onClick={this.prevMonth}
                            />

                            <span className="m-1 text-large">
                                {this.state.currentDayText}
                            </span>
                            <ChevronRightIcon
                                onClick={this.nextMonth}
                                className="pointer ml-3 my-auto"
                            />
                        </span>
                    </div>
                    <InvoicesDatatablesComponent
                        type="invoice"
                        customQuery={{
                            query_object: shareholderId,
                            query_object_type: "share",
                        }}
                        currentDay={this.state.currentDay}
                        buildingId={this.state.building.value}
                    />
                </CardWrapperComponent>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShareholderInvoices);
