import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { Component } from "react";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "shards-react";
import "react-confirm-alert/src/react-confirm-alert.css";
import HonkioAPI from "../../middlewares/HonkioAPI";
import CenteredPageLoader from "../ui_utils/CenteredPageLoader";
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import DataTable from "react-data-table-component";

import { TimeRange } from "pondjs";

import moment from "moment";
import "moment/locale/fi";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

const fields = [],
    fields2 = [];
const context = require.context("./json/", true, /.json$/);

context.keys().forEach((key) => {
    const fileName = key.replace("./", "");
    const resource = require(`./json/${fileName}`);
    const namespace = fileName.replace(".json", "");
    fields[namespace] = JSON.parse(JSON.stringify(resource));
});
const context2 = require.context("./json_integration/", true, /.json$/);
context2.keys().forEach((key) => {
    const fileName = key.replace("./", "");
    const resource = require(`./json_integration/${fileName}`);
    const namespace = fileName.replace(".json", "");
    fields2[namespace] = JSON.parse(JSON.stringify(resource));
});
const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

class DataReviewComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        };

        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        let that = this;
        let dataId = this.props.match.params.dataId;

        let load = async function () {
            let response = await HonkioAPI().userShop.userFetch(
                "merchantbuildingdatalist",
                {
                    query_id: dataId,
                }
            );
            // let building = await HonkioAPI().userShop.userFetch('merchantbuildinglist', {
            //     'id': response.data[0].building
            // });

            let values = [];
            // alert(response.data[0].type)
            if (response.data[0].type === "manual_input") {
                Object.keys(fields).map((name) => {
                    let title = name.replace(/^\d+_/, "");
                    let fieldSet = fields[name];
                    fieldSet.map((field) => {
                        let fieldname = field.name;
                        values.push({
                            name: field.name,
                            value: response.data[0].data[field.name]
                                ? response.data[0].data[field.name]
                                : "Not set",
                        });
                    });
                });
            } else {
                // Object.keys(response.data[0].data).forEach(k=> {
                //     let item = response.data[0].data[k]
                //     values.push({name: k, value:item})
                // })
                let total = 0;
                let fieldsMap = {};
                let mapped = [];
                Object.keys(fields2).forEach((k) => {
                    fields2[k].forEach(
                        (field) => (fieldsMap[field.name] = { ...field })
                    );
                });

                Object.keys(response.data[0].data).map((name) => {
                    let field = fieldsMap[name];
                    let data = response.data[0].data;
                    if (["MDM_ID", "Kiinteisto_MDM_ID_FK"].includes(name))
                        return;
                    values.push({
                        label: name,
                        name: name,
                        value: data[name],
                        source: response.data[0].type,
                    });
                    // if (field) {
                    //     values.push({
                    //         label: field.label,
                    //         name: field.name,
                    //         value: data.hasOwnProperty(field.name) ? data[name] : 'Not set',
                    //         source: field.source
                    //     })
                    //     mapped.push(name)
                    // } else {
                    //     values.push({
                    //         label: 'N/A',
                    //         name: name,
                    //         value: data[name],
                    //         source: 'N/A'
                    //     })
                    //
                    // }
                });
                // var missing = Object.keys(fieldsMap).filter(function(item) {
                //     return mapped.indexOf(item) < 0; // Returns true for items not found in b.
                // });
                // missing.forEach((missing) => {
                //         values.push({
                //             label: fieldsMap[missing].label,
                //             name: fieldsMap[missing].name,
                //             value: 'Not set',
                //             source: fieldsMap[missing].source
                //         })
                // })
                // Object.keys(fields2).map((name) => {
                //     let title = name.replace(/^\d+_/, '')
                //     let fieldSet = fields2[name];
                //     fieldSet.map((field) => {
                //         let fieldname = field.name;
                //         total++
                //         values.push({
                //             label: field.label,
                //             name: field.name,
                //             value: response.data[0].data.hasOwnProperty(field.name) ? response.data[0].data[field.name] : 'Not set',
                //             source: field.source
                //         })
                //     })
                //
                // });
                // alert(total)
            }
            that.setState({
                data: response.data[0],
                values: values,
                loading: false || {},
            });
            //, building: building.buildings[0]
        };
        load();
    }

    getColumns = () => {
        let that = this;
        let cols = [
            {
                name: i18n.t(k.NAME),
                width: "20%",
                selector: "label",
                sortable: true,
                style: `cursor: pointer;`,
            },
            // {
            //     name: 'KEY',
            //     width: '20%',
            //     selector: 'name',
            //     sortable: true,
            //     style: `cursor: pointer;`
            // },
            {
                name: i18n.t(k.VALUE),
                width: "50%",
                selector: "value",
                sortable: true,
                style: `cursor: pointer;`,
            },
        ];
        if (this.state.data.type !== "manual_input") {
            cols.push({
                name: "Source",
                selector: "source",
                sortable: true,
                style: `cursor: pointer;`,
            });
        }
        return cols;
    };

    render() {
        let that = this;
        let buildingId = this.props.match.params.buildingId;
        if (this.state.loading) {
            return <CenteredPageLoader />;
        }
        console.error(this.state);

        return (
            <div className="container-fluid">
                <CardWrapperComponent
                    header={true}
                    footer={true}
                    card_title={`${i18n.t(k.BUILDING_DATA)} ${
                        this.state.building.name
                    } (MDM_ID: ${this.state.building.MDM_ID})`}
                >
                    <h3>{i18n.t(k.UPDATE_INFORMATION)}</h3>
                    <span>
                        {`${i18n.t(k.UPDATED_AT_LABEL)}
                  ${this.state.data.updated_date}
                  ${i18n.t(k.BY)} ${
                            this.state.data.updated_by_firstname
                                ? this.state.data.updated_by_firstname
                                : "system"
                        } ${
                            this.state.data.updated_by_lastname
                                ? this.state.data.updated_by_lastname
                                : ""
                        }`}
                    </span>

                    <div id="buildingDataTable" className="mt-3">
                        <DataTable
                            autoWidth={true}
                            columns={this.getColumns()}
                            background="#ffffff00"
                            // onRowClicked={this.rowSelected}
                            data={this.state.values}
                            noDataComponent={
                                <span>{i18n.t(k.EMPTY_LIST)}</span>
                            }
                            paginationComponentOptions={{
                                rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                                rangeSeparatorText: i18n.t(k.ROWS_OF),
                                noRowsPerPage: false,
                                selectAllRowsItem: false,
                                selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                            }}
                            progressPending={this.state.loading}
                            progressComponent={<Circular />}
                            noHeader={true}
                        />
                    </div>
                    {/*{Object.keys(fields).map((name) => {*/}
                    {/*    let title = name.replace(/^\d+_/, '')*/}
                    {/*    let fieldSet = fields[name];*/}
                    {/*    return <div>*/}
                    {/*        <h4>{title}</h4>*/}
                    {/*        {fieldSet.map((field)=> {*/}
                    {/*        let fieldname = field.name;*/}
                    {/*        return <div>*/}
                    {/*        <div>*/}
                    {/*        <b>{field.name}</b>*/}
                    {/*        <p>{this.state.data.data[field.name] ? this.state.data.data[field.name] : 'Not set'}</p>*/}
                    {/*        </div>*/}
                    {/*        </div>*/}
                    {/*    })}</div>*/}
                    {/*    */}
                    {/*})};*/}
                </CardWrapperComponent>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DataReviewComponent);
