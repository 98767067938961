import i18n from 'i18next';import k from "./../../i18n/keys";import React, { Component } from 'react';
import { DateTimePicker } from "react-widgets";
import { Badge } from "shards-react";

import moment from "moment";
import "moment/locale/fi";

class SingleDatePickerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleStartDate = e => {
    this.setState({ 'start_date': moment(e).toDate() });
    this.props.handleChangeEvent(
    {
      target: {
        name: this.props.name,
        id: this.props.id,
        value: moment(e).format('YYYY-MM-DD HH:mm:ss') } });
  };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.value)
        this.setState({value: moment(nextProps.value).toDate()})
    }
    componentDidMount() {
        if (this.props.value)
        this.setState({value: moment(this.props.value).toDate()})
    }

  render() {
    return (<DateTimePicker
              {...this.props}
              id={this.props.id}
              name={this.props.name ? this.props.name : this.props.id}
              value={this.state.value}
              onChange={this.handleStartDate}
              timeFormat="HH:mm"
              format="LL, HH:mm" />);


  }}



export default SingleDatePickerComponent;
