import i18n from 'i18next';import k from "./../../i18n/keys";import React, { Component } from 'react';
import { Button, Form, FormGroup, FormInput, Modal, ModalBody, ModalHeader } from "shards-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import HonkioAPI from "../../middlewares/HonkioAPI";
import Select from "react-select";

class PortfolioEditModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'portfolio': {
        "buildings": [] },


      "buildings": [] };

    this.save = this.save.bind(this);
    this.handleChangeProperties = this.handleChangeProperties.bind(this);
  }

  loadBuildings() {
    let that = this;
    let merchantgetbuildings = async function () {
      let response = await HonkioAPI().userShop.userFetch('merchantbuildinglist', { merchant_id: localStorage.getItem('merchant_id') });
      let buildings = response.buildings;
      that.setState({ 'buildings': buildings, 'loading': false });
    };
    merchantgetbuildings();
  }

  componentDidMount() {
    this.loadBuildings();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.portfolio !== null) {
      let portfolio = nextProps.portfolio;
      let i,values,labels,options = [];
      for (i = 0, values = portfolio.buildings, labels = portfolio.buildings_names; i < portfolio.buildings.length; i++) {
        options.push({ value: values[i], label: labels[i] });
      }
      this.setState({ 'portfolio': { ...portfolio, "buildings": options } });
    }
  }

  save() {
    let that = this;
    this.setState({ 'loading': true });
    let savePortfolio = async function () {
      let buildings = [];
      let stateBuildings = that.state.portfolio.buildings;
      stateBuildings.forEach(function (option) {
        buildings.push(option.value);
      });
      let params = {
        'name': that.state.portfolio.name,
        'description': that.state.portfolio.description,
        'buildings': buildings };

      if (that.state.portfolio.id) {
        params['id'] = that.state.portfolio.id;
      }

      let response = await HonkioAPI().userShop.userFetch('merchantbuildingportfolioset', params);
      // fixme check status
      that.setState({ 'loading': false });
      that.props.onPortfolioSaved();
    };
    savePortfolio();
  }

  handleChangeProperties(option) {
    this.setState({ 'portfolio': { ...this.state.portfolio, "buildings": option } });
  }

  handleChange(e) {
    this.setState({ 'portfolio': { ...this.state.portfolio, [e.target.id]: e.target.value } });
  }

  render() {
    let options = [];
    if (this.state.buildings) {
      let buildings = this.state.buildings;
      buildings.forEach(function (buildng) {
        options.push({ value: buildng.id, label: buildng.name });
      });
    }

    let saveButton = <Button onClick={this.save}>{i18n.t(k.SAVE)}</Button>;
    if (this.state.loading) {
      saveButton = <CircularProgress size={45} />;
    }
    return (

      <Modal open={this.props.visible}
      toggle={this.props.toggle}
      onRequestClose={this.props.onRequestClose}
      className="w-100 modal-lg modal-dialog-centered">
                <ModalHeader>{this.props.portfolio === null ? i18n.t(k.CREATE_PORTFOLIO) : i18n.t(k.EDIT_PORTFOLIO)}</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <label htmlFor="name">{i18n.t(k.NAME)}</label>
                            <FormInput id="name"
              value={this.state.portfolio.name}
              onChange={e => this.handleChange(e)} />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="description">{i18n.t(k.DESCRIPTION)}</label>
                            <FormInput id="description"
              value={this.state.portfolio.description}
              onChange={e => this.handleChange(e)} />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="contract_owner">{i18n.t(k.BUILDINGS)}</label>
                            <Select id="buildings" name="filters"
              placeholder={i18n.t(k.FILTERS)}
              value={this.state.portfolio.buildings}
              isMulti
              onChange={this.handleChangeProperties}
              options={options}>
                            </Select>
                        </FormGroup>
                        {saveButton}
                    </Form>
                </ModalBody>
            </Modal>);

  }}


export default PortfolioEditModalComponent;
