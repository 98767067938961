import i18n from "i18next";
import k from "./../../../i18n/keys";
import React, { Component } from "react";
import "react-notifications/dist/react-notifications.css";
import "react-notifications/dist/react-notifications.js";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import CircularProgress from "@material-ui/core/CircularProgress";
import class_data from "../product_types.json";
import Email from "@material-ui/icons/Email";
import DebtChooseShareholderComponent from "./DebtChooseShareholderComponent";
import GroupIcon from "@material-ui/icons/Group";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert";
import DeleteForever from "@material-ui/icons/DeleteForever";
import DebtEditModalComponent from "./DebtEditModalComponent";
import EditIcon from "@material-ui/icons/Edit";
import { ClearLocalStorage } from "../AccountingComponent";
import getValues from "../product_type_utils";
import { Link } from "react-router-dom";

import moment from "moment";
import "moment/locale/fi";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

class DebtsDatatablesComponent extends Component {
    constructor(props) {
        super(props);
        this.loadDebts = this.loadDebts.bind(this);
        this.state = {
            buildingId: this.props.buildingId,
            currentDay: this.props.currentDay,
            modal: {
                visible: 0,
            },

            modalEdit: {
                visible: 0,
                debt: {},
            },
        };

        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.toggle = this.toggle.bind(this);
        this.hideModalEdit = this.hideModalEdit.bind(this);
        this.showModalEdit = this.showModalEdit.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
    }

    componentDidMount() {
        // alert(this.state.buildingId)
        this.loadDebts(this.state.buildingId, this.state.currentDay);
        this.loadMerchant();
    }

    loadMerchant() {
        let that = this;
        let merchantGet = async function () {
            let response = await HonkioAPI().userShop.userFetch(
                "merchantget",
                {}
            );
            let newState = {
                merchant: response.merchant,
                loading: false,
                columns: response.merchant.list_product_categories_groups[0],
            };

            that.setState(newState);
        };
        merchantGet();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let newState = { ...this.state, nextProps };
        this.setState(newState);
        // this.loadDebts(
        //     nextProps.buildingId ? nextProps.buildingId : this.state.buildingId,
        //     nextProps.currentDay ? nextProps.currentDay : this.state.currentDay
        // )
    }

    hideModal() {
        this.setState({ modal: { ...this.state.modal, visible: false } });
        localStorage.removeItem("shareholderId");
    }

    showModal(d) {
        this.setState({
            modal: {
                ...this.state.modal,
                visible: true,
                buildingId: d.building_id,
                debt: d,
            },
        });
        localStorage.removeItem("shareholderId");
    }

    toggle() {
        this.setState({
            modal: { ...this.state.modal, visible: !this.state.modal.visible },
        });
        localStorage.removeItem("shareholderId");
    }

    hideModalEdit() {
        this.setState({
            modalEdit: { ...this.state.modalEdit, visible: false },
        });
        ClearLocalStorage();
    }

    showModalEdit(d) {
        this.setState({
            modalEdit: {
                ...this.state.modalEdit,
                visible: true,
                buildingId: d.building_id,
                debt: d,
            },
        });
        ClearLocalStorage();
    }

    toggleEdit() {
        this.setState({
            modalEdit: {
                ...this.state.modalEdit,
                visible: !this.state.modalEdit.visible,
            },
        });
        ClearLocalStorage();
    }

    confirmDelete(debt) {
        let that = this;
        const options = {
            title: "Destructive action",
            message: "",
            childrenElement: () => (
                <p>
                    {i18n.t(k.ARE_YOU_SURE_WANT_TO_DELETE1)} <b>{debt.name}</b>
                    {i18n.t(k._4)}
                </p>
            ),
            buttons: [
                {
                    label: i18n.t(k.YES),
                    onClick: () => {
                        const del = async () => {
                            that.setState({ loading: true });
                            let response = await HonkioAPI().userShop.userFetch(
                                "merchantbuildingdebtset",
                                {
                                    id: debt.id,
                                    delete: true,
                                }
                            );

                            if (response.status === "accept") {
                                NotificationManager.success(
                                    i18n.t(k.LOADING),
                                    i18n.t(k.SHAREHOLDER_HAS_BEEN_REMOVED)
                                );
                            } else {
                                NotificationManager.success(
                                    `${response.description}`,
                                    i18n.t(k.DELETE_FAILED)
                                );
                            }
                            that.loadDebts(
                                that.state.buildingId,
                                that.state.currentDay
                            );
                        };
                        del();
                    },
                },

                {
                    label: i18n.t(k.NO),
                    onClick: () => {},
                },
            ],

            closeOnEscape: true,
            closeOnClickOutside: true,
        };

        confirmAlert(options);
    }

    loadDebts(buildingId, date) {
        let that = this;
        that.setState({ debts: [], loading: true });
        let loadDebts = async function () {
            let result = await HonkioAPI().userShop.userFetch(
                "merchantbuildingdebtlist",
                {
                    building_id: buildingId,
                    query_date: {
                        creation_date: {
                            lte: moment(date ? date : that.state.currentDay)
                                .endOf("month")
                                .format("YYYY-MM-DD hh:mm:ss"),
                            gte: moment(
                                date ? date : that.state.currentDay
                            ).format("YYYY-MM-DD hh:mm:ss"),
                        },
                    },
                }
            );

            if (result.status === "accept") {
                that.setState({ debts: result.debts, loading: false });
            }
        };
        loadDebts();
    }

    getColumnsDebts() {
        let that = this;
        return [
            {
                name: i18n.t(k.NAME),
                selector: "name",
                sortable: true,
                style: `cursor: pointer;`,
            },

            {
                name: i18n.t(k.DUE_DATE),
                selector: "end_date",
                sortable: true,
                style: `cursor: pointer;`,
            },

            {
                name: i18n.t(k.FIRST_PAYMENT_DAY),
                selector: "start_date",
                sortable: true,
                style: `cursor: pointer;`,
            },

            {
                name: i18n.t(k.TOTAL_AMOUNT_OF_DEBT),
                selector: "total_amount",
                sortable: true,
                style: `cursor: pointer;`,
            },

            {
                name: i18n.t(k.LIABILITY_TYPE),
                selector: "type",
                sortable: true,
                style: `cursor: pointer;`,
            },

            {
                name: i18n.t(k.MONTHLY_PAYMENT_SUM),
                selector: "amount_per_month",
                sortable: true,
                style: `cursor: pointer;`,
            },

            {
                name: i18n.t(k.CLASS),
                sortable: true,
                selector: function (d) {
                    let name = undefined;
                    if (that.state.columns) {
                        let columns = that.state.columns;
                        for (const [key, value] of Object.entries(columns)) {
                            if (parseInt(key) === parseInt(d.class)) {
                                name = value.name;
                                return name;
                            }
                            value.items.forEach((item) => {
                                if (parseInt(item.id) === parseInt(d.class)) {
                                    name = item.content;
                                    return name;
                                }
                            });
                        }
                    } else {
                        getValues().forEach((t) => {
                            if (d.class && d.class.length > 0)
                                if (parseInt(t.value) === parseInt(d.class)) {
                                    // alert(`${parseInt(t.value)}   ${parseInt(d.class)}`)
                                    name = t.label;
                                }
                        });
                    }
                    if (name === undefined) {
                        name = i18n.t(k.NOT_SET);
                    }
                    return name;
                },
            },

            {
                name: i18n.t(k.MERCHANT_NAME),
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (d) {
                    return <span>{localStorage.getItem("merchant_name")}</span>;
                },
            },

            {
                name: i18n.t(k.ACTION),
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (d) {
                    return (
                        <span>
                            <Link
                                to="#"
                                className="pointer ml-1"
                                onClick={() => {
                                    that.showModal(d);
                                }}
                            >
                                <GroupIcon />
                            </Link>
                            <Link
                                to="#"
                                className="pointer ml-1"
                                onClick={() => {
                                    that.showModalEdit(d);
                                }}
                            >
                                <EditIcon />
                            </Link>
                            <Link
                                to="#"
                                className="pointer"
                                onClick={() => {
                                    that.confirmDelete(d);
                                }}
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ];
    }

    render() {
        let that = this;
        return (
            <div>
                <DataTable
                    // title="Employees"
                    className="border"
                    noHeader={true}
                    autoWidth={true}
                    columns={this.getColumnsDebts()}
                    background="#ffffff00"
                    data={this.state.debts}
                    noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                    paginationComponentOptions={{
                        rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                        rangeSeparatorText: i18n.t(k.ROWS_OF),
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                    }}
                    onRowClicked={(d) => that.props.push("/payment/" + d.id)}
                    progressPending={this.state.loading}
                    progressComponent={<Circular />}
                />

                <DebtChooseShareholderComponent
                    visible={this.state.modal.visible}
                    open={this.hideModal}
                    buidlingId={this.state.modal.buildingId}
                    toggle={this.toggle}
                    onRequestClose={this.props.onRequestClose}
                    push={this.props.push}
                    debt={this.state.modal.debt}
                />

                <DebtEditModalComponent
                    visible={this.state.modalEdit.visible}
                    open={this.hideModalEdit}
                    toggle={this.toggleEdit}
                    onRequestClose={this.props.onRequestClose}
                    debt={this.state.modalEdit.debt}
                    buildingId={this.state.buildingId}
                    onDebtSaved={() => {
                        that.setState({ type: "" });
                        that.setState({ type: "debts" });
                        that.hideModalEdit();
                        that.loadDebts(
                            that.state.buildingId,
                            that.state.currentDay
                        );
                    }}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DebtsDatatablesComponent);
