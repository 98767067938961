import i18n from "i18next";
import React, { Component } from "react";
import { Button, FormInput, InputGroup, InputGroupAddon } from "shards-react";
import SingleDatePickerComponent from "../../ui_utils/SingleDatePickerComponent";
import moment from "moment";
import "moment/locale/fi";
import EditIcon from "@material-ui/icons/Edit";
import CommentModalComponent from "./CommentModalComponent";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

class FieldInputComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commentModal: {
                field: null,
                visible: false,
            },
            commentField: null,
            modalOpen: false,
        };
        this.hideCommentModal = this.hideCommentModal.bind(this);
        this.showCommentModal = this.showCommentModal.bind(this);
        this.toggleCommentModal = this.toggleCommentModal.bind(this);
    }

    hideCommentModal() {
        this.setState({
            commentModal: { ...this.state.commentModal, visible: false },
        });
    }

    showCommentModal(field) {
        this.setState({
            commentModal: {
                ...this.state.commentModal,
                visible: true,
                field: field,
            },
        });
    }

    toggleCommentModal() {
        this.setState({
            commentModal: {
                ...this.state.commentModal,
                visible: !this.state.commentModal.visible,
            },
        });
    }

    mapComponent = (field, className) => {
        let input;
        let that = this;
        let buttonTheme = "secondary";
        if (this.props.data.data) {
            if (
                this.props.data.data[field.name] &&
                this.props.data.data[field.name].comment
            ) {
                buttonTheme = "success";
            }
        }
        let commentButton = (
            <Button
                theme={buttonTheme}
                size={"sm"}
                onClick={() => {
                    this.showCommentModal(field);
                }}
            >
                <EditIcon fontSize={"small"} />
            </Button>
        );

        switch (field.type) {
            case "date":
                if (field.name == "Raportin päivä") {
                    input = (
                        <SingleDatePickerComponent
                            disabled
                            id={field.name}
                            name={field.name}
                            // className={className}
                            value={
                                this.props.data.updated_date
                                    ? this.props.data.updated_date
                                    : ""
                            }
                            handleChangeEvent={that.props.handleChangeEvent}
                        />
                    );
                    commentButton = null;
                } else {
                    input = (
                        <SingleDatePickerComponent
                            id={field.name}
                            name={field.name}
                            // className={className}
                            value={
                                this.props.data.data &&
                                this.props.data.data[field.name]
                                    ? this.props.data.data[field.name].value
                                    : ""
                            }
                            handleChangeEvent={that.props.handleChangeEvent}
                        />
                    );
                }
                break;
            case "number":
                input = (
                    <FormInput
                        id={field.name}
                        name={field.name}
                        className={className}
                        pattern="\d*"
                        value={
                            this.props.data.data &&
                            this.props.data.data[field.name]
                                ? this.props.data.data[field.name].value
                                : ""
                        }
                        onChange={this.props.handleChangeEvent}
                    />
                );

            default:
                if (field.name == "Arvioitsija") {
                    return null;
                    // let updatedBy = ''
                    // if (this.props.data.updated_by_firstname) {
                    //     updatedBy += this.props.data.updated_by_firstname + ' '
                    // }
                    // if (this.props.data.updated_by_lastname) {
                    //     updatedBy += this.props.data.updated_by_lastname
                    // }
                    // input = <FormInput
                    //     disabled
                    //     id={field.name}
                    //     name={field.name}
                    //     className={className}
                    //     value={updatedBy}
                    //     onChange={this.props.handleChangeEvent}
                    //     />
                    // commentButton = null;
                } else {
                    input = (
                        <FormInput
                            id={field.name}
                            name={field.name}
                            className={className}
                            value={
                                this.props.data.data &&
                                this.props.data.data[field.name]
                                    ? this.props.data.data[field.name].value
                                    : ""
                            }
                            onChange={this.props.handleChangeEvent}
                        />
                    );
                }
        }

        return (
            <div className="col-4 mb-3">
                <span className={"col-6 no-left-padding"}>{field.name}</span>
                <InputGroup>
                    {input}
                    <InputGroupAddon type="append" className={"col-1"}>
                        {commentButton}
                    </InputGroupAddon>
                </InputGroup>
            </div>
        );
    };

    // toggleCommentModal(field) {
    //     let commentField;
    //     if (field) {
    //         commentField = <div>
    //         <span className={'col-6'}>{field.name} comment</span>
    //         <FormInput id={'comment_'+field.name}
    //         name={field.name}
    //         className='col-5'
    //         value={this.props.data.data && this.props.data.data[field.name] ?
    //             this.props.data.data[field.name].comment : ''}
    //         onChange={this.props.handleCommentChangeEvent}
    //         />
    //         </div>
    //     }
    //     this.setState({
    //         modalOpen: !this.state.modalOpen,
    //         commentField: commentField,
    //     })  ;

    // }

    render() {
        let that = this;
        return (
            <div className="w-100 mb-3">
                <h4 className="text-capitalize mb-3">{this.props.title}</h4>
                <div className="d-flex flex-wrap">
                    {this.props.fields.map((field) => {
                        return this.mapComponent(field, "col-5");
                    })}
                </div>
                {/* <Modal open={this.state.modalOpen}>
                    <CardWrapperComponent>
                    {this.state.commentField}
                    <Button onClick={}>{i18n.t(k.CLOSED_L)}</Button>
                    </CardWrapperComponent>
                </Modal> */}
                <CommentModalComponent
                    visible={this.state.commentModal.visible}
                    handleCommentChangeEvent={
                        this.props.handleCommentChangeEvent
                    }
                    open={this.hideCommentModal}
                    toggle={this.toggleCommentModal}
                    onRequestClose={this.hideCommentModal}
                    data={this.props.data}
                    field={this.state.commentModal.field}
                />
            </div>
        );
    }
}

export default FieldInputComponent;
