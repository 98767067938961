import i18n from 'i18next';import k from "./../../../i18n/keys";import React, { Component } from 'react';
import CardWrapperComponent from "../../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import 'react-confirm-alert/src/react-confirm-alert.css';
import HonkioAPI from "../../../middlewares/HonkioAPI";
import 'react-slideshow-image/dist/styles.css';
import { Bar, Line, Pie } from 'react-chartjs-2';
import PageHeaderComponent from "../../ui_utils/PageHeaderComponent";

const Circular = () =>
<div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>;


class ProjectListOneComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      project: {},
      agreements: {} };

    this.loadProject = this.loadProject.bind(this);
    this.loadAgreements = this.loadAgreements.bind(this);
  }

  componentDidMount() {
    let projectId = this.props.match.params.projectId;
    this.loadProject(projectId);
  }



  loadProject(projectId) {
    let that = this;
    let response;
    let params = {
      "id": projectId };

    let merchantgetproject = async function () {
      response = await HonkioAPI().userShop.userFetch('merchantbuildingprojectlist', params);
      // console.log(response);
      that.setState({ 'project': response.projects[0], 'budget': response.result });
      that.loadAgreements(response.projects[0].building_id);
      // console.log(response);
    };
    merchantgetproject();

  }

  loadAgreements(buildingId) {
    let that = this;
    let merchantgetagreements = async function () {
      let response = await HonkioAPI().userShop.userFetch('merchantagreementlist', { "building_id": buildingId }); // todo: send start_date and end_date
      that.setState({ 'agreements': response.rents, res: response.res });
    };
    if (this.state.budget) {
      merchantgetagreements();
    }

  }

  render() {
    let that = this;
    let chartColors = {
      red: 'rgb(255, 99, 132)',
      orange: 'rgb(255, 159, 64)',
      yellow: 'rgb(255, 205, 86)',
      green: 'rgb(75, 192, 192)',
      green_a: 'rgba(75, 192, 192, 0.5)',
      blue: 'rgb(54, 162, 235)',
      purple: 'rgb(153, 102, 255)',
      grey: 'rgb(201, 203, 207)',
      grey_a: 'rgba(201, 203, 207, 0.5)' };

    let optionsMain = {
      hover: {
        enabled: false },

      title: {
        display: false,
        lineHeight: 2.5,
        text: ['Transactions orders amount', 'Click on a color box to hide/show a dataset'] },

      legend: {
        display: true },

      tooltips: {
        enabled: true },


      scales: {
        xAxes: [{
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Month' } }] } };




    let dataMain = {};
    let data = [];

    let labelsB = [];
    let dataB = [];
    let rentalIncomes = 0;

    if (this.state.res && this.state.budget) {
      this.state.res.forEach(function (res) {
        data.push(res['value']);
      });

      labelsB = this.state.budget['label'];
      dataB = this.state.budget['budget'];

      dataMain = {
        labels: labelsB,
        datasets: [{
          label: i18n.t(k.RENTAL_INCOME),
          backgroundColor: chartColors.green_a,
          borderColor: chartColors.green,
          borderWidth: 1,
          data: data,
          fill: true },
        {
          label: i18n.t(k.PROJECT_COSTS),
          fill: false,
          backgroundColor: chartColors.grey_a,
          borderColor: chartColors.grey,
          borderWidth: 1,
          data: dataB }] };

    }
    if (this.state.project && this.state.budget) {
      let label = this.state.budget['label'];
      // console.log(this.state);
      return (
        <div>
                     <div className="container-fluid">
                    <PageHeaderComponent
            title={`${i18n.t(k.PROJECT_L)} + ${this.state.project.name}`}
            subtitle={i18n.t(k.OVERVIEW)} />
          </div>
                    <CardWrapperComponent
          header={true}
          footer={true}
          card_title={`${i18n.t(k.PROJECT_L)} + ${this.state.project.name}`}>
                        <div className="row">
                            <div className="col-md-4">
                                 <div className="list-group">
                                    <li className="list-group-item pl-3">
                                         {i18n.t(k.PROJECT_BUDGET)} {this.state.project.budget} {i18n.t(k.EUR)}
                                    </li>
                                     <li className="list-group-item pl-3">
                                         {i18n.t(k.PROJECT_DESCRIPTION)} {this.state.project.description}
                                    </li>
                                     <li className="list-group-item pl-3">
                                         {i18n.t(k.PROJECT_DURATION)} {label.length} {i18n.t(k.MONTHS)}
                                    </li>
                                 </div>
                            </div>
                            <div className="col-md-8">
                                <Bar
                data={dataMain}
                title="asd"
                options={{ optionsMain }} />

                            </div>
                        </div>
                    </CardWrapperComponent>
                </div>);

    }
    return (
      <Circular />);

  }}


function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = dispatch => ({});


export default connect(mapStateToProps, mapDispatchToProps)(ProjectListOneComponent);
