import React from 'react'
import i18n from 'i18next';
import k from "./../../i18n/keys";
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Selector from './Selector';
import { NotificationManager, NotificationContainer } from "react-notifications";
import { APP_ID } from '../../config';
import getAdminRolesList from './../../actions/api/roles/getAdminRolesList';
import CardWrapperComponent from '../ui_utils/CardWrapperComponent';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const MerchantRolesList = ({ language = 'en' }) => {
    const [roles, setRoles] = useState(null);
    const pageTitle = i18n.t(k.USER_GROUPS)
    const [loading, setLoading] = useState(false);
    let isMounted = true;
    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        fetchAdminRolesList()
        return () => {
            isMounted = false
        };
    }, []);

    const fetchAdminRolesList = async () => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === 'accept') {
                    setRoles(data.roles)
                    setLoading(false);
                } else {
                    setLoading(false);
                    setRoles([])
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, i18n.t(k.ERROR), 4000)
            setRoles([])
            setLoading(false);
        }
        /*getAdminRolesList({ appid: APP_ID, merchant: localStorage.merchant_id })
        .then(({ roles }) => {
            return roles?.sort((a, b) => (a.role_id > b.role_id ? -1 : 1))
        })
        .then(setRoles)*/
        getAdminRolesList({ appid: APP_ID, merchant: localStorage.merchant_id }, errorHandler)
            .then(data => {
                return resultHandler(data)
            })
    }

    const onRoleClick = (role_id, role_name) => {
        // console.log(role_id, role_name)
        history.push(`/role/users/?role=${role_id}&role_name=${role_name}`)
    }
    return (
        <div>
            <NotificationContainer />
            <CardWrapperComponent
                header={true}
                card_title={pageTitle}>
                <div className="m-1">
                    {false && <Link
                        to={`/role/create?appid=${APP_ID}`}
                        className="btn btn-outline-primary m-1">
                        <i className="bi-plus-square" /> {i18n.t(k.CREATE_NEW_ROLE)}
                    </Link>}
                </div>
                <Selector
                    items={roles}
                    appid={APP_ID}
                    loading={loading}
                    onClick={onRoleClick}
                    language={language} />
            </CardWrapperComponent>
        </div>
    );
};

export default MerchantRolesList;
