import React, { useEffect, useState } from "react";
import InputFieldWithValidation from "./../../form/elements/InputFieldWithValidation";
import CheckboxSwitch from "./../../form/elements/CheckboxSwitch";
import UploadFileWithPreview from "../../form/elements/UploadFileWithPreview";
import ImagePreview from "./../../form/elements/ImagePreview";
import { Button } from "shards-react";
import AddressWithMap from "./AddressWithMap";
import SmartForm from "./../../form/SmartForm";
import MapStreetViewModal from "./../../ui_utils/MapStreetViewModal";
import BusinessId from "./BusinessId";

const AssetDinamicProperties = ({
    latitude = 0,
    longitude = 0,
    type,
    properties,
    onChangeProperties = () => {},
    onChangeCoordinates = () => {},
    onChangeStreenUrl = () => {},
}) => {
    const [assetProperties, setAssetProperties] = useState(properties);
    const [assetType, setAssetType] = useState(type);
    const [assetCoordinates, setAssetCoordinates] = useState([
        latitude,
        longitude,
    ]);
    const [assetStreetUrl, setAssetStreetUrl] = useState();

    const [showStreetViewModal, setShowStreetViewModal] = useState({
        visible: false,
        name: undefined,
    });
    const handleShowStreetViewModal = (name) => {
        // console.log(name, "-----------name");
        setShowStreetViewModal({ visible: true, name: name });
    };
    const handleCloseStreetViewModal = () => {
        setShowStreetViewModal({ visible: false, name: undefined });
    };

    const onChangeStreetUrl = (name, data) => {
        // console.log(name, data,"-----------------------------------------------0000000000000000000000000000000000000")
        setAssetProperties({
            ...assetProperties,
            [name]: data,
        });
        setAssetStreetUrl({ name: name, url: data });
        // handleCloseStreetViewModal();
    };

    const onChangeAddress = (name, address) => {
        if (address) {
            onChangeAssetProperties(name, address.address);
            setAssetCoordinates([address.latitude, address.longitude]);
        }
    };

    const onChangeBusinessId = (name, businessInfo) => {
        if (businessInfo) {
            onChangeAssetProperties(name, businessInfo);
        }
    };

    const onChangeAssetProperties = (name, value) => {
        setAssetProperties({
            ...assetProperties,
            [name]: value,
        });
    };
    useEffect(() => {
        if (assetProperties) {
            onChangeProperties(assetProperties);
        }
    }, [assetProperties]);

    useEffect(() => {
        if (assetCoordinates) {
            onChangeCoordinates(assetCoordinates);
        }
    }, [assetCoordinates]);

    useEffect(() => {
        if (assetStreetUrl) {
            onChangeStreenUrl(assetStreetUrl);
        }
    }, [assetStreetUrl]);
    //  console.log(assetProperties,"----------------------assetProperties")
    // TODO: Somewhere here is non-unique key - find and make unique
    const getElements = () => {
        return assetType.properties.map((e, i) => {
            // console.log(assetProperties,"----------------------assetProperties",e)
            switch (e.property_type) {
                case "float":
                    return (
                        <InputFieldWithValidation
                            className="mb-3"
                            name={e.property_name}
                            // k={`${e.property_name}-${e.property_type}-${i}`}
                            k={i}
                            key={`${e.property_name}-${e.property_type}-${i}`}
                            required={e.property_required}
                            label={`${e.property_description}(${e.property_type})`}
                            type="number"
                            step="0.1"
                            onChange={onChangeAssetProperties}
                            value={assetProperties[e.property_name]}
                        />
                    );
                case "int":
                    return (
                        <InputFieldWithValidation
                            className="mb-3"
                            // k={`${e.property_name}-${e.property_type}-${i}`}
                            k={i}
                            key={`${e.property_name}-${e.property_type}-${i}`}
                            name={e.property_name}
                            required={e.property_required}
                            label={`${e.property_description}(${e.property_type})`}
                            type="number"
                            step="1"
                            onChange={onChangeAssetProperties}
                            value={assetProperties[e.property_name]}
                        />
                    );
                case "str":
                    return (
                        <InputFieldWithValidation
                            className="mb-3"
                            k={i}
                            key={`${e.property_name}-${e.property_type}-${i}`}
                            name={e.property_name}
                            required={e.property_required}
                            label={`${e.property_description}(${e.property_type})`}
                            type="text"
                            onChange={onChangeAssetProperties}
                            value={assetProperties[e.property_name]}
                        />
                    );
                case "date":
                    return (
                        <InputFieldWithValidation
                            className="mb-3"
                            k={i}
                            key={`${e.property_name}-${e.property_type}-${i}`}
                            name={e.property_name}
                            required={e.property_required}
                            label={`${e.property_description}(${e.property_type})`}
                            type="date"
                            onChange={onChangeAssetProperties}
                            value={assetProperties[e.property_name]}
                        />
                    );
                case "bool":
                    return (
                        <CheckboxSwitch
                            k={i}
                            key={`${e.property_name}-${e.property_type}-${i}`}
                            className="ml-5"
                            name={e.property_name}
                            checked={assetProperties[e.property_name]}
                            onChange={onChangeAssetProperties}
                            label={e.property_description}
                        />
                    );
                case "image":
                    return (
                        <UploadFileWithPreview
                            k={i}
                            key={`${e.property_name}-${e.property_type}-${i}`}
                            className="col-md-6 mb-3"
                            name={e.property_name}
                            type="text"
                        />
                    );
                case "streetview":
                    return (
                        <div key={`${e.property_name}-${e.property_type}-${i}`}>
                            <ImagePreview
                                k={i}
                                className="col-md-6 mb-3"
                                name={e.property_name}
                                latitude={assetCoordinates[0]}
                                longitude={assetCoordinates[1]}
                                type="text"
                            />
                            <Button
                                variant="secondary"
                                onClick={() =>
                                    handleShowStreetViewModal(e.property_name)
                                }
                            >
                                Set new street photo
                            </Button>
                        </div>
                    );
                case "address":
                    return (
                        <AddressWithMap
                            className="mb-3"
                            k={i}
                            key={`${e.property_name}-${e.property_type}-${i}-addr`}
                            name={e.property_name}
                            required={e.property_required}
                            latitude={assetCoordinates[0]}
                            longitude={assetCoordinates[1]}
                            address={
                                assetProperties
                                    ? assetProperties[e.property_name]
                                    : undefined
                            }
                            onAddressChanged={onChangeAddress}
                            label={`${e.property_description}(${e.property_type})`}
                        />
                    );
                case "business_id":
                    return (
                        <BusinessId
                            className="mb-3"
                            k={i}
                            key={`${e.property_name}-${e.property_type}-${i}`}
                            name={e.property_name}
                            required={e.property_required}
                            latitude={assetCoordinates[0]}
                            longitude={assetCoordinates[1]}
                            businessInfo={
                                assetProperties
                                    ? assetProperties[e.property_name]
                                    : undefined
                            }
                            onBusinessIdChanged={onChangeBusinessId}
                            label={`${e.property_description}`}
                        />
                    );
                default:
                    return null;
                // code block
            }
        });
    };
    // console.log(assetType,"------typw",
    // assetProperties,"-------properties")
    return (
        <div>
            <SmartForm defaultValues={assetProperties}>
                {assetProperties && assetType && getElements()}
            </SmartForm>
            {showStreetViewModal?.visible && (
                <MapStreetViewModal
                    show={showStreetViewModal.visible}
                    onHide={() => handleCloseStreetViewModal()}
                    name={showStreetViewModal.name}
                    latitude={assetCoordinates[0]}
                    longitude={assetCoordinates[1]}
                    onSaveUrl={onChangeStreetUrl}
                />
            )}
        </div>
    );
};

export default AssetDinamicProperties;
