import i18n from 'i18next';import k from "./../../i18n/keys";import React, { Component } from 'react';
import { Button, Form, FormGroup, FormInput, FormSelect, Modal, ModalBody, ModalHeader } from "shards-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import HonkioAPI from "../../middlewares/HonkioAPI";

class TenantEditModalComponent extends Component {
  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
    this.state = { 'tenant': {} };
  }

  handleChange(e) {
    this.setState({ 'tenant': { ...this.state.tenant, [e.target.id]: e.target.value } });
  }

  save() {
    let that = this;
    this.setState({ 'loading': true });
    let saveTenant = async function () {
      let params = {
        firstname: that.state.tenant.firstname,
        lastname: that.state.tenant.lastname,
        phone: that.state.tenant.phone,
        email: that.state.tenant.email,
        role_id: that.props.roleId,
        buildings: [that.props.buildingId],
        consumer_create: true
      };

      let response = await HonkioAPI().userShop.userFetch("merchantuserinvite", params);
      that.setState({loading: false});

      if (response.status === "accept") {
        that.props.onTenantSaved();
      }
    };
    saveTenant();
  }

  render() {
    let saveButton = <Button onClick={this.save}>{i18n.t(k.SAVE)}</Button>;
    if (this.state.loading) {
      saveButton = <CircularProgress size={45} />;
    }
    return (

      <Modal open={this.props.visible}
      toggle={this.props.toggle}
      onRequestClose={this.props.onRequestClose}
      className="w-100 modal-lg modal-dialog-centered">
                <ModalHeader>{this.state.tenant === null ? i18n.t(k.CREATE_TENANT) : i18n.t(k.EDIT_TENANT)}</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <label htmlFor="firstname">{i18n.t(k.FIRSTNAME)}</label>
                            <FormInput id="firstname"
                            value={this.state.tenant.firstname}
                            onChange={e => this.handleChange(e)} />
                        </FormGroup>
                       <FormGroup>
                            <label htmlFor="lastname">{i18n.t(k.LASTNAME)}</label>
                            <FormInput id="lastname"
                            value={this.state.tenant.lastname}
                            onChange={e => this.handleChange(e)} />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="email">{i18n.t(k.EMAIL)}</label>
                            <FormInput id="email"
              value={this.state.tenant.email}
              onChange={e => this.handleChange(e)} />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="phone">{i18n.t(k.PHONE)}</label>
                            <FormInput id="phone"
              value={this.state.tenant.phone}
              onChange={e => this.handleChange(e)} />
                        </FormGroup>
                        {saveButton}
                    </Form>
                </ModalBody>
            </Modal>);

  }}


export default TenantEditModalComponent;
