import React, { Component } from "react";
import { supportedLanguages } from "../../i18n/i18helper";
import ReactFlagsSelect from "react-flags-select";
import HonkioAPI from "../../middlewares/HonkioAPI";
import { consumerInfoFailure } from "../../actions/api/consumer/consumerGetActions";

class LanguageSelectorComponent extends Component {
    render() {
        let lang = localStorage.getItem("i18nextLng") || "en";
        // alert(lang)
        return (
            <ReactFlagsSelect
                id="languageSelector"
                selected={lang === "en" ? "GB" : lang.toUpperCase()}
                customLabels={{ GB: "English", FI: "Finnish" }}
                countries={supportedLanguages.map(function (x) {
                    if (x === "en") return "GB";
                    if (x === "fi") return "FI";
                    return x.toUpperCase();
                })}
                onSelect={(code) => {
                    if (code === "GB") code = "en";
                    if (code === "FI") code = "fi";
                    localStorage.setItem("i18nextLng", code);

                    const setLang = async () => {
                        let consumerget = await HonkioAPI().mainShop.userFetch(
                            "consumerget",
                            {}
                        );
                        await HonkioAPI().mainShop.userFetch("consumerset", {
                            consumer_str_language: code,
                            consumer_str_email: consumerget.login_identity,
                        });
                        window.location.reload();
                    };
                    setLang();
                }}
                showOptionLabel={true}
                showSelectedLabel={false}
                // className="m-0 p-0 d-flex justify-content-start align-items-center"
                className="m-0 p-0 nav-item dropdown d-flex"
                style={{
                    border: "none",
                }}
            />
        );
    }
}

export default LanguageSelectorComponent;
