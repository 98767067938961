import i18n from 'i18next';
import k from "./../../../i18n/keys";
import React, { Component } from 'react';
import { Form } from "shards-react";

class AgreementPreviewComponent extends Component {
  constructor(props) {
    super(props);
  }

  // componentWillReceiveProps(nextProps, nextContext){
  //     if (nextProps.tenant !== undefined) {
  //         this.setState({'tenant': nextProps.tenant !== null ? nextProps.tenant : {}})
  //     }
  // }

  handleChange(e) {
    this.setState({ 'tenant': { ...this.state.tenant, [e.target.id]: e.target.value } });
  }

  render() {
    let that = this;
    let fields = [];
    if (this.props.agreement?.rules) {
      Object.keys(this.props.agreement.rules).forEach(k => {
        fields.push({
          'label': this.props.agreement.rules[k].label,
          'value': this.props.agreement.rules[k].value
        });

      });
    }

    return (
      <Form>
        <h4>{i18n.t(k.AGREEMENT_REVIEW)}</h4>
        <hr />
        <p>{i18n.t(k.TITLE2)} {this.props.agreement.title}</p>
        <p>{i18n.t(k.DESCRIPTION1)} {this.props.agreement.description}</p>
        {fields.map((item, index) => {
          return <p>{item.label}{i18n.t(k._7)} {item.value}</p>;
        })}
      </Form>);
  }
}

export default AgreementPreviewComponent;