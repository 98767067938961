module.exports = {
    TOKEN: "TOKEN",
    WHOOPS_THE_APP_HAS_CRASHED: "WHOOPS_THE_APP_HAS_CRASHED",
    AVERAGE_MATURITY: "AVERAGE_MATURITY",
    TENATNS: "TENATNS",
    _: "_",
    RENTED_AREA_COST: "RENTED_AREA_COST",
    _1: "_1",
    EURO: "EURO",
    RENTED_AREA: "RENTED_AREA",
    _2: "_2",
    M: "M",
    PROPERTY_INCOMES_EXAMPLE_DATA: "PROPERTY_INCOMES_EXAMPLE_DATA",
    LOADING: "LOADING",
    WELCOME_TO_RENTAL_MANAGEMENT_P: "WELCOME_TO_RENTAL_MANAGEMENT_P",
    PLEASE: "PLEASE",
    LOGIN: "LOGIN",
    YOU_WOULD_LIKE_TO_START_RENTAL: "YOU_WOULD_LIKE_TO_START_RENTAL",
    TRY: "TRY",
    RENTAL_SOLUTION_FOR_FREE: "RENTAL_SOLUTION_FOR_FREE",
    ADD_CATEGORY_GROUP: "ADD_CATEGORY_GROUP",
    SAVE_PRODUCT_CATEGORIES_GROUPS: "SAVE_PRODUCT_CATEGORIES_GROUPS",
    _3: "_3",
    SEARCH_CATEGORY: "SEARCH_CATEGORY",
    ALL_CATEGORIES: "ALL_CATEGORIES",
    PRODUCT_CATEGORIES_GROUPS: "PRODUCT_CATEGORIES_GROUPS",
    BANK_SETTINGS: "BANK_SETTINGS",
    BANK_NAME: "BANK_NAME",
    BANK_IBAN: "BANK_IBAN",
    BANK_BIC: "BANK_BIC",
    INVOICE_TEMPLATE: "INVOICE_TEMPLATE",
    SAVE: "SAVE",
    TERMS_OF_USE: "TERMS_OF_USE",
    PLEASE_ACCEPT_TERMS_OF_USE_IN: "PLEASE_ACCEPT_TERMS_OF_USE_IN",
    ACCEPT: "ACCEPT",
    SEND_AGREEMENT_TO_TENANT: "SEND_AGREEMENT_TO_TENANT",
    AGREEMENT_TITLE: "AGREEMENT_TITLE",
    AGREEMENT_DESCRIPTION: "AGREEMENT_DESCRIPTION",
    AREA_RESERVABLE: "AREA_RESERVABLE",
    AGREEMENT_CREATED_AT: "AGREEMENT_CREATED_AT",
    AGREEMENT_START_DATE: "AGREEMENT_START_DATE",
    AGREEMENT_END_DATE: "AGREEMENT_END_DATE",
    AGREEMENT_ADDITIONAL_TEXT: "AGREEMENT_ADDITIONAL_TEXT",
    AGREEMENT_RENT_AMOUNT: "AGREEMENT_RENT_AMOUNT",
    RENT_AREA: "RENT_AREA",
    TENANT_EMAIL: "TENANT_EMAIL",
    TENANT_NAME: "TENANT_NAME",
    BUILDING_NAME: "BUILDING_NAME",
    PROPERTY_NAME: "PROPERTY_NAME",
    AGREEMENT_STATE: "AGREEMENT_STATE",
    ARE_YOU_SURE_WANT_TO_DELETE: "ARE_YOU_SURE_WANT_TO_DELETE",
    _4: "_4",
    ARE_YOU_SURE_WANT_TO_DELETE_AG: "ARE_YOU_SURE_WANT_TO_DELETE_AG",
    NEW_AGREEMENT: "NEW_AGREEMENT",
    _5: "_5",
    NAME: "NAME",
    DESCRIPTION: "DESCRIPTION",
    TENANT: "TENANT",
    BUILDING: "BUILDING",
    PROPERTY: "PROPERTY",
    START_DATE: "START_DATE",
    END_DATE: "END_DATE",
    STATUS: "STATUS",
    ACTION: "ACTION",
    NOT_SET: "NOT_SET",
    RESERVED_AREA_AMOUNT: "RESERVED_AREA_AMOUNT",
    AMOUNT: "AMOUNT",
    CURRENCY: "CURRENCY",
    EUR: "EUR",
    SEK: "SEK",
    RENT_PRICE: "RENT_PRICE",
    AMOUNT_PER_MONTH: "AMOUNT_PER_MONTH",
    PER_USER: "PER_USER",
    BASIC_INFORMATION: "BASIC_INFORMATION",
    TITLE: "TITLE",
    AGREEMENT_ID: "AGREEMENT_ID",
    BUILDING1: "BUILDING1",
    PROPERTY1: "PROPERTY1",
    AGREEMENT_DURATION: "AGREEMENT_DURATION",
    RESERVE: "RESERVE",
    RELEASE_PERIOD: "RELEASE_PERIOD",
    SELECT_RELEASE_PERIOD: "SELECT_RELEASE_PERIOD",
    MONTH: "MONTH",
    MONTH1: "MONTH1",
    MONTH2: "MONTH2",
    BILLING_CYCLE_START_DATE: "BILLING_CYCLE_START_DATE",
    CURRENT_MONTH: "CURRENT_MONTH",
    TWO_MONTH: "TWO_MONTH",
    NEXT_WEEK: "NEXT_WEEK",
    PREV_NEXT: "PREV_NEXT",
    TITLE1: "TITLE1",
    TIMEGRIDMONTH_TIMEGRIDWEEK_TIM: "TIMEGRIDMONTH_TIMEGRIDWEEK_TIM",
    AGREEMENT_REVIEW: "AGREEMENT_REVIEW",
    TITLE2: "TITLE2",
    DESCRIPTION1: "DESCRIPTION1",
    _7: "_7",
    AREA_SELECTED_CLICK_NEXT_TO_C: "AREA_SELECTED_CLICK_NEXT_TO_C",
    SELECT_FLOORPLAN: "SELECT_FLOORPLAN",
    SELECT_AN_AREA: "SELECT_AN_AREA",
    CREATE_AN_AREA: "CREATE_AN_AREA",
    IMPORT_FLOORPLAN: "IMPORT_FLOORPLAN",
    ARE_YOU_SURE_WANT_TO_DELETE_AG1: "ARE_YOU_SURE_WANT_TO_DELETE_AG1",
    SELECT: "SELECT",
    AGREEMENT_TEMPLATES: "AGREEMENT_TEMPLATES",
    ARE_YOU_SURE_WANT_TO_DELETE1: "ARE_YOU_SURE_WANT_TO_DELETE1",
    TENANTS: "TENANTS",
    TENANT1: "TENANT1",
    NOTHING_SELECTED: "NOTHING_SELECTED",
    CREATE_NEW_TENANT: "CREATE_NEW_TENANT",
    EMAIL: "EMAIL",
    PHONE: "PHONE",
    BACK: "BACK",
    CREATE_NEW: "CREATE_NEW",
    NEXT: "NEXT",
    CREATE_TEMPLATE: "CREATE_TEMPLATE",
    RETURN: "RETURN",
    SIGN_AN_AGREEMENT: "SIGN_AN_AGREEMENT",
    SECRET_MISMATCH: "SECRET_MISMATCH",
    SOMETHING_SHORT_AND_LEADING_AB: "SOMETHING_SHORT_AND_LEADING_AB",
    RESET: "RESET",
    THE_AGREEMENT_HAS_BEEN_SIGNED: "THE_AGREEMENT_HAS_BEEN_SIGNED",
    THE_AGREEMENT_HAS_BEEN_SIGNED1: "THE_AGREEMENT_HAS_BEEN_SIGNED1",
    OK: "OK",
    CREATE_NEW_AREA: "CREATE_NEW_AREA",
    CREATE_BUILDING_MEMBER: "CREATE_BUILDING_MEMBER",
    SAVE_AREA: "SAVE_AREA",
    TRUE: "TRUE",
    FALSE: "FALSE",
    CREATE_AREA: "CREATE_AREA",
    TOTAL_AREA: "TOTAL_AREA",
    AREA_TYPE: "AREA_TYPE",
    RESERVABLE: "RESERVABLE",
    UPLOAD_DOCUMENT: "UPLOAD_DOCUMENT",
    ROW: "ROW",
    ARE_YOU_SURE_WANT_TO_DELETE_ME: "ARE_YOU_SURE_WANT_TO_DELETE_ME",
    UPLOAD_FILE: "UPLOAD_FILE",
    DELETE: "DELETE",
    AREA_TYPE1: "AREA_TYPE1",
    SHAREHOLDER: "SHAREHOLDER",
    SHAREHOLDER_SSN: "SHAREHOLDER_SSN",
    SHAREHOLDER_EMAIL: "SHAREHOLDER_EMAIL",
    SHAREHOLDER_NAME: "SHAREHOLDER_NAME",
    SHAREHOLDER_BUSINESS_ID: "SHAREHOLDER_BUSINESS_ID",
    SHAREHOLDER_CONTACT_EMAIL: "SHAREHOLDER_CONTACT_EMAIL",
    SHAREHOLDER_BUSINESS_NAME: "SHAREHOLDER_BUSINESS_NAME",
    SHAREHOLDER_TYPE: "SHAREHOLDER_TYPE",
    SHAREHOLDER_PHONE: "SHAREHOLDER_PHONE",
    SHAREHOLDER_ADDRESS: "SHAREHOLDER_ADDRESS",
    SHAREHOLDER_POSTAL_CODE: "SHAREHOLDER_POSTAL_CODE",
    SHAREHOLDER_CITY: "SHAREHOLDER_CITY",
    AREA_NAME: "AREA_NAME",
    TOTAL_AREA_M: "TOTAL_AREA_M",
    NUMBER_OF_SHARES: "NUMBER_OF_SHARES",
    TIME_SLOT: "TIME_SLOT",
    LONG_TERM: "LONG_TERM",
    MIN: "MIN",
    MIN1: "MIN1",
    HOUR: "HOUR",
    BUSINESS: "BUSINESS",
    PERSON: "PERSON",
    RESIDENTIAL_APARTMENT: "RESIDENTIAL_APARTMENT",
    OFFICE: "OFFICE",
    PREMISE: "PREMISE",
    STOCK: "STOCK",
    STAIRWELL: "STAIRWELL",
    SAUNA: "SAUNA",
    LAUNDRY_ROOM: "LAUNDRY_ROOM",
    DRYING_ROOM: "DRYING_ROOM",
    CRAFT_ROOM: "CRAFT_ROOM",
    CLUBROOM: "CLUBROOM",
    GARAGE: "GARAGE",
    BICYCLE_STORAGE: "BICYCLE_STORAGE",
    TECHNICAL_ROOM: "TECHNICAL_ROOM",
    COLD_CELLAR: "COLD_CELLAR",
    AIR_RAID_SHELTER: "AIR_RAID_SHELTER",
    POOL_AREA: "POOL_AREA",
    MEETING_ROOM: "MEETING_ROOM",
    PUBLIC_SPACE: "PUBLIC_SPACE",
    OTHER: "OTHER",
    CREATE_NEW_SHAREHOLDER: "CREATE_NEW_SHAREHOLDER",
    BUSINESS1: "BUSINESS1",
    PERSON1: "PERSON1",
    RESIDENTIAL_APARTMENT1: "RESIDENTIAL_APARTMENT1",
    OFFICE1: "OFFICE1",
    PREMISE1: "PREMISE1",
    STOCK1: "STOCK1",
    STAIRWELL1: "STAIRWELL1",
    SAUNA1: "SAUNA1",
    LAUNDRY_ROOM1: "LAUNDRY_ROOM1",
    DRYING_ROOM1: "DRYING_ROOM1",
    CRAFT_ROOM1: "CRAFT_ROOM1",
    CLUBROOM1: "CLUBROOM1",
    GARAGE1: "GARAGE1",
    BICYCLE_STORAGE1: "BICYCLE_STORAGE1",
    TECHNICAL_ROOM1: "TECHNICAL_ROOM1",
    COLD_CELLAR1: "COLD_CELLAR1",
    AIR_RAID_SHELTER1: "AIR_RAID_SHELTER1",
    THE_POOL_AREA: "THE_POOL_AREA",
    MEETING_ROOM1: "MEETING_ROOM1",
    PUBLIC_SPACE1: "PUBLIC_SPACE1",
    OTHER1: "OTHER1",
    CHOOSE: "CHOOSE",
    EDIT_AREA: "EDIT_AREA",
    BUSINESS_HOURS_EDIT: "BUSINESS_HOURS_EDIT",
    YOU_CAN_EDIT_YOUR_BUSINESS_HOU: "YOU_CAN_EDIT_YOUR_BUSINESS_HOU",
    MONDAY: "MONDAY",
    _8: "_8",
    TUESDAY: "TUESDAY",
    WEDNESDAY: "WEDNESDAY",
    THURSDAY: "THURSDAY",
    FRIDAY: "FRIDAY",
    SATURDAY: "SATURDAY",
    SUNDAY: "SUNDAY",
    STEPS: "STEPS",
    SHAREHOLDER_BUSINESSS_NAME: "SHAREHOLDER_BUSINESSS_NAME",
    CHOOSE_SHAREHOLDER: "CHOOSE_SHAREHOLDER",
    NULL: "NULL",
    PLEASE_SELECT_SHAREHOLDER: "PLEASE_SELECT_SHAREHOLDER",
    BUSINESS_ID: "BUSINESS_ID",
    ADDRESS: "ADDRESS",
    PROPERTY_MANAGER: "PROPERTY_MANAGER",
    BUILDING_PICTURE: "BUILDING_PICTURE",
    ADDITIONAL_FIELDS: "ADDITIONAL_FIELDS",
    NUMBER_OF_SHARES_TOTAL: "NUMBER_OF_SHARES_TOTAL",
    TOTAL_AREA_M1: "TOTAL_AREA_M1",
    BUILDING_RIGHTS_M: "BUILDING_RIGHTS_M",
    TOTAL_VOLUME_M: "TOTAL_VOLUME_M",
    ELECTRICITY_CONSUMPTION_KWH: "ELECTRICITY_CONSUMPTION_KWH",
    HEATING_ENERGY_CONSUMPTION_IN: "HEATING_ENERGY_CONSUMPTION_IN",
    COOLING_ENERGY_CONSUMPTION_IN: "COOLING_ENERGY_CONSUMPTION_IN",
    TOTAL_WATER_CONSUMPTION: "TOTAL_WATER_CONSUMPTION",
    PLAN_DEFINED_PARKING_PLACES: "PLAN_DEFINED_PARKING_PLACES",
    ACTUAL_PARKING_PLACES: "ACTUAL_PARKING_PLACES",
    NUMBER_OF_CAR_PORT_PLACES: "NUMBER_OF_CAR_PORT_PLACES",
    NUMBER_OF_ELECTRIC_CONNECTORS: "NUMBER_OF_ELECTRIC_CONNECTORS",
    NUMBER_OF_CHARGE_PLACES_FOR_CA: "NUMBER_OF_CHARGE_PLACES_FOR_CA",
    BUILDING_YEAR_OF_GRADUATION: "BUILDING_YEAR_OF_GRADUATION",
    NUMBER_OF_ELEVATORS: "NUMBER_OF_ELEVATORS",
    NUMBER_OF_STAIRWELLS: "NUMBER_OF_STAIRWELLS",
    COMMERCIAL_BUILDING: "COMMERCIAL_BUILDING",
    HOUSING: "HOUSING",
    ACCOMMODATION_SPACE: "ACCOMMODATION_SPACE",
    ACCOMMODATION: "ACCOMMODATION",
    WORKPLACE_BUILDING: "WORKPLACE_BUILDING",
    NURSING_HOME: "NURSING_HOME",
    CONCRETE: "CONCRETE",
    BRICK: "BRICK",
    STEEL: "STEEL",
    WOOD: "WOOD",
    CEILING_BRUSH: "CEILING_BRUSH",
    HIP_ROOF: "HIP_ROOF",
    PITCHED_ROOF: "PITCHED_ROOF",
    MANSARD_ROOF: "MANSARD_ROOF",
    FLAT_ROOF: "FLAT_ROOF",
    TOWER_ROOF: "TOWER_ROOF",
    INVERTED_GABLE_ROOF: "INVERTED_GABLE_ROOF",
    SEMI_HIP_ROOF: "SEMI_HIP_ROOF",
    SERIES_SHED_ROOF: "SERIES_SHED_ROOF",
    SHEET_METAL: "SHEET_METAL",
    CONCRETE_ROOF_TILE: "CONCRETE_ROOF_TILE",
    MACHINE_SEALED_DAMPER: "MACHINE_SEALED_DAMPER",
    ADOBE: "ADOBE",
    BITUMEN_TREATED_FELT: "BITUMEN_TREATED_FELT",
    COPPER: "COPPER",
    SHINGLE: "SHINGLE",
    LEASEHOLD: "LEASEHOLD",
    OWN_LAND: "OWN_LAND",
    DSL: "DSL",
    HOME_PNA: "HOME_PNA",
    OPTICAL_FIBER: "OPTICAL_FIBER",
    ETHERNET: "ETHERNET",
    DISTRICT_COOLING: "DISTRICT_COOLING",
    AIR_CONDITIONING: "AIR_CONDITIONING",
    DISTRICT_HEATING: "DISTRICT_HEATING",
    OIL_HEATING: "OIL_HEATING",
    GEOTHERMAL_HEAT: "GEOTHERMAL_HEAT",
    PEAT: "PEAT",
    BRIQUET: "BRIQUET",
    ELECTRIC: "ELECTRIC",
    MECHANICAL: "MECHANICAL",
    MECHANICAL_HEAT_RECOVERY: "MECHANICAL_HEAT_RECOVERY",
    SERIES_SHED_ROOF1: "SERIES_SHED_ROOF1",
    BUILDING2: "BUILDING2",
    BUILDING3: "BUILDING3",
    AVERAGE_MATURITY1: "AVERAGE_MATURITY1",
    BUILDING4: "BUILDING4",
    PROPERTY_MANAGER1: "PROPERTY_MANAGER1",
    BUILDING5: "BUILDING5",
    ACTUAL_PARKING_PLACES1: "ACTUAL_PARKING_PLACES1",
    BUSINESS_ID1: "BUSINESS_ID1",
    BUILDING_RIGHTS: "BUILDING_RIGHTS",
    BUILDING_TYPE: "BUILDING_TYPE",
    BUILDING_YEAR_OF_GRADUATION1: "BUILDING_YEAR_OF_GRADUATION1",
    CHARGE_PLACES_FOR_CARS: "CHARGE_PLACES_FOR_CARS",
    CONSTRUCTION_MATERIAL: "CONSTRUCTION_MATERIAL",
    COOLING_SYSTEM: "COOLING_SYSTEM",
    DATA_CABELS: "DATA_CABELS",
    VENTILATION_SYSTEM: "VENTILATION_SYSTEM",
    ELECTRIC_CONNECTORS_TO_CARS: "ELECTRIC_CONNECTORS_TO_CARS",
    ELECTRICITY_CONSUMPTION: "ELECTRICITY_CONSUMPTION",
    ELEVATORS: "ELEVATORS",
    HEATING_ENERGY_CONSUMPTION: "HEATING_ENERGY_CONSUMPTION",
    HEATING_SYSTEM: "HEATING_SYSTEM",
    OWN_OR_LEASED_LAND: "OWN_OR_LEASED_LAND",
    SHARES: "SHARES",
    PLAN_DEFINED_PARKING_PLACES1: "PLAN_DEFINED_PARKING_PLACES1",
    ROOF_MATERIAL: "ROOF_MATERIAL",
    ROOF_TYPE: "ROOF_TYPE",
    STAIRWELLS: "STAIRWELLS",
    TOTAL_AREA1: "TOTAL_AREA1",
    TOTAL_VOLUME: "TOTAL_VOLUME",
    TOTAL_WATER_CONSUMPTION1: "TOTAL_WATER_CONSUMPTION1",
    AREAS_IN_BUILDING: "AREAS_IN_BUILDING",
    BUILDINGS_IN_PROPERTY: "BUILDINGS_IN_PROPERTY",
    LOADING1: "LOADING1",
    TOTAL_AREA2: "TOTAL_AREA2",
    TIME_SLOT1: "TIME_SLOT1",
    OWNER_NAME: "OWNER_NAME",
    OWNER_EMAIL: "OWNER_EMAIL",
    SELECT_A_BUILDING: "SELECT_A_BUILDING",
    SELECT_A_PROPERTY: "SELECT_A_PROPERTY",
    NO_IMAGE: "NO_IMAGE",
    BUILDINGEDIT_NEW: "BUILDINGEDIT_NEW",
    PROPERTYEDIT_NEW: "PROPERTYEDIT_NEW",
    ADD: "ADD",
    FLOORPLANS: "FLOORPLANS",
    IMAGE: "IMAGE",
    PROPERTY_MANAGER2: "PROPERTY_MANAGER2",
    APPOINT: "APPOINT",
    APPOINT_A_CHAIRMAN: "APPOINT_A_CHAIRMAN",
    CREATE_EVENT: "CREATE_EVENT",
    TYPE: "TYPE",
    BASIC: "BASIC",
    BASIC1: "BASIC1",
    GENERAL: "GENERAL",
    GENERAL1: "GENERAL1",
    AGENDA: "AGENDA",
    SAVE_EVENT: "SAVE_EVENT",
    YOU_HAVE: "YOU_HAVE",
    UNASSIGNED_SHARES: "UNASSIGNED_SHARES",
    YOU_HAVE_ASSIGNED: "YOU_HAVE_ASSIGNED",
    MORE_SHARES_THAN: "MORE_SHARES_THAN",
    ADD_SHAREHOLDER: "ADD_SHAREHOLDER",
    ADD_BUILDING_MEMBER: "ADD_BUILDING_MEMBER",
    ADD_BOARD_MEMBER: "ADD_BOARD_MEMBER",
    SCHEDULE_A_MEETING_OF_SHAREHOL: "SCHEDULE_A_MEETING_OF_SHAREHOL",
    DESTRUCTIVE_ACTION: "DESTRUCTIVE_ACTION",
    ID: "ID",
    ZIP: "ZIP",
    CITY: "CITY",
    HONKIO_USER: "HONKIO_USER",
    CHAIRMAN: "CHAIRMAN",
    BOARD_MEMBER: "BOARD_MEMBER",
    IS_SHAREHOLDER: "IS_SHAREHOLDER",
    CHECK_REQUIRED_DOCUMENTS: "CHECK_REQUIRED_DOCUMENTS",
    EDIT_BUILDING_CERTIFICATE_TEMP: "EDIT_BUILDING_CERTIFICATE_TEMP",
    REQUEST_BUILDING_CERTIFICATE: "REQUEST_BUILDING_CERTIFICATE",
    REQUIRED_DOCUMENTS: "REQUIRED_DOCUMENTS",
    FINANCIAL_STATEMENTS: "FINANCIAL_STATEMENTS",
    ACTION_REPORT: "ACTION_REPORT",
    BUDGET: "BUDGET",
    AUDITORS_REPORT: "AUDITORS_REPORT",
    BOARD_OF_DIRECTORS_REPORT: "BOARD_OF_DIRECTORS_REPORT",
    ENERGY_CERTIFICATE: "ENERGY_CERTIFICATE",
    RULES_OF_PROCEDURE: "RULES_OF_PROCEDURE",
    ARTICLES_OF_ASSOCIATION: "ARTICLES_OF_ASSOCIATION",
    YES: "YES",
    NO: "NO",
    FINANCIAL_STATEMENTS1: "FINANCIAL_STATEMENTS1",
    ACTION_REPORT1: "ACTION_REPORT1",
    BUDGET1: "BUDGET1",
    AUDITORS_REPORT1: "AUDITORS_REPORT1",
    BOARD_OF_DIRECTORS_REPORT1: "BOARD_OF_DIRECTORS_REPORT1",
    ENERGY_CERTIFICATE1: "ENERGY_CERTIFICATE1",
    RULES_OF_PROCEDURE1: "RULES_OF_PROCEDURE1",
    ARTICLES_OF_ASSOCIATION1: "ARTICLES_OF_ASSOCIATION1",
    PLEASE_SELECT_A_DOCUMENT_TYPE: "PLEASE_SELECT_A_DOCUMENT_TYPE",
    LOAD_BUILDING_CERT: "LOAD_BUILDING_CERT",
    GENERATE_PDF: "GENERATE_PDF",
    CREATE_PROJECT: "CREATE_PROJECT",
    EDIT_PROJECT: "EDIT_PROJECT",
    SIX_MONTH: "SIX_MONTH",
    PROJECT_BUDGET: "PROJECT_BUDGET",
    PROJECT_DESCRIPTION: "PROJECT_DESCRIPTION",
    PROJECT_DURATION: "PROJECT_DURATION",
    MONTHS: "MONTHS",
    RENTAL_INCOME: "RENTAL_INCOME",
    PROJECT_COSTS: "PROJECT_COSTS",
    PROJECT: "PROJECT",
    DASHBOARD: "DASHBOARD",
    DAY: "DAY",
    SECONDARY: "SECONDARY",
    LIGHT: "LIGHT",
    DAY1: "DAY1",
    WEEK: "WEEK",
    WEEK1: "WEEK1",
    MONTH3: "MONTH3",
    MONTH4: "MONTH4",
    CREATE_NEW_CARD: "CREATE_NEW_CARD",
    _9: "_9",
    _10: "_10",
    _11: "_11",
    MATURITY: "MATURITY",
    TENANTS1: "TENANTS1",
    SQUARE: "SQUARE",
    RENTAL_COST: "RENTAL_COST",
    NONE: "NONE",
    RENTED_SQUARE: "RENTED_SQUARE",
    RENTAL_COST1: "RENTAL_COST1",
    IMPORT_A_SENSOR: "IMPORT_A_SENSOR",
    PRIMARY: "PRIMARY",
    IMPORT: "IMPORT",
    TEMPERATURE: "TEMPERATURE",
    HUMIDITY: "HUMIDITY",
    PROXIMITY: "PROXIMITY",
    TOUCH: "TOUCH",
    FAS_FA_THERMOMETER_HALF_FA_X: "FAS_FA_THERMOMETER_HALF_FA_X",
    FAS_FA_TINT_FA_X: "FAS_FA_TINT_FA_X",
    FAS_FA_DOOR_OPEN_FA_X: "FAS_FA_DOOR_OPEN_FA_X",
    FAS_FA_HAND_POINT_UP_FA_X: "FAS_FA_HAND_POINT_UP_FA_X",
    TENANTS_COUNT: "TENANTS_COUNT",
    AREA_SQUARE: "AREA_SQUARE",
    RENTAL_COST2: "RENTAL_COST2",
    ADD_A_WIDGET: "ADD_A_WIDGET",
    IOT_SENSOR: "IOT_SENSOR",
    GENERIC: "GENERIC",
    C: "C",
    MINUTES_AGO: "MINUTES_AGO",
    MAX: "MAX",
    AVG: "AVG",
    MIN2: "MIN2",
    GRAY: "GRAY",
    WHITE: "WHITE",
    ANOMALY: "ANOMALY",
    BLACK: "BLACK",
    DB_D: "DB_D",
    LAST_OPEN: "LAST_OPEN",
    _12: "_12",
    LAST_TOUCHED: "LAST_TOUCHED",
    RENAME: "RENAME",
    SENSOR_DETAILS: "SENSOR_DETAILS",
    REMOVE: "REMOVE",
    IMAGE_FILES_ONLY: "IMAGE_FILES_ONLY",
    CLICK_HERE_TO_UPLOAD_FILE: "CLICK_HERE_TO_UPLOAD_FILE",
    FLOORPLAN_NEW: "FLOORPLAN_NEW",
    IMPORT_FLOORPLAN1: "IMPORT_FLOORPLAN1",
    AREAS_FLOORPLAN: "AREAS_FLOORPLAN",
    THUMBNAIL: "THUMBNAIL",
    SHOW_QR: "SHOW_QR",
    QR_CODE: "QR_CODE",
    ASK_USER_TO_VISIT_HIS_EMAIL_O: "ASK_USER_TO_VISIT_HIS_EMAIL_O",
    USER_EXISTS_GROUP_MEMBERSHIP: "USER_EXISTS_GROUP_MEMBERSHIP",
    FIRST_NAME: "FIRST_NAME",
    LAST_NAME: "LAST_NAME",
    PHONE_NUMBER: "PHONE_NUMBER",
    MESSAGE_TO_USER: "MESSAGE_TO_USER",
    INVITE_USER_TO: "INVITE_USER_TO",
    INVITE_BTN: "INVITE_BTN",
    INVITE: "INVITE",
    WELCOME: "WELCOME",
    _13: "_13",
    _14: "_14",
    HAS: "HAS",
    IN_ORDER_TO_PROCEED: "IN_ORDER_TO_PROCEED",
    PLEASE_INSTALL_OUR_APPLICATION: "PLEASE_INSTALL_OUR_APPLICATION",
    OPEN: "OPEN",
    IN_THE_APPLICATION: "IN_THE_APPLICATION",
    ADD_LIABILITY: "ADD_LIABILITY",
    ADD_ASSET: "ADD_ASSET",
    INVOICEEDIT_NEW: "INVOICEEDIT_NEW",
    ADD_INVOICE: "ADD_INVOICE",
    PURCHASEEDIT_NEW: "PURCHASEEDIT_NEW",
    ADD_PURCHASE: "ADD_PURCHASE",
    SELECT_TYPE: "SELECT_TYPE",
    INVOICE: "INVOICE",
    PURCHASE_INVOICE: "PURCHASE_INVOICE",
    LIABILITIES: "LIABILITIES",
    ASSETS: "ASSETS",
    MERCHANTSETTINGS: "MERCHANTSETTINGS",
    MERCHANT: "MERCHANT",
    D_BLOCK_MX_AUTO_W: "D_BLOCK_MX_AUTO_W",
    W_FLOAT_RIGHT: "W_FLOAT_RIGHT",
    ADD_PAYMENT: "ADD_PAYMENT",
    PAID_AMOUNT: "PAID_AMOUNT",
    PAID_VAT_AMOUNT: "PAID_VAT_AMOUNT",
    CHANGE_STATUS: "CHANGE_STATUS",
    _15: "_15",
    INVOICE_ID: "INVOICE_ID",
    DATES: "DATES",
    DUE_DATE: "DUE_DATE",
    DAYS: "DAYS",
    SEND_IN_NUMBER_OF_DAYS_BEFORE: "SEND_IN_NUMBER_OF_DAYS_BEFORE",
    CREATED_DATE: "CREATED_DATE",
    TOTAL_AMOUNT: "TOTAL_AMOUNT",
    VAT_AMOUNT: "VAT_AMOUNT",
    LATE_INTEREST_FEE: "LATE_INTEREST_FEE",
    BILLER_DETAILS: "BILLER_DETAILS",
    DUE_DATE1: "DUE_DATE1",
    SEND_DATE: "SEND_DATE",
    _16: "_16",
    _17: "_17",
    _18: "_18",
    APPLICATION_PDF: "APPLICATION_PDF",
    PENDING: "PENDING",
    PART_PAID: "PART_PAID",
    PAID: "PAID",
    OVER_PAID: "OVER_PAID",
    ERROR: "ERROR",
    APPROVED: "APPROVED",
    SENT: "SENT",
    PREVIEW_INVOICE: "PREVIEW_INVOICE",
    ADD_A_PRODUCT: "ADD_A_PRODUCT",
    AMOUNT_NO_VAT: "AMOUNT_NO_VAT",
    VAT_CLASS: "VAT_CLASS",
    _19: "_19",
    _20: "_20",
    _21: "_21",
    _22: "_22",
    _23: "_23",
    ADD_PRODUCT: "ADD_PRODUCT",
    ARE_YOU_SURE_WANT_TO_CHANGE_ST: "ARE_YOU_SURE_WANT_TO_CHANGE_ST",
    SEND: "SEND",
    PAID1: "PAID1",
    VAT_NUMBER: "VAT_NUMBER",
    ACCOUNTING: "ACCOUNTING",
    CREATE: "CREATE",
    EDIT: "EDIT",
    VALUE: "VALUE",
    CREATE_LIABILITY: "CREATE_LIABILITY",
    BILLING_START_DATE: "BILLING_START_DATE",
    INVOICE_PAYMENT_TERM: "INVOICE_PAYMENT_TERM",
    SHAREHOLDER_PAYMENT_PLAN_TYPE: "SHAREHOLDER_PAYMENT_PLAN_TYPE",
    NUMBER_OF_MONTHS_TO_PAY_OFF: "NUMBER_OF_MONTHS_TO_PAY_OFF",
    SHARES1: "SHARES1",
    SURFACE_AREA: "SURFACE_AREA",
    WEEK2: "WEEK2",
    WEEKS: "WEEKS",
    WEEKS1: "WEEKS1",
    WEEKS2: "WEEKS2",
    SHARES2: "SHARES2",
    SURFACE_AREA1: "SURFACE_AREA1",
    EDIT_PAYMENT: "EDIT_PAYMENT",
    CREATE_PAYMENT: "CREATE_PAYMENT",
    PAYMENT_DATE: "PAYMENT_DATE",
    ARE_YOU_SURE_WANT_TO_DELETE_PL: "ARE_YOU_SURE_WANT_TO_DELETE_PL",
    TOTAL_LIABILITY_AMOUNT: "TOTAL_LIABILITY_AMOUNT",
    THIS_PLAN_TOTAL_AMOUNT: "THIS_PLAN_TOTAL_AMOUNT",
    FIRST_PAYMENT_DAY: "FIRST_PAYMENT_DAY",
    TOTAL_AMOUNT_OF_DEBT: "TOTAL_AMOUNT_OF_DEBT",
    LIABILITY_TYPE: "LIABILITY_TYPE",
    MONTHLY_PAYMENT_SUM: "MONTHLY_PAYMENT_SUM",
    CLASS: "CLASS",
    MERCHANT_NAME: "MERCHANT_NAME",
    FILTERS: "FILTERS",
    HIDE_PAID: "HIDE_PAID",
    OVERTIME: "OVERTIME",
    APPROVED1: "APPROVED1",
    OVERPAID_ERROR: "OVERPAID_ERROR",
    REGISTER: "REGISTER",
    COMPANY_REGISTRATION: "COMPANY_REGISTRATION",
    SO_YOU_ARE_OWNER_OF_SERVICE_OR: "SO_YOU_ARE_OWNER_OF_SERVICE_OR",
    BUSINESS_NAME: "BUSINESS_NAME",
    POSTAL_CODE: "POSTAL_CODE",
    CREATE_PORTFOLIO: "CREATE_PORTFOLIO",
    EDIT_PORTFOLIO: "EDIT_PORTFOLIO",
    BUILDINGS: "BUILDINGS",
    PROPERTIES: "PROPERTIES",
    PORTFOLIO: "PORTFOLIO",
    BUILDINGS1: "BUILDINGS1",
    PORTFOLIO_TITLE: "PORTFOLIO_TITLE",
    PORTFOLIO_DESCRIPTION: "PORTFOLIO_DESCRIPTION",
    M1: "M1",
    RENTED_AREA_AMOUNT_MONTH: "RENTED_AREA_AMOUNT_MONTH",
    PORTFOLIO_AVERAGE_MATURITY: "PORTFOLIO_AVERAGE_MATURITY",
    AREA_COUNT: "AREA_COUNT",
    LONG_TERM_AREAS: "LONG_TERM_AREAS",
    PRODUCT_CLASS: "PRODUCT_CLASS",
    TOTAL_INCOME: "TOTAL_INCOME",
    TOTAL_OUTCOME: "TOTAL_OUTCOME",
    DOWNLOAD_XLSX: "DOWNLOAD_XLSX",
    OPEN_SHAREHOLDERS_CALENDAR: "OPEN_SHAREHOLDERS_CALENDAR",
    TOTAL: "TOTAL",
    CREATE_TENANT: "CREATE_TENANT",
    EDIT_TENANT: "EDIT_TENANT",
    ROOT_TENANT: "ROOT_TENANT",
    TEST: "TEST",
    TEST_CONSUMERGET: "TEST_CONSUMERGET",
    TEST_USERACCESSLIST: "TEST_USERACCESSLIST",
    TEST_SHOPFIND: "TEST_SHOPFIND",
    TEST_ORDERLIST: "TEST_ORDERLIST",
    TEST_PRODUCTLIST: "TEST_PRODUCTLIST",
    START_DATE1: "START_DATE1",
    END_DATE1: "END_DATE1",
    THERE_IS_NO_BUILDING_DOCUMENTS: "THERE_IS_NO_BUILDING_DOCUMENTS",
    WHEN_YOU_UPLOAD_A_DOCUMENT_IT: "WHEN_YOU_UPLOAD_A_DOCUMENT_IT",
    SEARCH: "SEARCH",
    YOUR_EMPLOYEES: "YOUR_EMPLOYEES",
    SELECT_GROUP: "SELECT_GROUP",
    INVITE_USER: "INVITE_USER",
    ARE_YOU_SURE_WANT_TO_RE_ASSIGN: "ARE_YOU_SURE_WANT_TO_RE_ASSIGN",
    USER_INFORMATION: "USER_INFORMATION",
    CURRENT_HOUR_RATE: "CURRENT_HOUR_RATE",
    HOUR1: "HOUR1",
    H: "H",
    ARE_YOU_SURE_WANT_TO_REMOVE_TH: "ARE_YOU_SURE_WANT_TO_REMOVE_TH",
    YOUR_GROUPS: "YOUR_GROUPS",
    ADD_NEW: "ADD_NEW",
    CREATE_NEW_GROUP: "CREATE_NEW_GROUP",
    GROUP_NAME: "GROUP_NAME",
    GROUP_DESCRIPTION: "GROUP_DESCRIPTION",
    CREATE_GROUP: "CREATE_GROUP",
    ARE_YOU_SURE_WANT_TO_REMOVE_TH1: "ARE_YOU_SURE_WANT_TO_REMOVE_TH1",
    SELECT_EMPLOYEE: "SELECT_EMPLOYEE",
    THE_IBAN_IS_INCORRECT_PLEASE: "THE_IBAN_IS_INCORRECT_PLEASE",
    CHECK_YOUR_IBAN: "CHECK_YOUR_IBAN",
    IN_ORDER_TO_USE_ACCOUNTING_SYS: "IN_ORDER_TO_USE_ACCOUNTING_SYS",
    COPYRIGHT_HONKIO: "COPYRIGHT_HONKIO",
    _24: "_24",
    _25: "_25",
    ANALYTICS: "ANALYTICS",
    YOUR_WEBSITE_S_ACTIVE_USERS_CO: "YOUR_WEBSITE_S_ACTIVE_USERS_CO",
    _26: "_26",
    IN_THE: "IN_THE",
    _27: "_27",
    SALES: "SALES",
    LAST_WEEK_YOUR_STORE_S_SALES_C: "LAST_WEEK_YOUR_STORE_S_SALES_C",
    _28: "_28",
    IT: "IT",
    VIEW_ALL_NOTIFICATIONS: "VIEW_ALL_NOTIFICATIONS",
    SUBSCRIPTION_PLAN: "SUBSCRIPTION_PLAN",
    MERCHANT1: "MERCHANT1",
    LOGOUT: "LOGOUT",
    _29: "_29",
    _30: "_30",
    SEARCH1: "SEARCH1",
    REQUEST_SHAREHOLDERS_CALENDAR: "REQUEST_SHAREHOLDERS_CALENDAR",
    BUILDING_MEMBER_SSN: "BUILDING_MEMBER_SSN",
    BUILDING_MEMBER_EMAIL: "BUILDING_MEMBER_EMAIL",
    BUILDING_MEMBER_NAME: "BUILDING_MEMBER_NAME",
    BUILDING_MEMBER_BUSINESS_ID: "BUILDING_MEMBER_BUSINESS_ID",
    BUILDING_MEMBER_CONTACT_EMAIL: "BUILDING_MEMBER_CONTACT_EMAIL",
    BUILDING_MEMBER_BUSINESS_NAME: "BUILDING_MEMBER_BUSINESS_NAME",
    BUILDING_MEMBER_TYPE: "BUILDING_MEMBER_TYPE",
    BUILDING_MEMBER_STATUS: "BUILDING_MEMBER_STATUS",
    BUILDING_MEMBER_PHONE: "BUILDING_MEMBER_PHONE",
    BUILDING_MEMBER_ADDRESS: "BUILDING_MEMBER_ADDRESS",
    BUILDING_MEMBER_POSTAL_CODE: "BUILDING_MEMBER_POSTAL_CODE",
    BUILDING_MEMBER_CITY: "BUILDING_MEMBER_CITY",
    AREA_OPENTIMES_CLOSEDAYS: "AREA_OPENTIMES_CLOSEDAYS",
    AREA_CHANGE_OWNER: "AREA_CHANGE_OWNER",
    AREA_DELETE: "AREA_DELETE",
    AREA_DOCUMENTS: "AREA_DOCUMENTS",
    AGREEMENT_EDIT: "AGREEMENT_EDIT",
    AGREEMENT_PREVIEW: "AGREEMENT_PREVIEW",
    AGREEMENT_SEND_TO_TENANT: "AGREEMENT_SEND_TO_TENANT",
    AGREEMENT_DELETE: "AGREEMENT_DELETE",
    PORTFOLIO_EDIT: "PORTFOLIO_EDIT",
    PORTFOLIO_VIEW: "PORTFOLIO_VIEW",
    PORTFOLIO_DELETE: "PORTFOLIO_DELETE",
    FINANCIAL_YEAR_START_MONTH: "FINANCIAL_YEAR_START_MONTH",
    JANUARY: "JANUARY",
    FEBRUARY: "FEBRUARY",
    MARCH: "MARCH",
    APRIL: "APRIL",
    MAY: "MAY",
    JUNE: "JUNE",
    JULY: "JULY",
    SEPTEMBER: "SEPTEMBER",
    OCTOBER: "OCTOBER",
    AUGUST: "AUGUST",
    NOVEMBER: "NOVEMBER",
    SHAREHOLDER_LASTNAME: "SHAREHOLDER_LASTNAME",
    DECEMBER: "DECEMBER",
    SHAREHOLDER_FIRSTNAME: "SHAREHOLDER_FIRSTNAME",
    CLOSE_DAYS_EDIT: "CLOSE_DAYS_EDIT",
    YOU_CAN_EDIT_YOUR_BUSINESS_CLO: "YOU_CAN_EDIT_YOUR_BUSINESS_CLO",
    AREAS_RESERVATIONS: "AREAS_RESERVATIONS",
    ORDERS: "ORDERS",
    ENQUEUED_HIGH_PRIORITY: "ENQUEUED_HIGH_PRIORITY",
    ENQUEUED: "ENQUEUED",
    LEGEND: "LEGEND",
    ASSIGNED: "ASSIGNED",
    ASSIGNED_WITH_PRIORITY: "ASSIGNED_WITH_PRIORITY",
    JOB_COMPLETED_OR_JOB_COMPLETED: "JOB_COMPLETED_OR_JOB_COMPLETED",
    COMPLETED: "COMPLETED",
    EMPLOYEE_HOLIDAYS: "EMPLOYEE_HOLIDAYS",
    REJECTED: "REJECTED",
    WORKER_VACATION_APPROVED: "WORKER_VACATION_APPROVED",
    NEW_REQUEST: "NEW_REQUEST",
    BUILDING_MEMBER_LASTNAME: "BUILDING_MEMBER_LASTNAME",
    BUILDING_MEMBER_FIRSTNAME: "BUILDING_MEMBER_FIRSTNAME",
    DAYGRIDMONTH_TIMEGRIDWEEK_TIME: "DAYGRIDMONTH_TIMEGRIDWEEK_TIME",
    SHAREHOLDER1: "SHAREHOLDER1",
    THERE_WAS_A_PROBLEM_WITH_RENDE: "THERE_WAS_A_PROBLEM_WITH_RENDE",
    SENSORS_OF: "SENSORS_OF",
    UNASSIGNED_SENSORS: "UNASSIGNED_SENSORS",
    BUILDING_CASHFLOW: "BUILDING_CASHFLOW",
    ASSIGN: "ASSIGN",
    BUILDING_INCOME_PIE: "BUILDING_INCOME_PIE",
    CONFIGURE: "CONFIGURE",
    BARS: "BARS",
    LINES: "LINES",
    MONTH_FORWARD: "MONTH_FORWARD",
    MONTH_BACK: "MONTH_BACK",
    INDEX: "INDEX",
    HUMIDITY1: "HUMIDITY1",
    F: "F",
    LINEAR: "LINEAR",
    AXIS: "AXIS",
    CURVEBASIS: "CURVEBASIS",
    TEMPERATURE1: "TEMPERATURE1",
    NO_DATA: "NO_DATA",
    EDIT_SETTINGS: "EDIT_SETTINGS",
    F_C: "F_C",
    REPORT_XLSX: "REPORT_XLSX",
    DELETE_EVENT: "DELETE_EVENT",
    ANSWER_NUMBER: "ANSWER_NUMBER",
    ANSWER: "ANSWER",
    CREATE_QUESTION: "CREATE_QUESTION",
    EDIT_QUESTION: "EDIT_QUESTION",
    CREATE_QUESTION1: "CREATE_QUESTION1",
    QUESTION: "QUESTION",
    ADD_ANSWER: "ADD_ANSWER",
    ADD_QUESTION: "ADD_QUESTION",
    FIRSTNAME: "FIRSTNAME",
    LASTNAME: "LASTNAME",
    PORTFOLIOS: "PORTFOLIOS",
    AGREEMENTS: "AGREEMENTS",
    REPORTS: "REPORTS",
    RESERVATIONS: "RESERVATIONS",
    AGREEMENT_CREATE: "AGREEMENT_CREATE",
    DASHBOARD_ITEM_CRASH: "DASHBOARD_ITEM_CRASH",
    AREA_RESERVATIONS: "AREA_RESERVATIONS",
    BALANCE_SHEET: "BALANCE_SHEET",
    FIN_START_MONTH: "FIN_START_MONTH",
    PLEASE_SELECT_A_BUILDING: "PLEASE_SELECT_A_BUILDING",
    PLEASE_SELECT_A_PROPERTY: "PLEASE_SELECT_A_PROPERTY",
    _6: "_6",
    PROJECTS: "PROJECTS",
    AGREEMENT_CREATE_WARNING: "AGREEMENT_CREATE_WARNING",
    AGREEMENT_CREATE_WARNING_TITLE: "AGREEMENT_CREATE_WARNING_TITLE",
    AGREEMENT_CREATE_WARNING_BUILDINGS: "AGREEMENT_CREATE_WARNING_BUILDINGS",
    AGREEMENT_CREATE_WARNING_AREAS: "AGREEMENT_CREATE_WARNING_AREAS",
    AGREEMENT_CREATE_WARNING_FLOORPLANS: "AGREEMENT_CREATE_WARNING_FLOORPLANS",
    AGREEMENT_CREATE_WARNING_TENANTS: "AGREEMENT_CREATE_WARNING_TENANTS",
    PORTFOLIOS_LIST: "PORTFOLIOS_LIST",
    BUILDINGS_LIST: "BUILDINGS_LIST",
    BUILDING_EDIT: "BUILDING_EDIT",
    BUILDING_OPEN: "BUILDING_OPEN",
    BUILDING_PROJECTS: "BUILDING_PROJECTS",
    BUILDING_USERS: "BUILDING_USERS",
    DOCUMENTS: "DOCUMENTS",
    BUILDING_SENSORS: "BUILDING_SENSORS",
    PROPERTIES_LIST: "PROPERTIES_LIST",
    PROPERTY_EDIT: "PROPERTY_EDIT",
    PROPERTY_OPEN: "PROPERTY_OPEN",
    PROPERTY_USERS: "PROPERTY_USERS",
    NET_ASSET_LABEL: "NET_ASSET_LABEL",
    UPDATED_DATE: "UPDATED_DATE",
    UPDATED_BY: "UPDATED_BY",
    AT: "AT",
    BY: "BY",
    BUILDING_DATA: "BUILDING_DATA",
    PROPERTY_DATA: "PROPERTY_DATA",
    UPDATED_AT_LABEL: "UPDATED_AT_LABEL",
    LIST_ALL_AREAS: "LIST_ALL_AREAS",
    FLOORPLAN_IMPORT: "FLOORPLAN_IMPORT",
    AREAS: "AREAS",
    FLOORPLAN_DELETE: "FLOORPLAN_DELETE",
    EMPTY_LIST: "EMPTY_LIST",
    UPDATE_INFORMATION: "UPDATE_INFORMATION",
    DATA_INPUT_LIST: "DATA_INPUT_LIST",
    NO_SHAREHOLDERS_C1: "NO_SHAREHOLDERS_C1",
    HERE_C1: "HERE_C1",
    AGREEMENT_NUMBER_ID: "AGREEMENT_NUMBER_ID",
    SSN: "SSN",
    SELECT_MEMBER_STATUS: "SELECT_MEMBER_STATUS",
    WIDGET_NAME: "WIDGET_NAME",
    INVOICE_NUMBER_OR: "INVOICE_NUMBER_OR",
    INVOICE_TITLE: "INVOICE_TITLE",
    CREATION_DATE: "CREATION_DATE",
    FEE_PERCENTAGE: "FEE_PERCENTAGE",
    STREET: "STREET",
    SELECT_MEETING_TYPE: "SELECT_MEETING_TYPE",
    SET_POSSIBLE_ANSWER: "SET_POSSIBLE_ANSWER",
    INPUT_QUESTION_HERE: "INPUT_QUESTION_HERE",
    SEARCH_FOR_SMTH: "SEARCH_FOR_SMTH",
    AREA: "AREA",
    OVERVIEW: "OVERVIEW",
    SUMMARY: "SUMMARY",
    MERCHANT_SETTINGS: "MERCHANT_SETTINGS",
    AAA: "AAA",
    TENANT_INVOICES: "TENANT_INVOICES",
    HOUR_RATES: "HOUR_RATES",
    PAYMENT_PLAN: "PAYMENT_PLAN",
    FLOORPLANS_L: "FLOORPLANS_L",
    LIST_OF_SENSORS: "LIST_OF_SENSORS",
    SHAREHOLDER_INVOICES: "SHAREHOLDER_INVOICES",
    SIGN: "SIGN",
    DELETE_ACTION: "DELETE_ACTION",
    SHAREHOLDERS_CALENDAR: "SHAREHOLDERS_CALENDAR",
    VIEW_INVOICES: "VIEW_INVOICES",
    SEND_INVITATION: "SEND_INVITATION",
    EDIT_INVOICE: "EDIT_INVOICE",
    ITEMS: "ITEMS",
    VIEW: "VIEW",
    DATA_INPUT: "DATA_INPUT",
    SHAREHOLDER_PP: "SHAREHOLDER_PP",
    SHAREHOLDER_ID: "SHAREHOLDER_ID",
    PROTASK: "PROTASK",
    OPEN_L: "OPEN_L",
    CLOSED_L: "CLOSED_L",
    AGREEMENT: "AGREEMENT",
    PROJECT_L: "PROJECT_L",
    PORTFOLIO_L: "PORTFOLIO_L",
    SHAREHOLDERS: "SHAREHOLDERS",
    NO_ACCESS_THIS_MOMENT: "NO_ACCESS_THIS_MOMENT",
    SAVED: "SAVED",
    USER_ADDED: "USER_ADDED",
    USER_HAS_BEEN_ADDED: "USER_HAS_BEEN_ADDED",
    ASK_USER_CHECK_INBOX: "ASK_USER_CHECK_INBOX",
    USER_HAS_BEEN_INVITED: "USER_HAS_BEEN_INVITED",
    UNKNOWN_ERROR: "UNKNOWN_ERROR",
    ERROR_SENDING_INVITE: "ERROR_SENDING_INVITE",
    SETTINGS_SAVED: "SETTINGS_SAVED",
    AGREEMENT_SENT: "AGREEMENT_SENT",
    AGREEMENT_HAS_BEEN_REMOVED: "AGREEMENT_HAS_BEEN_REMOVED",
    DELETE_FAILED: "DELETE_FAILED",
    AGREEMENT_SAVED: "AGREEMENT_SAVED",
    CREATED: "CREATED",
    TEMPLATE_HAS_BEEN_CREATED: "TEMPLATE_HAS_BEEN_CREATED",
    AGREEMENT_TEMPLATE_HAS_BEEN_REMOVED: "AGREEMENT_TEMPLATE_HAS_BEEN_REMOVED",
    TENANT_HAS_BEEN_REMOVED: "TENANT_HAS_BEEN_REMOVED",
    AREA_HAS_BEEN_REMOVED: "AREA_HAS_BEEN_REMOVED",
    AREA_EDITED: "AREA_EDITED",
    SUCCESS: "SUCCESS",
    ERROR_API: "ERROR_API",
    AREA_TRANSFER_COMPLETED: "AREA_TRANSFER_COMPLETED",
    DOCUMENT_HAS_BEEN_REMOVED: "DOCUMENT_HAS_BEEN_REMOVED",
    DOCUMENT_DOWNLOADING_STARTED: "DOCUMENT_DOWNLOADING_STARTED",
    DOCUMENT_UPLOAD_FAILED: "DOCUMENT_UPLOAD_FAILED",
    UPLOAD_SUCCESS: "UPLOAD_SUCCESS",
    UNSUPPORTED_EXTENSION: "UNSUPPORTED_EXTENSION",
    SCHEDULE_SAVED: "SCHEDULE_SAVED",
    UPLOAD_FAILED_IMAGE: "UPLOAD_FAILED_IMAGE",
    BUILDING_SAVED: "BUILDING_SAVED",
    BUILDING_HAS_BEEN_REMOVED: "BUILDING_HAS_BEEN_REMOVED",
    PROPERTY_SAVED: "PROPERTY_SAVED",
    BPROPERTY_HAS_BEEN_REMOVED: "PROPERTY_HAS_BEEN_REMOVED",
    CHAIRMAN_APPOINTED: "CHAIRMAN_APPOINTED",
    SHAREHOLDER_HAS_BEEN_REMOVED: "SHAREHOLDER_HAS_BEEN_REMOVED",
    DONE: "DONE",
    BUILDING_CERT_REQUESTED_LONG: "BUILDING_CERT_REQUESTED_LONG",
    BUILDING_CERT_SAVED: "BUILDING_CERT_SAVED",
    PROJECT_HAS_BEEN_REMOVED: "PROJECT_HAS_BEEN_REMOVED",
    FLOORPLAN_IMPORTED: "FLOORPLAN_IMPORTED",
    FLOORPLAN_IMPORT_FAILED: "FLOORPLAN_IMPORT_FAILED",
    FLOORPLAN_HAS_BEEN_REMOVED: "FLOORPLAN_HAS_BEEN_REMOVED",
    LIABILITIES_MERCHANT_IN_PROGRESS: "LIABILITIES_MERCHANT_IN_PROGRESS",
    WARNING: "WARNING",
    INVOICE_SAVED: "INVOICE_SAVED",
    INVOICE_SENT: "INVOICE_SENT",
    EMAIL_SENT: "EMAIL_SENT",
    CANT_FIND_PP: "CANT_FIND_PP",
    LIABILITY_ADDED: "LIABILITY_ADDED",
    CREATION_FAILED: "CREATION_FAILED",
    DATA_SAVED: "DATA_SAVED",
    NO_INVOICES: "NO_INVOICES",
    EVENT_REMOVED: "EVENT_REMOVED",
    EVENT_SAVED: "EVENT_SAVED",
    FILE_DOWNLOADING_STARTED_LONG: "FILE_DOWNLOADING_STARTED_LONG",
    GROUP_HAS_BEEN_CREATED: "GROUP_HAS_BEEN_CREATED",
    INVITE_USERS_CTA: "INVITE_USERS_CTA",
    GROUP_HAS_BEEN_REMOVED: "GROUP_HAS_BEEN_REMOVED",
    BUILDING_MDM_ID: "BUILDING_MDM_ID",
    BUILDING_INCOME: "BUILDING_INCOME",
    RENT_COSTS: "RENT_COSTS",
    PAID_DATE: "PAID_DATE",
    PREVIEW: "PREVIEW",
    CREATE_A_BUILDING: "CREATE_A_BUILDING",
    CREATE_A_PROPERTY: "CREATE_A_PROPERTY",
    ROWS_PER_PAGE: "ROWS_PER_PAGE",
    ROWS_OF: "ROWS_OF",
    ROWS_ALL: "ROWS_ALL",
    LAST_UPDATED_DATE: "LAST_UPDATED_DATE",
    PROPERTY_HAS_BEEN_REMOVED: "PROPERTY_HAS_BEEN_REMOVED",
    CONSTRUCTION_PERIOD: "CONSTRUCTION_PERIOD",
    RENT_LEASE_DATE: "RENT_LEASE_DATE",
    MARKET_RENT_PRICE: "MARKET_RENT_PRICE",
    CONSTRUCTION_REGULATIONS: "CONSTRUCTION_REGULATIONS",
    IS_LEASED: "IS_LEASED",
    CONSTRUCTION_PERIOD1: "CONSTRUCTION_PERIOD1",
    RENT_LEASE_DATE1: "RENT_LEASE_DATE1",
    MARKET_RENT_PRICE1: "MARKET_RENT_PRICE1",
    CONSTRUCTION_REGULATIONS1: "CONSTRUCTION_REGULATIONS1",
    IS_LEASED1: "IS_LEASED1",
    ALL_BUILDINGS: "ALL_BUILDINGS",
    AGREEMENT_RESIGN_DATE: "AGREEMENT_RESIGN_DATE",
    AMOUNT_MAINTENANCE: "AMOUNT_MAINTENANCE",
    AMOUNT_RENT_TAX: "AMOUNT_RENT_TAX",
    AMOUNT_RENT_ANNUAL_INCREASE_MIN: "AMOUNT_RENT_ANNUAL_INCREASE_MIN",
    AMOUNT_RENT_ANNUAL_INCREASE: "AMOUNT_RENT_ANNUAL_INCREASE",
    AGREEMENT_CONTRACT_TYPE: "AGREEMENT_CONTRACT_TYPE",
    RESIGN_DATE: "RESIGN_DATE",
    BUILDINGS_COUNT: "BUILDINGS_COUNT",
    OWNER: "OWNER",
    OWNERSHIP: "OWNERSHIP",
    PROPERTY3: "PROPERTY3",
    PROPERTY5: "PROPERTY5",
    PROPERTY4: "PROPERTY4",
    BUILDINGS_COUNT1: "BUILDINGS_COUNT1",
    OWNER1: "OWNER1",
    OWNERSHIP1: "OWNERSHIP1",
    LANDPLOTS_COUNT: "LANDPLOTS_COUNT",
    UNUSED_BUILDING_RIGHTS: "UNUSED_BUILDING_RIGHTS",
    SIGNED_DATE: "SIGNED_DATE",
    COSTS: "COSTS",
    PROPERTY_PICTURE: "PROPERTY_PICTURE",
    RATING: "RATING",
    EXPLANATION: "EXPLANATION",
    COST_CENTER: "COST_CENTER",
    PURPOSE: "PURPOSE",
    ITEM_DESCRIPTION: "ITEM_DESCRIPTION",
    EXPLANATION1: "EXPLANATION1",
    COST_CENTER1: "COST_CENTER1",
    PURPOSE1: "PURPOSE1",
    ITEM_DESCRIPTION1: "ITEM_DESCRIPTION1",
    AREA_PHOTOS: "AREA_PHOTOS",
    PHOTO_UPLOAD_FAILED: "PHOTO_UPLOAD_FAILED",
    UPLOAD_PHOTO: "UPLOAD_PHOTO",
    BOOKING_REQUESTS: "BOOKING_REQUESTS",
    SPACE_TYPE: "SPACE_TYPE",
    RETAIL: "RETAIL",
    STORAGE: "STORAGE",
    INDUSTRIAL: "INDUSTRIAL",
    HOTEL: "HOTEL",
    RESIDENTIAL: "RESIDENTIAL",
    OTHER_NO_GLA: "OTHER_NO_GLA",
    PARKING: "PARKING",
    ADDITIONAL_RENT: "ADDITIONAL_RENT",
    MAINTENANCE_CHARGE: "MAINTENANCE_CHARGE",
    TURNOVER_RENT: "TURNOVER_RENT",
    ANALYSIS_DAY: "ANALYSIS_DAY",
    REPORT_DAY: "REPORT_DAY",
    MODEL_LENGTH: "MODEL_LENGTH",
    MARKET_RENTS: "MARKET_RENTS",
    CREDIT_LOSSES_PERCENT: "CREDIT_LOSSES_PERCENT",
    CAPITAL_EXP: "CAPITAL_EXP",
    TERM_VALUE_VAC_RATE: "TERM_VALUE_VAC_RATE",
    TERM_VALUE_CAPITAL_EXP: "TERM_VALUE_CAPITAL_EXP",
    LEASE_ROLLOVERS: "LEASE_ROLLOVERS",
    IDLE_TIME_AFTER_LEASE: "IDLE_TIME_AFTER_LEASE",
    IDLE_TIME_FOR_VACANT: "IDLE_TIME_FOR_VACANT",
    TENANT_IMPROVEMENT_COSTS: "TENANT_IMPROVEMENT_COSTS",
    LETTING_FEE: "LETTING_FEE",
    SPEC_LEASE_MOD_LOGIC: "SPEC_LEASE_MOD_LOGIC",
    GEN_MARKET_VAC_RATE: "GEN_MARKET_VAC_RATE",
    ASSUMED_SPEC_LEASE_LENGTH: "ASSUMED_SPEC_LEASE_LENGTH",
    IDLE_BEFORE_SPEC_LEASES: "IDLE_BEFORE_SPEC_LEASES",
    TRANSACTION_COSTS_INITIAL: "TRANSACTION_COSTS_INITIAL",
    CASH_FLOW_YIELD: "CASH_FLOW_YIELD",
    TERM_VALUE_CAP_RATE: "TERM_VALUE_CAP_RATE",
    ACCURACY: "ACCURACY",
    MARKET_VALUE_EUR: "MARKET_VALUE_EUR",
    BASIC_INFO: "BASIC_INFO",
    FORECASTING_PERIOD: "FORECASTING_PERIOD",
    TERMINAL_VALUE: "TERMINAL_VALUE",
    CURRENT_LEASE_AGREEMENTS: "CURRENT_LEASE_AGREEMENTS",
    SPEC_LEASE_AGREEMENTS: "SPEC_LEASE_AGREEMENTS",
    KEY_FIGURE_CALC_ASSUMPTIONS: "KEY_FIGURE_CALC_ASSUMPTIONS",
    YIELDS_AND_DISC_RATES: "YIELDS_AND_DISC_RATES",
    CHANGE_OF_MARKET_RENTS: "CHANGE_OF_MARKET_RENTS",
    INFLATION: "INFLATION",
    CHANGE_OF_EXPENCES: "CHANGE_OF_EXPENCES",
    YEAR: "YEAR",
    EVALUATOR_INPUT: "EVALUATOR_INPUT",
    DISC_CASH_FLOW: "DISC_CASH_FLOW",
    RENTROLL: "RENTROLL",
    CHART_TYPE: "CHART_TYPE",
    CASHFLOW: "CASHFLOW",
    COSTS_CHART: "COSTS_CHART",
    COSTS_TABLE: "COSTS_TABLE",
    MODE: "MODE",
    MONTH_VIEW: "MONTH_VIEW",
    YEAR_VIEW: "YEAR_VIEW",
    TOTAL_COSTS: "TOTAL_COSTS",
    SEPARATE_COSTS: "SEPARATE_COSTS",
    LINES_TYPE: "LINES_TYPE",
    TERMINATION_DATE: "TERMINATION_DATE",
    AGREEMENT_LENGTH: "AGREEMENT_LENGTH",
    TENANT_RATING: "TENANT_RATING",
    MONTHLY_INCOME: "MONTHLY_INCOME",
    OVER_UNDER_RENT: "OVER_UNDER_RENT",
    MARKET_RENT_EUR: "MARKET_RENT_EUR",
    MARKET_RENT_EUR_M2: "MARKET_RENT_EUR_M2",
    TOTAL_PASSING_RENT: "TOTAL_PASSING_RENT",
    ADMINISTRATION: "ADMINISTRATION",
    MAINTENANCE: "MAINTENANCE",
    YARD_WORK: "YARD_WORK",
    CLEANING: "CLEANING",
    HEATING: "HEATING",
    WATER_AND_WASTE: "WATER_AND_WASTE",
    ELECTRICITY_AND_GAS: "ELECTRICITY_AND_GAS",
    WASTE_MAINTENANCE: "WASTE_MAINTENANCE",
    INSURANCE: "INSURANCE",
    SITE_RENTS: "SITE_RENTS",
    PROPERTY_TAX: "PROPERTY_TAX",
    OTHER_EXPENSES: "OTHER_EXPENSES",
    MAINTENANCE_REPAIRS: "MAINTENANCE_REPAIRS",
    MARKETING_RECOVERY: "MARKETING_RECOVERY",
    UTILITIES_RECOVERY: "UTILITIES_RECOVERY",
    RENTS_LEASES: "RENTS_LEASES",
    ESTIMATED_RENTAL_VALUE: "ESTIMATED_RENTAL_VALUE",
    OVER_UNDER_RENTS: "OVER_UNDER_RENTS",
    OTHER_INCOME: "OTHER_INCOME",
    POTENTIAL_RETAL_INCOME: "POTENTIAL_RETAL_INCOME",
    OVER_UNDER_RENTS_PLUS: "OVER_UNDER_RENTS_PLUS",
    CONTRACT_INCOME: "CONTRACT_INCOME",
    VACANCY_REDUCTION_IDLE_TIME_MONTH: "VACANCY_REDUCTION_IDLE_TIME_MONTH",
    VACANCY_REDUCTION_LONG_TERM_ALLOWANCE:
        "VACANCY_REDUCTION_LONG_TERM_ALLOWANCE",
    VACANCY_REDUCTIONS: "VACANCY_REDUCTIONS",
    VACANCY_RATE_PERCENT: "VACANCY_RATE_PERCENT",
    OPERATING_EXPENSES: "OPERATING_EXPENSES",
    NET_OPERATING_INCOME_NOI_I: "NET_OPERATING_INCOME_NOI_I",
    CAPITAL_EXPEDITURE: "CAPITAL_EXPEDITURE",
    TENANT_IMPROVEMENTS: "TENANT_IMPROVEMENTS",
    NET_INCOME_NOI_II: "NET_INCOME_NOI_II",
    PRESENT_VALUE_OF_NET_INCOME_NOI_II: "PRESENT_VALUE_OF_NET_INCOME_NOI_II",
    EUR_SQM_MTH: "EUR_SQM_MTH",
    BUSINESS_PREMICES: "BUSINESS_PREMICES",
    NET_OPERATING_INCOME_YEAR_11: "NET_OPERATING_INCOME_YEAR_11",
    TERMINAL_VALUE_CAPITALIZATION_RATE: "TERMINAL_VALUE_CAPITALIZATION_RATE",
    TERMINAL_VALUE_YEAR_11: "TERMINAL_VALUE_YEAR_11",
    PRESENT_VALUE_TERMINAL_VALUE: "PRESENT_VALUE_TERMINAL_VALUE",

    RETURNS: "RETURNS",
    CURRENT_INITAL_YIELD_NOI_I: "CURRENT_INITAL_YIELD_NOI_I",
    CURRENT_INITAL_YIELD_NOI_I_AFTER_TR_COSTS:
        "CURRENT_INITAL_YIELD_NOI_I_AFTER_TR_COSTS",
    POTENTIAL_YIELD_NOI_I: "POTENTIAL_YIELD_NOI_I",
    REVERSIONARY_YIELD_NOI_I: "REVERSIONARY_YIELD_NOI_I",
    FIRST_YEAR_INITIAL_YIELD_NOI_I: "FIRST_YEAR_INITIAL_YIELD_NOI_I",

    YELDS_AND_DISCOUNT_RATES: "YELDS_AND_DISCOUNT_RATES",
    CASH_FLOW_YIELD_10_YR: "CASH_FLOW_YIELD_10_YR",
    CASH_FLOW_DISCOUNT_RATE_10_YR: "CASH_FLOW_DISCOUNT_RATE_10_YR",
    TERMINAL_VALUE_DISCOUNT_RATE: "TERMINAL_VALUE_DISCOUNT_RATE",

    BREAK_DOWN_OF_VALUE: "BREAK_DOWN_OF_VALUE",
    PRESENT_VALUE_OF_10_YEAR_CHASH_FLOWS:
        "PRESENT_VALUE_OF_10_YEAR_CHASH_FLOWS",
    PRESENT_VALUE_OF_TERMINAL_VALUE: "PRESENT_VALUE_OF_TERMINAL_VALUE",
    OTHER_VALUE_UNUSED_BUILDING_RIGHTS: "OTHER_VALUE_UNUSED_BUILDING_RIGHTS",

    KEY_FIGURES: "KEY_FIGURES",
    AVERAGE_VACANCY_RATE_PERCENT_DURING_10_YEARS:
        "AVERAGE_VACANCY_RATE_PERCENT_DURING_10_YEARS",
    CURRENT_VACANCY_RATE_PERCENT_ECONOMIC:
        "CURRENT_VACANCY_RATE_PERCENT_ECONOMIC",
    LEASABLE_AREA_SQM: "LEASABLE_AREA_SQM",
    LEASE_MATURITY_YEARS: "LEASE_MATURITY_YEARS",
    TRANSACTION_COSTS_AT_PURCHASE_PERCENT_OF_VALUE:
        "TRANSACTION_COSTS_AT_PURCHASE_PERCENT_OF_VALUE",

    GROUTH_ASSUMPTIONS: "GROUTH_ASSUMPTIONS",
    INFLATION_PERCENT: "INFLATION_PERCENT",
    MARKET_RENT_CHANGE_PERCENT: "MARKET_RENT_CHANGE_PERCENT",
    EXPENCES_CHANGE_PERCENT: "EXPENCES_CHANGE_PERCENT",
    OFFICE_EUR: "OFFICE_EUR",
    RETAIL_EUR: "RETAIL_EUR",
    STORAGE_EUR: "STORAGE_EUR",
    INDUSTRIAL_EUR: "INDUSTRIAL_EUR",
    HOTEL_EUR: "HOTEL_EUR",
    RESIDENTIAL_EUR: "RESIDENTIAL_EUR",
    PARKING_EUR: "PARKING_EUR",
    REVENUES: "REVENUES",
    TOTAL_USAGE_CHARGES: "TOTAL_USAGE_CHARGES",
    TOTAL_RENT: "TOTAL_RENT",
    ADDITIONAL_COSTS: "ADDITIONAL_COSTS",
    COSTS_INTERVAL: "COSTS_INTERVAL",
    ANALYSIS_PERIOD_START: "ANALYSIS_PERIOD_START",
    ANALYSIS_PERIOD_END: "ANALYSIS_PERIOD_END",
    CHARTS: "CHARTS",
    USER_GROUPS: "USER_GROUPS",
    USERS: "USERS",
    DELETE_FROM_GROUP: "DELETE_FROM_GROUP",
    USER_SUCCESSFULLY_REMOVED_FROM_GROUP:
        "USER_SUCCESSFULLY_REMOVED_FROM_GROUP",
    CREATE_NEW_ROLE: "CREATE_NEW_ROLE",
    INVITATION_FOR_ROLE: "INVITATION_FOR_ROLE",
    EDIT_ROLE: "EDIT_ROLE",
    CLOSE: "CLOSE",
    ROLE_SAVED: "ROLE_SAVED",
    ROLE_REMOVED: "ROLE_REMOVED",
    UPDATE_ROLE: "UPDATE_ROLE",
    PHOTO: "PHOTO",
    ACTIVE: "ACTIVE",
    DISPLAY_NAME: "DISPLAY_NAME",
    PUBLIC: "PUBLIC",
    PRIVATE: "PRIVATE",
    MESSAGE: "MESSAGE",
    CREATE_LANDPLOT: "CREATE_LANDPLOT",
    CREATE_A_PLOT: "CREATE_A_PLOT",
    PLOT_SAVED: "PLOT_SAVED",
    PARKING_PLACE_SAVED: "PARKING_PLACE_SAVED",
    CREATE_PARKING_PLACE: "CREATE_PARKING_PLACE",
    ROOM_SAVED: "ROOM_SAVED",
    CREATE_ROOM: "CREATE_ROOM",
    LANDPLOT: "LANDPLOT",
    GROUPS: "GROUPS",
    ROOMS: "ROOMS",
    ASSET_GROUP_CREATE: "ASSET_GROUP_CREATE",
    PARKING_PLACE: "PARKING_PLACE",
    ADD_TO_AREA_FROM_LIST: "ADD_TO_AREA_FROM_LIST",
    AREA_CONTENT: "AREA_CONTENT",
    ITEM_POOL: "ITEM_POOL",
    EDIT_PLOT: "EDIT_PLOT",
    FLOORPLAN: "FLOORPLAN",
    FLOORPLAN_EDIT: "FLOORPLAN_EDIT",
    FLOORPLAN_CREATE: "FLOORPLAN_CREATE",
    ROOM_EDIT: "ROOM_EDIT",
    ROOM: "ROOM",
    VIDEO: "VIDEO",
    LANDPLOTS: "LANDPLOTS",
    CREATE_PLOT: "CREATE_PLOT",
    CREATE_BUILDING: "CREATE_BUILDING",
    LINKED_SENSORS: "LINKED_SENSORS",
    REMOVE_LINK: "REMOVE_LINK",
    LINK_SENSORS: "LINK_SENSORS",
    STREET_VIEW: "STREET_VIEW",
    NOT_FOUND: "NOT_FOUND",
    ALREADY_ADDED_TO_AREA: "ALREADY_ADDED_TO_AREA",
    CREATE_PROPERTY: "CREATE PROPERTY",
    //video or learn
    VIDEO_LIST: "VIDEO LIST",
    ADD_VIDEO: "ADD VIDEO",
};
