import React, { useEffect, useState, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import { NotificationManager, NotificationContainer } from "react-notifications";
import { getAssetList } from './../../../../actions/api/assets/getAssetList';
import i18n from 'i18next';
import k from "../../../../i18n/keys";
import { getAssetTypeName } from '../../../../actions/api/assets/config';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DataTable from 'react-data-table-component';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';

const Circular = () =>
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>;

const SelectableAssetListModal = ({ show, onHide, onSubmit, parent, selectedAssets = [] }) => {
    const [assets, setAssets] = useState(selectedAssets);
    const [assetList, setAssetList] = useState();
    const [parents, setParents] = useState([]);

    const [loading, setLoading] = useState(false);
    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false
        };
    }, []);

    const handleSubmitClick = () => {
        onSubmit(assets)
    }

    const onClickHide = () => {
        onHide()
    }

    useEffect(() => {
        if (parent && parent !== null)
            loadAssetsByParent(parent)
    }, [parent]);


    const onAssetSelect = (data) => {
        const found = assets.find(asset => asset._id === data._id)
        if (!found) {
            setAssets([...assets, data])
        } else {
            setAssets(assets.filter(asset => asset._id !== data._id))
        }
    }

    const saveNextParent = (parent) => {
        const parentIndex = parents.indexOf(parent)
        if (parentIndex === -1) {
            setParents([...parents, parent])
        } else {
            setParents(parents.filter((element, index) => index <= parentIndex))
        }
    }

    const getPrevParent = () => {
        if (parents.length >= 2) {
            return parents[parents.length - 2]
        } else {
            return undefined
        }
    }

    const loadPrevParent = () => {
        loadAssetsByParent(getPrevParent())
    }

    const loadAssetsByParent = async (parent) => {
        setLoading(true)
        const resultHandler = (data) => {
            if (isMounted) {
                saveNextParent(parent)
                setLoading(false)
                if (data && data.status === 'accept') {
                    setAssetList(data.assets)
                } else {
                    setAssetList([])
                }
            }
        };

        const errorHandler = (error) => {
            setLoading(false)
            NotificationManager.error(error.description, "Error", 4000)
            setAssetList([])
        }

        getAssetList({ parent: parent, levels: 1 }, errorHandler)
            .then(data => {
                return resultHandler(data)
            })
    }

    const getColumns = () => {
        return [
            {
                name: i18n.t(k.NAME),
                selector: 'name',
                wrap: true,
                sortable: true,
                grow: 1,
                style: `cursor: pointer;`
            },
            {
                name: i18n.t(k.DESCRIPTION),
                selector: 'description',
                sortable: true,
                wrap: true,
                grow: 2,
                style: `cursor: pointer;`
            },
            {
                name: i18n.t(k.TYPE),
                sortable: true,
                wrap: true,
                grow: 2,
                style: `cursor: pointer;`,
                cell: function (d) {
                    return `${getAssetTypeName(d.type)}`
                }
            },
            {
                name: 'Select',
                grow: 0,
                cell: (row, _index, _column) => {
                    const found = assets.find(asset => asset._id === row._id)
                    const icon = found ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
                    return <IconButton size="small" onClick={e => { onAssetSelect(row) }} >
                        {icon}
                    </IconButton>
                }
            }];
    }

    return <Modal
        dialogClassName="wide-modal"
        show={show}
        onHide={onClickHide}>
        <Modal.Header closeButton>
            <Modal.Title>Select property to add to area</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                {getPrevParent() && <IconButton aria-label="delete" onClick={() => loadPrevParent()} ><ArrowBackIcon /></IconButton>}
                {assetList && <DataTable
                    autoWidth={true}
                    columns={getColumns()}
                    background="#ffffff00"
                    data={assetList}
                    title={i18n.t(k.ITEM_POOL)}
                    onRowClicked={row => {
                        const found = assets.find(asset => asset._id === row._id)
                        if (!found) {
                            loadAssetsByParent(row._id)
                        } else {
                            NotificationManager.warning(i18n.t(k.ALREADY_ADDED_TO_AREA))
                        }
                    }}
                    noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                            rangeSeparatorText: i18n.t(k.ROWS_OF),
                            noRowsPerPage: false,
                            selectAllRowsItem: false,
                            selectAllRowsItemText: i18n.t(k.ROWS_ALL)
                        }
                    }
                    progressPending={loading}
                    progressComponent={<Circular />} />
                }
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleSubmitClick}>Submit</Button>
        </Modal.Footer>
    </Modal>
};

export default SelectableAssetListModal