import React from "react";
import { Nav } from "shards-react";
import UserActions from "./UserActions";
import { onMerchantSelect } from "../../../misc";
import LanguageSelectorComponent from "../../../components/ui_utils/LanguageSelectorComponent";

export default () => (
    <Nav navbar className="border-left flex-row">
        {/*<Notifications />*/}
        <LanguageSelectorComponent />
        <UserActions onMerchantSelect={onMerchantSelect} />
    </Nav>
);
