import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { Component } from "react";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import "react-confirm-alert/src/react-confirm-alert.css";
import DataTable from "react-data-table-component";
import CheckIcon from "@material-ui/icons/Check";
import HonkioAPI from "../../middlewares/HonkioAPI";
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import AddIcon from "@material-ui/icons/Add";
import WidgetAddModal from "./addModal/WidgetAddModal";
import SensorImportModal from "./SensorImportModal";
import * as qs from "qs";
import { Button } from "shards-react";

const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

class SensorListComponent extends Component {
    constructor(props) {
        super(props);
        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.toggle = this.toggle.bind(this);
        this.state = {
            loading: false,
            modal: {
                visible: false,
            },
        };
    }

    showModal() {
        this.setState({
            ...this.state,
            modal: { ...this.state.modal, visible: true },
        });
    }

    hideModal() {
        this.setState({ modal: { ...this.state.modal, visible: false } });
    }
    toggle() {
        this.setState({
            modal: { ...this.state.modal, visible: !this.state.modal.visible },
        });
    }

    componentDidMount() {
        let building = this.props.match.params.buildingId;
        // alert(building)
        this.loadAssets(building);
    }

    loadAssets(building) {
        let that = this;
        that.setState({ loading: true });
        let loadAsset = async function () {
            let buildingResponse = { name: null };

            // that.setState({ 'assets': response.assets, 'loading': false })
            let query = {};
            query["query_building"] = building ? building : "none";

            let response = await HonkioAPI().userShop.userFetch(
                "merchantassetlist",
                query
            );
            that.setState({
                assets: response.assets,
                building_name: buildingResponse.name,
                loading: false,
            });
        };
        loadAsset();
    }

    getColumns() {
        let state = this.state;
        let that = this;
        return [
            {
                name: "#",
                selector: "id",
                cell: function (d) {
                    let icon;
                    switch (d.group) {
                        case "temperature":
                            icon = i18n.t(k.FAS_FA_THERMOMETER_HALF_FA_X);
                            break;
                        case "humidity":
                            icon = i18n.t(k.FAS_FA_TINT_FA_X);
                            break;
                        case "proximity":
                            icon = i18n.t(k.FAS_FA_DOOR_OPEN_FA_X);
                            break;
                        case "touch":
                            icon = i18n.t(k.FAS_FA_HAND_POINT_UP_FA_X);
                            break;
                    }

                    return <i className={icon}>&nbsp;</i>;
                },
                //
                width: "10%",
                sortable: false,
            },

            {
                name: i18n.t(k.NAME),
                width: "75%",
                cell: function (d) {
                    return d.name;
                },
                sortable: true,
            },

            {
                name: i18n.t(k.ACTION),
                selector: "action",
                width: "15%",
                cell: function (d) {
                    // console.log('d');
                    // console.log(d);

                    return (
                        <span className="pointer">
                            <CheckIcon
                                onClick={(e) => {
                                    that.props.onAssetSelected(d);
                                    that.props.nextStep();
                                }}
                            />
                        </span>
                    );
                },
            },
        ];
    }

    render() {
        return (
            <div>
                <div className="container-fluid">
                    <PageHeaderComponent
                        title={
                            this.state.building_name
                                ? i18n.t(k.SENSORS_OF) +
                                  ` ${this.state.building_name}`
                                : i18n.t(k.UNASSIGNED_SENSORS)
                        }
                        subtitle={i18n.t(k.LIST_OF_SENSORS)}
                    />
                </div>
                <CardWrapperComponent
                    header={true}
                    footer={true}
                    card_title={i18n.t(k.BUILDING_SENSORS)}
                    rightIcon={
                        <Button
                            disabled={!this.state.selection}
                            className="float-right pointer"
                            onClick={this.showModal}
                        >
                            {i18n.t(k.ASSIGN)}
                        </Button>
                    }
                >
                    <DataTable
                        // title="Employees"
                        noHeader={true}
                        columns={this.getColumns()}
                        background="#ffffff00"
                        data={this.state.assets}
                        noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                        paginationComponentOptions={{
                            rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                            rangeSeparatorText: i18n.t(k.ROWS_OF),
                            noRowsPerPage: false,
                            selectAllRowsItem: false,
                            selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                        }}
                        selectableRows={
                            typeof this.props.match.params.buildingId !==
                            "undefined"
                        }
                        onSelectedRowsChange={(e) =>
                            this.setState({ selection: e })
                        }
                        // data={data}
                        progressPending={this.state.loading}
                        progressComponent={<Circular />}
                    />
                </CardWrapperComponent>
                <SensorImportModal
                    visible={this.state.modal.visible}
                    open={this.toggle}
                    toggle={this.hideModal}
                    onRequestClose={this.hideModal}
                    selection={this.state.selection}
                    addWidget={this.addWidget}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SensorListComponent);
