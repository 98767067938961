import i18n from 'i18next'; import k from "./../../i18n/keys"; import React, { Component } from 'react';
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button, Form, FormGroup, FormInput } from "shards-react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import HonkioAPI from "../../middlewares/HonkioAPI";
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import NotificationManager from "react-notifications/lib/NotificationManager";
import { post } from 'axios';
import { UPLOAD_URL, BACKEND_URL } from "../../config";
import CenteredPageLoader from "../ui_utils/CenteredPageLoader";
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";
import { ASSET_TYPE } from './../../actions/api/assets/config';

class FloorPlanCreateComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      floorplan: {
        type: ASSET_TYPE.FLOORPLAN,
        structure: '', //todo set floorplan structure
        properties: {}
      }
    };
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    let string = this.props.match.params.building;
    // string = string.split("/");
    // let building = string[string.length - 1];
    this.setState({ 'building': string });
  }

  handleChange(e) {
    this.setState({
      floorplan: {
        ...this.state.floorplan,
        [e.target.id]: e.target.value
      }
    });
  }

  save() {
    let that = this;
    let savefloorplan = async function () {
      that.setState({ loading: true });
      /*let params = {
        'title': that.state.floorplan.name,
        'file': that.state.floorplan.description,
        'building': that.state.building
      };*/
      let params = {
        merchant: localStorage.getItem('merchant_id'),
        name: that.state.floorplan.name,
        //longitude: that.state.streetCoords.lon,
        //latitude: that.state.streetCoords.lat,
        //properties: assetProperties,
        structure: that.state.floorplan.structure,
        type: ASSET_TYPE.FLOORPLAN,
        parent: that.state.building,
        properties: {}
      };
      if (that.state.floorplan.id) {
        params['id'] = that.state.floorplan.id;
      }
      let response = await HonkioAPI().userShop.userFetch('assetset', params, error => {
        that.setState({ loading: false })
        NotificationManager.warning(i18n.t(k.FLOORPLAN_IMPORT_FAILED), `${error.description}`);
      });
      if (response?.status === 'accept' && response?.asset._id) {
        that.setState({
          floorplan: {
            ...that.state.floorplan,
            id: response.asset._id
          }
        });
        let data = {
        };
        const formData = new FormData();
        let url, thumb;
        // let responseFile = await post(responseUrl.upload_url, formData, config).then(uploadFile => {
        //   url = uploadFile.data.url;
        //   thumb = uploadFile.data.thumbnail_url;
        // }, error => {
        //   NotificationManager.warning(i18n.t(k.UPLOAD_FAILED_IMAGE), `${error.description}`);
        //   that.setState({ 'loading': false });
        // });
        let responseFile = await HonkioAPI().userShop.userFetch('fileupload', {"file": that.state.file, "content-type": 'multipart/form-data'}).then(uploadFile => {
            url = uploadFile.file.url;
            thumb = uploadFile.file.url;
          }, error => {
            NotificationManager.warning(i18n.t(k.UPLOAD_FAILED_IMAGE), `${error.description}`);
            that.setState({ 'loading': false });
          });
        if (url && thumb) {
          let resporseUrl = await HonkioAPI().userShop.userFetch('assetset',
            {
              id: that.state.floorplan.id,
              properties: {
                url: url,
                custom_fields: {
                  thumbUrl: thumb
                }
              }
            }, error => {
              NotificationManager.warning(i18n.t(k.UPLOAD_FAILED_IMAGE), `${error}`);
              that.setState({ 'loading': false });
            });
          that.setState({ loading: false });
          if (resporseUrl.status === 'accept') {
            NotificationManager.success(i18n.t(k.FLOORPLAN_IMPORTED));
            that.props.history.push(`/floorplans/${that.state.building}`);
          }
        } else {
          // NotificationManager.warning(i18n.t(k.FLOORPLAN_IMPORT_FAILED));
          // that.setState({ loading: false });
          NotificationManager.warning(i18n.t(k.UPLOAD_FAILED_IMAGE));
          // that.props.history.push(`/floorplans/${that.state.building}`);
        }
    } else /*if (response.status === 'error') */ {
      NotificationManager.warning(i18n.t(k.FLOORPLAN_IMPORT_FAILED));
      that.setState({ loading: false });
    }
  };
    savefloorplan();
  }

  render() {
    let that = this;
    let saveButton = <Button onClick={this.save}>{i18n.t(k.SAVE)}</Button>;
    const FileUploader = () => {
      const handleChangeStatus = status => {
        that.setState({ 'file': status.file, 'meta': status.meta });
      };
      const handleSubmit = (files, allFiles) => {
        allFiles.forEach(f => f.remove());
      };
      return (
        <div>
          <Dropzone
            // getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            // onSubmit={handleSubmit}
            accept="image/*"
            maxFiles={1}
            multiple={false}
            // getFilesFromEvent={(e, a)=> console.error(e, a)}
            inputContent={(files, extra) => extra.reject ? i18n.t(k.IMAGE_FILES_ONLY) : i18n.t(k.CLICK_HERE_TO_UPLOAD_FILE)}
            styles={{ dropzone: { minHeight: 150, maxHeight: 250 } }}
            submitButtonContent="" />
        </div>);
    };
    if (this.state.loading) {
      return <CenteredPageLoader />;
    }
    let fileimage;
    if (this.state.meta) {
      fileimage = <img alt="asd" src={that.state.meta.previewUrl} width="600" className="border-top border-bottom mb-2" />;
    }
    return (
      <div>
        <div className="container-fluid">
          <PageHeaderComponent
            title={""} />
        </div>
        <CardWrapperComponent
          header={true}
          footer={true}
          card_title={i18n.t(k.FLOORPLAN_IMPORT)}>
          <Form>
            <FormGroup>
              {/*<input type="file" onChange={(e) =>{this.setState({file:e.target.files[0]})}} />*/}
              <FileUploader />
            </FormGroup>
            {fileimage}
            <FormGroup>
              <label htmlFor="name">{i18n.t(k.NAME)}</label>
              <FormInput id="name"
                value={this.state.floorplan.name}
                onChange={e => this.handleChange(e)} />
            </FormGroup>
            {saveButton}
          </Form>
        </CardWrapperComponent>
      </div>);
  }
}

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FloorPlanCreateComponent);
