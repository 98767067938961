import i18n from 'i18next';
import k from '../../i18n/keys';
import React, {Component} from 'react';
import {Modal, ModalBody, ModalHeader} from "shards-react";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class QuestionEditModalComponent extends Component {
    constructor(props) {
        super(props);
        this.answers_fields = []
        this.answers_numbers = []
        this.state = {
            count_of_question: 1,
            answers: this.answers_fields,
            answer_numbers: this.answers_numbers
        }
        this.addAnswerField = this.addAnswerField.bind(this)
    }

    addAnswerField() {
        // console.log('Add Answer Field')
        this.setState({
            count_of_question: this.state.count_of_question + 1
        })
        // console.log('Co./unt of questions', this.state.count_of_question)
        let field = (<div className="form-group">
            <label htmlFor="question">{i18n.t(k.ANSWER_NUMBER)} {this.state.count_of_question}</label>
            <input type="text" className="form-control answers" name={`answer${this.state.count_of_question}`}
                   placeholder={i18n.t(k.SET_POSSIBLE_ANSWER)} onChange={this.handleChange}/>
        </div>)
        this.answers_fields.push(field)
        this.answers_numbers.push(this.state.count_of_question)
        this.setState({
            answers: this.answers_fields,
            answer_numbers: this.answers_numbers
        })
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }
    handleSubmit = (e) => {
        e.preventDefault()
        let count = this.state.count_of_question
        let answers = document.getElementsByClassName('answers')
        let options = {}
        for (let i=0; i<count-1; i++){
            options[i] = {
                'name': answers[i].value
            }
        }
        let text = this.state.question
        let title = `${text}`
        let question = {
            title: title,
            text: text,
            options: options,
            object: null,
            object_type: 'reservation'
        }
        this.props.addQuestion(question)
        this.props.onQuestionSaved()

    }

    render() {
        let button = <button className="btn btn-primary"><i className="fas fa-plus"/> {i18n.t(k.CREATE_QUESTION1)}</button>
        if (this.state.loading) {
            button = <CircularProgress size={35}/>
        }
        return (
            <Modal open={this.props.visible}
                   toggle={this.props.toggle}
                   onRequestClose={this.props.onRequestClose}
                   className="w-100 modal-lg modal-dialog-centered modal-dialog-scrollable">
                <ModalHeader>{this.props.question.id ? i18n.t(k.EDIT_QUESTION) : i18n.t(k.CREATE_QUESTION)}</ModalHeader>
                <ModalBody>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="question">{i18n.t(k.QUESTION)}</label>
                                <input type="text" className="form-control" name="question" onChange={this.handleChange}
                                       placeholder={i18n.t(k.INPUT_QUESTION_HERE)}/>
                            </div>
                            <div className="form-group">
                                <button className="btn  btn-sm btn-success" onClick={this.addAnswerField}
                                        type="button">Add answer
                                </button>
                            </div>
                            <div className="" id="answers">
                                {this.state.answers.map((item, index) => {
                                    return (<div key={index}>{item}</div>)
                                })}
                            </div>
                            <hr/>
                            <div className="form-group">
                                {button}

                            </div>
                        </form>
                </ModalBody>

            </Modal>
        );
    }
}
