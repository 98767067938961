import i18n from "i18next";
import k from "./../../i18n/keys";
import React from "react";
import { Button, FormGroup, FormInput, FormTextarea } from "shards-react";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-notifications/dist/react-notifications.css";
import "@fullcalendar/daygrid/main.css";
import "react-notifications/dist/react-notifications.js";
import DatePickerComponent from "../ui_utils/DatePickerComponent";
import Select from "react-select";
import DeleteForever from "@material-ui/icons/DeleteForever";

import moment from "moment";
import "moment/locale/fi";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

export default class EventEditComponent extends React.Component {
    constructor(props) {
        super(props);
        this.onEventDeleteHandler = this.onEventDeleteHandler.bind(this);
    }

    onEventDeleteHandler() {
        this.props.onEventRemove();
        this.props.goToStep(1);
    }

    render() {
        let deleteEvent;
        // console.log("EVENT ")
        // console.log(this.props.edit)
        if (this.props.edit) {
            if (this.props.edit.id) {
                deleteEvent = (
                    <Button
                        theme="danger"
                        className="mb-4"
                        onClick={this.onEventDeleteHandler}
                    >
                        <DeleteForever className="mr-1" fontSize="small" />
                        Delete Event
                    </Button>
                );
            }
        }
        let selector = (
            <Select
                id="meeting_type"
                options={[
                    { value: i18n.t(k.BASIC), label: i18n.t(k.BASIC1) },
                    { value: i18n.t(k.GENERAL), label: i18n.t(k.GENERAL1) },
                ]}
                placeholder={i18n.t(k.SELECT_MEETING_TYPE)}
                onChange={(e) => this.props.handleChange(e)}
            />
        );
        if (this.props.edit.type) {
            selector = (
                <Select
                    id="meeting_type"
                    options={[
                        { value: i18n.t(k.BASIC), label: i18n.t(k.BASIC1) },
                        { value: i18n.t(k.GENERAL), label: i18n.t(k.GENERAL1) },
                    ]}
                    value={{ ...this.props.edit.type }}
                    placeholder={i18n.t(k.SELECT_MEETING_TYPE)}
                    onChange={(e) => this.props.handleChange(e)}
                />
            );
        }
        return (
            <div>
                {deleteEvent}
                <div>
                    <DatePickerComponent
                        endDateLabel="End Date and Time"
                        startDateLabel="Start Date and Time"
                        dates={false}
                        endSelector={true}
                        start_date={moment(this.props.edit.start_date).toDate()}
                        end_date={moment(this.props.edit.end_date).toDate()}
                        handleChangeEvent={(e) => {
                            e.target["id"] = e.target.name;
                            this.props.handleChange(e);
                        }}
                    />
                    <FormGroup>
                        <label htmlFor="title">{i18n.t(k.TITLE)}</label>
                        <FormInput
                            id="title"
                            placeholder={i18n.t(k.TITLE)}
                            className="mb-2"
                            value={this.props.edit.title}
                            onChange={this.props.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor="meeting_type">{i18n.t(k.TYPE)}</label>
                        {selector}
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor="agenda">{i18n.t(k.AGENDA)}</label>
                        <FormTextarea
                            id="agenda"
                            onChange={this.props.handleChange}
                            value={this.props.edit.agenda}
                        />
                    </FormGroup>
                </div>
            </div>
        );
    }
}
