export const AGREEMENT_FIELDS = {
    AGREEMENT_ID: "agreement_ID",
    CONTRACT_TYPE: "contract_type",
    BUILDING: "building",
    START_DATE: "start_date",
    END_DATE: "end_date",
    RESIGN_DATE: "resign_date",//
    RELEASE_PERIOD: "release_period",
    BILLING_START_DATE: "billing_start_date",//
    AMOUNT: "amount",
    AMOUNT_PER_MONTH: "amount_per_month",
    MAINTENANCE_COST: "maintenance_cost",
    AMOUNT_TAX: "amount_tax",
    MINIMAL_ANNUAL_RENT_INCREASE: "minimal_annual_rent_increase",
    ANNUAL_RENT_INCREASE: "annual_rent_increase",
    RENT_TOTAL_AREA: "rent_total_area",
    TENANT_NAME: 'tenant_name',
    TENANT_EMAIL: 'tenant_email',
}