import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { Component } from "react";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataTable from "react-data-table-component";
import "react-confirm-alert/src/react-confirm-alert.css";
import HonkioAPI from "../../middlewares/HonkioAPI";
import { Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Schedule from "@material-ui/icons/Schedule";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForever from "@material-ui/icons/DeleteForever";
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";
import { Link } from "react-router-dom";

const Circular = () => (
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

class PortfolioListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            portfolio: {},
            buildings: [],
            areas: [],
            avg_maturity: i18n.t(k.LOADING1),
        };

        this.loadPortfolio = this.loadPortfolio.bind(this);
        this.getBuildingSlideShow = this.getBuildingSlideShow.bind(this);
    }

    componentDidMount() {
        let portfolioId = this.props.match.params.portfolioId;
        this.loadPortfolio(portfolioId);
    }

    getBuildingSlideShow() {
        let buildings = [];
        let fadeImages = [];
        let i = 0;
        this.state.buildings.forEach(function (building) {
            fadeImages.push(building.custom_fields.url);
        });
        if (
            this.state.buildings &&
            typeof this.state.buildings !== "undefined"
        ) {
            this.state.buildings.forEach(function (building) {
                buildings.push(
                    <div className="each-fade">
                        <img src={fadeImages[i]} />
                    </div>
                );

                i++;
            });
        }
        return buildings.length ? buildings : [<img alt="no images" />];
    }

    loadPortfolio(portfolioId) {
        let that = this;
        let response;
        let params = {
            merchant_id: localStorage.getItem("merchant_id"),
            id: portfolioId,
        };

        let merchantgetportfolio = async function () {
            response = await HonkioAPI().userShop.userFetch(
                "merchantbuildingportfoliolist",
                params
            );
            if (response.status === "error") {
                return;
            }
            that.setState({
                portfolio: response.portfolios[0],
                loading: false,
                buildings: response.buildings,
                areas: response.areas,
                total_area: response.total_area,
                amount: response.amount,
            });

            // console.log(response);

            params = { buildings_list: that.state.portfolio.buildings };
            response = await HonkioAPI().userShop.userFetch(
                "merchantaveragematurity",
                params
            );
            that.setState({ avg_maturity: response.avg_maturity });
        };
        merchantgetportfolio();
    }

    getColumns() {
        let that = this;
        return [
            {
                name: i18n.t(k.NAME),
                selector: "name",
                sortable: true,
                style: `cursor: pointer;`,
            },

            {
                name: i18n.t(k.TOTAL_AREA),
                selector: (d) => (
                    <span>
                        {d.rules.total_area}
                        {i18n.t(k.M)}
                    </span>
                ),
                sortable: true,
                style: `cursor: pointer;`,
            },

            {
                name: i18n.t(k.BUILDING),
                selector: "building_name",
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.OWNER_NAME),
                selector: (d) => <span>{d.rules.owner_name}</span>,
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.AREA_TYPE),
                selector: (d) => (
                    <span>
                        {d.rules.type[0].toUpperCase() + d.rules.type.slice(1)}
                    </span>
                ), //to upper case first letter
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.RESERVABLE),
                selector: (d) => (
                    <span>
                        {d.rules.reservable ? i18n.t(k.TRUE) : i18n.t(k.FALSE)}
                    </span>
                ),
                sortable: true,
                style: `cursor: pointer;`,
            },

            {
                name: i18n.t(k.ACTION),
                selector: "action",
                cell: function (d) {
                    let opentimes;
                    if (d.rules.reservable) {
                        opentimes = (
                            <Link to={`/opentimes/${d.id}`} className="pointer">
                                <Schedule />
                            </Link>
                        );
                    }
                    return (
                        <span>
                            <Link
                                to="#"
                                className="pointer"
                                onClick={() => {
                                    that.confirmDelete(d);
                                }}
                            >
                                <EditIcon />
                            </Link>
                            {opentimes}
                            <Link
                                to="#"
                                className="pointer"
                                onClick={() => {
                                    that.confirmDelete(d);
                                }}
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ];
    }

    render() {
        let that = this;

        if (this.state.portfolio && this.state.buildings) {
            return (
                <div>
                    <div className="container-fluid">
                        {/*<PageHeaderComponent*/}
                        {/*  title={`${i18n.t(k.PORTFOLIO)} ${this.state.portfolio.name}`}*/}
                        {/*  subtitle={i18n.t(k.PORTFOLIO)}*/}
                        {/*/>*/}
                    </div>
                    <CardWrapperComponent
                        header={true}
                        footer={true}
                        card_title={`${i18n.t(k.PORTFOLIO_L)}: ${
                            this.state.portfolio.name
                        }`}
                    >
                        <div className="list-group col-md-12">
                            <div className="row">
                                <div
                                    className="list-group col-md-6"
                                    style={{ maxWidth: "640px" }}
                                >
                                    <div
                                        className="slide-container"
                                        style={{
                                            minWidth: "640px",
                                            marginLeft: "0rem",
                                            marginBottom: "20px",
                                        }}
                                    >
                                        <Zoom className="ml-3">
                                            {this.getBuildingSlideShow()}
                                        </Zoom>
                                    </div>
                                </div>
                                <div className="list-group col-md-6 ml-4">
                                    <li
                                        href="#"
                                        className="list-group-item pl-3 list-group-item-action"
                                    >
                                        {i18n.t(k.PORTFOLIO_TITLE)}{" "}
                                        {this.state.portfolio.name}
                                    </li>
                                    <li
                                        href="#"
                                        className="list-group-item pl-3 list-group-item-action"
                                    >
                                        {i18n.t(k.PORTFOLIO_DESCRIPTION)}{" "}
                                        {this.state.portfolio.description}
                                    </li>
                                    <li
                                        href="#"
                                        className="list-group-item pl-3 list-group-item-action"
                                    >
                                        {i18n.t(k.TOTAL_AREA1)}{" "}
                                        {this.state.total_area} {i18n.t(k.M1)}
                                    </li>
                                    <li
                                        href="#"
                                        className="list-group-item pl-3 list-group-item-action"
                                    >
                                        {i18n.t(k.RENTED_AREA_AMOUNT_MONTH)}{" "}
                                        {this.state.amount} {i18n.t(k.EUR)}
                                    </li>
                                    <li
                                        href="#"
                                        className="list-group-item pl-3 list-group-item-action"
                                    >
                                        {i18n.t(k.PORTFOLIO_AVERAGE_MATURITY)}{" "}
                                        {this.state.avg_maturity}
                                    </li>
                                    <li
                                        href="#"
                                        className="list-group-item pl-3 list-group-item-action"
                                    >
                                        {i18n.t(k.AREA_COUNT)}{" "}
                                        {this.state.areas.length}
                                    </li>
                                    <li
                                        href="#"
                                        className="list-group-item pl-3 list-group-item-action"
                                    >
                                        {i18n.t(k.LONG_TERM_AREAS)}
                                    </li>
                                </div>
                                <div className="list-group col-md-6"></div>
                            </div>
                        </div>
                        <h6 htmlFor="name" className="text-uppercase mt-4">
                            {i18n.t(k.AREAS_IN_BUILDING)}{" "}
                        </h6>
                        <DataTable
                            autoWidth={true}
                            columns={this.getColumns()}
                            background="#ffffff00"
                            data={this.state.areas}
                            noDataComponent={
                                <span>{i18n.t(k.EMPTY_LIST)}</span>
                            }
                            paginationComponentOptions={{
                                rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                                rangeSeparatorText: i18n.t(k.ROWS_OF),
                                noRowsPerPage: false,
                                selectAllRowsItem: false,
                                selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                            }}
                            progressPending={this.state.loading}
                            progressComponent={<Circular />}
                            noHeader={true}
                            onRowClicked={this.rowSelected}
                        />
                    </CardWrapperComponent>
                </div>
            );
        }
        return <Circular />;
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PortfolioListComponent);
