import i18n from 'i18next'; import k from "./../../../i18n/keys"; import React, { Component } from 'react';
import CardWrapperComponent from "../../ui_utils/CardWrapperComponent";
import 'react-confirm-alert/src/react-confirm-alert.css';
import HonkioAPI from "../../../middlewares/HonkioAPI";
import PageHeaderComponent from "../../ui_utils/PageHeaderComponent";
import { Button, Collapse, FormCheckbox } from "shards-react";
import AreaPhotoUploadComponent from "./AreaPhotoUploadComponent";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { confirmAlert } from 'react-confirm-alert'; // Import
import AddIcon from '@material-ui/icons/Add';
import FileBoxComponent from "../../ui_utils/FileBoxComponent";
import AreaPhotoViewComponent from "./AreaPhotoViewComponent"

export default class AreaPhotosComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      area: {},
      modal: { visible: false },
      photoViewModal: {
        visible: false,
        file: null,
      },
    };
    this.loadArea = this.loadArea.bind(this);
  }

  componentDidMount() {
    let areaId = this.props.match.params.areaId;
    this.loadArea(areaId);
    this.loadFiles(areaId);
  }

  loadArea(areaId) {
    let that = this;
    let merchantgetareas = async function () {
      let response = await HonkioAPI().userShop.userFetch('merchantassetlist', { "query_id": areaId }, error => {
        //that.setState({ loading: false });
      });
      if (response?.status === 'accept' && response.assets) {
        let areas = response.assets;
        that.setState({ 'area': areas[0] });
      } else {
        //that.setState({ loading: false });
      }
    }
    merchantgetareas();
  }

  loadFiles(object_id) {
    let that = this;
    let merchantfileslist = async function () {
      let response = await HonkioAPI().userShop.userFetch('merchantfileslist', { object: object_id, query_type: "image" });
      let files = response.files;
      that.setState({ 'files': files, 'loading': false });
    };
    merchantfileslist();
  }

  hideModal = () => {
    this.setState({ ...this.state, 'modal': { ...this.state.modal, visible: false } });
  };

  hidePhotoViewModal = () => {
    this.setState({ ...this.state, 'photoViewModal': { ...this.state.photoViewModal, visible: false } });
  };

  showModal = () => {
    this.setState({ ...this.state, 'modal': { ...this.state.modal, visible: true } });
  };

  showPhotoViewModal = (file) => {
    this.setState({ ...this.state, 'photoViewModal': { ...this.state.photoViewModal, visible: true, file: file } });
  };

  toggle = () => {
    this.setState({ 'modal': { ...this.state.modal, visible: !this.state.modal.visible } });
  };

  photoViewToggle = () => {
    this.setState({ 'photoViewModal': { ...this.state.photoViewModal, visible: !this.state.photoViewModal.visible } });
  };

  confirmDelete(file) {
    let that = this;
    this.setState({ "loading": true });
    const options = {
      title: i18n.t(k.DELETE),
      message: '',
      childrenElement: () =>
        <p>
          {i18n.t(k.ARE_YOU_SURE_WANT_TO_DELETE_ME)}
        </p>,
      buttons: [
        {
          label: i18n.t(k.YES),
          onClick: () => {
            const del = async () => {
              that.setState({ 'loading': true });
              let response = await HonkioAPI().userShop.userFetch('userfileset', {
                'id': file.id,
                'object': "",
                'object_type': "",
                'access': "private"
              });

              if (response.status === 'accept') {
                that.setState({ "types": { ...that.state.types, [file.properties.type]: false } });
                NotificationManager.success(i18n.t(k.SUCCESS), i18n.t(k.DOCUMENT_HAS_BEEN_REMOVED));
                this.loadFiles(that.props.match.params.areaId);

              } else {
                NotificationManager.warning(`${response.description}`, i18n.t(k.DELETE_FAILED));
              }

            };
            del();
          }
        },
        {
          label: i18n.t(k.NO), onClick: () => { }
        }],


      closeOnEscape: true,
      closeOnClickOutside: true
    };

    confirmAlert(options);
  }

  forceDownload(blob, filename) {
    let a = document.createElement('a');
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  load(file) {
    let that = this;
    NotificationManager.success(i18n.t(k.SUCCESS), i18n.t(k.DOCUMENT_DOWNLOADING_STARTED));
    async function loadAsync() {
      const response = await fetch(file.url, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Authorization': localStorage.getItem('token')
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
      });
      response.blob().then(blob => {
        let blobUrl = window.URL.createObjectURL(blob);
        that.forceDownload(blobUrl, file.properties.file);
      });
    }
    loadAsync();
  }

  render() {
    let that = this;
    let title = "Photos: " + (this.state.area.name ? this.state.area.name : "Loading...");
    return (
      <div>
        <div className="container-fluid mb-4">
          <PageHeaderComponent title={title} subtitle={i18n.t(k.OVERVIEW)} />
        </div>
        <CardWrapperComponent add_class="mb-5" header={true} card_title={title}>
          <div className="col-lg-12 justify-content-center align-items-center">
            <Button className="ml-3 mb-4" theme="info" onClick={this.showModal}><AddIcon /> {i18n.t(k.UPLOAD_FILE)}</Button>
            <FileBoxComponent
              data={this.state.files}
              preview={true}
              onPreviewClick={that.showPhotoViewModal}
              onDelete={file => that.confirmDelete(file)}
              onDownload={file => that.load(file)}
              onUploadClick={() => that.showModal()} />
          </div>
          <AreaPhotoUploadComponent
            visible={this.state.modal.visible}
            toggle={this.toggle}
            onRequestClose={this.hideModal}
            areaId={this.props.match.params.areaId}
            onMediaUploaded={() => {
              this.loadFiles(this.props.match.params.areaId);
              this.hideModal();
            }
            }
          />
          <AreaPhotoViewComponent
            file={this.state.photoViewModal.file}
            visible={this.state.photoViewModal.visible}
            toggle={this.photoViewToggle}
            onRequestClose={this.hidePhotoViewModal}
          />
        </CardWrapperComponent>
      </div>);
  }
}