import HonkioAPI from '../../../middlewares/HonkioAPI';

const setAdminRole =({ delete: del = false, ...rest }, errorHandler = {}) => {
    const params = {
      delete: del,
      ...rest,
    };
    // console.log(JSON.stringify(params));
    return HonkioAPI().mainShop.userFetch('adminroleset', params, errorHandler);
  };

export default setAdminRole;