import i18n from 'i18next';import k, { BUILDING_INCOME_PIE } from "./../../../i18n/keys";import React, { Component } from 'react';
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Button, Form, FormGroup } from "shards-react";
import { CONSUMER_URL } from "../../../config";
import CheckIcon from "@material-ui/icons/Check";
import FormInputValidation from "../../ui_utils/FormInputValidationComponent";
import Select from "react-select";

const Circular = () =>
// we need to add some padding to circular progress to keep it from activating our scrollbar
<div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>;


class GenericWidgetBasicInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      type: i18n.t(k.MATURITY),
      name: '' };


  }


  render() {

    return (
      <div>
                <FormGroup>
                    <label htmlFor="name">{i18n.t(k.NAME)}</label>
                    <FormInputValidation id="name" validationType="notNull"
          value={this.state.name}
          placeholder={i18n.t(k.NAME)}
          onChange={e => this.setState({ name: e.target.value })}
          handleValidation={this.props.handleValidation} />
                </FormGroup>
                <FormGroup>
                <label htmlFor="type">{i18n.t(k.TYPE)}</label>
                <Select id="type" options={[
          { 'value': 'maturity', label: i18n.t(k.AVERAGE_MATURITY) },
          { 'value': 'tenants', label: i18n.t(k.TENANTS_COUNT) },
          { 'value': 'square', label: i18n.t(k.AREA_SQUARE) },
          { 'value': 'rental_cost', label: i18n.t(k.RENTAL_COST2) },
          { 'value': 'building_invoices', label: i18n.t(k.BUILDING_CASHFLOW) },
          { 'value': 'building_income_pie', label: i18n.t(k.BUILDING_INCOME_PIE) },
          ]}
          defaultValue={{ 'value': 'maturity', label: i18n.t(k.AVERAGE_MATURITY) }} onChange={e => {
            this.setState({
              'type': e.value });

          }} />
                </FormGroup>
                <Button theme="primary" className="float-right" onClick={() => this.props.addWidget(this.state.name, this.state.type)}>{i18n.t(k.NEXT)}</Button>
            </div>);

  }}


function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = dispatch => ({});


export default connect(mapStateToProps, mapDispatchToProps)(GenericWidgetBasicInfo);
