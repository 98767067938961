import i18n from "i18next";
import k from "./i18n/keys";
import React, { Component } from "react";
import { history, persistor, store } from "./store";
import { Redirect, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";

import { REDIRECT_URL } from "./config";
import routes from "./routes";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";

import "./main.css";
import "react-widgets/dist/css/react-widgets.css";

import Moment from "moment";
import "moment/locale/fi";

import momentLocalizer from "react-widgets-moment";
import ErrorBoundary from "./components/ErrorBoundary";
import "./i18n/init.js";

const actualLocale = Moment.locale(localStorage.getItem("i18nextLng") ?? "en");

momentLocalizer();
// Function for providing "protected routing" redirects in case of unauthorized request
const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            localStorage.getItem(i18n.t(k.TOKEN)) ? (
                <Component {...props} />
            ) : (
                // <Component {...props}/>
                <Redirect
                    to={{
                        pathname: REDIRECT_URL,
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

class App extends Component {
    render() {
        return (
            <ErrorBoundary>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <ConnectedRouter history={history}>
                            <Switch>
                                {routes.map((route, index) => {
                                    let route_props = {
                                        key: index,
                                        path: route.path,
                                        exact: route.exact,
                                        // component: route.component
                                        component: (props) => {
                                            return (
                                                <route.layout {...props}>
                                                    <route.component
                                                        {...props}
                                                    />
                                                </route.layout>
                                            );
                                        },
                                    };

                                    return route.protected ? (
                                        <ProtectedRoute {...route_props} />
                                    ) : (
                                        <Route {...route_props} />
                                    );
                                })}
                            </Switch>
                        </ConnectedRouter>
                    </PersistGate>
                </Provider>
            </ErrorBoundary>
        );
    }
}

export default App;
