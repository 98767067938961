import i18n from "i18next";
import k, { PRIVATE } from "../../../i18n/keys";
import React, { Component } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { APP_ID, UPLOAD_URL } from "../../../config";
import { post } from "axios";
import { Modal, ModalBody, ModalHeader } from "shards-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "react-select";
import { connect } from "react-redux";

// export default class DocumentUploadModalComponent extends Component {
class ImageUploadModalComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleChange(files) {
        this.setState({
            files: files,
        });
    }

    handleButtonUpload = () => {
        let itemsProcessed = 0;
        let that = this;
        let allowedExtensions = ["jpg", "jpeg", "png"];
        this.state.files.forEach((file) => {
            let filename = file.name.split(".")[0];
            let filepath = file.path;
            let filetype = that.state.doc_type;

            let inArray = allowedExtensions.includes(file.name.split(".")[1]);
            if (inArray) {
                that.setState({ loading: true });

                let savefile = async function () {
                    let params = {
                        title: filename,
                        file: filepath,
                        asset: that.state.asset,
                        type: filetype,
                    };

                    const merchant_id = localStorage.getItem("merchant_id");
                    if (!merchant_id) {
                        NotificationManager.warning(
                            `Please select merchant first`
                        );
                        return;
                    }

                    let message = {
                        id: that.props.consumer_info.consumer_info.id,
                        file: file,
                        "content-type": "multipart/form-data",
                        // object: APP_ID,
                        object: merchant_id,
                        object_type: that.props.objectType,
                        access: "public",
                        file_type: "image",
                        extension: file.name.split(".")[1],
                        display_name: filename,
                        metadata: { document_type: filetype },
                    };
                    let url, thumb;
                    let r = await HonkioAPI()
                        .userShop.userFetch("fileupload", message)
                        .then(
                            (uploadFile) => {
                                url = uploadFile.file.url;
                                thumb = uploadFile.file.url;
                            },
                            (error) => {
                                NotificationManager.warning(
                                    i18n.t(k.UPLOAD_FAILED_IMAGE),
                                    `${error.description}`
                                );
                                that.setState({ loading: false });
                            }
                        );
                    itemsProcessed++;
                    NotificationManager.success(
                        `${i18n.t(k.UPLOAD_SUCCESS)} ${filename}`,
                        i18n.t(k.SUCCESS)
                    );
                    if (itemsProcessed === that.state.files.length) {
                        that.setState({ loading: false });
                        that.props.onMediaUploaded();
                    }
                };
                savefile();
            } else {
                itemsProcessed++;
                NotificationManager.warning(
                    `${i18n.t(k.UNSUPPORTED_EXTENSION)} ${filename}`,
                    i18n.t(k.DOCUMENT_UPLOAD_FAILED)
                );
                if (itemsProcessed === that.state.files.length) {
                    that.setState({ loading: false });
                    that.props.onMediaUploaded();
                }
            }
        });
    };

    handleChangeType(e = null) {
        this.setState({ doc_type: e.value });
    }

    render() {
        let that = this;
        let button = (
            <button
                className="btn btn-primary"
                onClick={this.handleButtonUpload}
            >
                Upload image
            </button>
        );
        if (this.state.loading) {
            button = <CircularProgress size={35} />;
        }

        let options = [
            {
                value: "safety_track_map",
                label: "Safety track map",
            },
            {
                value: "image",
                label: "Image",
            },
        ];

        return (
            <Modal
                open={this.props.visible}
                toggle={this.props.toggle}
                size="lg"
                onRequestClose={this.props.onRequestClose}
                className="w-100"
            >
                <ModalHeader>Upload image</ModalHeader>
                <ModalBody>
                    <DropzoneArea
                        dropzoneText={"Drag and drop the image here or click"}
                        onChange={this.handleChange.bind(this)}
                        showPreviews={true}
                        showPreviewsInDropzone={false}
                        maxFileSize={100000000}
                        useChipsForPreview
                        filesLimit={10}
                        previewGridProps={{
                            container: { spacing: 1, direction: i18n.t(k.ROW) },
                        }}
                        previewText="Selected image files"
                        showAlerts={false}
                        styles={{
                            "MuiDropzoneArea-root": {
                                maxHeight: 150,
                                minHeight: 150,
                            },
                        }}
                    />

                    <hr />
                    <Select
                        options={options}
                        defaultValue={{
                            value: -1,
                            label: "Please select image type",
                        }}
                        onChange={(e) => {
                            that.handleChangeType(e);
                        }}
                    />
                    <div className="form-group mt-3">{button}</div>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return { consumer_info: state.consumer_info };
};

export default connect(mapStateToProps)(ImageUploadModalComponent);
