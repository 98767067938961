import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timegrid from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Col, Container, FormCheckbox, FormGroup, Row } from "shards-react";
import CardWrapperComponent from "../../ui_utils/CardWrapperComponent";
import HonkioAPI from "../../../middlewares/HonkioAPI";
// import i18n from "i18next";
// import k from "../../../i18n/keys";

import allLocales from "@fullcalendar/core/locales-all";
import moment from "moment";
import "moment/locale/fi";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

export default class FullCalendarComponent extends React.Component {
    constructor(props) {
        super(props);
        this.calendarRef = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.onEventClick = this.onEventClick.bind(this);
        this.state = {};
    }
    componentDidMount() {
        this.initAreaReservationsSource();
        let calendarApi = this.calendarRef.current.getApi();
        calendarApi.addEventSource(this.reservationsEventsSource);
    }

    // initDaysOffEventsSource() {
    //         this.daysOffEventsSource = function (options, successCallback, failureCallback) {
    //             const request = async () => {
    //                 let merchantgetorders = await HonkioAPI().userShop.userFetch('merchantgetorders', {
    //                     'query_model': 'service_daysoff_request',
    //                     'query_date': {
    //                         'start_date': {
    //                             'gte': moment(options.start).format('YYYY-MM-DD hh:mm:ss').toLocaleString(),
    //                             'lte': moment(options.end).format('YYYY-MM-DD hh:mm:ss').toLocaleString(),
    //                         }
    //                     }
    //                 })
    //                 console.error('merchantgetorders')
    //                 console.error(merchantgetorders)
    //                 let schedule = []
    //                 merchantgetorders.orders.forEach(function (order) {
    //                     let color = '#ff9500';
    //                     let textColor = '#ffffff';
    //                     let title = order.title;
    //                     switch (order.order_status) {
    //                         case 'new':
    //                             color = '#ff0000';textColor = '#ffffff';
    //                             title = `${order.contractors.user_owner.str_firstname} ${order.contractors.user_owner.str_firstname} - New Day off request`
    //                             break;
    //                         case 'approved':
    //                             color = '#99ccff';textColor = '#000000';
    //                             title = `${order.contractors.user_owner.str_firstname} ${order.contractors.user_owner.str_firstname} Vacation`
    //                             break;
    //                         case 'rejected':
    //                             color = '#6600ff';textColor = '#ffffff';
    //                             title = `${order.contractors.user_owner.str_firstname} ${order.contractors.user_owner.str_firstname} Vacation reject`
    //                             break;
    //                     }
    //                     schedule.push({
    //                         'busy': 'free',
    //                         'className': 'pointer',
    //                         'url': '/daysOff',
    //                         'title': title,
    //                         'textColor': textColor,
    //                         'start': moment(order.start_date).toDate(),
    //                         'end': moment(order.end_date).toDate(),
    //                         'backgroundColor': color
    //                     })
    //                 })
    //                 successCallback(schedule)
    //             }
    //             request();
    //         }
    // }

    initAreaReservationsSource() {
        this.reservationsEventsSource = function (
            options,
            successCallback,
            failureCallback
        ) {
            const request = async () => {
                let schedule = [];
                let response = await HonkioAPI().userShop.userFetch(
                    "merchantarealist",
                    { query_properties: { reservable: true } }
                );
                for (let i = 0; i < response.areas.length; i++) {
                    let area = response.areas[i];
                    let cal = await HonkioAPI().userShop.userFetch(
                        "merchantgetcalendar",
                        {
                            query_order: true,
                            query_type: "busy",
                            query_start_date: moment(options.start).format(
                                "YYYY-MM-DD hh:mm:ss"
                            ),
                            query_end_date: moment(options.end).format(
                                "YYYY-MM-DD hh:mm:ss"
                            ),
                            query_object: area.id,
                            query_object_type: "area",
                            //                             'gte': ,
                            //                             'lte': moment(options.end).format('YYYY-MM-DD hh:mm:ss').toLocaleString(),
                        }
                    );
                    cal.schedule.forEach((item) => {
                        // alert(moment(item.start_date).toDate())
                        schedule.push({
                            busy: "busy",
                            // 'url': `/order-details/${order.order_id}`,
                            className: "pointer",
                            title: `[${area.building_name}] ${area.name}${item.description}`,
                            textColor: "#ffffff",
                            start: moment(item.start_date).toDate(),
                            end: moment(item.end_date).toDate(),
                            backgroundColor: "#ff0000",
                        });
                    });
                }

                //
                // merchantgetorders.orders.forEach(function (order) {
                //     let color = '#ff9500';
                //     let textColor = '#ffffff';
                //     let title = `[${order.order_status}] ${order.title}`;
                //     switch (order.order_status) {
                //         case 'enqueued':
                //             color = '#999966';textColor = '#ffffff';
                //             title = `[${order.order_status}] ${order.title}`
                //             break;
                //         case 'enqueued_priority':
                //             color = '#ff6699';textColor = '#ffffff';
                //             title = `[${order.order_status}] ${order.title}`
                //             break;
                //         case 'assigned':
                //             color = '#006699';textColor = '#ffffff';
                //             title = `[${order.order_status}] ${order.title}`
                //             break;
                //         case 'assigned_priority':
                //             color = '#990000';textColor = '#ffffff';
                //             title = `[${order.order_status}] ${order.title}`
                //             break;
                //         case 'job_done':
                //             color = '#66ff33';textColor = '#000000';
                //             title = `[${order.order_status}] ${order.title}`
                //             break;
                //         case 'finished':
                //             color = '#66ff33';textColor = '#000000';
                //             title = `[${order.order_status}] ${order.title}`
                //             break;
                //         case 'completed':
                //             color = '#003300';textColor = '#ffffff';
                //             title = `[${order.order_status}] ${order.title}`
                //             break;
                //     }
                //     if (order.order_status !== 'postponed') {
                //         order['end_date'] = moment(order.start_date).add(1, 'hours').toDate()
                //     } else
                //     if (moment(order.start_date) < moment()){
                //         order['end_date'] = moment().toDate()
                //     }
                //
                // })
                successCallback(schedule);
            };
            request();
        };
    }

    getEventTimeFormat() {
        // return 'h:mm'
        return {
            // like '14:30:00'
            hour: "2-digit",
            minute: "2-digit",
            meridiem: false,
        };
    }

    handleChange(e, t) {
        let calendarApi = this.calendarRef.current.getApi();
        const newState = { ...this.state };
        newState[t] = !newState[t];

        this.setState(newState);
        calendarApi.removeAllEventSources();
        if (newState["orders"]) {
            calendarApi.addEventSource(this.reservationsEventsSource);
        }
        if (newState["daysoff"]) {
            calendarApi.addEventSource(this.daysOffEventsSource);
        }
        calendarApi.refetchEvents();
    }

    onEventClick(a, b, c) {
        // bind with an arrow function
        // console.log("a");
        // console.log(a);
        // console.log("b");
        // console.log(b);
        // console.log("c");
        // console.log(c);
    }
    render() {
        return (
            <CardWrapperComponent
            // card_title={i18n.t(k.AREA_RESERVATIONS)}
            >
                {/* <h3>{i18n.t(k.AREA_RESERVATIONS)}</h3> */}
                {/*<FormGroup>*/}
                {/*    <FormCheckbox*/}
                {/*        id="orders"*/}
                {/*        theme="warning"*/}
                {/*        checked={this.state.orders}*/}
                {/*        onChange={e => this.handleChange(e, "orders")}>Show orders*/}
                {/*    </FormCheckbox>*/}

                {/*    <FormCheckbox*/}
                {/*        id="daysoff"*/}
                {/*        theme="warning"*/}
                {/*        checked={this.state.daysoff}*/}
                {/*        onChange={e => this.handleChange(e, "daysoff")}>Show worker holidays*/}
                {/*    </FormCheckbox>*/}
                {/*</FormGroup>*/}

                <FullCalendar
                    locales={allLocales}
                    locale={actualLocale}
                    defaultView="dayGridMonth"
                    ref={this.calendarRef}
                    header={{
                        left: "prev,next",
                        center: "title",
                        right: "dayGridMonth,timeGridWeek,timeGridDay",
                    }}
                    plugins={[dayGridPlugin, timegrid, interactionPlugin]}
                    eventTimeFormat={this.getEventTimeFormat()}
                    eventClick={this.onEventClick}
                    displayEventEnd={true}
                />
                <Container className="w-100 m-3 p-3">
                    <Row>
                        <Col>{/* <h3>{i18n.t(k.LEGEND)}</h3> */}</Col>
                    </Row>
                    {/*<Row>*/}
                    {/*  <Col>*/}
                    {/*      <h4>Orders</h4>*/}
                    {/*      <table border="0">*/}
                    {/*          <tr><td style={{'background': '#999966', 'width': 32, 'height': 32}}> </td> <td>Enqueued</td></tr>*/}
                    {/*          <tr><td style={{'background': '#ff0000', 'width': 32, 'height': 32}}> </td> <td>Enqueued (high priority)</td></tr>*/}
                    {/*          <tr><td style={{'background': '#006699', 'width': 32, 'height': 32}}> </td> <td>Assigned</td></tr>*/}
                    {/*          <tr><td style={{'background': '#990000', 'width': 32, 'height': 32}}> </td> <td>Assigned (with priority)</td></tr>*/}
                    {/*          <tr><td style={{'background': '#66ff33', 'width': 32, 'height': 32}}> </td> <td>Job completed or job completed</td></tr>*/}
                    {/*          <tr><td style={{'background': '#003300', 'width': 32, 'height': 32}}> </td> <td>Completed</td></tr>*/}
                    {/*      </table>*/}
                    {/*  </Col>*/}
                    {/*  <Col>*/}
                    {/*      <h4>Employee holidays</h4>*/}
                    {/*      <table border="0">*/}
                    {/*          <tr><td style={{'background': '#99ccff', 'width': 32, 'height': 32}}> </td> <td>Worker vacation (approved)</td></tr>*/}
                    {/*          <tr><td style={{'background': '#6600ff', 'width': 32, 'height': 32}}> </td> <td>Rejected</td></tr>*/}
                    {/*          <tr><td style={{'background': '#ff0000', 'width': 32, 'height': 32}}> </td> <td>New request</td></tr>*/}
                    {/*      </table>*/}
                    {/*  </Col>*/}
                    {/*</Row>*/}
                </Container>
            </CardWrapperComponent>
        );
    }
}
