import i18n from 'i18next'; import k from "./../../i18n/keys"; import React from 'react';
import { GoogleMap, InfoWindow, Marker, StreetViewPanorama, withGoogleMap, withScriptjs } from "react-google-maps";
import Geocode from "react-geocode";
import { GOOGLE_API_KEY } from "../../config";

const { StandaloneSearchBox } = require("react-google-maps/lib/components/places/StandaloneSearchBox");

Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.enableDebug();

const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`;

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      fields: [
        'address',
        'city',
        'area',
        'state',
        'country_name',
        'postal_code',
        'country_iso',
        'latitude',
        'longitude'],

      address: '',
      city: '',
      area: '', // admin
      state: '',
      country_name: '',
      postal_code: '',
      country_iso: '',
      latitude: '',
      longitude: '',
      mapPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng
      },
      markerPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng
      },
      streetViewVisibility : this.props.streetViewVisibility || false
    };
    this.setMapRef = element => {
      this.map = element;
    };
  }

  /**
   * Get the current address from the default map position and set those values in the state
   */
  componentDidMount() {
    let that = this;
    Geocode.fromLatLng(this.state.mapPosition.lat, this.state.mapPosition.lng).then(
      response => {
        if (response.results && response.results.length > 0) {
          const address = response.results[0].formatted_address,
            addressArray = response.results[0].address_components,
            city = that.getCity(addressArray),
            area = that.getArea(addressArray),
            state = that.getState(addressArray),
            postal_code = that.getPostalCode(addressArray),
            country_name = that.getCountry(addressArray)[0],
            country_iso = that.getCountry(addressArray)[1];
          that.setState({
            // search: 'te',
            address: address ? address : '',
            area: area ? area : '',
            city: city ? city : '',
            state: state ? state : '',
            postal_code: postal_code ? postal_code : '',
            country_name: country_name ? country_name : '',
            country_iso: country_iso ? country_iso : '',
            latitude: this.state.mapPosition.lat ? this.state.mapPosition.lat : '',
            longitude: this.state.mapPosition.lng ? this.state.mapPosition.lng : ''
          });
        }      
      },
      error => {
        console.error(error);
      });
      
  }
  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.markerPosition.lat !== this.props.center.lat ||
      this.state.address !== nextState.address ||
      this.state.city !== nextState.city ||
      this.state.area !== nextState.area ||
      this.state.state !== nextState.state ||
      this.state.search !== nextProps.search) {
      return true;
    } else if (this.props.center.lat === nextProps.center.lat) {
      return false;
    }
  }

  /**
   * Get the city and set the city input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCity = addressArray => {
    let city = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };
  /**
   * Get the area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getArea = addressArray => {
    let area = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
  getCountry = addressArray => {
    let country, country_code;
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && 'country' === addressArray[i].types[0]) {
          country = addressArray[i].long_name;
          country_code = addressArray[i].short_name;
          return [country, country_code];
        }
      }
    }
  };
  getPostalCode = addressArray => {
    let postal_code;
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && 'postal_code' === addressArray[i].types[0]) {
          postal_code = addressArray[i].long_name;

          return postal_code;
        }
      }
    }
  };
  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getState = addressArray => {
    let state = '';
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };
  /**
   * And function for city,state and address input
   * @param event
   */
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    this.props.handleChangeEvent(event);
  };
  /**
   * This Event triggers when the marker window is closed
   *
   * @param event
   */
  onInfoWindowClose = event => {
  };
  /**
   * When the user types an address in the search box
   * @param place
   */
  onPlaceSelected = () => {

    let place = this.searchBox.getPlaces()[0];
    let addressArray;
    let address;
    let that = this;
    const run = (addressArray, address) => {
      const city = that.getCity(addressArray),
        area = that.getArea(addressArray),
        state = that.getState(addressArray),
        postal_code = that.getPostalCode(addressArray),
        country_name = that.getCountry(addressArray)[0],
        country_iso = that.getCountry(addressArray)[1],
        latValue = place.geometry.location.lat(),
        lngValue = place.geometry.location.lng();
      // Set these values in the state.
      let fields = {
        address: address ? address : '',
        area: area ? area : '',
        city: city ? city : '',
        state: state ? state : '',
        postal_code: postal_code ? postal_code : '',
        country_name: country_name ? country_name : '',
        country_iso: country_iso ? country_iso : '',
        latitude: latValue ? latValue : '',
        longitude: lngValue ? lngValue : '',
        markerPosition: {
          lat: latValue,
          lng: lngValue
        },
        mapPosition: {
          lat: latValue,
          lng: lngValue
        }
      };
      let streetView = that.map.getStreetView();
      for (const [key, value] of Object.entries(fields)) {
        that.props.handleAddressChange(key, value);
      }
      that.props.handleCoordinatesChange(latValue, lngValue);
      streetView.setVisible(true);
      streetView.setPosition({ lat: latValue, lng: lngValue });
    }
    if (!place.address_components) {
      const geocode = async () => {
        let newLat = place.geometry.location.lat();
        let newLng = place.geometry.location.lng();
        const response = await Geocode.fromLatLng(newLat, newLng);
        address = response.results[0].formatted_address
        addressArray = response.results[0].address_components
        run(addressArray, address)
      };
      geocode();
    } else {
      address = place.formatted_address
      addressArray = place.address_components
      run(addressArray, address)
    }
   
  };
  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */
  onMarkerDragEnd = event => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng(),
      addressArray = [];
    let streetView = this.map.getStreetView();
  
    Geocode.fromLatLng(newLat, newLng).then(
      response => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray),
          postal_code = this.getPostalCode(addressArray),
          country_name = this.getCountry(addressArray)[0],
          country_iso = this.getCountry(addressArray)[1];
        let fields = {
          address: address ? address : '',
          area: area ? area : '',
          city: city ? city : '',
          state: state ? state : '',
          postal_code: postal_code ? postal_code : '',
          country_name: country_name ? country_name : '',
          country_iso: country_iso ? country_iso : '',
          latitude: newLat ? newLat : '',
          longitude: newLng ? newLng : '',
           markerPosition: {
               'lat': newLat,
               'lng': newLng,
           },
          // street: true,
        };

        for (const [key, value] of Object.entries(fields)) {
          this.props.handleAddressChange(key, value);
        }
        streetView.setVisible(true);
        streetView.setPosition({ lat: newLat, lng: newLng });
        this.setState({ markerPosition: {
            lat: newLat,
            lng: newLng
          } })
      },
      error => {
        console.error(error);
      });
      this.props.handleCoordinatesChange(newLat, newLng);
  };
  componentWillReceiveProps(nextProps, nextContext) {
    let that = this;
    if (nextProps.search) {
      // alert(nextProps.search)
      this.setState({ search: nextProps.search })
      setTimeout(() => that.searchBox2.focus(), 400)
    }
  }

  onSearch(text) {
    this.setState({ search: text })
    // if (this.searchBox) {
    this.searchBox.current.value = "text"
    // }
  }

  render() {
    let that = this;
    const SBox = withScriptjs(props => <StandaloneSearchBox
      ref={searchBox => this.searchBox = searchBox}
      onPlacesChanged={this.onPlaceSelected}
    // onPlacesChanged={props.onPlacesChanged}
    >
      <input
        type="text"
        value={that.state.search}
        ref={searchBox => this.searchBox2 = searchBox}
        onChange={(e) => that.setState({ search: e.target.value })}
        className="form-control mb-4" />
    </StandaloneSearchBox>);
    const mmap = withGoogleMap(
      (props) =>
        <div>
          <GoogleMap
            google={this.props.google}
            defaultZoom={this.props.zoom}
            ref={this.setMapRef}
            defaultCenter={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }}
            visible>
            <StreetViewPanorama
            onVisibleChanged={this.onVisibleChanged}
           
              onPositionChanged={e => {
                that.props.onStreetPositionChanged(that.map.getStreetView().getPosition());
              }}
              position={{lat: this.state.latitude, lng: this.state.longitude}}
              onPovChanged={() => {
                that.props.onPovChanged(that.map.getStreetView().getPov());
              }}
              visible={this.state.streetViewVisibility}
            />
            <Marker google={this.props.google}
              name={'Dolores park'}
              draggable={true}
              onDragEnd={this.onMarkerDragEnd}
              position={{ lat: this.state.markerPosition.lat, lng: this.state.markerPosition.lng }} />
            <Marker />
            {/* <InfoWindow
              onClose={this.onInfoWindowClose}
              position={{
                lat: this.state.markerPosition.lat + 0.0008,
                lng: this.state.markerPosition.lng
              }}
              >
              <div>
                <span style={{ padding: 0, margin: 0 }}>{this.state.address}</span>
              </div>
            </InfoWindow> */}
            
            {this.props.customMarkers ? this.props.customMarkers.map((marker) => {
              return <Marker google={this.props.google}
                name={marker.name}
                draggable={false}
                position={{ lat: marker.lat, lng: marker.lng }}
              />
            }
            )
              : ''}
          </GoogleMap>
        </div>
    );

    const AsyncMap = withScriptjs(mmap);

    let map;
    if (this.props.center.lat !== undefined) {
      map = <div>
        <AsyncMap
          googleMapURL={GOOGLE_MAP_URL}
          loadingElement={
            <div style={{ height: `100%` }} />}
          containerElement={
            <div style={{ height: this.props.height }} className="mb-3 " />}
          mapElement={
            <div style={{ height: `100%` }} className="border" />} 
            />
        
      </div>;
    } else {
      map = <div style={{ height: this.props.height }} />;
    }
    return map;
  }
}
export default Map;
