import i18n from 'i18next'; import k from "./../../../../i18n/keys"; import React, { Component, useRef, useState } from 'react';
import CardWrapperComponent from "../../../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "shards-react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import NotificationManager from "react-notifications/lib/NotificationManager";
import HonkioAPI from "../../../../middlewares/HonkioAPI";
import JoditEditor from "jodit-react";
import PageHeaderComponent from "../../../ui_utils/PageHeaderComponent";

const Circular = () =>
  <div style={{ padding: '24px' }}>
    <CircularProgress size={75} />
  </div>;

class AgreementReviewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      id: null,
      text: ''
    };
    this.textInputRef = React.createRef();
    this.loadAgreement = this.loadAgreement.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    let agreement = this.props.match.params.agreement;
    this.setState({ 'agreement': agreement });
    this.loadAgreement(agreement);
  }

  loadAgreement(agreement) {
    let that = this;
    let merchantagreementtemplatelist = async function () {
      let query = { merchant_id: localStorage.getItem('merchant_id') };
      query['rent_id'] = agreement;
      let response = await HonkioAPI().userShop.userFetch('merchantagreementdocumentlist', query, error => {
        NotificationManager.error(`${error?.description}`, i18n.t(k.ERROR));
        that.setState({
          text: '',
          loading: false
        });
      });
      if (response?.status === 'accept' && response?.template && response?.template.length > 0) {
        that.setState({
          text: response?.template[0].text,
          loading: false,
          id: response?.template[0].id
        });
      } else {
        that.setState({
          text: '',
          loading: false
        });
        NotificationManager.error('Document not found');
      }
    };
    merchantagreementtemplatelist();
  }

  save = () => {
    let that = this;
    let merchantagreemendocumentset = async function () {
      let query = {
        merchant_id: localStorage.getItem('merchant_id'),
        id: that.state.id,
        text: that.textInputRef.current.value
      };
      let response = await HonkioAPI().userShop.userFetch('merchantagreementdocumentset', query, error => {
        NotificationManager.error(`${error?.description}`, i18n.t(k.ERROR));
      });
      if (response?.status === 'accept') {
        NotificationManager.success(i18n.t(k.AGREEMENT_SAVED));
      }
    };
    merchantagreemendocumentset();
  };

  render() {
    let that = this;
    const config = { readonly: false };
    let editor = <Circular />;
    if (this.state.text) {
      editor = <JoditEditor
        ref={this.textInputRef}
        value={this.state.text}
        content={this.state.text}
        config={config}
        tabIndex={1} />;
    }
    return (
      <div>
        <div className="container-fluid">
          <PageHeaderComponent
            title={i18n.t(k.AGREEMENT_EDIT)}
            subtitle={i18n.t(k.OVERVIEW)} />
        </div>
        <CardWrapperComponent
          header={true}
          footer={true}
          card_title={i18n.t(k.AGREEMENT_EDIT)}>
          {editor}
          <Button className="mr-2 mt-4" theme="primary" onClick={() => that.save()}>{i18n.t(k.SAVE)}</Button>
        </CardWrapperComponent>
      </div>);
  }
}

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AgreementReviewComponent);
