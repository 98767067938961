import clsx from 'clsx';
import React from 'react'
import { useFormContext } from 'react-hook-form';

const CheckboxSwitch = ({ className, name, value, label, defaultChecked, k, onChange = () => { } }) => {
    const { register } = useFormContext();
    const onChangeField = (data) => {
        onChange(name, data.target.checked)
    }
    return (
        <div key={k} className={clsx(className, 'custom-control custom-switch mb-3')}>
            <input
                // ref={register}
                name={name}
                className="custom-control-input"
                type="checkbox"
                id={name}
                value={value}
                defaultChecked={defaultChecked}
                onChange={onChangeField}
            />
            <label className="custom-control-label" htmlFor={name}>
                {label}
            </label>
        </div>
    );
};

export default CheckboxSwitch;
