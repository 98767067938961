import HonkioAPI from '../../../middlewares/HonkioAPI';

const getAdminRole = (options = {}) => {
  const {
    id,
  } = options;
  return HonkioAPI().mainShop.userFetch('adminroleget', {
    id
  });
};
export default getAdminRole;