import React, { Component } from "react";
import "react-notifications/dist/react-notifications.css";
import "react-notifications/dist/react-notifications.js";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import InvoiceStatusItemComponent from "../InvoiceStatusItemComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import class_data from "../product_types.json";
import i18n from "i18next";
import k from "../../../i18n/keys";

import moment from "moment";
import "moment/locale/fi";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

class AssetsDatatablesComponent extends Component {
    constructor(props) {
        super(props);
        this.loadAssets = this.loadAssets.bind(this);
        this.state = {
            buildingId: this.props.buildingId,
            currentDay: this.props.currentDay,
        };
    }
    componentDidMount() {
        // alert(this.state.buildingId)
        this.loadAssets(this.state.buildingId, this.state.currentDay);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState(nextProps);
        console.error(nextProps);
        this.loadDepts(
            nextProps.buildingId ? nextProps.buildingId : this.state.buildingId,
            nextProps.currentDay ? nextProps.currentDay : this.state.currentDay
        );
    }

    loadAssets(buildingId, date) {
        let that = this;
        that.setState({ assets: [], loading: true });
        let loadAssets = async function () {
            let result = await HonkioAPI().userShop.userFetch(
                "merchantbuildingassetlist",
                {
                    building_id: buildingId,
                    query_date: {
                        creation_date: {
                            lt: moment(date ? date : that.state.currentDay)
                                .endOf("month")
                                .format("YYYY-MM-DD hh:mm:ss"),
                            gte: moment(
                                date ? date : that.state.currentDay
                            ).format("YYYY-MM-DD hh:mm:ss"),
                        },
                    },
                }
            );

            if (result.status === "accept") {
                that.setState({ assets: result.assets, loading: false });
            }
        };
        loadAssets();
    }

    getColumnsAssets() {
        return [
            {
                name: "Name",
                selector: "name",
                sortable: true,
                style: `cursor: pointer;`,
            },

            {
                name: "Value",
                selector: "value",
                sortable: true,
                style: `cursor: pointer;`,
            },

            {
                name: "Last check day",
                selector: "last_check_day",
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: "Class",
                sortable: true,
                selector: function (d) {
                    let codes = class_data;
                    return codes[d.class] ? codes[d.class].en : "";
                },
            },

            {
                name: "Merchant Name",
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (d) {
                    return <span>{localStorage.getItem("merchant_name")}</span>;
                },
            },
        ];
    }

    render() {
        return (
            <DataTable
                // title="Employees"
                className="border"
                noHeader={true}
                autoWidth={true}
                columns={this.getColumnsAssets()}
                background="#ffffff00"
                data={this.state.assets}
                noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                paginationComponentOptions={{
                    rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                    rangeSeparatorText: i18n.t(k.ROWS_OF),
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                }}
                progressPending={this.state.loading}
                progressComponent={<Circular />}
            />
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssetsDatatablesComponent);
