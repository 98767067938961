import i18n from 'i18next';import k from "./../i18n/keys";import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  render() {
    // Handles crashes for lifecycle methods in production mode. Show
    if (this.state.hasError && !(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
      localStorage.clear();
      setTimeout(function () {
        window.location.href = '/';
      }, 5000);
      return <h1>{i18n.t(k.WHOOPS_THE_APP_HAS_CRASHED)}</h1>;
    }
    return this.props.children;
  }}


export default ErrorBoundary;