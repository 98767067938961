// Types
export const SET_PROPERTY_LIST = "SET_PROPERTY_LIST";
export const SET_SELECTED_PROPERTY = "SET_SELECTED_PROPERTY";
export const SET_PROPERTY_TOTAL = "SET_PROPERTY_TOTAL";
export const SET_LANDPLOT_LIST = "SET_LANDPLOT_LIST";
export const SET_SELECTED_LANDPLOT = "SET_SELECTED_LANDPLOT";
export const SET_LANDPLOT_TOTAL = "SET_LANDPLOT_TOTAL";
export const SET_BUILDING_LIST = "SET_BUILDING_LIST";
export const SET_SELECTED_BUILDING = "SET_SELECTED_BUILDING";
export const SET_BUILDING_TOTAL = "SET_BUILDING_TOTAL";
export const SET_BUILDING_ASSET_LIST = "SET_BUILDING_ASSET_LIST";
export const SET_BUILDING_ASSETS_TOTAL = "SET_BUILDING_ASSETS_TOTAL";
export const SET_SELECTED_BUILDING_ASSETS = "SET_SELECTED_BUILDING_ASSETS";
export const SET_SAFETY_IMAGE = "SET_SAFETY_IMAGE";
export const SET_SAFETY_STEP = "SET_SAFETY_STEP";
export const CLEAR_SAFETY_TRACK = "CLEAR_SAFETY_TRACK";

const initialState = {
    propertyList: [],
    selectedProperty: null,
    propertyTotal: 0,
    landplotList: [],
    selectedLandplot: null,
    landplotTotal: 0,
    buildingList: [],
    selectedBuilding: null,
    buildingTotal: 0,
    buildingAssetsList: [],
    buildingAssetsTotal: 0,
    selectedBuildingAssets: [],
    images: null,
    step: 0,
};

function SafetyTrackReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PROPERTY_LIST:
            return {
                ...state,
                propertyList: action.payload,
            };
        case SET_SELECTED_PROPERTY:
            return {
                ...state,
                selectedProperty: action.payload,
            };
        case SET_PROPERTY_TOTAL:
            return {
                ...state,
                propertyTotal: action.payload,
            };
        case SET_LANDPLOT_LIST:
            return {
                ...state,
                landplotList: action.payload,
            };
        case SET_SELECTED_LANDPLOT:
            return {
                ...state,
                selectedLandplot: action.payload,
            };
        case SET_LANDPLOT_TOTAL:
            return {
                ...state,
                landplotTotal: action.payload,
            };
        case SET_BUILDING_LIST:
            return {
                ...state,
                buildingList: action.payload,
            };
        case SET_SELECTED_BUILDING:
            return {
                ...state,
                selectedBuilding: action.payload,
            };
        case SET_BUILDING_TOTAL:
            return {
                ...state,
                buildingTotal: action.payload,
            };
        case SET_BUILDING_ASSET_LIST:
            return {
                ...state,
                buildingAssetsList: action.payload,
            };
        case SET_BUILDING_ASSETS_TOTAL:
            return {
                ...state,
                buildingAssetsTotal: action.payload,
            };
        case SET_SELECTED_BUILDING_ASSETS:
            return {
                ...state,
                selectedBuildingAssets: action.payload,
            };
        case SET_SAFETY_IMAGE:
            return {
                ...state,
                images: action.payload,
            };
        case SET_SAFETY_STEP:
            return {
                ...state,
                step: action.payload,
            };
        case CLEAR_SAFETY_TRACK:
            return initialState;
        default:
            return state;
    }
}

export default SafetyTrackReducer;
