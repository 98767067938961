import i18n from 'i18next';import k from "./../../../i18n/keys";import React, { Component } from "react";
import { Button, Form, FormGroup, FormInput, FormSelect, Modal, ModalBody, ModalHeader } from "shards-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import Select from "react-select";
import FormInputValidation from "../../ui_utils/FormInputValidationComponent";

class AreaCreatePolyModalComponent extends Component {
  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
    this.state = {
      polygon: { data: null },
      plan: {},
      reservable: false,
      areaType: '',
      totalArea: '',
      shares: '',
      name: '',
      amount: '',
      shareholder_type: i18n.t(k.BUSINESS) };

    this.getValues = this.getValues.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
  }


  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.polygon !== undefined) {
      this.setState({
        polygon: nextProps.polygon !== null ? nextProps.polygon : {} });

    }

    if (nextProps.area !== undefined) {
      this.setState({ "plan": { ...this.state.plan }, "polgon": { ...this.state.polygon }, ...nextProps.area });
    }

    if (nextProps.plan !== undefined) {
      this.setState({ plan: nextProps.plan });
      if (!this.state.options) {
        this.getValues(nextProps.plan.building);
      }
    }
  }

  handleChange(e) {
    if (e.target.id === 'shareholder_id') {
      this.setState({ "shareholder_id": e.target.value });
      if (e.target.value.length >= 6) {
        this.getCompanyData(e.target.value);
      }
    }
    if (e.target.id === 'timeslot' && e.target.value !== 'long-term') {
      this.setState({ "reservable": true });
    }
    if (e.target.id === 'timeslot' && e.target.value === 'long-term') {
      this.setState({ "reservable": false });
    }
    this.setState({
      [e.target.id]: e.target.value });

  }

  save() {
    let that = this;
    this.setState({ loading: true });
    let savebuilding = async function () {
      // params for area
      let params = {
        title: that.state.name,
        rules: {
          data: that.state.polygon.data,
          building: that.state.plan.building,
          floorplan: that.state.plan.id,
          total_area: that.state.totalArea,
          type: that.state.areaType,
          reservable: that.state.reservable,
          duration: that.state.timeslot,
          amount: that.state.amount } };


      // create area
      let response = await HonkioAPI().userShop.userFetch(
      "merchantareaset",
      params);


      // get area id
      let area_id = response.area['id'];
      let shareholder = that.state.shareholder;
      // if new shareholder - create it
      if (shareholder === "0") {

        // params for shareholder
        params = {
          firstname: that.state.shareholder_name,
          lastname: that.state.shareholder_lastname ? that.state.shareholder_lastname : "",
          phone: that.state.shareholder_phone,
          business_id: that.state.shareholder_id,
          email: that.state.shareholder_email,
          type: that.state.shareholder_type,
          building_id: that.state.plan.building,
          role_id: that.state.shareholder_role,
          consumer_create: true
        }

        // create shareholder
        response = await HonkioAPI().userShop.userFetch(
        "merchantuserinvite",
        params);

        // get shareholder id
        shareholder = response.consumer.consumer_id;
        // console.log(shareholder)
      }
      // params for shares
      params = {
        shareholder_id: shareholder,
        property_name: that.state.plan.building_name,
        building_id: that.state.plan.building,
        area_id: area_id,
        shares: that.state.shares };
      // create shares
      response = await HonkioAPI().userShop.userFetch(
      "merchantshareset",
      params);

      // all good? redirect
      that.setState({ loading: false });
      if (response.status === "accept") {
        that.props.onAreaSaved()
      }
    };
    savebuilding();
  }

  handleChangeAreaType(e) {
    if (e.value === "other") {
      this.setState({ "optional": true });
    } else {
      this.setState({ "areaType": e.value, "optional": false });
    }
  }

  handleChangeShareholderType(e) {
    this.setState({ "shareholder_type": e.value });
  }

  getValues(buildingId) {
    let options = []; // {value: i18n.t(k._3), label: i18n.t(k.CREATE_NEW_SHAREHOLDER)}
    let that = this;
    let merchantshareholderlist = async function () {
      let getmerchantroles = await HonkioAPI().userShop.userFetch('getmerchantroles', {
        'query_access': true,
        'query_sort': [{'access': -1}, {'name': 1}]
      })
      let roles = getmerchantroles.roles

      if (roles.length > 0) {
        let roles_ids = []
        roles.forEach(role => {
          if (['Shareholders'].includes(role.name)) {
            roles_ids.push(role.role_id)
            that.setState({"shareholder_role": role.role_id})
          }
        })
        let merchantlistuserroles = await HonkioAPI().userShop.userFetch('merchantlistuserroles',
          {query_roles: true, roles: roles_ids, query_count: 100})
        let shareholders = []
        merchantlistuserroles.consumers.forEach(c => {
          if (c.buildings.includes(buildingId)) {
            shareholders.push(c)
          }
        })
        shareholders.forEach(function (shareholder) {
          options.push({value: shareholder['user_id'], label: shareholder.settings.str_firstname + " " + shareholder.settings.str_lastname});
        });
      }
       }
      merchantshareholderlist();

      this.setState({options: options});
      return options;

  }

  handleValidation(id, valid) {
    this.setState({
      'validation': { [id]: valid } });

  }

  getCompanyData(id) {
    let that = this;
    let INFO_URL = `https://avoindata.prh.fi/bis/v1/${id}`;

    let requestInfo = async function () {
      let response = await fetch(INFO_URL);
      try {
        let json = await response.json();
        if (json.results.length > 0) {
          let data = json.results[0];
          let phone = '';
          data.contactDetails.forEach(function (contact) {
            if (contact.value.startsWith('+')) {
              phone = contact.value;
            }
          });

          let address = data.addresses[data.addresses.length - 1];

          that.setState({
            'shareholder_name': data.name,
            'shareholder_address': address.street,
            'shareholder_zip': address.postCode,
            'shareholder_city': address.city,
            'shareholder_phone': phone });

        }
      } catch (e) {
        console.error(e);
      }

    };
    requestInfo();
  }

  render() {
    let saveButton = <Button onClick={this.save}>{i18n.t(k.SAVE)}</Button>;
    if (this.state.loading) {
      saveButton = <CircularProgress size={45} />;
    }

    // console.log("STATE EDIT MODAL");
    // console.log(this.state);

    const options_type = [
    { value: i18n.t(k.BUSINESS), label: i18n.t(k.BUSINESS1) },
    { value: i18n.t(k.PERSON), label: i18n.t(k.PERSON1) }];


    const options = [
    { value: i18n.t(k.RESIDENTIAL_APARTMENT), label: i18n.t(k.RESIDENTIAL_APARTMENT1) },
    { value: i18n.t(k.OFFICE), label: i18n.t(k.OFFICE1) },
    { value: i18n.t(k.PREMISE), label: i18n.t(k.PREMISE1) },
    { value: i18n.t(k.STOCK), label: i18n.t(k.STOCK1) },
    { value: i18n.t(k.STAIRWELL), label: i18n.t(k.STAIRWELL1) },
    { value: i18n.t(k.SAUNA), label: i18n.t(k.SAUNA1) },
    { value: i18n.t(k.LAUNDRY_ROOM), label: i18n.t(k.LAUNDRY_ROOM1) },
    { value: i18n.t(k.DRYING_ROOM), label: i18n.t(k.DRYING_ROOM1) },
    { value: i18n.t(k.CRAFT_ROOM), label: i18n.t(k.CRAFT_ROOM1) },
    { value: i18n.t(k.CLUBROOM), label: i18n.t(k.CLUBROOM1) },
    { value: i18n.t(k.GARAGE), label: i18n.t(k.GARAGE1) },
    { value: i18n.t(k.BICYCLE_STORAGE), label: i18n.t(k.BICYCLE_STORAGE1) },
    { value: i18n.t(k.TECHNICAL_ROOM), label: i18n.t(k.TECHNICAL_ROOM1) },
    { value: i18n.t(k.COLD_CELLAR), label: i18n.t(k.COLD_CELLAR1) },
    { value: i18n.t(k.AIR_RAID_SHELTER), label: i18n.t(k.AIR_RAID_SHELTER1) },
    { value: i18n.t(k.POOL_AREA), label: i18n.t(k.THE_POOL_AREA) },
    { value: i18n.t(k.MEETING_ROOM), label: i18n.t(k.MEETING_ROOM1) },
    { value: i18n.t(k.PUBLIC_SPACE), label: i18n.t(k.PUBLIC_SPACE1) },
    { value: i18n.t(k.OTHER), label: i18n.t(k.OTHER1) }];


    let optional_input;
    let amount;
    let shareholder;
    let shareholder_type;
    let shareholder_options;

    if (this.state.optional) {
      optional_input = <FormGroup>
                <label htmlFor="areaType">{i18n.t(k.AREA_TYPE1)}</label>
                <FormInput
        id="areaType"
        value={this.state.areaType}
        onChange={e => this.handleChange(e)} />

            </FormGroup>;
    }

    if (this.state.reservable) {
      amount = <FormGroup>
                <label htmlFor="amount">{i18n.t(k.AMOUNT)}</label>
                <FormInput
        id="amount"
        value={this.state.amount}
        onChange={e => this.handleChange(e)} />

            </FormGroup>;
    }

    if (this.state.options) {
      shareholder_options = <FormGroup>
                <label htmlFor="type">{i18n.t(k.SHAREHOLDER)}</label>
                <Select id="type" options={this.state.options} onChange={e => {
          this.setState({
            'shareholder': e.value });
        }}
        menuPlacement="bottom"
        menuPosition='fixed' />
            </FormGroup>;
    }

    switch (this.state.shareholder_type) {
      case 'person':
        shareholder_type =
          // todo: refactor to component
          <div>
            <FormGroup>
              <label htmlFor="shareholder_id">{i18n.t(k.SHAREHOLDER_SSN)}</label>
              <FormInput
                id="shareholder_id"
                value={this.state.shareholder_id}
                onChange={e => this.handleChange(e)}/>

            </FormGroup>
            <FormGroup>
              <label htmlFor="shareholder_email">{i18n.t(k.SHAREHOLDER_EMAIL)}</label>
              <FormInput
                id="shareholder_email"
                value={this.state.shareholder_email}
                onChange={e => this.handleChange(e)}/>

            </FormGroup>
            <FormGroup>
              <label htmlFor="shareholder_name">{i18n.t(k.SHAREHOLDER_FIRSTNAME)}</label>
              <FormInput
                id="shareholder_name"
                value={this.state.shareholder_name}
                onChange={e => this.handleChange(e)}/>

            </FormGroup>
            <FormGroup>
              <label htmlFor="shareholder_lastname">{i18n.t(k.SHAREHOLDER_LASTNAME)}</label>
              <FormInput
                id="shareholder_lastname"
                value={this.state.shareholder_lastname}
                onChange={e => this.handleChange(e)}/>

            </FormGroup>
          </div>;
        break;
      case 'business':
        // todo: refactor to component
        shareholder_type =
          <div>
            <FormGroup>
              <label htmlFor="shareholder_id">{i18n.t(k.SHAREHOLDER_BUSINESS_ID)}</label>
              <FormInput
                id="shareholder_id"
                value={this.state.shareholder_id}
                onChange={e => this.handleChange(e)}/>

            </FormGroup>
            <FormGroup>
              <label htmlFor="shareholder_email">{i18n.t(k.SHAREHOLDER_CONTACT_EMAIL)}</label>
              <FormInput
                id="shareholder_email"
                value={this.state.shareholder_email}
                onChange={e => this.handleChange(e)}/>

            </FormGroup>
            <FormGroup>
              <label htmlFor="shareholder_name">{i18n.t(k.SHAREHOLDER_BUSINESS_NAME)}</label>
              <FormInput
                id="shareholder_name"
                value={this.state.shareholder_name}
                onChange={e => this.handleChange(e)}/>

            </FormGroup>
          </div>;
        break;
      default:
        shareholder_type = <div />;
        break;}


    if (this.state.shareholder === "0") {
      shareholder = <div>
                <FormGroup>
                    <label htmlFor="shareholder_type">{i18n.t(k.SHAREHOLDER_TYPE)}</label>
                    <Select id="shareholder_type" onChange={e => {
            this.handleChangeShareholderType(e);
          }}
          defaultValue={{ value: i18n.t(k.BUSINESS), label: i18n.t(k.BUSINESS1) }}
          options={options_type}>
                    </Select>
                </FormGroup>
                {shareholder_type}

                <FormGroup>
                    <label htmlFor="shareholder_phone">{i18n.t(k.SHAREHOLDER_PHONE)}</label>
                    <FormInput
          id="shareholder_phone"
          value={this.state.shareholder_phone}
          onChange={e => this.handleChange(e)} />
                </FormGroup>
            </div>;
    }
    return (
      <Modal
      open={this.props.visible}
      toggle={this.props.toggle}
      onRequestClose={this.props.onRequestClose}
      className="modal-dialog-scrollable modal-lg mx-auto">
                    <ModalHeader>{i18n.t(k.CREATE_NEW_AREA)}</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <label htmlFor="name">{i18n.t(k.AREA_NAME)}</label>
                                <FormInput
              id="name"
              value={this.state.name}
              onChange={e => this.handleChange(e)} />

                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="area_type">{i18n.t(k.AREA_TYPE)}</label>
                                <Select id="area_type" onChange={e => {
                this.handleChangeAreaType(e);
              }}
              options={options}
              menuPlacement="bottom"
              menuPosition='fixed'>
                                </Select>
                            </FormGroup>
                            {optional_input}
                            <FormGroup>
                                <label htmlFor="totalArea">{i18n.t(k.TOTAL_AREA_M)}</label>
                                <FormInputValidation id="totalArea" validationType="number"
              value={this.state.totalArea}
              placeholder={i18n.t(k.TOTAL_AREA_M)}
              onChange={e => this.handleChange(e)}
              handleValidation={this.handleValidation} />
                            </FormGroup>
                            {shareholder_options}
                            {shareholder}

                            <FormGroup>
                                <label htmlFor="shares">{i18n.t(k.NUMBER_OF_SHARES)}</label>
                                <FormInputValidation id="shares" validationType="number"
              value={this.state.shares}
              placeholder={i18n.t(k.NUMBER_OF_SHARES)}
              onChange={e => this.handleChange(e)}
              handleValidation={this.handleValidation} />
                            </FormGroup>

                            <FormGroup>
                                <label htmlFor="timeslot">{i18n.t(k.TIME_SLOT)}</label>
                                <FormSelect id="timeslot" onChange={e => this.handleChange(e)}>
                                    <option key='long-term' value="long-term">{i18n.t(k.LONG_TERM)}</option>
                                    <option key='15' value="900000">{i18n.t(k.MIN)}</option>
                                    <option key='30' value="1800000">{i18n.t(k.MIN1)}</option>
                                    <option key='60' value="3600000">{i18n.t(k.HOUR)}</option>
                                </FormSelect>
                            </FormGroup>
                            {amount}
                            {saveButton}
                        </Form>
                    </ModalBody>
                </Modal>);

  }}


export default AreaCreatePolyModalComponent;
