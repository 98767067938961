/*
UserAccessList reducer
Cases:
USERACCESSLIST_IS_LOADING
USERACCESSLIST_SUCCESS
USERACCESSLIST_FAILURE
USERACCESSLIST_CLEAR
 */

const initialState = {
  err: null,
  isLoading: false,
  isLoaded: false,
  user_access_list: {} };


function UserAccessListReducer(state = initialState, action) {
  switch (action.type) {
    case "USERACCESSLIST_IS_LOADING":
      return {
        ...state,
        isLoading: true,
        err: null,
        isLoaded: false,
        user_access_list: {} };

    case "USERACCESSLIST_SUCCESS":
      return {
        ...state,
        err: null,
        isLoading: false,
        isLoaded: true,
        user_access_list: action.response };

    case "USERACCESSLIST_FAILURE":
      return {
        ...state,
        err: action.err,
        isLoading: false,
        isLoaded: false,
        user_access_list: action.err };

    case "USERACCESSLIST_CLEAR":
      return {
        ...initialState };

    default:
      return state;}

}

export default UserAccessListReducer;