import i18n from "i18next";
import k from "./i18n/keys";
import HonkioAPI from "./middlewares/HonkioAPI";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { SHOP_ID, SHOP_PASSWORD } from "./config";

export function onMerchantSelect(e) {
    try {
        const merchantData = JSON.parse(
            localStorage.getItem("merchants")
        ).filter((item) => item.id === e.target.id);

        if (merchantData.length) {
            let merchant_id = e.target.id;

            localStorage.setItem("shop", SHOP_ID);
            localStorage.setItem("shop_password", SHOP_PASSWORD);
            localStorage.setItem("merchant_id", merchant_id);
            localStorage.setItem("merchant_name", merchantData?.[0]?.str_name);

            window.dispatchEvent(new Event("merchant_name"));
            // window.location = "/";
            return merchantData?.[0];
        }
        return null;
    } catch (error) {
        console.log(`onMerchantSelect: error`, error);
        return null;
    }
}

export function roundNumber(num, scale) {
    if (!("" + num).includes("e")) {
        return +(Math.round(num + "e+" + scale) + "e-" + scale);
    } else {
        let arr = ("" + num).split("e");
        let sig = "";
        if (+arr[1] + scale > 0) {
            sig = "+";
        }
        return +(
            Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) +
            "e-" +
            scale
        );
    }
}
export async function getShareHolderRole() {
    let srole;
    let params = { query_access: true };

    let response = await HonkioAPI().userShop.userFetch(
        "getmerchantroles",
        params
    );

    response.roles.forEach(function (role) {
        if (role["name"].toLowerCase() === "shareholders") {
            srole = role;
        }
    });
    return srole;
}

export function preventRoboto() {
    let head = document.getElementsByTagName("head")[0];
    let insertBefore = head.insertBefore;
    head.insertBefore = function (newElement, referenceElement) {
        if (
            newElement.href &&
            newElement.href.indexOf(
                "//fonts.googleapis.com/css?family=Roboto"
            ) > -1
        ) {
            // console.info("Prevented Roboto from loading!");
            return;
        }
        insertBefore.call(head, newElement, referenceElement);
    };
}
