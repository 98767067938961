import i18n from "i18next";
import k from "../../../i18n/keys";
import React, { useEffect, useState, useContext } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {
    getAssetList,
    getAssetListWithPages,
} from "../../../actions/api/assets/getAssetList";
import PageableDatatablesComponent from "../../ui_utils/PageableDatatablesComponent";
import getAssetTypeList from "../../../actions/api/assetTypes/getAssetTypeList";
import { APP_ID } from "../../../config";
import { Modal, Button } from "react-bootstrap";
import { updateAssetData } from "../../../actions/api/assets/setAssetData";
import { STORAGE } from "../../../actions/auth/oauthActions";
import { useDispatch, useSelector } from "react-redux";
import { getFileList } from "../../../actions/api/files/getFileList";
import QRCode from "qrcode.react";
import { Link } from "react-router-dom";

const Circular = () => (
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);
const PAGE_ITEMS_COUNT = 5;

// TODO: Load `selectedAssetData.parent`'s assets here and check if safety_video already attached - check meta_data
// If so - show that video link rendered as QR code
// TODO #2: Attach `url` into safety_video properties from asset

const ImagesListModal = (props) => {
    // const { isModalOpen } = useSelector((state) => state.safetyVideos);
    // const selectedAssetData = useSelector((state) => state.safetyVideos.data);
    const dispatch = useDispatch();

    const { isModalOpen, onClose, callback, currentImage } = props;

    const [imagesList, setImagesList] = useState([]);
    const [typesList, setTypesList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedImagesList, setSelectedImagesList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log(
            `ImagesListModal: isModalOpen = ${isModalOpen}, currentImage = `,
            currentImage
        );
    }, [props]);

    const onSelectImage = (e) => {
        e.preventDefault();
        callback(selectedImagesList[0]);
    };

    const onHide = () => {
        setImagesList([]);
        // Set image to null
        // callback(null);
        onClose();
    };

    const fetchAssetFiles = async () => {
        try {
            setLoading(true);
            const res = await getFileList(
                { type: "image", object: APP_ID },
                (err) => {
                    console.log("API error", err);
                }
            );
            if (res.files) setImagesList(res.files);
            setLoading(false);
        } catch (error) {
            setImagesList([]);
            console.log("Error getting videos list");
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isModalOpen) {
            fetchAssetFiles();
        }
    }, [isModalOpen]);

    const getImagesForPage = () => {
        const startIdx = currentPage * PAGE_ITEMS_COUNT;
        const endIdx = startIdx + PAGE_ITEMS_COUNT;
        const imagesForPage = imagesList.slice(startIdx, endIdx);

        return imagesForPage;
    };

    const onImageSelected = (data) => {
        console.log(
            `onImageSelected: data.selectedRows[0]`,
            data.selectedRows[0]
        );
        setSelectedImagesList([data.selectedRows[0]]);
    };

    const columns = [
        {
            name: i18n.t(k.NAME),
            selector: (row, index) => `${row.display_name}.${row.extension}`,
            sortable: true,
            autoWidth: true,
            grow: 2,
            style: `cursor: pointer;`,
        },
        {
            name: "Actions",
            cell: (row, index, column, id) => (
                <a
                    href={`/videoPlayer?url=${row.url}&name=${row.display_name}`}
                    target="_blank"
                >
                    View [TODO: fix]
                </a>
            ),
            sortable: true,
            autoWidth: true,
            grow: 0,
            style: `cursor: pointer;`,
        },
    ];

    const rowSelectCritera = (row) => {
        if (!selectedImagesList.length) return false;
        const selected = selectedImagesList.filter((item) => {
            const isSelected = item?._id === row?._id;

            return isSelected;
        });
        return selected.length > 0;
    };

    return (
        <div>
            <Modal show={isModalOpen || false} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Select{currentImage ? "ed" : ""} Safety track map image
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {currentImage ? (
                        <img
                            src={currentImage.url}
                            alt="current image"
                            width="100%"
                        />
                    ) : (
                        <PageableDatatablesComponent
                            noHeader={true}
                            selectableRows
                            selectableRowsComponent={Radio}
                            onSelectedRowsChange={(e) => onImageSelected(e)}
                            selectableRowSelected={rowSelectCritera}
                            autoWidth={true}
                            columns={columns}
                            background="#ffffff00"
                            items={getImagesForPage()}
                            loadItems={(page) => {
                                setCurrentPage(page);
                            }}
                            noDataComponent={
                                <span>{i18n.t(k.EMPTY_LIST)}</span>
                            }
                            paginationTotalRows={imagesList.length}
                            progressPending={loading}
                            progressComponent={<Circular />}
                            paginationServer={true}
                            paginationPerPage={PAGE_ITEMS_COUNT}
                            paginationRowsPerPageOptions={[PAGE_ITEMS_COUNT]}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => onHide()}>
                        Close
                    </Button>
                    {currentImage ? null : (
                        <Button variant="danger" onClick={onSelectImage}>
                            Confirm
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ImagesListModal;
