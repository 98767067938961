import i18n from "i18next";
import k from "./../../../i18n/keys";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Collapse,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    NavItem,
    NavLink,
} from "shards-react";
import { dashboardSuccess } from "../../../actions/DashboardActions";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import { onMerchantSelect } from "../../../../src/misc";
import { gettingConsumerInfo } from "../../../actions/api/consumer/consumerGetActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const UserActions = (props) => {
    const dispatch = useDispatch();
    const consumer_info = useSelector((state) => state.consumer_info);

    const [visible, setVisible] = useState(false);
    const [merchant, setMerchant] = useState({
        id: "",
        name: "select merchant",
    });

    const fullname = `${consumer_info?.consumer_info?.consumer_str_firstname} ${consumer_info?.consumer_info?.consumer_str_lastname}`;
    const consumer_id = consumer_info?.consumer_info?._id;

    const handleMerchantName = () => {
        const newMercName = localStorage.getItem("merchant_name");
        const newMercId = localStorage.getItem("merchant_id");

        setMerchant({
            id: newMercId,
            name: newMercName,
        });
    };

    useEffect(() => {
        getInfo();
        handleMerchantName();
    }, []);

    useEffect(() => {
        window.addEventListener("merchant_name", handleMerchantName);
        return () => {
            window.removeEventListener("merchant_name", handleMerchantName);
        };
    }, []);

    const toggleUserActions = () => {
        setVisible(!visible);
    };

    const getMerchants = () => {
        let merchants = JSON.parse(localStorage.getItem("merchants"));
        let merchantsName = [];
        for (let key in merchants) {
            merchantsName.push({
                id: merchants[key].id,
                name: merchants[key].str_name,
                active: localStorage.merchant_id === merchants[key].id,
            });

            // if (merchants[key].active) {
            //     setMerchant({
            //         id: merchants[key].id,
            //         name: merchants[key].str_name,
            //     });
            // }
        }
        return merchantsName;
    };

    const handleMerchantSelect = (e) => {
        try {
            dispatch(dashboardSuccess({ dashboard: null }));
            // Set merchant dat to localStorage
            onMerchantSelect(e);
            toggleUserActions();
        } catch (error) {
            console.log("handleMerchantSelct: error", error);
        }
    };

    const merchantList = () => {
        let merchantsArray = getMerchants();
        let merchants = [];

        if (merchantsArray !== undefined) {
            merchantsArray.forEach(function (merchant_item) {
                merchants.push(
                    <div
                        key={merchant_item.id}
                        id={merchant_item.id}
                        name={merchant_item.name}
                        onClick={handleMerchantSelect}
                        className="text-primary dropdown-item nav-link"
                        style={{ cursor: "pointer" }}
                    >
                        {" "}
                        {merchant_item.name}
                    </div>
                );
            });
        }
        return merchants;
    };

    const getInfo = () => {
        dispatch(gettingConsumerInfo(localStorage.getItem("token")));
    };

    return (
        <NavItem
            tag={Dropdown}
            caret
            className="d-flex"
            toggle={toggleUserActions}
        >
            <DropdownToggle
                caret
                tag={NavLink}
                className="pl-2 mr-3 text-nowrap"
                style={{
                    cursor: "pointer",
                    minWidth: "200px",
                    fontSize: "0.85rem",
                    color: "rgba(0,0,0,0.8)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <span
                    className="d-none d-md-inline-block my-auto"
                    style={{
                        minWidth: "60%",
                    }}
                >
                    {fullname} {merchant.name}
                </span>
            </DropdownToggle>
            <Collapse tag={DropdownMenu} right small open={visible}>
                {/* <Link to="/subscriptionPlan" className="dropdown-item ">
                    <i className="fas fa-file-invoice-dollar fa-fw"></i>
                    {i18n.t(k.SUBSCRIPTION_PLAN)}
                </Link> */}
                <Link
                    to="#"
                    style={{ pointerEvents: "none" }}
                    className="text-reagent-gray dropdown-item nav-link"
                >
                    {i18n.t(k.MERCHANT1)}
                </Link>

                {merchantList()}
                <hr />
                <Link
                    to="/logout"
                    className="text-danger dropdown-item nav-link"
                >
                    {i18n.t(k.LOGOUT)}
                </Link>
            </Collapse>
        </NavItem>
    );
};

export default UserActions;
