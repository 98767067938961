import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { Component } from "react";
import { connect } from "react-redux";

import {
    Button,
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    FormInput,
    FormSelect,
    Row,
} from "shards-react";
import { setInvite } from "../../actions/api/invite/setInviteActions";
import AddIcon from "@material-ui/icons/Add";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import AddPaymentModalComponent from "./AddPaymentModalComponent";
import BallotIcon from "@material-ui/icons/Ballot";
import { NotificationManager } from "react-notifications";
import HonkioAPI from "../../middlewares/HonkioAPI";
import { UPLOAD_URL } from "../../config";
import { DateTimePicker } from "react-widgets";
import CenteredPageLoader from "../ui_utils/CenteredPageLoader";
import axios, { post } from "axios";
import FilePreviewer from "react-file-previewer";
import { confirmAlert } from "react-confirm-alert";
import InvoiceProductEditComponent from "./InvoiceProductEditComponent";
import Select from "react-select";
import * as qs from "qs";
import InvoicePreviewModal from "./InvoicePreviewModal";

import moment from "moment";
import "moment/locale/fi";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

class InvoiceEditComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: { visible: false },
            previewModal: { visible: false },
            invoice: {
                type: "invoice",
                status: "pending",
                products: [],
                destination: {},
                biller: {},
            },

            building: null,
            loading: true,
        };

        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.onRequestClose = this.onRequestClose.bind(this);
        this.saveInvoice = this.saveInvoice.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.onAddProductClicked = this.onAddProductClicked.bind(this);
    }

    hideModal() {
        this.setState({ modal: { ...this.state.modal, visible: false } });
        this.onRequestClose();
    }

    showModal() {
        this.setState({ modal: { ...this.state.modal, visible: true } });
    }
    onRequestClose() {
        this.hideModal();
    }

    onPreviewRequestClose = () => {};

    hidePreviewModal = () => {
        this.setState({
            previewModal: { ...this.state.previewModal, visible: false },
        });
        this.onPreviewRequestClose();
    };
    showPreviewModal = () => {
        this.setState({
            previewModal: { ...this.state.previewModal, visible: true },
        });
    };

    handleChange(e, subfield) {
        if (subfield) {
            this.setState({
                invoice: {
                    ...this.state.invoice,
                    [subfield]: {
                        ...this.state.invoice[subfield],
                        [e.target.id]: e.target.value,
                    },
                    // [e.target.id]: e.target.value
                },
            });
        } else {
            this.setState({
                invoice: {
                    ...this.state.invoice,
                    [e.target.id]: e.target.value,
                },
            });
        }
    }

    toggle() {
        this.setState((prevState) => {
            return { open: !prevState.open };
        });
    }

    onAddProductClicked(product) {
        console.error("product");
        console.error("product");
        // alert(product.name)
        console.error(product);
        console.error(product);
        console.error(product);
        let newProducts = [...this.state.invoice.products, product];
        // newProducts.push(product)
        let newAmount = this.recalcProductAmount(newProducts);
        this.setState({
            invoice: {
                ...this.state.invoice,
                products: newProducts,
                amount: newAmount.amount,
                amount_vat: newAmount.amount_vat,
            },
            modal: { ...this.state.modal, visible: false },
        });

        // this.hideModal()
    }
    componentDidMount() {
        let ttype = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
        }).type;
        if (ttype) {
            this.setState({ invoice: { ...this.state.invoice, type: ttype } });
        }
        let that = this;
        let id = this.props.match.params.invoiceId;
        let loadBuildings = async function () {
            let response = await HonkioAPI().userShop.userFetch(
                "merchantbuildinglist",
                {}
            );
            let buildings = [
                {
                    id: i18n.t(k.MERCHANT),
                    name: localStorage.getItem("merchant_name"),
                },
                ...response.buildings,
            ];
            that.setState({
                buildings: buildings,
                building: buildings[0].id,
                loading: false,
            });
        };
        let loadInvoices = async function () {
            let response = await HonkioAPI().userShop.userFetch(
                "merchantbuildinglist",
                {}
            );
            let buildings = response.buildings;

            let result = await HonkioAPI().userShop.userFetch(
                "merchantpaymentinvoicelist",
                {
                    query_id: id,
                }
            );

            let invoice = result.payment_invoices[0];
            for (var i = 0; i < invoice.products.length; i++) {
                let product = invoice.products[i];
                if (product.hasOwnProperty("active")) {
                    delete product["active"];
                }
                invoice.products[i] = product;
            }
            let files = await HonkioAPI().userShop.userFetch(
                "merchantfileslist",
                {
                    object: id,
                }
            );

            if (files.files.length > 0) {
                let url = files.files[files.files.length - 1].url;
                const response = await fetch(url, {
                    method: "GET", // *GET, POST, PUT, DELETE, etc.
                    mode: "cors", // no-cors, *cors, same-origin
                    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: "include", // include, *same-origin, omit
                    headers: {
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                        Authorization: localStorage.getItem("token"),
                    },

                    redirect: "follow", // manual, *follow, error
                    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                });

                response.blob().then((blob) => {
                    // that.setState({'filePreview': window.URL.createObjectURL(blob)})
                    if (result.status === "accept") {
                        let blobUrl = window.URL.createObjectURL(blob);
                        console.error(blob);
                        console.error(blob);
                        console.error(blob);
                        that.setState({
                            invoice: invoice,
                            buildings: buildings,
                            building:
                                invoice.custom_fields.building ||
                                buildings[0].id,
                            loading: false,
                            filePreview: {
                                src: blobUrl,
                                type: blob.type,
                            },
                        });
                    }
                });
            } else if (result.status === "accept") {
                that.setState({
                    invoice: invoice,
                    buildings: buildings,
                    building: buildings[0].id,
                    loading: false,
                });
            }
        };
        if (id && id !== "new") {
            loadInvoices();
        } else {
            loadBuildings();
        }
    }

    showWarning(title, text, callback) {
        let that = this;
        const options = {
            title: title,
            message: "",
            childrenElement: () => <p>{text}</p>,
            buttons: [
                {
                    label: i18n.t(k.YES),
                    onClick: () => callback,
                },

                { label: i18n.t(k.NO), onClick: () => {} },
            ],

            closeOnEscape: true,
            closeOnClickOutside: true,
        };

        confirmAlert(options);
    }
    changeStatus(newStatus) {
        switch (newStatus) {
            case "sent":
                this.showPreviewModal();
                break;
            case "error":
                this.showWarning(
                    "Error",
                    "Would you like to cancel this invoice and create new?"
                );
                break;
            case "paid":
            case "over_paid":
                this.setState({
                    invoice: {
                        ...this.state.invoice,
                        status: newStatus,
                        paid_amount: this.state.invoice.amount,
                        paid_amount_vat: this.state.invoice.amount_vat,
                    },
                });
                break;
            default:
                this.setState({
                    invoice: { ...this.state.invoice, status: newStatus },
                });
                break;
        }
    }

    uploadFile(id) {
        let that = this;
        let uploadFile = async function () {
            let fileset = await HonkioAPI().userShop.userFetch("userfileset", {
                object: id,
                object_type: "payment_invoice",
                access: "private",
            });

            const formData = new FormData();
            formData.append("file", that.state.file, that.state.file.name);
            formData.append(
                "message",
                new Blob(
                    [
                        JSON.stringify({
                            id: fileset.id,
                            upload_token: fileset.upload_token,
                        }),
                    ],
                    {
                        type: "application/json",
                    }
                )
            );

            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };

            let url, thumb;
            await post(UPLOAD_URL, formData, config).then(
                (uploadFile) => {
                    console.error("uploadFile");
                    console.error(uploadFile);
                    url = uploadFile.data.url;
                    thumb = uploadFile.data.thumbnail_url;
                },
                (error) => {
                    NotificationManager.warning(
                        i18n.t(k.UPLOAD_FAILED_IMAGE),
                        `${error}`
                    );
                    that.setState({ loading: false });
                }
            );
        };
        uploadFile();
    }

    saveInvoice() {
        let that = this;
        console.error("INVOIE");
        console.error(that.state.invoice);

        let saveInvoice = async function () {
            that.setState({ loading: true });
            console.error({
                ...that.state.invoice,
                custom_fields: { building: that.state.building },
            });
            let response = await HonkioAPI().userShop.userFetch(
                "merchantpaymentinvoiceset",
                {
                    ...that.state.invoice,
                    custom_fields: { building: that.state.building },
                    due_date: that.state.invoice.due_date,
                    amount: parseFloat(that.state.invoice.amount).toFixed(2),
                    amount_vat: parseFloat(
                        that.state.invoice.amount_vat
                    ).toFixed(2),
                }
            );

            if (response.status === "accept") {
                let id = response.payment_invoice.id;
                if (that.state.file) {
                    that.uploadFile(id);
                }
                NotificationManager.success(i18n.t(k.INVOICE_SAVED));
                that.setState({ loading: false });
                // if (that.props.match.params.invoiceId && that.props.match.params.invoiceId === 'new') {
                that.props.history.push(
                    `/accounting?type=invoice&building=${that.state.building}`
                );
                // }
            }
        };
        saveInvoice();
    }

    recalcProductAmount = (newData) => {
        let data = newData ? newData : this.state.invoice.products;
        let amount = 0,
            vat = 0;
        for (var i = 0; i < data.length; i++) {
            let product = data[i];
            amount += parseFloat(product.amount) * parseInt(product.count);
            vat += parseFloat(product.amount_vat) * parseInt(product.count);
        }

        return { amount: amount, amount_vat: vat };
    };

    render() {
        console.error("this.state.invoice");
        console.error(this.state.invoice);
        if (this.state.loading) {
            return <CenteredPageLoader />;
        }
        let filePreview = (
            <input
                type="file"
                onChange={(e) => {
                    this.setState({ file: e.target.files[0] });
                }}
            />
        );
        if (this.state.filePreview) {
            console.error("this.state.filePreview");
            console.error(this.state.filePreview);
            if (this.state.filePreview.type.startsWith("image")) {
                filePreview = (
                    <div>
                        <input type="file" onChange={() => {}} />
                        <div>
                            <img
                                src={this.state.filePreview.src}
                                style={{ maxHeight: 700, maxWidth: 400 }}
                            />
                        </div>
                    </div>
                );
            } else {
                filePreview = (
                    <div>
                        <input type="file" onChange={() => {}} />
                        <div style={{ "max-height": "700px" }}>
                            <FilePreviewer
                                file={{
                                    url: this.state.filePreview.src,
                                    mimeType: i18n.t(k.APPLICATION_PDF),
                                }}
                            />
                        </div>
                    </div>
                );
            }
        } else if (this.state.file) {
            console.error(this.state.file);

            if (this.state.file.type.startsWith("image")) {
                filePreview = (
                    <div>
                        <input type="file" onChange={() => {}} />
                        <div>
                            <img
                                src={URL.createObjectURL(this.state.file)}
                                style={{ maxHeight: 700, maxWidth: 400 }}
                            />
                        </div>
                    </div>
                );
            } else {
                filePreview = (
                    <div>
                        <input type="file" onChange={() => {}} />
                        <div style={{ maxHeight: "700px" }}>
                            <FilePreviewer file={this.state.file} />
                        </div>
                    </div>
                );
            }
        }

        let statuses = [
            "pending",
            "sent",
            "paid",
            "part_paid",
            "over_paid",
            "error",
        ];
        let disallowedStatuses = [];
        if (this.state.invoice.type === "payment") {
            statuses.push("approved");

            switch (this.state.invoice.status) {
                case "paid":
                    disallowedStatuses = [
                        i18n.t(k.PENDING),
                        i18n.t(k.PART_PAID),
                    ];
                    break;
                case "part_paid":
                    disallowedStatuses = [
                        i18n.t(k.PENDING),
                        i18n.t(k.PART_PAID),
                    ];
                    break;
                case "pending":
                    disallowedStatuses = [
                        i18n.t(k.PENDING),
                        i18n.t(k.PAID),
                        i18n.t(k.PART_PAID),
                        i18n.t(k.OVER_PAID),
                        i18n.t(k.ERROR),
                    ];
                    break;
                case "approved":
                    disallowedStatuses = [
                        i18n.t(k.PENDING),
                        i18n.t(k.APPROVED),
                    ];
                    break;
                case "sent":
                    disallowedStatuses = [
                        i18n.t(k.PENDING),
                        i18n.t(k.APPROVED),
                        i18n.t(k.SENT),
                    ];
                    break;
            }
        }

        let statusItems = statuses.map((item, i) => (
            <DropdownItem
                disabled={disallowedStatuses.indexOf(item) !== -1}
                onClick={() => {
                    this.changeStatus(item);
                }}
            >
                {item}
            </DropdownItem>
        ));
        let paid;
        let paymentInfo;
        paymentInfo = (
            <div>
                <InvoiceProductEditComponent
                    products={this.state.invoice.products}
                    onAddProductClicked={this.onAddProductClicked}
                    onProductsChanged={(products) => {
                        this.setState({
                            invoice: { ...this.state.invoice, products },
                        });
                    }}
                />
            </div>
        );
        if (
            this.state.invoice.status === "paid" ||
            this.state.invoice.status === "part_paid" ||
            this.state.invoice.status === "over_paid"
        ) {
            paid = (
                <div>
                    <FormGroup>
                        <label htmlFor="paid_amount">
                            {i18n.t(k.PAID_AMOUNT)}
                        </label>
                        <FormInput
                            id="paid_amount"
                            placeholder={i18n.t(k.PAID_AMOUNT)}
                            type="text"
                            value={this.state.invoice.paid_amount}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor="paid_amount_vat">
                            {i18n.t(k.PAID_VAT_AMOUNT)}
                        </label>
                        <FormInput
                            id="paid_amount_vat"
                            placeholder={i18n.t(k.PAID_VAT_AMOUNT)}
                            type="text"
                            value={this.state.invoice.paid_amount_vat}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </FormGroup>
                </div>
            );
        }
        console.error(this.state.invoice);
        let options = [];
        if (this.state.buildings) {
            let buildings = this.state.buildings;
            buildings.forEach(function (buildng) {
                options.push({ value: buildng.id, label: buildng.name });
            });
        }

        return (
            <CardWrapperComponent
                header={true}
                footer={true}
                card_title={i18n.t(k.EDIT_INVOICE)}
            >
                {/*<Button onClick={this.showModal} className="m-3"><AddIcon/>Add payment</Button>*/}
                <div className="row">
                    <div className="col-12">
                        <Dropdown open={this.state.open} toggle={this.toggle}>
                            <DropdownToggle>
                                <BallotIcon fontSize="small" className="mr-1" />
                                {i18n.t(k.CHANGE_STATUS)}
                                {this.state.invoice.status}
                                {i18n.t(k._15)}
                            </DropdownToggle>
                            <DropdownMenu>{statusItems}</DropdownMenu>
                        </Dropdown>

                        <Form>
                            <FormGroup>
                                <label htmlFor="title">
                                    {i18n.t(k.BUILDING)}
                                </label>
                                <Select
                                    id="building"
                                    onChange={(e) =>
                                        this.setState({ building: e.value })
                                    }
                                    defaultValue={{
                                        value: this.state.buildings[0].id,
                                        label: this.state.buildings[0].name,
                                    }}
                                    options={options}
                                />
                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="title">{i18n.t(k.TITLE)}</label>
                                <FormInput
                                    id="title"
                                    placeholder={i18n.t(k.INVOICE_TITLE)}
                                    type="text"
                                    value={this.state.invoice.title}
                                    onChange={(e) => this.handleChange(e)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="invoice_id">
                                    {i18n.t(k.INVOICE_ID)}
                                </label>
                                <FormInput
                                    id="invoice_id"
                                    placeholder={i18n.t(k.INVOICE_NUMBER_OR)}
                                    type="text"
                                    value={this.state.invoice.invoice_id}
                                    disabled
                                />
                            </FormGroup>

                            <br />
                            <h4>{i18n.t(k.DATES)}</h4>
                            <Container>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <label htmlFor="due_date">
                                                {i18n.t(k.DUE_DATE)}
                                            </label>
                                            <DateTimePicker
                                                id="due_date"
                                                name="due_date"
                                                // value={moment(this.state.invoice.due_date)}
                                                defaultValue={
                                                    this.state.invoice.due_date
                                                        ? moment(
                                                              this.state.invoice
                                                                  .due_date
                                                          ).toDate()
                                                        : moment()
                                                              .add(
                                                                  i18n.t(
                                                                      k.DAYS
                                                                  ),
                                                                  14
                                                              )
                                                              .toDate()
                                                }
                                                // onSelect={this.handleStartDate}
                                                onChange={(d) => {
                                                    this.handleChange({
                                                        target: {
                                                            id: i18n.t(
                                                                k.DUE_DATE1
                                                            ),
                                                            value: moment(d)
                                                                .format(
                                                                    "YYYY-MM-DD hh:mm:ss"
                                                                )
                                                                .toLocaleString(),
                                                        },
                                                    });
                                                }}
                                                // onChange={value => this.setState({ })}
                                                // format={moment().format("YYYY-MM-DD h:mm:ss")}
                                            />
                                            {/*<FormInput id="due_date" placeholder="Due date" type="text" value={this.state.invoice.due_date}*/}
                                            {/*           onChange={e => this.handleChange(e)}/>*/}
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <label htmlFor="send_before">
                                                {i18n.t(
                                                    k.SEND_IN_NUMBER_OF_DAYS_BEFORE
                                                )}
                                            </label>
                                            <Select
                                                id="send_before"
                                                onChange={(e) =>
                                                    this.handleChange({
                                                        target: {
                                                            id: i18n.t(
                                                                k.SEND_DATE
                                                            ),
                                                            value: e.value,
                                                        },
                                                    })
                                                }
                                                defaultValue={{
                                                    value:
                                                        this.state.invoice
                                                            .send_before || 14,
                                                    label:
                                                        this.state.invoice
                                                            .send_before || 14,
                                                }}
                                                options={[
                                                    {
                                                        value: 14,
                                                        label: i18n.t(k._16),
                                                    },
                                                    {
                                                        value: 7,
                                                        label: i18n.t(k._17),
                                                    },
                                                    {
                                                        value: 30,
                                                        label: i18n.t(k._18),
                                                    },
                                                ]}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <label htmlFor="creation_date">
                                                {i18n.t(k.CREATED_DATE)}
                                            </label>
                                            <FormInput
                                                id="creation_date"
                                                placeholder={i18n.t(
                                                    k.CREATION_DATE
                                                )}
                                                disabled
                                                type="text"
                                                value={
                                                    this.state.invoice
                                                        .creation_date
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Container>

                            {paymentInfo}
                            {/*<FormGroup>*/}
                            {/*    <label htmlFor="payment_method">Payment Method</label>*/}
                            {/*    <FormInput id="payment_method" placeholder="Payment method" type="text" value="Not set" disabled/>*/}
                            {/*</FormGroup>*/}
                            <br />
                            <h4>{i18n.t(k.TOTAL_AMOUNT)}</h4>
                            <Container>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <label htmlFor="amount">
                                                {i18n.t(k.AMOUNT)}
                                            </label>
                                            <FormInput
                                                id="amount"
                                                placeholder={i18n.t(k.AMOUNT)}
                                                type="text"
                                                value={
                                                    this.state.invoice.amount
                                                }
                                                onChange={(e) =>
                                                    this.handleChange(e)
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <label htmlFor="amount">
                                                {i18n.t(k.VAT_AMOUNT)}
                                            </label>
                                            <FormInput
                                                id="amount_vat"
                                                placeholder={i18n.t(
                                                    k.VAT_AMOUNT
                                                )}
                                                type="text"
                                                value={
                                                    this.state.invoice
                                                        .amount_vat
                                                }
                                                onChange={(e) =>
                                                    this.handleChange(e)
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <label htmlFor="overtime_fee">
                                                {i18n.t(k.LATE_INTEREST_FEE)}
                                            </label>
                                            <FormInput
                                                id="overtime_fee"
                                                placeholder={i18n.t(
                                                    k.FEE_PERCENTAGE
                                                )}
                                                type="text"
                                                value={
                                                    this.state.invoice
                                                        .overtime_fee
                                                }
                                                onChange={(e) =>
                                                    this.handleChange(e)
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Container>
                            <br />
                            <h4>{i18n.t(k.BILLER_DETAILS)}</h4>
                            <Container>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <label htmlFor="email">
                                                {i18n.t(k.EMAIL)}
                                            </label>
                                            <FormInput
                                                id="email"
                                                placeholder={i18n.t(k.EMAIL)}
                                                type="text"
                                                value={
                                                    this.state.invoice.biller
                                                        .email
                                                }
                                                onChange={(e) =>
                                                    this.handleChange(
                                                        e,
                                                        "biller"
                                                    )
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <label htmlFor="name">
                                                {i18n.t(k.NAME)}
                                            </label>
                                            <FormInput
                                                id="name"
                                                placeholder={i18n.t(k.NAME)}
                                                type="text"
                                                value={
                                                    this.state.invoice.biller
                                                        .name
                                                }
                                                onChange={(e) =>
                                                    this.handleChange(
                                                        e,
                                                        "biller"
                                                    )
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <label htmlFor="phone">
                                                {i18n.t(k.PHONE)}
                                            </label>
                                            <FormInput
                                                id="phone"
                                                placeholder={i18n.t(k.PHONE)}
                                                type="text"
                                                value={
                                                    this.state.invoice.biller
                                                        .phone
                                                }
                                                onChange={(e) =>
                                                    this.handleChange(
                                                        e,
                                                        "biller"
                                                    )
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Container>
                            {paid}
                            <Button className="m-3" onClick={this.saveInvoice}>
                                {i18n.t(k.SAVE)}
                            </Button>
                            {/*<Button className="m-3" onClick={this.sendInvoice}>Send invoice on email</Button>*/}
                        </Form>
                    </div>
                    {/*<div className="col-4">*/}
                    {/*    {filePreview}*/}

                    {/*</div>*/}
                </div>
                <AddPaymentModalComponent
                    visible={this.state.modal.visible}
                    open={this.hideModal}
                    toggle={this.hideModal}
                    onRequestClose={this.onRequestClose}
                />

                <InvoicePreviewModal
                    visible={this.state.previewModal.visible}
                    open={this.hidePreviewModal}
                    toggle={this.hidePreviewModal}
                    invoice={this.state.invoice}
                    onRequestClose={this.onPreviewRequestClose}
                />
            </CardWrapperComponent>
        );
    }
}

function mapStateToProps(state) {
    return { ...state };
}

const mapDispatchToProps = (dispatch) => ({
    setInvite: (role_id, email, userData) =>
        dispatch(setInvite(role_id, email, userData)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceEditComponent);
