import React, { Component } from "react";
import "react-notifications/dist/react-notifications.css";
import "react-notifications/dist/react-notifications.js";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import InvoiceStatusItemComponent from "../InvoiceStatusItemComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import i18n from "i18next";
import k from "../../../i18n/keys";

import moment from "moment";
import "moment/locale/fi";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

class PurchasesDatatablesComponent extends Component {
    constructor(props) {
        super(props);
        // this.saveProduct = this.saveProduct.bind(this)
        // this.handleChange = this.handleChange.bind(this)
        this.rowSelected = this.rowSelected.bind(this);
        this.state = {
            type: this.props.type,
            buildingId: this.props.buildingId,
            currentDay: this.props.currentDay,
        };
    }
    componentDidMount() {
        // alert(this.state.buildingId)
        this.loadInvoices(
            this.state.currentDay,
            this.state.type,
            this.state.buildingId
        );
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState(nextProps);
        console.error(nextProps);
        this.loadInvoices(
            nextProps.currentDay ? nextProps.currentDay : this.state.currentDay,
            nextProps.type ? nextProps.type : this.state.type,
            nextProps.buildingId ? nextProps.buildingId : this.state.buildingId
        );
    }

    loadInvoices(date, type, buildingId) {
        let that = this;
        that.setState({ invoices: [], loading: true });
        let loadInvoices = async function () {
            let result = await HonkioAPI().userShop.userFetch(
                "merchantpaymentinvoicelist",
                {
                    query_type: "payment",
                    query_date: {
                        creation_date: {
                            lt: moment(date ? date : that.props.currentDay)
                                .endOf("month")
                                .format("YYYY-MM-DD hh:mm:ss"),
                            gte: moment(
                                date ? date : that.props.currentDay
                            ).format("YYYY-MM-DD hh:mm:ss"),
                        },
                    },
                    query_properties: {
                        building: { eq: buildingId },
                    },
                }
            );

            if (result.status === "accept") {
                // console.log(result);
                // alert(result.payment_invoices.length)
                that.setState({
                    invoices: result.payment_invoices,
                    loading: false,
                });
            }
        };
        loadInvoices();
    }

    getColumnsInvoices() {
        let state = this.state;
        let that = this;
        return [
            {
                name: "Title",
                selector: "title",
                sortable: true,
                style: `cursor: pointer;`,
                width: "10%",
            },

            {
                name: "Type",
                selector: "type",
                sortable: true,
                style: `cursor: pointer;`,
                width: "10%",
            },

            {
                name: "Due date",
                selector: "due_date",
                sortable: true,
                style: `cursor: pointer;`,
                width: "15%",
            },

            {
                name: "Paid date",
                selector: "paid_date",
                sortable: true,
                style: `cursor: pointer;`,
            },

            {
                name: "Amount",
                selector: "amount",
                sortable: true,
                style: `cursor: pointer;`,
                width: "10%",
            },

            {
                name: "Paid amount",
                selector: "paid_amount",
                sortable: true,
                style: `cursor: pointer;`,
                width: "10%",
            },

            {
                name: "Status",
                // selector: 'status',
                sortable: true,
                style: `cursor: pointer;`,
                width: "10%",
                cell: function (d) {
                    return <span>{d.status}</span>;
                },
            },

            {
                name: "Merchant Name",
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (d) {
                    return <span>{localStorage.getItem("merchant_name")}</span>;
                },
            },
        ];
    }

    rowSelected(o) {
        console.error("this.props");
        console.error(this.props);
        console.error(this);
        window.location.href = "invoice"
            ? `/invoiceEdit/${o.id}`
            : `/purchaseEdit/${o.id}`;
    }

    render() {
        console.error("this.state.invoices");
        console.error(this.state.invoices);
        return (
            <DataTable
                // title="Employees"
                className="border"
                noHeader={true}
                autoWidth={true}
                columns={this.getColumnsInvoices()}
                background="#ffffff00"
                data={this.state.invoices}
                noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                paginationComponentOptions={{
                    rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                    rangeSeparatorText: i18n.t(k.ROWS_OF),
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                }}
                progressPending={this.state.loading}
                progressComponent={<Circular />}
                onRowClicked={this.rowSelected}
            />
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PurchasesDatatablesComponent);
