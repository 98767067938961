import i18n from 'i18next'; import k from "./../../i18n/keys"; import React, { Component } from 'react';
import { DateTimePicker } from "react-widgets";
import { Badge } from "shards-react";
import moment from "moment";
import "moment/locale/fi";

class DatePickerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleRangeClick = this.handleRangeClick.bind(this);
  }

  handleStartDate = e => {
    this.setState({ 'start_date': moment(e).toDate() });
    this.props.handleChangeEvent(
      {
        target: {
          name: i18n.t(k.START_DATE1),
          value: moment(e).format('YYYY-MM-DD HH:mm:ss').toLocaleString()
        }
      });
  };
  handleEndDate = e => {
    this.setState({ 'end_date': moment(e).toDate() });
    this.props.handleChangeEvent(
      {
        target: {
          name: i18n.t(k.END_DATE1),
          value: moment(e).format('YYYY-MM-DD HH:mm:ss').toLocaleString()
        }
      });
  };

  handleRangeClick(start, end) {
    let that = this;
    return () => {
      let newState = {};
      if (start) {
        newState['start_date'] = start();
        this.handleStartDate(newState['start_date']);
      }
      if (end) {
        newState['end_date'] = end();
        this.handleEndDate(newState['end_date']);
      }
      that.setState(newState);
    };
  }

  render() {
    let endSelector = '';
    if (this.props.endSelector) {
      endSelector = <div className="col-md-6">
        <div className="form-group">
          <label htmlFor="end_date">{this.props.endDateLabel}</label>
          <DateTimePicker
            id="end_date"
            name="end_date"
            value={this.props.end_date}
            onChange={this.handleEndDate}
            format="LL, HH:mm"
            timeFormat="HH:mm" />

        </div>
      </div>;
    }
    let dates;
    if (this.props.dates) {
      dates = <div><h6 className="text-muted text-uppercase ">{i18n.t(k.DATES)}</h6>
        {this.props.ranges.map(t => <Badge theme="info" className="ml-3 pointer"
          onClick={this.handleRangeClick(t.start_date, t.end_date)}>{t.name}</Badge>)}
        <hr />
      </div>;
    }
    return (
      <div className="bg-light border p-3 mb-3">
        {dates}
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="start_date">{this.props.startDateLabel}</label>
              <DateTimePicker
                id="start_date"
                name="start_date"
                value={this.props.start_date}
                onChange={this.handleStartDate}
                timeFormat="HH:mm"
                format="LL, HH:mm" />
            </div>
          </div>
          {endSelector}
        </div>
      </div>);
  }
}

DatePickerComponent.defaultProps = {
  dates: true,
  startDateLabel: i18n.t(k.START_DATE),
  endDateLabel: i18n.t(k.END_DATE),
  start_date: moment().startOf('day').toDate(),
  end_date: moment().startOf('day').add(4, 'M').add(1, 'day').toDate()
};

export default DatePickerComponent;
