import i18n from 'i18next';
import k from "./../../../i18n/keys";
import React, { Component } from 'react';
import { Form } from "shards-react";
import AgreementRentAreaSelectAreaModalComponent from "./AgreementRentAreaModalComponent";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import SecureImage from "../../ui_utils/SecureImage";
import DataTable from "react-data-table-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ASSET_TYPE } from '../../../actions/api/assets/config';
import { NotificationManager } from "react-notifications";
// import honkio_logo from '../../../image/honkio_logo.png';
import { AGREEMENT_FIELDS } from '../../../../src/components/agreements/subcomponents/config';

const Circular = () =>
  <div style={{ padding: '24px' }}>
    <CircularProgress size={75} />
  </div>;

class AgreementRentAreaComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areas: {},
      modal: {
        visible: false,
        area: null
      },
      modalArea: {
        visible: false,
        floorplan: null
      }
    };
    this.hideModalArea = this.hideModalArea.bind(this);
    this.showModalArea = this.showModalArea.bind(this);
    this.toggleArea = this.toggleArea.bind(this);
    this.loadFloorPlans = this.loadFloorPlans.bind(this);
    this.thumbnailUrl = this.thumbnailUrl.bind(this);
  }

  loadFloorPlans(building) {
    let that = this;
    that.setState({ loading: true });
    let merchantfloorplanlist = async function () {
      let response = await HonkioAPI().userShop.userFetch('merchantassetlist', {
        query_parent: building,
        query_type: ASSET_TYPE.FLOORPLAN
      }, error => {
        that.setState({ loading: false });
        NotificationManager.success('Error loading floorplans')
      });
      if (response?.status === 'accept' && response?.assets) {
        let floorplans = response.assets;
        that.setState({
          floorplans: floorplans,
          loading: false
        });
      } else {
        that.setState({ loading: false });
      }
    };
    merchantfloorplanlist();
  }

  hideModalArea() {
    this.setState({ 'modalArea': { ...this.state.modalArea, visible: false } });
  }

  showModalArea(floorplan) {
    this.setState({
      modalArea: { ...this.state.modalArea, visible: true, floorplan: floorplan },
      floorplan: floorplan
    });
  }

  toggleArea() {
    this.setState({ 'modalArea': { ...this.state.modalArea, visible: !this.state.modalArea.visible } });
  }

  componentDidUpdate(prevProps) { //inna: change componentWillReceiveProps to this method in other cases
    if (this.props.agreement?.rules?.building &&
      this.props.agreement?.rules?.building?.value !== this.state.building) {
      this.setState({ building: this.props.agreement.rules.building.value });
      this.loadFloorPlans(this.props.agreement.rules.building.value);
    }
  }

  /* componentWillReceiveProps(nextProps, nextContext) {
     if (nextProps.agreement) {
       if (nextProps.agreement.rules) {
         if (nextProps.agreement.rules.building) {
           this.setState({ 'building': nextProps.agreement.rules.building.value });
           this.loadFloorPlans(nextProps.agreement.rules.building.value);
         }
       }
     }
     // if (nextProps.agreement.rules.building.value) {
     //     this.setState({'building': nextProps.agreement.rules.building.value})
     // }
   }*/

  isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  thumbnailUrl(string) {
    if (this.isValidHttpUrl(string?.custom_fields?.thumbUrl)) return string?.thumbUrl
    if (this.isValidHttpUrl(string?.url)) return string?.url
    // return honkio_logo
  }

  getColumns() {
    let that = this;
    return [
      {
        name: i18n.t(k.THUMBNAIL),
        selector: '',
        sortable: false,
        width: '30%',
        cell: function (d) {
          return <SecureImage src={that.thumbnailUrl(d.properties)}
            style={{
              width: 50,  //its same to '20%' of device width
              aspectRatio: 1, // <-- this
              resizeMode: 'contain', //optional
            }}
          />
        }
      },
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
        style: `cursor: pointer;`
      },
      {
        name: 'Building',
        selector: 'parent_name',
        sortable: true,
        style: `cursor: pointer;`
      }];
  }

  render() {
    let areaSelected = <div />;
    if (this.state.areaSelected) {
      areaSelected = <div>
        {`${this.state.areaSelected} ${i18n.t(k.AREA_SELECTED_CLICK_NEXT_TO_C)}`}
      </div>;
    }
    let that = this;
    let getHeader = () => {
      if (that.props.smallerHeader) {
        return (
          <h5>{i18n.t(k.SELECT_FLOORPLAN)}</h5>
        );
      }
      else {
        return (
          <h4>{i18n.t(k.SELECT_FLOORPLAN)}</h4>
        )
      }
    }
    return (
      <div>
        <Form>
          {getHeader()}
          <hr />
          <DataTable
            autoWidth={true}
            columns={this.getColumns()}
            background="#ffffff"
            data={this.state.floorplans}
            progressPending={this.state.loading}
            progressComponent={<Circular />}
            noHeader={true}
            striped
            highlightOnHover
            noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
            paginationComponentOptions={
              {
                rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                rangeSeparatorText: i18n.t(k.ROWS_OF),
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: i18n.t(k.ROWS_ALL)
              }
            }
            onRowClicked={floorplan => {
              // this.setState({'floorplan': floorplan});
              this.showModalArea(floorplan);
            }} />

          {areaSelected}
        </Form>
        <AgreementRentAreaSelectAreaModalComponent
          visible={this.state.modalArea.visible}
          open={this.hideModalArea}
          onRequestClose={this.props.onRequestClose}
          floorplan={this.state.floorplan}
          toggle={this.toggleArea}
          history={this.props.history}
          onAreaSelected={area => {
            this.props.handleChangeFromPicker(
              AGREEMENT_FIELDS.RENT_TOTAL_AREA,
              area.properties?.rules?.total_area,
              area.properties?.rules?.reservable,
              area.id,
              area.properties?.rules?.amount);
            this.setState({ areaSelected: area.name });
            that.hideModalArea();
          }} />
      </div>);
  }
}

export default AgreementRentAreaComponent;