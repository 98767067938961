import i18n from 'i18next';
import k from '../../../i18n/keys';
import React, { Component } from 'react';
import WidgetComponent from "./WidgetComponent";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import CircularProgress from "@material-ui/core/CircularProgress";
import "moment/locale/fi";

class AverageMaturityWidgetComponent extends Component {
  constructor(props) {
    super(props);
    this.refreshData = this.refreshData.bind(this);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.refreshData();
    let that = this;
    this.setContainerRef = element => {
      that.container = element;
    };
  }

  refreshData() {
    let that = this;
    that.setState({ loading: true });
    let merchantaveragematurity = async function () {
      let response = await HonkioAPI().userShop.userFetch('merchantaveragematurity', {});
      let avg_maturity = response.avg_maturity;
      that.setState({ 'avg_maturity': avg_maturity, 'loading': false });
    };
    merchantaveragematurity();
  }

  render() {
    let drawChart = (height, width) => {
      return <div className="d-flex justify-content-center w-100 h-100">
        <span className="justify-content-center align-self-center h5">{this.state.avg_maturity}</span>
      </div>;
    };

    return (
      <WidgetComponent
        position={this.props.position}
        height={this.props.height}
        width={this.props.width}
        icon="fas fa-money-bill-wave fa-2x"
        name={this.props.name}
        type={i18n.t(k.AVERAGE_MATURITY1)}
        summary1={drawChart()}
        onRemoveClick={this.props.onRemoveClick}
        onRename={this.props.onRename}
        chart={this.state.loading ? () => <CircularProgress size={35} /> : drawChart} />);
  }
}

export default AverageMaturityWidgetComponent;
