const initialState = {
  err: null,
  closedays: null };


function closedaysReducer(state = initialState, action) {
  switch (action.type) {
    case "CLOSEDAYS_SUCCESS":
      return {
        ...state,
        err: null,
        closedays: action.closedays };

    case "CLOSEDAYS_ERROR":
      return {
        ...state,
        err: action.err,
        closedays: null };

    default:
      return state;}

}

export default closedaysReducer;