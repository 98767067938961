import i18n from "i18next";
import k from "../../../i18n/keys";
import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Form } from "shards-react";
import { CircularProgress } from "@material-ui/core";
import Calendar from "rc-year-calendar";

import { NotificationManager } from "react-notifications";
import { getCloseDays } from "../../../actions/api/CloseDaysActions";
import HonkioAPI from "../../../middlewares/HonkioAPI";

import moment from "moment";
import "moment/locale/fi";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

class CloseDaysComponent extends Component {
    constructor(props) {
        super(props);
        this.onDayClick = this.onDayClick.bind(this);
        this.daysSource = this.daysSource.bind(this);
        // this.save = this.save.bind(this);
    }

    componentDidMount() {
        this.props.getCloseDays(this.props.areaId);
    }

    onDayClick(e) {
        // console.log(e)
        let newState = this.state.closedays;
        let day = moment(e.date).format("YYYY-MM-DD");
        if (newState.indexOf(day) >= 0) {
            newState.splice(newState.indexOf(day), 1);
        } else {
            newState.push(day);
        }

        this.setState({ closedays: newState });
        this.props.onCloseDaysChanged(newState);
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ closedays: nextProps.closedays });
    }
    daysSource() {
        let days = [];

        if (this.state && this.state.closedays) {
            for (var i = 0; i < this.state.closedays.length; i++) {
                let day = this.state.closedays[i];
                days.push({
                    color: "#FF000088",
                    startDate: moment(day).startOf("day").toDate(),
                    endDate: moment(day).endOf("day").toDate(),
                });
            }
        }
        return days;
    }

    // save() {
    //     let that = this;
    //     let calendarSetOpentimes = async function () {
    //                 let response = await HonkioAPI().userShop.userFetch('calendarsetclosedays',
    //                 {'closedays': that.state.closedays})
    //             if (response.status === 'accept') {
    //                 NotificationManager.success('Closedays were saved!');
    //             } else {
    //                 NotificationManager.warning(response.description || 'Unknown error', 'API error', 5000);
    //             }
    //             that.props.getCloseDays()
    //         }
    //         calendarSetOpentimes()
    // }

    render() {
        // if (this.state !== null && typeof this.props.closedays) {
        console.error(this.state);
        return (
            <div className="m-2">
                <h2 className="mt-2">{i18n.t(k.CLOSE_DAYS_EDIT)}</h2>
                <p className="mt-2">
                    {i18n.t(k.YOU_CAN_EDIT_YOUR_BUSINESS_CLO)}
                </p>
                <Form className="m-3">
                    <Calendar
                        dataSource={this.daysSource()}
                        onDayClick={this.onDayClick}
                        style="background"
                    />
                    {/*<Button id="save_btn" onClick={this.save}>Save</Button>*/}
                </Form>
            </div>
        );
        // }
        return <div>Loading</div>;
    }
}

function mapStateToProps(state) {
    // alert(state.closedays.closedays.length)
    return { closedays: state.closedays.closedays };
}

const mapDispatchToProps = (dispatch) => ({
    getCloseDays: (areaId) => dispatch(getCloseDays(areaId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CloseDaysComponent);
