import en from "./en.json"
import fi from "./fi.json"

const supportedLanguages = ['en', 'fi']

const localeConfig = {
    en: { translation: en },
    'fi-FI': { translation: fi },
    fi: { translation: fi },
}
export {supportedLanguages, localeConfig}