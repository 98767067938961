import React, { useEffect, useState, useContext } from "react";
import i18n from "i18next";
import k from "./../../i18n/keys";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import DeleteForever from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "shards-react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";
import BreadcrumbsComponent from "../breadcrumbs/BreadcrumbsComponent";
import { ASSET_TYPE } from "./../../actions/api/assets/config";
import useSearchString from "./../../utils/useSearchString";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { deleteAsset } from "./../../actions/api/assets/setAssetData";
import { Modal } from "react-bootstrap";
import { getAssetList } from "./../../actions/api/assets/getAssetList";
import getAsset from "./../../actions/api/assets/getAssets";
import CircularProgress from "@material-ui/core/CircularProgress";
import DescriptionIcon from "@material-ui/icons/Description";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ListIcon from "@material-ui/icons/List";
import { ReactComponent as SafetyVideoIcon } from "../../img/icons/video_icon.svg";
import { APP_ID } from "./../../config";
import {
    openSafetyVideoModal,
    closeSafetyVideoModal,
} from "../../actions/action_utils";
import getAssetTypeList from "./../../actions/api/assetTypes/getAssetTypeList";
import GroupableDatatable from "../directories/GroupableDatatable";

const Circular = () => (
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

const PlotList = () => {
    const dispatch = useDispatch();
    const { parent } = useSearchString();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [assetList, setAssetList] = useState([]);
    const [typesList, setTypesList] = useState([]);
    const [parentInfo, setParentInfo] = useState([]);
    const [assetToRemove, setAssetToRemove] = useState();
    const [showConfirmRemoveAsset, setShowConfirmRemoveAsset] = useState(false);
    const handleShowConfirmRemoveAsset = () => setShowConfirmRemoveAsset(true);
    const handleCloseConfirmRemoveAsset = () =>
        setShowConfirmRemoveAsset(false);

    let isMounted = true;

    useEffect(() => {
        fetchAssetList();
        fetchChildrenTypes();
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        if (parent) fetchParent();
    }, [parent]);

    const onDelete = () => {
        handleCloseConfirmRemoveAsset();

        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                NotificationManager.success("Successfully removed");
                fetchAssetList();
            } else {
                NotificationManager.error("Error");
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        deleteAsset(assetToRemove._id, errorHandler)
            .then((data) => {
                return resultHandler(data);
            })
            .catch(console.error);
    };

    const fetchAssetList = async () => {
        setLoading(true);
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    setAssetList(data.assets);
                    setLoading(false);
                } else {
                    NotificationManager.error("Error");
                    setAssetList();
                    setLoading(false);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setAssetList();
            setLoading(false);
        };
        if (parent) {
            getAssetList(
                {
                    // type: ASSET_TYPE.PLOT,
                    levels: 3,
                    parent: parent,
                },
                errorHandler
            )
                .then((data) => {
                    return resultHandler(data);
                })
                .catch(console.error);
        } else {
            setAssetList();
        }
    };

    const fetchChildrenTypes = async () => {
        setLoading(true);
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    setTypesList(data.asset_types);
                    setLoading(false);
                } else {
                    NotificationManager.error("Error");
                    setTypesList();
                    setLoading(false);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setTypesList();
            setLoading(false);
        };
        getAssetTypeList(
            {
                query_application: APP_ID,
                // query_parent: type,
            },
            errorHandler
        )
            .then((data) => {
                return resultHandler(data);
            })
            .catch((error) => {
                console.log("getAssetTypeList: modal: error", error);
            });
    };

    const fetchParent = async () => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    setParentInfo(data.asset);
                } else {
                    NotificationManager.error("Error");
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setParentInfo();
        };
        if (parent) {
            getAsset({ id: parent }, errorHandler)
                .then((data) => {
                    return resultHandler(data);
                })
                .catch(console.error);
        } else {
            setParentInfo();
        }
    };

    const showConfirmRemoveDialog = (asset) => {
        setAssetToRemove(asset);
        handleShowConfirmRemoveAsset();
    };

    const handleAttachSafetyVideo = (selected_asset) => {
        const asset = typesList.filter((itm) => itm.name === "safety_video")[0];

        const data = {
            // ...asset,
            type: ASSET_TYPE.BUILDING,
            description: asset.description,
            name: asset.name,
            properties: { ...asset.properties },
            parent: selected_asset._id,
        };

        dispatch(openSafetyVideoModal(data));
    };

    const columns = [
        {
            name: i18n.t(k.NAME),
            selector: "name",
            grow: 2,
            sortable: true,
            style: `cursor: pointer;`,
        },
        {
            name: i18n.t(k.ADDRESS),
            selector: "properties.address",
            sortable: true,
            grow: 2,
            style: `cursor: pointer;`,
        },
        {
            name: i18n.t(k.PROPERTY_MANAGER2),
            grow: 2,
            selector: "properties.asset_manager",
            sortable: true,
            style: `cursor: pointer;`,
        },
        {
            name: i18n.t(k.ACTION),
            selector: "action",
            grow: 1,
            cell: function (asset) {
                return (
                    <span>
                        <Link
                            title="Assets list"
                            id="sensorTip2"
                            to={`/sensor/list?parent=${asset._id}&property_type=${asset.type}`}
                            className="pointer"
                        >
                            <ListIcon />
                        </Link>
                        <Link
                            title="Safety video"
                            id="saftyVideoTip"
                            onClick={(e) => {
                                e.preventDefault();
                                handleAttachSafetyVideo(asset);
                            }}
                            className="pointer"
                            to="#"
                        >
                            <SafetyVideoIcon
                                style={{ width: "1rem", height: "1rem" }}
                            />
                        </Link>
                        <Link
                            title={i18n.t(k.EDIT)}
                            id={"editTip" + asset._id}
                            to={`/assets/edit?asset=${asset._id}`}
                            className="pointer"
                        >
                            <EditIcon />
                        </Link>
                        <Link
                            title={i18n.t(k.DOCUMENTS)}
                            id="documentsTip"
                            to={`/asset/documents?id=${asset._id}`}
                            className="pointer"
                        >
                            <DescriptionIcon />
                        </Link>
                        <Link
                            title={i18n.t(k.DELETE)}
                            id="deleteTip"
                            to="#"
                            className="pointer"
                            onClick={() => {
                                showConfirmRemoveDialog(asset);
                            }}
                        >
                            <DeleteForever />
                        </Link>
                    </span>
                );
            },
        },
    ];

    const actionsColumn = {
        name: i18n.t(k.ACTION),
        selector: "action",
        grow: 1,
        cell: function (asset) {
            return (
                <span>
                    <Link
                        title="Assets list"
                        id="sensorTip2"
                        to={`/sensor/list?parent=${asset._id}&property_type=${asset.type}`}
                        className="pointer"
                    >
                        <ListIcon />
                    </Link>
                    <Link
                        title="Safety video"
                        id="saftyVideoTip"
                        onClick={(e) => {
                            e.preventDefault();
                            handleAttachSafetyVideo(asset);
                        }}
                        className="pointer"
                        to="#"
                    >
                        <SafetyVideoIcon
                            style={{ width: "1rem", height: "1rem" }}
                        />
                    </Link>
                    <Link
                        title={i18n.t(k.EDIT)}
                        id={"editTip" + asset._id}
                        to={`/assets/edit?asset=${asset._id}`}
                        className="pointer"
                    >
                        <EditIcon />
                    </Link>
                    <Link
                        title={i18n.t(k.DOCUMENTS)}
                        id="documentsTip"
                        to={`/asset/documents?id=${asset._id}`}
                        className="pointer"
                    >
                        <DescriptionIcon />
                    </Link>
                    <Link
                        title={i18n.t(k.DELETE)}
                        id="deleteTip"
                        to="#"
                        className="pointer"
                        onClick={() => {
                            showConfirmRemoveDialog(asset);
                        }}
                    >
                        <DeleteForever />
                    </Link>
                </span>
            );
        },
    };

    const getParents = () => {
        let parentsList = [];
        if (parent) {
            parentsList = [
                {
                    name: i18n.t(k.PROPERTIES),
                    linkTo: "/landplots",
                },
                {
                    name: parentInfo.name,
                    linkTo: null,
                },
            ];
        }
        return parentsList;
    };

    let getBreadcrumbs = () => {
        if (parent) {
            return <BreadcrumbsComponent parents={getParents()} />;
        } else {
            return;
        }
    };

    return (
        <div>
            <div className="container-fluid">
                <PageHeaderComponent title={i18n.t(k.LANDPLOTS)} />
            </div>
            <CardWrapperComponent
                header={true}
                footer={true}
                card_title={getBreadcrumbs()}
            >
                <Button
                    theme="info"
                    onClick={() => {
                        if (parent) {
                            history.push(
                                `/assets/create?type=${ASSET_TYPE.PLOT}&parent=${parent}`
                            );
                        } else {
                            NotificationManager.error(
                                "Landplot is not defined"
                            );
                        }
                    }}
                    className="mr-2"
                >
                    <AddIcon />
                    {i18n.t(k.CREATE_LANDPLOT)}
                </Button>
                <Button
                    theme="info"
                    onClick={() => {
                        NotificationManager.error(
                            "TODO: Add video to parent from query string"
                        );
                    }}
                    className="mr-2"
                >
                    <AddIcon />
                    Add video
                </Button>
                {/* <DataTable
                    autoWidth={true}
                    columns={columns}
                    background="#ffffff00"
                    data={assetList}
                    noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                    paginationComponentOptions={{
                        rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                        rangeSeparatorText: i18n.t(k.ROWS_OF),
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                    }}
                    progressComponent={<Circular />}
                    progressPending={loading}
                    onRowClicked={(e) =>
                        history.push(`/buildings?parent=${e._id}`)
                    }
                /> */}
                <GroupableDatatable
                    parent={parent}
                    onRowClicked={
                        (e) => history.push(`/buildings?parent=${e._id}`)
                        // (e) => history.push(`/directory/${e._id}`)
                    }
                />
                <Modal
                    show={showConfirmRemoveAsset}
                    onHide={handleCloseConfirmRemoveAsset}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Warning</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Do you want to remove plot?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleCloseConfirmRemoveAsset}
                        >
                            Close
                        </Button>
                        <Button variant="danger" onClick={() => onDelete()}>
                            Remove
                        </Button>
                    </Modal.Footer>
                </Modal>
            </CardWrapperComponent>
        </div>
    );
};

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PlotList);
