import i18n from 'i18next';
import k from "./../../../i18n/keys";
import React, {Component} from 'react';
import {connect} from "react-redux";
import {Button, Col, Container, Form, FormGroup, Row, Slider} from "shards-react";
import {getOpenTimes} from "../../../actions/api/OpenTimesActions";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import {NotificationManager} from "react-notifications";
import OpenTimesComponent from "./OpenTimesComponent";
import CloseDaysComponent from "./CloseDaysComponent";
import {getCloseDays} from "../../../actions/api/CloseDaysActions";

class AreaScheduleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            closedays: [],
            opentimes: {}
        };
    }

  onCloseDaysChanged = (closedays) => {
    this.setState({'closedays': closedays})
  };

  onOpenTimesChanged = (opentimes) => {
    this.setState({'opentimes': opentimes})
  };

  save = () => {
    let that = this;

    let calendarSetOpentimes = async function () {
      let calendarsetopentimes = await HonkioAPI().userShop.userFetch('calendarsetopentimes', {
        'opentimes': that.state.opentimes,
        area_id: that.props.match.params.areaId });
      let calendarsetclosedays = await HonkioAPI().userShop.userFetch('calendarsetclosedays',
    {'closedays': that.state.closedays, area_id: that.props.match.params.areaId})
      let areas = await HonkioAPI().userShop.userFetch('merchantarealist', {
          'id': that.props.match.params.areaId
      });
      let merchantschedulegenerate = {'status': 'error'}
      if (areas.areas.length > 0) {
          let area = areas.areas[0];
          let merchantschedulegenerate = await HonkioAPI().userShop.userFetch('merchantschedulegenerate', {
            'opentimes': that.state.opentimes,
            'closedays': that.state.closedays,
            'products': [area.product],
            'object_type': 'area',
            'object': that.props.match.params.areaId,
            'opentitle': `${area.name} - ${i18n.t(k.OPEN_L)}`,
            'closedtitle': `${area.name} - ${i18n.t(k.CLOSED_L)}`,
          });
      }
      if (calendarsetopentimes.status === 'accept') {
        NotificationManager.success(i18n.t(k.SCHEDULE_SAVED));
      } else {
        NotificationManager.warning(calendarsetopentimes.description || i18n.t(k.UNKNOWN_ERROR), i18n.t(k.ERROR_API), 5000);
      }
    };
    calendarSetOpentimes();
  }
  render() {
        return <div className="jumbotron bg-light m-2 w-100">
            <Button id="save_btn" className="mb-3 offset-1" onClick={this.save}>{i18n.t(k.SAVE)}</Button>
            <Container>
                <Row>
                    <Col>
                        <OpenTimesComponent
                            areaId={this.props.match.params.areaId}
                            onOpenTimesChanged={this.onOpenTimesChanged}
                        />
                    </Col>
                    <Col>
                        <CloseDaysComponent
                                areaId={this.props.match.params.areaId}
                                onCloseDaysChanged={this.onCloseDaysChanged}
                            />
                    </Col>
                </Row>
            </Container>
        </div>
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = dispatch => ({
    getOpenTimes: (areaId) => dispatch(getOpenTimes(areaId)),
    getCloseDays: (areaId) => dispatch(getCloseDays(areaId))
});

export default connect(mapStateToProps, mapDispatchToProps)(AreaScheduleComponent);
