import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { Component } from "react";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataTable from "react-data-table-component";
import DeleteForever from "@material-ui/icons/DeleteForever";
import { Button } from "shards-react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import HonkioAPI from "../../middlewares/HonkioAPI";
import "react-notifications/dist/react-notifications.css";
import "react-notifications/dist/react-notifications.js";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import InviteUserModal from "../invite/InviteUserComponent";
import { Alert } from "shards-react";
import { Row, Col } from "shards-react";
import DescriptionIcon from "@material-ui/icons/Description";
import { FormCheckbox } from "shards-react";
import CenteredLoader from "../ui_utils/CenteredLoader";
import { getShareHolderRole } from "../../misc";
import { Link } from "react-router-dom";

const Circular = () => (
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

class ShareholderListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            shareholders: [],
            organisation: [],
            members: [],
            modal: {
                visible: false,
                shareholder: {},
            },
            modalAppoint: {
                visible: false,
            },
            modalInvite: {
                visible: false,
                email: "",
                phone: "",
                firstname: "",
                lastname: "",
            },
        };
        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.toggle = this.toggle.bind(this);
        this.hideModalAppoint = this.hideModalAppoint.bind(this);
        this.showModalAppoint = this.showModalAppoint.bind(this);
        this.toggleAppoint = this.toggleAppoint.bind(this);
        this.hideModalInvite = this.hideModalInvite.bind(this);
        this.showModalInvite = this.showModalInvite.bind(this);
        this.toggleInvite = this.toggleInvite.bind(this);
        this.loadOrganisation = this.loadOrganisation.bind(this);
        this.loadBuilding = this.loadBuilding.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.getShareholderRoleId = this.getShareholderRoleId.bind(this);
    }

    hideModal() {
        this.setState({ modal: { ...this.state.modal, visible: false } });
    }

    showModal(shareholder) {
        this.setState({
            modal: {
                ...this.state.modal,
                visible: true,
                shareholder: shareholder,
            },
        });
    }

    toggle() {
        this.setState({
            modal: { ...this.state.modal, visible: !this.state.modal.visible },
        });
    }

    hideModalAppoint() {
        this.setState({
            modalAppoint: { ...this.state.modalAppoint, visible: false },
        });
    }

    showModalAppoint(shareholders) {
        this.setState({
            modalAppoint: {
                ...this.state.modalAppoint,
                visible: true,
                shareholders: shareholders,
            },
        });
    }

    toggleAppoint() {
        this.setState({
            modalAppoint: {
                ...this.state.modalAppoint,
                visible: !this.state.modalAppoint.visible,
            },
        });
    }

    hideModalInvite() {
        this.setState({
            modalInvite: { ...this.state.modalInvite, visible: false },
        });
    }

    showModalInvite(d) {
        this.setState({
            modalInvite: {
                ...this.state.modalInvite,
                visible: true,
                email: d.email,
                phone: d.phone,
                firstname: d.name.split(" ")[0] ? d.name.split(" ")[0] : "",
                lastname: d.name.split(" ")[1] ? d.name.split(" ")[1] : "",
                shareholder_id: d.id,
            },
        });
    }

    toggleInvite() {
        this.setState({
            modalInvite: {
                ...this.state.modalInvite,
                visible: !this.state.modalInvite.visible,
            },
        });
    }

    componentDidMount() {
        let buildingId = this.props.match
            ? this.props.match.params.buildingId
            : this.props.buildingId;
        this.loadBuilding(buildingId);
        this.loadOrganisation(buildingId);
        this.getShareholderRoleId();
    }

    componentWillReceiveProps(nextProps) {
        // if (!this.state.hasOwnProperty('role') || this.state.role === null) {
        if (
            nextProps.roles !== undefined &&
            nextProps.roles[0] !== undefined &&
            !this.state.role
        ) {
            this.setState({
                ...nextProps,
                role: nextProps.roles[0]._id,
                role_name: nextProps.roles[0].display_name,
            });
            this.props.getMerchantConsumerList(nextProps.roles[0]._id);
            // }
        } else {
            this.setState(nextProps);
        }
    }

    confirmDelete(shareholder) {
        let that = this;
        const options = {
            title: i18n.t(k.DESTRUCTIVE_ACTION),
            message: "",
            childrenElement: () => (
                <p>
                    {i18n.t(k.ARE_YOU_SURE_WANT_TO_DELETE1)}{" "}
                    <b>{shareholder.name}</b>
                    {i18n.t(k._4)}
                </p>
            ),
            buttons: [
                {
                    label: i18n.t(k.YES),
                    onClick: () => {
                        const del = async () => {
                            that.setState({ loading: true });
                            let response = await HonkioAPI().userShop.userFetch(
                                "merchantshareholderset",
                                {
                                    id: shareholder.id,
                                    delete: true,
                                }
                            );
                            if (response.status === "accept") {
                                NotificationManager.success(
                                    i18n.t(k.LOADING),
                                    i18n.t(k.SHAREHOLDER_HAS_BEEN_REMOVED)
                                );
                            } else {
                                NotificationManager.success(
                                    `${response.description}`,
                                    i18n.t(k.DELETE_FAILED)
                                );
                            }
                            that.loadOrganisation(
                                this.props.match.params.buildingId
                                    ? this.props.match.params.buildingId
                                    : null
                            );
                        };
                        del();
                    },
                },
                {
                    label: i18n.t(k.NO),
                    onClick: () => {},
                },
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        };
        confirmAlert(options);
    }

    loadBuilding(buildingId) {
        let that = this;
        let merchantgetbuildings = async function () {
            let responseBuildings = await HonkioAPI().userShop.userFetch(
                "merchantassetlist",
                {
                    query_id: buildingId,
                },
                (error) => {
                    that.setState({
                        loading: false,
                        building: {},
                        loading: false,
                        properties: {},
                        total_shares: {},
                    });
                }
            );
            if (
                responseBuildings.status === "accept" &&
                responseBuildings.assets.length > 0
            ) {
                let building = responseBuildings.assets[0];
                that.setState({
                    building: building,
                    loading: false,
                    properties: building.properties,
                    total_shares: building.properties.shares,
                });
            } else {
                that.setState({
                    loading: false,
                    building: {},
                    loading: false,
                    properties: {},
                    total_shares: {},
                });
            }
        };
        merchantgetbuildings();
    }

    loadOrganisation(buildingId) {
        let that = this;
        let query = {};
        if (buildingId) {
            query["building_id"] = buildingId;
        }
        this.setState({ loading: true });
        let merchantgetorganisation = async function () {
            let list_roles = [];
            let assign_shares = 0;
            let consumers = [];
            let roles_ids = [];
            let getmerchantroles = await HonkioAPI().userShop.userFetch(
                "getmerchantroles",
                {
                    query_access: true,
                    query_sort: [{ user_id: 1 }],
                }
            );
            if (getmerchantroles.roles.length > 0) {
                getmerchantroles.roles.forEach((role) => {
                    if (!["tenant"].includes(role.name)) {
                        roles_ids.push(role.role_id);
                        list_roles.push({ id: role._id, name: role.name });
                    }
                });
                let merchantlistuserroles =
                    await HonkioAPI().userShop.userFetch(
                        "merchantlistuserroles",
                        {
                            query_roles: true,
                            roles: roles_ids,
                            query_count: 100,
                        }
                    );
                let shares = await HonkioAPI().userShop.userFetch(
                    "merchantsharelist",
                    {
                        building_id: that.props.match
                            ? that.props.match.params.buildingId
                            : that.props.buildingId,
                    }
                );
                merchantlistuserroles.consumers.forEach((c) => {
                    if (
                        c.buildings.includes(
                            that.props.match
                                ? that.props.match.params.buildingId
                                : that.props.buildingId
                        )
                    ) {
                        c["shares"] = 0;
                        c["roles"].push(c["role_id"]);
                        consumers.push(c);
                    }
                });
                consumers.forEach((c) => {
                    shares.shares.forEach((s) => {
                        if (s.shareholder_id === c.user_id) {
                            c.shares += 1;
                        }
                    });
                    assign_shares += c.shares;
                });
            }
            for (let i = 0; i < consumers.length; i++) {
                for (let j = i + 1; j < consumers.length; ) {
                    if (consumers[i].user_id === consumers[j].user_id) {
                        consumers[j]["roles"].push(consumers[i]["role_id"]);
                        consumers.splice(i, 1);
                    } else {
                        j++;
                    }
                }
            }
            let organisation = consumers.sort((a, b) =>
                a.user_id > b.user_id ? 1 : b.user_id > a.user_id ? -1 : 0
            );
            that.setState({
                organisation: organisation,
                loading: false,
                list_roles: list_roles,
                shares: assign_shares,
            });
        };
        merchantgetorganisation();
    }

    appointChairman(user_id, buildingId, role_id) {
        let that = this;
        let save = async function () {
            let params = {
                user_id: user_id,
                building_id: buildingId,
                role_id: role_id,
            };
            let response = await HonkioAPI().userShop.userFetch(
                "merchantappointchairman",
                params
            );
            NotificationManager.success(i18n.t(k.CHAIRMAN_APPOINTED));
            that.loadOrganisation(
                that.props.match.params.buildingId
                    ? that.props.match.params.buildingId
                    : null
            );
        };
        save();
    }

    appointBoardMember(user_id, buildingId, role_id, is_board_member) {
        let that = this;
        let save = async function () {
            let params = {
                user_id: user_id,
                building_id: buildingId,
                is_board_member: is_board_member,
                role_id: role_id,
            };
            let response = await HonkioAPI().userShop.userFetch(
                "merchantappointboardmember",
                params
            );
            NotificationManager.success(i18n.t(k.DONE));
            that.loadOrganisation(
                that.props.match.params.buildingId
                    ? that.props.match.params.buildingId
                    : null
            );
        };
        save();
    }

    getColumns() {
        let that = this;
        return [
            {
                name: i18n.t(k.NAME),
                selector: "settings.str_firstname",
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: "Last name",
                selector: "settings.str_lastname",
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.EMAIL),
                selector: "email",
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.PHONE),
                selector: "settings.str_telephone",
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.HONKIO_USER),
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (e) {
                    return e.active ? "Yes" : "Not yet";
                },
            },
            {
                name: i18n.t(k.CHAIRMAN),
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (e) {
                    let role_id;
                    let chairman = false;
                    that.state.list_roles.forEach((role) => {
                        if (role.name === "chairman") {
                            role_id = role.id;
                            if (e.roles.includes(role_id)) {
                                chairman = true;
                            }
                        }
                    });
                    return (
                        <FormCheckbox
                            checked={chairman}
                            onChange={() =>
                                that.appointChairman(
                                    e.user_id,
                                    that.props.match.params.buildingId,
                                    role_id
                                )
                            }
                        />
                    );
                },
            },
            {
                name: i18n.t(k.BOARD_MEMBER),
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (e) {
                    let board_member = false;
                    let role_id;
                    that.state.list_roles.forEach((role) => {
                        if (role.name === "board_member") {
                            role_id = role.id;
                            if (e.roles.includes(role_id)) {
                                board_member = true;
                            }
                        }
                    });
                    return (
                        <FormCheckbox
                            checked={board_member}
                            onChange={() =>
                                that.appointBoardMember(
                                    e.user_id,
                                    that.props.match.params.buildingId,
                                    role_id,
                                    board_member
                                )
                            }
                        />
                    );
                },
            },
            {
                name: i18n.t(k.NUMBER_OF_SHARES),
                sortable: true,
                style: `cursor: pointer;`,
                selector: "shares",
            },
            {
                name: i18n.t(k.ACTION),
                selector: "action",
                cell: function (d) {
                    return (
                        <span>
                            <Link
                                to={`/shareholderInvoices/${d.id}`}
                                className="pointer"
                                target="_blank"
                                data-toggle="tooltip"
                                title={i18n.t(k.VIEW_INVOICES)}
                            >
                                <DescriptionIcon />
                            </Link>
                            <Link
                                to="#"
                                className="pointer"
                                data-toggle="tooltip"
                                title={i18n.t(k.DELETE_ACTION)}
                                onClick={() => {
                                    that.confirmDelete(d);
                                }}
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ];
    }

    onRequestClose() {
        try {
            this.props.getMerchantConsumerList(this.state.role);
        } catch (e) {
            console.error(e);
        }
    }

    getShareholderRoleId() {
        let that = this;
        let getmerchantroles = async function () {
            let sRole = await getShareHolderRole();
            that.setState({
                shareholder_role_id: sRole["role_id"],
                role: sRole,
            });
        };
        getmerchantroles();
    }

    render() {
        let title = "";
        let that = this;
        if (this.state.building) {
            title = this.state.building.name;
        }
        let alert;
        if (
            parseInt(this.state.total_shares) - parseInt(this.state.shares) >
            0
        ) {
            alert = (
                <Alert theme="danger">
                    {i18n.t(k.YOU_HAVE)}{" "}
                    {parseInt(this.state.total_shares) -
                        parseInt(this.state.shares)}{" "}
                    {i18n.t(k.UNASSIGNED_SHARES)}
                </Alert>
            );
        }
        if (
            parseInt(this.state.total_shares) - parseInt(this.state.shares) <
            0
        ) {
            alert = (
                <Alert theme="danger">
                    {i18n.t(k.YOU_HAVE_ASSIGNED)}{" "}
                    {parseInt(this.state.shares) -
                        parseInt(this.state.total_shares)}{" "}
                    {i18n.t(k.MORE_SHARES_THAN)}
                </Alert>
            );
        }
        let datatable = CenteredLoader();
        if (this.state.list_roles) {
            datatable = (
                <DataTable
                    autoWidth={true}
                    columns={this.getColumns()}
                    background="#ffffff00"
                    noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                    paginationComponentOptions={{
                        rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                        rangeSeparatorText: i18n.t(k.ROWS_OF),
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                    }}
                    data={this.state.organisation}
                    progressPending={this.state.loading}
                    progressComponent={<Circular />}
                    noHeader={true}
                    onRowClicked={this.rowSelected}
                />
            );
        }
        return (
            <div>
                {/*<div className="container-fluid">*/}
                {/*  <PageHeaderComponent*/}
                {/*    title={"Building Organisation: " + title}*/}
                {/*    subtitle="Overview"/>*/}
                {/*</div>*/}
                {/*<CardWrapperComponent*/}
                {/*  header={true}*/}
                {/*  footer={true}*/}
                {/*  card_title={"Building Organisation: " + title}>*/}
                <Row>
                    <Col>
                        {/*<Button theme="info" onClick={() => {*/}
                        {/*  that.showModal(null);*/}
                        {/*}}><AddIcon className="mr-2"/>{i18n.t(k.ADD_BUILDING_MEMBER)}</Button>*/}
                        <Button onClick={this.showModal} className="mb-3">
                            {i18n.t(k.INVITE_USER)}
                        </Button>
                    </Col>
                    <Col md="3">{alert}</Col>
                </Row>
                {datatable}
                {/*</CardWrapperComponent>*/}
                <InviteUserModal
                    visible={this.state.modal.visible}
                    open={this.hideModal}
                    toggle={this.toggle}
                    onRequestClose={this.props.onRequestClose}
                    shareholder={this.state.modal.shareholder}
                    buildingId={
                        that.props.match
                            ? that.props.match.params.buildingId
                            : that.props.buildingId
                    }
                    role={this.state.role}
                    onInviteSuccess={() => {
                        that.hideModal();
                        // if (that.state.role) {
                        //   that.props.getMerchantConsumerList(that.state.role);
                        // } else {
                        that.loadOrganisation(
                            that.props.match
                                ? that.props.match.params.buildingId
                                : that.props.buildingId
                        );
                        // }
                    }}
                />
                <NotificationContainer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShareholderListComponent);
