import React, { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import {
    NotificationManager,
    NotificationContainer,
} from "react-notifications";
import i18n from "i18next";
import k from "../../../i18n/keys";
import { getAssetList } from "./../../../actions/api/assets/getAssetList";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    ASSET_TYPE,
    getAssetTypeName,
} from "./../../../actions/api/assets/config";
import ClearIcon from "@material-ui/icons/Clear";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Link } from "react-router-dom";

const Circular = () => (
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

const SelectedAssetList = ({ selectedAssets = [], onAssetRemove }) => {
    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    const getColumns = () => {
        return [
            {
                name: i18n.t(k.NAME),
                selector: "name",
                wrap: true,
                sortable: true,
                grow: 1,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.DESCRIPTION),
                selector: "description",
                sortable: true,
                wrap: true,
                grow: 2,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.TYPE),
                sortable: true,
                wrap: true,
                grow: 2,
                style: `cursor: pointer;`,
                cell: function (d) {
                    return `${getAssetTypeName(d.type)}`;
                },
            },
            {
                name: "Select",
                grow: 0,
                cell: (row, _index, _column) => {
                    return (
                        <Link
                            to={"#"}
                            className="pointer"
                            onClick={() => {
                                onAssetRemove(row);
                            }}
                            title={i18n.t(k.SELECT)}
                        >
                            <ClearIcon />
                        </Link>
                    );
                },
            },
        ];
    };
    return (
        <div>
            <DataTable
                style={{ height: "300px" }}
                fixedHeader
                fixedHeaderScrollHeight="300px"
                title={i18n.t(k.AREA_CONTENT)}
                autoWidth={true}
                columns={getColumns()}
                background="#ffffff00"
                data={selectedAssets}
                pagination={true}
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5]}
                noDataComponent={<span>{i18n.t(k.ADD_TO_AREA_FROM_LIST)}</span>}
            />
        </div>
    );
};

export default SelectedAssetList;
