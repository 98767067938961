import HonkioAPI from "../../../middlewares/HonkioAPI";
import moment from "moment";

export const getAssetList = (options = {}, errorHandler = {}) => {
    const {
        type: query_type,
        parent: query_parent,
        levels = 1,
        // New field in controller requires pickup and return dates for range search
        query_pickup_date = "1970-01-01",
        query_return_date = moment().format("YYYY-MM-DD"),
    } = options;
    return HonkioAPI().mainShop.userFetch(
        "assetlist",
        {
            query_parent: query_parent,
            query_type: query_type,
            query_parent_levels: levels,
            query_pickup_date,
            query_return_date,
        },
        errorHandler
    );
};

export const getAssetListWithPages = (options = {}, errorHandler = {}) => {
    const {
        type: query_type,
        types: query_types,
        parent: query_parent,
        levels = 1,
        page = 0,
        count = 0,
        merchant: query_merchant,
        // New field in controller requires pickup and return dates for range search
        query_pickup_date = "1970-01-01",
        query_return_date = moment().format("YYYY-MM-DD"),
    } = options;
    return HonkioAPI().mainShop.userFetch(
        "assetlist",
        {
            query_parent: query_parent,
            query_type: query_type,
            query_types: query_types,
            query_skip: +page * count,
            query_count: count,
            query_parent_levels: levels,
            query_merchant,
            query_pickup_date,
            query_return_date,
        },
        errorHandler
    );
};

export const getAllAssetList = (options = {}, errorHandler = {}) => {
    const { type: query_type } = options;
    return HonkioAPI().mainShop.userFetch(
        "assetlist",
        {
            query_type: query_type,
        },
        errorHandler
    );
};

export const getMerchantAssetListWithPages = (
    options = {},
    errorHandler = {}
) => {
    const {
        type: query_type,
        types: query_types,
        parent: query_parent,
        levels = 1,
        query_child_levels = 0,
        page = 0,
        count = 0,
        merchant: query_merchant,
        // New field in controller requires pickup and return dates for range search
        query_pickup_date = "1970-01-01",
        query_return_date = moment().format("YYYY-MM-DD"),
    } = options;
    return HonkioAPI().mainShop.userFetch(
        "merchantassetlist",
        {
            query_parent: query_parent,
            query_type: query_type,
            query_types: query_types,
            query_skip: +page * count,
            query_count: count,
            query_parent_levels: levels,
            query_child_levels: query_child_levels,
            query_merchant,
            query_pickup_date,
            query_return_date,
        },
        errorHandler
    );
};
