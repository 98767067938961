import HonkioAPI from "../../middlewares/HonkioAPI";


const isLoadingCloseDays = () => ({
    type: "CLOSEDAYS_LOADING"
});

function closedaysSuccess(json) {
    return {
        type: "CLOSEDAYS_SUCCESS",
        closedays: json.closedays
    };
}

const closedaysError = err => ({
    type: "CLOSEDAYS_ERROR",
    err
});

function getCloseDays(areaId) {
    // console.log('Sending request to flask api!!!')
     return async function (dispatch) {
        dispatch(isLoadingCloseDays());
        try {
            let closedays = await HonkioAPI().userShop.userFetch('calendargetclosedays',
                {"area_id": areaId},
                message => {closedaysError(message.description); return false;})


            return dispatch(closedaysSuccess({'closedays': closedays.closedays}));
        } catch (err) {
            return dispatch(closedaysError(err));
        }
    };

}

export {getCloseDays};
