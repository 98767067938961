import i18n from 'i18next';import k from "./../../../i18n/keys";import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Form, FormGroup, Slider } from "shards-react";
import { getOpenTimes } from "../../../actions/api/OpenTimesActions";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import { NotificationManager } from "react-notifications";

class OpenTimesComponent extends Component {
  constructor(props) {
    super(props);
    this.updateOpenTimes = this.updateOpenTimes.bind(this);
    this.state = {
      form: {} };



  }

  componentDidMount() {
    this.props.getOpenTimes(this.props.areaId);
  }

  getTime(hours, minutes) {
    var time = null;
    minutes = minutes + "";
    if (hours < 12) {
      time = "AM";
    } else {
      time = "PM";
    }
    if (hours == 0) {
      hours = 12;
    }
    if (hours > 12) {
      hours = hours - 12;
    }
    if (minutes.length == 1) {
      minutes = "0" + minutes;
    }
    return hours + ":" + minutes + " " + time;
  }


  componentWillReceiveProps(nextProps) {
    // console.log("currenaaaaaat");
    // console.log(nextProps);
    // console.log(nextProps.opentimes);
    var displayTimes = [];
    if (nextProps.opentimes.opentimes) {
    for (var i = 0; i < 7; i++) {
      if (nextProps.opentimes.opentimes[i]) {
        var val0 = nextProps.opentimes.opentimes[i][0],
        val1 = nextProps.opentimes.opentimes[i][1],
        minutes0 = parseInt(val0 % 60, 10),
        hours0 = parseInt(val0 / 60 % 24, 10),
        minutes1 = parseInt(val1 % 60, 10),
        hours1 = parseInt(val1 / 60 % 24, 10),
        // name = $(elem).data('name');
        minutesDt = 30;
        minutes0 = Math.floor(minutes0 / minutesDt) * minutesDt;
        minutes1 = Math.floor(minutes1 / minutesDt) * minutesDt;
        // opentimes[name] = [get24Time(hours0, minutes0), get24Time(hours1, minutes1)];
        var startTime = this.getTime(hours0, minutes0);
        var endTime = this.getTime(hours1, minutes1);
        // console.log(startTime + endTime);
        displayTimes.push([startTime, endTime]);
      }
        this.openTimesChanged(nextProps.opentimes.opentimes);
        this.setState({ ...nextProps.opentimes, displayTimes: displayTimes });
    }}

  }

  handleSlide(i, e) {

    var val0 = e[0],
    val1 = e[1],
    minutes0 = parseInt(val0 % 60, 10),
    hours0 = parseInt(val0 / 60 % 24, 10),
    minutes1 = parseInt(val1 % 60, 10),
    hours1 = parseInt(val1 / 60 % 24, 10),
    // name = $(elem).data('name');
    minutesDt = 30;
    minutes0 = Math.floor(minutes0 / minutesDt) * minutesDt;
    minutes1 = Math.floor(minutes1 / minutesDt) * minutesDt;
    // opentimes[name] = [get24Time(hours0, minutes0), get24Time(hours1, minutes1)];
    var startTime = this.getTime(hours0, minutes0);
    var endTime = this.getTime(hours1, minutes1);
    // console.log(startTime + endTime);
    var displayTimes = this.state.displayTimes;
    displayTimes[i] = [startTime, endTime];
    var state = {...this.state};

    state.opentimes[i] = [
    parseInt(hours0) * 60 + parseInt(minutes0),
    parseInt(hours1) * 60 + parseInt(minutes1)];

    // console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAaaa');
    // console.log(state.opentimes[i]);
    state.displayTimes = displayTimes;
    this.setState(state);
    this.openTimesChanged(state.opentimes)
    console.error(i, e);
    return true;
  }

  updateOpenTimes() {
    // let that = this;
    //
    // let calendarSetOpentimes = async function () {
    //   let a = that.state.opentimes;
    //
    //   //    ('products', False, list),
    //     //     ('object', True, str),
    //     //     ('object_type', True, str),
    //     //     ('opentitle', False, str),
    //     //     ('closedtitle', False, str),
    //     // console.error(saveTimes)
    //     // console.error(saveTimes)
    //     // console.error(saveTimes)
    //     // // alert(typeof saveTimes)
    //     // console.error('saveTimes')
    //   let response = await HonkioAPI().userShop.userFetch('calendarsetopentimes', {
    //     'opentimes': saveTimes,
    //     area_id: that.props.areaId });
    //   let areas = await HonkioAPI().userShop.userFetch('merchantarealist', {
    //       'id': that.props.areaId
    //   });
    //   if (areas.areas.length > 0) {
    //       let area = areas.areas[0]
    //       let merchantschedulegenerate = await HonkioAPI().userShop.userFetch('merchantschedulegenerate', {
    //         'opentimes': saveTimes,
    //         'closedays': [],
    //         'products': [area.product],
    //         'object_type': 'area',
    //         'object': that.props.areaId
    //       });
    //   }
    //   if (response.status === 'accept') {
    //     NotificationManager.success('Opentimes were saved!');
    //   } else {
    //     NotificationManager.warning(response.description || 'Unknown error', 'API error', 5000);
    //   }
    //   that.props.getOpenTimes(that.props.areaId);
    // };
    // calendarSetOpentimes();
  }

  padToFour = number => number <= 9 ? `0${number}`.slice(-2) : number;

  openTimesChanged = (opentimes) => {
       let saveTimes = {};
      for (var i = 0; i < 7; i++) {
        if (opentimes[i]) {
          var val0 = opentimes[i][0],
          val1 = opentimes[i][1],
          minutes0 = parseInt(val0 % 60, 10),
          hours0 = parseInt(val0 / 60 % 24, 10),
          minutes1 = parseInt(val1 % 60, 10),
          hours1 = parseInt(val1 / 60 % 24, 10),
          // name = $(elem).data('name');
          minutesDt = 30;
          minutes0 = Math.floor(minutes0 / minutesDt) * minutesDt;
          minutes1 = Math.floor(minutes1 / minutesDt) * minutesDt;
          // opentimes[name] = [get24Time(hours0, minutes0), get24Time(hours1, minutes1)];
          if (hours0 === hours1 && minutes0 === minutes1) {
            continue;
          }
        }

        saveTimes[i] = [`${this.padToFour(hours0)}:${this.padToFour(minutes0)}`, `${this.padToFour(hours1)}:${this.padToFour(minutes1)}`];
      }
      this.props.onOpenTimesChanged(saveTimes)
  }

  render() {
    // console.log("ASDASDASDASDSS");
    // console.log(this.state);
    // console.log(this.props);
    if (this.state !== null && this.state.opentimes) {

      return (

        <div className="m-2">
                    <h2 className="mt-2">{i18n.t(k.BUSINESS_HOURS_EDIT)}</h2>
                    <p className="mt-2">{i18n.t(k.YOU_CAN_EDIT_YOUR_BUSINESS_HOU)}</p>
                    <Form>
                        <FormGroup className="container-fluid">
                            <label className="col-md-2" htmlFor="monday"><b>{i18n.t(k.MONDAY)}</b></label>
                            <div className="row">
                                <Slider id="monday"
                connect
                className="col-md-8"
                pips={{
                  mode: i18n.t(k.STEPS),
                  stepped: true,
                  density: 3 }}

                onSlide={e => this.handleSlide(0, e)}
                start={this.state.opentimes[0]}
                value={this.state.opentimes[0]}
                range={{ min: 0, max: 1380 }} />

                                <span
                className="col-md-4 mt-4">{this.state.displayTimes[0][0]} {i18n.t(k._8)} {this.state.displayTimes[0][1]}</span>
                            </div>
                        </FormGroup>

                        <FormGroup className="container-fluid">
                            <label className="col-md-2"
              htmlFor="tuesday"><b>{i18n.t(k.TUESDAY)}</b></label>
                            <div className="row">
                                <Slider id="tuesday"
                connect
                className="col-md-8"
                pips={{
                  mode: i18n.t(k.STEPS),
                  stepped: true,
                  density: 3 }}

                onSlide={e => this.handleSlide(1, e)}
                start={this.state.opentimes[1]}
                value={this.state.opentimes[1]}
                range={{ min: 0, max: 1380 }} />

                                <span
                className="col-md-4 mt-4">{this.state.displayTimes[1][0]} {i18n.t(k._8)} {this.state.displayTimes[1][1]}</span>
                            </div>
                        </FormGroup>

                        <FormGroup className="container-fluid">
                            <label className="col-md-2"
              htmlFor="wednesday"><b>{i18n.t(k.WEDNESDAY)}</b></label>
                            <div className="row">
                                <Slider id="wednesday"
                connect
                className="col-md-8"
                pips={{
                  mode: i18n.t(k.STEPS),
                  stepped: true,
                  density: 3 }}

                onSlide={e => this.handleSlide(2, e)}
                start={this.state.opentimes[2]}
                value={this.state.opentimes[2]}
                range={{ min: 0, max: 1380 }} />

                                <span
                className="col-md-4 mt-4">{this.state.displayTimes[2][0]} {i18n.t(k._8)} {this.state.displayTimes[2][1]}</span>
                            </div>
                        </FormGroup>

                        <FormGroup className="container-fluid">
                            <label className="col-md-2"
              htmlFor="thursday"><b>{i18n.t(k.THURSDAY)}</b></label>
                            <div className="row">
                                <Slider id="thursday"
                connect
                className="col-md-8"
                pips={{
                  mode: i18n.t(k.STEPS),
                  stepped: true,
                  density: 3 }}

                onSlide={e => this.handleSlide(3, e)}
                start={this.state.opentimes[3]}
                value={this.state.opentimes[3]}
                range={{ min: 0, max: 1380 }} />

                                <span
                className="col-md-4 mt-4">{this.state.displayTimes[3][0]} {i18n.t(k._8)} {this.state.displayTimes[3][1]}</span>
                            </div>
                        </FormGroup>

                        <FormGroup className="container-fluid">
                            <label className="col-md-2"
              htmlFor="friday"><b>{i18n.t(k.FRIDAY)}</b></label>
                            <div className="row">
                                <Slider id="friday"
                connect
                className="col-md-8"
                pips={{
                  mode: i18n.t(k.STEPS),
                  stepped: true,
                  density: 3 }}

                onSlide={e => this.handleSlide(4, e)}
                start={this.state.opentimes[4]}
                value={this.state.opentimes[4]}
                range={{ min: 0, max: 1380 }} />

                                <span
                className="col-md-4 mt-4">{this.state.displayTimes[4][0]} {i18n.t(k._8)} {this.state.displayTimes[4][1]}</span>
                            </div>
                        </FormGroup>

                        <FormGroup className="container-fluid">
                            <label className="col-md-2"
              htmlFor="saturday"><b>{i18n.t(k.SATURDAY)}</b></label>
                            <div className="row">
                                <Slider id="saturday"
                connect
                className="col-md-8"
                pips={{
                  mode: i18n.t(k.STEPS),
                  stepped: true,
                  density: 3 }}

                onSlide={e => this.handleSlide(5, e)}
                start={this.state.opentimes[5]}
                value={this.state.opentimes[5]}
                range={{ min: 0, max: 1380 }} />

                                <span
                className="col-md-4 mt-4">{this.state.displayTimes[5][0]} {i18n.t(k._8)} {this.state.displayTimes[5][1]}</span>
                            </div>
                        </FormGroup>

                        <FormGroup className="container-fluid">
                            <label className="col-md-2"
              htmlFor="sunday"><b>{i18n.t(k.SUNDAY)}</b></label>
                            <div className="row">
                                <Slider id="sunday"
                connect
                className="col-md-8"
                pips={{
                  mode: i18n.t(k.STEPS),
                  stepped: true,
                  density: 3 }}

                onSlide={e => this.handleSlide(6, e)}
                start={this.state.opentimes[6]}
                value={this.state.opentimes[6]}
                range={{ min: 0, max: 1380 }} />

                                <span
                className="col-md-4 mt-4">{this.state.displayTimes[6][0]} {i18n.t(k._8)} {this.state.displayTimes[6][1]}</span>
                            </div>
                        </FormGroup>


                        {/*<Button id="save_btn"*/}
            {/*// onClick={this.updateOpenTimes}>{i18n.t(k.SAVE)}</Button>*/}
                    </Form>
                </div>);

    } else {
      return '';
    }
  }}


function mapStateToProps(state) {
  return { opentimes: state.opentimes };
}

const mapDispatchToProps = dispatch => ({
  getOpenTimes: areaId => dispatch(getOpenTimes(areaId)) });

export default connect(mapStateToProps, mapDispatchToProps)(OpenTimesComponent);