// Types

export const ADD_BREADCRUMB = "ADD_BREADCRUMB";
export const REMOVE_BREADCRUMB = "REMOVE_BREADCRUMB";
export const SET_BREADCRUMBS = "SET_BREADCRUMBS";
export const GOTO_BREADCRUMB = "GOTO_BREADCRUMB";

const initialState = {
    breadcrumbs: [],
};

function DirectoryReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_BREADCRUMB:
            // payload should be an asset
            return {
                ...state,
                breadcrumbs: [...state.breadcrumbs, action.payload],
            };
        case REMOVE_BREADCRUMB:
            // payload should be asset's id
            const newBreadcrumbs = state.breadcrumbs.filter(
                (item) => item._id !== action.payload
            );
            return {
                ...state,
                breadcrumbs: newBreadcrumbs,
            };
        case SET_BREADCRUMBS:
            // payload should be array of assets or empty array
            return {
                ...state,
                breadcrumbs: action.payload,
            };
        case GOTO_BREADCRUMB:
            // payload should be asset's id
            const gotoIndex = state.breadcrumbs.findIndex(
                (item) => item._id === action.payload
            );
            if (gotoIndex === -1) return state;
            const newBreadcrumbs2 = state.breadcrumbs.slice(0, gotoIndex + 1);
            return {
                ...state,
                breadcrumbs: newBreadcrumbs2,
            };
        default:
            return state;
    }
}

export default DirectoryReducer;
