import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Button } from 'react-bootstrap';
import NotificationManager from "react-notifications/lib/NotificationManager";
import { getAssetTypeName } from '../../actions/api/assets/config';
import { deleteAsset } from '../../actions/api/assets/setAssetData';

const AssetDeleteModal = ({ assetToRemove, assetType, show, onHide, onAssetRemoved }) => {
    const onDelete = () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success("Successfully removed")
                onAssetRemoved()
            } else {
                NotificationManager.error("Error")
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000)
        }

        deleteAsset(assetToRemove._id, errorHandler)
            .then(data => {
                return resultHandler(data)
            })
            .catch(console.error);
    };

    return <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>{`Do you want to remove ${getAssetTypeName(assetType)} "${assetToRemove?.name}?"`}</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => onHide()}>Close</Button>
            <Button variant="danger" onClick={() => onDelete()}>Remove</Button>
        </Modal.Footer>
    </Modal>
};

export default AssetDeleteModal