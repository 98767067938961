import i18n from "i18next";
import k from "../../i18n/keys";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import QuestionEditModalComponent from "./QuestionEditModalComponent";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Button } from "shards-react";
import DeleteForever from "@material-ui/icons/DeleteForever";
import { Link } from "react-router-dom";

export default class QuestionListComponent extends Component {
    constructor(props) {
        super(props);
        this.initLoaded = false;
        this.state = {
            modal: { visible: false, question: {} },
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.edit && !this.initLoaded) {
            if (nextProps.edit.id && !this.initLoaded) this.initLoaded = true;
            this.props.loadQuestions(nextProps.edit.id);
        }
    }

    hideModal = () => {
        this.setState({
            ...this.state,
            modal: { ...this.state.modal, visible: false },
        });
    };

    showModal = () => {
        this.setState({
            ...this.state,
            modal: { ...this.state.modal, visible: true },
        });
    };

    toggle = () => {
        this.setState({
            modal: { ...this.state.modal, visible: !this.state.modal.visible },
        });
    };

    getColumns() {
        let that = this;
        return [
            {
                name: "Question Title",
                selector: "title",
            },
            {
                name: "Question",
                selector: "text",
                sortable: true,
            },
            {
                name: "Actions",
                width: "20",
                cell: function (d) {
                    return (
                        <span>
                            <Link
                                title={i18n.t(k.DELETE_ACTION)}
                                id="deleteTip"
                                to="#"
                                className="pointer"
                                onClick={() => {
                                    that.props.questionDelete(d);
                                }}
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ];
    }

    render() {
        return (
            <div>
                <Button theme="info" className="mb-4" onClick={this.showModal}>
                    <AddCircleOutlineIcon className="mr-1" fontSize="small" />
                    Add question
                </Button>

                <DataTable
                    className="border"
                    noHeader={true}
                    center={true}
                    autoWidth={true}
                    columns={this.getColumns()}
                    background="#ffffff00"
                    data={this.props.questions}
                    noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                    paginationComponentOptions={{
                        rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                        rangeSeparatorText: i18n.t(k.ROWS_OF),
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                    }}
                />
                <QuestionEditModalComponent
                    visible={this.state.modal.visible}
                    product={this.state.modal.product}
                    toggle={this.toggle}
                    onRequestClose={this.hideModal}
                    eventId={null}
                    question={this.state.modal.question}
                    addQuestion={this.props.addQuestion}
                    onQuestionSaved={() => {
                        this.hideModal();
                    }}
                />
            </div>
        );
    }
}
