import React from 'react';
const SelectField = ({
  item,
  index,
  field = 'field',
  group = 'default',
  type = 'text',
  name,
  label,
  className,
  register,
  options = [],
  defaultValue,
  required = false,
}) => {
  name = name ?? `${group}[${index}].${field}`;
  defaultValue = defaultValue ?? item?.[field];
  return (
    <div className={className}>
      {label && <label htmlFor={name}>{label}</label>}
      <select
        ref={register}
        type={type}
        className="custom-select d-block w-100"
        name={name}
        defaultValue={defaultValue}
        required={required}
      >
        <option style={{ display: 'none' }}>?</option>
        {options.map((opt, index) => (
          <option key={index}>{opt}</option>
        ))}
      </select>
    </div>
  );
};

export default SelectField;
