import i18n from 'i18next';
import k from "./../i18n/keys";
import React, {Component} from 'react';
import CardWrapperComponent from "./ui_utils/CardWrapperComponent";
import {connect} from "react-redux";
import {Line, Pie} from 'react-chartjs-2';
import HonkioAPI from "../middlewares/HonkioAPI";
import {Button, Form, FormGroup, FormInput} from "shards-react";
import SaveIcon from '@material-ui/icons/Save';
import CenteredPageLoader from "./ui_utils/CenteredPageLoader";
import {NotificationManager} from "react-notifications";
import JoditEditor from "jodit-react";
import Select from "react-select";
import DatePickerComponent from "./ui_utils/DatePickerComponent";
import {DateTimePicker} from "react-widgets";
import getValues from "./invoices/product_type_utils";

import moment from "moment";
import "moment/locale/fi";

var IBAN = require('iban');

class MerchantSettingsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			iban_valid: false,
			template_modified: 0
		};
		this.options = [
			{value: "january", label: i18n.t(k.JANUARY)},
			{value: "february", label: i18n.t(k.FEBRUARY)},
			{value: "march", label: i18n.t(k.MARCH)},
			{value: "april", label: i18n.t(k.APRIL)},
			{value: "may", label: i18n.t(k.MAY)},
			{value: "june", label: i18n.t(k.JUNE)},
			{value: "july", label: i18n.t(k.JULY)},
			{value: "august", label: i18n.t(k.AUGUST)},
			{value: "september", label: i18n.t(k.SEPTEMBER)},
			{value: "october", label: i18n.t(k.OCTOBER)},
			{value: "november", label: i18n.t(k.NOVEMBER)},
			{value: "december", label: i18n.t(k.DECEMBER)},
		]
		this.loadMerchant = this.loadMerchant.bind(this);
		this.getDefault = this.getDefault.bind(this)
	}

	componentDidMount() {
		this.loadMerchant();
	}

	loadMerchant() {
		let that = this;
		let merchantGet = async function () {
			let response = await HonkioAPI().userShop.userFetch('merchantget', {});
			let template = await HonkioAPI().userShop.userFetch('merchantmessagetemplateget', {
				'id': response.merchant.id_invoice_template
			});

			let newState = {
				'merchant': response.merchant,
				'loading': false,
				'id_invoice_template': response.merchant.id_invoice_template,
				'template': template.template.message,
				'subject': template.template.subject
			};


			if (response.merchant.list_bank_account && response.merchant.list_bank_account.length > 0) {
				newState['bank_name'] = response.merchant.list_bank_account[0].name;
				newState['bank_iban'] = response.merchant.list_bank_account[0].iban;
				newState['bank_bic'] = response.merchant.list_bank_account[0].bic;
				newState['iban_valid'] = IBAN.isValid(response.merchant.list_bank_account[0].iban);
				newState['fin_year_start'] = response.merchant.str_financial_year_start_month;
			}
			that.setState(newState);
			console.error('response');
			console.error(response);
		};
		merchantGet();
	}

	save = () => {
		let that = this;
		this.setState({'loading': true});
		let merchantSet = async function () {
			let consumer = await HonkioAPI().userShop.userFetch('consumerget', {});


			let response = await HonkioAPI().userShop.userFetch('merchantset', {
				'id': localStorage.getItem('merchant_id'),
				'login_identity': consumer.login_identity,
				'merchant': {
					'list_bank_account': [{
						'name': that.state.bank_name,
						'iban': that.state.bank_iban,
						'bic': that.state.bank_bic
					}],
					'str_financial_year_start_month': that.state.fin_year_start
				},
				'adminuser': {}
			});

			if (that.state.template_modified > 1) {
				let template = await HonkioAPI().userShop.userFetch('merchantmessagetemplateset', {
					'id': that.state.id_invoice_template,
					'subject': that.state.subject,
					'message': that.state.template
				});

			}
			let merchant = await HonkioAPI().userShop.userFetch('merchantget', {});
			that.props.merchantgetSuccess(merchant);
			NotificationManager.success(i18n.t(k.SETTINGS_SAVED));
			that.setState({'merchant': response.merchant, 'loading': false});
			that.loadMerchant();
		};
		merchantSet();
	};

	handleChange(e) {
		let newState = {
			...this.state,
			[e.target.id]: e.target.value
		};

		if (e.target.id === 'bank_iban') {
			newState['iban_valid'] = IBAN.isValid(e.target.value.replace(/\W/g, ''));
			newState['bank_iban'] = e.target.value.replace(/\W/g, '');
		}
		this.setState(newState);
	}

	getDefault() {
		let month = this.state.fin_year_start
		this.options.forEach(option => {
			if (option.value === month) {
				// console.log("OPTION")
				// console.log(option)
				return option
			}
			return this.options[0]
		})
	}

	render() {
		// console.log("STATE")
		// console.log(this.state)
		let select = <Select id="type" options={this.options} onChange={e => {
							this.setState({
								'fin_year_start': e.value
							});
						}}/>

		if (this.state.fin_year_start) {
			select = <Select id="type"
											 options={this.options}
											 value={this.options.filter(option => option.value === this.state.fin_year_start)}
											 onChange={e => {
													this.setState({
														'fin_year_start': e.value
													});
												}}
			/>
		}
		if (this.state.loading) {
			return <CenteredPageLoader/>;
		}
		let that = this;
		return (
			<div className="mt-3">
				<CardWrapperComponent
					header={true}
					footer={true}
					card_title={i18n.t(k.MERCHANT_SETTINGS)}>
					<Button className="mb-3" onClick={() => {
						this.props.history.push('/productClasses');
					}}><SaveIcon/>{i18n.t(k.PRODUCT_CATEGORIES_GROUPS)}</Button>
					<h4>{i18n.t(k.BANK_SETTINGS)}</h4>

					<FormGroup>
						<label htmlFor="bank_name">{i18n.t(k.BANK_NAME)}</label>
						<FormInput id="bank_name" placeholder={i18n.t(k.BANK_NAME)} type="text"
											 value={this.state.bank_name}
											 onChange={e => this.handleChange(e)}/>
					</FormGroup>
					<FormGroup>
						<label htmlFor="bank_iban">{i18n.t(k.BANK_IBAN)}</label>
						<FormInput id="bank_iban" placeholder="FI21 1234 5600 0007 85" type="text"
											 value={this.state.bank_iban}
											 valid={this.state.iban_valid === true}
											 invalid={this.state.iban_valid === false}
											 onChange={e => this.handleChange(e)}/>
					</FormGroup>
					<FormGroup>
						<label htmlFor="bank_bic">{i18n.t(k.BANK_BIC)}</label>
						<FormInput id="bank_bic" placeholder={i18n.t(k.BANK_BIC)} type="text"
											 value={this.state.bank_bic}
											 onChange={e => this.handleChange(e)}/>
					</FormGroup>
					{/*{ (localStorage.getItem('access') || []).includes('superadmin') ?*/}
					{/*<FormGroup>*/}
					{/*	<label htmlFor="bank_bic">{i18n.t(k.BANK_BIC)}</label>*/}
					{/*	<FormInput id="bank_bic" placeholder={i18n.t(k.BANK_BIC)} type="text"*/}
					{/*						 value={this.state.bank_bic}*/}
					{/*						 onChange={e => this.handleChange(e)}/>*/}
					{/*</FormGroup>: ''}*/}


					<FormGroup>
						<label htmlFor="start_date">{i18n.t(k.FIN_START_MONTH)}</label>
						{select}
					</FormGroup>


					<hr/>
					<h4>{i18n.t(k.INVOICE_TEMPLATE)}</h4>
					<JoditEditor
						value={this.state.template}
						content={this.state.template}
						config={{readonly: false}}
						tabIndex={1}
						onChange={newContent => {
							that.setState({'template': newContent, 'template_modified': this.state.template_modified + 1});
						}}
						onBlur={newContent => that.setState({'template': newContent})}/>

					<Button className="m-3" onClick={this.save}><SaveIcon/>{i18n.t(k.SAVE)}</Button>
				</CardWrapperComponent>
			</div>);

	}
}


function mapStateToProps(state) {
	return state;
}

const mapDispatchToProps = dispatch => ({
	merchantgetSuccess: merchant => dispatch({
		type: "GET_MERCHANT_SUCCESS",
		merchantInfo: merchant.merchant
	})
});


export default connect(mapStateToProps, mapDispatchToProps)(MerchantSettingsComponent);