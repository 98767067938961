import HonkioAPI from '../../../middlewares/HonkioAPI';

const getAdminRolesList = (options = {}) => {
  const {
    appid,
    merchant,
    debug,
    id,
  } = options;
  return HonkioAPI().mainShop.userFetch('adminrolelist', {
    debug,
    query_application: appid,
    query_merchant: merchant,
    id
  });
};
export default getAdminRolesList;
