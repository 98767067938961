import React, {  useState} from 'react';
import { NotificationManager, NotificationContainer } from "react-notifications";
import SelectedAssetList from './SelectedAssetList';
import { Button } from 'shards-react';
import { Form } from 'shards-react';
import { FormGroup } from 'shards-react';
import { FormInput } from 'shards-react';
import i18n from 'i18next';
import k from "../../../i18n/keys";
import SelectableAssetListModal from './Subcomponents/SelectableAssetListModal';

const AssetGroupEditForm = ({ defaultValues, onSubmit }) => {
    const [assetGroupInfo, setAssetGroupInfo] = useState(defaultValues);

    const [showAddAssetModal, setShowAddAssetModal] = useState(false);
    const handleShowAddAssetModal = () => setShowAddAssetModal(true);
    const handleCloseAddAssetModal = () => setShowAddAssetModal(false);

    const onAssetSelect = (data) => {
        const found = assetGroupInfo.assets.find(asset => asset._id === data._id)
        if (!found) {
            setAssetGroupInfo({
                ...assetGroupInfo,
                assets: [...assetGroupInfo.assets, data]
            })
        } else {
            setAssetGroupInfo({
                ...assetGroupInfo,
                assets: assetGroupInfo.assets.filter(asset => asset._id !== data._id)
            })
        }
    }
    const onAssetRemove = (data) => {
        setAssetGroupInfo({
            ...assetGroupInfo,
            assets: assetGroupInfo.assets.filter(asset => asset._id !== data._id)
        })
    }
    const onSaveClick = (data) => {
        if (data.name && data.name.length > 0 && data.description && data.description.length > 0 && data.assets && data.assets.length > 0) {
            onSubmit(data)
        } else {
            NotificationManager.warning('Fill required info')
        }
    }
    const onAddClick = () => {
        handleShowAddAssetModal()
    }
    function submitAddAssets(assetList) {
        setAssetGroupInfo({
            ...assetGroupInfo,
            assets: assetList
        })
        handleCloseAddAssetModal()
    }
    return <div >
        <NotificationContainer />
        <Form>
            <div className='row'>
                <div className='col'>
                    <FormGroup>
                        <label htmlFor="name">{i18n.t(k.AREA_NAME)}</label>
                        <FormInput
                            id="name"
                            valid={assetGroupInfo.name && assetGroupInfo.name.length > 0}
                            invalid={assetGroupInfo.name?.trim().length === 0}
                            value={assetGroupInfo.name}
                            onChange={e => setAssetGroupInfo({
                                ...assetGroupInfo,
                                name: e.target.value
                            }
                            )} />
                    </FormGroup>
                </div>
                <div className='col'>
                    <FormGroup>
                        <label htmlFor="name">{i18n.t(k.DESCRIPTION)}</label>
                        <FormInput
                            id="name"
                            valid={assetGroupInfo.description?.length > 0}
                            invalid={assetGroupInfo.description?.trim().length === 0}
                            value={assetGroupInfo.description}
                            onChange={e => setAssetGroupInfo({
                                ...assetGroupInfo,
                                description: e.target.value
                            }
                            )} />
                    </FormGroup>
                </div>
            </div>
        </Form>
        <SelectedAssetList
            selectedAssets={assetGroupInfo.assets}
            onAssetRemove={onAssetRemove}
        />

        <Button
            className='mr-3'
            theme="secondary"
            onClick={() => onAddClick()}>Add</Button>
        <Button
            theme="primary"
            onClick={() => onSaveClick(assetGroupInfo)}>Save</Button>
        {showAddAssetModal && <SelectableAssetListModal
            show={showAddAssetModal}
            onHide={handleCloseAddAssetModal}
            onSubmit={submitAddAssets}
            selectedAssets={assetGroupInfo.assets}
            parent={defaultValues.parent}
        />}
    </div>
};

export default AssetGroupEditForm;
