import React, { Component } from "react";
import { Col, Row } from "shards-react";

class PageHeaderComponent extends Component {
  render() {
    return (
      <Row noGutters className="page-header py-4">
                <Col xs="12" sm="12" md="12" className="">
                    <span className="text-uppercase page-subtitle">{this.props.subtitle}</span>
                    <h3 className="page-title">{this.props.title}</h3>
                </Col>
            </Row>);

  }}


export default PageHeaderComponent;