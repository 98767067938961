import i18n from 'i18next';
import k from "./../../../i18n/keys";
import React, {Component} from 'react';
import {Button, ButtonGroup} from "shards-react";


class NetAssetWizardNavComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let backButton, nextButton;
    if (this.props.currentStep > 1) {
      backButton =
        <Button theme="secondary" className="mr-5" onClick={this.props.previousStep}>{i18n.t(k.BACK)}</Button>;
    }
    // if (this.props.currentStep === 1) {
    //   nextButton = <Button theme="primary" onClick={this.props.nextStep}>{i18n.t(k.CREATE_NEW)}</Button>;
    if (this.props.currentStep < this.props.totalSteps) {
      nextButton = <Button theme="primary" onClick={this.props.nextStep}>{i18n.t(k.NEXT)}</Button>;
    } else {
      nextButton = <ButtonGroup>
        <Button theme="primary" onClick={() => {
          this.props.onSaveClick();
        }}>{i18n.t(k.SEND)}</Button>
      </ButtonGroup>;
    }
    return (

      <span className="d-flex justify-content-end">
        {backButton}
        {nextButton}
    </span>);


  }
}


export default NetAssetWizardNavComponent;
