import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { Component } from "react";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataTable from "react-data-table-component";
import DeleteForever from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import Schedule from "@material-ui/icons/Schedule";
import { Button } from "shards-react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import NotificationManager from "react-notifications/lib/NotificationManager";
import HonkioAPI from "../../middlewares/HonkioAPI";
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";
import AreaCreatePolyModalComponent from "./subcomponents/AreaCreatePolyModalComponent";
import AreaCreateComponent from "./AreaCreateComponent";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import SwapAreaModalComponent from "./subcomponents/SwapAreaModalComponent";
import AreaEditModalComponent from "./subcomponents/AreaEditModalComponent";
import DescriptionIcon from "@material-ui/icons/Description";
import BreadcrumbsComponent from "../breadcrumbs/BreadcrumbsComponent";
import { Photo } from "@material-ui/icons";
import { ASSET_TYPE, ASSET_AREA_TYPE } from "./../../actions/api/assets/config";
import { Link } from "react-router-dom";

const Circular = () => (
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

class AreasListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            noShareholders: false,
            areas: [],
            modal: {
                visible: false,
                area: {
                    rules: {},
                },
            },
            modalEdit: {
                visible: false,
                area: {
                    rules: {},
                },
            },
            modalNewArea: {
                visible: false,
                floorplan: "",
            },
            modalSwap: {
                visible: false,
            },
        };
        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.toggle = this.toggle.bind(this);

        this.hideModalEdit = this.hideModalEdit.bind(this);
        this.showModalEdit = this.showModalEdit.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);

        this.hideModalSwap = this.hideModalSwap.bind(this);
        this.showModalSwap = this.showModalSwap.bind(this);
        this.toggleSwap = this.toggleSwap.bind(this);

        this.hideModalNewArea = this.hideModalNewArea.bind(this);
        this.showModalNewArea = this.showModalNewArea.bind(this);
        this.toggleNewArea = this.toggleNewArea.bind(this);

        this.loadAreas = this.loadAreas.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
    }

    hideModal() {
        this.setState({ modal: { ...this.state.modal, visible: false } });
    }

    showModal(area, polygon, plan) {
        this.setState({
            modal: {
                ...this.state.modal,
                visible: true,
                area: area,
                polygon: polygon,
                plan: plan,
            },
        });
    }

    toggle() {
        this.setState({
            modal: { ...this.state.modal, visible: !this.state.modal.visible },
        });
    }

    hideModalEdit() {
        this.setState({
            modalEdit: { ...this.state.modalEdit, visible: false },
        });
    }

    showModalEdit(area) {
        this.setState({
            modalEdit: { ...this.state.modalEdit, visible: true, area: area },
        });
    }

    toggleEdit() {
        this.setState({
            modalEdit: {
                ...this.state.modalEdit,
                visible: !this.state.modalEdit.visible,
            },
        });
    }

    hideModalSwap() {
        this.setState({
            modalSwap: { ...this.state.modalSwap, visible: false },
        });
    }

    showModalSwap(area) {
        this.setState({
            modalSwap: { ...this.state.modalSwap, visible: true, area: area },
        });
    }

    toggleSwap() {
        this.setState({
            modalSwap: {
                ...this.state.modalSwap,
                visible: !this.state.modalSwap.visible,
            },
        });
    }

    hideModalNewArea() {
        this.setState({
            modalNewArea: { ...this.state.modalNewArea, visible: false },
        });
    }

    showModalNewArea(floorplanId) {
        this.setState({
            modalNewArea: {
                ...this.state.modalNewArea,
                visible: true,
                floorplan: floorplanId,
            },
        });
    }

    toggleNewArea() {
        this.setState({
            modalNewArea: {
                ...this.state.modalNewArea,
                visible: !this.state.modalNewArea.visible,
            },
        });
    }
    onRequestCloseNewArea() {
        this.hideModal();
    }

    componentDidMount() {
        this.loadAreas();
        this.loadFloorplan();
        this.loadParents();
    }

    loadFloorplan() {
        let that = this;
        let floorplanId = this.props.match.params.floorplan;
        if (floorplanId) {
            this.setState({ floorplanId: floorplanId });
            let query = { query_id: floorplanId };
            let merchantfloorplanlist = async function () {
                let response = await HonkioAPI().userShop.userFetch(
                    "merchantassetlist",
                    query,
                    (error) => {
                        that.setState({
                            plan: [],
                        });
                    }
                );
                if (
                    response?.status === "accept" &&
                    response?.assets &&
                    response?.assets.length > 0
                ) {
                    that.setState({ plan: response.assets[0] });
                } else {
                    that.setState({
                        plan: [],
                    });
                }
            };
            merchantfloorplanlist();
        }
    }

    loadParents() {
        let that = this;
        that.setState({
            loadingParents: true,
            parents: [],
        });
        let floorplanId = this.props.match.params.floorplan;
        let areaId = this.props.match.params.area;
        let buildingId = this.props.match.params.buildingId;
        let merchantAreaParentList = async function () {
            let params = {
                query_parent_levels: 3,
            };
            if (floorplanId) params["query_id"] = floorplanId;
            if (buildingId) params["query_id"] = buildingId;
            if (areaId) params["query_id"] = areaId;
            let response = await HonkioAPI().userShop.userFetch(
                "merchantassetlist",
                params,
                (error) => {
                    that.setState({
                        loadingParents: false,
                        parents: [],
                    });
                }
            );
            if (
                response?.status === "accept" &&
                response?.parents &&
                response?.assets
            ) {
                let parents = response.parents;
                const parentAsset = response.assets;
                that.setState({
                    parentAsset: {
                        parentAsset,
                        parents,
                    },
                    loadingParents: false,
                });
            } else {
                that.setState({
                    loadingParents: false,
                    parents: [],
                });
            }
        };
        merchantAreaParentList();
    }
    confirmDelete(area) {
        let that = this;
        const options = {
            title: "Destructive action",
            message: "",
            childrenElement: () => (
                <p>
                    {i18n.t(k.ARE_YOU_SURE_WANT_TO_DELETE1)} <b>{area.name}</b>
                    {i18n.t(k._4)}
                </p>
            ),
            buttons: [
                {
                    label: i18n.t(k.YES),
                    onClick: () => {
                        const del = async () => {
                            that.setState({ loading: true });
                            let response = await HonkioAPI().userShop.userFetch(
                                "merchantassetset",
                                {
                                    id: area.id,
                                    delete: true,
                                }
                            );
                            if (response.status === "accept") {
                                NotificationManager.success(
                                    i18n.t(k.LOADING),
                                    i18n.t(k.AREA_HAS_BEEN_REMOVED)
                                );
                            } else {
                                NotificationManager.success(
                                    `${response.description}`,
                                    i18n.t(k.DELETE_FAILED)
                                );
                            }
                            that.loadAreas();
                        };
                        del();
                    },
                },
                {
                    label: i18n.t(k.NO),
                    onClick: () => {},
                },
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        };
        confirmAlert(options);
    }

    loadAreas() {
        let that = this;
        let floorplanId = this.props.match.params.floorplan;
        let areaId = this.props.match.params.area;
        let buildingId = this.props.match.params.buildingId;
        this.setState({ loading: true });
        let merchantarealist = async function () {
            let params = {
                query_type: ASSET_TYPE.AREA,
            };
            if (floorplanId) {
                params["query_parent"] = floorplanId;
            }
            if (buildingId) {
                params["query_parent"] = buildingId;
            }
            if (areaId) params["query_parent"] = areaId;
            let response = await HonkioAPI().userShop.userFetch(
                "assetlist",
                params,
                (error) => {
                    that.setState({
                        areas: [],
                        loading: false,
                    });
                    NotificationManager.error(
                        `${error.description}`,
                        i18n.t(k.ERROR_API)
                    );
                }
            );
            if (response?.status === "accept" && response?.assets) {
                that.setState({
                    areas: response.assets,
                    loading: false,
                });
            } else {
                that.setState({
                    areas: [],
                    loading: false,
                });
                NotificationManager.error(i18n.t(k.ERROR_API));
            }
        };
        merchantarealist();
    }

    getColumns() {
        let that = this;
        return [
            {
                name: i18n.t(k.NAME),
                selector: "name",
                sortable: true,
                autoWidth: true,
                grow: 2,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.TOTAL_AREA),
                selector: (d) => (
                    <span>
                        {d.properties.rules?.total_area}
                        {i18n.t(k.M)}
                    </span>
                ),
                sortable: true,
                autoWidth: true,
                grow: 1,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.FLOORPLANS_L),
                selector: "parent_name",
                sortable: true,
                autoWidth: true,
                grow: 1,
                style: `cursor: pointer;`,
            },
            // }, {
            //     name: 'Shareholder',
            //     selector: (d) => <span>{d.rules.owner_name}</span>,
            //     sortable: true,
            //     style: `cursor: pointer;`
            // },
            {
                name: i18n.t(k.AREA_TYPE),
                selector: (d) => (
                    <span>
                        {d.properties?.rules
                            ? ASSET_AREA_TYPE[d.properties.rules.space_type - 1]
                                  ?.label || "N/A"
                            : "N/A"}
                    </span>
                ),
                sortable: true,
                autoWidth: true,
                grow: 0,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.RESERVABLE),
                selector: (d) => (
                    <span>
                        {d.properties?.rules?.reservable
                            ? i18n.t(k.TRUE)
                            : i18n.t(k.FALSE)}
                    </span>
                ),
                sortable: true,
                autoWidth: true,
                grow: 0,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.MARKET_RENT_PRICE),
                selector: (d) => (
                    <span>{d.properties?.rules?.market_rent || "N/A"}</span>
                ),
                sortable: true,
                autoWidth: true,
                grow: 0,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.ACTION),
                selector: "action",
                autoWidth: true,
                grow: 1,
                cell: function (d) {
                    let opentimes;
                    if (d.properties?.rules?.reservable) {
                        opentimes = (
                            <Link
                                className="pointer"
                                title={i18n.t(k.AREA_OPENTIMES_CLOSEDAYS)}
                                to={`/opentimes/${d.id}`}
                            >
                                <Schedule />
                            </Link>
                        );
                    }
                    return (
                        <span>
                            <Link
                                to="#"
                                className="pointer"
                                onClick={() => {
                                    that.showModalEdit(d, null);
                                }}
                                title={i18n.t(k.EDIT_AREA)}
                            >
                                <EditIcon />
                            </Link>
                            {opentimes}
                            <Link
                                to="#"
                                className="pointer"
                                title={i18n.t(k.AREA_CHANGE_OWNER)}
                                onClick={() => {
                                    that.showModalSwap(d);
                                }}
                            >
                                <SwapHorizIcon />
                            </Link>
                            <Link
                                id="documentsTip"
                                to={`/area-docs/${d.id}`}
                                title={i18n.t(k.AREA_DOCUMENTS)}
                                className="pointer"
                            >
                                <DescriptionIcon />
                            </Link>
                            <Link
                                id="documentsTip"
                                to={`/area-photos/${d.id}`}
                                title={i18n.t(k.AREA_PHOTOS)}
                                className="pointer"
                            >
                                <Photo />
                            </Link>
                            <Link
                                to="#"
                                className="pointer"
                                title={i18n.t(k.AREA_DELETE)}
                                onClick={() => {
                                    that.confirmDelete(d);
                                }}
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ];
    }

    swap(to_shareholder, from_shareholder, area_id) {
        let that = this;
        let params = {
            to_shareholder: to_shareholder,
            area_id: area_id,
        };
        if (from_shareholder !== undefined) {
            params["from_shareholder"] = from_shareholder;
        }
        let merchantsharetransfer = async function () {
            let response = await HonkioAPI().userShop.userFetch(
                "merchantsharetransfer",
                params
            );
        };
        merchantsharetransfer();
    }

    edit(area) {
        let rules = {
            total_area: area.properties?.rules?.total_area,
            market_rent: area.properties?.rules?.market_rent,
            space_type: area.properties?.rules?.space_type,
            amount: area.properties?.rules?.amount,
        };
        const assetProperties = {
            properties: { rules },
        };
        let params = {
            name: area.name,
            // total_area: area.rules.total_area,
            // market_rent: area.rules.market_rent,
            // space_type: area.rules.space_type,
            // tenant_improvement_costs: area.rules.tenant_improvement_costs,
            // id: area.id };

            ...assetProperties,
            id: area.id,
            structure: "", //inna: todo insert area structure
        };
        let that = this;
        let merchantareaset = async function () {
            let response = await HonkioAPI().userShop.userFetch(
                "merchantassetset",
                params,
                (error) => {
                    that.setState({ loading: false });
                }
            );
            if (response.status === "accept") {
                NotificationManager.success(
                    i18n.t(k.AREA_EDITED),
                    i18n.t(k.SUCCESS)
                );
                that.loadAreas();
            }
        };
        merchantareaset();
    }

    rowSelected = (d) => {
        let that = this;
        that.props.history.push("/areas/" + d.id);
    };

    getParents = () => {
        let that = this;
        let parentsList = [
            {
                name: i18n.t(k.PROPERTIES),
                linkTo: "/landplots",
            },
        ];
        if (that.state.parentAsset) {
            const landplotElem = that.state.parentAsset.parents
                .filter((asset) => asset.type === ASSET_TYPE.LANDPLOT)
                .map((asset) => ({
                    name: asset.name,
                    linkTo: "/buildings/" + asset.id,
                }));
            const buildingElem = that.state.parentAsset.parents
                .filter((asset) => asset.type === ASSET_TYPE.BUILDING)
                .map((asset) => ({
                    name: asset.name,
                    linkTo: "/floorplans/" + asset.id,
                }));
            const areaElem = that.state.parentAsset.parents
                .filter(
                    (asset) =>
                        asset.type === ASSET_TYPE.AREA &&
                        that.state.parentAsset.parentAsset.parent === asset.id
                )
                .map((asset) => ({
                    name: asset.name,
                    linkTo: "/areas/" + asset.id,
                }));
            const areaOthersElem = that.state.parentAsset.parents
                .filter(
                    (asset) =>
                        asset.type === ASSET_TYPE.AREA &&
                        that.state.parentAsset.parentAsset.parent !== asset.id
                )
                .map((asset) => ({
                    name: "...",
                    linkTo: null,
                }));
            const lastElem = that.state.parentAsset.parentAsset.map(
                (asset) => ({
                    name: asset.name,
                    linkTo: null,
                })
            );
            if (landplotElem.length > 0) {
                parentsList = [...parentsList, landplotElem[0]];
            }
            if (buildingElem.length > 0) {
                parentsList = [...parentsList, buildingElem[0]];
            }
            if (areaOthersElem.length > 0) {
                parentsList = [...parentsList, areaOthersElem[0]];
            }
            if (areaElem.length > 0) {
                parentsList = [...parentsList, areaElem[0]];
            }
            if (lastElem.length > 0) {
                parentsList = [...parentsList, lastElem[0]];
            }
        }
        return parentsList;
    };
    render() {
        let that = this;
        let getBreadcrumbs = () => {
            return <BreadcrumbsComponent parents={this.getParents()} />;
        };
        // console.log("THE STATE OF THINGS")
        // console.log(this.state)
        return (
            <div>
                <div className="container-fluid">
                    <PageHeaderComponent title={i18n.t(k.AREAS)} />
                </div>
                <CardWrapperComponent
                    header={true}
                    footer={true}
                    card_title={getBreadcrumbs()}
                >
                    {this.state.floorplanId && (
                        <Button
                            theme="info"
                            onClick={() =>
                                that.showModalNewArea(this.state.floorplanId)
                            }
                        >
                            <AddIcon className="mr-2" />
                            {i18n.t(k.CREATE_AREA)}
                        </Button>
                    )}
                    <DataTable
                        autoWidth={true}
                        columns={this.getColumns()}
                        background="#ffffff00"
                        data={this.state.areas}
                        noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                        paginationComponentOptions={{
                            rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                            rangeSeparatorText: i18n.t(k.ROWS_OF),
                            noRowsPerPage: false,
                            selectAllRowsItem: false,
                            selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                        }}
                        progressPending={this.state.loading}
                        progressComponent={<Circular />}
                        onRowClicked={this.rowSelected}
                    />
                </CardWrapperComponent>
                <AreaCreatePolyModalComponent
                    visible={this.state.modal.visible}
                    open={this.hideModal}
                    toggle={this.toggle}
                    onRequestClose={this.props.onRequestClose}
                    polygon={
                        this.state.modal.area?.properties?.rules.data ??
                        this.state.polygon
                    }
                    area={this.state.modal.area}
                    plan={this.state.modal.plan}
                    plan_id={this.state.floorplanId}
                    onAreaSaved={() => {
                        that.hideModal();
                        window.location.reload(false);
                    }}
                />
                <AreaCreateComponent
                    visible={this.state.modalNewArea.visible}
                    open={this.hideModalNewArea}
                    toggle={this.toggleNewArea}
                    onRequestClose={this.props.onRequestClose}
                    floorplan={this.state.modalNewArea.floorplan}
                    onAreaSelected={(polygon) => {
                        that.setState({ polygon: polygon });
                        that.toggleNewArea();
                        that.showModal(null, polygon, this.state.plan);
                    }}
                />
                <SwapAreaModalComponent
                    visible={this.state.modalSwap.visible}
                    open={this.hideModalSwap}
                    toggle={this.toggleSwap}
                    onRequestClose={this.props.onRequestClose}
                    area={this.state.modalSwap.area}
                    onAreaSwapped={(
                        to_shareholder,
                        from_shareholder,
                        area_id
                    ) => {
                        that.swap(to_shareholder, from_shareholder, area_id);
                        that.toggleSwap();
                        NotificationManager.success(
                            i18n.t(k.AREA_TRANSFER_COMPLETED),
                            i18n.t(k.SUCCESS)
                        );
                    }}
                />
                <AreaEditModalComponent
                    visible={this.state.modalEdit.visible}
                    open={this.hideModalEdit}
                    toggle={this.toggleEdit}
                    onRequestClose={this.props.onRequestClose}
                    area={this.state.modalEdit.area}
                    onAreaEdited={(area) => {
                        that.edit(area);
                        that.toggleEdit();
                    }}
                />
            </div>
        );
    }
}

export default AreasListComponent;
