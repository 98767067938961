import React from 'react';

const LoadingBar = ({ progress, transitionDuration = 300 }) => {
  return (
    <div className="p-5 shadow rounded">
      <h3>{`Loading (${progress ?? 0}%)`}</h3>
      <div className="progress">
        <div
          className="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
          style={{
            width: `${progress}%`,
            transitionDuration: `${transitionDuration}ms`,
          }}
        ></div>
      </div>
    </div>
  );
};

export default LoadingBar;
