const initialState = {
  err: null,
  opentimes: null };


function opentimesReducer(state = initialState, action) {
  switch (action.type) {
    case "OPENTIMES_SUCCESS":
      return {
        ...state,
        err: null,
        opentimes: action.opentimes };

    case "OPENTIMES_ERROR":
      return {
        ...state,
        err: action.err,
        opentimes: null };

    default:
      return state;}

}

export default opentimesReducer;