const formDataToOptions = (fieldArray) => {
  return (
    fieldArray?.reduce((properties, { name, type, description }) => {
      return { ...properties, [name]: { type, description } };
    }, {}) || {}
  );
};

const extraPropertiesToFields = (properties) =>
  Object.entries(properties).reduce(
    (carry, [name, { type, description }]) => [
      ...carry,
      { name, type, description },
    ],
    []
  );

export { formDataToOptions, extraPropertiesToFields };
