import i18n from "i18next";
import k from "./../../i18n/keys";
import React from "react";
import { Link } from "react-router-dom";
import Menu from "@material-ui/icons/Menu";
import { connect } from "react-redux";
import { toggleSidebar } from "../../actions/action_utils";

class NavbarToggle extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.toggleSidebar();
    }

    render() {
        return (
            <nav className="nav">
                <Link
                    to="#"
                    onClick={this.handleClick}
                    className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center"
                >
                    <Menu />
                </Link>
            </nav>
        );
    }
}

function mapStateToProps(state) {
    return { state };
}

const mapDispatchToProps = (dispatch) => ({
    toggleSidebar: () => dispatch(toggleSidebar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavbarToggle);
