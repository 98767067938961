import i18n from 'i18next';import k from "./../../i18n/keys";import React, { Component } from 'react';
import 'react-notifications/dist/react-notifications.css';
import 'react-notifications/dist/react-notifications.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "shards-react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { confirmAlert } from "react-confirm-alert";
import InvoicePreviewModal from "./InvoicePreviewModal";
import HonkioAPI from "../../middlewares/HonkioAPI";
// import {getInvite} from "../../actions/inviteActions";


const Circular = () =>
// we need to add some padding to circular progress to keep it from activating our scrollbar
<div style={{ padding: '24px' }}>
    <CircularProgress size={75} />
  </div>;

// Fixme unify with userlistcomponent

class InvoiceStatusItemComponent extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      open: false,
      'previewModal': { visible: false } };

  }

  toggle() {
    this.setState(prevState => {
      return { open: !prevState.open };
    });
  }

  onPreviewRequestClose = () => {};

  hidePreviewModal = () => {
    this.setState({ 'previewModal': { ...this.state.previewModal, visible: false } });
    this.onPreviewRequestClose();
  };
  showPreviewModal = () => {
    this.setState({ 'previewModal': { ...this.state.previewModal, visible: true } });
  };

  confirmChange(item, status) {
    let that = this;
    const options = {
      title: 'Confirm',
      message: '',
      childrenElement: () =>
      <p>
                    {i18n.t(k.ARE_YOU_SURE_WANT_TO_CHANGE_ST)} <b>{status}</b>{i18n.t(k._4)}
                </p>,
      buttons: [
      {
        label: i18n.t(k.YES),
        onClick: () => {
          if (status === 'sent') {
            that.showPreviewModal();
          } else {
            let markPaid = async function () {
              let response = await HonkioAPI().userShop.userFetch('merchantpaymentinvoiceset', {
                id: that.props.item.id, status: i18n.t(k.PAID) });

              window.location.href = `/accounting?type=invoice&building=${that.props.item.custom_fields.building}`;
            };
            markPaid();
          }
          // this.props.onInvoiceStatusChange(item, 'sent')
        } },

      {
        label: i18n.t(k.NO), onClick: () => {
        } }],


      closeOnEscape: true,
      closeOnClickOutside: true };

    confirmAlert(options);
  }
  render() {
    const mystyle = {
      position: 'relative!important' };

    // let that = this;
    // let disallowedStatuses=[]
    // let statuses = ["pending", "paid", "part_paid", "over_paid", "error"]
    // let statusItems = statuses.map((item,i) =><DropdownItem
    //     disabled={disallowedStatuses.indexOf(item) !== -1}
    //       // onClick={() => {this.changeStatus(item)}}
    // >{item}</DropdownItem>)
    //
    // console.error('statusItems')
    // console.error(statusItems)
    let button;
    if (this.props.item.type !== 'invoice') {
      return null;
    }
    switch (this.props.item.status) {
      case 'pending':
        button = <Button onClick={() => {
          this.confirmChange(this.props.item, 'sent');
        }}>{i18n.t(k.SEND)}</Button>;
        break;
      case 'sent':
        button = <Button theme="success" onClick={() => {
          this.confirmChange(this.props.item, 'paid');
        }}>{i18n.t(k.PAID1)}</Button>;
        break;
      // default:
      //     button = <span>this.props.item.status</span>
    }
    return <div>
        {button}
         <InvoicePreviewModal
      visible={this.state.previewModal.visible}
      open={this.hidePreviewModal}
      toggle={this.hidePreviewModal}
      invoice={this.props.item}
      onRequestClose={this.onPreviewRequestClose} />

    </div>;

  }}



export default InvoiceStatusItemComponent;
