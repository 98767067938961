import i18n from 'i18next';import k from "./../../../i18n/keys";import React, { Component } from 'react';
import { Button, Form, FormGroup, FormInput, Modal, ModalBody, ModalHeader } from "shards-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import Select from "react-select";
import DatePickerComponent from "../../ui_utils/DatePickerComponent";

import moment from "moment";
import "moment/locale/fi";

const ranges = [
{
  name: i18n.t(k.CURRENT_MONTH),
  start_date: () => {
    return moment().startOf('day').toDate();
  },
  end_date: () => {
    return moment().endOf('month').toDate();
  } },

{
  name: i18n.t(k.TWO_MONTH),
  start_date: () => {
    return moment().startOf('day').toDate();
  },
  end_date: () => {
    return moment().add(1, 'month').endOf('month').toDate();
  } },

{
  name: i18n.t(k.SIX_MONTH),
  start_date: () => {
    return moment().startOf('day').toDate();
  },
  end_date: () => {
    return moment().add(6, 'month').endOf('month').toDate();
  } }];


class ProjectEditModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'project': {} };

    this.save = this.save.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.project !== null) {
      this.setState({ 'project': nextProps.project });
    }
    if (nextProps.buildingId !== null) {
      this.setState({ 'buildingId': nextProps.buildingId });
    }
  }

  save() {
    let that = this;
    this.setState({ 'loading': true });
    let saveProject = async function () {
      let params = {
        'name': that.state.project.name,
        'description': that.state.project.description,
        'budget': that.state.project.budget,
        'building_id': that.state.buildingId,
        'start_date': that.state.project.start_date,
        'end_date': that.state.project.end_date };

      if (that.state.project.id) {
        params['id'] = that.state.project.id;
      }

      let response = await HonkioAPI().userShop.userFetch('merchantbuildingprojectset', params);
      // fixme check status
      that.setState({ 'loading': false });
      that.props.onProjectSaved();
    };
    if (this.state.buildingId) {
      saveProject();
    }
  }

  handleChange(e) {
    this.setState({ 'project': { ...this.state.project, [e.target.id]: e.target.value } });
  }

  render() {
    // console.log(this.props);
    // console.log(this.state);
    let saveButton = <Button onClick={this.save}>{i18n.t(k.SAVE)}</Button>;
    if (this.state.loading) {
      saveButton = <CircularProgress size={45} />;
    }
    return (
      <Modal open={this.props.visible}
      toggle={this.props.toggle}
      onRequestClose={this.props.onRequestClose}
      className="w-100 modal-lg modal-dialog-centered modal-dialog-scrollable">
                <ModalHeader>{this.props.project === null ? i18n.t(k.CREATE_PROJECT) : i18n.t(k.EDIT_PROJECT)}</ModalHeader>
                <ModalBody>
                    <Form>
                        <DatePickerComponent
            ranges={ranges}
            endSelector={true}
            handleChangeEvent={
            e => {
              e.target['id'] = e.target.name;
              switch (e.target.name) {
                case 'start_date':
                  this.handleChange(e);
                  break;
                case 'end_date':
                  this.handleChange(e);
                  break;}

            }} />


                        <FormGroup>
                            <label htmlFor="name">{i18n.t(k.NAME)}</label>
                            <FormInput id="name"
              value={this.state.project.name}
              onChange={e => this.handleChange(e)} />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="description">{i18n.t(k.DESCRIPTION)}</label>
                            <FormInput id="description"
              value={this.state.project.description}
              onChange={e => this.handleChange(e)} />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="budget">{i18n.t(k.BUDGET1)}</label>
                            <FormInput id="budget"
              value={this.state.project.budget}
              onChange={e => this.handleChange(e)} />
                        </FormGroup>

                        {saveButton}
                    </Form>
                </ModalBody>
            </Modal>);

  }}


export default ProjectEditModalComponent;