import React, { Component } from "react";
import { DashboardErrorBoundary } from "./DashboardItemErrorBoundary";
import DashboardComponent from "./DashboardComponent";
import HonkioAPI from "../../middlewares/HonkioAPI";

class DashboardSafeComponent extends Component {
    componentDidMount() {
        let that = this;
        const requestTou = async () => {
            let data = await HonkioAPI().mainShop.userFetch(
                "usermerchantlist",
                {}
            );
            if (data.merchants) {
                localStorage.setItem(
                    "merchants",
                    JSON.stringify(data.merchants)
                );
            }
        };
        requestTou();
    }
    render() {
        return (
            <DashboardErrorBoundary>
                <DashboardComponent />
            </DashboardErrorBoundary>
        );
    }
}

export default DashboardSafeComponent;
