import i18n from 'i18next';import k from "./../../../i18n/keys";import React, { Component } from 'react';
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Button, Form, FormGroup } from "shards-react";
import { CONSUMER_URL } from "../../../config";
import CheckIcon from "@material-ui/icons/Check";
import FormInputValidation from "../../ui_utils/FormInputValidationComponent";
import Select from "react-select";
const Circular = () =>
// we need to add some padding to circular progress to keep it from activating our scrollbar
<div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>;


class WidgetTypeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      name: '',
      type: 'sensor'
    };


  }

  onSelect = () => {
    if (this.state.type === 'sensor') {
      this.props.goToStep(2);
    } else {
      this.props.goToStep(4);
    }
  };

  render() {
    return (
      <div>
                <FormGroup>
                <label htmlFor="type">{i18n.t(k.TYPE)}</label>
                <Select id="type" options={[
          { 'value': 'sensor', label: i18n.t(k.IOT_SENSOR) },
          { 'value': 'generic', label: i18n.t(k.GENERIC) }]}
          defaultValue={{ 'value': 'sensor', label: i18n.t(k.IOT_SENSOR) }} onChange={e => {
            this.setState({
              'type': e.value });

          }} />
                </FormGroup>
                <Button theme="primary" className="float-right" onClick={this.onSelect}>{i18n.t(k.NEXT)}</Button>
            </div>);

  }}


function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = dispatch => ({});


export default connect(mapStateToProps, mapDispatchToProps)(WidgetTypeComponent);