import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { useEffect, useState, useContext } from "react";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    Work,
    Add,
    Edit,
    DeleteForever,
    Description,
    EuroSymbol,
    add_ic_call,
    AddAPhoto,
} from "@material-ui/icons";
import { Button } from "shards-react";
import "react-confirm-alert/src/react-confirm-alert.css";
import NotificationManager from "react-notifications/lib/NotificationManager";

import PageableDatatablesComponent from "../ui_utils/PageableDatatablesComponent";
import { ASSET_TYPE } from "./../../actions/api/assets/config";
import { getAssetListWithPages } from "./../../actions/api/assets/getAssetList";
import { STORAGE } from "./../../actions/auth/oauthActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AssetDeleteModal from "./../assets/AssetDeleteModal";
import { Link } from "react-router-dom";
import ListIcon from "@material-ui/icons/List";
import { ReactComponent as SafetyVideoIcon } from "../../img/icons/video_icon.svg";
import getAssetTypeList from "./../../actions/api/assetTypes/getAssetTypeList";
import VideosListModal from "../assets/subcomponents/VideosListModal";
import { APP_ID } from "./../../config";
import { useDispatch, useSelector } from "react-redux";
import {
    openSafetyVideoModal,
    closeSafetyVideoModal,
} from "../../actions/action_utils";
import GroupableDatatable from "../directories/GroupableDatatable";
import {
    addBreadcrumb,
    removeBreadcrumb,
    setBreadcrumbs,
    gotoBreadcrumb,
} from "../../actions/directory";
// import bg_no_photo from './../../image/bg_no_photo.jpg';
const Circular = () => (
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

const PAGE_ITEMS_COUNT = 10;

const LandplotList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [assetList, setAssetList] = useState([]);
    const [typesList, setTypesList] = useState([]);
    const [total, setTotal] = useState();
    const [showConfirmRemoveAsset, setShowConfirmRemoveAsset] = useState({
        visible: false,
        asset: undefined,
    });
    const handleShowConfirmRemoveAsset = (asset) =>
        setShowConfirmRemoveAsset({ visible: true, asset: asset });
    const handleCloseConfirmRemoveAsset = () => {
        setShowConfirmRemoveAsset({ visible: false, asset: undefined });
    };
    let isMounted = true;

    useEffect(() => {
        // Reset breadcrumbs for DirectoryView component
        dispatch(setBreadcrumbs([]));

        // Fetch data
        fetchAssetList();
        fetchChildrenTypes();
        return () => {
            isMounted = false;
        };
    }, []);

    // Update page data when navbar merchant changed
    const refreshAssetList = () => {
        console.log("merchant_name changed");
        fetchAssetList();
        fetchChildrenTypes();
    };
    useEffect(() => {
        window.addEventListener("merchant_name", refreshAssetList);
        return () => {
            window.removeEventListener("merchant_name", refreshAssetList);
        };
    }, []);

    const fetchAssetList = async (page) => {
        setLoading(true);
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    setAssetList(data.assets);
                    setTotal(data.total);
                    setLoading(false);
                } else {
                    NotificationManager.error("Error");
                    setAssetList();
                    setLoading(false);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setAssetList();
            setLoading(false);
        };
        getAssetListWithPages(
            {
                type: ASSET_TYPE.LANDPLOT,
                page: page,
                count: PAGE_ITEMS_COUNT,
                merchant: localStorage.getItem(STORAGE.MERCHANT_ID),
            },
            errorHandler
        )
            .then((data) => {
                return resultHandler(data);
            })
            .catch(console.error);
    };

    const showConfirmRemoveDialog = (asset) => {
        handleShowConfirmRemoveAsset(asset);
    };

    const fetchChildrenTypes = async () => {
        setLoading(true);
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    setTypesList(data.asset_types);
                    setLoading(false);
                } else {
                    NotificationManager.error("Error");
                    setTypesList();
                    setLoading(false);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setTypesList();
            setLoading(false);
        };
        getAssetTypeList(
            {
                query_application: APP_ID,
                // query_parent: type,
            },
            errorHandler
        )
            .then((data) => {
                return resultHandler(data);
            })
            .catch((error) => {
                console.log("getAssetTypeList: modal: error", error);
            });
    };

    const onAssetRemoved = () => {
        fetchAssetList();
        handleCloseConfirmRemoveAsset();
    };

    const handleAttachSafetyVideo = (e, selected_asset) => {
        e.preventDefault();
        const asset = typesList.filter((itm) => itm.name === "safety_video")[0];

        const data = {
            // ...asset,
            type: ASSET_TYPE.BUILDING,
            description: asset.description,
            name: asset.name,
            properties: { ...asset.properties },
            parent: selected_asset._id,
        };

        dispatch(openSafetyVideoModal(data));
    };

    const columns = [
        {
            name: i18n.t(k.PHOTO),
            selector: "properties.street_view",
            cell: function (asset) {
                if (asset.properties?.street_view) {
                    return (
                        <img
                            alt="asd"
                            src={asset.properties.street_view}
                            width="100"
                            className="border-top border-bottom mb-2"
                        />
                    );
                } else {
                    return "no Photo";
                }
            },
        },
        {
            name: i18n.t(k.NAME),
            selector: "name",
            width: "20%",
            sortable: true,
            style: `cursor: pointer;`,
        },
        {
            name: i18n.t(k.DESCRIPTION),
            selector: "description",
            sortable: true,
            style: `cursor: pointer;`,
        },
        {
            name: i18n.t(k.ADDRESS),
            selector: "properties.address",
            sortable: true,
            style: `cursor: pointer;`,
        },
        {
            name: i18n.t(k.ACTION),
            selector: "action",
            cell: function (asset) {
                return (
                    <span>
                        <Link
                            title={i18n.t(k.PROPERTY_EDIT)}
                            id={"editTip" + asset._id}
                            to={`/assets/edit?asset=${asset._id}`}
                            className="pointer"
                        >
                            <Edit />
                        </Link>
                        <Link
                            title={i18n.t(k.DOCUMENTS)}
                            id="documentsTip"
                            to={`/asset/documents?id=${asset._id}`}
                            className="pointer"
                        >
                            <Description />
                        </Link>
                        <Link
                            title={i18n.t(k.AGREEMENTS)}
                            id="agreementsTip"
                            to={`/landplotAgreements/${asset._id}`}
                            className="pointer"
                        >
                            <i className="fas fa-handshake fa-lg"></i>
                        </Link>
                        <Link
                            title={i18n.t(k.BUILDING_PROJECTS)}
                            id="projectsTip"
                            to={`/laplotprojects/${asset._id}`}
                            className="pointer"
                        >
                            <Work />
                        </Link>
                        {/* <Link
                            title={i18n.t(k.COSTS)}
                            id="costsTip"
                            to={`/costs/${asset._id}`}
                            className="pointer"
                        >
                            <EuroSymbol />
                        </Link>
                        <Link
                            title="videos"
                            id="videosTip"
                            to={`/videos`}
                            className="pointer"
                        >
                            <AddAPhoto />
                        </Link>
                        <Link
                            title="Safety video"
                            id="saftyVideoTip"
                            onClick={(e) => handleAttachSafetyVideo(e, asset)}
                            className="pointer"
                            to="#"
                        >
                            <SafetyVideoIcon
                                style={{
                                    width: "1rem",
                                    height: "1rem",
                                    margin: "5px",
                                }}
                            />
                        </Link>

                        <Link
                            title={i18n.t(k.GROUPS)}
                            id="groupsTip"
                            to={`/assetGroups?landplot=${asset._id}&landplotname=${asset.name}`}
                            className="pointer"
                        >
                            <i className="fa fa-object-group"></i>
                        </Link> */}
                        <Link
                            title={i18n.t(k.DELETE)}
                            id="deleteTip"
                            to="#"
                            className="pointer"
                            onClick={() => showConfirmRemoveDialog(asset)}
                        >
                            <DeleteForever />
                        </Link>
                    </span>
                );
            },
        },
    ];

    const onRowClicked = (asset) => {
        // Use specific list component for parent's children display
        // history.push(`/plots?parent=${asset._id}`);

        // Set first breadcrumb to clicked Property
        dispatch(setBreadcrumbs([asset]));
        // Use one component to view all assets of the parent (directory-like)
        history.push(`/directory/${asset._id}`);
    };
    return (
        <div>
            <br />
            <CardWrapperComponent
                header={true}
                footer={true}
                card_title={i18n.t(k.PROPERTIES_LIST)}
            >
                <Button
                    className="mr-2"
                    theme="info"
                    onClick={() => {
                        history.push(
                            `/assets/create?type=${ASSET_TYPE.LANDPLOT}`
                        );
                    }}
                >
                    <Add />
                    {i18n.t(k.CREATE_PROPERTY)}
                </Button>
                {/* <Button
                    className="mr-2"
                    theme="info"
                    onClick={() => {
                        history.push(`/safetyTrack`);
                    }}
                >
                    <Add />
                    Add safety track
                </Button> */}
                <PageableDatatablesComponent
                    autoWidth={true}
                    columns={columns}
                    background="#ffffff00"
                    items={assetList}
                    loadItems={(page) => {
                        fetchAssetList(page);
                    }}
                    progressPending={loading}
                    paginationTotalRows={total}
                    paginationComponentOptions={{
                        rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                        rangeSeparatorText: i18n.t(k.ROWS_OF),
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                    }}
                    progressComponent={<Circular />}
                    paginationServer={true}
                    paginationPerPage={PAGE_ITEMS_COUNT}
                    paginationRowsPerPageOptions={[PAGE_ITEMS_COUNT]}
                    onRowClicked={onRowClicked}
                />
            </CardWrapperComponent>
            {showConfirmRemoveAsset.asset && (
                <AssetDeleteModal
                    show={showConfirmRemoveAsset.visible}
                    assetType={ASSET_TYPE.LANDPLOT}
                    onHide={() => handleCloseConfirmRemoveAsset()}
                    assetToRemove={showConfirmRemoveAsset.asset}
                    onAssetRemoved={onAssetRemoved}
                />
            )}
        </div>
    );
};

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LandplotList);
