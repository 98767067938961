import i18n from "i18next";
import k from "../../../i18n/keys";
import React from "react";
import { Link } from "react-router-dom";
import DeleteForever from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import ListIcon from "@material-ui/icons/List";
import WorkIcon from "@material-ui/icons/Work";
import AddIcon from "@material-ui/icons/Add";
import DescriptionIcon from "@material-ui/icons/Description";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import GroupIcon from "@material-ui/icons/Group";
import ApartmentIcon from "@material-ui/icons/Apartment";
import ListOfSensors from "@material-ui/icons/List";
import IotImg from "../../../img/icons/iot.png";
import { ReactComponent as SafetyVideoIcon } from "../../../img/icons/video_icon.svg";
import { NotificationManager } from "react-notifications";

const columnsKeeper = (type, actions) => {
    const columnsStorage = {
        landplot: [
            {
                name: i18n.t(k.PHOTO),
                selector: "properties.street_view",
                cell: function (asset) {
                    if (asset.properties?.street_view) {
                        return (
                            <img
                                alt="asd"
                                src={asset.properties.street_view}
                                width="100"
                                className="border-top border-bottom mb-2"
                            />
                        );
                    } else {
                        return "no Photo";
                    }
                },
            },
            {
                name: i18n.t(k.NAME),
                selector: "name",
                width: "20%",
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.DESCRIPTION),
                selector: "description",
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.ADDRESS),
                selector: "properties.address",
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.ACTION),
                selector: "action",
                cell: function (asset) {
                    return (
                        <span>
                            <Link
                                title={i18n.t(k.PROPERTY_EDIT)}
                                id={"editTip" + asset._id}
                                to={`/assets/edit?asset=${asset._id}`}
                                className="pointer"
                            >
                                <EditIcon />
                            </Link>
                            <Link
                                title={i18n.t(k.DOCUMENTS)}
                                id="documentsTip"
                                to={`/asset/documents?id=${asset._id}`}
                                className="pointer"
                            >
                                <DescriptionIcon />
                            </Link>
                            <Link
                                title={i18n.t(k.AGREEMENTS)}
                                id="agreementsTip"
                                to={`/landplotAgreements/${asset._id}`}
                                className="pointer"
                            >
                                <i className="fas fa-handshake fa-lg"></i>
                            </Link>
                            <Link
                                title={i18n.t(k.BUILDING_PROJECTS)}
                                id="projectsTip"
                                to={`/laplotprojects/${asset._id}`}
                                className="pointer"
                            >
                                <WorkIcon />
                            </Link>
                            <Link
                                title={i18n.t(k.COSTS)}
                                id="costsTip"
                                to={`/costs/${asset._id}`}
                                className="pointer"
                            >
                                <EuroSymbolIcon />
                            </Link>
                            <Link
                                title="videos"
                                id="videosTip"
                                to={`/videos`}
                                className="pointer"
                            >
                                <AddAPhotoIcon />
                            </Link>
                            <Link
                                title="Assets list"
                                id="sensorTip2"
                                to={`/sensor/list?parent=${asset._id}&property_type=${asset.type}`}
                                className="pointer"
                            >
                                <ListIcon />
                            </Link>
                            <Link
                                title="Safety video"
                                id="saftyVideoTip"
                                onClick={(e) => {
                                    e.preventDefault();
                                    actions.handleAttachSafetyVideo(asset);
                                }}
                                className="pointer"
                                to="#"
                            >
                                <SafetyVideoIcon
                                    style={{ width: "1rem", height: "1rem" }}
                                />
                            </Link>

                            <Link
                                title={i18n.t(k.GROUPS)}
                                id="groupsTip"
                                to={`/assetGroups?landplot=${asset._id}&landplotname=${asset.name}`}
                                className="pointer"
                            >
                                <i className="fa fa-object-group"></i>
                            </Link>
                            <Link
                                title={i18n.t(k.DELETE)}
                                id="deleteTip"
                                to="#"
                                className="pointer"
                                onClick={() =>
                                    actions.showConfirmRemoveDialog(asset)
                                }
                            >
                                <DeleteForever />
                            </Link>
                            <Link
                                title={i18n.t(k.DELETE)}
                                id="directory"
                                to={`/directory/${asset._id}`}
                                className="pointer"
                            >
                                <ArrowForwardIcon /> Dir
                            </Link>
                        </span>
                    );
                },
            },
        ],
        plot: [
            {
                name: i18n.t(k.NAME),
                selector: "name",
                grow: 2,
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.ADDRESS),
                selector: "properties.address",
                sortable: true,
                grow: 1,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.PROPERTY_MANAGER2),
                grow: 1,
                selector: "properties.asset_manager",
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.ACTION),
                selector: "action",
                grow: 1,
                cell: function (asset) {
                    return (
                        <span>
                            <Link
                                title="Safety video"
                                id="saftyVideoTip"
                                onClick={(e) => {
                                    e.preventDefault();
                                    actions.handleAttachSafetyVideo(asset);
                                }}
                                className="pointer"
                                to="#"
                            >
                                <SafetyVideoIcon
                                    style={{ width: "1rem", height: "1rem" }}
                                />
                            </Link>
                            <Link
                                title={i18n.t(k.EDIT)}
                                id={"editTip" + asset._id}
                                to={`/assets/edit?asset=${asset._id}`}
                                className="pointer"
                            >
                                <EditIcon />
                            </Link>
                            <Link
                                title={i18n.t(k.DOCUMENTS)}
                                id="documentsTip"
                                to={`/asset/documents?id=${asset._id}`}
                                className="pointer"
                            >
                                <DescriptionIcon />
                            </Link>
                            <Link
                                title={i18n.t(k.DELETE)}
                                id="deleteTip"
                                to="#"
                                className="pointer"
                                onClick={(e) => {
                                    e.preventDefault();
                                    actions.showConfirmRemoveDialog(asset);
                                }}
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ],
        building: [
            {
                name: i18n.t(k.NAME),
                selector: "name",
                grow: 2,
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.ADDRESS),
                selector: "properties.address",
                sortable: true,
                grow: 2,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.ACTION),
                selector: "action",
                grow: 1,
                cell: function (asset) {
                    return (
                        <span>
                            <Link
                                title={i18n.t(k.BUILDING_EDIT)}
                                id={"editTip" + asset._id}
                                to={`/assets/edit?asset=${asset._id}`}
                                className="pointer"
                            >
                                <EditIcon />
                            </Link>
                            <Link
                                title={i18n.t(k.DOCUMENTS)}
                                id="documentsTip"
                                to={`/asset/documents?id=${asset._id}`}
                                className="pointer"
                            >
                                <DescriptionIcon />
                            </Link>
                            <Link
                                title={i18n.t(k.AGREEMENTS)}
                                id="projectsTip"
                                to={`/agreements/${asset._id}`}
                                className="pointer"
                            >
                                <i className="fas fa-handshake fa-lg"></i>
                            </Link>
                            <Link
                                title={i18n.t(k.BUILDING_PROJECTS)}
                                id="projectsTip"
                                to={`/projects/${asset._id}`}
                                className="pointer"
                            >
                                <WorkIcon />
                            </Link>
                            <Link
                                title={i18n.t(k.BUILDING_USERS)}
                                id="usersTip"
                                to={`/buildingUsers/${asset._id}`}
                                className="pointer"
                            >
                                <GroupIcon />
                            </Link>
                            <Link
                                title={i18n.t(k.BUILDING_SENSORS)}
                                id="sensorsTip"
                                to={`/sensors/${asset._id}`}
                                className="pointer"
                            >
                                <ApartmentIcon />
                            </Link>
                            <Link
                                title={i18n.t(k.IOT_SENSOR)}
                                id="sensorTip"
                                // href={`sensor/list?parent=${asset._id}&property_type=building`}
                                to="#"
                                onClick={(e) =>
                                    actions.handleShowConfirmLinkAssets(asset)
                                }
                                className="pointer"
                            >
                                <img
                                    src={IotImg}
                                    style={{
                                        width: "1rem",
                                        height: "1rem",
                                    }}
                                />
                            </Link>
                            <Link
                                title={"Safety video"}
                                id="saftyVideoTip"
                                onClick={(e) => {
                                    e.preventDefault();
                                    actions.handleAttachSafetyVideo(asset);
                                }}
                                className="pointer"
                                to="#"
                            >
                                <SafetyVideoIcon
                                    style={{ width: "1rem", height: "1rem" }}
                                />
                            </Link>
                            <Link
                                title={i18n.t(k.DELETE)}
                                id="deleteTip"
                                to="#"
                                className="pointer"
                                onClick={() => {
                                    actions.showConfirmRemoveDialog(asset);
                                }}
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ],
        floor: [
            /*         {
            name: i18n.t(k.THUMBNAIL),
            selector: '',
            sortable: false,
            grow: 1,
            cell: function (d) {
                return <img src={thumbnailUrl(d.properties)}
                    style={{
                        width: 50,  //its same to '20%' of device width
                        aspectRatio: 1, // <-- this
                        resizeMode: 'contain', //optional
                    }}
                />
            }
        }, */
            {
                name: i18n.t(k.NAME),
                selector: "name",
                sortable: true,
                style: `cursor: pointer;`,
                grow: 2,
            },
            {
                name: i18n.t(k.DESCRIPTION),
                selector: "description",
                sortable: true,
                style: `cursor: pointer;`,
                grow: 2,
            },
            {
                name: i18n.t(k.ACTION),
                selector: "action",
                grow: 1,
                cell: function (asset) {
                    return (
                        <span>
                            <Link
                                title={i18n.t(k.FLOORPLAN_EDIT)}
                                id="editTip"
                                to={`/assets/edit?asset=${asset._id}`}
                                className="pointer"
                            >
                                <EditIcon />
                            </Link>
                            <Link
                                title={i18n.t(k.DOCUMENTS)}
                                id="documentsTip"
                                to={`/asset/documents?id=${asset._id}`}
                                className="pointer"
                            >
                                <DescriptionIcon />
                            </Link>
                            <Link
                                title={i18n.t(k.IOT_SENSOR)}
                                id="sensorTip"
                                to="#"
                                onClick={(e) =>
                                    actions.handleShowConfirmLinkAssets(asset)
                                }
                                className="pointer"
                            >
                                <img
                                    src={IotImg}
                                    style={{
                                        width: "1rem",
                                        height: "1rem",
                                    }}
                                />
                            </Link>
                            <Link
                                title="Safety video"
                                id="saftyVideoTip"
                                onClick={(e) => {
                                    e.preventDefault();
                                    actions.handleAttachSafetyVideo(asset);
                                }}
                                className="pointer"
                                to="#"
                            >
                                <SafetyVideoIcon
                                    style={{ width: "1rem", height: "1rem" }}
                                />
                            </Link>
                            <Link
                                title={i18n.t(k.DELETE)}
                                id="deleteTip"
                                to="#"
                                className="pointer"
                                onClick={() =>
                                    actions.showConfirmRemoveDialog(asset)
                                }
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ],
        room: [
            {
                name: i18n.t(k.NAME),
                selector: "name",
                sortable: true,
                grow: 2,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.DESCRIPTION),
                selector: "description",
                sortable: true,
                grow: 2,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.RESERVABLE),
                selector: (d) => (
                    <span>
                        {d.properties?.reservable
                            ? i18n.t(k.TRUE)
                            : i18n.t(k.FALSE)}
                    </span>
                ),
                sortable: true,
                grow: 0,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.MARKET_RENT_PRICE),
                selector: (d) => (
                    <span>{d.properties?.rules?.market_rent || "N/A"}</span>
                ),
                sortable: true,
                grow: 0,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.ACTION),
                selector: "action",
                grow: 1,
                cell: function (asset) {
                    return (
                        <span>
                            <Link
                                title={i18n.t(k.ROOM_EDIT)}
                                id={"editTip" + asset._id}
                                to={`/assets/edit?asset=${asset._id}`}
                                className="pointer"
                            >
                                <EditIcon />
                            </Link>
                            <Link
                                title={i18n.t(k.DOCUMENTS)}
                                id="documentsTip"
                                to={`/asset/documents?id=${asset._id}`}
                                className="pointer"
                            >
                                <DescriptionIcon />
                            </Link>
                            <Link
                                title={i18n.t(k.IOT_SENSOR)}
                                id="sensorTip"
                                // to={`sensor/list?parent=${asset._id}&property_type=building`}
                                to="#"
                                onClick={(e) =>
                                    actions.handleShowConfirmLinkAssets(asset)
                                }
                                className="pointer"
                            >
                                <img
                                    src={IotImg}
                                    style={{
                                        width: "1rem",
                                        height: "1rem",
                                    }}
                                />
                            </Link>
                            <Link
                                title="Safety video"
                                id="saftyVideoTip"
                                onClick={(e) => {
                                    e.preventDefault();
                                    actions.handleAttachSafetyVideo(asset);
                                }}
                                className="pointer"
                                to="#"
                            >
                                <SafetyVideoIcon
                                    style={{ width: "1rem", height: "1rem" }}
                                />
                            </Link>
                            <Link
                                title={i18n.t(k.DELETE)}
                                id="deleteTip"
                                to="#"
                                className="pointer"
                                onClick={() => {
                                    actions.showConfirmRemoveDialog(asset);
                                }}
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ],
        parking_place: [
            {
                name: i18n.t(k.NAME),
                selector: "name",
                sortable: true,
                autoWidth: true,
                grow: 2,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.DESCRIPTION),
                selector: "description",
                sortable: true,
                autoWidth: true,
                grow: 2,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.ACTION),
                selector: "action",
                autoWidth: true,
                grow: 1,
                cell: function (asset) {
                    return (
                        <span>
                            <Link
                                title={i18n.t(k.ROOM_EDIT)}
                                id={"editTip" + asset._id}
                                to={`/assets/edit?asset=${asset._id}`}
                                className="pointer"
                            >
                                <EditIcon />
                            </Link>
                            <Link
                                title={i18n.t(k.DOCUMENTS)}
                                id="documentsTip"
                                to={`/asset/documents?id=${asset._id}`}
                                className="pointer"
                            >
                                <DescriptionIcon />
                            </Link>
                            <Link
                                title={i18n.t(k.IOT_SENSOR)}
                                id="sensorTip"
                                // to={`sensor/list?parent=${asset._id}&property_type=building`}
                                to="#"
                                onClick={(e) =>
                                    actions.handleShowConfirmLinkAssets(asset)
                                }
                                className="pointer"
                            >
                                <img
                                    src={IotImg}
                                    style={{
                                        width: "1rem",
                                        height: "1rem",
                                    }}
                                />
                            </Link>
                            <Link
                                title="Safety video"
                                id="saftyVideoTip"
                                onClick={(e) => {
                                    e.preventDefault();
                                    actions.handleAttachSafetyVideo(asset);
                                }}
                                className="pointer"
                                to="#"
                            >
                                <SafetyVideoIcon
                                    style={{ width: "1rem", height: "1rem" }}
                                />
                            </Link>
                            <Link
                                title={i18n.t(k.DELETE)}
                                id="deleteTip"
                                to="#"
                                className="pointer"
                                onClick={() => {
                                    actions.showConfirmRemoveDialog(asset);
                                }}
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ],
        safety_video: [
            {
                name: i18n.t(k.NAME),
                selector: "name",
                grow: 2,
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: "Video url",
                selector: "properties.video_url",
                grow: 2,
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.ACTION),
                selector: "action",
                grow: 1,
                cell: function (asset) {
                    return (
                        <span>
                            <Link
                                title="Safety video"
                                id="saftyVideoTip"
                                onClick={(e) => {
                                    e.preventDefault();
                                    actions.handleAttachSafetyVideo(asset);
                                }}
                                className="pointer"
                                to="#"
                            >
                                <SafetyVideoIcon
                                    style={{ width: "1rem", height: "1rem" }}
                                />
                            </Link>
                            <Link
                                title={i18n.t(k.EDIT)}
                                id={"editTip" + asset._id}
                                to={`/assets/edit?asset=${asset._id}`}
                                className="pointer"
                            >
                                <EditIcon />
                            </Link>
                            <Link
                                title={i18n.t(k.DOCUMENTS)}
                                id="documentsTip"
                                to={`/asset/documents?id=${asset._id}`}
                                className="pointer"
                            >
                                <DescriptionIcon />
                            </Link>
                            <Link
                                title={i18n.t(k.DELETE)}
                                id="deleteTip"
                                to="#"
                                className="pointer"
                                onClick={(e) => {
                                    e.preventDefault();
                                    actions.showConfirmRemoveDialog(asset);
                                }}
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ],
        default: [
            {
                name: i18n.t(k.NAME),
                selector: "name",
                grow: 4,
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.ACTION),
                selector: "action",
                grow: 1,
                cell: function (asset) {
                    return (
                        <span>
                            <Link
                                title="Safety video"
                                id="saftyVideoTip"
                                onClick={(e) => {
                                    e.preventDefault();
                                    actions.handleAttachSafetyVideo(asset);
                                }}
                                className="pointer"
                                to="#"
                            >
                                <SafetyVideoIcon
                                    style={{ width: "1rem", height: "1rem" }}
                                />
                            </Link>
                            <Link
                                title={i18n.t(k.EDIT)}
                                id={"editTip" + asset._id}
                                to={`/assets/edit?asset=${asset._id}`}
                                className="pointer"
                            >
                                <EditIcon />
                            </Link>
                            <Link
                                title={i18n.t(k.DOCUMENTS)}
                                id="documentsTip"
                                to={`/asset/documents?id=${asset._id}`}
                                className="pointer"
                            >
                                <DescriptionIcon />
                            </Link>
                            <Link
                                title={i18n.t(k.DELETE)}
                                id="deleteTip"
                                to="#"
                                className="pointer"
                                onClick={(e) => {
                                    e.preventDefault();
                                    actions.showConfirmRemoveDialog(asset);
                                }}
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ],
    };
    return columnsStorage[type];
};

// Here actions contain additional functions for actions
const defaultActions = {
    handleAttachSafetyVideo: (asset) => {
        NotificationManager.error(
            `You need to pass "handleAttachSafetyVideo" as field for actions object!`
        );
    },
    showConfirmRemoveDialog: (asset) => {
        NotificationManager.error(
            `You need to pass "showConfirmRemoveDialog" as field for actions object!`
        );
    },
    handleShowConfirmLinkAssets: (asset) => {
        NotificationManager.error(
            `You need to pass "handleShowConfirmLinkAssets" as field for actions object!`
        );
    },
};

const getColumnsForType = (type, actions = defaultActions) => {
    switch (type) {
        case "landplot":
            return columnsKeeper("landplot", actions);
        case "plot":
            return columnsKeeper("plot", actions);
        case "building":
            return columnsKeeper("building", actions);
        case "floor":
            return columnsKeeper("floor", actions);
        case "room":
            return columnsKeeper("room", actions);
        case "parking_place":
            return columnsKeeper("parking_place", actions);
        case "safety_video":
            return columnsKeeper("safety_video", actions);
        default:
            return columnsKeeper("default", actions);
    }
};

export default getColumnsForType;

// Link to list of sensors
{
    /* <Link
    title={i18n.t(k.IOT_SENSOR)}
    id="sensorTip2"
    to={`/sensor/list?parent=${asset._id}&property_type=building`}
    className="pointer"
>
    <ListOfSensors />
</Link> */
}
