import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { Component } from "react";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import HonkioAPI from "../../middlewares/HonkioAPI";
import { FormSelect } from "shards-react";
import AddIcon from "@material-ui/icons/Add";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DebtEditModalComponent from "./subcomponents/DebtEditModalComponent";
import AssetEditModalComponent from "./subcomponents/AssetEditModalComponent";
import Select from "react-select";
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";
import * as qs from "qs";
import InvoicesDatatablesComponent from "./subcomponents/InvoicesDatatablesComponent";
import DebtsDatatablesComponent from "./subcomponents/DebtsDatatablesComponent";
import AssetsDatatablesComponent from "./subcomponents/AssetsDatatablesComponent";
import SettingsIcon from "@material-ui/icons/Settings";
import { NotificationManager } from "react-notifications";
import PurchasesDatatablesComponent from "./subcomponents/PurchasesDatatablesComponent";

import moment from "moment";
import "moment/locale/fi";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

export const ClearLocalStorage = () => {
    localStorage.removeItem("total_amount");
    localStorage.removeItem("start_date");
    localStorage.removeItem("end_date");
    localStorage.removeItem("number_of_month");
    localStorage.removeItem("diff");
};

class AccountingComponent extends Component {
    constructor(props) {
        super(props);
        let today = moment()
            .hours(0)
            .minutes(0)
            .seconds(0)
            .milliseconds(0)
            .startOf("month");
        this.state = {
            loading: true,
            hovering: false,
            modalDebt: {
                visible: false,
                debt: {},
            },

            building: {
                value: i18n.t(k.MERCHANT),
                label: localStorage.getItem("merchant_name"),
            },
            modalAsset: {
                visible: false,
                asset: {},
            },

            filter: {},
            currentDay: today.toDate(),
            currentDayText: today.format("MMMM YYYY"),
            open: null,
            type: "invoice",
        };

        this.hideModalDebt = this.hideModalDebt.bind(this);
        this.showModalDebt = this.showModalDebt.bind(this);
        this.toggleDebt = this.toggleDebt.bind(this);

        this.hideModalAsset = this.hideModalAsset.bind(this);
        this.showModalAsset = this.showModalAsset.bind(this);
        this.toggleAsset = this.toggleAsset.bind(this);

        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
        // this.rowSelected = this.rowSelected.bind(this)
        // this.loadInvoices = this.loadInvoices.bind(this)
        this.loadBuildings = this.loadBuildings.bind(this);
        this.prevMonth = this.prevMonth.bind(this);
        this.nextMonth = this.nextMonth.bind(this);

        this.handleChangeBuilding = this.handleChangeBuilding.bind(this);
    }

    hideModalDebt() {
        this.setState({
            modalDebt: { ...this.state.modalDebt, visible: false },
        });
        ClearLocalStorage();
    }

    showModalDebt(d) {
        if (this.state.building.value === "merchant") {
            NotificationManager.warning(
                i18n.t(k.LIABILITIES_MERCHANT_IN_PROGRESS),
                i18n.t(k.WARNINIG)
            );
        } else {
            this.setState({
                modalDebt: { ...this.state.modalDebt, visible: true, debt: d },
            });
            ClearLocalStorage();
        }
    }

    toggleDebt() {
        this.setState({
            modalDebt: {
                ...this.state.modalDebt,
                visible: !this.state.modalDebt.visible,
            },
        });
        ClearLocalStorage();
    }

    hideModalAsset() {
        this.setState({
            modalAsset: { ...this.state.modalAsset, visible: false },
        });
    }

    showModalAsset(d) {
        this.setState({
            modalAsset: { ...this.state.modalAsset, visible: true, asset: d },
        });
    }

    toggleAsset() {
        this.setState({
            modalAsset: {
                ...this.state.modalAsset,
                visible: !this.state.modalAsset.visible,
            },
        });
    }

    handleMouseOver() {
        // console.log('Mouse Over');
        this.setState({ hovering: true });
    }

    handleChangeBuilding(option) {
        this.setState({ building: option });
    }

    handleMouseOut() {
        // console.log('Mouse Out');
        this.setState({ hovering: false });
    }

    componentDidMount() {
        this.loadBuildings();
    }

    loadBuildings() {
        let that = this;
        let type = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
        }).type;
        let buildingArg = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
        }).building;

        let merchantgetbuildings = async function () {
            let response = await HonkioAPI().userShop.userFetch(
                "merchantbuildinglist",
                {}
            );
            let buildings = [...response.buildings]; // id: 'merchant', name: localStorage.getItem('merchant_name') },
            let newState = {
                buildings: buildings,
                loading: false,
                building: { value: buildings[0].id, label: buildings[0].name },
            };
            // alert(buildings[0].id)
            if (type) newState["type"] = type;
            that.setState(newState);
        };
        merchantgetbuildings();
    }

    toggle(id) {
        this.setState((prevState) => {
            return { open: id };
        });
    }

    nextMonth() {
        let tomorrow = moment(this.state.currentDay)
            .add(1, "month")
            .hours(0)
            .minutes(0)
            .seconds(0)
            .milliseconds(0);
        this.setState({
            currentDay: tomorrow.toDate(),
            currentDayText: tomorrow.format("MMMM YYYY"),
        });
        console.error(tomorrow.format("MMMM YYYY"));
        console.error(tomorrow.toDate());
    }

    prevMonth() {
        let tomorrow = moment(this.state.currentDay)
            .subtract(1, "month")
            .hours(0)
            .minutes(0)
            .seconds(0)
            .milliseconds(0);
        this.setState({
            currentDay: tomorrow.toDate(),
            currentDayText: tomorrow.format("MMMM YYYY"),
        });
    }

    render() {
        let that = this;

        let addDebtButton,
            addAssetButton,
            addInvoiceButton,
            addPaymentButton,
            main,
            dataTables;
        let options = [];
        let selectClass = i18n.t(k.D_BLOCK_MX_AUTO_W);
        let component;
        switch (this.state.type) {
            case "invoice":
                component = (
                    <InvoicesDatatablesComponent
                        type={this.state.type}
                        currentDay={this.state.currentDay}
                        buildingId={this.state.building.value}
                    />
                );
                break;
            case "payment":
                component = (
                    <PurchasesDatatablesComponent
                        type={this.state.type}
                        currentDay={this.state.currentDay}
                        buildingId={this.state.building.value}
                    />
                );
                break;
            case "debts":
                component = (
                    <DebtsDatatablesComponent
                        type={this.state.type}
                        push={this.props.history.push}
                        currentDay={this.state.currentDay}
                        buildingId={this.state.building.value}
                    />
                );
                break;
            case "assets":
                component = (
                    <AssetsDatatablesComponent
                        type={this.state.type}
                        currentDay={this.state.currentDay}
                        buildingId={this.state.building.value}
                    />
                );
                break;
            default:
                component = <CircularProgress size={75} />;
                break;
        }

        if (this.state.buildings) {
            let buildings = this.state.buildings;
            buildings.forEach(function (buildng) {
                options.push({ value: buildng.id, label: buildng.name });
            });
        }

        addDebtButton = (
            <label
                onClick={() => {
                    this.showModalDebt();
                }}
                className="btn btn-outline-primary"
            >
                <AddIcon />
                {i18n.t(k.ADD_LIABILITY)}
            </label>
        );

        addAssetButton = (
            <label
                onClick={() => {
                    this.showModalAsset();
                }}
                className="btn btn-outline-primary"
            >
                <AddIcon />
                {i18n.t(k.ADD_ASSET)}
            </label>
        );

        addInvoiceButton = (
            <label
                onClick={() => {
                    this.props.history.push("/invoiceEdit/new");
                }}
                className="btn btn-outline-primary"
            >
                <AddIcon />
                {i18n.t(k.ADD_INVOICE)}
            </label>
        );

        addPaymentButton = (
            <label
                onClick={() => {
                    this.props.history.push("/purchaseEdit/new");
                }}
                className="btn btn-outline-primary"
            >
                <AddIcon />
                {i18n.t(k.ADD_PURCHASE)}
            </label>
        );
        selectClass = i18n.t(k.W_FLOAT_RIGHT);
        // selectClass = "w-25 mt-3 float-right"

        main = (
            <div>
                <div className="d-flex mb-3">
                    <span className="my-auto p-1 border d-flex">
                        <ChevronLeftIcon
                            className="pointer mr-3 my-auto"
                            onClick={this.prevMonth}
                        />

                        <span className="m-1 text-large">
                            {this.state.currentDayText}
                        </span>
                        <ChevronRightIcon
                            onClick={this.nextMonth}
                            className="pointer ml-3 my-auto"
                        />
                    </span>
                    <div className="ml-auto">
                        {/*<label htmlFor="id">Type</label>*/}
                        <FormSelect
                            onChange={(e) => {
                                that.setState({ type: e.target.value });
                                // that.loadByType(e.target.value)
                            }}
                        >
                            <option value="none">
                                {i18n.t(k.SELECT_TYPE)}
                            </option>
                            <option
                                value="invoice"
                                selected={this.state.type === "invoice"}
                            >
                                {i18n.t(k.INVOICE)}
                            </option>
                            <option
                                value="payment"
                                selected={this.state.type === "payment"}
                            >
                                {i18n.t(k.PURCHASE_INVOICE)}
                            </option>
                            <option
                                value="debts"
                                selected={this.state.type === "debts"}
                            >
                                {i18n.t(k.LIABILITIES)}
                            </option>
                            <option
                                value="assets"
                                selected={this.state.type === "assets"}
                            >
                                {i18n.t(k.ASSETS)}
                            </option>
                        </FormSelect>
                    </div>
                </div>
                {component}
            </div>
        );
        let button_group;
        if (
            addPaymentButton ||
            addDebtButton ||
            addAssetButton ||
            addInvoiceButton
        ) {
            button_group = (
                <div
                    className="btn-group btn-group-sm btn-group-toggle d-flex mr-auto"
                    data-toggle="buttons"
                >
                    {addPaymentButton}
                    {addDebtButton}
                    {addAssetButton}
                    {addInvoiceButton}
                </div>
            );
        } else {
            button_group = <></>;
        }

        return (
            <div>
                <div className="container-fluid">
                    <PageHeaderComponent title={i18n.t(k.ACCOUNTING)} />
                </div>
                <CardWrapperComponent
                    header={true}
                    footer={true}
                    card_title={
                        this.state.building ? (
                            this.state.building.label
                        ) : (
                            <CircularProgress size={24} />
                        )
                    }
                    rightIcon={
                        <SettingsIcon
                            className="float-right pointer"
                            onClick={() => {
                                this.props.history.push("/merchantSettings");
                            }}
                        />
                    }
                >
                    <div className="d-flex mb-3">
                        {button_group}

                        {this.state.buildings ? (
                            <Select
                                className={selectClass}
                                id="building"
                                onChange={this.handleChangeBuilding}
                                defaultValue={options[0]}
                                options={options}
                            />
                        ) : (
                            <CircularProgress size={75} />
                        )}
                    </div>
                    {main}
                </CardWrapperComponent>

                <DebtEditModalComponent
                    visible={this.state.modalDebt.visible}
                    open={this.hideModalDebt}
                    toggle={this.toggleDebt}
                    onRequestClose={this.props.onRequestClose}
                    // debt={this.state.modalDebt.debt}
                    buildingId={this.state.building.value}
                    onDebtSaved={() => {
                        that.setState({ type: "" });
                        that.setState({ type: "debts" });
                        that.hideModalDebt();
                    }}
                />

                <AssetEditModalComponent
                    visible={this.state.modalAsset.visible}
                    open={this.hideModalAsset}
                    toggle={this.toggleAsset}
                    onRequestClose={this.props.onRequestClose}
                    buildingId={this.state.building.value}
                    onAssetSaved={() => {
                        that.setState({ type: "" });
                        that.setState({ type: "assets" });
                        that.hideModalAsset();
                    }}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountingComponent);
