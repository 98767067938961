import i18n from "i18next";
import k from "./../../../i18n/keys";
import React, { Component } from "react";
import {
    Button,
    Form,
    FormGroup,
    FormInput,
    FormSelect,
    Modal,
    ModalBody,
    ModalHeader,
} from "shards-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import { DateTimePicker } from "react-widgets";

import moment from "moment";
import "moment/locale/fi";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

class DebtShareholderPaymentEditModalComponent extends Component {
    constructor(props) {
        super(props);
        this.save = this.save.bind(this);
        this.state = {
            payment: {},

            edit: false,
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.payment !== undefined) {
            this.setState({
                payment: nextProps.payment !== null ? nextProps.payment : {},
            });
        }
        if (nextProps.debt !== undefined) {
            this.setState({
                debt: nextProps.debt !== null ? nextProps.debt : {},
            });
        }
    }

    handleChange(e) {
        this.setState({
            payment: { ...this.state.payment, [e.target.id]: e.target.value },
        });
    }

    handleChangeDatePicker(date) {
        this.setState({
            payment: { ...this.state.payment, payment_date: date },
        });
    }

    save() {
        let that = this;
        this.setState({ loading: true });
        let savePayment = async function () {
            let params = {
                amount: that.state.debt.amount,
                payment_date: that.state.payment.payment_date,
                id: that.state.debt.id,
                shareholder_amount: that.state.payment.shareholder_amount,
            };

            if (that.state.payment.id) {
                params["payment_id"] = that.state.payment.id;
            }
            if (
                !that.state.payment.payment_date ||
                !that.state.payment.shareholder_amount
            ) {
                alert("Please select payment date and payment amount");
                that.setState({ loading: false });
            } else {
                let response = await HonkioAPI().userShop.userFetch(
                    "merchantshareholderdebtset",
                    params
                );
                // console.log(params);
                // console.log(response);
                that.setState({ loading: false });
                that.props.onPaymentSaved();
            }
        };
        savePayment();
    }

    render() {
        // console.log("PAYMENT ADD MODAL");
        // console.log(this.state);
        let saveButton = (
            <Button
                onClick={() => {
                    this.save();
                }}
            >
                {i18n.t(k.SAVE)}
            </Button>
        );
        if (this.state.loading) {
            saveButton = <CircularProgress size={45} />;
        }

        let that = this;
        return (
            <Modal
                open={this.props.visible}
                toggle={this.props.toggle}
                onRequestClose={this.props.onRequestClose}
                className="w-100 modal-lg modal-dialog-centered"
            >
                <ModalHeader>
                    {this.state.payment.id
                        ? i18n.t(k.EDIT_PAYMENT)
                        : i18n.t(k.CREATE_PAYMENT)}
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="payment_date">
                                    {i18n.t(k.PAYMENT_DATE)}
                                </label>
                                <DateTimePicker
                                    id="payment_date"
                                    name="payment_date"
                                    value={moment(
                                        this.state.payment.payment_date
                                    ).toDate()}
                                    step={1}
                                    defaultValue={moment().toDate()}
                                    onChange={(e) => {
                                        that.handleChangeDatePicker(
                                            moment(e).toDate()
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-md-6">
                                <FormGroup>
                                    <label htmlFor="shareholder_amount">
                                        {i18n.t(k.AMOUNT)}
                                    </label>
                                    <FormInput
                                        id="shareholder_amount"
                                        value={
                                            this.state.payment
                                                .shareholder_amount
                                        }
                                        onChange={(e) => this.handleChange(e)}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        {saveButton}
                    </Form>
                </ModalBody>
            </Modal>
        );
    }
}

export default DebtShareholderPaymentEditModalComponent;
