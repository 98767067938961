import i18n from "i18next";
import k from "./../../../i18n/keys";
import React, { Component } from "react";
import {
  Button,
  Card,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroup,
  InputGroupAddon,
} from "shards-react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import FormInputValidation from "../../ui_utils/FormInputValidationComponent";
import CheckIcon from "@material-ui/icons/Check";

class WidgetComponent extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      open: false,
      modal: {
        visible: false,
      },
      renaming: false,
      name: this.props.name,
    };

    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  hideModal() {
    this.setState({ modal: { ...this.state.modal, visible: false } });
  }

  showModal() {
    this.setState({ modal: { ...this.state.modal, visible: true } });
  }

  toggleModal() {
    this.setState({
      modal: { ...this.state.modal, visible: !this.state.modal.visible },
    });
  }

  toggle() {
    this.setState((prevState) => {
      return { open: !prevState.open };
    });
  }
  componentDidMount() {
    let that = this;
    this.setContainerRef = (element) => {
      that.container = element;
    };
    this.setContainerRef2 = (element) => {
      that.container2 = element;
    };
  }
  render() {
    let data;
    if (
      this.props.position.h < 3 &&
      this.props.position.w < 3 &&
      this.props.summary1 !== null
    ) {
      data = <div>{this.props.summary1 || ""}</div>;
    } else if (
      this.props.position.h > 3 &&
      this.props.position.w < 4 &&
      this.props.summary1 !== null
    ) {
      data = (
        <div className="h-100 w-100" ref={this.setContainerRef2}>
          <div>
            {this.container && this.container2
              ? this.props.chart(
                this.props.height - this.container.clientHeight * 2,
                this.container2.offsetWidth
              )
              : ""}
          </div>
          <div>{this.props.summary1 || ""}</div>
        </div>
      );
    } else if (this.props.position.w === 4 && this.props.summary1 !== null) {
      data = (
        <div className="row h-100 w-100">
          <div className="col-9" ref={this.setContainerRef2}>
            {this.container && this.container2
              ? this.props.chart(
                this.props.height - this.container.clientHeight * 2.5,
                this.container2.offsetWidth
              )
              : ""}
          </div>
          <div className="col-3">{this.props.summary1 || ""}</div>
        </div>
      );
    }
    if (this.props.position.w > 4 && this.props.summary1 !== null) {
      data = (
        <div className="row h-100 w-100">
          <div className="col-10" ref={this.setContainerRef2}>
            {this.container && this.container2
              ? this.props.chart(
                this.props.height - this.container.clientHeight * 2.5,
                this.container2.offsetWidth
              )
              : ""}
          </div>
          <div className="col-2">{this.props.summary1 || ""}</div>
        </div>
      );
    } else {
      data = (
        <div className="row h-100 w-100" ref={this.setContainerRef2}>
          {this.container && this.container2
            ? this.props.chart(
              this.props.height - this.container.clientHeight * 2,
              this.container2.offsetWidth
            )
            : ""}
        </div>
      );
    }
    let widgetName = <b>{this.state.name}</b>;
    if (this.state.renaming) {
      widgetName = (
        <div className="row">
          <InputGroup className="mb-2">
            <FormInputValidation
              id="name"
              validationType="notNull"
              value={this.state.name}
              placeholder={i18n.t(k.WIDGET_NAME)}
              onChange={(e) => this.setState({ name: e.target.value })}
              handleValidation={this.props.handleValidation}
            />
            <InputGroupAddon type="append">
              <Button
                onClick={(e) => {
                  this.props.onRename(this.state.name);
                  this.setState({ renaming: false });
                }}
                size={"sm"}
              >
                <CheckIcon />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
      );
    }
    return (
      <div
        key={this.props.key}
        style={{
          height: this.props.height,
          width: this.props.width,
        }}>
        <Card className="h-100 w-100">
          <div className="ml-3 mt-3 w-100" ref={this.setContainerRef}>
            <div className="row">
              <span className="mr-3 ml-3">
                <i className={this.props.icon}>&nbsp;</i>
              </span>
              <div className="ml-3">
                {widgetName}
                <br />
                <span className="text-muted">{this.props.type}</span>
              </div>

              <div id="widgetHeader" className="ml-auto mr-5">
                <Dropdown
                  open={this.state.open}
                  toggle={this.toggle}
                  className="widget-settings"
                  direction={"left"}
                >
                  <DropdownToggle theme="light" children={<MoreVertIcon />} />

                  <DropdownMenu>
                    <DropdownItem
                      disabled={false}
                      onClick={(e) =>
                        this.setState({ renaming: !this.state.renaming })
                      }
                    >
                      <EditIcon className="mr-1" />
                      {i18n.t(k.RENAME)}
                    </DropdownItem>
                    <DropdownItem
                      disabled={!this.props.onSettingsClick}
                      onClick={this.props.onSettingsClick}
                    >
                      <InfoIcon className="mr-1" />
                      {i18n.t(k.EDIT_SETTINGS)}
                    </DropdownItem>
                    <DropdownItem onClick={this.props.onRemoveClick}>
                      <DeleteForeverIcon className="mr-1" />
                      {i18n.t(k.REMOVE)}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
            <hr style={{ margin: 0 }} />
          </div>
          {data}
        </Card>
      </div>
    );
  }
}

export default WidgetComponent;
