import i18n from "i18next";
import k from "./../../../i18n/keys";
import React, { Component } from "react";
import { Button, Form } from "shards-react";
import DataTable from "react-data-table-component";
import CenteredLoader from "../../ui_utils/CenteredLoader";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import DoneIcon from "@material-ui/icons/Done";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { confirmAlert } from "react-confirm-alert";
import DeleteForever from "@material-ui/icons/DeleteForever";
import { Link } from "react-router-dom";

class AgreementTemplateComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: null,
        };

        this.confirmDelete = this.confirmDelete.bind(this);
    }

    // componentWillReceiveProps(nextProps, nextContext){
    //     if (nextProps.tenant !== undefined) {
    //         this.setState({'tenant': nextProps.tenant !== null ? nextProps.tenant : {}})
    //     }
    // }
    componentDidMount() {
        let that = this;
        let loadTemplates = async function () {
            let response = await HonkioAPI().userShop.userFetch(
                "merchantagreementtemplatelist",
                {},
                (error) => {
                    that.setState({
                        building: {
                            templates: [],
                            loading: false,
                        },
                    });
                    NotificationManager.error(
                        `${error?.description}`,
                        i18n.t(k.ERROR)
                    );
                }
            );
            if (
                response?.status === "accept" &&
                response.templates &&
                response.templates.length > 0
            ) {
                that.setState({
                    templates: response.templates,
                    loading: false,
                });
            } else {
                that.setState({
                    templates: [],
                    loading: false,
                });
            }
        };
        loadTemplates();
    }

    handleChange(e) {
        this.setState({
            tenant: { ...this.state.tenant, [e.target.id]: e.target.value },
        });
    }

    confirmDelete(agreement) {
        let that = this;
        const options = {
            title: "Destructive action",
            message: "",
            childrenElement: () => (
                <p>
                    {i18n.t(k.ARE_YOU_SURE_WANT_TO_DELETE_AG1)}
                    <b>{agreement.name}</b>
                    {i18n.t(k._4)}
                </p>
            ),
            buttons: [
                {
                    label: i18n.t(k.YES),
                    onClick: () => {
                        const del = async () => {
                            that.setState({ loading: true });
                            let response = await HonkioAPI().userShop.userFetch(
                                "merchantagreementtemplateset",
                                {
                                    id: agreement._id,
                                    delete: true,
                                }
                            );

                            if (response.status === "accept") {
                                NotificationManager.success(
                                    i18n.t(k.LOADING),
                                    i18n.t(
                                        k.AGREEMENT_TEMPLATE_HAS_BEEN_REMOVED
                                    )
                                );
                            } else {
                                NotificationManager.success(
                                    `${response.description}`,
                                    i18n.t(k.DELETE_FAILED)
                                );
                            }
                            let loadTemplates = async function () {
                                let response =
                                    await HonkioAPI().userShop.userFetch(
                                        "merchantagreementtemplatelist",
                                        {}
                                    );
                                let templates = response.templates;
                                that.setState({
                                    templates: templates,
                                    loading: false,
                                });
                                // console.log(templates);
                            };
                            loadTemplates();
                        };
                        del();
                    },
                },

                {
                    label: i18n.t(k.NO),
                    onClick: () => {},
                },
            ],

            closeOnEscape: true,
            closeOnClickOutside: true,
        };

        confirmAlert(options);
    }

    getColumns() {
        let that = this;
        return [
            {
                name: i18n.t(k.NAME),
                selector: "name",
                sortable: true,
                style: `cursor: pointer;`,
            },

            {
                name: i18n.t(k.TENANT),
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (d) {
                    let tenant = i18n.t(k.NOT_SET);
                    if (d.rules.tenant) {
                        tenant = d.rules.tenant.value;
                    }
                    return <span>{tenant}</span>;
                },
            },

            {
                name: i18n.t(k.BUILDING),
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (d) {
                    let building = i18n.t(k.NOT_SET);
                    if (d.rules.building_name) {
                        building = d.rules.building_name.value;
                    }
                    return <span>{building}</span>;
                },
            },

            {
                name: i18n.t(k.START_DATE),
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (d) {
                    let start_date = i18n.t(k.NOT_SET);
                    if (d.rules.start_date) {
                        start_date = d.rules.start_date.value;
                    }
                    return <span>{start_date}</span>;
                },
            },

            {
                name: i18n.t(k.END_DATE),
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (d) {
                    let end_date = i18n.t(k.NOT_SET);
                    if (d.rules.end_date) {
                        end_date = d.rules.end_date.value;
                    }
                    return <span>{end_date}</span>;
                },
            },

            {
                name: i18n.t(k.ACTION),
                selector: "action",
                cell: function (d) {
                    return (
                        <span>
                            <Button
                                theme="primary"
                                size="sm"
                                onClick={() => {
                                    that.props.onSelectTemplate(d);
                                    that.props.goToStep(2);
                                }}
                            >
                                {" "}
                                {i18n.t(k.SELECT)}
                            </Button>
                            <Link
                                to="#"
                                className="pointer"
                                onClick={() => {
                                    that.confirmDelete(d);
                                }}
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ];
    }

    render() {
        let that = this;
        if (!this.state.templates) {
            return <CenteredLoader />;
        }
        return (
            <Form>
                <h4>{i18n.t(k.AGREEMENT_TEMPLATES)}</h4>
                <hr />
                <DataTable
                    autoWidth={true}
                    columns={this.getColumns()}
                    background="#ffffff00"
                    onRowClicked={this.rowSelected}
                    data={this.state.templates}
                    noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                    paginationComponentOptions={{
                        rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                        rangeSeparatorText: i18n.t(k.ROWS_OF),
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                    }}
                    progressPending={this.state.loading}
                    progressComponent={<CenteredLoader />}
                />
            </Form>
        );
    }
}

export default AgreementTemplateComponent;
