import i18n from 'i18next';
import k from '../../../i18n/keys';
import {BACKEND_URL} from "../../../config";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import {NotificationManager} from "react-notifications";

import moment from "moment";
import "moment/locale/fi";

const isLoadingAreaSchedule = () => ({
  type: "AREA_SCHEDULE_LOADING"
});


function areaScheduleSuccess(json) {
  return {
    type: "AREA_SCHEDULE_SUCCESS",
    schedule: json.schedule
  };

}

const areaScheduleError = err => ({
  type: "AREA_SCHEDULE_ERROR",
  err
});


const isSettingEvent = () => ({
  type: "SET_EVENT_LOADING"
});

function setEventSuccess(json) {
  NotificationManager.success('', i18n.t(k.SAVED));
  return {
    type: "SET_EVENT_SUCCESS",
    isSettingEvent: false,
    setEventSucess: true
  };

}

const setEventError = err => ({
  type: "SET_EVENT_ERROR",
  err
});


function getAreaSchedule(auth_token, areaId, start, end) {
  // console.log('Sending request to flask api!!!');
  return async function (dispatch) {
    dispatch(isLoadingAreaSchedule());
    try {
      let body_object = {
        // command: "consumerget",
        access_token: auth_token,
        object: areaId,
        object_type: 'area',
        query_start_date: moment(start).format('YYYY-MM-DD HH:mm:ss').toLocaleString(),
        query_end_date: moment(end).format('YYYY-MM-DD HH:mm:ss').toLocaleString()
      };


      let response = await fetch(`${BACKEND_URL}merchantgetfullcalendar`,
        {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
          },

          body: JSON.stringify(body_object)
        });

      if (!response.ok) {
        throw new Error("An Error has occurred, please try again.");
      }
      let json = await response.json();

      return dispatch(areaScheduleSuccess(json));
    } catch (err) {
      return dispatch(areaScheduleError(err));
    }
  };

}

function setEvent(areaId, start, end, type, description, product) {
  // console.log('Sending request to flask api!!!');
  // console.log(product);
  return async function (dispatch) {
    dispatch(isSettingEvent());
    try {

      let json = await HonkioAPI().userShop.userFetch('merchanteventset', {
          object: areaId,
          object_type: 'area',
          type: type,
          repeat: "once",
          description: description,
          products: [product],
          start: moment.utc(moment(start)).format('YYYY-MM-DD HH:mm:ss').toLocaleString(),
          end: moment.utc(moment(end)).format('YYYY-MM-DD HH:mm:ss').toLocaleString()
        },
        message => {
          setEventError(message.description);
          return false;
        });
      return dispatch(setEventSuccess(json));
    } catch (err) {
      return dispatch(setEventError(err));
    }
  };
}

function setEventBuilding(params) {
  // console.log('Sending request to flask api!!!');

  return async function (dispatch) {
    dispatch(isSettingEvent());
    try {
      let query = {
        object: params.event.areaId,
        object_type: 'building',
        type: params.event.type,
        repeat: "once",
        description: params.event.description,
        start: moment.utc(moment(params.event.start)).format('YYYY-MM-DD HH:mm:ss').toLocaleString(),
        end: moment.utc(moment(params.event.end)).format('YYYY-MM-DD HH:mm:ss').toLocaleString(),
        fields: params.event.customFields,
        notify: true
      };

      if (params.event.id) {
        query['id'] = params.event.id;
      }

      if (params.event.iCal) {
        let queryToIcs = {
          query_object: params.calToIcs.query_object,
          query_object_type: params.calToIcs.query_object_type,
          query_start_date: params.calToIcs.query_start_date,
          query_end_date: params.calToIcs.query_end_date
        }
        let response = await HonkioAPI().userShop.userFetch('merchantfullcalendartoics', queryToIcs)
        // console.log("ICAL SET")
        // console.log(response)
        query['ical'] = response.ical
      }

      let json = await HonkioAPI().userShop.userFetch('merchanteventset', query, message => {
        setEventError(message.description);
        return false;
      });

      query = {
        "object": json._id,
        "object_type": "event",
        "title": "TITLE",
        "text": "TEXT",
        "options": {
          "0": {
            "name": "Yes"
          },
          "1": {
            "name": "No"
          }
        },
        "creation_date": moment.utc(moment()).format('YYYY-MM-DD HH:mm:ss').toLocaleString(),
        "expire_date": moment.utc(moment(params.event.start)).format('YYYY-MM-DD HH:mm:ss').toLocaleString(),
        "properties": {
          "answer": "0",
          "type": "participate"
        },
        "active": true,
        "visible": true
      }
      // console.log("QUERY")
      // console.log(query)
      let result = await HonkioAPI().userShop.userFetch('merchantpollset', query)
      return dispatch(setEventSuccess(json));
    } catch (err) {
      return dispatch(setEventError(err));
    }
  }
}


export {getAreaSchedule, setEvent, setEventBuilding};
