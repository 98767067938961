import i18n from "i18next";
import k from "./../../../i18n/keys";
import React, { Component } from "react";
import CardWrapperComponent from "../../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataTable from "react-data-table-component";
import DeleteForever from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "shards-react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import NotificationManager from "react-notifications/lib/NotificationManager";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import SecureImage from "../../ui_utils/SecureImage";
import PageHeaderComponent from "../../ui_utils/PageHeaderComponent";
import DebtPaymentEditModalComponent from "./DebtPaymentEditModalComponent";
import { Link } from "react-router-dom";

import moment from "moment";
import "moment/locale/fi";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

class DebtPaymentPlanListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            paymentPlan: [],
            modal: {
                visible: false,
                floorplan: null,
            },
        };

        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.toggle = this.toggle.bind(this);
        // this.loadPaymentPlan = this.loadPaymentPlan.bind(this)
        // this.confirmDelete = this.confirmDelete.bind(this)
    }

    hideModal() {
        this.setState({ modal: { ...this.state.modal, visible: false } });
    }

    showModal(payment) {
        this.setState({
            modal: { ...this.state.modal, visible: true, payment: payment },
        });
    }

    toggle() {
        this.setState({
            modal: { ...this.state.modal, visible: !this.state.modal.visible },
        });
    }

    componentDidMount() {
        let string = this.props.match.params.paymentPlan;
        this.setState({ paymentId: string });
        // console.log('paymentId: ', string);
        this.load(string);
    }

    load(string) {
        let that = this;
        let query = {};
        let merchantdebtlist = async function () {
            if (string) {
                query["id"] = string;
            }
            let response = await HonkioAPI().userShop.userFetch(
                "merchantbuildingdebtlist",
                query
            );
            // console.log('PAY');
            // console.log(response);
            let paymentPlan = response.debts;
            that.setState({
                paymentPlan: paymentPlan[0]["payment_plan"],
                loading: false,
                debt: paymentPlan[0],
            });
        };
        merchantdebtlist();
    }
    confirmDelete(plan) {
        let that = this;
        const options = {
            title: "Destructive action",
            message: "",
            childrenElement: () => (
                <p>{i18n.t(k.ARE_YOU_SURE_WANT_TO_DELETE_PL)}</p>
            ),
            buttons: [
                {
                    label: i18n.t(k.YES),
                    onClick: () => {
                        const del = async () => {
                            let response = await HonkioAPI().userShop.userFetch(
                                "merchantbuildingdebtset",
                                {
                                    payment_id: plan.id,
                                    id: that.state.debt.id,
                                    delete: true,
                                }
                            );

                            if (response.status === "accept") {
                                NotificationManager.success(
                                    i18n.t(k.LOADING),
                                    i18n.t(k.AREA_HAS_BEEN_REMOVED)
                                );
                            } else {
                                NotificationManager.success(
                                    `${response.description}`,
                                    i18n.t(k.DELETE_FAILED)
                                );
                            }
                        };
                        del();
                        that.load(that.state.paymentId);
                    },
                },

                {
                    label: i18n.t(k.NO),
                    onClick: () => {},
                },
            ],

            closeOnEscape: true,
            closeOnClickOutside: true,
        };

        confirmAlert(options);
    }

    getColumns() {
        let that = this;

        return [
            {
                name: "Payment date",
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (d) {
                    return (
                        <span>
                            <span>
                                {moment(d.payment_date).format(
                                    "YYYY-MM-DD HH:MM:SS"
                                )}
                            </span>
                        </span>
                    );
                },
            },

            {
                name: "Amount",
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (d) {
                    return (
                        <span>
                            <span>{that.roundNumber(d.amount, 2)}</span>
                        </span>
                    );
                },
            },

            {
                name: "Status",
                selector: "status",
                sortable: true,
                style: `cursor: pointer;`,
            },

            {
                name: "Action",
                selector: "action",
                cell: function (d) {
                    return (
                        <span>
                            <Link
                                to="#"
                                className="mr-3 pointer"
                                onClick={() => {
                                    that.showModal(d);
                                }}
                            >
                                <EditIcon />
                            </Link>
                            <Link
                                to="#"
                                className="pointer"
                                onClick={() => {
                                    that.confirmDelete(d);
                                }}
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ];
    }
    roundNumber(num, scale) {
        if (!("" + num).includes("e")) {
            return +(Math.round(num + "e+" + scale) + "e-" + scale);
        } else {
            let arr = ("" + num).split("e");
            let sig = "";
            if (+arr[1] + scale > 0) {
                sig = "+";
            }
            return +(
                Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) +
                "e-" +
                scale
            );
        }
    }
    render() {
        let that = this;
        // console.log(this.state);
        let table;
        let modal;
        if (this.state.debt && this.state.paymentPlan) {
            let plan = this.state.paymentPlan;
            let totalAmount = 0;
            for (let i = 0; i < plan.length; i++) {
                totalAmount += parseInt(plan[i].amount);
            }
            table = (
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">
                                {i18n.t(k.TOTAL_LIABILITY_AMOUNT)}{" "}
                                {this.state.debt.total_amount}
                            </th>
                            <th scope="col">
                                {i18n.t(k.THIS_PLAN_TOTAL_AMOUNT)} {totalAmount}
                            </th>
                        </tr>
                    </thead>
                </table>
            );
            modal = (
                <DebtPaymentEditModalComponent
                    visible={this.state.modal.visible}
                    open={this.hideModal}
                    toggle={this.toggle}
                    onRequestClose={this.props.onRequestClose}
                    payment={this.state.modal.payment}
                    debt_id={this.state.debt.id}
                    onPaymentSaved={() => {
                        that.hideModal();
                        that.load(that.state.paymentId);
                    }}
                />
            );
        }
        return (
            <div>
                <div className="container-fluid">
                    <PageHeaderComponent
                        title={i18n.t(k.PAYMENT_PLAN)}
                        subtitle={i18n.t(k.OVERVIEW)}
                    />
                </div>
                <CardWrapperComponent
                    header={true}
                    footer={true}
                    card_title={i18n.t(k.PAYMENT_PLAN)}
                >
                    <Button
                        theme="info"
                        onClick={() => {
                            that.showModal(null);
                        }}
                    >
                        <AddIcon className="mr-2" />
                        {i18n.t(k.ADD_PAYMENT)}
                    </Button>

                    {/*<div className="">*/}
                    <DataTable
                        autoWidth={true}
                        columns={this.getColumns()}
                        background="#ffffff00"
                        data={this.state.paymentPlan}
                        noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                        paginationComponentOptions={{
                            rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                            rangeSeparatorText: i18n.t(k.ROWS_OF),
                            noRowsPerPage: false,
                            selectAllRowsItem: false,
                            selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                        }}
                        progressPending={this.state.loading}
                        progressComponent={<Circular />}
                    />

                    {table}
                </CardWrapperComponent>

                {modal}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DebtPaymentPlanListComponent);
