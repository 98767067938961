import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { Component } from "react";
// import {getInvite} from "../../actions/inviteActions";
import { connect } from "react-redux";

import { Button, Form, FormGroup } from "shards-react";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import "react-notifications/dist/react-notifications.css";
import "react-notifications/dist/react-notifications.js";
import { CONSUMER_URL } from "../../config";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteForever from "@material-ui/icons/DeleteForever";
import HonkioAPI from "../../middlewares/HonkioAPI";
import { confirmAlert } from "react-confirm-alert";
import InviteUserModal from "../invite/InviteUserComponent";
import CenteredPageLoader from "../ui_utils/CenteredPageLoader";
import PageableDatatablesComponent from "../ui_utils/PageableDatatablesComponent";
import { Link } from "react-router-dom";

const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

class SimpleUsersListComponent extends Component {
    constructor(props) {
        super(props);
        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.onRequestClose = this.onRequestClose.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getMerchantConsumerList = this.getMerchantConsumerList.bind(this);
        this.state = {
            loading: true,
            role: this.props.role,
            modal: {
                visible: false,
            },
        };
    }

    hideModal() {
        this.setState({ modal: { ...this.state.modal, visible: false } });
        // this.onRequestClose();
    }

    showModal() {
        this.setState({ modal: { ...this.state.modal, visible: true } });
    }

    componentDidMount() {
        this.getMerchantConsumerList(this.state.role._id, 0);
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.role) {
            this.setState({ role: nextProps.role });
            this.getMerchantConsumerList(nextProps.role, 0);
        }
    }

    getMerchantConsumerList(role, page) {
        let that = this;
        if (!page) page = 0;
        that.setState({ loading: true });
        let load = async function () {
            let params = {
                query_roles: true,
                roles: [role],
                query_skip: parseInt(page * 20),
                query_count: 20,
            };
            if (that.state.role && that.state.role.name !== "admin") {
                params["query_building"] = that.props.buildingId;
            }
            let merchantlistuserroles = await HonkioAPI().userShop.userFetch(
                "merchantlistuserroles",
                params
            );
            let consumers = merchantlistuserroles.consumers;
            that.setState({
                consumers: consumers,
                total: merchantlistuserroles.total,
                loading: false,
            });
        };
        load();
    }

    onRequestClose() {
        try {
            this.props.getMerchantConsumerList(this.state.role._id);
        } catch (e) {
            console.error(e);
        }
    }

    getColumns() {
        let state = this.state;
        let that = this;
        return [
            {
                name: "#",
                selector: "id",
                cell: function (d) {
                    let avatar_url = `${CONSUMER_URL}/user_photo/${d.id}.jpg`;
                    return <img height="64px" width="64px" src={avatar_url} />;
                },
                maxWidth: "64px",
                sortable: false,
            },

            {
                name: "Firstname",
                selector: "settings.str_firstname",
                sortable: true,
            },

            {
                name: "Lastname",
                selector: "settings.str_lastname",
                sortable: true,
            },
            {
                name: i18n.t(k.HONKIO_USER),
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (e) {
                    return e.active ? "Yes" : "Not yet";
                },
            },
            {
                name: "Email",
                selector: "email",
                sortable: false,
            },

            {
                name: "Phone",
                selector: "settings.str_telephone",
                sortable: false,
            },

            {
                name: "Action",
                selector: "action",
                cell: function (d) {
                    let mkClickListenter = (id) => {
                        return (e) => {
                            e.preventDefault();
                            const options = {
                                title: "Destructive action",
                                message: "",
                                childrenElement: () => (
                                    <p>
                                        {i18n.t(
                                            k.ARE_YOU_SURE_WANT_TO_REMOVE_TH1
                                        )}
                                    </p>
                                ),
                                buttons: [
                                    {
                                        label: i18n.t(k.YES),
                                        onClick: () => {
                                            let del = async function () {
                                                let response =
                                                    await HonkioAPI().userShop.userFetch(
                                                        "merchantuserroleset",
                                                        {
                                                            role_id:
                                                                that.state.role,
                                                            user_id: id,
                                                            delete: true,
                                                        }
                                                    );
                                                that.getMerchantConsumerList(
                                                    that.state.role
                                                );
                                            };
                                            del();
                                        },
                                    },

                                    {
                                        label: i18n.t(k.NO),
                                        onClick: () => {},
                                    },
                                ],

                                closeOnEscape: true,
                                closeOnClickOutside: true,
                                /* willUnmount: () => {},
               afterClose: () => {}
               onClickOutside: () => {},
               onKeypressEscape: () => {}*/
                            };

                            confirmAlert(options);
                        };
                    };
                    return (
                        <span>
                            <Link
                                className="m-2"
                                href=""
                                onClick={mkClickListenter(d.id)}
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ];
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
        this.getMerchantConsumerList(e.target.value);
    };

    render() {
        if (this.state.loading) {
            return <CenteredPageLoader />;
        }
        let that = this;
        // console.log(this.state)
        return (
            <div>
                {/*<h3 className="mt-2">{i18n.t(k.YOUR_EMPLOYEES)}</h3>*/}
                <Form>
                    <FormGroup>
                        {/*<Button id="add" className="mt-3">Add new</Button>*/}
                    </FormGroup>
                    <Button onClick={this.showModal} className="mb-3">
                        {i18n.t(k.INVITE_USER)}
                    </Button>
                    {/*<label htmlFor="role">{i18n.t(k.SELECT_GROUP)}</label>*/}
                    {/*<InputGroup>*/}
                    {/*    /!*<FormSelect id="role" onChange={e => this.handleChange(e)}>*!/*/}
                    {/*    /!*    {this.getRolesSelectOptions()}*!/*/}
                    {/*    /!*</FormSelect>*!/*/}
                    {/*    <InputGroupAddon type="append">*/}
                    {/*        <Button onClick={this.showModal} className="ml-3">{i18n.t(k.INVITE_USER)}</Button>*/}
                    {/*    </InputGroupAddon>*/}

                    {/*</InputGroup>*/}

                    <FormGroup>
                        <PageableDatatablesComponent
                            // title="Employees"
                            className="border"
                            noHeader={true}
                            autoWidth={true}
                            columns={this.getColumns()}
                            background="#ffffff00"
                            items={this.state.consumers}
                            total={this.state.total}
                            progressPending={this.state.loading}
                            onRowClicked={this.rowSelected}
                            // conditionalRowStyles={conditionalRowStyles}
                            loadItems={(page) => {
                                this.getMerchantConsumerList(
                                    this.state.role,
                                    page
                                );
                            }}
                        />
                        {/*            <DataTable*/}
                        {/*autoWidth={true}*/}
                        {/*columns={this.getColumns()}*/}
                        {/*background="#ffffff00"*/}
                        {/*data={this.state.consumers}*/}
                        {/*progressPending={this.state.loadingConsumers}*/}
                        {/*progressComponent={<Circular />}*/}
                        {/*onRowClicked={this.rowSelected} />*/}
                    </FormGroup>
                </Form>
                <InviteUserModal
                    visible={this.state.modal.visible}
                    open={this.hideModal}
                    toggle={this.hideModal}
                    onRequestClose={this.onRequestClose}
                    buildingId={this.props.buildingId}
                    role_name={this.state.role_name}
                    role={this.state.role}
                    onInviteSuccess={() => {
                        NotificationManager.success(
                            i18n.t(k.ASK_USER_CHECK_INBOX),
                            i18n.t(k.USER_HAS_BEEN_INVITED)
                        );
                        // alert(that.state.role._id)
                        that.hideModal();
                        that.getMerchantConsumerList(that.state.role._id);
                    }}
                    onInviteError={(response) => {
                        NotificationManager.warning(
                            response.description || i18n.t(k.UNKNOWN_ERROR),
                            i18n.t(k.ERROR_SENDING_INVITE),
                            5000
                        );
                    }}
                />

                <NotificationContainer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    // return {...state.consumersList, roles: state.merchantRoles.roles || []}
    return state;
}

const mapDispatchToProps = (dispatch) => ({
    // refetchMerchantRoles: (query) => dispatch(refetchMerchantRoles(query)),
    // getMerchantConsumerList: (role_id) => dispatch(getMerchantConsumerList(role_id)),
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SimpleUsersListComponent);
