import i18n from "i18next";
import k from "../../../i18n/keys";
import React, { useEffect, useState, useContext } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {
    getAssetList,
    getAssetListWithPages,
} from "../../../actions/api/assets/getAssetList";
import PageableDatatablesComponent from "../../ui_utils/PageableDatatablesComponent";
import getAssetTypeList from "../../../actions/api/assetTypes/getAssetTypeList";
import { APP_ID } from "../../../config";
import { Modal, Button } from "react-bootstrap";
import { updateAssetData } from "../../../actions/api/assets/setAssetData";
import { STORAGE } from "../../../actions/auth/oauthActions";
import { useDispatch, useSelector } from "react-redux";
import { closeSafetyVideoModal } from "../../../actions/action_utils";
import { getFileList } from "../../../actions/api/files/getFileList";
import QRCode from "qrcode.react";
import { Link } from "react-router-dom";

const Circular = () => (
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);
const PAGE_ITEMS_COUNT = 5;

// TODO: Load `selectedAssetData.parent`'s assets here and check if safety_video already attached - check meta_data
// If so - show that video link rendered as QR code
// TODO #2: Attach `url` into safety_video properties from asset

const VideosListModal = () => {
    const { isModalOpen } = useSelector((state) => state.safetyVideos);
    const selectedAssetData = useSelector((state) => state.safetyVideos.data);
    const dispatch = useDispatch();

    const [videosList, setVideosList] = useState([]);
    const [typesList, setTypesList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedVideosList, setSelectedVideosList] = useState([]);
    const [assetList, setAssetList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [finished, setFinished] = useState(false);

    const [currentSafetyVideo, setCurrentSafetyVideo] = useState(null);

    const onLinkClicked = () => {
        try {
            const selectedVideosCount = selectedVideosList.length;
            if (selectedVideosCount > 0) {
                selectedVideosList.forEach((asset, index) => {
                    const videoType = typesList.find(
                        (item) => item.name === "safety_video"
                    );
                    const linkData = {
                        type: "safety_video", // Or asset.name
                        description: asset.description,
                        name: `${asset.display_name}.${asset.extension}`,
                        properties: {
                            ...videoType.properties,
                            video_url: asset.url,
                            safety_video: true,
                        },
                        parent: selectedAssetData.parent,
                    };

                    return linkAsset(
                        linkData,
                        index === selectedVideosCount - 1
                    );
                });
            } else {
                setFinished(true);
            }
        } catch (error) {
            NotificationManager.error("Error linking asset");
            console.log("Error linking asset:", error);
        }
    };
    const linkAsset = (asset, stop) => {
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                if (stop) {
                    setFinished(true);
                    setSelectedVideosList([]);
                }
            } else {
                NotificationManager.error("Error");
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        const newAsset = {
            ...asset,
            parent: selectedAssetData.parent,
        };

        updateAssetData(
            newAsset,
            localStorage.getItem(STORAGE.MERCHANT_ID),
            APP_ID,
            selectedAssetData.type || "",
            errorHandler
        ).then((data) => {
            NotificationManager.success("Sensors added");
            return resultHandler(data);
        });
    };

    const onHide = () => {
        setVideosList([]);
        setCurrentSafetyVideo(null);
        dispatch(closeSafetyVideoModal());
    };

    const fetchAssetFiles = async () => {
        try {
            setLoading(true);
            const merchant_id = localStorage.getItem("merchant_id");
            const res = await getFileList(
                { type: "video", object: merchant_id },
                (err) => {
                    console.log("API error", err);
                }
            );
            if (res.files) setVideosList(res.files);
            setLoading(false);
        } catch (error) {
            setVideosList([]);
            console.log("Error getting videos list");
            setLoading(false);
        }
    };

    const fetchAssetList = async (page) => {
        setLoading(true);
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                setAssetList(data.assets);
                setLoading(false);
            } else {
                NotificationManager.error("Error");
                setAssetList();
                setLoading(false);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setAssetList();
            setLoading(false);
        };
        getAssetListWithPages(
            {
                page: page,
                count: PAGE_ITEMS_COUNT,
                parent: selectedAssetData.parent,
            },
            errorHandler
        )
            .then((data) => {
                return resultHandler(data);
            })
            .catch(console.error);
    };

    const fetchChildrenTypes = async () => {
        setLoading(true);
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                setTypesList(data.asset_types);
                setLoading(false);
            } else {
                NotificationManager.error("Error");
                setTypesList();
                setLoading(false);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setTypesList();
            setLoading(false);
        };
        getAssetTypeList(
            {
                query_application: APP_ID,
                // query_parent: type,
            },
            errorHandler
        )
            .then((data) => {
                return resultHandler(data);
            })
            .catch((error) => {
                console.log("getAssetTypeList: modal: error", error);
            });
    };

    useEffect(() => {
        if (isModalOpen) {
            fetchAssetFiles();
            fetchAssetList();
            fetchChildrenTypes();
        }
    }, [isModalOpen]);

    useEffect(() => {
        if (finished) onHide();
    }, [finished]);

    const getVideosForPage = () => {
        const startIdx = currentPage * PAGE_ITEMS_COUNT;
        const endIdx = startIdx + PAGE_ITEMS_COUNT;
        const videosForPage = videosList.slice(startIdx, endIdx);

        return videosForPage;
    };

    const onVideoSelected = (data) => {
        setSelectedVideosList([data.selectedRows[0]]);
    };

    const columns = [
        {
            name: i18n.t(k.NAME),
            selector: (row, index) => `${row.display_name}.${row.extension}`,
            sortable: true,
            autoWidth: true,
            grow: 2,
            style: `cursor: pointer;`,
        },
        {
            name: "Actions",
            cell: (row, index, column, id) => (
                <a
                    href={`/videoPlayer?url=${row.url}&name=${row.display_name}`}
                    target="_blank"
                >
                    View
                </a>
            ),
            sortable: true,
            autoWidth: true,
            grow: 0,
            style: `cursor: pointer;`,
        },
    ];

    const rowSelectCritera = (row) => {
        if (!selectedVideosList.length) return false;
        const selected = selectedVideosList.filter((item) => {
            const isSelected = item?._id === row?._id;

            return isSelected;
        });
        return selected.length > 0;
    };

    const getCurrentSafetyVideo = () => {
        if (!assetList.length) return null;
        const sfVid = assetList.find((item) => item.type === "safety_video");
        if (sfVid) {
            setCurrentSafetyVideo(sfVid);
        }
        return null;
    };

    useEffect(() => {
        getCurrentSafetyVideo();
    }, [assetList]);

    return (
        <div>
            <Modal show={isModalOpen || false} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Select{currentSafetyVideo ? "ed" : ""} safety video
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {currentSafetyVideo ? (
                        <a
                            title={`${currentSafetyVideo?.name} - click to view`}
                            target="_blank"
                            // href={currentSafetyVideo?.properties?.video_url}
                            href={`/videoPlayer?url=${currentSafetyVideo?.properties?.video_url}&name=${currentSafetyVideo?.name}`}
                            className="d-flex justify-content-center align-items-center"
                        >
                            <QRCode
                                value={
                                    currentSafetyVideo?.properties?.video_url
                                }
                                size={256}
                            />
                        </a>
                    ) : (
                        <PageableDatatablesComponent
                            noHeader={true}
                            selectableRows
                            selectableRowsComponent={Radio}
                            onSelectedRowsChange={(e) => onVideoSelected(e)}
                            selectableRowSelected={rowSelectCritera}
                            autoWidth={true}
                            columns={columns}
                            background="#ffffff00"
                            items={getVideosForPage()}
                            loadItems={(page) => {
                                setCurrentPage(page);
                            }}
                            noDataComponent={
                                <span>{i18n.t(k.EMPTY_LIST)}</span>
                            }
                            paginationTotalRows={videosList.length}
                            progressPending={loading}
                            progressComponent={<Circular />}
                            paginationServer={true}
                            paginationPerPage={PAGE_ITEMS_COUNT}
                            paginationRowsPerPageOptions={[PAGE_ITEMS_COUNT]}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {currentSafetyVideo ? (
                        <Link
                            to={`/assets/edit?asset=${currentSafetyVideo._id}`}
                            variant="danger"
                            onClick={() => onLinkClicked()}
                        >
                            Edit
                        </Link>
                    ) : null}
                    <Button variant="secondary" onClick={() => onHide()}>
                        Close
                    </Button>
                    {currentSafetyVideo ? null : (
                        <Button
                            variant="danger"
                            onClick={() => onLinkClicked()}
                        >
                            Link
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default VideosListModal;
