import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { Component } from "react";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteForever from "@material-ui/icons/DeleteForever";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "shards-react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import NotificationManager from "react-notifications/lib/NotificationManager";
import HonkioAPI from "../../middlewares/HonkioAPI";
import EditIcon from "@material-ui/icons/Edit";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import Email from "@material-ui/icons/Email";
import PageableDatatablesComponent from "../ui_utils/PageableDatatablesComponent";
import { Link } from "react-router-dom";
import { ASSET_TYPE } from "./../../actions/api/assets/config";

class AgreementsListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buildingId: this.props.match.params.buildingId,
            loading: true,
            button_loading: true,
            agreements: [],
            loading_button: true,
        };
        this.loadAgreements = this.loadAgreements.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.createTemplate = this.createTemplate.bind(this);
        this.rowSelected = this.rowSelected.bind(this);
        this.createAgreement = this.createAgreement.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
    }

    componentDidMount() {
        this.loadAgreements(0);
    }

    sendEmail(d) {
        let saveAgreement = async function () {
            let params = {
                ...d,
            };
            params["state"] = "email_sent";
            params["send_email"] = true;
            params["id"] = params._id;
            let response = await HonkioAPI().userShop.userFetch(
                "merchantagreementset",
                params
            );
            if (response) {
                NotificationManager.success(i18n.t(k.AGREEMENT_SENT));
            }
        };
        saveAgreement();
    }

    confirmDelete(agreement) {
        let that = this;
        const options = {
            title: "Destructive action",
            message: "",
            childrenElement: () => (
                <p>
                    {i18n.t(k.ARE_YOU_SURE_WANT_TO_DELETE_AG)}{" "}
                    <b>{agreement.name}</b>
                    {i18n.t(k._4)}
                </p>
            ),
            buttons: [
                {
                    label: i18n.t(k.YES),
                    onClick: () => {
                        const del = async () => {
                            that.setState({ loading: true });
                            let response = await HonkioAPI().userShop.userFetch(
                                "merchantagreementset",
                                {
                                    id: agreement._id,
                                    delete: true,
                                }
                            );

                            if (response.status === "accept") {
                                NotificationManager.success(
                                    i18n.t(k.LOADING),
                                    i18n.t(k.AGREEMENT_HAS_BEEN_REMOVED)
                                );
                            } else {
                                NotificationManager.success(
                                    `${response.description}`,
                                    i18n.t(k.DELETE_FAILED)
                                );
                            }
                            that.loadAgreements();
                        };
                        del();
                    },
                },
                {
                    label: i18n.t(k.NO),
                    onClick: () => {},
                },
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        };
        confirmAlert(options);
    }

    createTemplate(agreement) {
        let that = this;
        let merchanagreementtemplateset = async function () {
            let rules = {};
            Object.keys(agreement.rules).forEach((k) => {
                if (
                    agreement.rules[k].value &&
                    agreement.rules[k].value !== null
                ) {
                    if (agreement.rules[k].label === null) {
                        agreement.rules[k]["label"] = k;
                    }
                    rules[k] = agreement.rules[k];
                }
            });
            let response = await HonkioAPI().userShop.userFetch(
                "merchantagreementtemplateset",
                {
                    title: agreement.name,
                    rules: rules,
                }
            );
            if (response.status === "accept") {
                NotificationManager.success(
                    i18n.t(k.CREATED),
                    i18n.t(k.TEMPLATE_HAS_BEEN_CREATED)
                );
                that.props.history.push(`/agreements`);
            }
        };
        merchanagreementtemplateset();
    }

    rowSelected(o) {
        this.props.history.push(`/agreement/${o._id}`);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (
            nextProps.match &&
            nextProps.match.params &&
            nextProps.match.params.buildingId !== this.state.buildingId
        ) {
            this.loadAgreements(0, nextProps.match.params.buildingId);
        }
        if (
            nextProps.match &&
            nextProps.match.params &&
            nextProps.match.params.landplotId !== this.state.landplotId
        ) {
            this.loadAgreements(0, null, nextProps.match.params.landplotId);
        }
    }

    loadAgreements(page, buildingId, landplotId) {
        let that = this;
        let building = buildingId
            ? buildingId
            : this.props.match.params.buildingId;
        let landplot = landplotId
            ? landplotId
            : this.props.match.params.landplotId;
        that.setState({
            loading: true,
            buildingId: building,
            landplotId: landplot,
        });
        let merchantagreementlist = async function () {
            if (!page) page = 0;
            let query = {
                query_skip: parseInt(page * 20),
                query_count: 20,
            };
            if (building && building !== "all") {
                query["building_id"] = building;
            }
            if (landplot && landplot !== "all") {
                query["query_landplot"] = landplot;
            }
            let response = await HonkioAPI().userShop.userFetch(
                "merchantagreementlist",
                query,
                (error) => {
                    that.setState({
                        agreements: [],
                        total: 0,
                        loading: false,
                    });
                }
            );
            if (response.status === "accept" && response.rents) {
                let agreements = response.rents;
                that.setState({
                    agreements: agreements,
                    total: response.total,
                    loading: false,
                });
            } else {
                that.setState({
                    agreements: [],
                    total: 0,
                    loading: false,
                });
            }
        };
        merchantagreementlist();

        let load = async function () {
            let paramsBuilding = {
                query_type: ASSET_TYPE.BUILDING,
            };
            let buildingsResponse = await HonkioAPI().userShop.userFetch(
                "merchantassetlist",
                paramsBuilding
            );
            let buildings = buildingsResponse?.assets ?? [];
            let paramsAreas = {
                query_type: ASSET_TYPE.AREA,
            };
            let areasResponse = await HonkioAPI().userShop.userFetch(
                "merchantassetlist",
                paramsAreas
            );
            let areas = areasResponse?.assets ?? [];
            let paramsFloorPlans = {
                query_type: ASSET_TYPE.FLOORPLAN,
            };
            let floorplansResponse = await HonkioAPI().userShop.userFetch(
                "merchantassetlist",
                paramsFloorPlans
            );
            let floorplans = floorplansResponse?.assets ?? [];

            // response = await HonkioAPI().userShop.userFetch('merchanttenantlist', {});
            // let tenants = response.tenants;

            let tenants = [];
            let getmerchantroles = await HonkioAPI().userShop.userFetch(
                "getmerchantroles",
                {
                    query_access: false,
                    query_sort: [{ access: -1 }, { name: 1 }],
                }
            );
            let roles = getmerchantroles?.roles ?? [];
            if (roles.length > 0) {
                let roles_ids = [];
                roles.forEach((role) => {
                    if (["tenant"].includes(role.name)) {
                        roles_ids.push(role.role_id);
                    }
                });
                let merchantlistuserroles =
                    await HonkioAPI().userShop.userFetch(
                        "merchantlistuserroles",
                        { query_roles: true, roles: roles_ids }
                    );
                tenants = merchantlistuserroles?.consumers ?? [];
            }
            that.setState({
                length: {
                    buildings: buildings.length > 0 ? 1 : 0,
                    areas: areas.length ? 1 : 0,
                    floorplans: floorplans.length ? 1 : 0,
                    tenants: tenants.length > 0 ? 1 : 0,
                },
                loading_button: false,
            });
        };
        load();
    }

    getColumns() {
        let that = this;
        return [
            {
                name: i18n.t(k._5),
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (d) {
                    let id = i18n.t(k.NOT_SET);
                    if (d.rules.agreement_id) {
                        id = d.rules.agreement_id.value;
                    }
                    return <span>{id}</span>;
                },
            },
            {
                name: i18n.t(k.NAME),
                selector: "name",
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.DESCRIPTION),
                selector: "description",
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.TENANT),
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (d) {
                    let tenant = i18n.t(k.NOT_SET);
                    if (d.rules.tenant_name) {
                        tenant = d.rules.tenant_name.value;
                    }
                    return <span>{tenant}</span>;
                },
            },
            {
                name: i18n.t(k.BUILDING),
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (d) {
                    let building = i18n.t(k.NOT_SET);
                    if (d.rules.building_name) {
                        building = d.rules.building_name.value;
                    }
                    return (
                        <Link to={`/agreements/${d.rules.building.value}`}>
                            {building}
                        </Link>
                    );
                },
            },
            {
                name: i18n.t(k.START_DATE),
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (d) {
                    let start_date = i18n.t(k.NOT_SET);
                    if (d.rules.start_date) {
                        start_date = d.rules.start_date.value;
                    }
                    return <span>{start_date}</span>;
                },
            },
            {
                name: i18n.t(k.END_DATE),
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (d) {
                    let end_date = i18n.t(k.NOT_SET);
                    if (d.rules.end_date) {
                        end_date = d.rules.end_date.value;
                    }
                    return <span>{end_date}</span>;
                },
            },
            {
                name: i18n.t(k.STATUS),
                sortable: true,
                cell: function (d) {
                    let state = d.state;
                    state = state.replace("_", " ");
                    return <span>{state}</span>;
                },
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.ACTION),
                selector: "action",
                cell: function (d) {
                    return (
                        <span>
                            <Link
                                to={`/review/${d._id}`}
                                className="pointer"
                                title={i18n.t(k.AGREEMENT_EDIT)}
                            >
                                <EditIcon />
                            </Link>
                            <Link
                                className="pointer ml-1"
                                title={i18n.t(k.AGREEMENT_PREVIEW)}
                                to={`/summary/${d._id}`}
                            >
                                <PictureAsPdf />
                            </Link>
                            <Link
                                className="pointer ml-1"
                                title={i18n.t(k.AGREEMENT_SEND_TO_TENANT)}
                                to={`#`}
                                onClick={() => {
                                    that.sendEmail(d);
                                }}
                            >
                                <Email />
                            </Link>
                            <Link
                                to={"#"}
                                className="pointer"
                                title={i18n.t(k.AGREEMENT_DELETE)}
                                onClick={() => {
                                    that.confirmDelete(d);
                                }}
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ];
    }

    createAgreement() {
        if (this.state.length) {
            let buildingsLength = this.state.length.buildings;
            let areasLength = this.state.length.areas;
            let floorplansLength = this.state.length.floorplans;
            let tenantsLength = this.state.length.tenants;
            if (buildingsLength <= 0) {
                NotificationManager.warning(
                    i18n.t(k.AGREEMENT_CREATE_WARNING_BUILDINGS),
                    i18n.t(k.AGREEMENT_CREATE_WARNING_TITLE)
                );
            } else if (areasLength <= 0) {
                NotificationManager.warning(
                    i18n.t(k.AGREEMENT_CREATE_WARNING_AREAS),
                    i18n.t(k.AGREEMENT_CREATE_WARNING_TITLE)
                );
            } else if (floorplansLength <= 0) {
                NotificationManager.warning(
                    i18n.t(k.AGREEMENT_CREATE_WARNING_FLOORPLANS),
                    i18n.t(k.AGREEMENT_CREATE_WARNING_TITLE)
                );
            } else if (tenantsLength <= 0) {
                NotificationManager.warning(
                    i18n.t(k.AGREEMENT_CREATE_WARNING_TENANTS),
                    i18n.t(k.AGREEMENT_CREATE_WARNING_TITLE)
                );
            } else {
                if (this.state.buildingId) {
                    this.props.history.push(
                        `/agreementNew/${this.state.buildingId}`
                    );
                } else {
                    this.props.history.push(`/agreementEdit/new`);
                }
            }
        }
    }

    render() {
        let that = this;
        let button = <CircularProgress size={35} />;
        if (!this.state.loading_button) {
            button = (
                <Button
                    theme="info"
                    onClick={() => {
                        // that.props.history.push(`/agreementEdit/new`)
                        this.createAgreement();
                    }}
                >
                    <AddIcon className="mr-2" />
                    {i18n.t(k.NEW_AGREEMENT)}
                </Button>
            );
        }
        return (
            <div>
                <div className="container-fluid">
                    {/*<PageHeaderComponent*/}
                    {/*title={i18n.t(k.AGREEME)}*/}
                    {/*subtitle="Overview" />*/}
                </div>
                <CardWrapperComponent
                    header={true}
                    footer={true}
                    card_title={i18n.t(k.AGREEMENTS)}
                >
                    {button}
                    {/*          <DataTable*/}
                    {/*autoWidth={true}*/}
                    {/*columns={this.getColumns()}*/}
                    {/*background="#ffffff00"*/}
                    {/*onRowClicked={this.rowSelected}*/}

                    {/*data={this.state.agreements}*/}
                    {/*progressPending={this.state.agreements}*/}
                    {/*progressComponent={<Circular />}*/}
                    {/*noHeader={true} />*/}
                    <PageableDatatablesComponent
                        // title="Employees"
                        className="border"
                        noHeader={true}
                        autoWidth={true}
                        columns={this.getColumns()}
                        background="#ffffff00"
                        items={this.state.agreements}
                        total={this.state.total}
                        itemsPerPage={20}
                        progressPending={this.state.loading}
                        onRowClicked={this.rowSelected}
                        loadItems={(page) => {
                            this.loadAgreements(page);
                        }}
                    />
                </CardWrapperComponent>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AgreementsListComponent);
