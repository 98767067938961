import i18n from 'i18next';import k from "./../../../../i18n/keys";import React, { Component } from 'react';
import CardWrapperComponent from "./../../../ui_utils/CardWrapperComponent";
import CanvasDraw from "react-canvas-draw";
import { Button } from "shards-react";
import AgreementSignModalComponent from "./AgreementSignModalComponent";
import HonkioAPI from "../../../../middlewares/HonkioAPI";
import 'react-confirm-alert/src/react-confirm-alert.css';
import CircularProgress from "@material-ui/core/CircularProgress";
import CenteredLoader from "../../../ui_utils/CenteredLoader";

const Circular = () =>
// we need to add some padding to circular progress to keep it from activating our scrollbar
<div style={{ padding: '24px' }}>
        <CircularProgress size={150} />
    </div>;


class AgreementSignComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalAfterSave: {
        visible: false },

      loading: true };

    this.hidemodalAfterSave = this.hidemodalAfterSave.bind(this);
    this.showmodalAfterSave = this.showmodalAfterSave.bind(this);
    this.togglemodalAfterSave = this.togglemodalAfterSave.bind(this);
    this.blobToFile = this.blobToFile.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    let that = this;
    let agreement = this.props.match.params.agreement;
    let secret = this.props.match.params.secret;
    const requestAgr = async () => {
      let response = await HonkioAPI().userShop.userFetch('merchantagreementlist', { 'query_id': agreement });
      // console.log(response);
      if (response.rents[0].secret === secret) {
        that.setState({ "loading": false });
      } else {
        that.setState({ "loading": false, "error": "Secret mismatch" });
      }
      that.setState({ 'agreement': response.rents[0], 'secret': secret });
    };
    requestAgr();
  }

  blobToFile(theBlob, fileName) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }


  save() {
    const canvasData = document.getElementsByTagName('canvas')[1];
    let sign = canvasData.toDataURL();
    let that = this;
    let signsave = async function () {
      let setUrl = await HonkioAPI().userShop.userFetch('merchantagreementset',
      {
        'id': that.props.match.params.agreement,
        'custom_fields': { "sign": sign } });

      if (setUrl.status === 'accept') {
        // console.log('saved');
      }
      const statusChangeParams = {
        'id': that.props.match.params.agreement,
        'state': 'signed',
        'send_email': true };

      let statusChange = await HonkioAPI().userShop.userFetch('merchantagreementset', statusChangeParams);
      if (statusChange.status === 'accept') {
        that.showmodalAfterSave();
      }
    };
    signsave();
  }

  hidemodalAfterSave() {
    this.setState({
      'modalAfterSave': {
        ...this.state.modalAfterSave,
        visible: false } });


  }

  showmodalAfterSave() {
    this.setState({
      'modalAfterSave': {
        ...this.state.modalAfterSave,
        visible: true } });


  }

  togglemodalAfterSave() {
    this.setState({
      'modalAfterSave': {
        ...this.state.modalAfterSave,
        visible: !this.state.modalAfterSave.visible } });


  }


  render() {

    if (this.state.loading) {
      return (
        <div className="center-block">
                    <CenteredLoader style={{ marginTop: 5 + "rem" }} />
                </div>);

    }

    if (this.state.error) {
      return (
        <div className="container">
                    <div className="vh-100" style={{ marginTop: 10 + 'rem' }}>
                        {i18n.t(k.RETURN)} <CenteredLoader />
                        <CardWrapperComponent card_title={i18n.t(k.SIGN)}
            style={{ minHeight: '500px' }}>
                            <div className="text-center mb-4">
                                <h2>{i18n.t(k.SIGN_AN_AGREEMENT)}</h2>
                            </div>
                            <div className="text-center w-100">
                                {i18n.t(k.SECRET_MISMATCH)}
                            </div>
                        </CardWrapperComponent>
                    </div>
                </div>);

    }

    return (
      <div className="container">
                <div className="vh-100" style={{ marginTop: 10 + 'rem' }}>
                    <CardWrapperComponent card_title={i18n.t(k.SIGN)}
          style={{ minHeight: '500px' }}>
                        <div className="text-center mb-4">
                            <h2>{i18n.t(k.SIGN_AN_AGREEMENT)}</h2>
                        </div>
                        <div className="text-center w-100">
                            {i18n.t(k.SOMETHING_SHORT_AND_LEADING_AB)}

              <br />
                            <Button theme="primary" className="mt-4"
              onClick={() => {
                this.save();
              }}>
                                {i18n.t(k.SIGN_AN_AGREEMENT)}
                            </Button>
                            <Button theme="warning" className="mt-4 ml-4"
              onClick={() => {
                this.canvas.clear();
              }}>
                                {i18n.t(k.RESET)}
                            </Button>
                        </div>
                        <div className="mt-4 center-block">
                            <CanvasDraw
              brushRadius={"1"}
              canvasWidth={"600"}
              ref={canvasDraw => this.canvas = canvasDraw}
              gridColor="rgba(150,150,150,0.25)" />

                        </div>
                    </CardWrapperComponent>
                    <AgreementSignModalComponent
          visible={this.state.modalAfterSave.visible}
          open={this.hidemodalAfterSave}
          toggle={this.togglemodalAfterSave}
          onRequestClose={this.props.onRequestClose} />

                </div>
            </div>);

  }}


export default AgreementSignComponent;
