import i18n from 'i18next';
import k from "./../../i18n/keys";
import React, {Component} from 'react';
import 'react-notifications/dist/react-notifications.css';
import 'react-notifications/dist/react-notifications.js';

import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from "react-redux";
import HonkioAPI from "../../middlewares/HonkioAPI";
import {Modal, ModalBody, ModalHeader} from "shards-react";
import parse from 'html-react-parser';
import {NotificationManager} from "react-notifications";

const Circular = () =>
// we need to add some padding to circular progress to keep it from activating our scrollbar
<div style={{ padding: '24px' }}>
    <CircularProgress size={75} />
  </div>;

// Fixme unify with userlistcomponent

class InvoicePreviewModal extends Component {
  constructor(props) {
    super(props);
    this.sendInvoice = this.sendInvoice.bind(this);
    // this.handleChange = this.handleChange.bind(this)
    this.state = {
      loading: true,
      sending: false,
      invoice: {} };

  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.invoice) {
      this.setState({ 'invoice': nextProps.invoice, 'sending': false });

      if (this.props.visible || nextProps.visible) {

        this.loadPreview(nextProps.invoice);
      }
    }
  }

  loadPreview = invoice => {
    let that = this;
    if (!invoice) {
      invoice = this.state.invoice;
    }
    let previewInvoice = async function () {

      console.error({ ...invoice });
      let response = await HonkioAPI().userShop.userFetch('merchantpaymentinvoiceset', {
        ...invoice, preview: true, status: 'sent' //due_date: invoice.due_date, ,
      });
      if (response.status === 'accept') {
        that.setState({ 'preview': response.payment_invoice, 'loading': false });
      }
    };
    previewInvoice();
  };
  sendInvoice() {
    let that = this;
    let sendInvoice = async function () {
      that.setState({ 'sending': true });
      console.error({ ...that.state.invoice, custom_fields: { building: that.state.building }, status: 'sent' });
      let a = {
        ...that.state.invoice, custom_fields: { building: that.props.building }, status: 'sent' };

      let response = await HonkioAPI().userShop.userFetch('merchantpaymentinvoiceset', {
        ...that.state.invoice, status: 'sent' });

      if (response.status === 'accept') {
        let id = response.payment_invoice.id;
        if (that.state.file) {
          that.uploadFile(id);
        }
        NotificationManager.success(i18n.t(k.INVOICE_SENT));
        that.setState({ 'sending': false });
        // if (that.props.match.params.invoiceId && that.props.match.params.invoiceId === 'new') {
        window.location.href = `/accounting?type=invoice&building=${that.state.invoice.custom_fields.building}`;
        // }
      }
    };
    sendInvoice();
  }
  render() {
    let preview = <CircularProgress size={75} />;
    if (!this.state.loading && this.state.preview) {
      preview = <div style={{ overflow: 'scroll', maxHeight: 600 }}>{parse(this.state.preview)}</div>;
    }
    //
    return (
      <Modal open={this.props.visible}
      toggle={this.props.toggle}
      size="lg"
      onRequestClose={this.props.onRequestClose}
      className="w-100">
                <ModalHeader>{i18n.t(k.PREVIEW_INVOICE)}</ModalHeader>
                <ModalBody>
                     {preview}
                 </ModalBody>
            </Modal>);

  }}



function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = dispatch => ({});


export default connect(mapStateToProps, mapDispatchToProps)(InvoicePreviewModal);
