import i18n from 'i18next';import k from "./../i18n/keys";import React, { Component } from 'react';
import { connect } from "react-redux";
import HonkioAPI from "../middlewares/HonkioAPI";
import { Button, FormGroup, FormInput } from "shards-react";
import SaveIcon from '@material-ui/icons/Save';
import CenteredPageLoader from "./ui_utils/CenteredPageLoader";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import EditableLabel from 'react-inline-editing';
import data from "./invoices/product_types.json";
import SettingsIcon from "@material-ui/icons/Settings";
import AddIcon from "@material-ui/icons/Add";
import { DeleteForever } from "@material-ui/icons";
import CardWrapperComponent from "./ui_utils/CardWrapperComponent";
import { NotificationManager } from "react-notifications";


function getItemsFromBackend() {
  let items = [];
  Object.keys(data).forEach(function (key) {
    items.push({ id: key, content: `${data[key].en} (${key})` });
  });
  return items;
}

function* idMaker() {
  let index = 1;
  while (true)
  yield index++;
}

const gen = idMaker();

const columnsFromBackend = {
  0: {
    name: i18n.t(k.ALL_CATEGORIES),
    items: getItemsFromBackend() } };




class MerchantProductClassGroupSettingsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      columns: columnsFromBackend };

    this.loadMerchant = this.loadMerchant.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    this.loadMerchant();
  }

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      // console.log('enter press here! ');
    }
    // console.log(event);
  };

  onDragEnd = (result, columns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      this.setState({
        columns: {
          ...columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems },

          [destination.droppableId]: {
            ...destColumn,
            items: destItems } } });



    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      this.setState({
        columns: {
          ...columns,
          [source.droppableId]: {
            ...column,
            items: copiedItems } } });



    }
  };

  find(items, text) {
    text = text.split(' ');
    return items.filter(function (item) {
      return text.every(function (el) {
        return item.content.indexOf(el) > -1;
      });
    });
  }


  search = (column, text, columnID) => {
    let items = getItemsFromBackend();
    let result = this.find(items, text);
    this.setState({
      columns: {
        ...this.state.columns, [columnID]: {
          ...this.state.columns[columnID],
          items: result } } });



  };

  getDroppable = (provided, snapshot, column) => {
    return (
      <div {...provided.droppableProps}
      ref={provided.innerRef}
      style={{
        background: snapshot.isDraggingOver ?
        "lightblue" :
        "white",
        padding: 4,
        width: 250,
        minHeight: 500 }}>

                {column.items.map((item, index) => {

          return (
            <Draggable
            key={item.id}
            draggableId={item.id}
            index={index}>
                            {(provided, snapshot) => {
                return (
                  <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{
                    userSelect: "none",
                    padding: 16,
                    margin: "0 0 8px 0",
                    minHeight: "50px",

                    backgroundColor: snapshot.isDragging ?
                    "#263B4A" :
                    "#456C86",
                    color: "white",
                    ...provided.draggableProps.style }}>


                                        {item.content}
                                    </div>);

              }}
                        </Draggable>);

        })}
                {provided.placeholder}
            </div>);

  };

  loadMerchant() {
    let that = this;
    let merchantGet = async function () {
      let response = await HonkioAPI().userShop.userFetch('merchantget', {});
      let newState = {
        'merchant': response.merchant,
        'loading': false,
        'columns': response.merchant.list_product_categories_groups.length > 0 ? response.merchant.list_product_categories_groups[0] : columnsFromBackend };

      that.setState(newState);
    };
    merchantGet();
  }

  save = () => {
    let that = this;
    let merchantSet = async function () {
      let consumer = await HonkioAPI().userShop.userFetch('consumerget', {});
      let response = await HonkioAPI().userShop.userFetch('merchantset', {
        'id': localStorage.getItem('merchant_id'),
        'login_identity': consumer.login_identity,
        'merchant': {
          'list_product_categories_groups': [that.state.columns] },

        'adminuser': {} });

      let merchant = await HonkioAPI().userShop.userFetch('merchantget', {});
      NotificationManager.success(i18n.t(k.SETTINGS_SAVED));
      that.setState({ 'merchant': response.merchant, 'loading': false });
      that.loadMerchant();
    };
    merchantSet();
  };

  delete(categoryId) {
    delete this.state.columns[categoryId];
  }

  render() {
    if (this.state.loading) {
      return <CenteredPageLoader />;
    }
    let that = this;
    if (this.state.columns) {
      // console.log(this.state.columns);
    }
    return (
      <div>
                <Button className="m-3"
        onClick={() => that.setState({
          columns: {
            ...that.state.columns,
            [gen.next().value]: {
              "name": "New Category",
              items: [] } } })}>


          <AddIcon />{i18n.t(k.ADD_CATEGORY_GROUP)}</Button>
                <Button className="m-3" onClick={() => {this.save();}}><SaveIcon />{i18n.t(k.SAVE_PRODUCT_CATEGORIES_GROUPS)}</Button>

                <div style={{ display: "flex", justifyContent: "left", flexWrap: "wrap", margin: 10 }}>

                    <DragDropContext onDragEnd={result => that.onDragEnd(result, that.state.columns)}>
                        {Object.entries(that.state.columns).map(([columnId, column], index) => {
              return (
                <div className="card" style={{ margin: 10 }}>
                                    <div className="card-header pr-1 pl-4 pb-1 border-bottom bg-light ">
                                        <div className="d-flex">
                                            <h6 className="text-muted mb-0 text-uppercase">
                                                <EditableLabel
                        text={column.name}
                        inputWidth='250px'
                        inputHeight='36px'
                        inputMaxLength='50'
                        onKeyDown={event => {
                          // console.log(event);
                        }}
                        onFocusOut={text => {
                          let columns = { ...that.state.columns };
                          columns[columnId].name = text;
                          that.setState({ columns: columns });
                        }} />
                                            </h6>
                                            <div className="col float-right" onClick={() => {this.delete(columnId);}}><DeleteForever
                        className="float-right pointer" /></div>
                                        </div>
                                        <div className="flex-column" />
                                        {columnId === i18n.t(k._3) ? <FormGroup>
                                            <FormInput
                      onChange={e => that.search(column, e.target.value, columnId)}
                      placeholder={i18n.t(k.SEARCH_CATEGORY)} />
                                        </FormGroup> : ""}

                                    </div>
                                    <div className="card-body pt-1" style={{ height: "500px", overflowY: "auto" }}
                  key={columnId}>
                                        <div style={{ margin: 8 }}>
                                            <Droppable droppableId={columnId} key={columnId}>
                                                {(provided, snapshot) => this.getDroppable(provided, snapshot, column)}
                                            </Droppable>
                                        </div>
                                    </div>
                                    <div className="card-footer border-top" />
                                </div>);

            })}
                    </DragDropContext>

                </div>
            </div>);

  }}


function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = dispatch => ({
  merchantgetSuccess: merchant => dispatch({
    type: "GET_MERCHANT_SUCCESS",
    merchantInfo: merchant.merchant }) });


export default connect(mapStateToProps, mapDispatchToProps)(MerchantProductClassGroupSettingsComponent);
