import i18n from 'i18next';
import k from "./../../i18n/keys";
import React, { useEffect, useState } from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import { Modal, Button } from 'react-bootstrap';
import MapStreetView from './MapStreetView';

const Circular = () =>
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>;

const MapStreetViewModal = ({ name, latitude, longitude, onSaveUrl = () => { } , show, onHide, }) => {

    let isMounted = true;
    const [url, setUrl] = useState();

    useEffect(() => {
        return () => {
            isMounted = false
        };
    }, []);

    const onSaveClicked = () => {
        // console.log(url,"------------onSaveUrl",name)
        onSaveUrl(name, url)
    }
    const onChangeUrl = (name, url) => {
        // console.log(url,"------------url")
        setUrl(url)
     }

    return (
        <div>
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{i18n.t(k.STREET_VIEW)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MapStreetView
                        name={name}
                        latitude={latitude}
                        longitude={longitude}
                        onChangeUrl={onChangeUrl}
                    />
                    {/* latitude = '40.714728', longitude = '-73.998672' */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => onHide()}>Close</Button>
                    <Button variant="primary" onClick={() => onSaveClicked()}>Save</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default MapStreetViewModal;
