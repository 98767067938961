import React, { Component } from "react";
import "react-notifications/dist/react-notifications.css";
import "react-notifications/dist/react-notifications.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataTable from "react-data-table-component";
import i18n from "i18next";
import k from "../../i18n/keys";

const Circular = () => (
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

const PAGE_ITEMS_COUNT = 20;

class PageableDatatablesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
        };
    }

    render() {
        return (
            <DataTable
                data={this.props.items}
                progressComponent={<Circular />}
                pagination
                progressPending={this.props.progressPending}
                paginationServer={true}
                paginationPerPage={
                    this.props.itemsPerPage
                        ? this.props.itemsPerPage
                        : PAGE_ITEMS_COUNT
                }
                paginationTotalRows={this.props.total}
                onChangePage={(page) => {
                    page--;
                    this.setState({ page: page });
                    this.props.loadItems(page);
                }}
                noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                paginationComponentOptions={{
                    rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                    rangeSeparatorText: i18n.t(k.ROWS_OF),
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                }}
                paginationRowsPerPageOptions={[
                    this.props.itemsPerPage
                        ? this.props.itemsPerPage
                        : PAGE_ITEMS_COUNT,
                ]}
                {...this.props}
            />
        );
    }
}

export default PageableDatatablesComponent;
