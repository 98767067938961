import i18n from 'i18next';
import k from "./../../i18n/keys";
import React, {Component} from 'react';
import 'react-notifications/dist/react-notifications.css';
import 'react-notifications/dist/react-notifications.js';

import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from "react-redux";
import HonkioAPI from "../../middlewares/HonkioAPI";
import {Button, FormGroup, FormInput, Modal, ModalBody, ModalHeader} from "shards-react";
import Select from "react-select";
import getValues from "./product_type_utils";

const Circular = () =>
// we need to add some padding to circular progress to keep it from activating our scrollbar
<div style={{ padding: '24px' }}>
    <CircularProgress size={75} />
  </div>;

// Fixme unify with userlistcomponent

class InvoiceProductAddModal extends Component {
  constructor(props) {
    super(props);
    this.saveProduct = this.saveProduct.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.state = {
      'product': {
        int_vat: 24 } };


  }

  componentWillReceiveProps(nextProps, nextContext) {
    // if (nextProps.product) {
    //     this.setState({'product': nextProps.product})
    // }
  }

  handleChange(e) {
    let newState = {};
    // switch (e.target.id) {
    //     case 'amount':
    //          var vat_divider = 1 + (this.state.product.int_vat / 100.0);
    //          var amount = parseFloat(e.target.value)
    //         var vat_amount = amount - (amount / vat_divider);
    //         newState = {
    //             'product': {...this.state.product,
    //                 [e.target.id]: e.target.value,
    //                 'amount_vat': vat_amount.toFixed(2),
    //                 'amount_no_vat': (amount-vat_amount).toFixed(2),
    //             }
    //         }
    //         break;
    //     case 'amount_no_vat':
    //         var vat_divider = (this.state.product.int_vat / 100.0);
    //         var amount_no_vat = parseFloat(e.target.value)
    //         var amount = amount_no_vat * (1 + parseFloat(vat_divider))
    //         var vat_amount = amount - amount_no_vat;
    //         newState = {
    //             'product': {...this.state.product,
    //                 'amount': amount.toFixed(2),
    //                 'amount_vat': vat_amount.toFixed(2),
    //                 // 'amount_no_vat': amount_no_vat.toFixed(2),
    //             }
    //         }
    //         break;
    //     default:
    newState = { 'product': { ...this.state.product, [e.target.id]: e.target.value } };
    // break;
    // }
    this.setState(newState);
  }
  onBlur(e) {
    let newState = {};
    switch (e.target.id) {
      case 'amount':
        var vat_divider = 1 + this.state.product.int_vat / 100.0;
        var amount = parseFloat(e.target.value);
        var vat_amount = amount - amount / vat_divider;
        newState = {
          'product': { ...this.state.product,
            'amount': amount.toFixed(2),
            'amount_vat': vat_amount.toFixed(2),
            'amount_no_vat': (amount - vat_amount).toFixed(2) } };


        break;
      case 'amount_no_vat':
        var vat_divider = this.state.product.int_vat / 100.0;
        var amount_no_vat = parseFloat(e.target.value);
        var amount = amount_no_vat * (1 + parseFloat(vat_divider));
        var vat_amount = amount - amount_no_vat;
        newState = {
          'product': { ...this.state.product,
            'amount': amount.toFixed(2),
            'amount_vat': vat_amount.toFixed(2),
            'amount_no_vat': amount_no_vat.toFixed(2) } };


        break;
      default:
        newState = { 'product': { ...this.state.product, [e.target.id]: e.target.value } };
        break;}

    this.setState(newState);
  }

  componentDidMount() {
    this.loadMerchant();
  }

  loadMerchant() {
    let that = this;
    let merchantGet = async function () {
      let response = await HonkioAPI().userShop.userFetch('merchantget', {});
      let newState = {
        'merchant': response.merchant,
        'loading': false,
        'columns': response.merchant.list_product_categories_groups[0] };

      that.setState(newState);
      console.error('response');
      console.error(response);
    };
    merchantGet();
  }

  saveProduct() {
    let b = this.state.product;

    this.props.onAddProductClicked({ ...this.state.product, count: 1 });
    this.setState({ 'product': {} });
  }

  render() {
    let b = this.state.product;
    // console.log('picker modal rendering');
    // console.log(this.state);

    let options = [];
    if (this.state.columns) {
      let data = this.state.columns;
      let basic = this.state.columns[0].items;
      let clone = Object.assign({}, data, { 0: undefined });
      Object.keys(basic).forEach(function (key) {
        options.push({ value: `${basic[key].id}`, label: `${basic[key].content}` });
      });
      for (const [key, value] of Object.entries(clone)) {
        if (value !== undefined) {
          options.push({ value: key, label: `${value.name} (${key})` });
        }
      }
    } else {
        options = getValues()
    }
    // console.log(this.props.visible)
    // alert(this.state.product.class)
    return (
      <Modal open={this.props.visible}
      toggle={this.props.toggle}
      size="lg"
      onRequestClose={this.props.onRequestClose}
      className="w-100">
                <ModalHeader>{i18n.t(k.ADD_A_PRODUCT)}</ModalHeader>
                <ModalBody>
                            <FormGroup>
                                <label htmlFor="name">{i18n.t(k.NAME)}</label>
                                <FormInput id="name" placeholder={i18n.t(k.NAME)} type="text" value={this.state.product.name}
            onChange={e => this.handleChange(e)} />
                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="amount">{i18n.t(k.TOTAL_AMOUNT)}</label>
                                <FormInput id="amount" placeholder={i18n.t(k.TOTAL_AMOUNT)} type="text" value={this.state.product.amount}
            onChange={e => this.handleChange(e)}
            onBlur={this.onBlur} />

                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="amount_no_vat">{i18n.t(k.AMOUNT_NO_VAT)}</label>
                                <FormInput id="amount_no_vat" placeholder={i18n.t(k.AMOUNT_NO_VAT)} type="text" value={this.state.product.amount_no_vat}
            onChange={e => this.handleChange(e)} />
                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="amount_vat">{i18n.t(k.VAT_AMOUNT)}</label>
                                <FormInput id="amount_vat" placeholder={i18n.t(k.VAT_AMOUNT)} type="text" value={this.state.product.amount_vat}
            onChange={e => this.handleChange(e)} />
                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="type">{i18n.t(k.VAT_CLASS)}</label>
                                <Select id="type" options={[
            { 'value': 0, label: i18n.t(k._19) },
            { 'value': 10, label: i18n.t(k._20) },
            { 'value': 14, label: i18n.t(k._21) },
            { 'value': 24, label: i18n.t(k._22) }]}
            defaultValue={{ 'value': 24, label: i18n.t(k._22) }} onChange={e => {
              var amount = parseFloat(this.state.product.amount);
              var vat_divider = 1 + parseInt(e.value) / 100.0;
              var vat_amount = amount - amount / vat_divider;
              this.setState({
                'product': { ...this.state.product,
                  'int_vat': e.value,
                  'amount': amount.toFixed(2),
                  'amount_vat': vat_amount.toFixed(2),
                  'amount_no_vat': (amount - vat_amount).toFixed(2) } });


            }} />
                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="type">{i18n.t(k.TYPE)}</label>
                                <Select id="type" options={options} defaultValue={() => {
              let vals = getValues();
              if (this.state.product.class) {
                for (var i = 0; i < vals.length; i++) {
                  if (vals[i].id === this.state.product.class) {
                    return vals[i];
                  }
                }
              }
            }} onChange={e => {
              this.setState({
                'product': { ...this.state.product,
                  'class': e.value } });


            }} />
                            </FormGroup>
                            <Button className="m-3" onClick={this.saveProduct} disabled={
          !this.state.product.amount || !this.state.product.amount_vat || !this.state.product.name}>
            {i18n.t(k.SAVE)}</Button>
                 </ModalBody>
            </Modal>);

  }}



function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = dispatch => ({});


export default connect(mapStateToProps, mapDispatchToProps)(InvoiceProductAddModal);
