const initialState = {
  loaded: false,
  dashboard: {
    positions: [],
    items: [] } };



function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case "DASHBOARD_LOADED":
      // debugger; DEBUGGERS ARE NOT ALLOWED IN GIT, NEVER PUSH DEBUGGERS WITH YOUR COMMITS

      // NEVER

      // !!!


      return {
        ...state,
        loaded: true,
        dashboard: action.dashboard };

    case "DASHBOARD_ERROR":
      return initialState;
    default:
      return state;}

}

export default dashboardReducer;