import React, { Component } from "react";

class StrippedLayout extends Component {
  render() {
    return (
      <div>
                {this.props.children}
            </div>);

  }}


export default StrippedLayout;