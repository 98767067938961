import i18n from 'i18next';import k from "./../../../../i18n/keys";import React, { Component } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Button } from "shards-react";
import HonkioAPI from "../../../../middlewares/HonkioAPI";
import domToPdf from 'dom-to-pdf';
import PdfComponent from "./PdfComponent";

export default class PortfolioListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { ready: false };
  }

  testApi() {
    let that = this;
    let merchantbuildingcert = async function () {
      let response = await HonkioAPI().userShop.userFetch('merchantbuildingcertset', { "building_id": "5fa945710c8c7fb5c3b29818" });
      // console.log(response);
      that.setState({ 'ready': true, string: response.text });
    };
    merchantbuildingcert();
  }

  generatePdf() {
    const element = document.getElementById("doc");
    const options = { filename: "test.pdf" };
    return domToPdf(element, options);
  }

  render() {
    let that = this;
    let but, div;
    if (this.state.ready) {
      div = <PdfComponent id='doc' html={this.state.string} />;
      but = <Button theme="info" onClick={() => {that.generatePdf();}}><AddIcon className="mr-2" />{i18n.t(k.LOAD_BUILDING_CERT)}</Button>;
    }

    return (
      <div>
				<Button theme="info" onClick={() => {
          that.testApi();
        }}><AddIcon className="mr-2" />{i18n.t(k.GENERATE_PDF)}</Button>
				{but}
				{div}
			</div>);

  }}