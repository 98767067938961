import i18n from 'i18next';
import k from '../../../../i18n/keys';
import React, { Component, useRef, useState } from 'react';
import CardWrapperComponent from "../../../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "shards-react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import NotificationManager from "react-notifications/lib/NotificationManager";
import HonkioAPI from "../../../../middlewares/HonkioAPI";
import JoditEditor from "jodit-react";
import AgreementReviewPdfComponent from "./AgreementReviewPdfComponent";

const Circular = () =>
  <div style={{ padding: '24px' }}>
    <CircularProgress size={75} />
  </div>;

class AgreementAfterSaveComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      document: {},
      agreement:{}
    };
    this.loadAgreement = this.loadAgreement.bind(this);
    this.createTemplate = this.createTemplate.bind(this);
  }

  componentDidMount() {
    let agreement = this.props.match.params.agreement;
    this.setState({ 'agreement': agreement });
    this.loadAgreement(agreement);
  }

  loadAgreement(agreement) {
    let that = this;
    let merchantagreementtemplatelist = async function () {
      let query = {
        //merchant_id: localStorage.getItem('merchant_id') ,
        rent_id: agreement
      };
      let responseDocument = await HonkioAPI().userShop.userFetch('merchantagreementdocumentlist', query, error => {
        NotificationManager.error(`${error?.description}`, i18n.t(k.ERROR));
        that.setState({ loading: false });
      });
      if (responseDocument?.status === 'accept' && responseDocument?.template && responseDocument?.template.length > 0) {
        that.setState({ document: responseDocument.template[0], 'loading': false });
      } else {
        NotificationManager.error('Document not found');
        that.setState({ document: {}, 'loading': false });
      }
      let responseAgreement = await HonkioAPI().userShop.userFetch('merchantagreementlist', { "query_id": agreement }, error => {
        NotificationManager.error(`${error?.description}`, i18n.t(k.ERROR));
        that.setState({ loading: false });
      });
      if (responseAgreement?.status === 'accept' && responseAgreement?.rents && responseAgreement?.rents.length > 0) {
        that.setState({ agreement: responseAgreement.rents[0] });
      } else {
        NotificationManager.error('Agreement not found');
        that.setState({ agreement: {}, loading: false });
      }
    };
    merchantagreementtemplatelist();
  }

  createTemplate() {
    let that = this;
    let merchanagreementtemplateset = async function () {
      let rules = {};
      Object.keys(that.state.agreement.rules).forEach(k => {
        if (that.state.agreement.rules[k].value && that.state.agreement.rules[k].value !== null) {
          if (that.state.agreement.rules[k].label === null) {
            that.state.agreement.rules[k]['label'] = k;
          }
          rules[k] = that.state.agreement.rules[k];
        }
      });
      let response = await HonkioAPI().userShop.userFetch('merchantagreementtemplateset', {
        'title': that.state.agreement.name,
        'rules': rules
      });
      if (response.status === 'accept') {
        NotificationManager.success(i18n.t(k.CREATED), i18n.t(k.TEMPLATE_HAS_BEEN_CREATED));
        that.props.history.push(`/agreements`);
      }
    };
    if (this.state.agreement) {
      merchanagreementtemplateset();
    }
  }

  render() {
    if (this.state.loading) 
    return  <Circular />;
    let agreement
    if (this.state.document) {
      agreement = <AgreementReviewPdfComponent
        visible={false}
        text={this.state.document.text} />;
    }
    return (
      <div>
        {agreement}
      </div>);
  }
}

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AgreementAfterSaveComponent);