import React, { Component } from "react";
import { connect } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import { format } from "d3-format";
import {
    BarChart,
    ChartContainer,
    ChartRow,
    Charts,
    EventMarker,
    Legend,
    LineChart,
    ScatterChart,
    styler,
    YAxis,
} from "react-timeseries-charts";
import { TimeRange, TimeSeries } from "pondjs";
import moment from "moment";
import "moment/locale/fi";
import CenteredPageLoader from "../ui_utils/CenteredPageLoader";
import CenteredLoader from "../ui_utils/CenteredLoader";
import { Button, FormCheckbox } from "shards-react";
import i18n from "i18next";
import k from "../../i18n/keys";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

class CostsChartComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            costsSeries: null,
            timerange: new TimeRange([new Date(1, 1), new Date(1, 1, 1)]),
            total: {},
            options: {},
            mode: "month",
            costsMode: "total",
            chartType: "bars",
            areaData: [],
            columns: [],
            excludeColumns: ["Costs Forecast"],
            allColumns: [],
        };
        this.setContainerRef = (element) => {
            this.container = element;
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let costs, areasData, options;

        this.setState({ costsSeries: null, loading: true });
        if (nextProps.costs) {
            costs = nextProps.costs;
        }
        if (nextProps.areasData) {
            areasData = nextProps.areasData;
        }
        if (nextProps.options) {
            options = nextProps.options;
        }
        this.parseChart(costs, areasData, options);
    }

    componentDidMount() {
        this.parseChart(this.props.costs, this.props.areasData);
    }

    onColumnCheckClick = (k) => {
        if (this.state.excludeColumns.indexOf(k) >= 0) {
            this.parseChart(undefined, undefined, {
                excludeColumns: this.state.excludeColumns.filter(
                    (o) => o !== k
                ),
            });
        } else {
            this.parseChart(undefined, undefined, {
                excludeColumns: [...this.state.excludeColumns, k],
            });
        }
    };

    parseChart = (costs, areaData, options) => {
        if (!options) options = this.state.options;
        let that = this;
        that.setState({ loading: true, costsSeries: null });
        costs = costs ? costs : this.state.costs;
        let mode = options.mode ? options.mode : that.state.mode;
        let costsMode = options.costsMode
            ? options.costsMode
            : that.state.costsMode;
        let excludeColumns = options.excludeColumns
            ? options.excludeColumns
            : that.state.excludeColumns;
        let inflation = options.inflation ? options.inflation : 5;
        let modelLength = options.modelLength ? options.modelLength : 10;
        if (modelLength > 15 || modelLength < 1) modelLength = 10;
        let chartType = options.chartType
            ? options.chartType
            : that.state.chartType;
        areaData = areaData ? areaData : this.state.areaData;

        // areaData.splice(0, areaData.length - 5)
        // areaData=[]
        let data = {};
        let total = {};
        let points = [];
        let columns = [];
        let allColumns = [];
        let maxSum = 0,
            minSum = 0,
            max = 0,
            min = 0;

        costs.forEach((d) => {
            Object.keys(d.data).forEach((k) => {
                if (!columns.includes(k) && excludeColumns.indexOf(k) < 0)
                    columns.push(k);
                if (!allColumns.includes(k)) allColumns.push(k);
            });
        });

        allColumns = [...allColumns, "Costs Forecast"];

        costs.forEach((d) => {
            let timestamp = moment(d.updated_date).startOf("month").toDate(); //.format('YYYY-MM');
            if (that.state.mode === "year") {
                timestamp = moment(d.updated_date).startOf("year").toDate(); //.format('YYYY');
            }
            let vals = [];
            let sum = 0;
            allColumns.forEach((cname) => {
                if (!total[cname]) {
                    total[cname] = parseFloat(d.data[cname]) || 0;
                } else {
                    total[cname] += parseFloat(d.data[cname]) || 0;
                }

                if (excludeColumns.includes(cname)) {
                    return;
                }
                if (d.data[cname] && parseFloat(d.data[cname])) {
                    vals.push(d.data[cname]);

                    sum += d.data[cname];
                    if (d.data[cname] > max) max = d.data[cname];
                    if (d.data[cname] < min) min = d.data[cname];
                } else {
                    vals.push(0);
                }
            });

            // if (!allZeros) {
            if (!data[timestamp]) data[timestamp] = [];
            data[timestamp] = vals.map((v, i) => {
                return (data[timestamp][i] || 0) + v;
            });
            // }
        });

        Object.keys(data).forEach((k) => {
            if (costsMode === "total") {
                points.push([k, data[k].reduce((a, b) => a + b, 0)]);
            } else {
                points.push([k, ...data[k]]);
            }
        });

        points = points.sort((a, b) => {
            return b[0] - a[0];
        });
        for (var i = 0; i < points.length; i++) {
            let str = moment(points[i][0]);
            if (mode === "month") {
                str = str.startOf("month").format("YYYY-MM");
            } else {
                str = str.startOf("year").format("YYYY");
            }
            points[i][0] = str;
            if (excludeColumns.indexOf("Costs Forecast") < 0) {
                points[i].push(0);
            }
            let sum = 0;
            points[i].forEach((v) => {
                sum += parseFloat(v);
            });
            if (sum > maxSum) maxSum = sum;
            if (sum < minSum) minSum = sum;
        }
        if (excludeColumns.indexOf("Costs Forecast") < 0) {
            let allCosts = 0;
            Object.keys(total).forEach((k) => {
                allCosts += total[k];
            });
            let avgCosts = Math.abs(
                allCosts /
                    moment(points[0][0]).diff(
                        moment(points[points.length - 1][0]),
                        mode === "month" ? "months" : "years",
                        true
                    )
            );
            let currentMonth = moment(points[points.length - 1][0]).toDate();
            let currentForecast = avgCosts;
            for (
                var m = 0;
                m < (mode === "month" ? 12 : 1) * modelLength;
                m++
            ) {
                currentMonth = moment(currentMonth)
                    .add(mode === "month" ? 1 : 12, "month")
                    .toDate();
                let str = moment(currentMonth);
                if (mode === "month") {
                    str = str.startOf("month").format("YYYY-MM");
                } else {
                    str = str.startOf("year").format("YYYY");
                }
                currentForecast +=
                    (currentForecast * parseFloat(0.01 * inflation)) /
                    (mode === "month" ? 12 : 1);
                points.push([
                    str,
                    ...allColumns
                        .slice(0, allColumns.length - 1)
                        .map((o) => 0.0),
                    currentForecast,
                ]);
            }
            columns.push("Costs Forecast");
        }

        columns = [
            "index",
            ...columns.filter((c) => !excludeColumns.includes(c)),
        ];
        allColumns = ["index", ...allColumns];
        let costsSeries = new TimeSeries({
            name: "index",
            columns: columns,
            points: points,
        });
        let newState = {
            costsSeries: costsSeries,
            timerange:
                points.length > 0
                    ? costsSeries.timerange()
                    : new TimeRange(
                          moment().startOf("month"),
                          moment().endOf("month")
                      ),
            columns: columns,
            options: options,
            allColumns: allColumns,
            excludeColumns: excludeColumns,
            costs: costs,
            maxSum: maxSum,
            minSum: minSum,
            max: max,
            min: min,
            total: total,
            mode: mode,
            areaData: areaData,
            chartType: chartType,
        };
        if (this.state.allColumns.length <= 0) {
            newState["style"] = styler(allColumns, "Paired");
        }
        that.setState(newState);
    };

    handleTimeRangeChange = (timerange) => {
        this.setState({ timerange });
    };

    handleTrackerChanged = (t) => {
        try {
            if (t && this.state.incomeSeries) {
                const e = this.state.incomeSeries.atTime(t);
                const eventTime = new Date(
                    e.begin().getTime() +
                        (e.end().getTime() - e.begin().getTime()) / 2
                );

                const eventValue = e.get(this.state.building.name);
                // alert(eventValue)
                const v = `${eventValue > 0 ? "+" : ""}${parseFloat(
                    eventValue
                ).toFixed(2)} EUR}`;
                this.setState({
                    tracker: eventTime,
                    trackerValue: v,
                    trackerEvent: e,
                });
            } else {
                this.setState({
                    tracker: null,
                    trackerValue: null,
                    trackerEvent: null,
                });
            }
        } catch (e) {
            console.error(e);
            this.setState({
                tracker: null,
                trackerValue: null,
                trackerEvent: null,
            });
        }
    };

    handleMouseNear = (point) => {
        this.setState({
            highlight: point,
        });
    };

    render() {
        let that = this;
        if (this.state.loading) {
            // fixme PLEASE, what a shame!
            if (this.state.costsSeries !== null) that.parseChart();
            this.setState({ loading: false });
            return <div>Loading...</div>;
        }

        let infoValues = [];
        let infoNetValues = [];
        const formatter = format(".2s");
        if (this.state.highlight) {
            const valueText = parseFloat(
                this.state.highlight.event.get(this.state.highlight.column)
            ).toFixed(2);
            infoNetValues = [
                { label: this.state.highlight.column, value: valueText },
            ];
            const speedText = `${formatter(
                this.state.highlight.event.get(this.state.highlight.column)
            )} EUR`;
            infoValues = [{ label: "Amount", value: speedText }];
        }
        // else if (this.state.tracker) {

        // const speedText = `${formatter(this.state.tracker.event.get(this.state.tracker.column))} EUR`;
        // text = `
        //     Speed: ${speedText},
        //     time: ${this.state.highlight.event.timestamp().toLocaleTimeString()}
        // `;
        // infoValues = [{ label: "Speed", value: speedText }];

        // }

        const infoStyle = {
            stroke: "#FF0000",
            color: "#00FF00",
            fill: "0000FF",
            opacity: 1.0,
            pointerEvents: "none",
            fontSize: "large",
            labelStyle: {
                fontSize: "normal",
                color: "#000000",
            },
        };
        const perEventStyle = (column, event) => {
            const color = "steelblue"; // heat[Math.floor((1 - event.get("station1") / 40) * 9)];
            return {
                normal: {
                    fill: color,
                    opacity: 1.0,
                },
                highlighted: {
                    fill: color,
                    stroke: "none",
                    opacity: 1.0,
                },
                selected: {
                    fill: "none",
                    stroke: "#2CB1CF",
                    strokeWidth: 3,
                    opacity: 1.0,
                },
                muted: {
                    stroke: "none",
                    opacity: 0.4,
                    fill: color,
                },
            };
        };
        return (
            <div id="costs_chart">
                <div
                    className="evaluators_left_column"
                    ref={this.setContainerRef}
                >
                    <ChartContainer
                        width={1000}
                        height="500"
                        timeRange={this.state.timerange}
                        enablePanZoom={true}
                        onTimeRangeChanged={this.handleTimeRangeChange}
                        infoStyle={infoStyle}
                        onTrackerChanged={this.handleTrackerChanged}
                        format={
                            that.state.mode === "month"
                                ? (d, c, e) => {
                                      return moment(d).format("MMM");
                                  }
                                : "year"
                        }
                    >
                        <ChartRow
                            height="500"
                            trackerInfoWidth={125}
                            trackerInfoHeight={30}
                            trackerInfoValues={infoValues}
                        >
                            <YAxis
                                id="axis"
                                // height="250"
                                // label="Costs"
                                // classed="Costs"
                                min={
                                    this.state.chartType === "bars"
                                        ? this.state.minSum * 1.25
                                        : this.state.min * 1.25
                                }
                                max={
                                    this.state.chartType === "bars"
                                        ? this.state.maxSum * 1.25
                                        : this.state.max * 1.25
                                }
                                // max={(this.state.chartType === 'bars' ? this.state.maxSum : this.state.max) * 1.25}
                                // width="70"
                                type="linear"
                            />
                            <Charts>
                                {this.state.chartType === "bars" ? (
                                    <BarChart
                                        axis="axis"
                                        style={this.state.style}
                                        // spacing={5}
                                        minBarHeight={1}
                                        // size={this.state.mode === 'month' ? 25 : 50}
                                        height="500"
                                        width="70"
                                        columns={this.state.columns.slice(
                                            1,
                                            this.state.columns.length
                                        )}
                                        series={this.state.costsSeries}
                                        info={infoNetValues}
                                        labelStyle={{
                                            paddingRight: 10,
                                            cursor: "pointer",
                                        }}
                                        infoStyle={infoStyle}
                                        // stemStyle={infoStyle}
                                        markerStyle={infoStyle}
                                        infoHeight={50}
                                        infoWidth={250}
                                        spacing={5}
                                        highlighted={this.state.highlight}
                                        onHighlightChange={(highlight) =>
                                            this.setState({ highlight })
                                        }
                                        selected={this.state.selection}
                                        // onSelectionChange={selection => this.setState({selection})}
                                    />
                                ) : (
                                    [
                                        <ScatterChart
                                            axis="axis"
                                            columns={this.state.columns}
                                            series={this.state.costsSeries}
                                            style={this.state.style}
                                            info={infoValues}
                                            infoHeight={28}
                                            infoWidth={110}
                                            infoStyle={{
                                                fill: "black",
                                                color: "#DDD",
                                            }}
                                            format=".1f"
                                            onMouseNear={this.handleMouseNear}
                                            highlight={this.state.highlight}
                                            radius={(event, column) => 3}
                                        />,
                                        <LineChart
                                            axis="axis"
                                            smooth={false}
                                            interpolation={"curveNatural"}
                                            columns={this.state.columns}
                                            series={this.state.costsSeries}
                                            style={this.state.style}
                                        />,
                                    ].map((o) => o)
                                )}
                            </Charts>
                        </ChartRow>
                    </ChartContainer>
                </div>
                <div className="w-100 col-9 evaluators_right_column">
                    <h3>Costs</h3>
                    {/*Object.keys(this.state.total)*/}
                    <Legend
                        categories={this.state.allColumns
                            .filter((o) => o !== "index")
                            .map((k) => {
                                return {
                                    key: k,
                                    label: (
                                        <FormCheckbox
                                            checked={
                                                this.state.excludeColumns.indexOf(
                                                    k
                                                ) < 0
                                            }
                                            onChange={(e) => {
                                                that.onColumnCheckClick(k);
                                            }}
                                        >
                                            {k !== "Costs Forecast"
                                                ? `${k}: ${parseFloat(
                                                      that.state.total[k]
                                                  ).toFixed(2)}`
                                                : "Costs forecast"}
                                        </FormCheckbox>
                                    ),
                                };
                            })}
                        style={this.state.style}
                        type="dot"
                    />
                    <div className="row">
                        <h3>Utils</h3>
                    </div>
                    <div className="row">
                        <Button
                            theme={"secondary"}
                            onClick={() => {
                                that.parseChart(undefined, undefined, {
                                    excludeColumns: [],
                                });
                            }}
                        >
                            Select ALL
                        </Button>
                        <Button
                            theme={"secondary"}
                            className="ml-3"
                            onClick={() => {
                                that.parseChart(undefined, undefined, {
                                    excludeColumns: [...that.state.allColumns],
                                });
                            }}
                        >
                            Select None
                        </Button>
                    </div>
                </div>
                <div className="row"></div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CostsChartComponent);
