import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { Component } from "react";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataTable from "react-data-table-component";
import DeleteForever from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "shards-react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import NotificationManager from "react-notifications/lib/NotificationManager";
import HonkioAPI from "../../middlewares/HonkioAPI";
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";
import BreadcrumbsComponent from "../breadcrumbs/BreadcrumbsComponent";
import { ASSET_TYPE, ASSET_AREA_TYPE } from "./../../actions/api/assets/config";
import { Link } from "react-router-dom";

//NOT USED
const Circular = () => (
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

class RoomsListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            noShareholders: false,
            rooms: [],
            modal: {
                visible: false,
                room: {
                    rules: {},
                },
            },
            modalEdit: {
                visible: false,
                room: {
                    rules: {},
                },
            },
            modalNewRoom: {
                visible: false,
                floorplan: "",
            },
            modalSwap: {
                visible: false,
            },
        };
        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.toggle = this.toggle.bind(this);

        this.hideModalEdit = this.hideModalEdit.bind(this);
        this.showModalEdit = this.showModalEdit.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);

        this.hideModalSwap = this.hideModalSwap.bind(this);
        this.showModalSwap = this.showModalSwap.bind(this);
        this.toggleSwap = this.toggleSwap.bind(this);

        this.hideModalNewRoom = this.hideModalNewRoom.bind(this);
        this.showModalNewRoom = this.showModalNewRoom.bind(this);
        this.toggleNewRoom = this.toggleNewRoom.bind(this);

        this.loadRooms = this.loadRooms.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
    }

    hideModal() {
        this.setState({ modal: { ...this.state.modal, visible: false } });
    }

    showModal(room, polygon, plan) {
        this.setState({
            modal: {
                ...this.state.modal,
                visible: true,
                room: room,
                polygon: polygon,
                plan: plan,
            },
        });
    }

    toggle() {
        this.setState({
            modal: { ...this.state.modal, visible: !this.state.modal.visible },
        });
    }

    hideModalEdit() {
        this.setState({
            modalEdit: { ...this.state.modalEdit, visible: false },
        });
    }

    showModalEdit(room) {
        this.setState({
            modalEdit: { ...this.state.modalEdit, visible: true, room: room },
        });
    }

    toggleEdit() {
        this.setState({
            modalEdit: {
                ...this.state.modalEdit,
                visible: !this.state.modalEdit.visible,
            },
        });
    }

    hideModalSwap() {
        this.setState({
            modalSwap: { ...this.state.modalSwap, visible: false },
        });
    }

    showModalSwap(room) {
        this.setState({
            modalSwap: { ...this.state.modalSwap, visible: true, room: room },
        });
    }

    toggleSwap() {
        this.setState({
            modalSwap: {
                ...this.state.modalSwap,
                visible: !this.state.modalSwap.visible,
            },
        });
    }

    hideModalNewRoom() {
        this.setState({
            modalNewRoom: { ...this.state.modalNewRoom, visible: false },
        });
    }

    showModalNewRoom(floorplanId) {
        this.setState({
            modalNewRoom: {
                ...this.state.modalNewRoom,
                visible: true,
                floorplan: floorplanId,
            },
        });
    }

    toggleNewRoom() {
        this.setState({
            modalNewRoom: {
                ...this.state.modalNewRoom,
                visible: !this.state.modalNewRoom.visible,
            },
        });
    }
    onRequestCloseNewRoom() {
        this.hideModal();
    }

    componentDidMount() {
        this.loadRooms();
        this.loadFloorplan();
        // this.loadParents();
    }

    loadFloorplan() {
        let that = this;
        let floorplanId = this.props.match.params.floorplan;
        if (floorplanId) {
            this.setState({ floorplanId: floorplanId });
            let query = { id: floorplanId };
            let merchantfloorplanlist = async function () {
                let response = await HonkioAPI().userShop.userFetch(
                    "assetget",
                    query,
                    (error) => {
                        that.setState({
                            plan: [],
                        });
                    }
                );
                if (response?.status === "accept") {
                    that.setState({ plan: response.asset });
                } else {
                    that.setState({
                        plan: [],
                    });
                }
            };
            merchantfloorplanlist();
        }
    }

    loadParents() {
        let that = this;
        that.setState({
            loadingParents: true,
            parents: [],
        });
        let floorplanId = this.props.match.params.floorplan;
        let roomId = this.props.match.params.room;
        let buildingId = this.props.match.params.buildingId;
        let merchantRoomParentList = async function () {
            let params = {
                query_parent_levels: 3,
            };
            if (floorplanId) params["query_id"] = floorplanId;
            if (buildingId) params["query_id"] = buildingId;
            if (roomId) params["query_id"] = roomId;
            let response = await HonkioAPI().userShop.userFetch(
                "merchantassetlist",
                params,
                (error) => {
                    that.setState({
                        loadingParents: false,
                        parents: [],
                    });
                }
            );
            if (
                response?.status === "accept" &&
                response?.parents &&
                response?.assets
            ) {
                let parents = response.parents;
                const parentAsset = response.assets;
                that.setState({
                    parentAsset: {
                        parentAsset,
                        parents,
                    },
                    loadingParents: false,
                });
            } else {
                that.setState({
                    loadingParents: false,
                    parents: [],
                });
            }
        };
        merchantRoomParentList();
    }
    confirmDelete(room) {
        let that = this;
        const options = {
            title: "Destructive action",
            message: "",
            childrenElement: () => (
                <p>
                    {i18n.t(k.ARE_YOU_SURE_WANT_TO_DELETE1)} <b>{room.name}</b>
                    {i18n.t(k._4)}
                </p>
            ),
            buttons: [
                {
                    label: i18n.t(k.YES),
                    onClick: () => {
                        const del = async () => {
                            that.setState({ loading: true });
                            let response = await HonkioAPI().userShop.userFetch(
                                "merchantassetset",
                                {
                                    id: room.id,
                                    delete: true,
                                }
                            );
                            if (response.status === "accept") {
                                NotificationManager.success(
                                    i18n.t(k.LOADING),
                                    i18n.t(k.ROOM_HAS_BEEN_REMOVED)
                                );
                            } else {
                                NotificationManager.success(
                                    `${response.description}`,
                                    i18n.t(k.DELETE_FAILED)
                                );
                            }
                            that.loadRooms();
                        };
                        del();
                    },
                },
                {
                    label: i18n.t(k.NO),
                    onClick: () => {},
                },
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        };
        confirmAlert(options);
    }

    loadRooms() {
        let that = this;
        let floorplanId = this.props.match.params.floorplan;
        let roomId = this.props.match.params.room;
        let buildingId = this.props.match.params.buildingId;
        this.setState({ loading: true });
        let merchantroomlist = async function () {
            let params = {
                // query_type: ASSET_TYPE.ROOM,
            };
            if (floorplanId) {
                params["query_parent"] = floorplanId;
            }
            if (buildingId) {
                params["query_parent"] = buildingId;
            }
            if (roomId) params["query_parent"] = roomId;
            let response = await HonkioAPI().userShop.userFetch(
                "assetlist",
                params,
                (error) => {
                    that.setState({
                        rooms: [],
                        loading: false,
                    });
                    NotificationManager.error(
                        `${error.description}`,
                        i18n.t(k.ERROR_API)
                    );
                }
            );
            if (response?.status === "accept" && response?.assets) {
                that.setState({
                    rooms: response.assets,
                    loading: false,
                });
            } else {
                that.setState({
                    rooms: [],
                    loading: false,
                });
                NotificationManager.error(i18n.t(k.ERROR_API));
            }
        };
        merchantroomlist();
    }

    getColumns() {
        let that = this;
        return [
            {
                name: i18n.t(k.NAME),
                selector: "name",
                sortable: true,
                autoWidth: true,
                grow: 2,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.TOTAL_room),
                selector: (d) => (
                    <span>
                        {d.properties.rules?.total_room}
                        {i18n.t(k.M)}
                    </span>
                ),
                sortable: true,
                autoWidth: true,
                grow: 1,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.FLOORPLANS_L),
                selector: "parent_name",
                sortable: true,
                autoWidth: true,
                grow: 1,
                style: `cursor: pointer;`,
            },
            // }, {
            //     name: 'Shareholder',
            //     selector: (d) => <span>{d.rules.owner_name}</span>,
            //     sortable: true,
            //     style: `cursor: pointer;`
            // },
            {
                name: i18n.t(k.ROOM_TYPE),
                selector: (d) => (
                    <span>
                        {d.properties?.rules
                            ? ASSET_AREA_TYPE[d.properties.rules.space_type - 1]
                                  ?.label || "N/A"
                            : "N/A"}
                    </span>
                ),
                sortable: true,
                autoWidth: true,
                grow: 0,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.RESERVABLE),
                selector: (d) => (
                    <span>
                        {d.properties?.rules?.reservable
                            ? i18n.t(k.TRUE)
                            : i18n.t(k.FALSE)}
                    </span>
                ),
                sortable: true,
                autoWidth: true,
                grow: 0,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.MARKET_RENT_PRICE),
                selector: (d) => (
                    <span>{d.properties?.rules?.market_rent || "N/A"}</span>
                ),
                sortable: true,
                autoWidth: true,
                grow: 0,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.ACTION),
                selector: "action",
                autoWidth: true,
                grow: 1,
                cell: function (d) {
                    return (
                        <span>
                            <Link
                                to="#"
                                className="pointer"
                                onClick={() => {
                                    that.showModalEdit(d, null);
                                }}
                                title={i18n.t(k.EDIT_ROOM)}
                            >
                                <EditIcon />
                            </Link>
                            <Link
                                to="#"
                                className="pointer"
                                title={i18n.t(k.ROOM_DELETE)}
                                onClick={() => {
                                    that.confirmDelete(d);
                                }}
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ];
    }

    swap(to_shareholder, from_shareholder, room_id) {
        let that = this;
        let params = {
            to_shareholder: to_shareholder,
            room_id: room_id,
        };
        if (from_shareholder !== undefined) {
            params["from_shareholder"] = from_shareholder;
        }
        let merchantsharetransfer = async function () {
            let response = await HonkioAPI().userShop.userFetch(
                "merchantsharetransfer",
                params
            );
        };
        merchantsharetransfer();
    }

    edit(room) {
        let rules = {
            total_room: room.properties?.rules?.total_room,
            market_rent: room.properties?.rules?.market_rent,
            space_type: room.properties?.rules?.space_type,
            amount: room.properties?.rules?.amount,
        };
        const assetProperties = {
            properties: { rules },
        };
        let params = {
            name: room.name,
            // total_room: room.rules.total_room,
            // market_rent: room.rules.market_rent,
            // space_type: room.rules.space_type,
            // tenant_improvement_costs: room.rules.tenant_improvement_costs,
            // id: room.id };

            ...assetProperties,
            id: room.id,
            structure: "", //inna: todo insert room structure
        };
        let that = this;
        let merchantroomset = async function () {
            let response = await HonkioAPI().userShop.userFetch(
                "merchantassetset",
                params,
                (error) => {
                    that.setState({ loading: false });
                }
            );
            if (response.status === "accept") {
                NotificationManager.success(
                    i18n.t(k.ROOM_EDITED),
                    i18n.t(k.SUCCESS)
                );
                that.loadRooms();
            }
        };
        merchantroomset();
    }

    rowSelected = (d) => {
        let that = this;
        // that.props.history.push('/rooms/' + d.id);
    };

    getParents = () => {
        let that = this;
        let parentsList = [
            {
                name: i18n.t(k.PROPERTIES),
                linkTo: "/landplots",
            },
        ];
        if (that.state.parentAsset) {
            const landplotElem = that.state.parentAsset.parents
                .filter((asset) => asset.type === ASSET_TYPE.LANDPLOT)
                .map((asset) => ({
                    name: asset.name,
                    linkTo: "/buildings/" + asset.id,
                }));
            const buildingElem = that.state.parentAsset.parents
                .filter((asset) => asset.type === ASSET_TYPE.BUILDING)
                .map((asset) => ({
                    name: asset.name,
                    linkTo: "/floorplans/" + asset.id,
                }));
            const roomElem = that.state.parentAsset.parents
                .filter(
                    (asset) =>
                        asset.type === ASSET_TYPE.ROOM &&
                        that.state.parentAsset.parentAsset.parent === asset.id
                )
                .map((asset) => ({
                    name: asset.name,
                    linkTo: "/rooms/" + asset.id,
                }));
            const roomOthersElem = that.state.parentAsset.parents
                .filter(
                    (asset) =>
                        asset.type === ASSET_TYPE.ROOM &&
                        that.state.parentAsset.parentAsset.parent !== asset.id
                )
                .map((asset) => ({
                    name: "...",
                    linkTo: null,
                }));
            const lastElem = that.state.parentAsset.parentAsset.map(
                (asset) => ({
                    name: asset.name,
                    linkTo: null,
                })
            );
            if (landplotElem.length > 0) {
                parentsList = [...parentsList, landplotElem[0]];
            }
            if (buildingElem.length > 0) {
                parentsList = [...parentsList, buildingElem[0]];
            }
            if (roomOthersElem.length > 0) {
                parentsList = [...parentsList, roomOthersElem[0]];
            }
            if (roomElem.length > 0) {
                parentsList = [...parentsList, roomElem[0]];
            }
            if (lastElem.length > 0) {
                parentsList = [...parentsList, lastElem[0]];
            }
        }
        return parentsList;
    };
    render() {
        let that = this;
        let getBreadcrumbs = () => {
            return <BreadcrumbsComponent parents={this.getParents()} />;
        };
        return (
            <div>
                <div className="container-fluid">
                    <PageHeaderComponent title={i18n.t(k.ROOMS)} />
                </div>
                <CardWrapperComponent
                    header={true}
                    footer={true}
                    card_title={getBreadcrumbs()}
                >
                    <Button
                        theme="info"
                        onClick={() => {
                            if (that.props.match.params.floorplan) {
                                that.props.history.push(
                                    `/newRoom/new/${that.props.match.params.floorplan}`
                                );
                            } else {
                                NotificationManager.error(
                                    "Floorplan is not defined"
                                );
                            }
                        }}
                    >
                        <AddIcon className="mr-2" />
                        {i18n.t(k.CREATE_ROOM)}
                    </Button>
                    <Button
                        theme="info"
                        onClick={() => {
                            if (that.props.match.params.floorplan) {
                                that.props.history.push(
                                    `/newParkingPlace/new/${that.props.match.params.floorplan}`
                                );
                            } else {
                                NotificationManager.error(
                                    "Landplot is not defined"
                                );
                            }
                        }}
                    >
                        <AddIcon className="mr-2" />
                        {i18n.t(k.CREATE_PARKING_PLACE)}
                    </Button>
                    <DataTable
                        autoWidth={true}
                        columns={this.getColumns()}
                        background="#ffffff00"
                        data={this.state.rooms}
                        noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                        paginationComponentOptions={{
                            rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                            rangeSeparatorText: i18n.t(k.ROWS_OF),
                            noRowsPerPage: false,
                            selectAllRowsItem: false,
                            selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                        }}
                        progressPending={this.state.loading}
                        progressComponent={<Circular />}
                        onRowClicked={this.rowSelected}
                    />
                </CardWrapperComponent>
            </div>
        );
    }
}

export default RoomsListComponent;
