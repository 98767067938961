import React from "react";
import { Collapse, Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import SidebarItems from "./SidebarItems";

class SidebarNavItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navItems: SidebarItems(),
        };
    }

    render() {
        const { navItems: items } = this.state;
        return (
            <div className="nav-wrapper">
                <Nav className="nav--no-borders flex-column text-left">
                    {items.map((item, idx) => (
                        <SidebarNavItem key={idx} item={item} />
                    ))}
                </Nav>
            </div>
        );
    }
}

export default SidebarNavItems;
