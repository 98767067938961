import i18n from 'i18next';import k from "./../../../i18n/keys";import React, { Component } from 'react';
import { Button, Form, FormGroup, FormSelect, Modal, ModalBody, ModalHeader } from "shards-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import { NotificationManager } from "react-notifications";
import Select from "react-select";

export default class SwapAreaModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shareholders_loaded: false };

  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.area !== undefined) {
      this.loadShareholders(nextProps.area.rules.building);
      this.loadBuilding(nextProps.area.rules.building);
      this.loadShareholder(nextProps.area.id);
      this.setState({ "buildingId": nextProps.area.rules.building, "area": nextProps.area });
    }
  }

  loadBuilding(buildingId) {
    let that = this;
    let merchantshareholderlist = async function () {
      let response = await HonkioAPI().userShop.userFetch('merchantbuildinglist', { "id": buildingId });
      that.setState({ "building": response.buildings[0], buildingId: response.buildings[0].id });

    };
    merchantshareholderlist();
  }

  loadShareholders(buildingId) {
    let that = this;
    let merchantshareholderlist = async function () {
      let response = await HonkioAPI().userShop.userFetch('merchantshareholderlist',
      { "building_id": buildingId });
      let shareholders = response.shareholders;
      that.setState({ 'shareholders_list': shareholders }, that.getShareholderSelector);
    };
    merchantshareholderlist();
  }

  loadShareholder(areaId) {
    let that = this;
    let merchantshareholderlist = async function () {
      let response = await HonkioAPI().userShop.userFetch('merchantsharelist',
      { "area_id": areaId });
      if (response.shares.length > 0) {
        that.setState({ 'from_shareholder': response.shares[0].shareholder_id });
      } else
      {
        that.setState({ 'from_shareholder': undefined });
      }
    };
    merchantshareholderlist();
  }


  getShareholderSelector() {
    let that = this;
    let shareholders = [];
    if (this.state.shareholders_list && typeof this.state.shareholders_list !== 'undefined') {
      this.state.shareholders_list.forEach(function (shareholder) {
        if (shareholder.id !== that.state.from_shareholder) {
          shareholders.push(
          { value: shareholder.id, label: shareholder.name });

        }
      });
    }
    this.setState({ "shareholders_loaded": true });
    this.options = shareholders;
  }

  handleChange(e) {
    this.setState({ 'to_shareholder': e.value });
  }

  render() {
    // console.log('MODAL STATE:');
    // console.log(this.state);

    let saveButton, select;
    saveButton = select = <CircularProgress size={45} />;

    if (!this.state.loading) {
      saveButton = <Button onClick={() => {this.props.onAreaSwapped(this.state.to_shareholder, this.state.from_shareholder, this.state.area.id);}}>{i18n.t(k.CHOOSE)}</Button>;
    }

    if (this.state.shareholders_loaded) {
      select = <Select id="shareholder" onChange={e => {this.handleChange(e);}}
      defaultValue={{ value: i18n.t(k.NULL), label: i18n.t(k.PLEASE_SELECT_SHAREHOLDER) }}
      options={this.options}
      menuPlacement="bottom"
      menuPosition='fixed'>
                    </Select>;}

    return (
      <Modal open={this.props.visible}
      toggle={this.props.toggle}
      onRequestClose={this.props.onRequestClose}
      className="w-100 modal-lg modal-dialog-centered modal-dialog-scrollable">
                <ModalHeader>{i18n.t(k.CHOOSE_SHAREHOLDER)}</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            {select}
                        </FormGroup>
                        {saveButton}
                    </Form>
                </ModalBody>
            </Modal>);

  }}