import React, { Component } from "react";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import i18n from "i18next";
import k from "./../../i18n/keys";

import DataTable from "react-data-table-component";
import { Add, Edit, DeleteForever, HelpOutline } from "@material-ui/icons";
import { Button } from "shards-react";
import "react-confirm-alert/src/react-confirm-alert.css";
import qrCode from "./scanner.jpeg";
import { Link } from "react-router-dom";

class ListVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            portfolios: [
                {
                    _id: "62444844ddc15f927ccbc996",
                    name: "Video Name 1",
                },
                {
                    _id: "62445d796d12e7a297d6bc5b",
                    name: "Video Name 12",
                },
                {
                    _id: "6255b013680a295365fe66f7",
                    name: "Video Name 13",
                },
                {
                    _id: "6256d206a7a1a8c471fca8c4",
                    name: "Video Name 14",
                },
            ],
            modal: {
                visible: false,
                portfolio: null,
            },
        };
    }
    getColumns() {
        let that = this;
        return [
            {
                name: i18n.t(k.NAME),
                selector: "name",
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.ACTION),
                selector: "action",
                // style: `justify-content: center;`,
                cell: function (d) {
                    return (
                        <span>
                            <Link
                                to="#"
                                className="pointer"
                                title="Edit"
                                onClick={() => {
                                    that.showModal(d);
                                }}
                            >
                                <Edit />
                            </Link>
                            <Link
                                to={`/portfolio/${d.id}`}
                                className="pointer"
                                title="Help"
                            >
                                <HelpOutline />
                            </Link>
                            <Link to="#" className="pointer" title="Qr scanner">
                                <img
                                    src={qrCode}
                                    alt="qr"
                                    style={{ height: 22, width: 25 }}
                                />
                            </Link>
                            <Link
                                to="#"
                                className="pointer"
                                title="Delete"
                                onClick={() => {
                                    that.confirmDelete(d);
                                }}
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ];
    }

    render() {
        let that = this;
        return (
            <div>
                <br />
                <div className="container-fluid"></div>
                <CardWrapperComponent
                    header={true}
                    footer={true}
                    card_title={i18n.t(k.VIDEO_LIST)}
                >
                    <Button
                        theme="info"
                        onClick={() => {
                            this.props.history.push(`/video/addNewVideo`);
                        }}
                    >
                        <Add className="mr-2" />
                        {i18n.t(k.ADD)} Video
                    </Button>
                    <DataTable
                        autoWidth={true}
                        columns={this.getColumns()}
                        background="#ffffff00"
                        data={this.state.portfolios}
                        noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                        paginationComponentOptions={{
                            rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                            rangeSeparatorText: i18n.t(k.ROWS_OF),
                            noRowsPerPage: false,
                            selectAllRowsItem: false,
                            selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                        }}
                        // progressPending={this.state.loading}

                        // onRowClicked={e => this.props.history.push(`/portfolio/${e.id}`)}
                    />
                </CardWrapperComponent>
            </div>
        );
    }
}

export default ListVideo;
