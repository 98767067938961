import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { useEffect, useState, useContext } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {
    getAssetList,
    getAssetListWithPages,
} from "./../../actions/api/assets/getAssetList";
import PageableDatatablesComponent from "./../ui_utils/PageableDatatablesComponent";
import getAssetTypeList from "./../../actions/api/assetTypes/getAssetTypeList";
import { APP_ID } from "./../../config";
import { Modal, Button } from "react-bootstrap";
import { updateAssetData } from "../../actions/api/assets/setAssetData";
import { STORAGE } from "./../../actions/auth/oauthActions";

const Circular = () => (
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);
const PAGE_ITEMS_COUNT = 5;

const SensorListModal = ({
    parent,
    type,
    property_type,
    onAssetsLinked,
    show,
    onHide,
}) => {
    //const [parentInfo, setParentInfo] = useState();
    const [loading, setLoading] = useState(false);
    const [assetList, setAssetList] = useState([]);
    const [total, setTotal] = useState();
    const [typesList, setTypesList] = useState([]);
    const [finished, setFinished] = useState(false);
    const [selectedSensorList, setSelectedSensorList] = useState([]);

    const [currentPage, setCurrentPage] = useState(0);

    const [isMounted, setIsMounted] = useState(false);

    // let isMounted = true;

    useEffect(() => {
        setIsMounted(true);
        return () => {
            // isMounted = false
            setIsMounted(false);
        };
    }, []);

    useEffect(() => {
        // if (type) fetchChildrenTypes();
        fetchChildrenTypes();
    }, [type, isMounted]);

    const fetchChildrenTypes = async () => {
        setLoading(true);
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    const iotSensors = data.asset_types.filter((itm) =>
                        itm.properties.find(
                            (pr) => pr.property_name === "iot_device"
                        )
                    );
                    // setTypesList(data.asset_types);
                    setTypesList(iotSensors);
                    setLoading(false);
                } else {
                    NotificationManager.error("Error");
                    setTypesList();
                    setLoading(false);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setTypesList();
            setLoading(false);
        };
        if (parent) {
            getAssetTypeList(
                {
                    query_application: APP_ID,
                    // query_parent: type,
                },
                errorHandler
            )
                .then((data) => {
                    return resultHandler(data);
                })
                .catch((error) => {
                    console.log("getAssetTypeList: modal: error", error);
                });
        } else {
            setAssetList();
        }
    };

    // useEffect(() => {
    //     if (typesList && typesList.length > 0) fetchAssetList();
    // }, [typesList]);

    const fetchAssetList = async (page) => {
        setLoading(true);
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    setAssetList(data.assets);
                    setTotal(data.total);
                    setLoading(false);
                } else {
                    NotificationManager.error("Error");
                    setAssetList();
                    setLoading(false);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setAssetList();
            setLoading(false);
        };
        if (typesList) {
            const types_array = typesList.map((e) => e.name);
            getAssetListWithPages(
                {
                    types: types_array,
                    page: page,
                    count: PAGE_ITEMS_COUNT,
                    parent: "None",
                },
                errorHandler
            )
                .then((data) => {
                    return resultHandler(data);
                })
                .catch(console.error);
        } else {
            setAssetList();
        }
    };

    const columns = [
        {
            name: i18n.t(k.NAME),
            selector: "name",
            sortable: true,
            autoWidth: true,
            grow: 2,
            style: `cursor: pointer;`,
        },
        {
            name: i18n.t(k.TYPE),
            selector: "type",
            sortable: true,
            autoWidth: true,
            grow: 0,
            style: `cursor: pointer;`,
        },
    ];

    useEffect(() => {
        if (finished) onAssetsLinked();
    }, [finished]);

    const onLinkClicked = () => {
        const selectedSensorsCount = selectedSensorList.length;
        if (selectedSensorsCount > 0) {
            selectedSensorList.forEach((asset, index) => {
                const linkData = {
                    type: asset.name,
                    description: asset.description,
                    name: asset.name,
                    properties: { ...asset.properties },
                    parent: asset.parent,
                };

                return linkAsset(linkData, index === selectedSensorsCount - 1);
            });
        } else {
            setFinished(true);
        }
    };

    const linkAsset = (asset, stop) => {
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                if (stop) {
                    setFinished(true);
                    setSelectedSensorList([]);
                }
            } else {
                NotificationManager.error("Error");
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        const newAsset = {
            ...asset,
            parent: parent,
        };

        updateAssetData(
            newAsset,
            localStorage.getItem(STORAGE.MERCHANT_ID),
            APP_ID,
            property_type,
            errorHandler
        ).then((data) => {
            NotificationManager.success("Sensors added");
            return resultHandler(data);
        });
    };

    const onAssetSelected = (data) => {
        setSelectedSensorList(
            selectedSensorList.concat(
                data.selectedRows.filter(
                    (item) => selectedSensorList.indexOf(item) < 0
                )
            )
        );
    };

    const getSensorsForPage = () => {
        const startIdx = currentPage * PAGE_ITEMS_COUNT;
        const endIdx = startIdx + PAGE_ITEMS_COUNT;
        const typesForPage = typesList.slice(startIdx, endIdx);

        return typesForPage;
        // return typesList;
    };

    return (
        <div>
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Select sensors</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PageableDatatablesComponent
                        noHeader={true}
                        selectableRows
                        onSelectedRowsChange={(e) => onAssetSelected(e)}
                        autoWidth={true}
                        columns={columns}
                        background="#ffffff00"
                        items={getSensorsForPage()}
                        loadItems={(page) => {
                            // fetchAssetList(page);
                            // fetchChildrenTypes();
                            setCurrentPage(page);
                        }}
                        noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                        // paginationTotalRows={total}
                        paginationTotalRows={typesList.length}
                        progressPending={loading}
                        progressComponent={<Circular />}
                        paginationServer={true}
                        paginationPerPage={PAGE_ITEMS_COUNT}
                        paginationRowsPerPageOptions={[PAGE_ITEMS_COUNT]}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => onHide()}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={() => onLinkClicked()}>
                        Link
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default SensorListModal;
