import i18n from 'i18next';import k from "./../../../../i18n/keys";import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from "shards-react";

class AgreementSignModalComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal open={this.props.visible}
      toggle={this.props.toggle}
      onRequestClose={this.props.onRequestClose}
      className="w-100">
                <ModalHeader>{i18n.t(k.THE_AGREEMENT_HAS_BEEN_SIGNED)}</ModalHeader>
                <ModalBody>
                    <p>{i18n.t(k.THE_AGREEMENT_HAS_BEEN_SIGNED1)}</p>
                    <Button className="float-right" theme="primary" onClick={() => {
            this.props.toggle();
          }}>{i18n.t(k.OK)}</Button>
                </ModalBody>
            </Modal>);

  }}


export default AgreementSignModalComponent;