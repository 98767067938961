import React from 'react';
import i18n from 'i18next';
import k from "./../../i18n/keys";
import { useForm } from 'react-hook-form';
import CommonProperties from './elements/CommonProperties';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useState } from 'react';
import nullFilter from './nullFilter';
import { APP_ID } from '../../config';
import setAdminRole from './../../actions/api/roles/setAdminRole';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CardWrapperComponent from './../ui_utils/CardWrapperComponent';

const RoleCreate = ({ role = { property: "private" }, language = 'en' }) => {
    const [newRole, setNewRole] = useState(role);
    const { register, handleSubmit, reset } = useForm({});
    const history = useHistory();
    const pageTitle = i18n.t(k.CREATE_NEW_ROLE)
    const onSubmit = (data) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setNewRole(data.role)
                history.goBack()
                NotificationManager.success(i18n.t(k.ROLE_SAVED))
            } else {
                NotificationManager.error(i18n.t(k.ERROR))
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, i18n.t(k.ERROR), 4000)
        }
        const multiLangCommonProperties = language && Object.keys(role).length > 0
            ? Object.entries({ ...role })
                .filter(([key]) =>
                    ['display_name', 'description'].includes(key.split('__')[0])
                )
                .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), [])
            : {};
        const {
            description,
            display_name,
            name,
            group_code,
            property,
            access,
        } = data;
        const localLangCommonProperties = {};
        if (language) {
            localLangCommonProperties['display_name__' + language] = display_name;
            localLangCommonProperties['description__' + language] = description;
        } else {
            localLangCommonProperties['display_name'] = display_name;
            localLangCommonProperties['description'] = description;
        }
        const request = nullFilter(
            Object.assign({}, multiLangCommonProperties, localLangCommonProperties, {
                id: newRole._id,
                merchant: localStorage.merchant_id,
                application: APP_ID,
                name,
                property,
                access,
                group_code,
            })
        );
        setAdminRole(request, errorHandler)
            .then(data => {
                return resultHandler(data)
            })
            .catch(console.error);
    };
    return (<div>
        <NotificationContainer />

        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
                <CardWrapperComponent
                    header={true}
                    footer={true}
                    card_footer={(
                        <div className="d-flex">
                            <button type="submit" className="btn btn-primary">
                                {newRole._id ? i18n.t(k.UPDATE_ROLE) : i18n.t(k.CREATE_NEW_ROLE)}
                            </button>
                        </div>
                    )}
                    card_title={pageTitle}>
                    <CommonProperties
                        register={register}
                        className="col-md-6 col-lg-3"
                        role={role}
                    />
                </CardWrapperComponent>
            </div>
        </form>
    </div>
    );
};

export default RoleCreate;
