import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { Component } from "react";
import "react-notifications/dist/react-notifications.css";
import "react-notifications/dist/react-notifications.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button, ButtonGroup } from "shards-react";
import DataTable from "react-data-table-component";
// import {getInvite} from "../../actions/inviteActions";
import { connect } from "react-redux";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import InvoiceProductEditModal from "./InvoiceProductAddModal";
import class_data from "./product_types.json";

const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

// Fixme unify with userlistcomponent

class InvoiceProductEditComponent extends Component {
    constructor(props) {
        super(props);
        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.toggle = this.toggle.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.rowClicked = this.rowClicked.bind(this);

        this.state = {
            modal: {
                visible: false,
            },

            order: null,
            link: {},
            processing: false,
            products: this.remapProductList(this.props.products),
        };
    }
    remapProductList(products) {
        // let newProducts = {}
        for (var i = 0; i < products.length; i++) {
            if (!products[i].id) {
                products[i]["id"] =
                    "id" + Math.floor(Math.random() * Math.floor(9999999));
            }
            // newProducts[products[i].id] = products[i]
        }
        return products;
        // return newProducts
    }
    hideModal() {
        this.setState({ modal: { ...this.state.modal, visible: false } });
    }
    componentWillReceiveProps(nextProps, nextContext) {
        console.error("nextProps");
        console.error(nextProps);
        if (nextProps.products) {
            this.setState({
                products: this.remapProductList(nextProps.products),
            });
        }
    }

    showModal(product) {
        // console.log('settings to visible');
        // console.log({ ...this.state, 'modal': { ...this.state.modal, visible: true } });
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                visible: true,
                product: product ? product : {},
            },
        });
    }

    toggle() {
        this.setState({
            modal: { ...this.state.modal, visible: !this.state.modal.visible },
        });
    }

    onChange(id, evt) {
        let products = this.props.products;
        for (var i = 0; i < products.length; i++) {
            var product = products[i];
        }
        // this.setState({
        //      'invoice': {...this.state.invoice,
        //          [e.target.id]: e.target.value
        //      }
        //  })
    }
    handleChange(e) {
        this.setState({
            products: { ...this.state.invoice, [e.target.id]: e.target.value },
        });
    }
    getColumns() {
        let state = this.state;
        let that = this;
        return [
            {
                name: "Name",
                selector: "name",
                sortable: true,
                width: "35%",
                // cell: function(d) {
                //     console.log('d')
                //     console.log(d)
                //         return <FormInput
                //                   placeholder="Name"
                //                   type="text" value={d.name}
                //                   onChange={e => that.handleChange(e)}/>
                //     // return <EditableDatatableItemComponent id="name" value={d.name} onChange={this.onChange()}/>
                // }
            },
            {
                name: "Amount",
                sortable: true,
                selector: function (d, b) {
                    // console.log('b');
                    // console.log(b);
                    return d.amount + "EUR";
                },
                width: "20%",
            },

            {
                name: "Vat",
                sortable: true,
                selector: function (d) {
                    return d.amount_vat + "EUR";
                },
                width: "10%",
            },

            {
                name: "VAT %",
                sortable: true,
                selector: function (d) {
                    return d.int_vat;
                },
                width: "10%",
            },

            {
                name: "Class",
                sortable: true,
                selector: function (d) {
                    let codes = class_data;
                    return codes[d.class] ? codes[d.class].en : "";
                },
                width: "15%",
            },

            {
                name: "Count",
                selector: "action",
                // width: '15%',
                cell: function (d) {
                    let click = (value) => {
                        return () => {
                            let products = that.props.products;
                            for (var i = 0; i < products.length; i++) {
                                if (products[i].id === d.id) {
                                    products[i].count += value;
                                    if (products[i].count <= 0) {
                                        products.splice(i, 1);
                                    }
                                    break;
                                }
                            }
                            that.props.onProductsChanged(products);
                        };
                    };
                    return (
                        <ButtonGroup size="sm">
                            <Button
                                theme="warning"
                                className="text-large"
                                onClick={click(-1)}
                            >
                                {i18n.t(k._8)}
                            </Button>
                            <span className="justify-content-center align-self-center ml-2 mr-2">
                                {d.count}
                            </span>
                            <Button
                                theme="success"
                                className="text-large"
                                onClick={click(1)}
                            >
                                {i18n.t(k._23)}
                            </Button>
                        </ButtonGroup>
                    );
                },
            },
        ];
    }
    rowClicked(product) {
        this.showModal(product);
    }
    render() {
        //
        // let total = 0;
        // if (this.state.order) {
        //     this.state.order.products.forEach((product) => { total += parseFloat(product.amount) * product.count})
        // }
        // let confirmButton
        // if (!this.state.processing) {
        //     if (this.state.order.order_status === 'finished') {
        //         confirmButton = <Button className="mb-3 ml-3" onClick={this.saveOrderProducts(true)}><SaveIcon className="mr-1" fontSize="small"/>Confirm and complete</Button>
        //     } else {
        //         confirmButton = <Button className="mb-3 ml-3" onClick={this.saveOrderProducts(false)}><SaveIcon className="mr-1" fontSize="small"/>Save</Button>
        //     }
        // } else {
        //     confirmButton = <CircularProgress size={35}/>
        // }
        // let message = <span>Worker fee will be added when you finalize the order</span>
        // if (Object.keys(this.state.link).length > 0) {
        //     message = <span>Worker fee has been added to products list, please adjust it according to spent time</span>
        // }
        return (
            <div>
                <DataTable
                    title={i18n.t(k.ITEMS)}
                    columns={this.getColumns()}
                    background="#ffffff00"
                    data={this.state.products}
                    noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                    paginationComponentOptions={{
                        rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                        rangeSeparatorText: i18n.t(k.ROWS_OF),
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                    }}
                    onRowClicked={this.rowClicked}
                    progressComponent={<Circular />}
                />

                <hr />
                <div className="row">
                    <div className="col-sm-2">
                        <Button theme="info" onClick={this.showModal}>
                            <AddCircleOutlineIcon
                                className="mr-1"
                                fontSize="small"
                            />
                            {i18n.t(k.ADD_PRODUCT)}
                        </Button>
                    </div>
                </div>
                <InvoiceProductEditModal
                    visible={this.state.modal.visible}
                    product={this.state.modal.product}
                    open={this.toggle}
                    toggle={this.hideModal}
                    onRequestClose={this.hideModal}
                    onAddProductClicked={(product) => {
                        this.setState({ modal: { visible: false } });
                        this.props.onAddProductClicked(product);
                    }}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    // console.log('staaaaaaaaaaaaaaaaaaaaaaaaaaa')
    // console.log(state)
    // console.log(this.props)
    return state;
}

const mapDispatchToProps = (dispatch) => ({
    // getMerchantConsumerList: (role_id) => dispatch(getMerchantConsumerList(role_id)),
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceProductEditComponent);
