
const initialState = {
  isLoadingGetMerchant: false };


function merchantGetReducer(state = initialState, action) {

  switch (action.type) {
    case "GET_MERCHANT_LOADING":
      return {
        isLoadingGetMerchant: true };

    case "GET_MERCHANT_SUCCESS":
      return {
        merchantInfo: action.merchantInfo,
        isLoadingGetMerchant: false };

    default:

      return state;}

}

export default merchantGetReducer;