import i18n from 'i18next';import k from "./../../i18n/keys";import React, { Component } from 'react';
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataTable from "react-data-table-component";
import AddIcon from '@material-ui/icons/Add';
import {Button, ButtonGroup, Breadcrumb, BreadcrumbItem} from "shards-react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";
import ListIcon from '@material-ui/icons/List';

const Circular = () =>
// we need to add some padding to circular progress to keep it from activating our scrollbar
<div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>;

const NAMES_MAP = {
    office: 'Office',
    business_premices: 'Business premices'
}

const BULLSHIT_DATA = {
    office: [
        {
            'expense': 'Management',
            '2012': 12.05,
            '2013': 14.00,
            '2014': 1.01,
            '2015': 10001104547.99,
            '2016': 0.00,
            '2017': 2.22
        },
        {
            'expense': 'Maintenance',
            '2012': 22.22,
            '2013': 42.42,
            '2014': 42.01,
            '2015': 0.00,
            '2016': 0.00,
            '2017': 7.77
        },
        {
            'expense': 'Outdoors',
            '2012': 47.09,
            '2013': 92.54,
            '2014': 89.81,
            '2015': 99.99,
            '2016': 100.00,
            '2017': 101.00
        },
        {
            'expense': 'Cleaning',
            '2012': 47.09,
            '2013': 92.54,
            '2014': 89.81,
            '2015': 99.99,
            '2016': 100.00,
            '2017': 101.00
        },
    ],
    business_premices: [
        {
            'expense': 'Management',
            '2012': 12.05,
            '2013': 14.00,
            '2014': 1.01,
            '2015': 10001104547.99,
            '2016': 0.00,
            '2017': 2.22
        },
        {
            'expense': 'Maintenance',
            '2012': 22.22,
            '2013': 42.42,
            '2014': 42.01,
            '2015': 0.00,
            '2016': 0.00,
            '2017': 7.77
        },
        {
            'expense': 'Outdoors',
            '2012': 47.09,
            '2013': 92.54,
            '2014': 89.81,
            '2015': 99.99,
            '2016': 100.00,
            '2017': 101.00
        },
        {
            'expense': 'Cleaning',
            '2012': 47.09,
            '2013': 92.54,
            '2014': 89.81,
            '2015': 99.99,
            '2016': 100.00,
            '2017': 101.00
        },
    ],
}

class KTIDataComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      floorplans: [],
      modal: {
        visible: false,
        floorplan: null } };


    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  hideModal() {
    this.setState({ 'modal': { ...this.state.modal, visible: false } });
  }

  showModal(building) {
    this.setState({ 'modal': { ...this.state.modal, visible: true, building: building } });
  }

  toggle() {
    this.setState({ 'modal': { ...this.state.modal, visible: !this.state.modal.visible } });
  }

  componentDidMount() {
    this.setState({ 'ktiData': BULLSHIT_DATA, loading: false });
  }

  getColumns() {
    let that = this;

    return [
    {
        name: 'Expense',
        selector: 'expense',
        // style: `cursor: pointer;`,
    },
    {
        name: '2012',
        selector: '2012',
        // style: `cursor: pointer;`,
    },
    {
        name: '2013',
        selector: '2013',
        // style: `cursor: pointer;`,
    },
    {
      name: '2014',
      selector: '2014',
    //   style: `cursor: pointer;`,
    },
    {
        name: '2015',
        selector: '2015',
        // style: `cursor: pointer;`,
    },
    {
        name: '2016',
        selector: '2016',
        // style: `cursor: pointer;`,
    },
    {
        name: '2017',
        selector: '2017',
        // style: `cursor: pointer;`,
    },
    ];


  }

  getTables() {
    let that = this;
    let tables = [];
    if (!this.state.ktiData) {
        return
    }
    let key = 0;
    for (const buildingType of Object.keys(this.state.ktiData)) {
        tables.push (
            <div key={key}>
                <h4>{NAMES_MAP[buildingType]}</h4>
                <DataTable
          autoWidth={true}
          columns={this.getColumns()}
          background="#ffffff00"
          data={this.state.ktiData[buildingType]}
          noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
          paginationComponentOptions={
              { rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                rangeSeparatorText: i18n.t(k.ROWS_OF),
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: i18n.t(k.ROWS_ALL) }
            }
          progressPending={this.state.loading}
          progressComponent={<Circular />}
          onRowClicked={e => that.props.history.push(`/areas/floorplan/${e.id}`)} />
            </div>
        )
        key += 1
    }
    return tables
  }

  render() {
    let that = this;
    return (
      <div>
        {this.getTables()}
      </div>);

  }}


function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = dispatch => ({});


export default connect(mapStateToProps, mapDispatchToProps)(KTIDataComponent);
