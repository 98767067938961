import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { ReactComponent, useEffect, useState, useContext } from "react";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteForever from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import Work from "@material-ui/icons/Work";
import { Button } from "shards-react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";
import GroupIcon from "@material-ui/icons/Group";
import DescriptionIcon from "@material-ui/icons/Description";
import ApartmentIcon from "@material-ui/icons/Apartment";
import BreadcrumbsComponent from "../breadcrumbs/BreadcrumbsComponent";
import { ASSET_TYPE } from "./../../actions/api/assets/config";
import useSearchString from "./../../utils/useSearchString";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getAssetListWithPages } from "./../../actions/api/assets/getAssetList";
import getAsset from "./../../actions/api/assets/getAssets";
import AssetDeleteModal from "./../assets/AssetDeleteModal";
import PageableDatatablesComponent from "./../ui_utils/PageableDatatablesComponent";
import IotImg from "../../img/icons/iot.png";
import SensorListModal from "../sensors/SensorListModal";
import ListOfSensors from "@material-ui/icons/List";
import { ReactComponent as SafetyVideoIcon } from "../../img/icons/video_icon.svg";
import { updateAssetData } from "../../actions/api/assets/setAssetData";
import { STORAGE } from "./../../actions/auth/oauthActions";
import { APP_ID } from "./../../config";
import getAssetTypeList from "./../../actions/api/assetTypes/getAssetTypeList";
import VideosListModal from "../assets/subcomponents/VideosListModal";
import { Link } from "react-router-dom";
import {
    openSafetyVideoModal,
    closeSafetyVideoModal,
} from "../../actions/action_utils";
import { useDispatch, useSelector } from "react-redux";

const Circular = () => (
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

const PAGE_ITEMS_COUNT = 10;

const BuildingList = () => {
    const dispatch = useDispatch();
    const { parent } = useSearchString();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [assetList, setAssetList] = useState([]);
    const [total, setTotal] = useState();
    const [parentInfo, setParentInfo] = useState([]);
    const [showConfirmRemoveAsset, setShowConfirmRemoveAsset] = useState({
        visible: false,
        asset: undefined,
    });
    const handleShowConfirmRemoveAsset = (asset) =>
        setShowConfirmRemoveAsset({ visible: true, asset: asset });
    const handleCloseConfirmRemoveAsset = () => {
        setShowConfirmRemoveAsset({ visible: false, asset: undefined });
    };
    let isMounted = true;

    const [showConfirmLinkAssets, setShowConfirmLinkAssets] = useState(false);
    const [showSafetyVideoModal, setShowSafetyVideoModal] = useState(false);
    const [currentBuilding, setCurrentBuilding] = useState(null);
    const [finished, setFinished] = useState(false);
    const [selectedSensorList, setSelectedSensorList] = useState([]);
    const [typesList, setTypesList] = useState([]);

    useEffect(() => {
        fetchAssetList();
        fetchChildrenTypes();
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        if (parent) fetchParent();
    }, [parent]);

    const fetchAssetList = async (page) => {
        setLoading(true);
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    setAssetList(data.assets);
                    setLoading(false);
                } else {
                    NotificationManager.error("Error");
                    setAssetList();
                    setLoading(false);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setAssetList();
            setLoading(false);
        };
        if (parent) {
            getAssetListWithPages(
                {
                    type: ASSET_TYPE.BUILDING,
                    page: page,
                    count: PAGE_ITEMS_COUNT,
                    levels: 3,
                    parent: parent,
                },
                errorHandler
            )
                .then((data) => {
                    return resultHandler(data);
                })
                .catch(console.error);
        } else {
            setAssetList();
        }
    };
    const fetchParent = async () => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    setParentInfo(data.asset);
                } else {
                    NotificationManager.error("Error");
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setParentInfo();
        };
        if (parent) {
            getAsset({ id: parent }, errorHandler)
                .then((data) => {
                    return resultHandler(data);
                })
                .catch(console.error);
        } else {
            setParentInfo();
        }
    };

    const columns = [
        {
            name: i18n.t(k.NAME),
            selector: "name",
            grow: 1,
            sortable: true,
            style: `cursor: pointer;`,
        },
        {
            name: i18n.t(k.ADDRESS),
            selector: "properties.address",
            sortable: true,
            grow: 2,
            style: `cursor: pointer;`,
        },
        {
            name: i18n.t(k.ACTION),
            selector: "action",
            grow: 1,
            cell: function (asset) {
                return (
                    <span>
                        <Link
                            title={i18n.t(k.BUILDING_EDIT)}
                            id={"editTip" + asset._id}
                            to={`/assets/edit?asset=${asset._id}`}
                            className="pointer"
                        >
                            <EditIcon />
                        </Link>
                        <Link
                            title={i18n.t(k.DOCUMENTS)}
                            id="documentsTip"
                            to={`/asset/documents?id=${asset._id}`}
                            className="pointer"
                        >
                            <DescriptionIcon />
                        </Link>
                        <Link
                            title={i18n.t(k.AGREEMENTS)}
                            id="projectsTip"
                            to={`/agreements/${asset._id}`}
                            className="pointer"
                        >
                            <i className="fas fa-handshake fa-lg"></i>
                        </Link>
                        <Link
                            title={i18n.t(k.BUILDING_PROJECTS)}
                            id="projectsTip"
                            to={`/projects/${asset._id}`}
                            className="pointer"
                        >
                            <Work />
                        </Link>
                        <Link
                            title={i18n.t(k.BUILDING_USERS)}
                            id="usersTip"
                            to={`/buildingUsers/${asset._id}`}
                            className="pointer"
                        >
                            <GroupIcon />
                        </Link>
                        <Link
                            title={i18n.t(k.BUILDING_SENSORS)}
                            id="sensorsTip"
                            to={`/sensors/${asset._id}`}
                            className="pointer"
                        >
                            <ApartmentIcon />
                        </Link>
                        <Link
                            title={i18n.t(k.IOT_SENSOR)}
                            id="sensorTip2"
                            to={`/sensor/list?parent=${asset._id}&property_type=building`}
                            className="pointer"
                        >
                            <ListOfSensors />
                        </Link>
                        <Link
                            title={i18n.t(k.IOT_SENSOR)}
                            id="sensorTip"
                            // href={`sensor/list?parent=${asset._id}&property_type=building`}
                            to="#"
                            onClick={(e) => handleShowConfirmLinkAssets(asset)}
                            className="pointer"
                        >
                            <img
                                src={IotImg}
                                style={{
                                    width: "1rem",
                                    height: "1rem",
                                }}
                            />
                        </Link>
                        <Link
                            title={"Safety video"}
                            id="saftyVideoTip"
                            onClick={(e) => handleAttachSafetyVideo(e, asset)}
                            className="pointer"
                            to="#"
                        >
                            <SafetyVideoIcon
                                style={{ width: "1rem", height: "1rem" }}
                            />
                        </Link>
                        <Link
                            title={i18n.t(k.DELETE)}
                            id="deleteTip"
                            to="#"
                            className="pointer"
                            onClick={() => {
                                showConfirmRemoveDialog(asset);
                            }}
                        >
                            <DeleteForever />
                        </Link>
                    </span>
                );
            },
        },
    ];

    const onRowClicked = (asset) => {
        history.push(`/floorplans?parent=${asset._id}`);
        // this.props.history.push(`/floorplans/${e._id}`)
    };

    const getParents = () => {
        let parentsList = [];
        if (parent) {
            parentsList = [
                {
                    name: i18n.t(k.PROPERTIES),
                    linkTo: "/landplots",
                },
                {
                    name: parentInfo.name,
                    linkTo: null,
                },
            ];
        }
        return parentsList;
    };

    const getBreadcrumbs = () => {
        if (parent) {
            return <BreadcrumbsComponent parents={getParents()} />;
        } else {
            return;
        }
    };

    const showConfirmRemoveDialog = (asset) => {
        handleShowConfirmRemoveAsset(asset);
    };
    const onAssetRemoved = () => {
        fetchAssetList();
        handleCloseConfirmRemoveAsset();
    };

    const handleShowConfirmLinkAssets = (selected_asset) => {
        setCurrentBuilding(selected_asset);
        setShowConfirmLinkAssets(true);
    };
    const handleCloseConfirmLinkAssets = () => {
        setCurrentBuilding(null);
        setShowConfirmLinkAssets(false);
    };

    const handleAttachSafetyVideo = (e, selected_asset) => {
        e.preventDefault();
        const asset = typesList.filter((itm) => itm.name === "safety_video")[0];

        const data = {
            // ...asset,
            type: ASSET_TYPE.BUILDING,
            description: asset.description,
            name: asset.name,
            properties: { ...asset.properties },
            parent: selected_asset._id,
        };

        dispatch(openSafetyVideoModal(data));

        // if (selected_asset) {
        //     linkAsset(data, true, selected_asset._id);
        // } else {
        //     setFinished(true);
        // }
    };
    // const handleCloseSafetyVideo = (selected_asset) => {
    //     // N.B: Remove this if not useful
    //     dispatch(closeSafetyVideoModal());
    //     setCurrentBuilding(null);
    // };

    const onAssetsLinked = () => {
        fetchAssetList();
        handleCloseConfirmLinkAssets();
    };

    const linkAsset = (asset, stop, parent_id) => {
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                if (stop) {
                    setFinished(true);
                    setSelectedSensorList([]);
                }
            } else {
                NotificationManager.error("Error");
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        const newAsset = {
            ...asset,
            parent: parent_id,
        };

        updateAssetData(
            newAsset,
            localStorage.getItem(STORAGE.MERCHANT_ID),
            APP_ID,
            ASSET_TYPE.BUILDING,
            errorHandler
        ).then((data) => {
            NotificationManager.success("Sensors added");
            return resultHandler(data);
        });
    };
    const fetchChildrenTypes = async () => {
        setLoading(true);
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    setTypesList(data.asset_types);
                    setLoading(false);
                } else {
                    NotificationManager.error("Error");
                    setTypesList();
                    setLoading(false);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setTypesList();
            setLoading(false);
        };
        if (parent) {
            getAssetTypeList(
                {
                    query_application: APP_ID,
                    // query_parent: type,
                },
                errorHandler
            )
                .then((data) => {
                    return resultHandler(data);
                })
                .catch((error) => {
                    console.log("getAssetTypeList: modal: error", error);
                });
        } else {
            setAssetList();
        }
    };

    return (
        <div>
            <div className="container-fluid">
                <PageHeaderComponent
                    title={`${i18n.t(k.BUILDINGS)} (${
                        parentInfo?.name || "-"
                    })`}
                />
            </div>
            <CardWrapperComponent
                header={true}
                footer={true}
                card_title={getBreadcrumbs()}
            >
                <Button
                    className="mr-2"
                    theme="info"
                    onClick={() => {
                        if (parent) {
                            history.push(
                                `/assets/create?type=${ASSET_TYPE.BUILDING}&parent=${parent}`
                            );
                        } else {
                            NotificationManager.error(
                                "Landplot is not defined"
                            );
                        }
                    }}
                >
                    <AddIcon className="mr-2" />
                    {i18n.t(k.CREATE_BUILDING)}
                </Button>
                <Button
                    theme="info"
                    onClick={() => {
                        if (parent) {
                            history.push(`/newParkingPlace/new/${parent}`);
                        } else {
                            NotificationManager.error(
                                "Landplot is not defined"
                            );
                        }
                    }}
                >
                    <AddIcon />
                    {i18n.t(k.CREATE_PARKING_PLACE)}
                </Button>
                <PageableDatatablesComponent
                    autoWidth={true}
                    columns={columns}
                    background="#ffffff00"
                    items={assetList}
                    loadItems={(page) => {
                        fetchAssetList(page);
                    }}
                    noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                    paginationComponentOptions={{
                        rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                        rangeSeparatorText: i18n.t(k.ROWS_OF),
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                    }}
                    progressComponent={<Circular />}
                    progressPending={loading}
                    paginationServer={true}
                    paginationPerPage={PAGE_ITEMS_COUNT}
                    paginationRowsPerPageOptions={[PAGE_ITEMS_COUNT]}
                    onRowClicked={onRowClicked}
                />
                {showConfirmRemoveAsset.asset && (
                    <AssetDeleteModal
                        show={showConfirmRemoveAsset.visible}
                        assetType={ASSET_TYPE.BUILDING}
                        onHide={() => handleCloseConfirmRemoveAsset()}
                        assetToRemove={showConfirmRemoveAsset.asset}
                        onAssetRemoved={onAssetRemoved}
                    />
                )}
                {showConfirmLinkAssets && (
                    <SensorListModal
                        show={showConfirmLinkAssets}
                        onHide={() => handleCloseConfirmLinkAssets()}
                        parent={currentBuilding._id}
                        // type={type}
                        type={ASSET_TYPE.BUILDING}
                        // property_type={property_type}
                        property_type={ASSET_TYPE.BUILDING}
                        onAssetsLinked={onAssetsLinked}
                    />
                )}
                {/* { && (
                    <VideosListModal
                        show={showConfirmLinkAssets}
                        onHide={() => handleCloseConfirmLinkAssets()}
                        parent={currentBuilding._id}
                        // type={type}
                        type={ASSET_TYPE.BUILDING}
                        // property_type={property_type}
                        property_type={ASSET_TYPE.BUILDING}
                        onAssetsLinked={onAssetsLinked}
                    />
                )} */}
            </CardWrapperComponent>
        </div>
    );
};

// export default connect(mapStateToProps, mapDispatchToProps)(BuildingList);
export default BuildingList;
