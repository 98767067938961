import React, { Component } from "react";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import "react-confirm-alert/src/react-confirm-alert.css";
import StepWizard from "react-step-wizard";
import CenteredPageLoader from "../ui_utils/CenteredPageLoader";
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";
import ShareholdersCalendarComponent from "./ShareholdersCalendarComponent";
import WizardCalendarNavComponent from "./WizardCalendarNavComponent";
import EventEditComponent from "./EventEditComponent";
import QuestionListComponent from "./QuestionListComponent";
import HonkioAPI from "../../middlewares/HonkioAPI";
import NotificationManager from "react-notifications/lib/NotificationManager";
import i18n from "i18next";
import k from "../../i18n/keys";
import { confirmAlert } from "react-confirm-alert";

import moment from "moment";
import "moment/locale/fi";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

export default class CalendarMainComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            edit: {},
            questions: [],
            object: {},
        };
        this.onDayClick = this.onDayClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onEventSubmit = this.onEventSubmit.bind(this);
        this.initBuilding = this.initBuilding.bind(this);
        this.addQuestion = this.addQuestion.bind(this);
        this.onEventClick = this.onEventClick.bind(this);
        this.onEventRemove = this.onEventRemove.bind(this);
        this.loadQuestions = this.loadQuestions.bind(this);
        this.questionDelete = this.questionDelete.bind(this);
    }

    componentDidMount() {
        this.setState({ building: this.props.match.params.buildingId });
    }

    handleChange(e) {
        if (e.target) {
            this.setState({
                edit: { ...this.state.edit, [e.target.id]: e.target.value },
            });
        } else {
            this.setState({
                edit: { ...this.state.edit, meeting_type: e.value },
            });
        }
    }

    questionDelete(question) {
        let that = this;
        let merchantpollset = async function () {
            await HonkioAPI().userShop.userFetch("merchantpollset", {
                id: question.id,
                delete: true,
            });
            that.loadQuestions(question.object);
        };
        merchantpollset();
    }

    loadQuestions(eventId) {
        let that = this;
        if (eventId) {
            let merchantpolllist = async function () {
                let response = await HonkioAPI().userShop.userFetch(
                    "userpollslist",
                    {
                        query_object: eventId,
                        query_properties: {
                            $and: [
                                {
                                    type: {
                                        $ne: "participate",
                                    },
                                },
                            ],
                        },
                        query_active: true,
                        query_visible: true,
                        query_object_type: "reservation",
                    }
                );
                that.setState({ questions: response.polls, loading: false });
            };
            merchantpolllist();
        }
    }

    onDayClick(date) {
        let start = moment(date.date);
        let end = start.clone().add(1, "hour");
        this.setState({
            edit: {
                header: "Reserve",
                dateStr:
                    start.format("DD-MM-YYYY HH:mm") +
                    " - " +
                    end.format("DD-MM-YYYY HH:mm"),
                start_date: start,
                end_date: end,
            },
        });
    }

    onEventRemove() {
        //todo: ConfirmDelete (delete event and cascade delete questions)
        let event = this.state.edit;
        let questions = this.state.questions;
        let eventId = event.id;
        let that = this;
        const options = {
            title: "Destructive action",
            message: "",
            childrenElement: () => (
                <p>
                    {i18n.t(k.ARE_YOU_SURE_WANT_TO_DELETE1)}{" "}
                    <b>{event.title}</b>
                    {i18n.t(k._4)}
                </p>
            ),
            buttons: [
                {
                    label: i18n.t(k.YES),
                    onClick: () => {
                        const del = async () => {
                            let response = await HonkioAPI().userShop.userFetch(
                                "merchanteventset",
                                {
                                    id: event.id,
                                    object: this.props.match.params.buildingId,
                                    object_type: "building",
                                    delete: true,
                                }
                            );

                            questions.forEach((question) => {
                                if (question.id) {
                                    HonkioAPI().userShop.userFetch(
                                        "merchantpollset",
                                        {
                                            id: question.id,
                                            delete: true,
                                        }
                                    );
                                }
                            });
                            if (response.status === "accept") {
                                NotificationManager.success(
                                    i18n.t(k.LOADING),
                                    i18n.t(k.EVENT_REMOVED)
                                );
                            } else {
                                NotificationManager.success(
                                    `${response.description}`,
                                    i18n.t(k.DELETE_FAILED)
                                );
                            }
                            setTimeout(() => {
                                window.location.reload(false);
                            }, 1000);
                        };
                        del();
                    },
                },

                {
                    label: i18n.t(k.NO),
                    onClick: () => {},
                },
            ],

            closeOnEscape: true,
            closeOnClickOutside: true,
        };

        confirmAlert(options);
    }

    onEventClick(event) {
        let type = { value: "general", label: i18n.t(k.GENERAL) };
        if (event.extendedProps.fields.meeting_type === "annual") {
            type = { value: "annual", label: i18n.t(k.BASIC) };
        }
        this.setState({
            edit: {
                agenda: event.extendedProps.fields.agenda,
                description: event.extendedProps.description,
                header: "Reserve",
                start_date: event.start,
                end_date: event.end,
                title: event.title,
                id: event.id,
                type: type,
            },
        });
    }

    initBuilding(object) {
        this.setState({ ...this.state, object });
    }

    onEventSubmit() {
        let that = this;
        let params = {
            event: {
                areaId: this.props.match.params.buildingId,
                id: this.state.edit.id,
                start: this.state.edit.start_date,
                end: this.state.edit.end_date,
                customFields: {
                    meeting_type: this.state.edit.meeting_type,
                    agenda: this.state.edit.agenda,
                },
                type: "busy",
                description: this.state.edit.title,
                iCal: true,
            },
            calToIcs: {
                query_object: this.props.match.params.buildingId,
                query_object_type: "building",
                query_start_date: moment(this.state.object.exp_start)
                    .format("YYYY-MM-DD HH:mm:ss")
                    .toLocaleString(),
                query_end_date: moment(this.state.object.exp_end)
                    .format("YYYY-MM-DD HH:mm:ss")
                    .toLocaleString(),
            },
            setPoll: {
                end: this.state.edit.end_date,
            },
        };
        let save = async function () {
            let query = {
                object: params.event.areaId,
                object_type: "building",
                type: params.event.type,
                repeat: "once",
                description: params.event.description,
                start: moment
                    .utc(moment(params.event.start))
                    .format("YYYY-MM-DD HH:mm:ss")
                    .toLocaleString(),
                end: moment
                    .utc(moment(params.event.end))
                    .format("YYYY-MM-DD HH:mm:ss")
                    .toLocaleString(),
                fields: params.event.customFields,
                notify: true,
            };

            if (params.event.id) {
                query["id"] = params.event.id;
            }

            if (params.event.iCal) {
                let queryToIcs = {
                    query_object: params.calToIcs.query_object,
                    query_object_type: params.calToIcs.query_object_type,
                    query_start_date: params.calToIcs.query_start_date,
                    query_end_date: params.calToIcs.query_end_date,
                };
                let response = await HonkioAPI().userShop.userFetch(
                    "merchantfullcalendartoics",
                    queryToIcs
                );
                // console.log("ICAL SET")
                // console.log(response)
                query["ical"] = response.ical;
            }

            let json = await HonkioAPI().userShop.userFetch(
                "merchanteventset",
                query
            );

            query = {
                object: json._id,
                object_type: "reservation",
                title: "TITLE",
                text: "TEXT",
                options: {
                    0: {
                        name: "Yes",
                    },
                    1: {
                        name: "No",
                    },
                },
                creation_date: moment
                    .utc(moment())
                    .format("YYYY-MM-DD HH:mm:ss")
                    .toLocaleString(),
                expire_date: moment
                    .utc(moment(params.event.start))
                    .format("YYYY-MM-DD HH:mm:ss")
                    .toLocaleString(),
                properties: {
                    answer: "0",
                    type: "participate",
                },
                active: true,
                visible: true,
            };
            await HonkioAPI().userShop.userFetch("merchantpollset", query);
            NotificationManager.success(i18n.t(k.EVENT_SAVED));
            that.state.questions.forEach((question) => {
                if (!question["_id"]) {
                    question["object"] = json._id;
                    question["creation_date"] = moment
                        .utc(moment())
                        .format("YYYY-MM-DD HH:mm:ss")
                        .toLocaleString();
                    question["expire_date"] = moment
                        .utc(moment(params.event.end))
                        .format("YYYY-MM-DD HH:mm:ss")
                        .toLocaleString();
                    question["active"] = true;
                    question["visible"] = true;
                    HonkioAPI().userShop.userFetch("merchantpollset", question);
                }
            });
            that.setState({
                ...that.state,
                object: {},
                edit: {},
                questions: [],
            });
        };
        save();
    }

    addQuestion(question) {
        let questions = this.state.questions;
        questions.push(question);
        this.setState({ questions: [...questions] });
    }

    render() {
        // console.log("MAIN STATE")
        // console.log(this.state)
        if (this.state.loading) {
            return <CenteredPageLoader />;
        }

        let edit = <CenteredPageLoader />;
        if (this.state.edit) {
            edit = (
                <EventEditComponent
                    edit={this.state.edit}
                    onEventRemove={this.onEventRemove}
                    handleChange={this.handleChange}
                />
            );
        }

        return (
            <div>
                <div className="container-fluid">
                    <PageHeaderComponent
                        title={i18n.t(k.SHAREHOLDERS_CALENDAR)}
                        subtitle={i18n.t(k.OVERVIEW)}
                    />
                </div>
                <CardWrapperComponent
                    header={false}
                    footer={true}
                    card_title={i18n.t(k.AGREEMENT_CREATE)}
                >
                    <StepWizard
                        className="wizard"
                        nav={
                            <WizardCalendarNavComponent
                                building={this.props.match.params.buildingId}
                                onSaveClick={this.onEventSubmit}
                            />
                        }
                    >
                        <ShareholdersCalendarComponent
                            building={this.props.match.params.buildingId}
                            onDayClick={this.onDayClick}
                            onEventClick={this.onEventClick}
                            initBuilding={this.initBuilding}
                        />
                        {edit}
                        <QuestionListComponent
                            questions={this.state.questions}
                            addQuestion={this.addQuestion}
                            edit={this.state.edit}
                            loadQuestions={this.loadQuestions}
                            questionDelete={this.questionDelete}
                        />
                    </StepWizard>
                </CardWrapperComponent>
            </div>
        );
    }
}
