import i18n from "i18next";
import k from "./../../../i18n/keys";
import React, { Component } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { UPLOAD_URL } from "../../../config";
import { post } from "axios";
import { Modal, ModalBody, ModalHeader } from "shards-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "react-select";

export default class DocumentUploadModalComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleChange(files) {
        this.setState({
            files: files,
        });
    }

    handleButtonUpload = () => {
        let itemsProcessed = 0;
        let that = this;
        let allowedExtensions = [
            "doc",
            "docx",
            "xls",
            "xlsx",
            "pdf",
            "rtf",
            "html",
            "djvu",
            "ifc",
            "txt",
            "mp4",
            "avi",
            "wmv",
        ];
        this.state.files.forEach((file) => {
            let filename = file.name.split(".")[0];
            let filepath = file.path;

            let inArray = allowedExtensions.includes(file.name.split(".")[1]);
            if (inArray) {
                that.setState({ loading: true });

                let savefile = async function () {
                    let params = {
                        title: filename,
                        file: filepath,
                    };

                    let fileset = await HonkioAPI().userShop.userFetch(
                        "userfileset",
                        {
                            object: that.props.areaId,
                            object_type: "area",
                            access: "private",
                            properties: params,
                        }
                    );

                    let data = {
                        id: fileset.id,
                        upload_token: fileset.upload_token,
                    };

                    const formData = new FormData();
                    formData.append("file", file, file.name);
                    formData.append(
                        "message",
                        new Blob([JSON.stringify(data)], {
                            type: "application/json",
                        })
                    );

                    const config = {
                        headers: {
                            "content-type": "multipart/form-data",
                        },
                    };

                    let url, thumb;
                    await post(UPLOAD_URL, formData, config).then(
                        (uploadFile) => {
                            url = uploadFile.data.url;
                            thumb = uploadFile.data.thumbnail_url;
                        },
                        (error) => {
                            that.setState({ loading: false });
                            NotificationManager.warning(
                                i18n.t(k.DOCUMENT_UPLOAD_FAILED),
                                `${error}`
                            );
                        }
                    );

                    itemsProcessed++;
                    NotificationManager.success(
                        `${i18n.t(k.UPLOAD_SUCCESS)} ${filename}`,
                        i18n.t(k.SUCCESS)
                    );
                    if (itemsProcessed === that.state.files.length) {
                        that.setState({ loading: false });
                        that.props.onMediaUploaded();
                    }
                };
                savefile();
            } else {
                itemsProcessed++;
                NotificationManager.warning(
                    `${i18n.t(k.UNSUPPORTED_EXTENSION)} ${filename}`,
                    i18n.t(k.DOCUMENT_UPLOAD_FAILED)
                );
                if (itemsProcessed === that.state.files.length) {
                    that.setState({ loading: false });
                    that.props.onMediaUploaded();
                }
            }
        });
    };

    handleChangeType(e = null) {
        this.setState({ doc_type: e.value });
    }

    render() {
        let that = this;
        let button = (
            <button
                className="btn btn-primary"
                onClick={this.handleButtonUpload}
            >
                {i18n.t(k.UPLOAD_DOCUMENT)}
            </button>
        );
        if (this.state.loading) {
            button = <CircularProgress size={35} />;
        }

        return (
            <Modal
                open={this.props.visible}
                toggle={this.props.toggle}
                size="lg"
                onRequestClose={this.props.onRequestClose}
                className="w-100"
            >
                <ModalHeader>{i18n.t(k.UPLOAD_DOCUMENT)}</ModalHeader>
                <ModalBody>
                    <DropzoneArea
                        dropzoneText={
                            "Drag and drop the Media file here or click"
                        }
                        onChange={this.handleChange.bind(this)}
                        showPreviews={true}
                        showPreviewsInDropzone={false}
                        maxFileSize={100000000}
                        useChipsForPreview
                        filesLimit={10}
                        previewGridProps={{
                            container: { spacing: 1, direction: i18n.t(k.ROW) },
                        }}
                        previewText="Selected media files"
                        showAlerts={false}
                        styles={{
                            "MuiDropzoneArea-root": {
                                maxHeight: 150,
                                minHeight: 150,
                            },
                        }}
                    />

                    <hr />
                    <div className="form-group mt-3">{button}</div>
                </ModalBody>
            </Modal>
        );
    }
}
