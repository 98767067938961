import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import i18n from "i18next";
import k from "../../i18n/keys";
import CircularProgress from "@material-ui/core/CircularProgress";
import getColumnsForType from "./helpers/getColumnsForType";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { ASSET_TYPE } from "../../actions/api/assets/config";
import { APP_ID } from "../../config";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { Button } from "shards-react";
import { deleteAsset } from "../../actions/api/assets/setAssetData";
import getAssetTypeList from "../../actions/api/assetTypes/getAssetTypeList";
import getAsset from "../../actions/api/assets/getAssets";
import { getAssetList } from "../../actions/api/assets/getAssetList";
import useSearchString from "../../utils/useSearchString";
import {
    openSafetyVideoModal,
    closeSafetyVideoModal,
} from "../../actions/action_utils";
import SensorListModal from "../sensors/SensorListModal";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";

const Circular = () => (
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

// TODO: move all modals to the top level as VideosListModal and load data into redux store on any action
const GroupableDatatable = ({ parent, onRowClicked, forceUpdate }) => {
    const dispatch = useDispatch();
    const [parentInfo, setParentInfo] = useState([]);

    const [groupedAssets, setGroupedAssets] = useState(null);

    const [loading, setLoading] = useState(false);

    // Modals and actions - move to store and top level
    const [assetList, setAssetList] = useState([]);
    const [typesList, setTypesList] = useState([]);
    const [currentAsset, setCurrentAsset] = useState(null);
    const [assetToRemove, setAssetToRemove] = useState();
    const [showConfirmRemoveAsset, setShowConfirmRemoveAsset] = useState(false);
    const [showConfirmLinkAssets, setShowConfirmLinkAssets] = useState(false);
    const handleShowConfirmRemoveAsset = () => setShowConfirmRemoveAsset(true);
    const handleCloseConfirmRemoveAsset = () =>
        setShowConfirmRemoveAsset(false);

    const handleShowConfirmLinkAssets = (selected_asset) => {
        setCurrentAsset(selected_asset);
        setShowConfirmLinkAssets(true);
    };
    const handleCloseConfirmLinkAssets = () => {
        setCurrentAsset(null);
        setShowConfirmLinkAssets(false);
    };

    const onAssetsLinked = () => {
        fetchAssetList();
        handleCloseConfirmLinkAssets();
    };

    useEffect(() => {
        fetchAssetList();
        fetchChildrenTypes();
        return () => {
            setAssetList([]);
            setTypesList([]);
            setGroupedAssets(null);
            setParentInfo([]);
        };
    }, [parent, forceUpdate]);

    useEffect(() => {
        if (parent) fetchParent();
    }, [parent]);

    const fetchChildrenTypes = async () => {
        setLoading(true);
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                setTypesList(data.asset_types);
                setLoading(false);
            } else {
                NotificationManager.error("Error");
                setTypesList();
                setLoading(false);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setTypesList();
            setLoading(false);
        };
        getAssetTypeList(
            {
                query_application: APP_ID,
                // query_parent: type,
            },
            errorHandler
        )
            .then((data) => {
                return resultHandler(data);
            })
            .catch((error) => {
                console.log("getAssetTypeList: modal: error", error);
            });
    };

    const fetchParent = async () => {
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                setParentInfo(data.asset);
            } else {
                NotificationManager.error("Error");
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setParentInfo();
        };
        if (parent) {
            setLoading(true);
            getAsset({ id: parent }, errorHandler)
                .then((data) => {
                    setLoading(false);
                    return resultHandler(data);
                })
                .catch((err) => {
                    setLoading(false);
                    console.error("Error getting parent info", err);
                });
        } else {
            setParentInfo();
        }
    };

    const onDelete = () => {
        handleCloseConfirmRemoveAsset();

        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                NotificationManager.success("Successfully removed");
                fetchAssetList();
            } else {
                NotificationManager.error("Error");
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        deleteAsset(assetToRemove._id, errorHandler)
            .then((data) => {
                return resultHandler(data);
            })
            .catch(console.error);
    };

    const groupAssetsByType = (assets = []) => {
        const grouped = {};
        if (!assets.length) return {};
        assets.forEach((item) => {
            if (grouped[item.type]) {
                grouped[item.type].push(item);
                return;
            }
            grouped[item.type] = [];
            grouped[item.type].push(item);
        });
        setGroupedAssets(grouped);
    };

    useEffect(() => {
        if (assetList.length) groupAssetsByType(assetList);
    }, [assetList]);

    const renderGroupName = (groupName = "other_asset") =>
        capitalizeFirstLetter(groupName);

    const showConfirmRemoveDialog = (asset) => {
        setAssetToRemove(asset);
        handleShowConfirmRemoveAsset();
    };

    const handleAttachSafetyVideo = (selected_asset) => {
        const asset = typesList.filter((itm) => itm.name === "safety_video")[0];

        const data = {
            type: ASSET_TYPE.BUILDING,
            description: asset.description,
            name: asset.name,
            properties: { ...asset.properties },
            parent: selected_asset._id || selected_asset.id,
        };

        dispatch(openSafetyVideoModal(data));
    };

    /**
     * Load columns for DataTable according to asset (`elem`) type
     */
    const createColumns = (elem) => {
        try {
            // Render all the asset properties as columns (replaced by prerendered columns)
            // const additionalColumns = Object.values(elem.properties)
            //     .filter((pr) => pr?.property_name)
            //     .map((item) => ({
            //         name: item.property_name,
            //         selector: `properties.${item.property_name}`,
            //         grow: 2,
            //         sortable: true,
            //         style: `cursor: pointer;`,
            //     }));

            // return [columnsBase[0]]
            //     .concat(additionalColumns)
            //     .concat(actionsColumn);
            // console.log("createColumns: elem", elem);
            const newColumns = getColumnsForType(elem.type, {
                handleAttachSafetyVideo,
                showConfirmRemoveDialog,
                handleShowConfirmLinkAssets,
            });

            return newColumns;
        } catch (error) {
            console.log("error creating columns", error);
            return [];
        }
    };

    const fetchAssetList = async () => {
        setLoading(true);
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                setAssetList(data.assets);
                setLoading(false);
            } else {
                NotificationManager.error("Error");
                setAssetList();
                setLoading(false);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setAssetList();
            setLoading(false);
        };
        if (parent) {
            getAssetList(
                {
                    levels: 3,
                    parent: parent,
                },
                errorHandler
            )
                .then((data) => {
                    return resultHandler(data);
                })
                .catch(console.error);
        }
    };

    // useEffect(() => {
    //     if (groupedAssets) console.log("*** groupedAssets", groupedAssets);
    // }, [groupedAssets]);

    /**
     * Renders multiple DataTables according to asset types
     */
    const renderGrouped = () => {
        if (!groupedAssets) {
            return (
                <DataTable
                    autoWidth={true}
                    columns={[]}
                    background="#ffffff00"
                    data={assetList}
                    noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                    paginationComponentOptions={{
                        rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                        rangeSeparatorText: i18n.t(k.ROWS_OF),
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                    }}
                    progressComponent={<Circular />}
                    progressPending={loading}
                    onRowClicked={onRowClicked}
                />
            );
        }

        return Object.keys(groupedAssets).map((groupName) => (
            <React.Fragment key={groupName}>
                <h4 className="mt-4">{renderGroupName(groupName)}</h4>
                <DataTable
                    noHeader={true}
                    autoWidth={true}
                    columns={createColumns(groupedAssets[groupName][0])}
                    background="#ffffff00"
                    data={groupedAssets[groupName]}
                    noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                    paginationComponentOptions={{
                        rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                        rangeSeparatorText: i18n.t(k.ROWS_OF),
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                    }}
                    progressComponent={<Circular />}
                    progressPending={loading}
                    onRowClicked={onRowClicked}
                />
            </React.Fragment>
        ));
    };

    return (
        <React.Fragment>
            <Modal
                show={showConfirmRemoveAsset}
                onHide={handleCloseConfirmRemoveAsset}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you want to remove plot?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseConfirmRemoveAsset}
                    >
                        Close
                    </Button>
                    <Button variant="danger" onClick={() => onDelete()}>
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>
            {showConfirmLinkAssets && (
                <SensorListModal
                    show={showConfirmLinkAssets}
                    onHide={() => handleCloseConfirmLinkAssets()}
                    parent={currentAsset._id}
                    // type={type}
                    // type={ASSET_TYPE.BUILDING}
                    type={currentAsset.type}
                    // property_type={property_type}
                    // property_type={ASSET_TYPE.BUILDING}
                    property_type={currentAsset.type}
                    onAssetsLinked={onAssetsLinked}
                />
            )}
            {renderGrouped()}
        </React.Fragment>
    );
};

export default GroupableDatatable;
