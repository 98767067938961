import i18n from "i18next";
import k from "./../../i18n/keys";
import React from "react";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import timegrid from "@fullcalendar/timegrid";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { Button } from "shards-react";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-notifications/dist/react-notifications.css";
import "@fullcalendar/daygrid/main.css";
import dayGridPlugin from "@fullcalendar/daygrid";
import "react-notifications/dist/react-notifications.js";
import HonkioAPI from "../../middlewares/HonkioAPI";
import AddIcon from "@material-ui/icons/Add";

import moment from "moment";
import "moment/locale/fi";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

export default class ShareholdersCalendarComponent extends React.Component {
    constructor(props) {
        super(props);
        this.calendarRef = React.createRef();
        this.onCalendarDayClick = this.onCalendarDayClick.bind(this);
        this.onCalendarEventClick = this.onCalendarEventClick.bind(this);
        this.export = this.export.bind(this);

        this.state = {
            initLoaded: "no",
        };
    }

    componentDidMount() {
        this.setState({ buildingId: this.props.building });
        if (this.state.initLoaded === "no") {
            this.initBuildingEventsSource();
            if (this.calendarRef.current) {
                let calendarApi = this.calendarRef.current.getApi();
                calendarApi.addEventSource(this.buildingEventsSource);
                this.setState({ initLoaded: "yes" });
            }
            // console.log(this.calendarRef.current)
        }
    }

    getRanges() {
        let now = new Date();
        let start = moment(
            new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate(),
                0,
                0,
                0,
                0
            )
        );
        let end = moment(start).add(1, "days").subtract(1, "seconds");
        return {
            Today: [moment(start), moment(end)],
            Tomorrow: [
                moment(start).add(1, "days"),
                moment(end).add(1, "days"),
            ],
            "The day after tomorrow": [
                moment(start).add(2, "days"),
                moment(end).add(2, "days"),
            ],
            "3 Days": [moment(start).add(3, "days"), moment(end)],
        };
    }

    getEventTimeFormat() {
        return {
            // like '14:30:00'
            hour: "2-digit",
            minute: "2-digit",
            meridiem: false,
            hour12: false,
        };
    }

    getEventTimeFormatSlot() {
        return {
            // like '14:30:00'
            hour: "2-digit",
            minute: "2-digit",
            meridiem: false,
            hour12: false,
        };
    }

    onCalendarDayClick(date) {
        // bind with an arrow function
        this.props.onDayClick(date);
        this.props.nextStep();
    }

    onCalendarEventClick(event) {
        // bind with an arrow function
        this.props.onEventClick(event.event);
        this.props.nextStep();
    }

    initBuildingEventsSource() {
        if (this.props.building) {
            let buidldingId = this.props.building;
            let that = this;
            this.buildingEventsSource = function (
                options,
                successCallback,
                failureCallback
            ) {
                const request = async () => {
                    let params = {
                        query_object: buidldingId,
                        query_object_type: "building",
                        query_start_date: moment(options.start)
                            .format("YYYY-MM-DD HH:mm:ss")
                            .toLocaleString(),
                        query_end_date: moment(options.end)
                            .format("YYYY-MM-DD HH:mm:ss")
                            .toLocaleString(),
                    };
                    let data = await HonkioAPI().userShop.userFetch(
                        "merchantgetfullcalendar",
                        params
                    );
                    // console.log("params")
                    // console.log(params)
                    let schedule = [];
                    data.schedule.forEach((item) => {
                        item["start"] = moment.utc(item["start"]).toDate();
                        item["end"] = moment.utc(item["end"]).toDate();
                        item["id"] = item["_id"];
                        schedule.push(item);
                    });
                    that.props.initBuilding({
                        exp_start: options.start,
                        exp_end: options.end,
                    });
                    successCallback(schedule);
                };
                request();
            };
        }
    }

    export() {
        const request = async () => {
            let params = {
                query_object: this.state.buildingId,
                query_object_type: "building",
                query_start_date: moment(this.state.exp_start)
                    .format("YYYY-MM-DD HH:mm:ss")
                    .toLocaleString(),
                query_end_date: moment(this.state.exp_end)
                    .format("YYYY-MM-DD HH:mm:ss")
                    .toLocaleString(),
                notify: true,
            };
            let response = await HonkioAPI().userShop.userFetch(
                "merchantfullcalendartoics",
                params
            );
            let ics = response.ok;
            let myblob = new Blob([ics], {
                type: "text/calendar",
            });
            let blobUrl = window.URL.createObjectURL(myblob);
            let a = document.createElement("a");
            a.download = "Shareholders calendar.ics";
            a.href = blobUrl;
            document.body.appendChild(a);
            a.click();
            a.remove();
            NotificationManager.success(
                i18n.t(k.FILE_DOWNLOADING_STARTED_LONG)
            );
        };
        request();
    }

    render() {
        return (
            <div>
                <Button
                    className="mb-1 mt-1"
                    theme="info"
                    onClick={this.export}
                >
                    <AddIcon /> {i18n.t(k.REQUEST_SHAREHOLDERS_CALENDAR)}
                </Button>
                <FullCalendar
                    defaultView="dayGridMonth"
                    ref={this.calendarRef}
                    dateClick={this.onCalendarDayClick}
                    eventClick={this.onCalendarEventClick}
                    plugins={[dayGridPlugin, timegrid, interactionPlugin]}
                    eventTimeFormat={this.getEventTimeFormat()}
                    displayEventEnd={true}
                    firstDay={1}
                    contentHeight="500px"
                />
                ;
            </div>
        );
    }
}
