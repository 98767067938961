import React, { Component } from "react";
import { connect } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import { format } from "d3-format";
import {
    BarChart,
    ChartContainer,
    ChartRow,
    Charts,
    LineChart,
    ScatterChart,
    styler,
    YAxis,
} from "react-timeseries-charts";
import { TimeRange, TimeSeries } from "pondjs";
import moment from "moment";
import "moment/locale/fi";
import CenteredPageLoader from "../ui_utils/CenteredPageLoader";
import CenteredLoader from "../ui_utils/CenteredLoader";
import { FormCheckbox } from "shards-react";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

class CashFlowChartComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            costsSeries: null,
            timerange: new TimeRange([new Date(1, 1), new Date(1, 1, 1)]),
            total: {},
            options: {},
            mode: "month",
            chartType: "bars",
            areaData: [],
            columns: [],
            excludeColumns: [],
            allColumns: [],
        };
        this.setContainerRef = (element) => {
            this.container = element;
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let costs, areasData, options;
        this.setState({ costsSeries: null, loading: true });
        if (nextProps.costs) {
            costs = nextProps.costs;
        }
        if (nextProps.areasData) {
            areasData = nextProps.areasData;
        }
        if (nextProps.options) {
            options = nextProps.options;
        }
        this.parseChart(costs, areasData, options);
    }

    componentDidMount() {
        this.parseChart(this.props.costs, this.props.areasData);
    }

    onColumnCheckClick = (k) => {
        if (this.state.excludeColumns.indexOf(k) >= 0) {
            this.parseChart(undefined, undefined, undefined, [
                ...this.state.excludeColumns.filter((o) => o !== k),
            ]);
        } else {
            this.parseChart(undefined, undefined, undefined, [
                ...this.state.excludeColumns,
                k,
            ]);
        }
    };

    parseChart = (costs, areaData, options, doForecast) => {
        if (!options) options = this.state.options;
        let that = this;
        costs = costs ? costs : this.state.costs;
        this.setState({ costsSeries: null, loading: true });
        let mode = options.mode ? options.mode : that.state.mode;
        let chartType = options.chartType
            ? options.chartType
            : that.state.chartType;
        let forecast =
            doForecast !== undefined ? doForecast : that.state.forecast;
        areaData = areaData ? areaData : this.state.areaData;
        let inflation = options.inflation ? options.inflation : 5;
        let modelLength = options.modelLength ? options.modelLength : 10;
        if (modelLength > 15 || modelLength < 1) modelLength = 10;
        // areaData.splice(0, areaData.length - 5)
        // areaData=[]
        let areaNames = areaData.map((d) => d.area_name);
        let data = {};
        let total = {};
        let points = [];
        let columns = ["income"];
        let maxSum = 0,
            max = 0,
            min = 0,
            avgCosts = 0;
        let costnames = [];
        costs.forEach((d) => {
            Object.keys(d.data).forEach((k) => {
                if (!costnames.includes(k)) costnames.push(k);
            });
        });

        costs.forEach((d) => {
            let timestamp = moment(d.updated_date).startOf("month").toDate(); //.format('YYYY-MM');
            if (that.state.mode === "year") {
                timestamp = moment(d.updated_date).startOf("year").toDate(); //.format('YYYY');
            }

            let costs = 0;
            costnames.forEach((cname) => {
                if (d.data[cname] && parseFloat(d.data[cname])) {
                    costs += parseFloat(d.data[cname]);
                }
            });
            if (!data[timestamp]) {
                data[timestamp] = -costs;
            } else {
                data[timestamp] -= costs;
            }
        });

        if (forecast) {
            let b = Object.keys(data).sort();
            let latest = Object.keys(data).sort((a, b) => {
                return a[0] - b[0];
            })[Object.keys(data).length - 1];
            let first = Object.keys(data).sort((a, b) => {
                return a[0] - b[0];
            })[0];
            // alert(first)
            // alert(latest)
            // points = points.sort((a, b) => {return a[0] - b[0]});
            let allCosts = 0;
            Object.values(data).forEach((c) => (allCosts += parseFloat(c)));
            let avgCosts = Math.abs(
                allCosts /
                    moment(latest).diff(
                        moment(first),
                        mode === "month" ? "months" : "years",
                        true
                    )
            );
            let currentMonth = moment(latest).toDate();
            let currentForecast = avgCosts;
            for (
                var m = 0;
                m < (mode === "month" ? 12 : 1) * modelLength;
                m++
            ) {
                currentMonth = moment(currentMonth)
                    .add(mode === "month" ? 1 : 12, "month")
                    .toDate();
                currentForecast +=
                    (currentForecast * parseFloat(0.01 * inflation)) /
                    (mode === "month" ? 12 : 1);
                data[currentMonth] = -1 * currentForecast;
            }
        }
        areaNames.forEach((aname) => {
            areaData.forEach((d) => {
                if (d.area_name !== aname || d.leases.length <= 0) {
                    return;
                }
                let start_date = moment(d.leases[0].start_date)
                    .startOf("month")
                    .toDate();
                let end_date = moment(d.leases[0].end_date)
                    .startOf("month")
                    .toDate();
                let current_start = start_date;
                while (current_start < end_date) {
                    let timestamp = moment(current_start)
                        .startOf("month")
                        .toDate(); //.format('YYYY-MM');
                    if (that.state.mode === "year") {
                        timestamp = moment(current_start)
                            .startOf("year")
                            .toDate(); //.format('YYYY');
                    }
                    // alert(parseFloat(d.income))
                    console.error(timestamp, data[timestamp]);
                    if (!data[timestamp]) {
                        data[timestamp] = parseFloat(d.income);
                    } else {
                        data[timestamp] += parseFloat(d.income);
                    }
                    current_start = moment(current_start)
                        .add(1, "month")
                        .toDate();
                }
            });
        });
        Object.keys(data).forEach((k) => {
            points.push([k, data[k]]);
        });

        for (var i = 0; i < points.length; i++) {
            points[i][0] = moment(points[i][0]).unix();
        }
        points = points.sort((a, b) => {
            return a[0] - b[0];
        });
        // alert(0)
        console.error(data);
        console.error(points);
        for (var i = 0; i < points.length; i++) {
            let str = moment.unix(points[i][0]);

            if (mode === "month") {
                str = str.startOf("month").format("YYYY-MM");
            } else {
                str = str.startOf("year").format("YYYY");
            }
            let sum = 0;
            points[i].forEach((row, index) => {
                if (index > 0) {
                    sum += points[i][index];
                    if (points[i][index] > max) max = points[i][index];
                    if (points[i][index] < min) min = points[i][index];
                }
            });
            // if (sum > maxSum) maxSum = sum;
            points[i][0] = str;
        }
        // alert(min + ' ' + max)
        columns = ["index", ...columns];

        let costsSeries = new TimeSeries({
            name: "index",
            columns: columns,
            points: points,
        });

        let allCosts = 0;
        Object.keys(total).forEach((k) => {
            allCosts += total[k];
        });

        // avgCosts = allCosts / moment(points[0][0]).diff(moment(points[points.length-1][0]), 'months', true)

        // alert(avgCosts)
        that.setState({
            costsSeries: costsSeries,
            costs: costs,
            timerange:
                points.length > 0
                    ? costsSeries.timerange()
                    : new TimeRange(
                          moment().startOf("month"),
                          moment().endOf("month")
                      ),
            columns: columns,
            options: options,
            maxSum: maxSum,
            max: max,
            min: min,
            total: total,
            mode: mode,
            forecast: forecast,
            areaData: areaData,
            chartType: chartType,
        });
    };

    handleTrackerChanged = (t) => {
        try {
            if (t) {
                const e = this.state.incomeSeries.atTime(t);
                const eventTime = new Date(
                    e.begin().getTime() +
                        (e.end().getTime() - e.begin().getTime()) / 2
                );

                const eventValue = e.get(this.state.building.name);
                // alert(eventValue)
                const v = `${eventValue > 0 ? "+" : ""}${parseFloat(
                    eventValue
                ).toFixed(2)} EUR}`;
                this.setState({
                    tracker: eventTime,
                    trackerValue: v,
                    trackerEvent: e,
                });
            } else {
                this.setState({
                    tracker: null,
                    trackerValue: null,
                    trackerEvent: null,
                });
            }
        } catch (e) {
            console.error(e);
            this.setState({
                tracker: null,
                trackerValue: null,
                trackerEvent: null,
            });
        }
    };

    handleTimeRangeChange = (timerange) => {
        this.setState({ timerange });
    };

    render() {
        console.error("this.state");
        console.error(this.state);
        if (this.state.loading) {
            // fixme PLEASE, what a shame!
            if (this.state.costsSeries !== null) this.parseChart();
            this.setState({ loading: false });
            return <div>Loading...</div>;
        }

        let infoValues = [];
        let infoNetValues = [];
        const formatter = format(".2s");
        if (this.state.highlight) {
            const valueText = parseFloat(
                this.state.highlight.event.get(this.state.highlight.column)
            ).toFixed(2);
            infoNetValues = [
                { label: this.state.highlight.column, value: valueText },
            ];
        }
        const style = styler(this.state.columns, "Paired");

        const infoStyle = {
            stroke: "#FF0000",
            color: "#00FF00",
            fill: "0000FF",
            opacity: 1.0,
            pointerEvents: "none",
            fontSize: "large",
            labelStyle: {
                fontSize: "normal",
                color: "#000000",
            },
        };

        return (
            <div id="costs_chart">
                <FormCheckbox
                    checked={this.state.forecast}
                    onChange={(e) => {
                        this.parseChart(
                            undefined,
                            undefined,
                            undefined,
                            !this.state.forecast
                        );
                    }}
                >
                    Costs Forecast
                </FormCheckbox>
                <div
                    className="evaluators_cashflow_chart"
                    ref={this.setContainerRef}
                >
                    <ChartContainer
                        width={
                            this.container ? this.container.offsetWidth : 1000
                        }
                        height="500"
                        timeRange={this.state.timerange}
                        enablePanZoom={true}
                        onTimeRangeChanged={this.handleTimeRangeChange}
                        infoStyle={infoStyle}
                        format={
                            this.state.mode === "month"
                                ? (d, c, e) => {
                                      return moment(d).format("MMM");
                                  }
                                : "year"
                        }
                    >
                        <ChartRow height="500">
                            <YAxis
                                id="axis"
                                // height="250"
                                // label="Costs"
                                // classed="Costs"
                                min={this.state.min * 1.25}
                                max={this.state.max * 1.25}
                                // max={(this.state.chartType === 'bars' ? this.state.maxSum : this.state.max) * 1.25}
                                // width="70"
                                type="linear"
                            />
                            <Charts>
                                {this.state.chartType === "bars" ? (
                                    <BarChart
                                        axis="axis"
                                        style={style}
                                        // spacing={5}
                                        minBarHeight={1}
                                        // size={this.state.mode === 'month' ? 25 : 50}
                                        height="500"
                                        width="70"
                                        // columns={this.state.columns}
                                        columns={this.state.columns.slice(
                                            1,
                                            this.state.columns.length
                                        )}
                                        series={this.state.costsSeries}
                                        info={infoNetValues}
                                        labelStyle={{
                                            paddingRight: 10,
                                            cursor: "pointer",
                                        }}
                                        infoStyle={infoStyle}
                                        // stemStyle={infoStyle}
                                        markerStyle={infoStyle}
                                        infoHeight={50}
                                        infoWidth={250}
                                        highlighted={this.state.highlight}
                                        onHighlightChange={(highlight) =>
                                            this.setState({ highlight })
                                        }
                                        selected={this.state.selection}
                                        // onSelectionChange={selection => this.setState({selection})}
                                    />
                                ) : (
                                    [
                                        <ScatterChart
                                            axis="axis"
                                            columns={this.state.columns}
                                            series={this.state.costsSeries}
                                            style={style}
                                        />,
                                        <LineChart
                                            axis="axis"
                                            smooth={true}
                                            interpolation={"curveBasis"}
                                            columns={this.state.columns}
                                            series={this.state.costsSeries}
                                            style={style}
                                        />,
                                    ].map((o) => o)
                                )}
                            </Charts>
                        </ChartRow>
                    </ChartContainer>
                </div>
                {/*<div className="row">*/}
                {/*    <div className="col-6">*/}
                {/*    <h3>Costs</h3>*/}
                {/*        /!*Object.keys(this.state.total)*!/*/}
                {/*        <Legend categories={this.state.allColumns.map((k) => {*/}
                {/*            return {key: k, label: <FormCheckbox*/}
                {/*              checked={this.state.excludeColumns.indexOf(k) < 0}*/}
                {/*              onChange={e => {that.onColumnCheckClick(k)}}*/}
                {/*            >*/}
                {/*                    {`${k}: ${parseFloat(that.state.total[k]).toFixed(2)}`}*/}
                {/*            </FormCheckbox>}*/}

                {/*        })} style={style} type="dot"/>*/}
                {/*    </div>*/}
                {/*    <div className="col-6">*/}
                {/*    <h3>Area Income</h3>*/}
                {/*        /!*Object.keys(this.state.total)*!/*/}
                {/*        <Legend categories={this.state.allColumns.map((k) => {*/}
                {/*            return {key: k, label: <FormCheckbox*/}
                {/*              checked={this.state.excludeColumns.indexOf(k) < 0}*/}
                {/*              onChange={e => {that.onColumnCheckClick(k)}}*/}
                {/*            >*/}
                {/*                    {`${k}: ${parseFloat(that.state.total[k]).toFixed(2)}`}*/}
                {/*            </FormCheckbox>}*/}

                {/*        })} style={style} type="dot"/>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CashFlowChartComponent);
