import React from "react";
import i18n from "i18next";
import k from "./../../i18n/keys";
import { useEffect, useState, useMemo } from "react";
import {
    NotificationManager,
    NotificationContainer,
} from "react-notifications";
import { Link } from "react-router-dom";
import { CONSUMER_URL } from "../../config";
import DataTable from "react-data-table-component";
import { Modal, Button } from "react-bootstrap";
import useSearchString from "./../../utils/useSearchString";
import setMerchantUserRole from "./../../actions/api/roles/setMerchantUserRole";
import getMerchantListUserRole from "./../../actions/api/roles/getMerchantListUserRole";
import DeleteIcon from "@material-ui/icons/Delete";
import CardWrapperComponent from "./../ui_utils/CardWrapperComponent";
import Wait from "./../ui_utils/Wait";

const MerchantUsersList = () => {
    const [users, setUsers] = useState([]);
    const { role = null, role_name = "" } = useSearchString();
    const [userId, setUserId] = useState(null);
    const [userToRemove, setUserToRemove] = useState(null);
    const [showConfirmRemoveUser, setShowConfirmRemoveUser] = useState(false);
    const handleShowConfirmRemoveUser = () => setShowConfirmRemoveUser(true);
    const handleCloseConfirmRemoveUser = () => setShowConfirmRemoveUser(false);

    const [loading, setLoading] = useState(false);
    const pageTitle = `${i18n.t(k.USERS)} ${role_name ? `(${role_name})` : ""}`;
    let isMounted = true;

    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        role && fetchUsersList(role);
    }, [role]);

    const showConfirmDialog = (user_id, email) => {
        setUserId(user_id);
        setUserToRemove({ user_id, email });
        handleShowConfirmRemoveUser();
    };

    const deleteUserFromGroup = () => {
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                NotificationManager.success(
                    i18n.t(k.USER_SUCCESSFULLY_REMOVED_FROM_GROUP),
                    i18n.t(k.SUCCESS),
                    4000
                );
                handleCloseConfirmRemoveUser();
                fetchUsersList(role);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, i18n.t(k.ERROR), 4000);
        };

        setMerchantUserRole(
            {
                role_id: role,
                user_id: userId,
                del: true,
            },
            errorHandler
        )
            .then((data) => {
                return resultHandler(data);
            })
            .then(handleCloseConfirmRemoveUser);
    };

    const fetchUsersList = async (role_id) => {
        setLoading(true);
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    setUsers(data.consumers);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setUsers([]);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setUsers([]);
            setLoading(false);
        };

        getMerchantListUserRole({ role_id: role_id }, errorHandler).then(
            (data) => {
                return resultHandler(data);
            }
        );
    };
    const columns = useMemo(() => [
        {
            name: i18n.t(k.PHOTO),
            sortable: true,
            cell: ({ id }) => (
                <img
                    height="64px"
                    width="64px"
                    src={`${CONSUMER_URL}user_photo/${id}.jpg`}
                    alt="User's avatar"
                />
            ),
        },
        {
            name: i18n.t(k.ACTIVE),
            sortable: true,
            cell: ({ active }) => (active ? "Yes" : "No"),
        },
        {
            name: i18n.t(k.FIRST_NAME),
            sortable: true,
            cell: ({ user_first_name }) => user_first_name,
        },
        {
            name: i18n.t(k.LAST_NAME),
            sortable: true,
            cell: ({ user_last_name }) => user_last_name,
        },
        {
            name: i18n.t(k.EMAIL),
            sortable: true,
            cell: ({ email }) => email,
        },
        {
            name: i18n.t(k.ACTION),
            cell: ({ user_id, email }) => {
                return (
                    <span>
                        <Link
                            to={"#"}
                            className="pointer"
                            onClick={() => {
                                showConfirmDialog(user_id, email);
                            }}
                            title={i18n.t(k.DELETE_FROM_GROUP)}
                        >
                            <DeleteIcon />
                        </Link>
                    </span>
                );
            },
        },
    ]);
    return (
        <div className="container-fluid">
            <NotificationContainer />
            <CardWrapperComponent header={true} card_title={pageTitle}>
                <div>
                    <Link
                        to={`/invitation/create?role_id=${role}&role_name=${role_name}`}
                        className="btn btn-outline-primary mt-3 mb-3"
                    >
                        <i className="bi-plus-square" /> {i18n.t(k.INVITE_USER)}
                    </Link>
                    <DataTable
                        dense
                        noHeader
                        striped
                        progressPending={loading}
                        progressComponent={
                            <div className="p-5">
                                <Wait />
                            </div>
                        }
                        data={users}
                        columns={columns}
                    />
                    <Modal
                        show={showConfirmRemoveUser}
                        onHide={handleCloseConfirmRemoveUser}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Warning</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                Do you want to remove user{" "}
                                <b>{userToRemove ? userToRemove.email : ""}</b>{" "}
                                from group <b>{role_name}</b>? The user will not
                                be deleted from the system
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={handleCloseConfirmRemoveUser}
                            >
                                {i18n.t(k.CLOSE)}
                            </Button>
                            <Button
                                variant="danger"
                                onClick={() => deleteUserFromGroup()}
                            >
                                {i18n.t(k.REMOVE)}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </CardWrapperComponent>
        </div>
    );
};

export default MerchantUsersList;
