import React, { useEffect, useState, useContext } from "react";
import i18n from "i18next";
import k from "./../../i18n/keys";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import DeleteForever from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "shards-react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";
import BreadcrumbsComponent from "../breadcrumbs/BreadcrumbsComponent";
import { ASSET_TYPE } from "./../../actions/api/assets/config";
import useSearchString from "./../../utils/useSearchString";
import {
    useHistory,
    useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { deleteAsset } from "./../../actions/api/assets/setAssetData";
import { Modal } from "react-bootstrap";
import { getAssetList } from "./../../actions/api/assets/getAssetList";
import getAsset from "./../../actions/api/assets/getAssets";
import CircularProgress from "@material-ui/core/CircularProgress";
import DescriptionIcon from "@material-ui/icons/Description";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ListIcon from "@material-ui/icons/List";
import { ReactComponent as SafetyVideoIcon } from "../../img/icons/video_icon.svg";
import { APP_ID } from "./../../config";
import {
    openSafetyVideoModal,
    closeSafetyVideoModal,
} from "../../actions/action_utils";
import getAssetTypeList from "./../../actions/api/assetTypes/getAssetTypeList";
import GroupableDatatable from "../directories/GroupableDatatable";
import getActionButtonsForType from "./helpers/getActionButtonsForType";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import SensorListModal from "../sensors/SensorListModal";

import {
    addBreadcrumb,
    removeBreadcrumb,
    setBreadcrumbs,
    gotoBreadcrumb,
} from "../../actions/directory";

const Circular = () => (
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

const DirectoryView = () => {
    const { parent } = useParams();
    const dispatch = useDispatch();
    const directory = useSelector((state) => state.directory);
    const history = useHistory();
    const [loading, setLoading] = useState({ parent: false, types: false });
    // const [assetList, setAssetList] = useState([]);
    const [typesList, setTypesList] = useState([]);
    const [parentInfo, setParentInfo] = useState(null);
    const [assetToRemove, setAssetToRemove] = useState();
    const [showConfirmRemoveAsset, setShowConfirmRemoveAsset] = useState(false);
    const handleShowConfirmRemoveAsset = () => setShowConfirmRemoveAsset(true);
    const handleCloseConfirmRemoveAsset = () =>
        setShowConfirmRemoveAsset(false);

    const [showConfirmLinkAssets, setShowConfirmLinkAssets] = useState(false);
    const handleShowConfirmLinkAssets = () => setShowConfirmLinkAssets(true);
    const handleCloseConfirmLinkAssets = () => {
        setShowConfirmLinkAssets(false);
    };

    // Use to update GroupableDatatable component data
    const [forceUpdate, setForceUpdate] = useState(0);

    useEffect(() => {
        if (parent) fetchParent();
        fetchChildrenTypes();
    }, [parent]);

    const onDelete = () => {
        handleCloseConfirmRemoveAsset();

        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                NotificationManager.success("Successfully removed");
            } else {
                NotificationManager.error("Error");
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        deleteAsset(assetToRemove._id, errorHandler)
            .then((data) => {
                return resultHandler(data);
            })
            .catch(console.error);
    };

    const fetchChildrenTypes = async () => {
        setLoading({ ...loading, types: true });
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                setTypesList(data.asset_types);
                setLoading({ ...loading, types: false });
            } else {
                NotificationManager.error("Error");
                setTypesList();
                setLoading({ ...loading, types: false });
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setTypesList();
        };
        getAssetTypeList(
            {
                query_application: APP_ID,
            },
            errorHandler
        )
            .then((data) => {
                return resultHandler(data);
            })
            .catch((error) => {
                console.log("getAssetTypeList: modal: error", error);
            });
    };

    const fetchParent = async () => {
        setLoading({ ...loading, parent: true });
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                setParentInfo(data.asset);
                // setLoading({ ...loading, parent: false });
            } else {
                NotificationManager.error("Error");
                // setLoading({ ...loading, parent: false });
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setParentInfo();
        };
        if (parent) {
            getAsset({ id: parent }, errorHandler)
                .then((data) => {
                    return resultHandler(data);
                })
                .catch((err) => {
                    console.log("fetchParent error", err);
                });
        } else {
            setParentInfo();
        }
    };

    const showConfirmRemoveDialog = (asset) => {
        setAssetToRemove(asset);
        handleShowConfirmRemoveAsset();
    };

    const handleAttachSafetyVideo = (selected_asset) => {
        const asset = typesList.filter((itm) => itm.name === "safety_video")[0];

        const data = {
            // ...asset,
            type: ASSET_TYPE.BUILDING,
            description: asset.description,
            name: asset.name,
            properties: { ...asset.properties },
            parent: selected_asset._id,
        };

        dispatch(openSafetyVideoModal(data));
    };

    const getParents = () => {
        let parentsList = [];
        if (parentInfo) {
            parentsList = [
                {
                    name: i18n.t(k.PROPERTIES),
                    linkTo: "/landplots",
                },
                {
                    name: parentInfo?.name,
                    linkTo: null,
                },
            ];
        }

        if (directory?.breadcrumbs?.length) {
            let breadcrumbsList = directory.breadcrumbs.map((item) => ({
                _id: item._id,
                name: item.name,
                linkTo: `/directory/${item._id}`,
            }));

            breadcrumbsList = [
                {
                    name: i18n.t(k.PROPERTIES),
                    linkTo: "/landplots",
                },
                ...breadcrumbsList,
            ];
            return breadcrumbsList;
        }
        return parentsList;
    };

    const getBreadcrumbs = () => {
        if (loading.parent) {
            return <Circular />;
        } else {
            return <BreadcrumbsComponent parents={getParents()} />;
        }
    };

    const createLandplot = () => {
        history.push(`/assets/create?type=${ASSET_TYPE.LANDPLOT}`);
    };

    const createPlot = () => {
        if (parent) {
            history.push(
                `/assets/create?type=${ASSET_TYPE.PLOT}&parent=${parent}`
            );
        } else {
            NotificationManager.error("Landplot is not defined");
        }
    };

    const createSafetyTrack = () => {
        NotificationManager.error(
            "TODO: Create safety track feature is not implemented yet..."
        );
    };

    const createBuilding = () => {
        if (parent) {
            history.push(
                `/assets/create?type=${ASSET_TYPE.BUILDING}&parent=${parent}`
            );
        } else {
            NotificationManager.error("Landplot is not defined");
        }
    };

    const createParkingPlace = () => {
        if (parent) {
            history.push(`/newParkingPlace/new/${parent}`);
        } else {
            NotificationManager.error("Landplot is not defined");
        }
    };

    const createFloorPlan = () => {
        history.push(
            `/assets/create?type=${ASSET_TYPE.FLOORPLAN}&parent=${parent}`
        );
    };

    const createRoom = () => {
        if (parent) {
            history.push(
                `/assets/create?type=${ASSET_TYPE.ROOM}&parent=${parent}`
            );
        }
    };

    const addVideoToParent = () => {
        const asset = typesList.filter((itm) => itm.name === "safety_video")[0];

        const data = {
            // ...asset,
            type: asset.name,
            description: asset.description,
            name: asset.name,
            properties: { ...asset.properties },
            parent: parent,
        };

        dispatch(openSafetyVideoModal(data));
    };

    const addSensorToParent = () => {
        handleShowConfirmLinkAssets();
    };

    const onAssetsLinked = () => {
        setForceUpdate(forceUpdate + 1);
        handleCloseConfirmLinkAssets();
    };

    const onRowClicked = (e) => {
        dispatch(addBreadcrumb(e));
        history.push(`/directory/${e._id}`);
    };

    return (
        <div>
            <div className="container-fluid">
                <PageHeaderComponent
                    title={
                        parentInfo?.type
                            ? capitalizeFirstLetter(parentInfo.type)
                            : "Directory"
                    }
                />
            </div>
            <CardWrapperComponent
                header={true}
                footer={true}
                card_title={getBreadcrumbs()}
            >
                {/* 1. Action buttons */}
                {parentInfo ? (
                    getActionButtonsForType(parentInfo?.type, {
                        createLandplot,
                        createSafetyTrack,
                        createPlot,
                        createBuilding,
                        createParkingPlace,
                        createFloorPlan,
                        createRoom,
                        addVideoToParent,
                        addSensorToParent,
                    })
                ) : (
                    <Circular />
                )}
                {/* 1. End Action buttons */}

                {/* 2. The parent's children grouped by type */}
                <GroupableDatatable
                    parent={parent}
                    onRowClicked={onRowClicked}
                    forceUpdate={forceUpdate}
                />
                {/* 2. End children tables */}

                {/* 3. Modal windows */}
                <Modal
                    show={showConfirmRemoveAsset}
                    onHide={handleCloseConfirmRemoveAsset}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Warning</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Do you want to remove plot?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleCloseConfirmRemoveAsset}
                        >
                            Close
                        </Button>
                        <Button variant="danger" onClick={() => onDelete()}>
                            Remove
                        </Button>
                    </Modal.Footer>
                </Modal>

                {showConfirmLinkAssets && (
                    <SensorListModal
                        show={showConfirmLinkAssets}
                        onHide={() => handleCloseConfirmLinkAssets()}
                        parent={parent}
                        // type={type}
                        type={parentInfo.type}
                        // property_type={property_type}
                        property_type={parentInfo.type}
                        onAssetsLinked={onAssetsLinked}
                    />
                )}
                {/* 3. End Modal windows */}
            </CardWrapperComponent>
        </div>
    );
};

export default DirectoryView;
