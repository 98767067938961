import i18n from 'i18next'; import k from "./../../i18n/keys"; import React, { Component } from 'react';
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataTable from "react-data-table-component";
import 'react-confirm-alert/src/react-confirm-alert.css';
import HonkioAPI from "../../middlewares/HonkioAPI";
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";
import CenteredPageLoader from "../ui_utils/CenteredPageLoader";
import SecureImage from "../ui_utils/SecureImage"
// import honkio_logo from '../../image/honkio_logo.png'
import { ASSET_TYPE } from './../../actions/api/assets/config';

export default class BuildingListOneComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingAreas: true,
      loadingAverageMaturity: true,
      building: {
        properties: {}
      },
      areas: [],
      avg_maturity: i18n.t(k.LOADING1)
    };
    this.loadBuilding = this.loadBuilding.bind(this)
    this.loadAreas = this.loadAreas.bind(this)
    this.loadAverageMaturity = this.loadAverageMaturity.bind(this)
  }

  componentDidMount() {
    this.loadBuilding();
  }

  loadBuilding() {
    let that = this;
    let buildingid = this.props.match.params.buildingId;
    if (buildingid) {
      let merchantgetbuildings = async function () {
        let responseBuildings = await HonkioAPI().userShop.userFetch('merchantassetlist', {
          merchant_id: localStorage.getItem('merchant_id'),
          "query_id": buildingid
        }, error => {
          that.setState({
            building: {
              properties: {},
              loading: false
            },
            total: 0,
          });
        })
        if (responseBuildings?.status === 'accept' && responseBuildings.assets && responseBuildings.assets.length > 0) {
          let building = responseBuildings.assets[0];
          that.setState({
            building: building,
            loading: false
          });
          that.loadAreas();
          that.loadAverageMaturity()
        } else {
          that.setState({
            building: {
              properties: {},
              loading: false
            },
          })
        }
      }
      merchantgetbuildings();
    }
  }

  loadAverageMaturity() {
    let that = this;
    let buildingid = this.props.match.params.buildingId;
    if (buildingid) {
      let merchantaveragematurity = async function () {
        let response = await HonkioAPI().userShop.userFetch('merchantaveragematurity',
          { building_id: buildingid }, error => {
            that.setState({
              avg_maturity: null,
              loadingAverageMaturity: false
            });
          })
        if (response?.status === 'accept') {
          let avg_maturity = response.avg_maturity;
          that.setState({
            avg_maturity: avg_maturity,
            loadingAverageMaturity: false
          });
        } else {
          that.setState({
            avg_maturity: null,
            loadingAverageMaturity: false
          });
        }
      };
      merchantaveragematurity();
    }
  }

  loadAreas() {
    let that = this;
    let buildingid = this.props.match.params.buildingId;
    if (buildingid) {
      let merchantgetareas = async function () {
        let responseAreas = await HonkioAPI().userShop.userFetch('merchantassetlist', {
          merchant_id: localStorage.getItem('merchant_id'),
          query_parent: buildingid,
          query_type: ASSET_TYPE.AREA,
          query_child_levels: 3
        }, error => {
          that.setState({
            loadingAreas: false,
            areas: [],
          });
        })
        if (responseAreas?.status === 'accept' && responseAreas.assets) {
          var areas = responseAreas.assets.map(function (item) {
            return {
              id: item.id,
              name: item.name,
              total_area: item.properties?.rules?.total_area ?? 0,
              type: item.properties?.rules?.type ?? '-',
              timeslot: item.properties?.rules?.timeslot ?? '-',
              owner_name: item.properties?.rules?.owner_name ?? '-',
              owner_email: item.properties?.rules?.owner_email ?? '-',
            }
          })
          that.setState({
            areas: areas,
            loadingAreas: false
          })
        } else {
          that.setState({
            areas: [],
            loadingAreas: false
          })
        }
      };
      merchantgetareas();
    }
  }

  getColumns() {
    let that = this;
    return [
      {
        name: i18n.t(k.TITLE),
        selector: 'name',
        sortable: true
      },
      {
        name: i18n.t(k.TOTAL_AREA2),
        selector: 'total_area',
        sortable: true
      },
      {
        name: i18n.t(k.AREA_TYPE),
        selector: 'type',
        sortable: true
      },
      {
        name: i18n.t(k.TIME_SLOT1),
        selector: 'timeslot',
        sortable: true
      },
      {
        name: i18n.t(k.OWNER_NAME),
        selector: 'owner_name',
        sortable: true
      },
      {
        name: i18n.t(k.OWNER_EMAIL),
        selector: 'owner_email',
        sortable: true
      }];
  }
  isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  thumbnailUrl(string) {
    if (this.isValidHttpUrl(string?.thumbUrl)) return string?.thumbUrl
    if (this.isValidHttpUrl(string?.thumbUrl)) return string?.url
    return "honkio_logo"
  }

  render() {
    let that = this;
    if (this.state.loading) {
      return <CenteredPageLoader />;
    }
    return (
      that.state.building && <div>
        <div className="container-fluid">
          <PageHeaderComponent
            title={`${i18n.t(k.BUILDING)}: ${this.state.building.name}`}
            subtitle={i18n.t(k.OVERVIEW)} />
        </div>
        <CardWrapperComponent
          header={true}
          footer={true}
          card_title={`${i18n.t(k.BUILDING)}: ${this.state.building.name}`}>
          <div className="row">
            <div className="list-group col-md-6">
              <li
                className="list-group-item pl-3  ">{i18n.t(k.BUILDING2)}
                <SecureImage src={that.thumbnailUrl(this.state.building.custom_fields)}
                  style={{
                    width: 50,  //its same to '20%' of device width
                    aspectRatio: 1, // <-- this
                    resizeMode: 'contain', //optional
                  }}
                />
              </li>
              <li
                className="list-group-item pl-3">{i18n.t(k.BUILDING3)}
                {this.state.building.name}
              </li>
              <li
                className="list-group-item pl-3">{i18n.t(k.PROPERTY1)}
                {this.state.building.properties.property}
              </li>
              <li
                className="list-group-item pl-3">{i18n.t(k.AVERAGE_MATURITY1)}
                {this.state.avg_maturity ?? '-'}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.BUILDING4)}
                {this.state.building.properties.description}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.PROPERTY_MANAGER1)}
                {this.state.building.properties.property_manager}
              </li>
              <li
                className="list-group-item pl-3">{i18n.t(k.BUILDING5)}
                {this.state.building.properties.address}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.ACTUAL_PARKING_PLACES1)}
                {this.state.building.properties.properties?.actualParkingPlaces}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.BUSINESS_ID1)}
                {this.state.building.properties.business_id}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.BUILDING_RIGHTS)}
                {this.state.building.properties.properties?.buildingRights}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.BUILDING_TYPE)}
                {this.state.building.properties.properties?.buildingType}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.BUILDING_YEAR_OF_GRADUATION1)}
                {this.state.building.properties.properties?.buildingYearOfGraduation}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.CONSTRUCTION_PERIOD1)}
                {this.state.building.properties.properties?.constructionPeriod}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.RENT_LEASE_DATE1)}
                {this.state.building.properties.properties?.rentLeaseDate}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.CONSTRUCTION_REGULATIONS1)}
                {this.state.building.properties.properties?.constructionRegulations}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.MARKET_RENT_PRICE1)}
                {this.state.building.properties.properties?.marketRentPrice}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.IS_LEASED1)}
                {this.state.building.properties.properties?.isLeased}
              </li>
            </div>
            <div className="list-group col-md-6">
              <li
                className="list-group-item pl-3  ">{i18n.t(k.CHARGE_PLACES_FOR_CARS)}
                {this.state.building.properties.properties?.chargePlacesForCars}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.CONSTRUCTION_MATERIAL)}
                {this.state.building.properties.properties?.constructionMaterial}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.COOLING_SYSTEM)}
                {this.state.building.properties.properties?.coolingSystem}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.DATA_CABELS)}
                {this.state.building.properties.properties?.dataCabels}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.VENTILATION_SYSTEM)}
                {this.state.building.properties.properties?.ventilationSystem}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.ELECTRIC_CONNECTORS_TO_CARS)}
                {this.state.building.properties.properties?.electricConnectorsToCars}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.ELECTRICITY_CONSUMPTION)}
                {this.state.building.properties.properties?.electricityConsumption}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.ELEVATORS)}
                {this.state.building.properties.properties?.elevators}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.HEATING_ENERGY_CONSUMPTION)}
                {this.state.building.properties.properties?.heatingEnergyConsumption}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.HEATING_SYSTEM)}
                {this.state.building.properties.properties?.heatingSystem}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.OWN_OR_LEASED_LAND)}
                {this.state.building.properties.properties?.ownOrLeasedLand}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.SHARES)}
                {this.state.building.properties.properties?.shares}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.PLAN_DEFINED_PARKING_PLACES1)}
                {this.state.building.properties.properties?.planDefinedParkingPlaces}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.ROOF_MATERIAL)}
                {this.state.building.properties.properties?.roofMaterial}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.ROOF_TYPE)}
                {this.state.building.properties.properties?.roofType}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.STAIRWELLS)}
                {this.state.building.properties.properties?.stairwells}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.TOTAL_AREA1)}
                {this.state.building.properties.properties?.totalArea}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.TOTAL_VOLUME)}
                {this.state.building.properties.properties?.totalVolume}
              </li>
              <li
                className="list-group-item pl-3  ">{i18n.t(k.TOTAL_WATER_CONSUMPTION1)}
                {this.state.building.properties.properties?.totalWaterConsumption}
              </li>
            </div>
          </div>
          <h6 htmlFor="name" className="text-uppercase mt-4">{i18n.t(k.AREAS_IN_BUILDING)} </h6>
          <DataTable
            autoWidth={true}
            columns={this.getColumns()}
            background="#ffffff00"
            data={this.state.areas}
            noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
            paginationComponentOptions={
              {
                rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                rangeSeparatorText: i18n.t(k.ROWS_OF),
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: i18n.t(k.ROWS_ALL)
              }
            }
            progressPending={this.state.loadingAreas}
            onRowClicked={this.rowSelected} />
        </CardWrapperComponent>
      </div>);
  }
}
