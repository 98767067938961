import i18n from "i18next";
import k from "../../../i18n/keys";
import React from "react";
import CardWrapperComponent from "../../ui_utils/CardWrapperComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteForever from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "shards-react";
import "react-confirm-alert/src/react-confirm-alert.css";
import NotificationManager from "react-notifications/lib/NotificationManager";
import PageHeaderComponent from "../../ui_utils/PageHeaderComponent";
import { useEffect, useState } from "react";
import useSearchString from "../../../utils/useSearchString";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getAssetGroupList } from "../../../actions/api/assets/assetGroup";
import PageableDatatablesComponent from "./../../ui_utils/PageableDatatablesComponent";
import { Link } from "react-router-dom";

const Circular = () => (
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

const AssetGroupList = () => {
    const history = useHistory();
    const [assetGroups, setAssetGroups] = useState();
    const [loading, setLoading] = useState();
    const [total, setTotal] = useState(12);
    const { landplot: landplotId, landplotname } = useSearchString();

    let isMounted = true;
    useEffect(() => {
        loadAssetGroups();
        return () => {
            isMounted = false;
        };
    }, []);

    const loadAssetGroups = (page) => {
        setLoading(true);
        if (!page) {
            page = 0;
        }
        const resultHandler = (data) => {
            setLoading(false);
            if (isMounted) {
                if (data?.status === "accept" && data?.asset_groups) {
                    setAssetGroups(data.asset_groups);
                    setTotal(data.total);
                } else {
                    NotificationManager.error("Error");
                }
            }
        };

        const errorHandler = (error) => {
            setAssetGroups([]);
            setLoading(false);
            NotificationManager.error(error.description, "Error", 4000);
        };
        let params = {
            page,
            query_count: 10,
        };
        if (landplotId) {
            params["query_parent"] = landplotId;
        }
        getAssetGroupList(params, errorHandler).then((data) => {
            return resultHandler(data);
        });
    };

    const confirmDelete = () => {
        NotificationManager.error("Not implemented");
    };
    const getColumns = () => {
        return [
            {
                name: i18n.t(k.NAME),
                selector: "name",
                wrap: true,
                sortable: true,
                grow: 1,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.DESCRIPTION),
                selector: "description",
                sortable: true,
                wrap: true,
                grow: 2,
                style: `cursor: pointer;`,
            },

            {
                name: i18n.t(k.ACTION),
                selector: "action",
                grow: 0,
                cell: function (d) {
                    return (
                        <span>
                            <Link
                                to={`/assetGroup/edit?group=${d._id}`}
                                className="pointer"
                                title={i18n.t(k.EDIT_AREA)}
                            >
                                <EditIcon />
                            </Link>

                            <Link
                                to="#"
                                className="pointer"
                                title={i18n.t(k.AREA_DELETE)}
                                onClick={() => {
                                    confirmDelete(d);
                                }}
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ];
    };
    const openGroupDetailsPage = () => {
        history.push(
            `/assetGroup/create?landplot=${landplotId}&landplotname=${landplotname}`
        );
    };

    return (
        <div>
            <div className="container-fluid">
                <PageHeaderComponent
                    title={`${i18n.t(k.AREAS)} (${landplotname})`}
                />
            </div>
            <CardWrapperComponent footer={true}>
                <Button theme="info" onClick={() => openGroupDetailsPage()}>
                    <AddIcon className="mr-2" />
                    {i18n.t(k.CREATE_AREA)}
                </Button>
                <PageableDatatablesComponent
                    autoWidth={true}
                    columns={getColumns()}
                    background="#ffffff00"
                    items={assetGroups}
                    loadItems={(page) => {
                        loadAssetGroups(page);
                    }}
                    progressPending={loading}
                    total={total}
                    itemsPerPage={10}
                    onRowClicked={(e) =>
                        this.props.history.push(`/plots/${e._id}`)
                    }
                />
            </CardWrapperComponent>
        </div>
    );
};

export default AssetGroupList;
