import React from "react";
import i18n from "i18next";
import k from "../../../i18n/keys";
import { Button } from "shards-react";
import AddIcon from "@material-ui/icons/Add";
import LinkOutlined from "@material-ui/icons/LinkOutlined";
import { NotificationManager } from "react-notifications";

const buttonsKeeper = (type, actions) => {
    // N.B: Here 'property' key etc. symbolizes parent type!
    // merchant is the parent for Property
    const buttonsStorage = {
        merchant: (
            // Displayed on the Properties page
            <>
                <Button
                    className="mr-2"
                    theme="info"
                    onClick={actions.createLandplot}
                >
                    <AddIcon />
                    {i18n.t(k.CREATE_PROPERTY)}
                </Button>
                {/* <Button
                    className="mr-2"
                    theme="info"
                    onClick={actions.createSafetyTrack}
                >
                    <AddIcon />
                    Add safety track
                </Button> */}
            </>
        ),
        property: (
            // Displayed on Plots page
            <>
                <Button
                    theme="info"
                    onClick={actions.createPlot}
                    className="mr-2"
                >
                    <AddIcon />
                    {i18n.t(k.CREATE_LANDPLOT)}
                </Button>
                <Button
                    theme="info"
                    onClick={actions.addVideoToParent}
                    className="mr-2"
                >
                    <AddIcon />
                    Add video
                </Button>
            </>
        ),
        plot: (
            // Disdplayed on Buildings page
            <>
                <Button
                    className="mr-2"
                    theme="info"
                    onClick={actions.createBuilding}
                >
                    <AddIcon className="mr-2" />
                    {i18n.t(k.CREATE_BUILDING)}
                </Button>
                <Button
                    className="mr-2"
                    theme="info"
                    onClick={actions.createParkingPlace}
                >
                    <AddIcon />
                    {i18n.t(k.CREATE_PARKING_PLACE)}
                </Button>
                <Button
                    theme="info"
                    onClick={actions.addSensorToParent}
                    className="mr-2"
                >
                    <AddIcon />
                    Add sensor
                </Button>
                <Button
                    theme="info"
                    onClick={actions.addVideoToParent}
                    className="mr-2"
                >
                    <AddIcon />
                    Add video
                </Button>
            </>
        ),
        building: (
            <>
                <Button
                    className="mr-2"
                    theme="info"
                    onClick={actions.createFloorPlan}
                >
                    <AddIcon />
                    {i18n.t(k.FLOORPLAN_CREATE)}
                </Button>
                <Button
                    className="mr-2"
                    theme="info"
                    onClick={actions.createParkingPlace}
                >
                    <AddIcon />
                    {i18n.t(k.CREATE_PARKING_PLACE)}
                </Button>
                <Button
                    theme="info"
                    onClick={actions.addSensorToParent}
                    className="mr-2"
                >
                    <AddIcon />
                    Add sensor
                </Button>
                <Button
                    theme="info"
                    onClick={actions.addVideoToParent}
                    className="mr-2"
                >
                    <AddIcon />
                    Add video
                </Button>
            </>
        ),
        floor: (
            <>
                <Button
                    className="mr-2"
                    theme="info"
                    onClick={actions.createRoom}
                >
                    <AddIcon />
                    {i18n.t(k.CREATE_ROOM)}
                </Button>
                <Button
                    className="mr-2"
                    theme="info"
                    onClick={actions.createParkingPlace}
                >
                    <AddIcon />
                    {i18n.t(k.CREATE_PARKING_PLACE)}
                </Button>
                <Button
                    theme="info"
                    onClick={actions.addSensorToParent}
                    className="mr-2"
                >
                    <AddIcon />
                    Add sensor
                </Button>
                <Button
                    theme="info"
                    onClick={actions.addVideoToParent}
                    className="mr-2"
                >
                    <AddIcon />
                    Add video
                </Button>
            </>
        ),
        room: (
            <>
                <Button
                    theme="info"
                    onClick={actions.addSensorToParent}
                    className="mr-2"
                >
                    <AddIcon />
                    Add sensor
                </Button>
                <Button
                    theme="info"
                    onClick={actions.addVideoToParent}
                    className="mr-2"
                >
                    <AddIcon />
                    Add video
                </Button>
            </>
        ),
        parking_place: (
            <>
                <Button
                    theme="info"
                    onClick={actions.addSensorToParent}
                    className="mr-2"
                >
                    <AddIcon />
                    Add sensor
                </Button>
                <Button
                    theme="info"
                    onClick={actions.addVideoToParent}
                    className="mr-2"
                >
                    <AddIcon />
                    Add video
                </Button>
            </>
        ),
        safety_video: (
            <>
                <Button
                    theme="info"
                    onClick={actions.addSensorToParent}
                    className="mr-2"
                >
                    <AddIcon />
                    Add sensor
                </Button>
                <Button
                    theme="info"
                    onClick={actions.addVideoToParent}
                    className="mr-2"
                >
                    <AddIcon />
                    Add video
                </Button>
            </>
        ),
        default: (
            <>
                <Button
                    theme="info"
                    onClick={actions.addVideoToParent}
                    className="mr-2"
                >
                    <AddIcon />
                    Add video
                </Button>
            </>
        ),
    };
    return buttonsStorage[type];
};

const defaultActions = {
    createLandplot: () => {
        NotificationManager.error(
            `You need to pass "createLandplot" as field for actions object!`
        );
    },
    createPlot: () => {
        NotificationManager.error(
            `You need to pass "createPlot" as field for actions object!`
        );
    },
    createSafetyTrack: () => {
        NotificationManager.error(
            `You need to pass "createLandplot" as field for actions object!`
        );
    },
    createBuilding: () => {
        NotificationManager.error(
            `You need to pass "createBuilding" as field for actions object!`
        );
    },
    createParkingPlace: () => {
        NotificationManager.error(
            `You need to pass "createParkingPlace" as field for actions object!`
        );
    },
    createFloorPlan: () => {
        NotificationManager.error(
            `You need to pass "createFloorPlan" as field for actions object!`
        );
    },
    createRoom: () => {
        NotificationManager.error(
            `You need to pass "createRoom" as field for actions object!`
        );
    },
    addVideoToParent: () => {
        NotificationManager.error(
            `You need to pass "addVideoToParent" as field for actions object!`
        );
    },
    addSensorToParent: () => {
        NotificationManager.error(
            `You need to pass "addSensorToParent" as field for actions object!`
        );
    },
};

/**
 * type - is actually parent's type. For example if type `property` - render buttons for PlotList page
 */
const getActionButtonsForType = (type, actions = defaultActions) => {
    switch (type) {
        case "merchant":
            return buttonsKeeper("merchant", actions);
        case "property":
            return buttonsKeeper("property", actions);
        case "landplot":
            return buttonsKeeper("landplot", actions);
        case "plot":
            return buttonsKeeper("plot", actions);
        case "building":
            return buttonsKeeper("building", actions);
        case "floor":
            return buttonsKeeper("floor", actions);
        case "room":
            return buttonsKeeper("room", actions);
        case "parking_place":
            return buttonsKeeper("parking_place", actions);
        case "safety_video":
            return buttonsKeeper("safety_video", actions);
        default:
            return buttonsKeeper("default", actions);
    }
};

export default getActionButtonsForType;
