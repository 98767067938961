import i18n from 'i18next';import k from "./../../i18n/keys";import React, { Component } from 'react';
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Button, Form, FormGroup, Modal, ModalBody, ModalHeader } from "shards-react";
import FormInputValidation from "../ui_utils/FormInputValidationComponent";
import { DISRUPTIVE_API_URL } from "../../config";
import Select from "react-select";
import HonkioAPI from "../../middlewares/HonkioAPI";
import CheckIcon from '@material-ui/icons/Check';
import DataTable from "react-data-table-component";
const Circular = () =>
// we need to add some padding to circular progress to keep it from activating our scrollbar
<div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>;


class SensorImportModal extends Component {
  constructor(props) {
    super(props);
    this.onIDChange = this.onIDChange.bind(this);
    this.requestSensor = this.requestSensor.bind(this);
    this.loadBuildings = this.loadBuildings.bind(this);
    this.doAssign = this.doAssign.bind(this);

    this.state = {
      loading: false,
      type: 'temperature' };


  }

  componentDidMount() {
    this.loadBuildings();
  }

  componentWillReceiveProps(nextProps, nextContext) {
      if (nextProps.visible) {
          this.setState({'building': null})
      }
  }

    requestSensor(id) {
    // let requestInfo = async function () {
    //     let response = await fetch(`${DISRUPTIVE_API_URL}/projects/${DISRUPTIVE_PROJECT_ID}/devices/${id}`
    //     try {
    //         let json = await response.json();
    //         if (json.results.length > 0) {
    //             let data = json.results[0];
    //             let phone = '';
    //             data.contactDetails.forEach(function (contact) {
    //                 if (contact.value.startsWith('+')) {
    //                     phone = contact.value
    //                 }
    //             })
    //
    //             let address = data.addresses[data.addresses.length - 1];
    //
    //             that.setState({
    //                 'str_name': {value: data.name, valid: 0},
    //                 'str_address1': {value: address.street, valid: 0},
    //                 'str_zip': {value: address.postCode, valid: 0},
    //                 'str_city': {value: address.city, valid: 0},
    //                 'str_telephone': phone
    //             })
    //         }
    //     } catch (e) {
    //         console.error(e)
    //     }
    //
    // }
    // requestInfo()
  }

  onIDChange(e) {
    // if (this.state.typing) {
    //     clearTimeout(this.state.typing);
    // }
    // let that = this;
    this.setState({
      'id': e.target.value
      // 'typing': setTimeout(function () {
      //     that.requestSensor(e.target.value);
      // }, 500)
    });
  }

  // doImport = () => {
  //   let that = this;
  //   that.setState({ 'loading': true });
  //   let loadAsset = async function () {
  //     let response = await HonkioAPI().userShop.userFetch('merchantassetset', {
  //       'group': that.state.type,
  //       'name': that.state.name,
  //       'alternative_id': that.state.id,
  //       'structure': '' });
  //
  //     that.setState({ 'assets': response.assets, 'loading': false });
  //     that.props.onRequestClose();
  //   };
  //   loadAsset();
  // };

  loadBuildings() {
    let that = this;
    let merchantgetbuildings = async function () {
      let response = await HonkioAPI().userShop.userFetch('merchantbuildinglist', { merchant_id: localStorage.getItem('merchant_id') });
      let buildings = response.buildings;
      that.setState({ 'items': buildings, 'loading': false });
      // response.buildings.forEach(element => {
      //   that.getAvgMaturity(element);
      // });
    };
    merchantgetbuildings();
  }


   loadAreas(buildingId) {
    let that = this;
    that.setState({'loading': true, 'building': buildingId});
    let merchantgetbuildings = async function () {
      let response = await HonkioAPI().userShop.userFetch('merchantarealist', { building: buildingId });
      let areas = response.areas;
      that.setState({ 'items': areas, 'loading': false });
      // response.buildings.forEach(element => {
      //   that.getAvgMaturity(element);
      // });
    };
    merchantgetbuildings();
  }

  doAssign(building, area) {
      // alert(e.name)
  let that = this;
  that.setState({'loading': true})
      console.error(that.props.selection)
      // return

      that.props.selection.selectedRows.forEach((asset) => {
          let assign = async function () {
              asset['building'] = building;
              asset['area'] = area.id;
              delete asset['qrid'];
              let response = await HonkioAPI().userShop.userFetch('merchantassetset', {...asset});
          }
         assign();
      })
      // let response = await HonkioAPI().userShop.userFetch('merchantbuildinglist', { merchant_id: localStorage.getItem('merchant_id') });
      // let buildings = response.buildings;
      that.setState({ 'buildings': [], 'loading': false });
      // that.props.onRequestClose()
      // response.buildings.forEach(element => {
      //   that.getAvgMaturity(element);
      // });


  }
getColumns() {
    let that = this;
    return [
    {
      name: i18n.t(k.IMAGE),
      sortable: true,
      style: `cursor: pointer;`,
      width: '20%',
      cell: function (d) {
        // console.log(d.custom_fields);
        if (d.custom_fields && (d.custom_fields.thumbUrl || d.custom_fields.url)) {
          return <img className="mt-1 mb-1" src={d.custom_fields.thumbUrl ? d.custom_fields.thumbUrl : d.custom_fields.url}
          alt="loading..." />;
        } else {
          return <span>{i18n.t(k.NO_IMAGE)}</span>;
        }
      } },

    {
      name: i18n.t(k.NAME),
      selector: 'name',
      sortable: true,
      style: `cursor: pointer;`
    },
    {
      name: i18n.t(k.ACTION),
      selector: 'action',
      cell: function (d) {
          if (that.state.building) {
              return <Button size={'sm'} onClick={() => {that.doAssign(that.state.building, d)}}>Select</Button>
          }
        return <span></span>;
      } }];
  }

  rowClicked = (d) => {
      if (!this.state.building) {
          this.loadAreas(d.id)
      } else {
          this.doAssign(this.state.building, d)
      }
  };

  render() {

    return (
      <Modal open={this.props.visible}
      toggle={this.props.toggle}
      onRequestClose={this.props.onRequestClose}
      className="w-100">
                <ModalHeader>{'Assign sensors to the building'}</ModalHeader>
                <ModalBody style={{ 'padding': 12 }}>
                    <DataTable
                      autoWidth={true}
                      columns={this.getColumns()}
                      background="#ffffff00"
                      data={this.state.items}
                      noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                      paginationComponentOptions={
                          { rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                            rangeSeparatorText: i18n.t(k.ROWS_OF),
                            noRowsPerPage: false,
                            selectAllRowsItem: false,
                            selectAllRowsItemText: i18n.t(k.ROWS_ALL) }
                        }
                      progressPending={this.state.loading}
                      progressComponent={<Circular />}
                      onRowClicked={this.rowClicked} />
                </ModalBody>
            </Modal>);

  }}


function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = dispatch => ({});


export default connect(mapStateToProps, mapDispatchToProps)(SensorImportModal);
