import { ROLE_ID } from "../../../config";
import HonkioAPI from "./../../../middlewares/HonkioAPI";

const setAssetData = async function (options, errorHandler) {
    return HonkioAPI().mainShop.userFetch(
        "assetset",
        {
            ...options,
        },
        errorHandler
    );
};

const updateAssetData = (
    asset,
    merchantId = null,
    appid = null,
    property_type = "",
    errorHandler = () => {}
) => {
    return setAssetData(
        {
            ...asset,
            merchant: merchantId,
            application: appid,
            role: ROLE_ID,
        },
        errorHandler
    );
};

const deleteAsset = (id, errorHandler) => {
    return setAssetData(
        {
            delete: true,
            id,
        },
        errorHandler
    );
};

export { updateAssetData, deleteAsset };
