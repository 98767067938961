import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { Component } from "react";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import "react-confirm-alert/src/react-confirm-alert.css";
import NotificationManager from "react-notifications/lib/NotificationManager";
import HonkioAPI from "../../middlewares/HonkioAPI";

import CenteredPageLoader from "../ui_utils/CenteredPageLoader";

import moment from "moment";
import "moment/locale/fi";
import { Button, Form, FormInput, FormGroup } from "shards-react";
import FieldInputComponent from "./subcomponents/FieldInputComponent";
import DataTable from "react-data-table-component";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

const fields = [];
const context = require.context("./json/", true, /.json$/);
const all = {};
context.keys().forEach((key) => {
    const fileName = key.replace("./", "");
    const resource = require(`./json/${fileName}`);
    const namespace = fileName.replace(".json", "");
    fields[namespace] = JSON.parse(JSON.stringify(resource));
});

const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

class NetAssetDataInputComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: {
                data: {
                    macro_assumptions: {},
                },
            },

            validation: {},
            column_data: [
                {
                    name: "Change of market rents (%)",
                },
                {
                    name: "Inflation (%)",
                },
                {
                    name: "Change of expenses (%)",
                },
            ],
        };

        this.loadData = this.loadData.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
    }

    handleValidation(id, valid) {
        this.setState({
            validation: { [id]: valid },
        });
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        let that = this;
        that.setState({ data: this.props.data, loading: false });
        that.getInputTableData(that.props.data);
    }

    onSaveClick() {
        let that = this;
        that.setState({ loading: true });
        let landplotId = this.props.landplotId;
        let saveData = async function () {
            let response = await HonkioAPI().userShop.userFetch(
                "merchantevaluationset",
                {
                    ...that.state.data,
                    object: landplotId,
                    object_type: "landplot",
                }
            );
            NotificationManager.success(i18n.t(k.DATA_SAVED));
            that.loadData();
        };
        saveData();
    }

    onChange = (e) => {
        let datadata = this.state.data.data ? this.state.data.data : {};
        let newState = {
            data: {
                ...this.state.data,
                data: {
                    ...datadata,
                    [e.target.id]: {
                        ...datadata[e.target.id],
                        value: e.target.value,
                    },
                },
            },
        };
        this.setState(newState);
        if (this.props.onJLLChange) {
            this.props.onJLLChange(newState.data.data);
        }
    };
    onCommentChange = (e) => {
        let fieldName = e.target.id.replace("comment_", "");
        this.setState({
            data: {
                ...this.state.data,
                data: {
                    ...this.state.data.data,
                    [fieldName]: {
                        ...this.state.data.data[fieldName],
                        comment: e.target.value,
                    },
                },
            },
        });
    };

    onTableDataChange = (e) => {
        let [id, year] = e.target.id.split("__");
        let macro = this.state.data.data.macro_assumptions;
        macro[id][year] = e.target.value;
        let data = {
            ...this.state.data,
            data: { ...this.state.data.data, ["macro_assumptions"]: macro },
        };
        this.setState({
            data: data,
        });
    };

    getInputTableData = (input_data) => {
        let data = input_data ? input_data : this.state.data;
        if (!data.data) {
            data.data = {
                macro_assumptions: {
                    market_change: [],
                    inflation: [],
                    cost_change: [],
                },
            };
        }

        let columnData = [
            {
                name: "Change of market rents (%)",
            },
            {
                name: "Inflation (%)",
            },
            {
                name: "Change of expenses (%)",
            },
        ];
        let years = 0;
        if (data && data.data) {
            if (data.data["Mallin pituus"]) {
                years = parseInt(data.data["Mallin pituus"].value);
            }
        }
        for (var a in data.data.macro_assumptions) {
            if (data.data.macro_assumptions[a].length < years) {
                for (
                    var i = data.data.macro_assumptions[a].length;
                    i <= years;
                    i++
                ) {
                    data.data.macro_assumptions[a].push("0");
                }
            }
        }

        columnData[0][`analysis_day`] = (
            <FormInput
                id={`market_change__0`}
                value={`${data.data.macro_assumptions.market_change[0]}`}
                onChange={(e) => this.onTableDataChange(e)}
            />
        );
        columnData[1][`analysis_day`] = (
            <FormInput
                id={`inflation__0`}
                value={`${data.data.macro_assumptions.inflation[0]}`}
                onChange={(e) => this.onTableDataChange(e)}
            />
        );
        columnData[2][`analysis_day`] = (
            <FormInput
                id={`cost_change__0`}
                value={`${data.data.macro_assumptions.cost_change[0]}`}
                onChange={(e) => this.onTableDataChange(e)}
            />
        );

        for (var i = 0; i < years; i++) {
            columnData[0][`year${i + 1}`] = (
                <FormInput
                    id={`market_change__${i + 1}`}
                    value={`${
                        data.data.macro_assumptions.market_change[i + 1]
                    }`}
                    onChange={(e) => this.onTableDataChange(e)}
                />
            );
            columnData[1][`year${i + 1}`] = (
                <FormInput
                    id={`inflation__${i + 1}`}
                    value={`${data.data.macro_assumptions.inflation[i + 1]}`}
                    onChange={(e) => this.onTableDataChange(e)}
                />
            );
            columnData[2][`year${i + 1}`] = (
                <FormInput
                    id={`cost_change__${i + 1}`}
                    value={`${data.data.macro_assumptions.cost_change[i + 1]}`}
                    onChange={(e) => this.onTableDataChange(e)}
                />
            );
        }
        return columnData;
    };

    getColumns = () => {
        let data = this.state.data;
        let columns = [
            {
                name: "",
                selector: "name",
            },
            {
                name: "Analysis day",
                selector: "analysis_day",
            },
        ];

        if (!data.data) {
            return columns;
        }

        let years = 0;
        if (this.state.data && this.state.data.data) {
            if (this.state.data.data["Mallin pituus"]) {
                years = parseInt(data.data["Mallin pituus"].value);
            }
        }
        // fill a list of dicts with a column with names, a column with analysis day inputs (onTableDataChange(e, 0))

        // then make a cycle to fill all three dicts with yearly inputs
        // onTableChange must make three lists: for each of values, where 0 will be the analysis day and 1-n - yearly stats

        for (var i = 0; i < years; i++) {
            columns.push({
                name: `Year ${i + 1}`,
                selector: `year${i + 1}`,
            });
        }

        return columns;
    };

    render() {
        let saveButton = (
            <Button
                onClick={() => {
                    this.props.onSaveClick();
                }}
            >
                {i18n.t(k.SAVE)}
            </Button>
        );
        if (this.state.loading) {
            return <CenteredPageLoader />;
        }

        return (
            <div>
                <Form>
                    <DataTable
                        autoWidth={true}
                        columns={this.getColumns()}
                        background="#ffffff00"
                        data={this.getInputTableData()}
                        noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                        paginationComponentOptions={{
                            rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                            rangeSeparatorText: i18n.t(k.ROWS_OF),
                            noRowsPerPage: false,
                            selectAllRowsItem: false,
                            selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                        }}
                        progressPending={this.state.loading}
                        progressComponent={<Circular />}
                    />

                    {Object.keys(fields).map((name) => {
                        let title = name.replace(/^\d+_/, "");
                        return (
                            <FieldInputComponent
                                fields={fields[name]}
                                title={title}
                                handleChangeEvent={this.onChange}
                                handleCommentChangeEvent={this.onCommentChange}
                                data={
                                    this.state.data
                                        ? this.state.data
                                        : { data: {} }
                                }
                            />
                        );
                    })}
                </Form>
                {saveButton}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NetAssetDataInputComponent);
