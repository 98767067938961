import React from 'react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

const InputFieldWithValidation = ({
  className,
  name,
  label,
  type,
  defaultValue,
  required,
  additional,
  error,
  disabled,
  hidden,
  min,
  step, 
  max,
  k,
  onLostFocus = () => {},
  value,
  onChange  = () => {}
}) => {
  const { register } = useFormContext();
  const onChangeField = (data) => {
      onChange(name, data.target.value)
  }
  return (
    <div key={k} className={clsx(className, { 'd-none': hidden })}>
      {label && <label htmlFor={name}>{label}</label>}
      <input
        // ref={register}
        type={type}
        className="form-control ml-2"
        id={name}
        name={name}
        defaultValue={defaultValue}
        required={required}
        disabled={disabled}
        min={min}
        step={step}
        max = {max}
        value={value}
        onBlur={onLostFocus}
        onChange={onChangeField}
      />
      {additional && <small className="text-muted">{additional}</small>}
      {error && <div className="invalid-feedback">Error message: $message</div>}
    </div>
  );
};

export default InputFieldWithValidation;
