const initialState = {
  inviteResponse: null,
  isLoaded: false };


function setInviteReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_INVITE_SUCCESS":
      return {
        inviteResponse: action.response,
        isLoaded: true };

    case "SET_INVITE_FAILURE":
      return {
        ...state,
        inviteResponse: null };

    default:
      return state;}

}

export default setInviteReducer;