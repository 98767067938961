/*
Cases:
CONSUMER_INFO_IS_LOADING
CONSUMER_INFO_SUCCESS
CONSUMER_INFO_FAILURE
CONSUMER_INFO_CLEAR
 */

const initialState = {
  err: null,
  isLoading: false,
  isLoaded: false,
  consumer_info: {} };


function ConsumerGetReducer(state = initialState, action) {
  switch (action.type) {
    case "CONSUMER_INFO_IS_LOADING":
      return {
        ...state,
        isLoading: true,
        err: null,
        isLoaded: false,
        consumer_info: {} };

    case "CONSUMER_INFO_SUCCESS":
      return {
        ...state,
        err: null,
        isLoading: false,
        isLoaded: true,
        consumer_info: action.consumer_info };

    case "CONSUMER_INFO_FAILURE":
      return {
        ...state,
        err: action.err,
        isLoading: false,
        isLoaded: false,
        consumer_info: action.err };

    case "CONSUMER_INFO_CLEAR":
      return {
        ...initialState };

    default:
      return state;}

}

export default ConsumerGetReducer;