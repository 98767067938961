import i18n from 'i18next';import k from "../../../i18n/keys";
import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from "shards-react";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class AreaPhotoViewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // if (this.state.loading) {
    //   button = <CircularProgress size={35} />;
    // }
    let image;
    // console.log(this.props)
    if (this.props.file) {
        image = <img src={this.props.file.url} className="preview"/>
    }
    return (
      <Modal open={this.props.visible}
      toggle={this.props.toggle}
      size="lg"
      onRequestClose={this.props.onRequestClose}
      className="w-100">
                {/* <ModalHeader>{i18n.t(k.UPLOAD_PHOTO)}</ModalHeader> */}
                <ModalBody>
                    {image}
                </ModalBody>
            </Modal>);

  }}
