import React, { Component } from "react";
import MainSidebar from "./MainSidebar/MainSidebar";
import { Col, Container, Row } from "shards-react";
import MainFooter from "./MainFooter";
import MainNavbar from "./MainNavbar/MainNavbar";
import { NotificationContainer } from "react-notifications";
import { connect } from "react-redux";
import HonkioAPI from "../middlewares/HonkioAPI";
import VideosListModal from "../components/assets/subcomponents/VideosListModal";
var IBAN = require("iban");

class BaseSideBarLayout extends Component {
    render() {
        let ibanError = true;
        (this.props.merchantInfo.list_bank_account || []).forEach(function (
            iban
        ) {
            if (IBAN.isValid(iban.iban)) {
                ibanError = false;
            }
        });

        return (
            <Container fluid>
                <Row>
                    <MainSidebar />
                    <Col
                        className="main-content p-0"
                        lg={{
                            size: this.props.isSidebarOpen ? 10 : 12,
                            offset: this.props.isSidebarOpen ? 2 : 0,
                        }}
                        md={{
                            size: this.props.isSidebarOpen ? 9 : 12,
                            offset: this.props.isSidebarOpen ? 3 : 0,
                        }}
                        sm="12"
                        tag="main"
                    >
                        <MainNavbar />
                        <div className="">{this.props.children}</div>
                        <MainFooter />
                    </Col>
                </Row>
                <NotificationContainer />

                {/* Modals */}
                <VideosListModal />
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        merchantInfo: (state.merchantInfo || {}).merchantInfo || {},
        isSidebarOpen: state.navbar.isSidebarOpen,
    };
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BaseSideBarLayout);
