import React, { useState } from "react";
import { NotificationContainer } from "react-notifications";
import SmartForm from "./../form/SmartForm";
import InputFieldWithValidation from "./../form/elements/InputFieldWithValidation";
// import CheckboxSwitch from './../form/elements/CheckboxSwitch';
import ButtonActionForm from "./../form/elements/ButtonActionForm";
import AssetDinamicProperties from "./subcomponents/AssetDinamicProperties";

const AssetEditForm = ({ defaultValues, onSubmit, assetType }) => {
    const [assetInfo, setAssetInfo] = useState(defaultValues);
    const [streetUrl, setStreetUrl] = useState();
    const [coordinates, setCoordinates] = useState();
    const onChangeField = (name, value) => {
        setAssetInfo({
            ...assetInfo,
            [name]: value,
        });
    };

    const onSubmitClicked = async (data) => {
        onDataSave();
    };

    const onChangeProperties = async (value) => {
        const newAssetInfo = {
            ...assetInfo,
            properties: {
                ...value,
            },
        };
        setAssetInfo(newAssetInfo);
    };

    const onChangeCoordinates = async (value) => {
        setCoordinates(value);
    };

    const onChangeStreenUrl = async (data) => {
        setStreetUrl(data);
    };

    const onDataSave = () => {
        //in case asset type was renamed on server side, update type property
        const newAssetInfo = {
            ...assetInfo,
            coordinates: coordinates,
            type: assetType.name,
        };
        // console.log(newAssetInfo, streetUrl)
        // debugger
        onSubmit(newAssetInfo, streetUrl);
    };
    // console.log(assetInfo,"-------------assetInfo");
    // console.log(assetType,"-------------assetType");

    // TODO: This component uses AssetDinamicProperties component which returns SmartForm itself
    // What leads to `form cannot be descendant of form` error - replace AssetDinamicProperties with
    // Separate component for mapping input fields depending on type
    // SECONDARY: Replace this logic with new component in AssetDinamicProperties component
    return (
        <div>
            <NotificationContainer />
            <SmartForm
                onSubmit={onSubmitClicked}
                defaultValues={assetInfo?.properties ? assetInfo : assetType}
            >
                <div className="col">
                    <InputFieldWithValidation
                        onChange={onChangeField}
                        defaultValue={
                            assetInfo.name ? assetInfo.name : assetInfo.type
                        }
                        className="mb-3"
                        name="name"
                        label="Name"
                        type="text"
                        k="name"
                    />
                    {/* <CheckboxSwitch
                    className='ml-5'
                    name="visible"
                    defaultValue={assetInfo.visible? assetInfo.visible : assetType.visible}
                    onChange={onChangeField}
                    label="Visible" /> */}
                    <InputFieldWithValidation
                        className="mb-3"
                        name="description"
                        label="Description"
                        type="text"
                        k="description"
                        required={true}
                        defaultValue={
                            assetInfo.description
                                ? assetInfo.description
                                : assetType.description
                        }
                        onChange={onChangeField}
                    />
                    {/* eslint-disable-next-line */}
                    <AssetDinamicProperties
                        latitude={
                            assetInfo?.coordinates
                                ? assetInfo?.coordinates[0]
                                : undefined
                        }
                        longitude={
                            assetInfo?.coordinates
                                ? assetInfo?.coordinates[1]
                                : undefined
                        }
                        type={assetType}
                        properties={assetInfo.properties}
                        onChangeProperties={onChangeProperties}
                        onChangeCoordinates={onChangeCoordinates}
                        onChangeStreenUrl={onChangeStreenUrl}
                    />
                </div>
                <ButtonActionForm
                    label="Save changes"
                    name="active"
                    value={true}
                    className="btn btn-primary btn-lg btn-block m-3"
                    type="submit"
                />
            </SmartForm>
        </div>
    );
};

export default AssetEditForm;
