import {
    SET_BUILDING_ASSETS_TOTAL,
    SET_BUILDING_ASSET_LIST,
    SET_BUILDING_LIST,
    SET_BUILDING_TOTAL,
    SET_LANDPLOT_LIST,
    SET_LANDPLOT_TOTAL,
    SET_PROPERTY_LIST,
    SET_PROPERTY_TOTAL,
    SET_SAFETY_STEP,
    SET_SELECTED_BUILDING,
    SET_SELECTED_BUILDING_ASSETS,
    SET_SELECTED_LANDPLOT,
    SET_SELECTED_PROPERTY,
    SET_SAFETY_IMAGE,
    CLEAR_SAFETY_TRACK,
} from "../../reducers/SafetyTrackReducer";

export const setPropertyList = (properties) => {
    return {
        type: SET_PROPERTY_LIST,
        payload: properties,
    };
};

export const setSelectedProperty = (property) => {
    return {
        type: SET_SELECTED_PROPERTY,
        payload: property,
    };
};

export const setPropertyTotal = (count) => {
    return {
        type: SET_PROPERTY_TOTAL,
        payload: count,
    };
};

export const setLandplotList = (landplots) => {
    return {
        type: SET_LANDPLOT_LIST,
        payload: landplots,
    };
};

export const setSelectedLandplot = (landplot) => {
    return {
        type: SET_SELECTED_LANDPLOT,
        payload: landplot,
    };
};

export const setLandplotTotal = (count) => {
    return {
        type: SET_LANDPLOT_TOTAL,
        payload: count,
    };
};

export const setBuildingList = (buildings) => {
    return {
        type: SET_BUILDING_LIST,
        payload: buildings,
    };
};

export const setSelectedBuilding = (building) => {
    return {
        type: SET_SELECTED_BUILDING,
        payload: building,
    };
};

export const setBuildingTotal = (count) => {
    return {
        type: SET_BUILDING_TOTAL,
        payload: count,
    };
};

export const setBuildingAssetList = (assets) => {
    return {
        type: SET_BUILDING_ASSET_LIST,
        payload: assets,
    };
};

export const setBuildingAssetTotal = (count) => {
    return {
        type: SET_BUILDING_ASSETS_TOTAL,
        payload: count,
    };
};

export const setSelectedBuildingAssets = (assets) => {
    return {
        type: SET_SELECTED_BUILDING_ASSETS,
        payload: assets,
    };
};

/**
 *
 * @param {*} img - set safety track map image
 * @returns
 */
export const setSafetyImage = (img) => {
    return {
        type: SET_SAFETY_IMAGE,
        payload: img,
    };
};

export const setSafetyStep = (number) => {
    return {
        type: SET_SAFETY_STEP,
        payload: number,
    };
};

export const clearSafetyTrack = () => {
    return {
        type: CLEAR_SAFETY_TRACK,
    };
};
