import HonkioAPI from '../../../middlewares/HonkioAPI';

const getMerchantListUserRole = ({ role_id }) => {
  return HonkioAPI().mainShop.userFetch('merchantlistuserroles', {
    query_roles: true,
    roles: [role_id],
  });
};

export default getMerchantListUserRole;
