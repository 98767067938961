import i18n from 'i18next';import k from "./../../i18n/keys";import React, { Component } from 'react';
import { connect } from "react-redux";

import {
Alert,
Button,
Container,
Form,
FormGroup,
FormInput,
FormTextarea,
Modal,
ModalBody,
ModalHeader,
Popover,
PopoverBody,
PopoverHeader,
Row } from
"shards-react";
import { setInvite } from "../../actions/api/invite/setInviteActions";
import HonkioAPI from "../../middlewares/HonkioAPI";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "react-select";
import {CONSUMER_URL} from "../../config";

const Circular = () =>
// we need to add some padding to circular progress to keep it from activating our scrollbar
<div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>;

const QRCode = require('qrcode.react');



class InviteUserModal extends Component {
  constructor(props) {
    super(props);
    this.doInvite = this.doInvite.bind(this);
    this.toggleQR = this.toggleQR.bind(this);
    this.checkUser = this.checkUser.bind(this);
    this.state = {
      'user_type': 'person',
      'showQR': false,
      'email': { value: null, userExists: null, loading: false },
      'firstname': { value: null, valid: 0 },
      'lastname': { value: null, valid: 0 },
      'phone': { value: null, valid: 0 },
      'message': { value: null, valid: 0 },
      'business_id': { value: null, valid: 0 },
    };

  }

  checkUser(email) {
    // let email = this.state.email.value;
    let result = null;
    let that = this;
    // that.setState({'email': {...that.state.email}})
    let requestInfo = async function () {

      result = await HonkioAPI().userShop.userFetch('merchantuserinfo', { 'email': email }, err => {
        that.setState({ 'email': { ...that.state.email, userExists: false, loading: false } });
        return true; // error handled === true
      });
      if (result.status === 'accept') {
        that.setState({ 'email': { ...that.state.email, userExists: true, loading: false } });
      }
      return result;
    };
    requestInfo();
  }

  validateEmail(email) {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
  }

  getCompanyData = (id) => {
    let that = this;
    let INFO_URL = `https://avoindata.prh.fi/bis/v1/${id}`;

    let requestInfo = async function () {
      let response = await fetch(INFO_URL);
      try {
        let json = await response.json();
        if (json.results.length > 0) {
          let data = json.results[0];
          let phone = '';
          data.contactDetails.forEach(function (contact) {
            if (contact.value.startsWith('+')) {
              phone = contact.value;
            }
          });

          let address = data.addresses[data.addresses.length - 1];

          that.setState({
            'firstname': {...that.state.firstname, 'value': data.name},
            'phone': {...that.state.phone, 'value': phone},
          });

        }
      } catch (e) {
        console.error(e);
      }

    };
    requestInfo();
  }

  handleChange(e) {
    let that = this;
    switch (e.target.id) {
      case 'email':
        let email = e.target.value;
        var valid = this.validateEmail(e.target.value);
        if (this.state.email.typing) {
          clearTimeout(this.state.email.typing);
        }
        this.setState({
          'email': {
            valid: email.length > 0 ? valid ? 1 : -1 : 0,
            value: email,
            userExists: null,
            loading: true,
            typing: setTimeout(function () {
              that.checkUser(email);
            }, 800) } });


        // this.checkUser()

        break;
        case 'business_id':
          if (e.target.value.length >= 6) {
            this.getCompanyData(e.target.value);
          }
      default:
        this.setState({
          [e.target.id]: {
            valid: e.target.value.length > 0 ? 1 : -1,
            value: e.target.value } });


        // this.setState({[e.target.id]: e.target.value})
        break;}

  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.inviteUser && nextProps.inviteUser.err) {
    //
    //     this.setState({'inviteUser': {'invite': null}})
    // } else if (nextProps.inviteUser && nextProps.inviteUser.inviteSent === true) {
    //
    //     this.setState({'inviteUser': {'invite': null}})
    //     var a = this.state
    // }
    if (nextProps.email !== undefined) {
      let that = this;
      this.setState({ 'email': { value: nextProps.email, userExists: null, loading: false } });
      let email = nextProps.email ? nextProps.email : "";
      let valid = this.validateEmail(nextProps.email);
      if (this.state.email.typing) {
        clearTimeout(this.state.email.typing);
      }
      this.setState({
        'email': {
          valid: email.length > 0 ? valid ? 1 : -1 : 0,
          value: email,
          userExists: null,
          loading: true,
          typing: setTimeout(function () {
            that.checkUser(email);
          }, 200) } });


    }
    if (nextProps.phone !== undefined) {
      this.setState({ 'phone': { value: nextProps.phone, valid: 0 } });
    }
    if (nextProps.firstname !== undefined) {
      this.setState({ 'firstname': { value: nextProps.firstname, valid: 0 } });
    }
    if (nextProps.lastname !== undefined) {
      this.setState({ 'lastname': { value: nextProps.lastname, valid: 0 } });
    }
    if (nextProps.shareholder_id !== undefined) {
      this.setState({ 'shareholder_id': { value: nextProps.shareholder_id, valid: 1 } });
    }

  }

  doInvite() {
    var userData = {};
    var state = this.state;
    //shareholder_id
    ['firstname', 'lastname', 'phone', 'message'].forEach(function (k) {
      if (state[k].value) {
        userData[k] = state[k].value;
      }
    });
    userData = {
        ...userData,
        consumer_create: true,
        building_id: this.props.buildingId,
        buildings: [this.props.buildingId]
    };
    // console.log("USERDATA");
    // console.log(userData);
    this.setInvite(this.props.role.role_id, this.state.email.value, userData);
    // close modal if accept

  }

  toggleQR() {
    this.setState({ 'showQR': !this.state.showQR });
  }
    setInvite = (role_id, email, userData) => {
      let that = this;
      let getmerchantroles = async function () {
            let roles = await HonkioAPI().userShop.userFetch('getmerchantroles',
      { 'query_id': role_id, 'query_access': 'all' });
              let hasAccess = false;
              if (roles.roles[0].access && roles.roles[0].access.length > 0) {
                hasAccess = true;
              }

              // console.log('roles');
              // console.log(roles);

              var message = {
                "command": "merchantuserinvite",
                "role_id": role_id,
                "email": email,
                "invite_url": `${window.location.origin.toString()}/invite/` };

              if (hasAccess) {
                message['invite_url'] = `${CONSUMER_URL}/register/`;
                message['redirect_url'] = window.location.origin.toString();
              }
              let response = await HonkioAPI().userShop.userFetch('merchantuserinvite',
              { ...message, ...userData },
              message => {
                // setInviteError(message.description);
                return false;
              });
                  if (response.status === "accept" && that.props.onInviteSuccess) {
      that.props.onInviteSuccess();
    }
        };
        getmerchantroles();
    }
  render() {
    var QRCol, userAlert, inviteFields;
    const options_type = [
      {value: 'person', label: i18n.t(k.PERSON1)},
      {value: 'business', label: i18n.t(k.BUSINESS1)}
    ];
    if (this.props.inviteUser && this.props.inviteUser.invite && this.props.inviteUser.invite.invite) {
      var inviteUrl = `${window.location.origin}/invite/${this.props.inviteUser.invite.invite._id}`;
      QRCol = <div>
                <Button id="qr_btn" onClick={this.toggleQR} theme="success" className="m-3">{i18n.t(k.SHOW_QR)}</Button>
                <Popover
        placement="right"
        open={this.state.showQR}
        toggle={this.toggleQR}
        delay={100}
        target="#qr_btn">
                    <PopoverHeader>{i18n.t(k.QR_CODE)}</PopoverHeader>
                    <PopoverBody>
                        <p className="mt-1">{i18n.t(k.ASK_USER_TO_VISIT_HIS_EMAIL_O)}</p>
                        <QRCode value={inviteUrl} size="235" />
                    </PopoverBody>
                </Popover>
            </div>;
    }
    if (this.state.email.userExists !== null && this.state.email.userExists === true) {
      userAlert = <Alert theme="success">
                {i18n.t(k.USER_EXISTS_GROUP_MEMBERSHIP)}
            </Alert>;
    }
    if (this.state.email.userExists !== null && this.state.email.userExists === false) {
      if (this.state.user_type === 'person') {
          inviteFields = <FormGroup>
                    <label htmlFor="firstname">{i18n.t(k.FIRST_NAME)}</label>
                    <FormInput id="firstname" placeholder={i18n.t(k.FIRST_NAME)} value={this.state.firstname.value}
            onChange={e => this.handleChange(e)}
            valid={this.state.firstname.valid && this.state.firstname.valid === 1}
            invalid={this.state.firstname.valid && this.state.firstname.valid === -1} />


                    <label htmlFor="lastname">{i18n.t(k.LAST_NAME)}</label>
                    <FormInput id="lastname" placeholder={i18n.t(k.LAST_NAME)} value={this.state.lastname.value}
            onChange={e => this.handleChange(e)}
            valid={this.state.lastname.valid && this.state.lastname.valid === 1}
            invalid={this.state.lastname.valid && this.state.lastname.valid === -1} />
                    <label htmlFor="phone">{i18n.t(k.PHONE_NUMBER)}</label>
                    <FormInput id="phone" placeholder={i18n.t(k.PHONE_NUMBER)} value={this.state.phone.value}
            onChange={e => this.handleChange(e)} />

                    <label htmlFor="message">{i18n.t(k.MESSAGE_TO_USER)}</label>
                    <FormTextarea id="message" placeholder={i18n.t(k.MESSAGE_TO_USER)}
            value={this.state.message.value} onChange={e => this.handleChange(e)} />
                    {/*onChange={this.handleChange}*/}
                </FormGroup>;
        } else {
                    inviteFields = <FormGroup>
                      <label htmlFor="business_id">{i18n.t(k.BUILDING_MEMBER_BUSINESS_ID)}</label>
                      <FormInput
                        id="business_id"
                        value={this.state.business_id.value}
                        onChange={e => this.handleChange(e)}/>


                    <label htmlFor="firstname">{i18n.t(k.FIRST_NAME)}</label>
                    <FormInput id="firstname" placeholder={i18n.t(k.FIRST_NAME)} value={this.state.firstname.value}
            onChange={e => this.handleChange(e)}
            valid={this.state.firstname.valid && this.state.firstname.valid === 1}
            invalid={this.state.firstname.valid && this.state.firstname.valid === -1} />


                    <label htmlFor="lastname">{i18n.t(k.LAST_NAME)}</label>
                    <FormInput id="lastname" placeholder={i18n.t(k.LAST_NAME)} value={this.state.lastname.value}
            onChange={e => this.handleChange(e)}
            valid={this.state.lastname.valid && this.state.lastname.valid === 1}
            invalid={this.state.lastname.valid && this.state.lastname.valid === -1} />
                    <label htmlFor="phone">{i18n.t(k.PHONE_NUMBER)}</label>
                    <FormInput id="phone" placeholder={i18n.t(k.PHONE_NUMBER)} value={this.state.phone.value}
            onChange={e => this.handleChange(e)} />

                    <label htmlFor="message">{i18n.t(k.MESSAGE_TO_USER)}</label>
                    <FormTextarea id="message" placeholder={i18n.t(k.MESSAGE_TO_USER)}
            value={this.state.message.value} onChange={e => this.handleChange(e)} />
                    {/*onChange={this.handleChange}*/}
                </FormGroup>;
      }
      }
    return (

      <Modal open={this.props.visible}
      toggle={this.props.toggle}
      onRequestClose={this.props.onRequestClose}
      className="w-100">
                <ModalHeader>{i18n.t(k.INVITE_USER_TO)} {this.props.role ? this.props.role.display_name: ''}</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
              <label htmlFor="shareholder_type">{i18n.t(k.BUILDING_MEMBER_TYPE)}</label>
              <Select id="shareholder_type" onChange={e => {
                this.setState({"user_type": e.value});
              }}
                      defaultValue={{value: i18n.t(k.PERSON), label: i18n.t(k.PERSON1)}}
                      options={options_type}>
              </Select>
            </FormGroup>
                        <FormGroup>
                            <label htmlFor="email">{i18n.t(k.EMAIL)}</label>
                            <FormInput id="email" placeholder={i18n.t(k.EMAIL)} type="email" value={this.state.email.value}
              onChange={e => this.handleChange(e)}
              valid={this.state.email.valid && this.state.email.valid === 1}
              invalid={this.state.email.valid && this.state.email.valid === -1}
              // onBlur={this.checkUser}
              />

                        </FormGroup>
                        {userAlert}
                        {/*{inviteFields ? : ''}*/}
                        {inviteFields}

                        <Container>
                            <Row>
                                {this.state.email.loading ? <Circular /> :
                <Button id="invite_btn" className="m-3" onClick={this.doInvite}>{i18n.t(k.INVITE)}
                </Button>}
                                {QRCol}
                            </Row>
                        </Container>
                    </Form>
                </ModalBody>

            </Modal>);


  }}


function mapStateToProps(state) {
  return { ...state, invite: state.inviteResponse ? state.inviteResponse.inviteResponse : null };
}

const mapDispatchToProps = dispatch => ({
  setInvite: (role_id, email, userData) => dispatch(setInvite(role_id, email, userData)) });

export default connect(mapStateToProps, mapDispatchToProps)(InviteUserModal);
