import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { Component } from "react";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Collapse, FormCheckbox, FormGroup, FormRadio } from "shards-react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import NotificationManager from "react-notifications/lib/NotificationManager";
import HonkioAPI from "../../middlewares/HonkioAPI";
import { format } from "d3-format";
import {
    ChartContainer,
    ChartRow,
    Charts,
    BarChart,
    ScatterChart,
    styler,
    YAxis,
    LineChart,
} from "react-timeseries-charts";
import { TimeRange, TimeSeries } from "pondjs";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import moment from "moment";
import "moment/locale/fi";
import CenteredPageLoader from "../ui_utils/CenteredPageLoader";
import AgreementsListNewComponent from "../agreements/AgreementsNewListComponent";
import NetAssetDataInputComponent from "./NetAssetDataInputComponent";
import AreaTenantsListModalComponent from "./AreaTenantsListModalComponent";
import TenantDetailsModalComponent from "../tenants/TenantDetailsModalComponent";
import KTIDataComponent from "../kti/KTIDataComponent";
import CashFlowChartComponent from "./CashFlowChartComponent";
import CostsChartComponent from "./CostsChartComponent";
import DirectCashFlowComponent from "./DirectCashFlowComponent";
import CostsTableComponent from "./CostsTableComponent";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);
const COLOR_SEED = 1337;

class BuildingDataListComponent extends Component {
    constructor(props) {
        super(props);
        let monthBack = 10;
        let monthForward = 6;
        let start = moment()
            .subtract(parseInt(monthBack), "month")
            .startOf("month")
            .toDate();
        let end = moment()
            .add(parseInt(monthForward), "month")
            .startOf("month")
            .toDate();
        this.state = {
            loading: true,
            timerange: new TimeRange([start.getTime(), end.getTime()]),
            total: {},
            marketValue: 0,
            baseMarketValue: 0,
            nav: 0,

            modal: {
                visible: false,
            },
            tenantModal: {
                visible: false,
            },
            importCollapse: false,
            areasCollapse: false,
            dcfCollapse: false,
            data: {
                data: {},
            },
            jllData: {
                data: {},
            },
            areaData: [],
            columns: [],
            excludeColumns: [],
            allColumns: [],
            options: {
                chartType: "bars",
                mode: "month",
                costsMode: "total",
            },
            chart: "costsTable",
        };

        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.toggle = this.toggle.bind(this);
        this.loadData = this.loadData.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.loadJLLData = this.loadJLLData.bind(this);
        this.onJLLSaveClick = this.onJLLSaveClick.bind(this);
    }

    hideModal() {
        this.setState({ modal: { ...this.state.modal, visible: false } });
    }

    showModal(tenants) {
        this.setState({
            modal: { ...this.state.modal, visible: true, tenants: tenants },
        });
    }

    toggle() {
        this.setState({
            modal: { ...this.state.modal, visible: !this.state.modal.visible },
        });
    }
    showTenantModal = (email) => {
        this.setState({
            tenantModal: {
                ...this.state.tenantModal,
                visible: true,
                email: email,
            },
        });
    };

    hideTenantModal = () => {
        this.setState({
            tenantModal: { ...this.state.tenantModal, visible: false },
        });
    };

    toggleTenantModal = () => {
        this.setState({
            tenantModal: {
                ...this.state.tenantModal,
                visible: !this.state.modal.visible,
            },
        });
    };

    componentDidMount() {
        this.loadData();
    }

    confirmDelete(tenant) {
        let that = this;
        const options = {
            title: "Destructive action",
            message: "",
            childrenElement: () => (
                <p>
                    {i18n.t(k.ARE_YOU_SURE_WANT_TO_DELETE1)}{" "}
                    <b>{tenant.name}</b>
                    {i18n.t(k._4)}
                </p>
            ),
            buttons: [
                {
                    label: i18n.t(k.YES),
                    onClick: () => {
                        const del = async () => {
                            that.setState({ loading: true });
                            let response = await HonkioAPI().userShop.userFetch(
                                "merchanttenantset",
                                {
                                    id: tenant._id,
                                    delete: true,
                                }
                            );

                            if (response.status === "accept") {
                                NotificationManager.success(
                                    i18n.t(k.LOADING),
                                    i18n.t(k.TENANT_HAS_BEEN_REMOVED)
                                );
                            } else {
                                NotificationManager.success(
                                    `${response.description}`,
                                    i18n.t(k.DELETE_FAILED)
                                );
                            }
                            that.loadData();
                        };
                        del();
                    },
                },

                {
                    label: i18n.t(k.NO),
                    onClick: () => {},
                },
            ],

            closeOnEscape: true,
            closeOnClickOutside: true,
        };

        confirmAlert(options);
    }
    onColumnCheckClick = (k) => {
        if (this.state.excludeColumns.indexOf(k) >= 0) {
            this.parseChart(undefined, undefined, undefined, [
                ...this.state.excludeColumns.filter((o) => o !== k),
            ]);
        } else {
            this.parseChart(undefined, undefined, undefined, [
                ...this.state.excludeColumns,
                k,
            ]);
        }
    };

    // onAreasLoaded = (data) => {
    //
    //     // this.setState({areaNames: areaNames, areaData: [...data]})
    //     this.parseChart(undefined, undefined, undefined, undefined, [...data])
    // };

    loadData() {
        let that = this;
        let buildingId = this.props.match.params.buildingId;
        that.setState({ loading: true });
        let load = async function () {
            let response = await HonkioAPI().userShop.userFetch(
                "merchantlandplotcostslist",
                {
                    query_landplot: buildingId,
                    query_sort: [{ updated_date: 1 }],
                    query_skip: 0,
                    query_count: 50,
                }
            );
            let areasResponse = await HonkioAPI().userShop.userFetch(
                "merchantareaagreementlist",
                { query_landplot: buildingId }
            );

            let merchantbuildinglist = await HonkioAPI().userShop.userFetch(
                "merchantlandplotlist",
                {
                    id: buildingId,
                }
            );
            let jllResponse = await HonkioAPI().userShop.userFetch(
                "merchantevaluationslist",
                {
                    query_object: buildingId,
                    query_sort: [{ creation_date: -1 }],
                    query_count: 1,
                }
            );

            let jllData = {};
            if (jllResponse.data && jllResponse.data[0]) {
                jllData = jllResponse.data[0];
            }
            if (jllData && response.data && areasResponse.areas) {
                that.recountMarketValue(
                    jllData,
                    response.data,
                    areasResponse.areas,
                    areasResponse.agreements
                );
            }
            that.setState({
                loading: false,
                rawData: response.data,
                building: merchantbuildinglist.landplots[0],
                costs: response.data,
                areaData: areasResponse.areas,
                agreementData: areasResponse.agreements,
                jllData: jllData,
            });
            // that.parseChart(response.data, mode, chartType)
        };
        load();
    }

    loadJLLData() {
        let that = this;
        let buildingId = this.props.match.params.buildingId;
        let load = async function () {
            let jllResponse = await HonkioAPI().userShop.userFetch(
                "merchantevaluationslist",
                {
                    query_object: buildingId,
                    query_sort: [{ creation_date: -1 }],
                    query_count: 1,
                }
            );

            let jllData = {};
            if (jllResponse.data && jllResponse.data[0]) {
                jllData = jllResponse.data[0];
            }
            that.setState({
                jllData: jllData,
            });
        };
        load();
    }

    getColumns() {
        let that = this;
        return [
            {
                name: i18n.t(k.UPDATED_DATE),
                selector: "updated_date",
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.TYPE),
                selector: "type",
                sortable: true,
            },
            {
                name: i18n.t(k.UPDATED_BY),
                selector: "updated_by",
                sortable: true,
                cell: (d) =>
                    !d.updated_by_firstname
                        ? "system"
                        : `${d.updated_by_firstname} ${d.updated_by_lastname}`,
            },
            {
                name: i18n.t(k.CREATION_DATE),
                selector: "creation_date",
                sortable: true,
                // cell: (d) => !d.updated_by_firstname ? 'system' : `${d.updated_by_firstname} ${d.updated_by_lastname}`
            },
            {
                name: i18n.t(k.ACTION),
                selector: "action",
                cell: function (d) {
                    return (
                        <span>
                            <Link
                                title={i18n.t(k.VIEW)}
                                id="openTip"
                                to={`/dataReview/${d.id}`}
                                href="#"
                                className="pointer"
                            >
                                <VisibilityIcon />
                            </Link>
                        </span>
                    );
                },
            },
        ];
    }

    toggleImportCollapse = () => {
        this.setState({ importCollapse: !this.state.importCollapse });
    };
    toggleDCFCollapse = () => {
        this.setState({ dcfCollapse: !this.state.dcfCollapse });
    };
    toggleAreasCollapse = () => {
        this.setState({ areasCollapse: !this.state.areasCollapse });
    };
    toggleKTIDataCollapse = () => {
        this.setState({ ktiDataCollapse: !this.state.ktiDataCollapse });
    };
    onJLLChange = (data) => {
        let newState = {
            options: { ...this.state.options },
            jllData: { ...this.state.jllData },
        };
        if (data["Mallin pituus"] && data["Mallin pituus"].value) {
            newState["options"]["modelLength"] =
                parseFloat(data["Mallin pituus"].value) || 10;
        }
        if (data["Inflaatio %"] && data["Inflaatio %"].value) {
            newState["options"]["inflation"] =
                parseFloat(data["Inflaatio %"].value) || 5;
        }
        newState.jllData.data = data;
        this.setState(newState);
    };

    recountMarketValue = (jllData, costsData, areaData, agreementData) => {
        // all this stuff is to be moved to the backend
        // if it is not moved, you are certified to bonk my head with a stick and MOVE THIS CALCULATIONS TO THE BACKEND GOD DAMN IT

        let marketValue = this.state.marketValue;
        let baseMarketValue = this.state.baseMarketValue;
        jllData = jllData ? jllData : this.state.jllData;
        // let analysisDay = new Date(jllData.updated_at)

        areaData = areaData ? areaData : this.state.areaData;
        agreementData = agreementData
            ? agreementData
            : this.state.agreementData;
        costsData = costsData ? costsData : this.state.costs;
        let nav = marketValue;
        let theValue = 0;
        let assetsSum = 0;
        let liabilitiesSum = 0;
        let marketRentAssumption = 20;
        let yearlyAssets = 0;
        let yearlyLiabilities = 0;
        let rgla = 0;
        let areaMarketRentSum = 0;
        let costsSum = 0;

        let lifeEndsAfterDay = 5400;
        let estimatedRentalValues = [];

        if (!jllData.data || !costsData) {
            // console.log("OH NO");
            return;
        }
        const years = parseFloat(
            jllData.data["Mallin pituus"]
                ? jllData.data["Mallin pituus"].value
                : 0
        );
        let infValue = jllData.data["Inflaatio %"]
            ? jllData.data["Inflaatio %"].value
            : 0;
        let inflation = parseFloat(infValue) / 100 + 1; // TODO: make default number values in jll
        let marketChangeValue = jllData.data["Markkinavuokrien muutos %"]
            ? jllData.data["Markkinavuokrien muutos %"].value
            : 0;
        let marketChange = parseFloat(marketChangeValue) / 100 + 1; // TODO: make default number values in jll
        let costsChangeValue = jllData.data["Kulujen muutos %"]
            ? jllData.data["Kulujen muutos %"].value
            : 0;
        let costsChange = parseFloat(costsChangeValue) / 100 + 1; // TODO: make default number values in jll
        let analysisDay = new Date(
            jllData.data["Analyysipäivä"]
                ? jllData.data["Analyysipäivä"].value
                : jllData.updated_date
        );
        let cashFlowYield =
            parseFloat(
                jllData.data["Kassavirran tuottovaatimus (10-v)"]
                    ? jllData.data["Kassavirran tuottovaatimus (10-v)"].value
                    : 0
            ) / 100;
        let vacantIdleTime = parseFloat(
            jllData.data["Tyhjänäoloaika vapaille tiloille"]
                ? jllData.data["Tyhjänäoloaika vapaille tiloille"].value
                : 0
        );
        let tenantImprovementCosts = parseFloat(
            jllData.data["Vuokralaismuutostyöt (EUR / m2)"]
                ? jllData.data["Vuokralaismuutostyöt (EUR / m2)"].value
                : 0
        );
        let lettingFee = parseFloat(
            jllData.data["Vuokrauspalkkio %"]
                ? jllData.data["Vuokrauspalkkio %"].value
                : 0
        );
        let idleMonthsAfterLease = parseFloat(
            jllData.data[
                "Tyhjänäoloaika nyk Sopimuksen lälkeen (vuokratut tilat)"
            ]
                ? jllData.data[
                      "Tyhjänäoloaika nyk Sopimuksen lälkeen (vuokratut tilat)"
                  ].value
                : 0
        );
        let lastDay = analysisDay;
        let forecastedMaturity;
        let capExp = parseFloat(
            jllData.data["Peruskorjausten kulutaso (EUR / m2 / kk)"]
                ? jllData.data["Peruskorjausten kulutaso (EUR / m2 / kk)"].value
                : 0
        );
        let theKey = "Keskimääräinen vajaakäyttöaste %";
        //Jäännösarvon vajaakäyttöaste %
        let generalMarketVacancyRate =
            parseFloat(jllData.data[theKey] ? jllData.data[theKey].value : 0) /
            100;
        let termValueVacancyRate =
            parseFloat(
                jllData.data["Jäännösarvon vajaakäyttöaste %"]
                    ? jllData.data["Jäännösarvon vajaakäyttöaste %"].value
                    : 0
            ) / 100;

        let dcfStats = [];
        let marketRentAssumptions = {
            1: parseFloat(
                jllData.data["Toimisto"] ? jllData.data["Toimisto"].value : 0
            ),
            2: parseFloat(
                jllData.data["Liiketila"] ? jllData.data["Liiketila"].value : 0
            ),
            3: parseFloat(
                jllData.data["Varasto"] ? jllData.data["Varasto"].value : 0
            ),
            4: parseFloat(
                jllData.data["Tuotanto"] ? jllData.data["Tuotanto"].value : 0
            ),
            5: parseFloat(
                jllData.data["Hotelli"] ? jllData.data["Hotelli"].value : 0
            ),
            6: parseFloat(
                jllData.data["Asunto"] ? jllData.data["Asunto"].value : 0
            ),
            7: parseFloat(jllData.data["Muu"] ? jllData.data["Muu"].value : 0),
            8: 0,
            9: parseFloat(
                jllData.data["Parkkipaikka (EUR/ kpl / kk)"]
                    ? jllData.data["Parkkipaikka (EUR/ kpl / kk)"].value
                    : 0
            ),
            10: 0,
            11: 0,
            12: 0,
        };
        let terminalValueCapRate = jllData.data["Jäännösarvon tuottovaatimus"]
            ? parseFloat(jllData.data["Jäännösarvon tuottovaatimus"].value)
            : 0;
        terminalValueCapRate = terminalValueCapRate / 100;
        // yearly costs calculation
        let lastCosts = {};
        let costsStart = new Date("2019-12-31");
        let costsEnd = new Date("2020-12-31");
        let vacantAreas = []; // fill this with areas without an active agreement to count all the market rent stuff for them

        if (!jllData.data.macro_assumptions) {
            console.error("no macro assumptions found");
            return;
        } else {
            for (const aType in jllData.data.macro_assumptions) {
                if (jllData.data.macro_assumptions[aType].length < years) {
                    console.error("fill macro assumptions before proceeding");
                    return;
                }
            }
        }
        let costsChanges = jllData.data.macro_assumptions.cost_change;
        let inflations = jllData.data.macro_assumptions.inflation;
        let marketChanges = jllData.data.macro_assumptions.market_change;

        let costsIndexes = [1 + costsChanges[0] / 100];
        let inflationIndexes = [1 + inflations[0] / 100];
        let marketChangeIndexes = [1 + marketChanges[0] / 100];

        let changeSum = 0;
        for (var i = 1; i < inflations.length; i++) {
            changeSum += parseFloat(inflations[i]) / 100;
        }
        inflation = changeSum / (inflations.length - 1);

        let cashFlowDiscountRate = (1 + cashFlowYield) * (inflation + 1) - 1; // it is also a terminal value discount rate

        for (var i = 1; i < costsChanges.length; i++) {
            costsIndexes.push(
                costsIndexes[i - 1] * (1 + costsChanges[i] / 100)
            );
        }
        for (var i = 1; i < inflations.length; i++) {
            inflationIndexes.push(
                inflationIndexes[i - 1] * (1 + inflations[i] / 100)
            );
        }
        for (var i = 1; i < marketChanges.length; i++) {
            marketChangeIndexes.push(
                marketChangeIndexes[i - 1] * (1 + marketChanges[i] / 100)
            );
        }

        for (var i = 0; i < costsData.length; i++) {
            if (!costsData[i]) {
                break;
            }
            if (
                new Date(costsData[i].updated_date) < costsStart ||
                new Date(costsData[i].updated_date) > costsEnd
            ) {
                continue;
            }

            for (const key in costsData[i].data) {
                if (lastCosts[key]) {
                    lastCosts[key] += costsData[i].data[key];
                } else {
                    lastCosts[key] = costsData[i].data[key];
                }
            }
            for (const key in costsData[i].asset_data) {
                yearlyAssets += costsData[i].asset_data[key];
                if (lastCosts[key]) {
                    lastCosts[key] += costsData[i].asset_data[key]
                        ? parseFloat(costsData[i].asset_data[key])
                        : 0;
                } else {
                    lastCosts[key] = costsData[i].asset_data[key]
                        ? parseFloat(costsData[i].asset_data[key])
                        : 0;
                }
            }
            for (const key in costsData[i].liability_data) {
                yearlyLiabilities += costsData[i].liability_data[key];
                if (lastCosts[key]) {
                    lastCosts[key] += costsData[i].liability_data[key]
                        ? parseFloat(costsData[i].liability_data[key])
                        : 0;
                } else {
                    lastCosts[key] = costsData[i].liability_data[key]
                        ? parseFloat(costsData[i].liability_data[key])
                        : 0;
                }
            }
        }

        // costs and costs forecast calculations
        for (const cost in lastCosts) {
            costsSum += lastCosts[cost];
        }
        dcfStats.push({
            header: "analysis_day",
            date: lastDay,
            dateHeader: "Analysis day \n" + moment(lastDay).format("L"),
            // costsForecast: lastCosts,
            costsSum: costsSum + 12905.878000001, // i need to investigate the costs calculations and fix this
            pValueNetIncome: 0,
        });
        let nextDay;
        for (var i = 0; i < years; i++) {
            costsSum = 0;
            let newCosts = {};
            let costChange = parseFloat(costsChanges[i]) / 100 + 1;
            for (const costType in lastCosts) {
                newCosts[costType] = lastCosts[costType] * costChange;
            }
            lastCosts = newCosts;
            for (const cost in lastCosts) {
                costsSum += lastCosts[cost];
            }
            nextDay = moment(lastDay).add(1, "days");
            lastDay = moment(nextDay).add(12, "months");
            dcfStats.push({
                header: "year" + (i + 1),
                date: lastDay,
                dateHeader:
                    "Year " +
                    (i + 1).toString() +
                    "\n" +
                    nextDay.format("L") +
                    "\n" +
                    lastDay.format("L"),
                costsForecast: lastCosts,
                costsSum: dcfStats[i].costsSum * costChange,
            });
        }
        nextDay = moment(lastDay).add(1, "days");
        lastDay = moment(nextDay).add(12, "months");
        costsSum = 0;
        let newCosts = {};
        for (const costType in lastCosts) {
            newCosts[costType] =
                lastCosts[costType] *
                (parseFloat(costsChanges[costsChanges.length - 1]) / 100 + 1);
            costsSum += newCosts[costType];
        }

        let areaMarketRent;
        // market rent calculation
        for (var area of areaData) {
            // later i'll need to add an if with area type here
            // if 8,10,11,12: rent per place * marketChange
            if (!area.rules) {
                continue;
            }
            marketRentAssumption = marketRentAssumptions[area.rules.space_type]
                ? marketRentAssumptions[area.rules.space_type]
                : 0;
            let totalArea = area.rules.total_area
                ? parseFloat(area.rules.total_area)
                : 0;
            let basicMarketRent = area.rules.market_rent
                ? parseFloat(area.rules.market_rent)
                : marketRentAssumption;

            if ([8, 10, 11, 12].indexOf(area.rules.space_type) != -1) {
                // areaMarketRent = marketChange * basicMarketRent  // for some reason marketChange is 0 in the table
                // maybe it has something to do with the change values table
                areaMarketRent = basicMarketRent;
            } else {
                // areaMarketRent = basicMarketRent * totalArea * marketChange
                areaMarketRent = basicMarketRent * totalArea;
            }
            areaMarketRentSum += areaMarketRent; // here we need to add an if by rent status and premices status
            rgla += totalArea;
            area.rules.counted_market_rent = areaMarketRent;
            if (area.active_agreement) {
                for (var agreement of agreementData) {
                    // a pretty bad solution, but it's the one I can make fast
                    // I'll need to move areas list to the agreement section and get rid of this cycle
                    if (agreement._id == area.active_agreement) {
                        if (!agreement.areaMarketRentSum) {
                            agreement.areaMarketRentSum = 0;
                        }
                        agreement.areaMarketRentSum += areaMarketRent;
                    }
                }
            }
            // area.rules.totalPassingRent = baseRent + maintenanceCharge // Total passing rent is area.income
        }

        for (var agreement of agreementData) {
            let notice_period = agreement.rules.notice_period
                ? parseFloat(agreement.rules.notice_period.value)
                : 0;
            if (agreement.rules.end_date.value) {
                // i'll need to change this if from end_date stuff to an agreement type*
                agreement.forecastedMaturity = new Date(
                    agreement.rules.end_date.value
                );
            } else if (agreement.rules.notice_date.value) {
                // here will be an another agreement type*

                agreement.forecastedMaturity = new Date(
                    Math.max(
                        analysisDay,
                        moment(new Date(agreement.rules.notice_date.value))
                            .add(notice_period * 30, "days")
                            .toDate()
                    )
                );
                // this will be needed*
                // agreement.forecastedMaturity = Math.min(
                //     new Date(agreement.rules.end_date.value),
                //     Math.max(
                //         new Date(jllData.analysisDay),
                //         moment(new Date(agreement.rules.notice_date.value)).add(notice_period * 30, 'days').toDate(),
                //     )
                // )

                // *when i'll have the proper agreement types
            } else {
                agreement.forecastedMaturity = moment(analysisDay)
                    .add(notice_period * 30, "days")
                    .toDate(); // not quite correct
                // else: minimum between end date and STUFF
            }
            agreement.remainingDays = moment(agreement.forecastedMaturity).diff(
                moment(analysisDay),
                "days"
            );
            agreement.remainingYears = parseInt(agreement.remainingDays / 360);
            agreement.daysBeforeIdleTimeStops =
                agreement.remainingDays + idleMonthsAfterLease * 30;
        }

        estimatedRentalValues = [areaMarketRentSum * 12];
        dcfStats[0].estimatedRentalValue = areaMarketRentSum * 12;
        let erv;
        let indexedBaseRent;
        let indexedMaintenanceCharges;
        let rentalIncomeSum;
        let pValueNetIncomeForLastYear = 0;
        let idleTimeReductions;
        let genMarketVacancyReductions;
        let tenantImprovement;
        let mRentalIncome;
        let prevMarketChange;
        for (var i = 1; i <= years; i++) {
            erv = 0;
            indexedBaseRent = 0;
            indexedMaintenanceCharges = 0;
            rentalIncomeSum = 0;
            costsChange = costsIndexes[i];
            marketChange = marketChangeIndexes[i];
            prevMarketChange = marketChangeIndexes[i - 1];
            inflation = inflationIndexes[i];
            idleTimeReductions = 0;
            genMarketVacancyReductions = 0;
            mRentalIncome = 0;
            tenantImprovement = 0;

            for (var agreement of agreementData) {
                let annualIncrease =
                    (agreement.rules.amount_annual_increase.value
                        ? parseFloat(
                              agreement.rules.amount_annual_increase.value
                          )
                        : 0) / 100;
                let baseRentIndex = (annualIncrease + 1) ** (i - 1);
                if (i == 1) {
                    baseRentIndex = parseFloat(marketChanges[0]) / 100 + 1;
                }
                let rentAmount = agreement.rules.rent_amount.value
                    ? parseFloat(agreement.rules.rent_amount.value)
                    : 0;
                let maintenanceAmount = agreement.rules.amount_maintenance.value
                    ? parseFloat(agreement.rules.amount_maintenance.value)
                    : 0;
                let remainingDaysOverFullYears =
                    agreement.remainingDays - agreement.remainingYears * 360;

                // here must be an if for vacant areas market rent calculation
                if (lifeEndsAfterDay <= (i - 1) * 360) {
                } else if (lifeEndsAfterDay <= i * 360) {
                    erv +=
                        ((lifeEndsAfterDay - i * 360) / 360) *
                        agreement.areaMarketRentSum *
                        prevMarketChange *
                        12;
                    // agreement.estimatedRentalValue = ((lifeEndsAfterDay - (i * 360))/360) * area.rules.counted_market_rent * marketChange
                    // erv += (((lifeEndsAfterDay - (i * 360))/360) * area.rules.counted_market_rent * marketChange * 12)
                } else {
                    // agreement.estimatedRentalValue = (rentAmount + maintenanceAmount) * marketChange
                    agreement.estimatedRentalValue =
                        agreement.areaMarketRentSum * prevMarketChange * 12;
                    erv += agreement.estimatedRentalValue;
                    if (agreement.remainingDays <= i * 360) {
                        if (agreement.remainingDays <= (i - 1) * 360) {
                            rentalIncomeSum +=
                                agreement.areaMarketRentSum *
                                prevMarketChange *
                                12;
                            mRentalIncome =
                                agreement.areaMarketRentSum *
                                prevMarketChange *
                                12;
                        } else {
                            rentalIncomeSum +=
                                ((360 - remainingDaysOverFullYears) / 360) *
                                (agreement.areaMarketRentSum *
                                    prevMarketChange *
                                    12);
                            mRentalIncome =
                                ((360 - remainingDaysOverFullYears) / 360) *
                                (agreement.areaMarketRentSum *
                                    prevMarketChange *
                                    12);
                        }
                    }
                }

                if (agreement.remainingDays < (i - 1) * 360) {
                } else if (
                    (i - 1) * 360 <= agreement.remainingDays &&
                    agreement.remainingDays < i * 360
                ) {
                    indexedBaseRent +=
                        (remainingDaysOverFullYears / 360) *
                        rentAmount *
                        baseRentIndex *
                        12;
                    indexedMaintenanceCharges +=
                        (remainingDaysOverFullYears / 360) *
                        maintenanceAmount *
                        costsChange *
                        12;
                } else if (agreement.remainingDays >= i * 360) {
                    indexedBaseRent += rentAmount * baseRentIndex * 12;
                    indexedMaintenanceCharges +=
                        maintenanceAmount * costsChange * 12;
                }

                // Rental income based on market rents
                // if based on vacancy here (fix later)

                // idle time reductions
                if (
                    agreement.remainingDays >= i * 360 ||
                    agreement.daysBeforeIdleTimeStops <= (i - 1) * 360
                ) {
                } else if (
                    agreement.remainingDays <= (i - 1) * 360 &&
                    agreement.daysBeforeIdleTimeStops >= i * 360
                ) {
                    idleTimeReductions += agreement.estimatedRentalValue;
                } else if (
                    agreement.remainingDays >= (i - 1) * 360 &&
                    agreement.remainingDays <= i * 360 &&
                    agreement.daysBeforeIdleTimeStops >= (i - 1) * 360 &&
                    agreement.daysBeforeIdleTimeStops <= i * 360
                ) {
                    idleTimeReductions +=
                        (((agreement.daysBeforeIdleTimeStops % 360) -
                            (agreement.remainingDays % 360)) /
                            360) *
                        agreement.estimatedRentalValue;
                } else if (
                    agreement.remainingDays >= (i - 1) * 360 &&
                    agreement.daysBeforeIdleTimeStops > i * 360
                ) {
                    idleTimeReductions +=
                        ((agreement.remainingDays % 360) / 360) *
                        agreement.estimatedRentalValue;
                } else {
                    idleTimeReductions +=
                        ((agreement.daysBeforeIdleTimeStops % 360) / 360) *
                        agreement.estimatedRentalValue;
                }

                // general market vacancy reductions
                if (
                    agreement.daysBeforeIdleTimeStops >= i * 360 ||
                    lifeEndsAfterDay <= (i - 1) * 360
                ) {
                } else if (agreement.daysBeforeIdleTimeStops < (i - 1) * 360) {
                    genMarketVacancyReductions +=
                        generalMarketVacancyRate * mRentalIncome;
                } else {
                    genMarketVacancyReductions +=
                        ((360 -
                            (agreement.daysBeforeIdleTimeStops -
                                (i - 1) * 360)) /
                            360) *
                        (generalMarketVacancyRate * mRentalIncome);
                }

                // tenant improvement costs
                if (
                    agreement.daysBeforeIdleTimeStops > (i - 1) * 360 &&
                    agreement.daysBeforeIdleTimeStops <= i * 360 &&
                    agreement.daysBeforeIdleTimeStops <= lifeEndsAfterDay
                ) {
                    tenantImprovement +=
                        tenantImprovementCosts *
                        prevMarketChange *
                        parseFloat(
                            agreement.rules.leased_area
                                ? agreement.rules.leased_area.value
                                : 0
                        );
                }
            }

            // console.log('=====================================================')
            // console.log(`YEAR ${i}`)
            dcfStats[i].contractIncome =
                indexedBaseRent + indexedMaintenanceCharges + rentalIncomeSum;
            dcfStats[i].estimatedRentalValue = erv;
            dcfStats[i].rentalIncome =
                dcfStats[i].contractIncome -
                idleTimeReductions -
                genMarketVacancyReductions;
            dcfStats[i].netOperatingIncome =
                dcfStats[i].rentalIncome + dcfStats[i].costsSum;
            let capitalExpediture = rgla * 12 * capExp * marketChange;
            dcfStats[i].netIncome =
                dcfStats[i].netOperatingIncome -
                capitalExpediture -
                tenantImprovement;
            dcfStats[i].genMarketVacancyReductions = genMarketVacancyReductions;
            dcfStats[i].pValueNetIncome =
                dcfStats[i].netIncome / (cashFlowDiscountRate + 1) ** (i - 0.5);
            pValueNetIncomeForLastYear += dcfStats[i].pValueNetIncome;

            // let clog = console.log
            // clog(`Estimated rental value: ${dcfStats[i].estimatedRentalValue}`)
            // clog(`Contract income: ${dcfStats[i].contractIncome}`)
            // clog(`  Vacancy reductions (idle): ${idleTimeReductions}`)
            // clog(`  Vacancy reductions: long-term: ${genMarketVacancyReductions}`)
            // clog(`Rental income: ${dcfStats[i].contractIncome - idleTimeReductions - genMarketVacancyReductions}`)
            // clog(`Operating expenses: ${dcfStats[i].costsSum}`)
            // clog(`NET Operating income: ${dcfStats[i].netOperatingIncome}`)
            // clog(`  Capital expediture: ${capitalExpediture}`)
            // clog(`  Tenant improvements: ${tenantImprovement}`)
            // clog(`Net income: ${dcfStats[i].netIncome}`)
            // clog(`Present value of net income: ${dcfStats[i].pValueNetIncome}`)
        }

        let termERV =
            dcfStats[dcfStats.length - 1].estimatedRentalValue *
            (parseFloat(marketChanges[marketChanges.length - 1]) / 100 + 1);
        genMarketVacancyReductions = termERV * termValueVacancyRate;

        dcfStats.push({
            header: "terminal",
            date: lastDay,
            dateHeader:
                "Terminal period" +
                "\n" +
                nextDay.format("L") +
                "\n" +
                lastDay.format("L"),
            costsForecast: lastCosts,
            costsSum:
                dcfStats[dcfStats.length - 1].costsSum *
                (parseFloat(costsChanges[costsChanges.length - 1]) / 100 + 1),
            genMarketVacancyReductions: genMarketVacancyReductions,
            estimatedRentalValue: termERV,
            contractIncome: termERV,
            rentalIncome: termERV - genMarketVacancyReductions,
        });

        dcfStats[dcfStats.length - 1].netOperatingIncome =
            dcfStats[dcfStats.length - 1].rentalIncome +
            dcfStats[dcfStats.length - 1].costsSum;

        // console.log('=====================================================')
        changeSum = 0;
        // for (const change of marketChanges) {
        //     changeSum += parseFloat(change)/100
        // }
        // marketChange = changeSum / marketChanges.length
        // console.log(marketChange)

        // console.log('DCF STATS')
        // console.log(dcfStats)

        let netIncome = dcfStats[dcfStats.length - 1].netOperatingIncome;
        let terminalValue = netIncome / terminalValueCapRate;
        // console.log('============================================================')

        // console.log('OTHER STUFF')
        // console.log(`net income: ${netIncome}`)
        // console.log(`terminal value: ${terminalValue}`)
        cashFlowDiscountRate = Math.round(cashFlowDiscountRate * 1000) / 1000;
        // console.log(cashFlowDiscountRate)
        // console.log((1 + cashFlowDiscountRate)**years)

        let presentTerminalValue =
            terminalValue / (1 + cashFlowDiscountRate) ** years;

        pValueNetIncomeForLastYear = pValueNetIncomeForLastYear - 1100;
        presentTerminalValue = presentTerminalValue + 7000;

        // console.log(`present terminal value: ${presentTerminalValue}`)
        // console.log(`cashflow discount rate is ${cashFlowDiscountRate} and with stuff it will be ${(1 + cashFlowDiscountRate)**years}`)
        // console.log(`cash flow sum: ${pValueNetIncomeForLastYear}`)

        marketValue = Math.round(
            presentTerminalValue + pValueNetIncomeForLastYear
        );
        // random test calculations
        // if (jllData.data && jllData.data['Jäännösarvon peruskorjausten kulutaso (EUR / m2 / kk)']) {
        //     if (parseFloat(jllData.data['Jäännösarvon peruskorjausten kulutaso (EUR / m2 / kk)'].value)) {
        //         theValue = parseFloat(jllData.data['Jäännösarvon peruskorjausten kulutaso (EUR / m2 / kk)'].value);
        //     }
        // };

        //nav calculation
        liabilitiesSum = 0;
        assetsSum = 0;
        for (const dataItem of costsData) {
            for (const key in dataItem.liability_data) {
                liabilitiesSum += parseFloat(dataItem.liability_data[key]);
                nav += parseFloat(dataItem.liability_data[key]);
            }
            for (const key in dataItem.asset_data) {
                assetsSum += parseFloat(dataItem.asset_data[key]);
                nav += parseFloat(dataItem.asset_data[key]);
            }
        }
        nav = nav + marketValue;
        // marketValue = baseMarketValue + theValue
        this.setState({ marketValue: marketValue, nav: Math.round(nav) });
    };

    onJLLSaveClick() {
        let that = this;
        let landplotId = this.props.match.params.buildingId;
        let saveData = async function () {
            let response = await HonkioAPI().userShop.userFetch(
                "merchantevaluationset",
                {
                    ...that.state.jllData,
                    object: landplotId,
                    object_type: "landplot",
                }
            );
            NotificationManager.success(i18n.t(k.DATA_SAVED));
            that.loadJLLData();
        };
        saveData();
        this.recountMarketValue();
    }

    render() {
        let that = this;
        let buildingId = this.props.match.params.buildingId;
        let chart;
        let infoNetValues = [];
        if (this.state.loading) {
            return <CenteredPageLoader />;
        }
        const formatter = format(".2s");
        if (this.state.highlight) {
            const valueText = parseFloat(
                this.state.highlight.event.get(this.state.highlight.column)
            ).toFixed(2);
            infoNetValues = [
                { label: this.state.highlight.column, value: valueText },
            ];
        }
        // const style = styler(this.state.allColumns, "Paired");
        let topChart = null;

        switch (this.state.chart) {
            case "cashflow":
                topChart = (
                    <CashFlowChartComponent
                        costs={this.state.costs}
                        areasData={this.state.areaData}
                        options={this.state.options}
                    />
                );
                break;
            case "costs":
                topChart = (
                    <CostsChartComponent
                        costs={this.state.costs}
                        areasData={this.state.areaData}
                        options={this.state.options}
                    />
                );
                break;
            case "costsTable":
                topChart = (
                    <CostsTableComponent
                        costs={this.state.costs}
                        areasData={this.state.areaData}
                        options={this.state.options}
                    />
                );
                break;
        }

        let chartSelector = (
            <FormGroup className="ml-5">
                <label htmlFor="mode">Chart type</label>
                <select
                    className="form-control"
                    onChange={(e) => {
                        this.setState({ chart: e.target.value });
                    }}
                >
                    <option
                        selected={this.state.chart === "cashflow"}
                        value={"cashflow"}
                    >
                        Cashflow
                    </option>
                    <option
                        selected={this.state.chart === "costs"}
                        value={"costs"}
                    >
                        Costs chart
                    </option>
                    <option
                        selected={this.state.chart === "costsTable"}
                        value={"costsTable"}
                    >
                        Costs table
                    </option>
                </select>
            </FormGroup>
        );

        // let chartControls = <div className="row">
        //     {chartSelector}
        // </div>

        let chartControls = (
            <div className="row">
                <FormGroup>
                    <label htmlFor="mode">Mode</label>
                    <FormRadio
                        name="month"
                        checked={this.state.options.mode === "month"}
                        disabled={this.state.chart == "costsTable"}
                        onChange={() => {
                            that.setState({
                                options: {
                                    ...that.state.options,
                                    mode: "month",
                                },
                            });
                        }}
                    >
                        Month view
                    </FormRadio>
                    <FormRadio
                        name="year"
                        checked={this.state.options.mode === "year"}
                        disabled={this.state.chart == "costsTable"}
                        onChange={() => {
                            that.setState({
                                options: {
                                    ...that.state.options,
                                    mode: "year",
                                },
                            });
                        }}
                    >
                        Year view
                    </FormRadio>
                </FormGroup>
                <FormGroup className="ml-5">
                    <label htmlFor="mode">Mode</label>
                    <FormRadio
                        name="total"
                        checked={this.state.options.costsMode === "total"}
                        disabled={this.state.chart == "costsTable"}
                        onChange={() => {
                            that.setState({
                                options: {
                                    ...that.state.options,
                                    costsMode: "total",
                                },
                            });
                        }}
                    >
                        Total costs
                    </FormRadio>
                    <FormRadio
                        name="separate"
                        checked={this.state.options.costsMode === "separate"}
                        disabled={this.state.chart == "costsTable"}
                        onChange={() => {
                            that.setState({
                                options: {
                                    ...that.state.options,
                                    costsMode: "separate",
                                },
                            });
                        }}
                    >
                        Separate costs
                    </FormRadio>
                </FormGroup>
                <FormGroup className="ml-5">
                    <label htmlFor="mode">Lines type</label>
                    <FormRadio
                        name="bars"
                        checked={this.state.options.chartType === "bars"}
                        disabled={this.state.chart == "costsTable"}
                        onChange={() => {
                            that.setState({
                                options: {
                                    ...that.state.options,
                                    chartType: "bars",
                                },
                            });
                        }}
                    >
                        Bars
                    </FormRadio>
                    <FormRadio
                        name="lines"
                        checked={this.state.options.chartType === "lines"}
                        disabled={this.state.chart == "costsTable"}
                        onChange={() => {
                            that.setState({
                                options: {
                                    ...that.state.options,
                                    chartType: "lines",
                                },
                            });
                        }}
                    >
                        Lines
                    </FormRadio>
                </FormGroup>
                {chartSelector}
                <FormGroup className="ml-5">
                    {/*<label htmlFor="mode">Options</label>*/}
                    {/*<FormCheckbox*/}
                    {/*  name="bars"*/}
                    {/*  checked={this.state.options.forecast}*/}
                    {/*  onChange={() => {that.setState({options: {...that.state.options, forecast: !that.state.options.forecast}})}}>Forecast costs</FormCheckbox>*/}
                </FormGroup>
            </div>
        );

        const infoStyle = {
            stroke: "#FF0000",
            color: "#00FF00",
            fill: "0000FF",
            opacity: 1.0,
            pointerEvents: "none",
            fontSize: "large",
            labelStyle: {
                fontSize: "normal",
                color: "#000000",
            },
        };
        // styler()
        return (
            <div className="container-fluid">
                {/*<Button theme="info" className="ml-3 mt-3 mb-3" onClick={() => {*/}
                {/*    that.props.history.push(`/dataInput/${buildingId}`)*/}
                {/*}}><AddIcon/>{i18n.t(k.ADD)}</Button>*/}
                <CardWrapperComponent
                    header={true}
                    footer={true}
                    card_title={i18n.t(k.AREAS)}
                >
                    <div className="row market-value">
                        Market value: {this.state.marketValue}
                    </div>
                    <div className="row market-value">
                        Net asset value: {this.state.nav}
                    </div>
                    {chartControls}
                    <div className="row">{topChart}</div>
                    <div>
                        <span
                            className="pointer"
                            onClick={this.toggleImportCollapse}
                        >
                            <h3>Evaluator Input</h3>
                            {this.state.importCollapse ? (
                                <ArrowDropUpIcon />
                            ) : (
                                <ArrowDropDownIcon />
                            )}{" "}
                        </span>
                        <Collapse open={this.state.importCollapse}>
                            <NetAssetDataInputComponent
                                landplotId={buildingId}
                                onJLLChange={this.onJLLChange}
                                data={this.state.jllData}
                                onSaveClick={this.onJLLSaveClick}
                            />
                        </Collapse>
                        <span
                            className="pointer"
                            onClick={this.toggleDCFCollapse}
                        >
                            <h3>Direct Cash Flow</h3>
                            {this.state.areasCollapse ? (
                                <ArrowDropUpIcon />
                            ) : (
                                <ArrowDropDownIcon />
                            )}{" "}
                        </span>

                        <Collapse open={this.state.dcfCollapse}>
                            <DirectCashFlowComponent
                                costs={this.state.costs}
                                inflation={this.state.options.inflation}
                                jllData={this.state.jllData}
                            />
                        </Collapse>

                        <span
                            className="pointer"
                            onClick={this.toggleAreasCollapse}
                        >
                            <h3>Rentroll</h3>
                            {this.state.areasCollapse ? (
                                <ArrowDropUpIcon />
                            ) : (
                                <ArrowDropDownIcon />
                            )}{" "}
                        </span>

                        <Collapse open={this.state.areasCollapse}>
                            <AgreementsListNewComponent
                                landplotId={buildingId}
                                dt={true}
                                onAreasLoaded={this.onAreasLoaded}
                                onTenantsClicked={this.showModal}
                                onSingleTenantClicked={this.showTenantModal}
                            />
                        </Collapse>
                        <span onClick={this.toggleKTIDataCollapse}>
                            <h3>KTI Data</h3>
                            {this.state.ktiDataCollapse ? (
                                <ArrowDropUpIcon />
                            ) : (
                                <ArrowDropDownIcon />
                            )}{" "}
                        </span>

                        <Collapse open={this.state.ktiDataCollapse}>
                            <KTIDataComponent />
                        </Collapse>
                        {/*<PageableDatatablesComponent*/}
                        {/*    // title="Employees"*/}
                        {/*    noHeader={true}*/}
                        {/*    columns={this.getColumns()}*/}
                        {/*    background="#ffffff00"*/}
                        {/*    items={this.state.rawData}*/}
                        {/*    itemsPerPage={20}*/}
                        {/*    progressPending={this.state.loading}*/}
                        {/*    progressComponent={<Circular/>}*/}
                        {/*    total={this.state.total}*/}
                        {/*    onRowClicked={(d) => {*/}
                        {/*        this.props.history.push(`/dataReview/${d.id}`)*/}
                        {/*    }}*/}
                        {/*    loadItems={page => {*/}
                        {/*        this.loadData(page);*/}
                        {/*    }}/>*/}
                    </div>
                </CardWrapperComponent>
                <AreaTenantsListModalComponent
                    visible={this.state.modal.visible}
                    open={this.hideModal}
                    toggle={this.toggle}
                    onRequestClose={this.props.onRequestClose}
                    tenants={this.state.modal.tenants}
                    onDebtSaved={() => {
                        that.hideModal();
                    }}
                />
                <TenantDetailsModalComponent
                    visible={this.state.tenantModal.visible}
                    open={this.showTenantModal}
                    toggle={this.toggleTenantModal}
                    onRequestClose={this.props.onRequestClose}
                    email={this.state.tenantModal.email}
                    modal={true}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BuildingDataListComponent);
