import i18n from "i18next";
import k from "./../../../i18n/keys";

export const ASSET_TYPE = {
    FLOORPLAN: "floor",
    BUILDING: "building",
    AREA: "area",
    LANDPLOT: "property",
    PLOT: "plot",
    PARKING_PLACE: "parking_place",
    ROOM: "room",
    VIDEO: "video",
};

export const getAssetTypeName = (asset_type) => {
    switch (asset_type) {
        case ASSET_TYPE.FLOORPLAN:
            return i18n.t(k.FLOORPLAN);
        case ASSET_TYPE.BUILDING:
            return i18n.t(k.BUILDING);
        case ASSET_TYPE.AREA:
            return i18n.t(k.AREA);
        case ASSET_TYPE.LANDPLOT:
            return i18n.t(k.PROPERTY);
        case ASSET_TYPE.PLOT:
            return i18n.t(k.LANDPLOT);
        case ASSET_TYPE.PARKING_PLACE:
            return i18n.t(k.PARKING_PLACE);
        case ASSET_TYPE.ROOM:
            return i18n.t(k.ROOM);
        case ASSET_TYPE.VIDEO:
            return i18n.t(k.VIDEO);
        case ASSET_TYPE.IMAGE:
            return i18n.t(k.IMAGE);
    }
};
export const ASSET_AREA_TYPE = [
    { value: 1, label: i18n.t(k.OFFICE) },
    { value: 2, label: i18n.t(k.RETAIL) },
    { value: 3, label: i18n.t(k.STORAGE) },
    { value: 4, label: i18n.t(k.INDUSTRIAL) },
    { value: 5, label: i18n.t(k.HOTEL) },
    { value: 6, label: i18n.t(k.RESIDENTIAL) },
    { value: 7, label: i18n.t(k.OTHER) },
    { value: 8, label: i18n.t(k.OTHER_NO_GLA) },
    { value: 9, label: i18n.t(k.PARKING) },
    { value: 10, label: i18n.t(k.ADDITIONAL_RENT) },
    { value: 11, label: i18n.t(k.MAINTENANCE_CHARGE) },
    { value: 12, label: i18n.t(k.TURNOVER_RENT) },
];
