/*
Cases:
CONSUMER_INFO_IS_LOADING
CONSUMER_INFO_SUCCESS
CONSUMER_INFO_FAILURE
CONSUMER_INFO_CLEAR
 */

const initialState = {
  err: null,
  isLoading: false,
  isLoaded: false,
  schedule: [] };


function editScheduleReducer(state = initialState, action) {
  switch (action.type) {
    case "USER_SCHEDULE_LOADING":
      return {
        ...state,
        isLoading: true,
        err: null,
        isLoaded: false,
        schedule: [] };

    case "USER_SCHEDULE_SUCCESS":
      return {
        ...state,
        err: null,
        isLoading: false,
        isLoaded: true,
        schedule: action.schedule };

    case "USER_SCHEDULE_FAILURE":
      return {
        ...state,
        err: action.err,
        isLoading: false,
        isLoaded: false,
        schedule: [] };

    case "SET_EVENT_LOADING":
      return {
        ...state,
        isSettingEvent: true };

    case "SET_EVENT_SUCCESS":
      return {
        ...state,
        isSettingEvent: false,
        setEventSuccess: true };

    default:
      return state;}

}

export default editScheduleReducer;