// Layout Types
// Route Components
import LandingComponent from "./components/LandingComponent";
import LogoutPageComponent from "./components/auth/LogoutPageComponent";
import AuthorizedComponent from "./components/auth/AuthorizedComponent";
import StrippedLayout from "./layouts/StrippedLayout";
import BaseSideBarLayout from "./layouts/BaseSideBarLayout";
import TouComponent from "./components/TouComponent";
import AgreementsListComponent from "./components/agreements/AgreementsListComponent";
import AgreementsNewListComponent from "./components/agreements/AgreementsNewListComponent";
import TenantsListComponent from "./components/tenants/TenantsListComponent";
import AreasListComponent from "./components/areas/AreasListComponent";
import AreaCreateComponent from "./components/areas/AreaCreateComponent";
import FloorPlansListComponent from "./components/floorplans/FloorPlansListComponent";
import AgreementsEditComponent from "./components/agreements/AgreementsEditComponent";
import SubscriptionPlanListComponent from "./components/subscription_plan/SubscriptionPlanListComponent";
import UsersListComponent from "./components/users/UsersListComponent";
import FloorPlanCreateComponent from "./components/floorplans/FloorPlanCreateComponent";
import AgreementReviewComponent from "./components/agreements/subcomponents/review/AgreementReviewComponent";
import AgreementSignComponent from "./components/agreements/subcomponents/sign/AgreementSignComponent";
import BuildingListOneComponent from "./components/buildings/BuildingListOneComponent";
import InvoiceListComponent from "./components/invoices/AccountingComponent";
import InvoiceEditComponent from "./components/invoices/InvoiceEditComponent";
import PortfolioListComponent from "./components/portfolio/PortfolioListComponent";
import PortfolioListOneComponent from "./components/portfolio/PortfolioListOneComponent";
import BuildingEditComponent from "./components/buildings/BuildingEditModalComponent";
import ReportsListComponent from "./components/reports/ReportsListComponent";
import AgreementListOneComponent from "./components/agreements/AgreementListOneComponent";
import ProjectsListComponent from "./components/buildings/projects/ProjectsListComponent";
import ProjectListOneComponent from "./components/buildings/projects/ProjectListOneComponent";
import AgreementAfterSaveComponent from "./components/agreements/subcomponents/review/AgreementAfterSaveComponent";
import DeptPaymentPlanListComponent from "./components/invoices/subcomponents/DebtPaymentPlanListComponent";
import PurchaseEditComponent from "./components/invoices/PurchaseEditComponent";
import MerchantSettingsComponent from "./components/MerchantSettingsComponent";
import ShareholderListComponent from "./components/buildings/ShareholderListComponent";
import ShareholderPaymentPlanComponent from "./components/invoices/subcomponents/ShareholderPaymentPlanComponent";
import ShareholderInvoices from "./components/invoices/ShareholderInvoices";
import TenantInvoices from "./components/invoices/TenantInvoices";
import AreaDocumentsComponent from "./components/areas/documents/AreaDocumentsComponent";
import TestApiComponent from "./components/buildings/documents/building_cert/TestApiComponent";
import BuildingCertEditComponent from "./components/buildings/documents/building_cert/BuildingCertEditComponent";
import MerchantProductClassGroupSettingsComponent from "./components/MerchantProductClassGroupSettingsComponent";
import SensorListComponent from "./components/dashboard/SensorListComponent";
import CalendarMainComponent from "./components/shareholders/CalendarMainComponent";
import DashboardSafeComponent from "./components/dashboard/DashboardSafeComponent";
import AreaScheduleComponent from "./components/areas/subcomponents/AreaScheduleComponent";
import FullCalendarComponent from "./components/areas/subcomponents/FullCalendarComponent";
import BuildingUsersListComponent from "./components/users/BuildingUsersListComponent";
import NetAssetDataInputWizardComponent from "./components/net/NetAssetDataInputWizardComponent";
import BuildingDataListComponent from "./components/net/BuildingDataListComponent";
import DataReviewComponent from "./components/net/DataReviewComponent";
import KTIDataComponent from "./components/kti/KTIDataComponent";
import AreaPhotosComponent from "./components/areas/photos/AreaPhotosComponent";
import RoleCreate from "./components/roles/RoleCreate";
import MerchantRolesList from "./components/roles/MerchantRolesList";
import MerchantUsersList from "./components/roles/MerchantUsersList";
import RoleEditor from "./components/roles/RoleEditor";
import InvitationForRoleCreate from "./components/invitations/InvitationForRoleCreate";

import ParkingPlaceEditComponent from "./components/parkingPlaces/ParkingPlaceEditComponent";
import RoomsListComponent from "./components/rooms/RoomsListComponent";
import RoomEditComponent from "./components/rooms/RoomEditComponent";
import AssetGroupList from "./components/areas/groups/AssetGroupList";
import AssetGroupCreate from "./components/areas/groups/AssetGroupCreate";
import AssetGroupEdit from "./components/areas/groups/AssetGroupEdit";
import AssetEdit from "./components/assets/AssetEdit";
import AssetCreate from "./components/assets/AssetCreate";
import AssetList from "./components/assets/AssetList";
import PlotList from "./components/plots/PlotList";
import BuildingList from "./components/buildings/BuildingList";
import FloorPlanList from "./components/floorplans/FloorPlanList";
import RoomList from "./components/rooms/RoomList";
import LandplotList from "./components/landplots/LandplotList";
import SensorList from "./components/sensors/SensorList";
import AssetDocuments from "./components/assets/AssetDocuments";
import AssetVideos from "./components/assets/AssetVideos";
import AssetImages from "./components/assets/AssetImages";

import AddVideo from "./components/learn/AddVideo";
import ListVideo from "./components/learn/ListVideo";
import AddQuestion from "./components/learn/AddQuestion";
import DirectoryView from "./components/directories/DirectoryView";
import SafetyTrack from "./components/safetyTrack/SafetyTrack";
import VideoPlayer from "./components/videoPlayer/VideoPlayer";
export default [
    {
        path: "/",
        layout: BaseSideBarLayout,
        exact: true,
        protected: true,
        component: DashboardSafeComponent,
    },

    // {
    //     path: "/dashboard",
    //     layout: BaseSideBarLayout,
    //     exact: true,
    //     protected: true,
    //     component: DashboardComponent
    // },
    {
        path: "/landing",
        exact: true,
        layout: StrippedLayout,
        protected: false,
        component: LandingComponent,
    },

    {
        path: "/sign/:agreement/:secret",
        exact: true,
        layout: StrippedLayout,
        protected: false,
        component: AgreementSignComponent,
    },

    {
        path: "/authorized",
        layout: StrippedLayout,
        protected: false,
        component: AuthorizedComponent,
    },

    {
        path: "/productClasses",
        layout: BaseSideBarLayout,
        protected: false,
        component: MerchantProductClassGroupSettingsComponent,
    },

    {
        path: "/tou",
        exact: true,
        layout: BaseSideBarLayout,
        protected: true,
        component: TouComponent,
    },
    {
        path: "/sensors/:buildingId",
        exact: true,
        layout: BaseSideBarLayout,
        protected: true,
        component: SensorListComponent,
    },
    {
        path: "/sensor/list",
        layout: BaseSideBarLayout,
        protected: true,
        component: SensorList,
    },
    {
        path: "/logout",
        layout: BaseSideBarLayout,
        protected: true,
        component: LogoutPageComponent,
    },
    {
        path: "/buildings/",
        layout: BaseSideBarLayout,
        protected: true,
        component: BuildingList,
    },
    {
        path: "/plots/",
        layout: BaseSideBarLayout,
        protected: true,
        component: PlotList,
    },
    {
        path: "/newRoom/:roomId/:parent",
        layout: BaseSideBarLayout,
        protected: true,
        component: RoomEditComponent,
    },
    {
        path: "/newParkingPlace/:plotId/:parent",
        layout: BaseSideBarLayout,
        protected: true,
        component: ParkingPlaceEditComponent,
    },
    {
        path: "/landplots",
        layout: BaseSideBarLayout,
        protected: true,
        component: LandplotList,
    },
    {
        path: "/videos",
        layout: BaseSideBarLayout,
        protected: true,
        component: ListVideo,
    },
    {
        path: "/video/addNewVideo",
        layout: BaseSideBarLayout,
        protected: true,
        component: AddVideo,
    },
    {
        path: "/video/AddQuestions",
        layout: BaseSideBarLayout,
        protected: true,
        component: AddQuestion,
    },
    {
        path: "/building/:buildingId",
        layout: BaseSideBarLayout,
        protected: true,
        component: BuildingListOneComponent,
    },
    {
        path: "/example",
        layout: BaseSideBarLayout,
        protected: true,
        component: TestApiComponent,
    },

    {
        path: "/area-docs/:areaId",
        layout: BaseSideBarLayout,
        protected: true,
        component: AreaDocumentsComponent,
    },

    {
        path: "/area-photos/:areaId",
        layout: BaseSideBarLayout,
        protected: true,
        component: AreaPhotosComponent,
    },

    {
        path: "/agreement/:agreementId",
        layout: BaseSideBarLayout,
        protected: true,
        component: AgreementListOneComponent,
    },

    {
        path: "/opentimes/:areaId",
        layout: BaseSideBarLayout,
        protected: true,
        component: AreaScheduleComponent,
    },
    {
        path: "/buildingEdit/:buildingId/:parent",
        layout: BaseSideBarLayout,
        protected: true,
        component: BuildingEditComponent,
    },
    {
        path: "/buildingNew/:buildingId/:parent",
        layout: BaseSideBarLayout,
        protected: true,
        component: BuildingEditComponent,
    },
    {
        path: "/dataInput/:buildingId",
        layout: BaseSideBarLayout,
        protected: true,
        component: NetAssetDataInputWizardComponent,
    },
    {
        path: "/costs/:buildingId",
        layout: BaseSideBarLayout,
        protected: true,
        component: BuildingDataListComponent,
    },
    {
        path: "/dataReview/:dataId",
        layout: BaseSideBarLayout,
        protected: true,
        component: DataReviewComponent,
    },

    {
        path: "/buildingCertEdit",
        layout: BaseSideBarLayout,
        protected: true,
        component: BuildingCertEditComponent,
    },

    {
        path: "/portfolio/:portfolioId",
        layout: BaseSideBarLayout,
        protected: true,
        component: PortfolioListOneComponent,
    },

    {
        path: "/agreements/:buildingId",
        layout: BaseSideBarLayout,
        protected: true,
        component: AgreementsListComponent,
    },
    {
        path: "/landplotAgreements/:landplotId",
        layout: BaseSideBarLayout,
        protected: true,
        component: AgreementsListComponent,
    },
    {
        path: "/evaluate/:buildingId",
        layout: BaseSideBarLayout,
        protected: true,
        component: AgreementsNewListComponent,
    },
    // {
    //     path: "/landplotAgreements/:landplotId",
    //     layout: BaseSideBarLayout,
    //     protected: true,
    //     component: AgreementsListComponent
    // },
    {
        path: "/ktiData",
        layout: BaseSideBarLayout,
        protected: true,
        component: KTIDataComponent,
    },
    {
        path: "/reports",
        layout: BaseSideBarLayout,
        protected: true,
        component: ReportsListComponent,
    },

    {
        path: "/portfolios",
        layout: BaseSideBarLayout,
        protected: true,
        component: PortfolioListComponent,
    },

    {
        path: "/tenants",
        layout: BaseSideBarLayout,
        protected: true,
        component: TenantsListComponent,
    },
    {
        path: "/buildingAreas/:buildingId",
        layout: BaseSideBarLayout,
        protected: true,
        component: AreasListComponent,
    },
    {
        path: "/rooms/floorplan/:floorplan",
        layout: BaseSideBarLayout,
        protected: true,
        component: RoomsListComponent,
    },
    {
        path: "/rooms",
        layout: BaseSideBarLayout,
        protected: true,
        component: RoomList,
    },
    {
        path: "/areas/floorplan/:floorplan",
        layout: BaseSideBarLayout,
        protected: true,
        component: AreasListComponent,
    },

    {
        path: "/areas/:area",
        layout: BaseSideBarLayout,
        protected: true,
        component: AreasListComponent,
    },
    {
        path: "/assets/list",
        layout: BaseSideBarLayout,
        protected: true,
        component: AssetList,
    },
    {
        path: "/assets/edit",
        layout: BaseSideBarLayout,
        protected: true,
        component: AssetEdit,
    },
    {
        path: "/assets/create",
        protected: true,
        layout: BaseSideBarLayout,
        component: AssetCreate,
    },
    {
        path: "/asset/documents",
        layout: BaseSideBarLayout,
        protected: true,
        component: AssetDocuments,
    },
    {
        path: "/safety-videos",
        layout: BaseSideBarLayout,
        protected: true,
        component: AssetVideos,
    },
    {
        path: "/images",
        layout: BaseSideBarLayout,
        protected: true,
        component: AssetImages,
    },
    {
        path: "/assetGroups/",
        layout: BaseSideBarLayout,
        protected: true,
        component: AssetGroupList,
    },
    {
        path: "/assetGroup/create",
        layout: BaseSideBarLayout,
        protected: true,
        component: AssetGroupCreate,
    },
    {
        path: "/assetGroup/edit",
        layout: BaseSideBarLayout,
        protected: true,
        component: AssetGroupEdit,
    },
    {
        path: "/newArea/:floorplan",
        layout: BaseSideBarLayout,
        protected: true,
        component: AreaCreateComponent,
    },

    {
        path: "/review/:agreement",
        layout: BaseSideBarLayout,
        protected: true,
        component: AgreementReviewComponent,
    },

    {
        path: "/summary/:agreement",
        layout: BaseSideBarLayout,
        protected: true,
        component: AgreementAfterSaveComponent,
    },

    {
        path: "/projects/:buildingId",
        layout: BaseSideBarLayout,
        protected: true,
        component: ProjectsListComponent,
    },

    {
        path: "/laplotprojects/:landplotId",
        layout: BaseSideBarLayout,
        protected: true,
        component: ProjectsListComponent,
    },

    {
        path: "/shareholders/:buildingId",
        layout: BaseSideBarLayout,
        protected: true,
        component: ShareholderListComponent,
    },
    {
        path: "/buildingUsers/:buildingId",
        layout: BaseSideBarLayout,
        protected: true,
        component: BuildingUsersListComponent,
    },

    {
        path: "/all_shareholders/:buildingId",
        layout: BaseSideBarLayout,
        protected: true,
        component: CalendarMainComponent,
    },

    {
        path: "/payment/:paymentPlan",
        layout: BaseSideBarLayout,
        protected: true,
        component: DeptPaymentPlanListComponent,
    },

    {
        path: "/shareholderPayment/:paymentPlan",
        layout: BaseSideBarLayout,
        protected: true,
        component: ShareholderPaymentPlanComponent,
    },

    {
        path: "/project/:projectId",
        layout: BaseSideBarLayout,
        protected: true,
        component: ProjectListOneComponent,
    },
    {
        path: "/floorplans",
        layout: BaseSideBarLayout,
        protected: true,
        component: FloorPlanList,
    },
    {
        path: "/floorplan/new/:building",
        layout: BaseSideBarLayout,
        protected: true,
        component: FloorPlanCreateComponent,
    },
    {
        path: "/agreementEdit/:agreementID",
        layout: BaseSideBarLayout,
        protected: true,
        component: AgreementsEditComponent,
    },
    {
        path: "/agreementNew/:buildingId",
        layout: BaseSideBarLayout,
        protected: true,
        component: AgreementsEditComponent,
    },
    {
        path: "/subscriptionPlan",
        layout: BaseSideBarLayout,
        protected: true,
        component: SubscriptionPlanListComponent,
    },

    {
        path: "/users",
        layout: BaseSideBarLayout,
        protected: true,
        component: UsersListComponent,
    },
    {
        path: "/reservations",
        layout: BaseSideBarLayout,
        protected: true,
        component: FullCalendarComponent,
    },

    {
        path: "/accounting",
        exact: true,
        layout: BaseSideBarLayout,
        protected: true,
        component: InvoiceListComponent,
    },

    {
        path: "/invoiceEdit/:invoiceId",
        exact: true,
        layout: BaseSideBarLayout,
        protected: true,
        component: InvoiceEditComponent,
    },

    {
        path: "/shareholderInvoices/:shareholderId",
        exact: true,
        layout: BaseSideBarLayout,
        protected: true,
        component: ShareholderInvoices,
    },

    {
        path: "/tenantInvoices/:tenantId",
        exact: true,
        layout: BaseSideBarLayout,
        protected: true,
        component: TenantInvoices,
    },

    {
        path: "/purchaseEdit/:invoiceId",
        exact: true,
        layout: BaseSideBarLayout,
        protected: true,
        component: PurchaseEditComponent,
    },

    {
        path: "/merchantSettings",
        exact: true,
        layout: BaseSideBarLayout,
        protected: true,
        component: MerchantSettingsComponent,
    },
    {
        hidden: false,
        path: "/role/users/",
        layout: BaseSideBarLayout,
        protected: true,
        component: MerchantUsersList,
    },
    {
        hidden: false,
        path: "/roles",
        layout: BaseSideBarLayout,
        protected: true,
        component: MerchantRolesList,
    },
    {
        hidden: false,
        path: "/role/create",
        layout: BaseSideBarLayout,
        protected: true,
        component: RoleCreate,
    },
    {
        path: "/role/edit",
        label: "Edit Role",
        layout: BaseSideBarLayout,
        component: RoleEditor,
        hidden: true,
    },
    {
        path: "/invitation/create",
        label: "Invitation Create Form",
        layout: BaseSideBarLayout,
        component: InvitationForRoleCreate,
        hidden: true,
    },
    {
        path: "/directory/:parent",
        layout: BaseSideBarLayout,
        protected: true,
        component: DirectoryView,
    },
    {
        path: "/safetyTrack",
        layout: BaseSideBarLayout,
        protected: true,
        component: SafetyTrack,
    },
    {
        path: "/videoPlayer",
        layout: BaseSideBarLayout,
        protected: true,
        component: VideoPlayer,
    },
];
