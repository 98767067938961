import i18n from 'i18next';
import k from "./../../../i18n/keys";
import React, { Component } from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import DataTable from "react-data-table-component";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { ASSET_TYPE } from './../../../actions/api/assets/config';
import HonkioAPI from './../../../middlewares/HonkioAPI';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "shards-react";

const Circular = () =>
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>;
//author: Inna. This is instead of area maps in AgreementRentAreaSelectAreaModal.js
class AgreementRentAreaSelectAreaModalComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            areas: [],
        };
        this.loadAreas = this.loadAreas.bind(this);
    }

    componentDidUpdate(prevProps) { //inna: change componentWillReceiveProps to this method in other cases
        if (this.props.floorplan && this.props.floorplan.id !== this.state.floorplanId) {
            this.setState({ floorplanId: this.props.floorplan.id })
            this.loadAreas(this.props.floorplan.id)
        }
    }

    loadAreas(floorplanId) {
        let that = this;
        this.setState({ loading: true });
        let merchantarealist = async function () {
            let params = {
                query_type: ASSET_TYPE.AREA,
                query_parent: floorplanId
            }
            let response = await HonkioAPI().userShop.userFetch('merchantassetlist', params, error => {
                that.setState({
                    areas: [],
                    loading: false
                })
                NotificationManager.error(`${error.description}`, i18n.t(k.ERROR_API));
            })
            if (response?.status === 'accept' && response?.assets) {
                that.setState({
                    areas: response.assets,
                    loading: false
                });
            } else {
                that.setState({
                    areas: [],
                    loading: false
                });
                NotificationManager.error(i18n.t(k.ERROR_API));
            }
        };
        merchantarealist();
    }

    getColumns() {
        let that = this;
        return [
            {
                name: i18n.t(k.NAME),
                selector: 'name',
                sortable: true,
                autoWidth: true,
                grow: 2,
                style: `cursor: pointer;`
            },
            {
                name: i18n.t(k.TOTAL_AREA),
                selector: d => <span>{d.properties.rules?.total_area}{i18n.t(k.M)}</span>,
                sortable: true,
                autoWidth: true,
                grow: 1,
                style: `cursor: pointer;`
            },
            {
                name: i18n.t(k.FLOORPLANS_L),
                selector: 'parent_name',
                sortable: true,
                autoWidth: true,
                grow: 1,
                style: `cursor: pointer;`
            },
            {
                name: i18n.t(k.AREA_TYPE),
                selector: d => <span>{(d.properties?.rules?.type?.charAt(0).toUpperCase() + d.properties?.rules?.type?.slice(1)) || 'N/A'}</span>, //to upper case first letter
                sortable: true,
                autoWidth: true,
                grow: 0,
                style: `cursor: pointer;`
            },
            {
                name: i18n.t(k.RESERVABLE),
                selector: d => <span>{d.properties?.rules?.reservable ? i18n.t(k.TRUE) : i18n.t(k.FALSE)}</span>,
                sortable: true,
                autoWidth: true,
                grow: 0,
                style: `cursor: pointer;`
            }
        ];
    }

    rowSelected = (area) => {
        this.props.onAreaSelected(area);
    }
    render() {
        let modal =
            <Modal
                open={this.props.visible}
                toggle={this.props.toggle}
                onRequestClose={this.props.onRequestClose}
                className="w-100 modal-lg modal-dialog-centered modal-dialog-scrollable">
                <ModalHeader closeButton>{i18n.t(k.SELECT_AN_AREA)}</ModalHeader>
                <ModalBody>
                    <div className="center-bloc">
                        <Circular />
                    </div>
                </ModalBody>
            </Modal>;

        //if (this.state.areas && this.state.areas.length > 0) {
            // let AREAS_MAP = this.areasMap();
            let that = this;
            modal =
                <Modal
                    open={this.props.visible}
                    toggle={this.props.toggle}
                    onRequestClose={this.props.onRequestClose}
                    className="w-100 modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <ModalHeader >{i18n.t(k.SELECT_AN_AREA)}
                    </ModalHeader>
                    <ModalBody>
                        <DataTable
                            autoWidth={true}
                            striped
                            highlightOnHover
                            columns={this.getColumns()}
                            background="#ffffff00"
                            data={this.state.areas}
                            noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                            paginationComponentOptions={
                                {
                                    rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                                    rangeSeparatorText: i18n.t(k.ROWS_OF),
                                    noRowsPerPage: false,
                                    selectAllRowsItem: false,
                                    selectAllRowsItemText: i18n.t(k.ROWS_ALL)
                                }
                            }
                            progressPending={this.state.loading}
                            progressComponent={<Circular />}
                            onRowClicked={this.rowSelected} />
                    </ModalBody>
                    <ModalFooter> <Button onClick = {this.props.onRequestClose}>Close</Button></ModalFooter>
                </Modal>
        //}
        return <div>{modal}</div>;
    }
}
export default AgreementRentAreaSelectAreaModalComponent
