 import React from 'react'
import { GOOGLE_API_KEY } from '../../../config';
const ImagePreview = ({ name, className, type, error, disabled, k , latitude, longitude }) => {
  const source = (latitude = '40.714728', longitude = '-73.998672') =>
  [
    'https://maps.googleapis.com/maps/api/streetview?size=400x400&location=',
    `${latitude},${longitude}`,
    '&fov=80&heading=70&pitch=0',
    `&key=${GOOGLE_API_KEY}`,
  ].join('');
  // console.log(name,"-----------name")
  return (
    <fieldset className={className} disabled={disabled}>
      <div  key={k}  className="card p-2 h-100">
        <div className="border mb-2 p-3 bg-light text-info h-100">
        <img src={source(latitude, longitude)} alt="Map" />
        </div>
      </div>
    </fieldset>
  );
};
export default ImagePreview;
