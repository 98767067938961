import { consumerInfoFailure, consumerInfoSuccess } from "../actions/api/consumer/consumerGetActions";
import { useraccesslistSuccess } from "../actions/api/user/useraccesslistActions";
import { shopFindSuccess } from "../actions/api/shop/shopFindActions";
import HonkioAPI from "./HonkioAPI";
import { replace } from "react-router-redux";
import { APP_PACKAGE, MERCHANT_REGISTER_URL } from "../config";
import initi18 from "../i18n/init";
import { APP_ID } from "../config";

function miscAPIRequestsMiddleware({ dispatch, getState }) {
  return next => action => {
    // if (action.type === "USER_AUTH_SUCCESS") {
    //   // if ((action.type === "@@router/LOCATION_CHANGE") && (action.type !== "LOGOUT") && (action.payload.location.pathname !== '/authorized')) {
    //   // if (typeof action === "function") {
    //   let state = getState();
    //   let access_token = action.token.access_token;
    //   // checks if consumer_info state exist
    //   let consumer_info = state.consumer_info.consumer_info || { status: 'error' };
    //   // checks if user_access_list state exist
    //   // let user_access_list = state.user_access_list.user_access_list || {status: 'error'}
    //   // checks if shop_find state exist
    //   // let shop_find = state.shop_find.shop_find || {status: 'error'}

    //   // dispatching gettingConsumerInfo action if needed
    //   // if (!state.consumer_info.isLoaded || (consumer_info.status === 'error')) {
    //   //     dispatch(gettingConsumerInfo(access_token))
    //   // }


    //   // dispatching gettingAccessList action if needed
    //   // if(access_token) {
    //   //     if (!state.user_access_list.isLoaded || (user_access_list.status === 'error')) {
    //   const request = async () => {
    //     let consumerget = await HonkioAPI().mainShop.userFetch('consumerget',
    //     { 'access_token': access_token },
    //     message => {
    //       consumerInfoFailure(message.description);
    //       return false;
    //     });
    //     // console.log('RESPONSE ORDER LIST JSON');
    //     // console.log(consumerget);

    //     initi18(consumerget.consumer_str_language)
    //     localStorage.setItem('i18nextLng', consumerget.consumer_str_language || 'en')

    //     dispatch(consumerInfoSuccess(consumerget));

    //     let useraccess = await HonkioAPI().mainShop.userFetch('useraccesslist', {});
    //     // console.log('useraccess');
    //     // console.log(useraccess);
    //     // let useraccess = await useraccessRequest.json()
    //     if (Object.values(useraccess.merchants).length <= 0) {
    //       if (window.confirm("You don't have a company in this application. Would you like to proceed to company registration?")) {
    //         window.location.href = MERCHANT_REGISTER_URL;
    //         return next(action);
    //       } else {
    //         localStorage.clear();
    //         window.location.href = '/';
    //         return next(action);
    //       }
    //     }
    //     dispatch(useraccesslistSuccess(Object.values(useraccess.merchants)[0].name));
    //     let shopfind = await HonkioAPI().mainShop.userFetch('shopfind',
    //     { query_merchant: Object.keys(useraccess.merchants)[0] }, message => {
    //       shopFindSuccess(message.description);
    //       return false;
    //     });

    //     let roles = await HonkioAPI().mainShop.userFetch('rolelist',
    //     { 'access_token': access_token },
    //     message => {
    //       consumerInfoFailure(message.description);
    //       return false;
    //     });
    //     // console.log("ROLES RESPONSE")
    //     // console.log(roles)
    //     let admin_role = roles.roles.find(role => role.name == 'admin' || role.name == 'superadmin')

    //     if (admin_role){
    //       localStorage.setItem("role", admin_role.id)
    //     }
    //     localStorage.setItem("shop", shopfind.product[0].id);
    //     localStorage.setItem("shop_password", shopfind.product[0].password);
    //     localStorage.setItem('merchants', JSON.stringify(useraccess.merchants));
    //     localStorage.setItem("merchant_name", Object.values(useraccess.merchants)[0].name);
    //     localStorage.setItem("merchant_id", Object.values(useraccess.merchants)[0].id);
    //     localStorage.setItem("access", useraccess.user_access[0].access);

    //     let ordermodel = await HonkioAPI().mainShop.userFetch('merchantgetordermodel',
    //     { 'name': 'service_order' }, message => {
    //       return false;
    //     });
    //     // console.log('ordermodel');
    //     // console.log(ordermodel);

    //     let model = ordermodel.ordermodel;
    //     localStorage.setItem("orderModel", JSON.stringify(model));


    //     let merchant = await HonkioAPI().userShop.userFetch('merchantget', {});
    //     dispatch({
    //       type: "GET_MERCHANT_SUCCESS",
    //       merchantInfo: merchant.merchant });


    //     let version = 0;
    //     if (consumerget.hasOwnProperty('consumer_dict_accepted_tou_version')) {
    //       let accepted = consumerget.consumer_dict_accepted_tou_version;
    //       if (accepted.hasOwnProperty(APP_PACKAGE) && accepted[APP_PACKAGE].hasOwnProperty('default')) {
    //         version = parseInt(accepted[APP_PACKAGE].default.version);
    //       }
    //     }
    //     let tou = await HonkioAPI().mainShop.userFetch('servergettou', {}, err => {
    //       console.error(err);
    //       return true;
    //     });
    //     let serverVersion = parseInt(tou.version || 0);
    //     if (version < serverVersion) {
    //       localStorage.setItem('touAccepted', false);
    //       window.location.href = '/tou';
    //     } else {
    //       localStorage.setItem('touAccepted', true);
    //       dispatch(replace("/"));
    //     }


    //     // let shopfind = await shopfindRequest.json()
    //     // dispatch(shopFindSuccess(shopfind));
    //     // dispatch(useraccesslistSuccess(useraccess));

    //   };
    //   request();
    //   //     }
    //   // }
    // }
    return next(action);


  };
}

export default miscAPIRequestsMiddleware;
