import i18n from 'i18next';
import k from "./../../../i18n/keys";
import React, { Component } from 'react';
import WidgetComponent from "./WidgetComponent";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Index, TimeRange, TimeSeries } from "pondjs";
import { ChartContainer, ChartRow, Charts, EventMarker, ScatterChart, styler, YAxis } from "react-timeseries-charts"
import moment from "moment";
import "moment/locale/fi";
import { d3Localize } from "../../../i18n/d3/d3helper";

d3Localize()

const baselineStyle = {
  line: {
    stroke: i18n.t(k.GRAY),
    strokeWidth: 1
  }
};

class TouchWidgetComponent extends Component {
  constructor(props) {
    super(props);
    this.refreshData = this.refreshData.bind(this);
    this.state = {
      loading: true,
      timerange: new TimeRange(moment(this.props.start_date).toDate(), moment(this.props.end_date).toDate())
    };
  }

  componentDidMount() {
    this.refreshData();
    let that = this;
    this.setContainerRef = element => {
      that.container = element;
    };
  }

  handleTrackerChanged = t => {
    if (t) {
      const e = this.state.touchSeries.atTime(t);
      if (e) {
        const eventTime = new Date(
          e.begin().getTime() + (e.end().getTime() - e.begin().getTime()) / 2);
        const eventValue = e.get("touch");
        const v = `${moment().utc(eventTime).format('LLL')}`;
        this.setState({ tracker: eventTime, trackerValue: v, trackerEvent: e });
      }
    } else {
      this.setState({ tracker: null, trackerValue: null, trackerEvent: null });
    }
  };
  renderMarker = () => {
    if (this.state.markerMode === "flag") {
      return (
        <EventMarker
          type="flag"
          axis="axis"
          event={this.state.trackerEvent}
          column="touch"
          info={[{ label: i18n.t(k.ANOMALY), value: this.state.trackerValue }]}
          infoTimeFormat="%Y"
          infoWidth={120}
          markerRadius={2}
          markerStyle={{ fill: i18n.t(k.BLACK) }} />);
    } else {
      return (
        <EventMarker
          type="point"
          axis="axis"
          event={this.state.trackerEvent}
          column="touch"
          markerLabel={this.state.trackerValue}
          markerLabelAlign="left"
          markerLabelStyle={{ fill: "#1f2c82", stroke: i18n.t(k.WHITE) }}
          markerRadius={3}
          markerStyle={{ fill: i18n.t(k.DB_D) }} />);
    }
  };

  handleTimeRangeChange = timerange => {
    let that = this;
    if (this.state.zooming) {
      clearTimeout(this.state.zooming);
    }
    this.setState({
      timerange,
      zooming: setTimeout(function () {
        that.refreshData()
      }, 200)
    });
  };

  refreshData() {
    let that = this;
    that.setState({ 'loading': true });
    let load = async function () {
      let query = {
        // query_merchant: localStorage.getItem('merchant_id'),
        query_asset: that.props.assetId,
        query_limit: 50,
        // query_aggregate: ['VALUE'],
      };
      if (that.props.start_date) {
        query['query_start_date'] = moment(that.state.timerange.begin()).format('YYYY-MM-DD hh:mm:ss');
      }
      if (that.props.end_date) {
        query['query_end_date'] = moment(that.state.timerange.end()).format('YYYY-MM-DD hh:mm:ss');
      }
      let response = await HonkioAPI().userShop.userFetch('merchantasseteventlist', query);
      let min = 0;
      let max = 0;
      let avg = 0;
      let points = [];
      let lastTouched = new Date(0, 0, 0, 0, 0, 0, 0);
      response.events.forEach(val => {
        const index = Index.getIndexString("1m", moment(val.timestamp).add(moment().utcOffset(), "minutes").toDate());
        const touch = 1;
        points.push([index, touch]);
        if (moment(val.timestamp).add(moment().utcOffset(), "minutes").toDate() > lastTouched) lastTouched = moment(val.timestamp).add(moment().utcOffset(), "minutes").toDate();
      });
      if (response.events.length > 0) {
        const touchSeries = new TimeSeries({
          name: i18n.t(k.TOUCH),
          columns: ["index", "touch"],
          points: points
        });
        that.setState({
          'events': response.events,
          touchSeries: touchSeries,
          timerange: touchSeries.timerange(),
          min: min,
          max: max,
          lastTouched: lastTouched,
          'loading': false
        });
      } else {
        that.setState({
          'loading': false, touchSeries: new TimeSeries({
            name: i18n.t(k.TEMPERATURE),
            columns: ["index", "temperature"],
            points: []
          })
        })
      }
    };
    load();
  }

  render() {
    const points = [];
    let line;
    let summary;
    let drawChart = (height, width) => {
      const style = styler([
        { key: "touch", color: "black", width: 1 }]);
      const axisStyle = {
        values: {
          labelColor: "grey",
          labelWeight: 100,
          labelSize: 11
        },
        axis: {
          axisColor: "grey",
          axisWidth: 1
        }
      };
      const min = this.state.min;
      const max = this.state.max;
      return this.state.touchSeries.collection.length ? <ChartContainer
        timeRange={this.state.timerange}
        // format={'day'}
        timeAxisStyle={axisStyle}
        onTrackerChanged={this.handleTrackerChanged}
        // enablePanZoom={true}
        // onTimeRangeChanged={this.handleTimeRangeChange}
        width={width}
        height={height}
      // width={this.container.offsetWidth}
      >
        <ChartRow
          // height={this.props.height * ROW_HEIGHT - 75}
          // width={this.props.width * 100 }
          height={height}
          width={width}
        // width={this.container.offsetWidth}
        >
          <YAxis
            id="axis"
            label="Touch"
            transition={300}
            style={axisStyle}
            labelOffset={0}
            min={min}
            max={max}
            format=",.1f"
            width="60"
            type="linear" />
          <Charts>
            <ScatterChart
              axis="axis"
              series={this.state.touchSeries}
              columns={["touch"]}
              style={style} />
            {this.renderMarker()}
          </Charts>
        </ChartRow>
      </ChartContainer> : <span>{i18n.t(k.NO_DATA)}</span>;
    };
    if (!this.state.loading) {
      line = this.state.touchSeries.collection.length ?
        summary = <div className="d-flex justify-content-center">
          <div className="w-100 h-100">
            {/*<div className="text-center">*/}
            {/*    <span>13.1C</span>*/}
            {/*    <br/>*/}
            {/*    <span className="text-muted">1 minutes ago</span>*/}
            {/*</div>*/}
            {/*<hr/>*/}
            <div className="text-center">
              <span><span className="text-muted">{i18n.t(k.LAST_TOUCHED)} </span><b>{moment(this.state.lastTouched).format('LLL')}</b></span><br />
            </div>
          </div>
        </div> : ''
    }

    return (
      <WidgetComponent
        position={this.props.position}
        height={this.props.height}
        width={this.props.width}
        icon="fas fa-hand-point-up fa-2x"
        name={this.props.name}
        onRename={this.props.onRename}
        type={i18n.t(k.TOUCH)}
        summary1={summary}
        onRemoveClick={this.props.onRemoveClick}
        chart={this.state.loading ? () => <CircularProgress size={35} /> : drawChart} />)
  }
}

export default TouchWidgetComponent;
