import i18next from "i18next";
import LngDetector from "i18next-browser-languagedetector";
import XHR from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
// import finnish from "./finnish.json"
import english from "./en.json";
import { localeConfig, supportedLanguages } from "./i18helper";
const finnish = require("./fi.json");
// const  = require('./finnish.json');

const options = {
    order: ["querystring", "navigator"],
    lookupQuerystring: "i18nextLng",
};

// i18next
//   .use(XHR) // <---- add this
//   // .use(LngDetector)
//   .use(initReactI18next)
//   .init({
//     lng: localStorage.getItem('i18nextLng') || 'en',
//     detection: options,
//     // ns: ['common'],
//     // defaultNS: 'common',
//     fallbackLng: 'en',
//     supportedLngs: supportedLanguages,
//     interpolation: {
//       escapeValue: false,
//     },
//     resources: localeConfig,
//     debug: true,
//   })
const initi18 = (lang) => {
    i18next
        .use(XHR) // <---- add this
        // .use(LngDetector)
        .use(initReactI18next)
        .init({
            lng:
                localStorage.getItem("token") && lang
                    ? lang
                    : localStorage.getItem("i18nextLng") || "en",
            detection: options,
            // ns: ['common'],
            // defaultNS: 'common',
            fallbackLng: "en",
            supportedLngs: supportedLanguages,
            interpolation: {
                escapeValue: false,
            },
            resources: localeConfig,
            // debug: true,
        });
};

initi18();

export default initi18;
// i18next.language = l;

// i18next.use(LngDetector).init({
//   lng: localStorage.getItem('lng') || 'en',
//   debug: true,
//   resources: {
//     en: { translation: english },
//     'fi-FI': { translation: finnish },
//     'fi': { translation: finnish },
//   },
// });

// Add this line to your app entrypoint. Usually it is src/index.js
// import './i18n/init';
