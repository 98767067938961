import i18n from 'i18next'; import k from "./../../i18n/keys"; import React, { Component } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import createLasso from "lasso-canvas-image";
import { Button, Modal, ModalBody, ModalHeader } from "shards-react";
import SecureImage from "../ui_utils/SecureImage";
import './subcomponents/styles/cropper.css';
import HonkioAPI from "../../middlewares/HonkioAPI";
import { ASSET_TYPE } from '../../actions/api/assets/config';

class AreaCreatePolComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      selector: 'polygonal',
      polygon: [],
      modalSaveAreaPol: {
        visible: false,
        polygon: { 'data': null }
      }
    };
    this.loadFloorplan = this.loadFloorplan.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.floorplan) {
      this.setState({
        ...this.state,
        floorplan: nextProps.floorplan
      });
      this.loadFloorplan(nextProps.floorplan);
    }
  }

  componentDidMount() {
    this.lassoInitiated = false;
    this.setState({ "lasso": null, polygon: { data: null } });
    this.setLassoRef = element => {
      this.imgRef = element;
      if (!this.lassoInitiated) {
        this.initLasso(element);
      }
    };
  }

  loadFloorplan(floorplan) {
    let that = this;
    let query = { query_type: ASSET_TYPE.FLOORPLAN };
    if (floorplan) {
      query['query_id'] = floorplan;
    }
    let merchantfloorplanlist = async function () {
      let response = await HonkioAPI().userShop.userFetch('merchantassetlist', query, error => {
        that.setState({
          plan: {},
          loading: false
        });
      });
      if (response?.status === 'accept' && response.assets?.length > 0) {
        let floorplan = response.assets[0];
        that.setState({
          plan: floorplan,
          loading: false
        });
      } else {
        that.setState({
          plan: {},
          loading: false
        })
      }
    }
    merchantfloorplanlist();
  }

  initLasso(elem) {
    let that = this;
    this.lassoInitiated = false;
    this.setState({ "polygon": { data: null } });
    if (elem instanceof HTMLImageElement) {//init of image
      that.lassoInitiated = true;
      this.setState({
        'lasso': createLasso({
          element: elem,
          radius: 10,
          onChange(polygon) {
            that.setState({ ...that.state, 'polygon': { 'data': polygon } });
          },
          onUpdate(polygon) {
            that.setState({ 'polygon': { 'data': polygon } });
          }
        })
      });
    }
  }

  render() {
    let image;
    if (this.state.plan) {
      const url = this.state.plan.properties?.url
      //const url = "https://admin.2.honkio-dev.com/merchant_photo/60c9f368bce5a9b4d55d709c.jpg"
      image = <SecureImage src={url} 
        style={{ width: "600px" }} className="img-lasso"
        imgRef={this.setLassoRef} />;
    }
    let that = this;
    return (
      <Modal
        open={this.props.visible}
        toggle={() => {
          that.lassoInitiated = false;
          this.props.toggle();
        }}
        onRequestClose={() => {
          that.lassoInitiated = false;
          this.props.onRequestClose();
        }}
        className="modal-dialog-scrollable modal-lg mx-auto">
        <ModalHeader>{i18n.t(k.CREATE_NEW_AREA)}</ModalHeader>
        <ModalBody>
          <div className="row ">
            <div className="col-md-12">
              <div style={{ display: "inline-block", position: "relative" }}>
                {image}
                <p>{this.state.polygon.data}</p>
              </div>
              <br />
              <Button className="push-right"
                onClick={() => { that.props.onAreaSelected(this.state.polygon); }}>
                {i18n.t(k.SAVE_AREA)}
              </Button>
              <Button className="push-right secondary"
                onClick={() => { that.props.onAreaSelected(null); }}>
                Skip
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>);
  }
}

export default AreaCreatePolComponent;
