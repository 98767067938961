import React, { useState, useEffect, useMemo } from "react";
import i18n from "i18next";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import NotificationManager from "react-notifications/lib/NotificationManager";
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import k from "./../../i18n/keys";
import {
    setPropertyList,
    setSelectedProperty,
    setPropertyTotal,
    setLandplotList,
    setSelectedLandplot,
    setLandplotTotal,
    setBuildingList,
    setSelectedBuilding,
    setBuildingTotal,
    setBuildingAssetList,
    setBuildingAssetTotal,
    setSafetyStep,
    setSelectedBuildingAssets,
} from "../../actions/safety_track";
import { STORAGE } from "./../../actions/auth/oauthActions";
import { ASSET_TYPE } from "./../../actions/api/assets/config";
import { APP_ID } from "./../../config";
import getAssetTypeList from "./../../actions/api/assetTypes/getAssetTypeList";
import {
    getAssetListWithPages,
    getMerchantAssetListWithPages,
} from "./../../actions/api/assets/getAssetList";
import PageableDatatablesComponent from "../ui_utils/PageableDatatablesComponent";
import getColumnsForType from "../directories/helpers/getColumnsForType";
import CircularProgress from "@material-ui/core/CircularProgress";
import SafetyNavigation from "./components/SafetyNavigation";
import GroupableDatatable from "../directories/GroupableDatatable";
import { ReactComponent as SafetyVideoIcon } from "../../img/icons/video_icon.svg";
import { openSafetyVideoModal } from "../../actions/action_utils";
import TrueIcon from "@material-ui/icons/CheckCircle";
import FalseIcon from "@material-ui/icons/Brightness1Rounded";
import SafetyTrackBuilder from "./components/SafetyTrackBuilder";
import { Button, ButtonGroup } from "shards-react";
import ArrowRight from "@material-ui/icons/ChevronRight";

const PAGE_ITEMS_COUNT = 10;

const Circular = () => (
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

// const MAX_ALLOWED_ITEMS = 30;
const MAX_ALLOWED_ITEMS = 2;

const SafetyTrack = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const safetyTrack = useSelector((state) => state.safetyTrack);
    const safetyVideos = useSelector((state) => state.safetyVideos);

    const [typesList, setTypesList] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (safetyTrack.step === 0) fetchPropertiesList();
        if (safetyTrack.step === 1) fetchLandplotList();
        if (safetyTrack.step === 2) fetchBuildingList();
        if (safetyTrack.step === 3) {
            fetchAssetList();
            fetchChildrenTypes();
        }
    }, [safetyTrack.step]);

    useEffect(() => {
        if (!safetyVideos.isModalOpen && safetyTrack.step === 3) {
            fetchAssetList();
        }
    }, [safetyVideos.isModalOpen, safetyTrack.step]);

    const fetchPropertiesList = async (page) => {
        setLoading(true);
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                dispatch(setPropertyList(data.assets));
                dispatch(setPropertyTotal(data.total));
                setLoading(false);
            } else {
                NotificationManager.error("Error");
                dispatch(setPropertyList([]));
                dispatch(setPropertyTotal(0));
                setLoading(false);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            dispatch(setPropertyList([]));
            setLoading(false);
        };
        getAssetListWithPages(
            {
                type: ASSET_TYPE.LANDPLOT,
                page: page,
                count: PAGE_ITEMS_COUNT,
                merchant: localStorage.getItem(STORAGE.MERCHANT_ID),
            },
            errorHandler
        )
            .then((data) => {
                return resultHandler(data);
            })
            .catch(console.error);
    };

    const propertyColumns = [
        {
            name: i18n.t(k.PHOTO),
            selector: "properties.street_view",
            cell: function (asset) {
                if (asset.properties?.street_view) {
                    return (
                        <img
                            alt="asd"
                            src={asset.properties.street_view}
                            width="100"
                            className="border-top border-bottom mb-2"
                        />
                    );
                } else {
                    return "no Photo";
                }
            },
        },
        {
            name: i18n.t(k.NAME),
            selector: "name",
            width: "20%",
            sortable: true,
            style: `cursor: pointer;`,
        },
        {
            name: i18n.t(k.DESCRIPTION),
            selector: "description",
            sortable: true,
            style: `cursor: pointer;`,
        },
        {
            name: i18n.t(k.ADDRESS),
            selector: "properties.address",
            sortable: true,
            style: `cursor: pointer;`,
        },
    ];

    const onPropertyRowClicked = (property) => {
        dispatch(setSelectedProperty(property));
        dispatch(setSafetyStep(1));
    };

    const onPropertyRowSelected = (property) => {
        if (safetyTrack?.selectedProperty?._id === property._id) return;
        onPropertyRowClicked(property);
    };

    const fetchLandplotList = async (page) => {
        if (!safetyTrack.selectedProperty) return;
        setLoading(true);
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                dispatch(setLandplotList(data.assets));
                dispatch(setLandplotTotal(data.total));
                setLoading(false);
            } else {
                NotificationManager.error("Error");
                dispatch(setLandplotList([]));
                dispatch(setLandplotTotal(0));
                setLoading(false);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            dispatch(setLandplotList([]));
            dispatch(setLandplotTotal(0));
            setLoading(false);
        };
        getAssetListWithPages(
            {
                levels: 3,
                type: ASSET_TYPE.PLOT,
                page: page,
                count: PAGE_ITEMS_COUNT,
                parent: safetyTrack.selectedProperty?._id,
            },
            errorHandler
        )
            .then((data) => {
                return resultHandler(data);
            })
            .catch(console.error);
    };

    const landplotColumns = [
        {
            name: i18n.t(k.NAME),
            selector: "name",
            grow: 2,
            sortable: true,
            style: `cursor: pointer;`,
        },
        {
            name: i18n.t(k.ADDRESS),
            selector: "properties.address",
            sortable: true,
            grow: 2,
            style: `cursor: pointer;`,
        },
        {
            name: i18n.t(k.PROPERTY_MANAGER2),
            grow: 2,
            selector: "properties.asset_manager",
            sortable: true,
            style: `cursor: pointer;`,
        },
    ];

    const onLandplotRowClicked = (landplot) => {
        dispatch(setSelectedLandplot(landplot));
        dispatch(setSafetyStep(2));
    };

    const onLandplotRowSelected = (landplot) => {
        if (safetyTrack?.selectedLandplot?._id === landplot._id) return;
        onLandplotRowClicked(landplot);
    };

    const fetchBuildingList = async (page) => {
        setLoading(true);
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                dispatch(setBuildingList(data.assets));
                dispatch(setBuildingTotal(data.total));
                setLoading(false);
            } else {
                NotificationManager.error("Error");
                dispatch(setBuildingList([]));
                dispatch(setBuildingTotal(0));
                setLoading(false);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setLoading(false);
        };
        getAssetListWithPages(
            {
                type: ASSET_TYPE.BUILDING,
                page: page,
                count: PAGE_ITEMS_COUNT,
                levels: 3,
                parent: safetyTrack?.selectedLandplot?._id,
            },
            errorHandler
        )
            .then((data) => {
                return resultHandler(data);
            })
            .catch(console.error);
    };

    const buildingColumns = [
        {
            name: i18n.t(k.NAME),
            selector: "name",
            grow: 1,
            sortable: true,
            style: `cursor: pointer;`,
        },
        {
            name: i18n.t(k.ADDRESS),
            selector: "properties.address",
            sortable: true,
            grow: 2,
            style: `cursor: pointer;`,
        },
    ];

    const onBuildingRowClicked = (building) => {
        dispatch(setSelectedBuilding(building));
        dispatch(setSafetyStep(3));
    };

    const onBuildingRowSelected = (building) => {
        if (safetyTrack?.selectedBuilding?._id === building._id) return;
        onLandplotRowClicked(building);
    };

    const fetchAssetList = async (page) => {
        setLoading(true);
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                dispatch(setBuildingAssetList(data.assets));
                dispatch(setBuildingAssetTotal(data.total_count));
                setLoading(false);
            } else {
                NotificationManager.error("Error");
                dispatch(setBuildingAssetList([]));
                dispatch(setBuildingAssetTotal(0));
                setLoading(false);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setLoading(false);
        };
        // TODO: Get all the assets for selected building recursively
        getMerchantAssetListWithPages(
            {
                // page: page,
                // count: PAGE_ITEMS_COUNT,
                levels: 3,
                query_child_levels: 10,
                parent: safetyTrack?.selectedBuilding?._id,
                merchant: localStorage.getItem(STORAGE.MERCHANT_ID),
            },
            errorHandler
        )
            .then((data) => {
                return resultHandler(data);
            })
            .catch(console.error);
    };

    const checkIfVideoAttached = (row) => {
        const attachedVideo = safetyTrack.buildingAssetsList.filter(
            (item) => item.type === "safety_video" && item.parent === row.id
        );
        if (attachedVideo.length) return true;
        return false;
    };

    const handleAttachSafetyVideo = (e, selected_asset) => {
        e.preventDefault();
        const asset = typesList.filter((itm) => itm.name === "safety_video")[0];

        const data = {
            // ...asset,
            type: ASSET_TYPE.BUILDING,
            description: asset.description,
            name: asset.name,
            properties: { ...asset.properties },
            parent: selected_asset.id,
        };

        dispatch(openSafetyVideoModal(data));
    };

    const assetColumns = [
        {
            name: i18n.t(k.NAME),
            selector: "name",
            // selector: (row) => `${row.parent_name} / ${row.name}`,
            sortable: true,
            autoWidth: true,
            grow: 2,
            style: `cursor: pointer;`,
        },
        {
            name: "Parent",
            selector: "parent_name",
            sortable: true,
            autoWidth: true,
            grow: 2,
            style: `cursor: pointer;`,
        },
        {
            name: "Has safety video",
            selector: (row) =>
                checkIfVideoAttached(row) ? (
                    <TrueIcon className="text-success" />
                ) : row.type === "safety_video" ? (
                    <span>&mdash;</span>
                ) : (
                    <FalseIcon className="text-danger" />
                ),
            sortable: true,
            autoWidth: true,
            grow: 2,
            style: `cursor: pointer;`,
        },
        {
            name: "Actions",
            selector: (row) => `${checkIfVideoAttached(row)}`,
            cell: (row) =>
                row.type === "safety_video" ? (
                    <span />
                ) : (
                    <Link
                        title="Safety video"
                        id="saftyVideoTip"
                        onClick={(e) => handleAttachSafetyVideo(e, row)}
                        className="pointer"
                        to="#"
                    >
                        <SafetyVideoIcon
                            style={{
                                width: "1rem",
                                height: "1rem",
                                margin: "5px",
                            }}
                        />
                    </Link>
                ),
        },
    ];

    const onAssetRowSelected = (data) => {
        if (
            safetyTrack?.selectedBuildingAssets?.length ===
            data?.selectedRows?.length
        )
            return;

        if (data.selectedRows.length) {
            dispatch(
                setSelectedBuildingAssets(
                    data.selectedRows.filter((item) =>
                        checkIfVideoAttached(item)
                    )
                )
            );
        } else {
            dispatch(setSelectedBuildingAssets([]));
        }
    };

    const fetchChildrenTypes = async () => {
        setLoading(true);
        const resultHandler = (data) => {
            if (data && data.status === "accept") {
                setTypesList(data.asset_types);
                setLoading(false);
            } else {
                NotificationManager.error("Error");
                setTypesList();
                setLoading(false);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setTypesList();
            setLoading(false);
        };
        getAssetTypeList(
            {
                query_application: APP_ID,
                // query_parent: type,
            },
            errorHandler
        )
            .then((data) => {
                return resultHandler(data);
            })
            .catch((error) => {
                console.log("getAssetTypeList: modal: error", error);
            });
    };

    const checkDisableNext = (st) => {
        switch (st) {
            case 0:
                if (!safetyTrack.selectedProperty) return true;
                return false;
            case 1:
                if (!safetyTrack.selectedLandplot) return true;
                return false;
            case 2:
                if (!safetyTrack.selectedBuilding) return true;
                return false;
            case 3:
                return false;
            case 4:
                return false;
            case 5:
                return false;
            default:
                return true;
        }
    };

    const handlePrevStep = () => {
        if (safetyTrack.step > 0) dispatch(setSafetyStep(safetyTrack.step - 1));
    };

    const handleNextStep = () => {
        if (safetyTrack.step < 5) dispatch(setSafetyStep(safetyTrack.step + 1));
    };

    const propertyRowSelectCritera = (row) => {
        if (!safetyTrack?.selectedProperty) return false;
        if (row?._id === safetyTrack?.selectedProperty?._id) return true;
        return false;
    };

    const landplotRowSelectCritera = (row) => {
        if (!safetyTrack?.selectedLandplot) return false;
        if (row?._id === safetyTrack?.selectedLandplot?._id) return true;
        return false;
    };

    const buildingRowSelectCritera = (row) => {
        if (!safetyTrack?.selectedBuilding) return false;
        if (row?._id === safetyTrack?.selectedBuilding?._id) return true;
        return false;
    };

    const assetRowSelectCritera = (row) => {
        const isSelected =
            safetyTrack.selectedBuildingAssets.filter(
                (item) => item.id === row.id
            )?.length > 0;
        return isSelected;
    };

    // TODO: Disable rows without videos attached and add action button to attach
    const assetRowDisableCritera = (row) => {
        return row.type === "safety_video" || !checkIfVideoAttached(row);
    };

    /*
    const getAssetsWithoutVideos = (list) => {
        if (!list?.length) return [];
        return list.filter((item) => item.type !== "safety_video");
    };
    */

    const renderTable = (st) => {
        switch (st) {
            case 0:
                return (
                    <PageableDatatablesComponent
                        key={0}
                        autoWidth={true}
                        columns={propertyColumns}
                        background="#ffffff00"
                        items={safetyTrack.propertyList}
                        loadItems={(page) => {
                            fetchPropertiesList(page);
                        }}
                        progressPending={loading}
                        paginationTotalRows={safetyTrack.propertyTotal}
                        paginationComponentOptions={{
                            rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                            rangeSeparatorText: i18n.t(k.ROWS_OF),
                            noRowsPerPage: false,
                            selectAllRowsItem: false,
                            selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                        }}
                        progressComponent={<Circular />}
                        paginationServer={true}
                        paginationPerPage={PAGE_ITEMS_COUNT}
                        paginationRowsPerPageOptions={[PAGE_ITEMS_COUNT]}
                        onRowClicked={onPropertyRowClicked}
                        selectableRows
                        selectableRowsComponent={Radio}
                        onSelectedRowsChange={(e) =>
                            onPropertyRowSelected(e?.selectedRows[0])
                        }
                        selectableRowSelected={propertyRowSelectCritera}
                    />
                );
            case 1:
                return (
                    <PageableDatatablesComponent
                        key={1}
                        autoWidth={true}
                        columns={landplotColumns}
                        background="#ffffff00"
                        items={safetyTrack.landplotList}
                        loadItems={(page) => {
                            fetchLandplotList(page);
                        }}
                        progressPending={loading}
                        paginationTotalRows={safetyTrack.landplotTotal}
                        paginationComponentOptions={{
                            rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                            rangeSeparatorText: i18n.t(k.ROWS_OF),
                            noRowsPerPage: false,
                            selectAllRowsItem: false,
                            selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                        }}
                        progressComponent={<Circular />}
                        paginationServer={true}
                        paginationPerPage={PAGE_ITEMS_COUNT}
                        paginationRowsPerPageOptions={[PAGE_ITEMS_COUNT]}
                        onRowClicked={onLandplotRowClicked}
                        selectableRows
                        selectableRowsComponent={Radio}
                        onSelectedRowsChange={(e) =>
                            onLandplotRowSelected(e?.selectedRows[0])
                        }
                        selectableRowSelected={landplotRowSelectCritera}
                    />
                );
            case 2:
                return (
                    <PageableDatatablesComponent
                        key={2}
                        autoWidth={true}
                        columns={buildingColumns}
                        background="#ffffff00"
                        items={safetyTrack.buildingList}
                        loadItems={(page) => {
                            fetchBuildingList(page);
                        }}
                        progressPending={loading}
                        paginationTotalRows={safetyTrack.buildingTotal}
                        paginationComponentOptions={{
                            rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                            rangeSeparatorText: i18n.t(k.ROWS_OF),
                            noRowsPerPage: false,
                            selectAllRowsItem: false,
                            selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                        }}
                        progressComponent={<Circular />}
                        paginationServer={true}
                        paginationPerPage={PAGE_ITEMS_COUNT}
                        paginationRowsPerPageOptions={[PAGE_ITEMS_COUNT]}
                        onRowClicked={onBuildingRowClicked}
                        selectableRows
                        selectableRowsComponent={Radio}
                        onSelectedRowsChange={(e) =>
                            onBuildingRowSelected(e?.selectedRows[0])
                        }
                        selectableRowSelected={buildingRowSelectCritera}
                    />
                );
            case 3:
                return (
                    <React.Fragment key={3}>
                        <h6 className="text-muted mt-2">
                            Add safety video to item in order to select it
                        </h6>
                        <PageableDatatablesComponent
                            // key={3}
                            autoWidth={true}
                            columns={assetColumns}
                            background="#ffffff00"
                            items={safetyTrack.buildingAssetsList}
                            loadItems={(page) => {
                                fetchAssetList(page);
                            }}
                            progressPending={loading}
                            paginationTotalRows={
                                safetyTrack.buildingAssetsTotal
                            }
                            paginationComponentOptions={{
                                rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                                rangeSeparatorText: i18n.t(k.ROWS_OF),
                                noRowsPerPage: false,
                                selectAllRowsItem: false,
                                selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                            }}
                            progressComponent={<Circular />}
                            paginationServer={true}
                            paginationPerPage={PAGE_ITEMS_COUNT}
                            paginationRowsPerPageOptions={[PAGE_ITEMS_COUNT]}
                            selectableRows
                            selectableRowsComponent={Checkbox}
                            onSelectedRowsChange={onAssetRowSelected}
                            selectableRowSelected={assetRowSelectCritera}
                            selectableRowDisabled={assetRowDisableCritera}
                        />
                        {safetyTrack.selectedBuildingAssets?.length ? (
                            <Button onClick={handleNextStep}>
                                Continue <ArrowRight />
                            </Button>
                        ) : null}
                    </React.Fragment>
                );
            case 4:
                return <SafetyTrackBuilder />;
            default:
                return "No data";
        }
    };

    return (
        <div>
            <div className="container-fluid">
                <PageHeaderComponent title={"Safety track"} />
            </div>
            <CardWrapperComponent
                header={true}
                footer={true}
                card_title={
                    <SafetyNavigation
                        step={safetyTrack.step}
                        disablePrev={safetyTrack.step === 0}
                        disableNext={checkDisableNext(safetyTrack.step)}
                        onPrev={handlePrevStep}
                        onNext={handleNextStep}
                    />
                }
            >
                {renderTable(safetyTrack.step)}
            </CardWrapperComponent>
        </div>
    );
};

export default SafetyTrack;
