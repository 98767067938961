import i18n from "i18next";
import k from "./../../../i18n/keys";
import React, { Component } from "react";
import "react-notifications/dist/react-notifications.css";
import "react-notifications/dist/react-notifications.js";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import InvoiceStatusItemComponent from "../InvoiceStatusItemComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Col, Container, FormCheckbox, Row } from "shards-react";
import PageableDatatablesComponent from "../../ui_utils/PageableDatatablesComponent";
import InvoicePreviewModal from "../InvoicePreviewModal";
import CardWrapperComponent from "../../ui_utils/CardWrapperComponent";
import PageviewIcon from "@material-ui/icons/Pageview";
import { Link } from "react-router-dom";

import moment from "moment";
import "moment/locale/fi";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

const conditionalRowStyles = [
    {
        when: (row) =>
            row.status !== "paid" && moment(row.due_date).diff(moment()) < 0,
        style: {
            backgroundColor: "#FF0000",
        },
    },

    {
        when: (row) => row.status === "paid",
        style: {
            backgroundColor: "#17a2b8",
        },
    },

    {
        when: (row) => ["over_paid", "error"].indexOf(row.status) >= 0,
        style: {
            backgroundColor: "#ffc107",
        },
    },

    {
        when: (row) => row.status === "approved",
        style: {
            backgroundColor: "#CCCCFF",
        },
    },
];

class InvoicesDatatablesComponent extends Component {
    constructor(props) {
        super(props);
        // this.saveProduct = this.saveProduct.bind(this)
        // this.handleChange = this.handleChange.bind(this)
        this.rowSelected = this.rowSelected.bind(this);
        this.state = {
            buildingId: this.props.buildingId,
            currentDay: this.props.currentDay,
            previewModal: { visible: false, invoice: {} },
        };
    }
    componentDidMount() {
        // alert(this.state.buildingId)
        this.loadInvoices(
            0,
            this.state.currentDay,
            this.state.type,
            this.state.buildingId
        );
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState(nextProps);
        console.error(nextProps);
        this.loadInvoices(
            0,
            nextProps.currentDay ? nextProps.currentDay : this.state.currentDay,
            nextProps.type ? nextProps.type : this.state.type,
            nextProps.buildingId ? nextProps.buildingId : this.state.buildingId
        );
    }
    onPreviewRequestClose = () => {};

    hidePreviewModal = () => {
        this.setState({
            previewModal: { ...this.state.previewModal, visible: false },
        });
        this.onPreviewRequestClose();
    };
    showPreviewModal = (invoice) => {
        this.setState({
            previewModal: {
                ...this.state.previewModal,
                visible: true,
                invoice: invoice,
            },
        });
    };
    loadInvoices(page, date, type, buildingId) {
        let that = this;
        if (!page) page = 0;

        that.setState({ invoices: [], loading: true });
        let loadInvoices = async function () {
            let query = {
                query_type: "invoice",
                query_date: {
                    creation_date: {
                        lt: moment(date ? date : that.props.currentDay)
                            .endOf("month")
                            .format("YYYY-MM-DD hh:mm:ss"),
                        gte: moment(date ? date : that.props.currentDay).format(
                            "YYYY-MM-DD hh:mm:ss"
                        ),
                    },
                },

                query_skip: parseInt(page * 20),
                query_count: 20,
            };

            if (that.props.customQuery) {
                query = { ...query, ...that.props.customQuery };
            } else {
                query["query_properties"] = {
                    building: { eq: buildingId },
                };
            }
            let result = await HonkioAPI().userShop.userFetch(
                "merchantpaymentinvoicelist",
                query
            );
            if (result.status === "accept") {
                // console.log(result);
                // alert(result.payment_invoices.length)
                that.setState({
                    invoices: result.payment_invoices,
                    loading: false,
                });
            }
        };
        loadInvoices();
    }

    getColumnsInvoices() {
        let state = this.state;
        let that = this;
        return [
            {
                name: i18n.t(k.TITLE),
                selector: "title",
                sortable: true,
                style: `cursor: pointer;`,
                width: "10%",
            },

            {
                name: i18n.t(k.TYPE),
                selector: "type",
                sortable: true,
                style: `cursor: pointer;`,
                width: "10%",
            },

            {
                name: i18n.t(k.DUE_DATE),
                selector: "due_date",
                sortable: true,
                style: `cursor: pointer;`,
                width: "15%",
            },

            {
                name: i18n.t(k.PAID_DATE),
                selector: "paid_date",
                sortable: true,
                style: `cursor: pointer;`,
            },

            {
                name: i18n.t(k.AMOUNT),
                selector: "amount",
                sortable: true,
                style: `cursor: pointer;`,
                width: "10%",
            },

            {
                name: i18n.t(k.PAID_AMOUNT),
                selector: "paid_amount",
                sortable: true,
                style: `cursor: pointer;`,
                width: "10%",
            },

            {
                name: i18n.t(k.STATUS),
                // selector: 'status',
                sortable: true,
                style: `cursor: pointer;`,
                width: "10%",
                cell: function (d) {
                    return <span>{d.status}</span>;
                },
            },

            {
                name: i18n.t(k.ACTION),
                // selector: 'status',
                sortable: true,
                style: `cursor: pointer;`,
                width: "10%",
                cell: function (d) {
                    return <InvoiceStatusItemComponent item={d} />;
                },
            },

            {
                name: i18n.t(k.PREVIEW),
                // selector: 'status',
                sortable: true,
                style: `cursor: pointer;`,
                width: "5%",
                cell: function (d) {
                    return (
                        <Link
                            to="#"
                            onClick={(e) => {
                                that.showPreviewModal(d);
                            }}
                        >
                            <PageviewIcon />
                        </Link>
                    );
                },
            },

            {
                name: i18n.t(k.MERCHANT_NAME),
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (d) {
                    return <span>{localStorage.getItem("merchant_name")}</span>;
                },
            },
        ];
    }

    rowSelected(o) {
        console.error("this.props");
        console.error(this.props);
        console.error(this);
        window.location.href = "invoice"
            ? `/invoiceEdit/${o.id}`
            : `/purchaseEdit/${o.id}`;
    }

    render() {
        console.error("this.state.invoices");
        console.error(this.state.invoices);
        return (
            <div>
                <span className="text-muted">{i18n.t(k.FILTERS)}</span>
                <Container>
                    <Row>
                        <Col>
                            <FormCheckbox
                                checked={this.state.orange}
                                onChange={(e) => this.handleChange(e, "orange")}
                            >
                                {i18n.t(k.HIDE_PAID)}
                            </FormCheckbox>
                        </Col>
                        <Col>
                            <span
                                className="mr-1"
                                style={{ backgroundColor: "#FF0000" }}
                            >
                                &nbsp;&nbsp;&nbsp;
                            </span>
                            {i18n.t(k.OVERTIME)}
                        </Col>
                        <Col>
                            <span
                                className="mr-1"
                                style={{ backgroundColor: "#17a2b8" }}
                            >
                                &nbsp;&nbsp;&nbsp;
                            </span>
                            {i18n.t(k.PAID1)}
                        </Col>
                        <Col>
                            <span
                                className="mr-1"
                                style={{ backgroundColor: "#CCCCFF" }}
                            >
                                &nbsp;&nbsp;&nbsp;
                            </span>
                            {i18n.t(k.APPROVED1)}
                        </Col>
                        <Col>
                            <span
                                className="mr-1"
                                style={{ backgroundColor: "#ffc107" }}
                            >
                                &nbsp;&nbsp;&nbsp;
                            </span>
                            {i18n.t(k.OVERPAID_ERROR)}
                        </Col>
                    </Row>
                </Container>
                <PageableDatatablesComponent
                    // title="Employees"
                    className="border"
                    noHeader={true}
                    autoWidth={true}
                    columns={this.getColumnsInvoices()}
                    background="#ffffff00"
                    items={this.state.invoices}
                    // data={data}
                    getTrProps={this.getTrProps}
                    progressPending={this.state.loading}
                    onRowClicked={this.rowSelected}
                    conditionalRowStyles={conditionalRowStyles}
                    loadItems={(page) => {
                        this.loadInvoices(page);
                    }}
                />

                <InvoicePreviewModal
                    visible={this.state.previewModal.visible}
                    open={this.hidePreviewModal}
                    toggle={this.hidePreviewModal}
                    invoice={this.state.previewModal.invoice}
                    onRequestClose={this.onPreviewRequestClose}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoicesDatatablesComponent);
