import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Col, Collapse } from "shards-react";

import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarNavItems from "./SidebarNavItems";

// import { Store } from "../../../flux";
import SidebarItems from "./SidebarItems";
import { toggleSidebar } from "../../actions/action_utils";
import { connect } from "react-redux";

class MainSidebar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menuVisible: false,
            sidebarNavItems: SidebarItems(),
        };

        this.onChange = this.onChange.bind(this);
        this.toggleSidebar = this.toggleSidebar.bind(this);
    }

    componentWillMount() {
        // Store.addChangeListener(this.onChange);
    }

    componentWillUnmount() {
        // Store.removeChangeListener(this.onChange);
    }

    onChange() {
        this.setState({
            ...this.state,
            // menuVisible: Store.getMenuState(),
            // sidebarNavItems: Store.getSidebarItems()
        });
    }

    toggleSidebar() {
        this.setState({
            ...this.state,
            menuVisible: !this.state.menuVisible,
        });
    }

    render() {
        const classes = classNames(
            "main-sidebar",
            "px-0",
            "col-12",
            "text-center"
            // this.state.menuVisible && "open"
        );

        return (
            <Col
                tag="aside"
                className={classes}
                style={{
                    transform: `translateX(${
                        this.props.isSidebarOpen ? "0%" : "-100%"
                    })`,
                }}
                lg={{ size: 2 }}
                md={{ size: 3 }}
            >
                <SidebarMainNavbar
                    hideLogoText={this.props.hideLogoText}
                    toggleSidebar={this.toggleSidebar}
                />
                {/*<SidebarSearch />*/}
                <SidebarNavItems />
            </Col>
        );
    }
}

MainSidebar.propTypes = {
    /**
     * Whether to hide the logo text, or not.
     */
    hideLogoText: PropTypes.bool,
};

MainSidebar.defaultProps = {
    hideLogoText: false,
};

// export default MainSidebar;
function mapStateToProps(state) {
    return { isSidebarOpen: state.navbar.isSidebarOpen };
}

const mapDispatchToProps = (dispatch) => ({
    toggleSidebar: () => dispatch(toggleSidebar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainSidebar);
