import i18n from 'i18next';
import k from "./../../../i18n/keys";
import React, { Component } from 'react';
import { Form, FormGroup, FormSelect, FormInput } from "shards-react";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import FormInputValidation from "../../ui_utils/FormInputValidationComponent";
import { ASSET_TYPE } from './../../../actions/api/assets/config';
import { AGREEMENT_FIELDS } from '../../../../src/components/agreements/subcomponents/config';

class AgreementBasicInfoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.setBuildingOptions = this.setBuildingOptions.bind(this)
  }

  componentDidMount() {
    let that = this;
    let merchantgetbuildings = async function () {
      let responseBuildings = await HonkioAPI().userShop.userFetch('merchantassetlist',
        {
          merchant_id: localStorage.getItem('merchant_id'),
          query_type: ASSET_TYPE.BUILDING
        }, error => {
          that.setState({
            building: {
              buildings: [],
              loading: false
            }
          });
        })
      if (responseBuildings?.status === 'accept' && responseBuildings.assets && responseBuildings.assets.length > 0) {
        let buildings = responseBuildings.assets;
        that.setState({
          buildings: buildings,
          loading: false
        });
        that.setBuildingOptions(buildings)
        if (buildings.length > 0 && !that.props.agreement.rules.building?.value) {
          that.props.handleChangeEvent(
            {
              target: {
                value: buildings[0].id,
                id: AGREEMENT_FIELDS.BUILDING
              }
            }, AGREEMENT_FIELDS.BUILDING);
        }
      } else {
        that.setState({
          building: {
            buildings: [],
            loading: false
          }
        });
      }
    };
    merchantgetbuildings();
  }

  setBuildingOptions(buildings) {
    let buildingsOptions = [];
    let buildingId = this.props.agreement.rules.building?.value
    if (buildings && typeof this.state.buildings !== 'undefined') {
      buildings.forEach(function (building) {
        if (!buildingId) {
          buildingsOptions.push(<option
            key={building.id}
            value={building.id}>{building.name}</option>);
        } else {
          if (building.id === buildingId)
            buildingsOptions.push(<option
              key={building.id}
              value={building.id}>{building.name}</option>);
        }
      });
    }
    this.setState({ buildingOptions: buildingsOptions });
  }

  render() {
    let that = this;
    let getHeader = () => {
      if (that.props.smallerHeader) {
        return (
          <h5>{i18n.t(k.BASIC_INFORMATION)}</h5>
        );
      }
      else {
        return (
          <h4>{i18n.t(k.BASIC_INFORMATION)}</h4>
        )
      }
    }
    return (
      <Form>
        {getHeader()}
        <hr />
        {/*<FormGroup>*/}
        {/*  <label htmlFor="name">Area</label>*/}
        {/*  <Button theme="info" onClick={() => {that.showModal(null)}}><AddIcon className="mr-2"/>Select area</Button>*/}
        {/*</FormGroup>*/}
        <FormGroup>
          <label htmlFor="title">{i18n.t(k.TITLE)}</label>
          <FormInputValidation
            id="title"
            validationType="notNull"
            value={this.props.agreement.name}
            placeholder={i18n.t(k.TITLE)}
            onChange={e => this.props.handleChangeEvent(e)}
            handleValidation={this.props.handleValidation} />
        </FormGroup>
        <FormGroup>
          <label htmlFor="description">{i18n.t(k.DESCRIPTION)}</label>
          <FormInputValidation
            id="description"
            validationType="notNull"
            value={this.props.agreement.description}
            placeholder={i18n.t(k.DESCRIPTION)}
            onChange={e => this.props.handleChangeEvent(e)}
            handleValidation={this.props.handleValidation} />
        </FormGroup>
        <FormGroup>
          <label htmlFor="agreement_id">{i18n.t(k.AGREEMENT_ID)}</label>
          <FormInputValidation
            id="agreement_id"
            validationType="notNull"
            value={this.props.agreement.rules && this.props.agreement.rules.agreement_id ?
              this.props.agreement.rules.agreement_id.value : ''}
            onChange={e => this.props.handleChangeEvent(e, AGREEMENT_FIELDS.AGREEMENT_ID)}
            placeholder={i18n.t(k.AGREEMENT_NUMBER_ID)}
            handleValidation={this.props.handleValidation} />
        </FormGroup>
        <FormGroup>
          <label htmlFor="agreement_contract_type">{i18n.t(k.AGREEMENT_CONTRACT_TYPE)}</label>
          <FormInputValidation
            id="agreement_contract_type"
            validationType="notNull"
            value={this.props.agreement.rules && this.props.agreement.rules.agreement_contract_type ?
              this.props.agreement.rules.agreement_contract_type.value : ''}
            onChange={e => this.props.handleChangeEvent(e, AGREEMENT_FIELDS.CONTRACT_TYPE)}
            // placeholder={i18n.t(k.AGREEMENT_NUMBER_ID)}
            handleValidation={this.props.handleValidation} />
        </FormGroup>
        <FormGroup>
          <label htmlFor="building">{i18n.t(k.BUILDING1)}</label>
          <FormSelect
            id="building"
            onChange={e => this.props.handleChangeEvent(e, AGREEMENT_FIELDS.BUILDING)}>
            {/*  {this.getBuildingSelector()} */}
            {this.state.buildingOptions}
          </FormSelect>
        </FormGroup>
      </Form>);
  }
}

export default AgreementBasicInfoComponent;