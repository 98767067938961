import i18n from "i18next";
import k from "./../i18n/keys";
import React, { Component } from "react";
import { connect } from "react-redux";
import { gettingConsumerInfo } from "../actions/api/consumer/consumerGetActions";
import CardWrapperComponent from "./ui_utils/CardWrapperComponent";
import HonkioAPI from "../middlewares/HonkioAPI";
import Iframe from "react-iframe";
import CenteredPageLoader from "./ui_utils/CenteredPageLoader";

class TouComponent extends Component {
    constructor(props) {
        super(props);
        this.acceptTou = this.acceptTou.bind(this);
        this.state = {};
    }

    // componentDidMount() {
    //   let that = this;
    //   const requestTou = async () => {
    //     let tou = await HonkioAPI().mainShop.userFetch('servergettou', {});
    //     that.setState({ 'touUrl': tou.str_tou_url });
    //   };
    //   requestTou();
    // }

    acceptTou() {
        let that = this;
        this.setState({ loading: true });
        const acceptTou = async () => {
            let tou = await HonkioAPI().mainShop.userFetch("useraccepttou", {});
            // console.log(tou);
            // console.log('tou');
            if (tou.status === "accept") {
                localStorage.setItem("touAccepted", true);
                window.location.href = "/";
            } else {
                that.setState({ loading: false });
            }
        };
        acceptTou();
    }

    render() {
        let iframe;
        if (this.state.loading) {
            return <CenteredPageLoader />;
        }
        if (this.state && this.state.touUrl) {
            iframe = (
                <Iframe
                    url={this.state.touUrl}
                    width="100%"
                    height="600px"
                    display="initial"
                    position="relative"
                    className="m-3"
                />
            );
        }
        return (
            <CardWrapperComponent>
                <h2>{i18n.t(k.TERMS_OF_USE)}</h2>
                <span>{i18n.t(k.PLEASE_ACCEPT_TERMS_OF_USE_IN)}</span>
                {iframe}
                <button className="btn btn-primary" onClick={this.acceptTou}>
                    {i18n.t(k.ACCEPT)}
                </button>
            </CardWrapperComponent>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({
    gettingConsumerInfo: (a) => dispatch(gettingConsumerInfo(a)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TouComponent);
