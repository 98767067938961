import React, { useEffect, useState, useContext } from "react";
import getAsset from "../../actions/api/assets/getAssets";
import useSearchString from "../../utils/useSearchString";
import {
    NotificationManager,
    NotificationContainer,
} from "react-notifications";
import AssetEditForm from "./AssetEditForm";
import getAssetType from "./../../actions/api/assetTypes/getAssetType";
import useLoader from "./../ui_utils/useLoader";
import { updateAssetData } from "./../../actions/api/assets/setAssetData";
import { APP_ID } from "./../../config";
import LoadingBar from "./../ui_utils/LoadingBar";
import CardWrapperComponent from "./../ui_utils/CardWrapperComponent";
import k from "../../i18n/keys";
import i18n from "i18next";
import { getAssetTypeName } from "../../actions/api/assets/config";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { uploadFile } from "../../actions/api/files/uploadFile";
import { blobToFile, getExt } from "./helpers/fileHelper";

const AssetEdit = () => {
    const history = useHistory();
    const [selectedAssetType, setSelectedAssetType] = useState([]);
    const [assetInfo, setAssetInfo] = useState({});
    const { asset: assetId } = useSearchString();
    const [progress, done] = useLoader([assetInfo, selectedAssetType]);

    let isMounted = true;

    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        if (assetInfo?.type_reference) fetchAssetType();
    }, [assetInfo]);

    const fetchAssetType = async () => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept" && data.asset_type) {
                    setSelectedAssetType(data.asset_type);
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };

        getAssetType({ id: assetInfo.type_reference }, errorHandler).then(
            (data) => {
                return resultHandler(data);
            }
        );
    };

    const onSave = (data, streetUrl, assetInfo) => {
        const resultHandler = (data) => {
            // console.log(data,"-----------data save",streetUrl)
            if (data && data.status === "accept") {
                NotificationManager.success("Asset saved", "Success", 4000);
                setAssetInfo(data.asset);
                if (streetUrl && data.asset?._id) {
                    // debugger
                    saveStreetUrl(streetUrl, data.asset);
                } else {
                    // debugger
                    history.push(
                        `/assets/list?type=${selectedAssetType.name}&parent=${data.asset.parent}`
                    );
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };
        /*let assetData
        if (assetInfo) {
            assetData = prepareToPublish(assetInfo, data)
        } else {
            assetData = {
                asset: data,
                merchant: data.merchant
            }
        }*/
        const assetData = {
            asset: data,
            merchant: data.merchant,
        };
        updateAssetData(
            assetData.asset,
            assetData.merchant,
            APP_ID,
            errorHandler
        ).then((data) => {
            return resultHandler(data);
        });
    };

    const saveStreetUrl = async (streetUrl, asset) => {
        let blob = await fetch(streetUrl.url).then((response) => {
            if (!response.ok) {
                throw new Error("Network response ERROR");
            }
            return response.blob();
        });
        blob = blobToFile(blob, "streetView.jpg");
        let filename = blob.name.split(".")[0];
        let fileext = getExt(blob.name);

        const resultHandler = (data) => {
            if (data && data.status === "accept" && data.file?.url) {
                NotificationManager.success(
                    "Street image saved",
                    "Success",
                    4000
                );
                const assetToSave = {
                    ...asset,
                    properties: {
                        ...asset.properties,
                        [streetUrl.name]: data.file.url,
                    },
                };
                onSave(assetToSave);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(
                error.description,
                "Error when saving street image",
                4000
            );
        };
        let options = {
            file: blob,
            "content-type": "multipart/form-data",
            object: asset._id,
            object_type: "asset",
            access: "private",
            file_type: "street_view",
            display_name: filename,
            extension: fileext,
            metadata: {},
        };

        uploadFile(options, errorHandler).then((data) => {
            return resultHandler(data);
        });
    };

    useEffect(() => {
        fetchAsset();
    }, [assetId]);

    const fetchAsset = async () => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    setAssetInfo(data.asset);
                } else {
                    NotificationManager.error("Error");
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setAssetInfo();
        };
        if (assetId) {
            getAsset({ id: assetId }, errorHandler)
                .then((data) => {
                    return resultHandler(data);
                })
                .catch(console.error);
        } else {
            setAssetInfo();
        }
    };
    // console.log(assetId,"-------------assetId")
    return (
        <div>
            <NotificationContainer />
            <br />
            <CardWrapperComponent
                header={true}
                card_title={
                    selectedAssetType?.name
                        ? `${i18n.t(k.EDIT)} ${getAssetTypeName(
                              selectedAssetType.name
                          )}`
                        : `${i18n.t(k.EDIT)}`
                }
            >
                {done ? (
                    assetInfo ? (
                        <AssetEditForm
                            onSubmit={onSave}
                            defaultValues={assetInfo}
                            assetType={selectedAssetType}
                        />
                    ) : (
                        "not found"
                    )
                ) : (
                    <LoadingBar progress={progress} />
                )}
            </CardWrapperComponent>
        </div>
    );
};

export default AssetEdit;
