import React from "react";
import { Button, FormCheckbox } from "shards-react";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";

export default function AddQuestion() {
  return (
    <div>
      <br />
      <div className="container-fluid"></div>
      <CardWrapperComponent
        header={true}
        footer={true}
        card_title="Add Questions"
      >
        <form className="ml-33">
          <div className="form-group" style={{width: 436}}>
            <textarea
              id="Question"
              className="form-control"
              placeholder="Question text here"
              // value={this.state.textAreaValue}
              // onChange={this.handleChange}
              rows={4}
            />
          </div>
          <div className="form-group w-50" style={{ display: "flex" }}>
            <input
              id="Question1"
              className="form-control"
              placeholder="Answer Option One"
            />
            <FormCheckbox
              className=" m-2"
              id="QuestionAns1"
              theme="warning"
              checked="true"
            ></FormCheckbox>
          </div>
          <div className="form-group w-50" style={{ display: "flex" }}>
            <input
              id="Question2"
              className="form-control"
              placeholder="Answer Option Two"
            />
            <FormCheckbox
              className=" m-2"
              id="QuestionAns2"
              theme="warning"
              checked={false}
            ></FormCheckbox>
          </div>
          <div className="form-group w-50" style={{ display: "flex" }}>
            <input
              id="Question3"
              className="form-control"
              placeholder="Answer Option Three"
            />
            <FormCheckbox
              className=" m-2"
              id="QuestionAns3"
              theme="warning"
              checked={false}
            ></FormCheckbox>
          </div>
          <Button>Preview Questions</Button>
          <Button className="ml-2">Save Question</Button>
        </form>
      </CardWrapperComponent>
    </div>
  );
}
