import i18n from "i18next";
import k from "./../../../i18n/keys";
import React, { Component } from "react";
import { Button, Form, FormGroup, FormSelect } from "shards-react";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import TenantEditModalComponent from "../../tenants/TenantEditModalComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForever from "@material-ui/icons/DeleteForever";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";

class AgreementTenantComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: {
                visible: false,
                tenant: null,
            },
        };
        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.toggle = this.toggle.bind(this);
        this.loadRootTenants = this.loadRootTenants.bind(this);
        this.handleContractOwnerSelected =
            this.handleContractOwnerSelected.bind(this);
        this.loadChildsTenants = this.loadChildsTenants.bind(this);
    }

    hideModal() {
        this.setState({ modal: { ...this.state.modal, visible: false } });
    }

    showModal(d) {
        this.setState({
            modal: {
                ...this.state.modal,
                visible: true,
                tenant: d,
            },
        });
    }

    toggle() {
        this.setState({
            modal: {
                ...this.state.modal,
                visible: !this.state.modal.visible,
            },
        });
    }

    confirmDelete(tenant) {
        let that = this;
        const options = {
            title: "Destructive action",
            message: "",
            childrenElement: () => (
                <p>
                    {i18n.t(k.ARE_YOU_SURE_WANT_TO_DELETE1)}{" "}
                    <b>{tenant.name}</b>
                    {i18n.t(k._4)}
                </p>
            ),
            buttons: [
                {
                    label: i18n.t(k.YES),
                    onClick: () => {
                        const del = async () => {
                            that.setState({ loading: true });
                            let response = await HonkioAPI().userShop.userFetch(
                                "merchanttenantset",
                                {
                                    id: tenant.id,
                                    delete: true,
                                }
                            );

                            if (response.status === "accept") {
                                NotificationManager.success(
                                    i18n.t(k.LOADING),
                                    i18n.t(k.TENANT_HAS_BEEN_REMOVED)
                                );
                            } else {
                                NotificationManager.success(
                                    `${response.description}`,
                                    i18n.t(k.DELETE_FAILED)
                                );
                            }
                            that.loadTenants();
                        };
                        del();
                    },
                },
                {
                    label: i18n.t(k.NO),
                    onClick: () => {},
                },
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        };
        confirmAlert(options);
    }

    getColumns() {
        let that = this;
        return [
            {
                name: i18n.t(k.NAME),
                selector: "name",
                sortable: true,
                style: `cursor: pointer;`,
                width: "30%",
            },
            {
                name: i18n.t(k.EMAIL),
                selector: "email",
                sortable: true,
                style: `cursor: pointer;`,
                width: "30%",
            },
            {
                name: i18n.t(k.PHONE),
                selector: "phone",
                sortable: true,
                style: `cursor: pointer;`,
                width: "30%",
            },
            {
                name: i18n.t(k.ACTION),
                selector: "action",
                cell: function (d) {
                    return (
                        <span>
                            <Link
                                to="#"
                                className="mr-3 pointer"
                                onClick={() => {
                                    that.showModal(d);
                                }}
                            >
                                <EditIcon />
                            </Link>
                            <Link
                                to="#"
                                className="pointer"
                                onClick={() => {
                                    that.confirmDelete(d);
                                }}
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ];
    }

    loadRootTenants() {
        let that = this;
        let merchanttenants = async function () {
            let tenants = [];
            let getmerchantroles = await HonkioAPI().userShop.userFetch(
                "getmerchantroles",
                {
                    query_access: false,
                    query_sort: [{ access: -1 }, { name: 1 }],
                }
            );
            let roles = getmerchantroles.roles;
            if (roles.length > 0) {
                let roles_ids = [];
                roles.forEach((role) => {
                    if (["tenant"].includes(role.name)) {
                        roles_ids.push(role.role_id);
                    }
                });
                let merchantlistuserroles =
                    await HonkioAPI().userShop.userFetch(
                        "merchantlistuserroles",
                        { query_roles: true, roles: roles_ids }
                    );
                tenants = merchantlistuserroles.consumers;
            }
            that.setState({ contractOwners: tenants, loading: false });
        };
        merchanttenants();
    }

    loadChildsTenants(child_id) {
        let that = this;
        let merchanttenants = async function () {
            let response = await HonkioAPI().userShop.userFetch(
                "merchanttenantlist",
                {
                    merchant_id: localStorage.getItem("merchant_id"),
                    parent_id: child_id,
                }
            );
            let residents = response.tenants;
            that.setState({ residents: residents, loading: false });
        };
        merchanttenants();
    }

    componentDidMount() {
        this.loadRootTenants();
    }

    handleChange(e) {
        this.setState({
            tenant: {
                ...this.state.tenant,
                [e.target.id]: e.value,
            },
        });
    }

    handleContractOwnerSelected(e) {
        this.setState({ contract_owner: e.target.value });
        this.loadChildsTenants(e.target.value);
        let mainTenant = {};
        mainTenant["email"] =
            e.target[e.target.selectedIndex].getAttribute("email");
        mainTenant["name"] =
            e.target[e.target.selectedIndex].getAttribute("name");
        mainTenant["id"] = e.target.value;
        let residents = [];
        if (this.state.residents) {
            this.state.residents.forEach(function (resident) {
                residents.push(resident._id);
            });
            this.props.handleTenantsChange(mainTenant, residents);
        }
        this.props.handleTenantsChange(mainTenant, residents);
    }

    getContracOwnerSelector() {
        let contractOwners = [];
        if (
            this.state.contractOwners &&
            typeof this.state.contractOwners !== "undefined"
        ) {
            this.state.contractOwners.forEach(function (contractOwner) {
                contractOwners.push(
                    <option
                        key={contractOwner._id}
                        value={contractOwner.id}
                        email={contractOwner.email}
                        name={`${contractOwner.user_first_name} ${contractOwner.user_last_name}`}
                    >
                        {`${contractOwner.user_first_name} ${contractOwner.user_last_name}`}
                    </option>
                );
            });
        }
        return contractOwners;
    }

    render() {
        let that = this;
        if (this.props.agreement.reservable === true) {
            return (
                <div>
                    <h4>{i18n.t(k.TENANTS)}</h4>
                    <hr />
                    <h5>{i18n.t(k.TENANT1)}</h5>
                    <Form>
                        <FormGroup>
                            <FormSelect
                                id="tenant_name"
                                onChange={(e) => {
                                    that.handleContractOwnerSelected(e);
                                }}
                            >
                                <option value="" name="" email="">
                                    {i18n.t(k.NOTHING_SELECTED)}
                                </option>
                                {this.getContracOwnerSelector()}
                            </FormSelect>
                        </FormGroup>
                    </Form>
                </div>
            );
        }

        // let residents;
        // if (this.state.residents) {
        //     residents = <div><h5>Roommates:</h5>
        //         <span>If you want to add new roommate, please, add new tenant with root tenant (agreement owner)</span>
        //         <DataTable
        //             autoWidth={true}
        //             columns={this.getColumns()}
        //             background="#ffffff00"
        //             data={this.state.residents}
        //             progressPending={this.state.loading}
        //             progressComponent={<Circular/>}
        //         />
        //     </div>
        // }

        let getHeader = () => {
            if (that.props.smallerHeader) {
                return <h5>{i18n.t(k.TENANTS)}</h5>;
            } else {
                return <h4>{i18n.t(k.TENANTS)}</h4>;
            }
        };
        return (
            <div>
                {getHeader()}
                <hr />
                <Button
                    theme="primary"
                    className="mb-4"
                    onClick={() => {
                        that.showModal();
                    }}
                >
                    {i18n.t(k.CREATE_NEW_TENANT)}
                </Button>
                <Form>
                    <FormGroup>
                        <label htmlFor="tenant_name">{i18n.t(k.TENANT1)}</label>
                        <FormSelect
                            id="tenant_name"
                            onChange={(e) => {
                                that.handleContractOwnerSelected(e);
                            }}
                        >
                            <option value="" name="" email="">
                                {i18n.t(k.NOTHING_SELECTED)}
                            </option>
                            {this.getContracOwnerSelector()}
                        </FormSelect>
                    </FormGroup>
                </Form>
                <TenantEditModalComponent
                    style={{ top: "40%" }}
                    visible={this.state.modal.visible}
                    open={this.hideModal}
                    toggle={this.toggle}
                    tenant={this.state.modal.tenant}
                    onRequestClose={this.props.onRequestClose}
                    contractOwners={this.state.contractOwners}
                    onTenantSaved={() => {
                        that.setState({ loading: true });
                        that.hideModal();
                        that.loadRootTenants();
                    }}
                />
            </div>
        );
    }
}

export default AgreementTenantComponent;
