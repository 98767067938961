import i18n from 'i18next';
import k from "./../../i18n/keys";
import React, {Component} from 'react';
import 'react-notifications/dist/react-notifications.css';
import 'react-notifications/dist/react-notifications.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Button, ButtonGroup, Modal, ModalBody, ModalHeader} from "shards-react";
import DataTable from "react-data-table-component";
import moment from "moment";
import TenantDetailsModalComponent from "../tenants/TenantDetailsModalComponent";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';


const Circular = () =>
// we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{padding: '24px'}}>
        <CircularProgress size={75}/>
    </div>;

// Fixme unify with userlistcomponent

class AreaTenantsListModalComponent extends Component {
    constructor(props) {
        super(props);
        this.getColumns = this.getColumns.bind(this);
        this.state = {}
    }


    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.tenants) {
            this.setState({'tenants': nextProps.tenants, 'mode': 'list'});
        }
    }


    getColumns() {
        let state = this.state;
        let that = this;
        return [
            {
                name: 'Tenant name',
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (d) {
                    return <span>{d.tenant_name}</span>;
                }
            },
            {
                name: 'Agreement length',
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (d) {
                    let length;
                    let start = d.start_date;
                    let end = d.end_date;
                    length = parseInt(moment(end).diff(moment(start), 'months', true))
                    return <span>{length} Month</span>;
                }
            },
            {
                name: 'Agreement duration',
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (d) {
                    let length;
                    let start = d.start_date
                    let end = d.end_date
                    length = parseInt(moment(end).diff(moment(), 'months', true))
                    return <span>{length} Month</span>;
                }
            },
            {
                name: 'Termination date',
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (d) {
                    let length;
                    length = moment(d.end_date).format()

                    return <span>{length}</span>;
                }
            },
            {
                name: 'Tenant rating',
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (d) {
                    let name = 'N/A';
                    return <span>-</span>;
                }
            }
        ];


    }

    rowClicked = (d) => {
        this.setState({'mode': 'view', 'email': d.tenant_email});
    }

    render() {

        let that = this;
        return (
            <Modal open={this.props.visible}
                   toggle={this.props.toggle}
                   size="lg"
                   onRequestClose={this.props.onRequestClose}
                   className="w-100">
                <ModalHeader>{i18n.t(k.TENANTS)}</ModalHeader>
                <ModalBody>
                    {this.state.mode === 'view' ?
                        <div className="w-100 h-100">
                            <span className="pointer text-primary mb-5" onClick={() => {that.setState({'mode': 'list'})}}><KeyboardBackspaceIcon/></span>
                        <TenantDetailsModalComponent
                            email={this.state.email}/>
                        </div>:
                    <DataTable
                        noHeader={true}
                        title={i18n.t(k.ITEMS)}
                        columns={this.getColumns()}
                        background="#ffffff00"
                        data={this.state.tenants}
                        noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                        paginationComponentOptions={
                            {
                                rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                                rangeSeparatorText: i18n.t(k.ROWS_OF),
                                noRowsPerPage: false,
                                selectAllRowsItem: false,
                                selectAllRowsItemText: i18n.t(k.ROWS_ALL)
                            }
                        }
                        onRowClicked={this.rowClicked}
                        progressComponent={<Circular/>}/>}
                </ModalBody>
            </Modal>
        );
    }
}


export default AreaTenantsListModalComponent;
