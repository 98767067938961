import {
    OAUTH_CLIENT_ID,
    OAUTH_CLIENT_SECRET,
    OAUTH_REDIRECT_URL,
    OAUTH_TOKEN_URL,
    REDIRECT_URL,
} from "../../config";
import { gettingConsumerInfo } from "../api/consumer/consumerGetActions";

const isAuthenticating = () => ({
    type: "IS_AUTHENTICATING",
});

function userLoginSuccess(json) {
    return async function (dispatch) {
        // localStorage.setItem("token", json.access_token);
        // localStorage.setItem("refresh_token", json.refresh_token);
        // let expiryDate = Math.round(new Date().getTime() / 1000) + json.expires_in;
        // localStorage.setItem("token_expires_in", expiryDate);

        /* 
        try {
            dispatch(gettingConsumerInfo(json.access_token));
        } catch (error) {
            console.log("***** Error gettingCounsumerInfo", error);
        }
        */
        window.location.href = "/";
        return {
            type: "USER_AUTH_SUCCESS",
            token: json,
        };
    };
}

function refreshTokenSuccess(json) {
    localStorage.setItem("token", json.access_token); //ugBtIdpZ1ln3F4xQVg7nClmznAeFKF
    localStorage.setItem("refresh_token", json.refresh_token); //rMBpNVNsA7rXOkkh5PvuOXjjc5pu8T
    let expiryDate = Math.round(new Date().getTime() / 1000) + json.expires_in;
    localStorage.setItem("token_expires_in", expiryDate); //1658838517
    return {
        type: "REFRESH_TOKEN_SUCCESS",
        token: json,
    };
}

const userLoginFailure = (err) => ({
    type: "USER_AUTH_FAILURE",
    err,
});

function tokenRefresh(refresh_token) {
    return async function (dispatch) {
        // return async function (dispatch) {
        // console.log('REFRESH TOKEN ACTION!!!');

        dispatch(isAuthenticating());

        try {
            let REFRESH_URL = `${OAUTH_TOKEN_URL}?grant_type=authorization_code&code=${refresh_token}&client_id=${OAUTH_CLIENT_ID}&client_secret=${OAUTH_CLIENT_SECRET}&redirect_uri=http%3A%2F%2Flocalhost%3A5000%2Fauthorized`;
            // console.log('REFRESH_URL', REFRESH_URL);
            let response = await fetch(REFRESH_URL, {
                // let response = await fetch(OAUTH_TOKEN_URL, {

                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },

                // body: JSON.stringify(body_object)
            });
            if (!response.ok) {
                throw new Error("An Error has occurred, please try again.");
            }

            let json = await response.json();
            // console.log('RESPONSE OAUTH REFRESH TOKEN JSON');
            // console.log(json);

            localStorage.setItem("token", json.access_token);
            localStorage.setItem("refresh_token", json.refresh_token);
            let expiryDate =
                Math.round(new Date().getTime() / 1000) + json.expires_in;
            localStorage.setItem("token_expires_in", expiryDate);
            // console.log('AFTER AUTH REDIRECT');
            // dispatch(replace("/"))

            return dispatch(userLoginSuccess(json));
        } catch (err) {
            console.error("err");
            console.error(err);
            return dispatch(userLoginFailure(err));
        }
    };
}

function authUser(code) {
    console.log("AUTH USER FUNCTION ACTION INIT!!!");
    return async function (dispatch) {
        // return async function (dispatch) {
        console.log("AUTH USER FUNCTION ACTION RETURN!!!"); // shut up

        dispatch(isAuthenticating());

        try {
            const formData = new FormData();
            formData.append("grant_type", "authorization_code");
            formData.append("code", code);
            formData.append("client_id", OAUTH_CLIENT_ID);
            formData.append("client_secret", OAUTH_CLIENT_SECRET);
            formData.append("redirect_uri", OAUTH_REDIRECT_URL);

            let response = await fetch(OAUTH_TOKEN_URL, {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                throw new Error("An Error has occurred, please try again.");
            }
            let json = await response.json();
            console.log("RESPONSE OAUTH TOKEN JSON");
            console.log(json);

            localStorage.clear();
            localStorage.setItem("token", json.access_token);
            localStorage.setItem("refresh_token", json.refresh_token);
            let expiryDate =
                Math.round(new Date().getTime() / 1000) + json.expires_in;

            localStorage.setItem("token_expires_in", expiryDate);
            // console.log('AFTER AUTH REDIRECT');

            return dispatch(userLoginSuccess(json));
        } catch (err) {
            console.error(err);
            return dispatch(userLoginFailure(err));
        }
    };
}

function LogoutAction() {
    return function (dispatch) {
        dispatch({ type: "LOGOUT" });
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("token_expires_in");
        return Promise.resolve();
    };
}

function RefreshTokenSuccess(json) {
    localStorage.setItem("token", json.access_token);
    localStorage.setItem("refresh_token", json.refresh_token);
    let expiryDate = Math.round(new Date().getTime() / 1000) + json.expires_in;
    localStorage.setItem("token_expires_in", expiryDate);
    return {
        type: "REFRESH_TOKEN_SUCCESS",
        token: json,
    };
}

export const STORAGE = {
    MERCHANT_ID: "merchant_id",
    ROLE_ID: "modeID",
    /*ROLE_ID: 'modeID',
    ROLE_NAME: 'mode',
    ROLE_DISPLAY_NAME: 'roleDisplayName',
  
    SHOP_ID: 'shop',
    SHOP_PSWRD: 'shop_password',
    SHOP_NAME: 'merchant_name',
    TOKEN: 'token',
    REFRESH_TOKEN: 'refresh_token',
    TOKEN_EXPIRES_IN: 'token_expires_in',
    DEVICE: 'device',
    TOU_ACCEPTED: 'touAccepted',
    ORDER_MODEL: 'orderModel', 
    LNG: 'i18nextLng',
    TOTAL_AMOUNT: 'total_amount',
    START_DATE: 'start_date',
    END_DATE: 'end_date',
    NUMBER_OF_MONTH: 'number_of_month',
    DIFF: 'diff',
    SHARE_HOLDER_ID: 'shareholderId',
    MERCHANTS: 'merchants',
    IS_MERCHANT_MODE: 'is_merchantmode'*/
};

export {
    authUser,
    userLoginSuccess,
    userLoginFailure,
    isAuthenticating,
    tokenRefresh,
    LogoutAction,
    RefreshTokenSuccess,
    refreshTokenSuccess,
};
