import i18n from 'i18next';import k from "./../../../i18n/keys";import React, { Component } from 'react';
import { Button, Form, FormGroup, FormInput, Modal, ModalBody, ModalHeader } from "shards-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import Select from "react-select";
import DatePickerComponent from "../../ui_utils/DatePickerComponent";
import getValues from "./../product_type_utils";

class AssetEditModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'asset': {
        name: '',
        "value": "",
        "start_date": "" } };


    this.save = this.save.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // if (nextProps.asset !== null) {
    //     this.setState({'asset': nextProps.project})
    // }
    if (nextProps.buildingId !== null) {
      this.setState({ 'buildingId': nextProps.buildingId });
    }
  }

  save() {
    let that = this;
    this.setState({ 'loading': true });
    let saveAsset = async function () {
      let params = {
        'name': that.state.asset.name,
        'value': that.state.asset.value,
        'last_check_day': that.state.asset.start_date,
        'class': that.state.asset.class,
        'building_id': that.state.buildingId };

      if (that.state.asset.id) {
        params['id'] = that.state.asset.id;
      }

      let response = await HonkioAPI().userShop.userFetch('merchantbuildingassetset', params);
      // fixme check status
      that.setState({ 'loading': false });
      that.props.onAssetSaved();
    };
    if (this.state.buildingId) {
      saveAsset();
    }
  }

  handleChange(e) {
    this.setState({ 'asset': { ...this.state.asset, [e.target.id]: e.target.value } });
  }

  componentDidMount() {
    this.loadMerchant();
  }

  loadMerchant() {
    let that = this;
    let merchantGet = async function () {
      let response = await HonkioAPI().userShop.userFetch('merchantget', {});
      let newState = {
        'merchant': response.merchant,
        'loading': false,
        'columns': response.merchant.list_product_categories_groups[0] };
      that.setState(newState);
      console.error('response');
      console.error(response);
    };
    merchantGet();
  }

  render() {
    let saveButton = <Button onClick={this.save}>{i18n.t(k.SAVE)}</Button>;
    if (this.state.loading) {
      saveButton = <CircularProgress size={45} />;
    }
    let options = [];
    if (this.state.columns) {
      let data = this.state.columns;
      let basic = this.state.columns[0].items;
      let clone = Object.assign({}, data, { 0: undefined });
      Object.keys(basic).forEach(function (key) {
        options.push({ value: `${basic[key].id}`, label: `${basic[key].content}` });
      });
      for (const [key, value] of Object.entries(clone)) {
        if (value !== undefined) {
          options.push({ value: key, label: `${value.name} (${key})` });
        }
      }
    } else {
        options = getValues()
    }
    return (
      <Modal open={this.props.visible}
      toggle={this.props.toggle}
      onRequestClose={this.props.onRequestClose}
      className="w-100 modal-lg modal-dialog-centered modal-dialog-scrollable">
                <ModalHeader>{this.props.asset === null ? i18n.t(k.CREATE) : i18n.t(k.EDIT)}</ModalHeader>
                <ModalBody>
                    <Form>
                        <DatePickerComponent
            endSelector={false}
            dates={false}
            endDateLabel=""
            startDateLabel="Last check day"
            handleChangeEvent={
            e => {
              e.target['id'] = e.target.name;
              switch (e.target.name) {
                case 'start_date':
                  this.handleChange(e);
                  break;}

            }} />


                        <FormGroup>
                            <label htmlFor="name">{i18n.t(k.NAME)}</label>
                            <FormInput id="name"
              value={this.state.asset.name}
              onChange={e => this.handleChange(e)} />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="type">{i18n.t(k.TYPE)}</label>
                            <Select id="type" options={options} defaultValue={() => {
                let vals = getValues();
                for (var i = 0; i < vals.length; i++) {
                  if (vals[i].id === this.state.product.class) {
                    return vals[i];
                  }
                }
              }} onChange={e => {
                this.setState({
                  'asset': {
                    ...this.state.asset,
                    'class': e.value } });


              }} />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="value">{i18n.t(k.VALUE)}</label>
                            <FormInput id="value"
              value={this.state.asset.value}
              onChange={e => this.handleChange(e)} />
                        </FormGroup>

                        {saveButton}
                    </Form>
                </ModalBody>
            </Modal>);

  }}


export default AssetEditModalComponent;
