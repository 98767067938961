import i18n from 'i18next';import k from "./../../i18n/keys";import React, { Component } from 'react';
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataTable from "react-data-table-component";
import AddIcon from '@material-ui/icons/Add';
import {Button, ButtonGroup, Breadcrumb, BreadcrumbItem, FormInput, FormGroup} from "shards-react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import moment from 'moment';
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";
import ListIcon from '@material-ui/icons/List';

const Circular = () =>
// we need to add some padding to circular progress to keep it from activating our scrollbar
<div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>;

const NAMES_MAP = {
    office: 'Office',
    business_premices: 'Business premices'
}

const BULLSHIT_DATA = {
    analysis_day: '2019-09-17 00:00:00',
    currency: 'EUR',
    dcf_table: [
        {
            'type': 'Estimated rental value',
            'highlight': true,
            'eur_per_m2': 12.05,
            'analysis_day': 14.00,
            'year1': 1.01,
            'year2': 10001104547.99,
            'year3': 0.00,
            'year4': 2.22,
            'year5': 2000.00,
        },
        {
            'type': 'Over/under rents',
            'eur_per_m2': '-',
            'analysis_day': 14.00,
            'year1': 14.00,
            'year2': 14.00,
            'year3': 14.00,
            'year4': 14.00,
            'year5': 2000.00,
        },
        {
            'type': 'Other income',
            'eur_per_m2': '-',
            'analysis_day': 14.00,
            'year1': 15.00,
            'year2': 16.00,
            'year3': 17.00,
            'year4': 18.00,
            'year5': 2000.00,
        },
        {
            'type': 'Potential rental income',
            'highlight': true,
            'eur_per_m2': 7,
            'analysis_day': 14.00,
            'year1': 15.00,
            'year2': 16.00,
            'year3': 17.00,
            'year4': 18.00,
            'year5': 2000.00,
        },
    ],
    calcs_table: [
        {
            'yield': 15.00,
            'cap_rate': 10.00
        },
    ]
}

class DirectCashFlowComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      costs: [],
      inflation: 5,
      data: [],
      modal: {
        visible: false,
        floorplan: null } };

    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.getForecast = this.getForecast.bind(this);
    this.onTableDataChange = this.onTableDataChange.bind(this);
    this.calculateData = this.calculateData.bind(this);
  }

  hideModal() {
    this.setState({ 'modal': { ...this.state.modal, visible: false } });
  }

  showModal(building) {
    this.setState({ 'modal': { ...this.state.modal, visible: true, building: building } });
  }

  toggle() {
    this.setState({ 'modal': { ...this.state.modal, visible: !this.state.modal.visible } });
  }

  componentDidMount() {
    let inflation = this.props.inflation ? this.props.inflation : 5
    this.setState({ 
        data: BULLSHIT_DATA.dcf_table, 
        calcs_data: BULLSHIT_DATA.calcs_table,
        costs: this.props.costs,
        inflation: inflation,
        loading: false });
  }

  calculateData() {
      let the = this;
      let data = BULLSHIT_DATA.dcf_table;   // we need to keep some solid data to make calculations
      let calcs_data = the.state.calcs_data[0];
      for (var item of data) {
          for (const field in item) {
            if (field.startsWith('year')) {
                item[field] = ((item[field] * (calcs_data.yield * 0.01)) / calcs_data.cap_rate).toFixed(2)
            }
          }
      }
      the.setState({data: data})
  }

  onTableDataChange(e, label=null) {
    if (!parseFloat(e.target.value)) {
        return
    }
    let calcs_data = this.state.calcs_data;
    let data = calcs_data[0];
    calcs_data[0] = {
        ...this.state.calcs_data[0],
        [e.target.id]: parseFloat(e.target.value)
    };
    this.setState({calcs_data: calcs_data
    });
    this.calculateData()
  }

  getForecast() {
    let allCosts = 0;
    let years = 0;
    let data = this.state.data
    if (!data) {
        return {}
    }
    let forecastData = {}
    for (const item in data[0]) {
        if (item.startsWith('year')) {
            years += 1
        }
    }
    const inflation = this.state.inflation
    this.state.costs.forEach((k) => {
        for (const header in k.data) {
            allCosts += parseFloat(k.data[header])
        }
    });

    let avgCosts = this.state.costs ? Math.abs(allCosts / this.state.costs.length) : 0

    let currentForecast = avgCosts
    let year = 1;
    for (var m=0; m < years; m++) {
        currentForecast += (currentForecast *  parseFloat(0.01 * inflation)) / 12
        forecastData['year' + year] = currentForecast.toFixed(2)
        year += 1
    }
    return ({
        'type': 'Costs forecast',
        'eur_per_m2': '-',
        'analysis_day': '-',
        ...forecastData
    })
  }

  getColumns() {
    let that = this;
    let analysisDay = new Date(BULLSHIT_DATA.analysis_day)
    
    let columns = [
        {
            name: BULLSHIT_DATA.currency ? BULLSHIT_DATA.currency : '',
            selector: 'type',
            // style: `min-width: 200px;`,
        },
        {
            name: 'EUR/m2/kk',
            selector: 'eur_per_m2',
            // style: `cursor: pointer;`,
        },
        {
            name: 'Analysis day\n' + moment(analysisDay).format('L'),
            selector: 'analysis_day',
            // style: `cursor: pointer;`,
        },
    ];
    let i = 1;
    let lastDay = analysisDay;
    if (BULLSHIT_DATA.dcf_table) {
        for (const fieldName in BULLSHIT_DATA.dcf_table[0]) {
            if (fieldName.startsWith('year')) {
                let nextDay = moment(lastDay).add(1, 'days')
                lastDay = moment(nextDay).add(12, 'months')
                columns.push({
                    name: 'Year ' + i.toString() + '\n' + nextDay.format('L') + '\n' + lastDay.format('L'),
                    selector: fieldName
                });
                i += 1
            }
    }
    
    }
    return columns;
  }

  getCalcColumns() {
      return ([
          {
              name: 'Yield, %',
              selector: 'yield',
              cell: () => {return (<FormGroup>
                <FormInput id="yield"
                           value={this.state.calcs_data[0].yield}
                           onChange={e => this.onTableDataChange(e)}
                           />
                </FormGroup>)}
          },
          {
              name: 'Cap rate, %',
              selector: 'cap_rate',
              cell: () => {return (<FormGroup>
                <FormInput id="cap_rate"
                           value={this.state.calcs_data[0].cap_rate}
                           onChange={e => this.onTableDataChange(e)}
                           />
                </FormGroup>)}
          },
      ])
  }

  getTables() {
    let that = this;
    let forecastData = this.getForecast()
    let data = this.state.data
    let calcs_data = this.state.calcs_data
    data.push(forecastData)
    return (
        <div>
        <div>
                <h4>{'Direct Cash Flow'}</h4>
                <DataTable
          autoWidth={true}
          columns={this.getColumns()}
          background="#ffffff00"
          data={data}
          noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
          paginationComponentOptions={
              { rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                rangeSeparatorText: i18n.t(k.ROWS_OF),
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: i18n.t(k.ROWS_ALL) }
            }
          progressPending={this.state.loading}
          progressComponent={<Circular />} />
                
                
            </div>
            <h4>Yield requirements</h4>
            <div className='small_table_container'>
            <DataTable
    noHeader
    autoWidth={true}
    columns={this.getCalcColumns()}
    background="#ffffff00"
    data={calcs_data}
    noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
    paginationComponentOptions={
        { rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
            rangeSeparatorText: i18n.t(k.ROWS_OF),
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: i18n.t(k.ROWS_ALL) }
        }
    progressPending={this.state.loading}
    progressComponent={<Circular />} />
        </div>
        </div>
    )
  }

  render() {
    let that = this;
    
    return (
      <div>
        {this.getTables()}
      </div>);

  }}


function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = dispatch => ({});


export default connect(mapStateToProps, mapDispatchToProps)(DirectCashFlowComponent);
