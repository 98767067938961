import React, { Component } from "react";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import i18n from "i18next";
import k from "./../../i18n/keys";
import { Button } from "shards-react";
import SaveIcon from "@material-ui/icons/Save";
import DataTable from "react-data-table-component";
import {
    Add,
    Edit,
    DeleteForever,
    HelpOutline,
    ScannerOutlined,
} from "@material-ui/icons";

import "react-confirm-alert/src/react-confirm-alert.css";
import Upload from "./Upload";
import { Link } from "react-router-dom";

class AddVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            portfolios: [
                {
                    _id: "62444844ddc15f927ccbc996",
                    name: "Paikka 1",
                },
                {
                    _id: "62445d796d12e7a297d6bc5b",
                    name: "Paikka 12",
                },
                {
                    _id: "6255b013680a295365fe66f7",
                    name: "Paikka 13",
                },
                {
                    _id: "6256d206a7a1a8c471fca8c4",
                    name: "Paikka 14",
                },
            ],
            modal: {
                visible: false,
                portfolio: null,
            },
        };
    }
    getColumns() {
        let that = this;
        return [
            {
                name: i18n.t(k.NAME),
                selector: "name",
                sortable: true,
                style: `cursor: pointer;`,
            },
            {
                name: i18n.t(k.ACTION),
                selector: "action",
                cell: function (d) {
                    return (
                        <span>
                            <Link
                                to="#"
                                className="pointer"
                                title={i18n.t(k.PORTFOLIO_EDIT)}
                                onClick={() => {
                                    that.showModal(d);
                                }}
                            >
                                <Edit />
                            </Link>
                            <Link
                                to={`/portfolio/${d.id}`}
                                className="pointer"
                                title={i18n.t(k.PORTFOLIO_VIEW)}
                            >
                                <HelpOutline />
                            </Link>
                            <Link
                                to={`/portfolio/${d.id}`}
                                className="pointer"
                                title={i18n.t(k.PORTFOLIO_VIEW)}
                            >
                                <ScannerOutlined />
                            </Link>
                            <Link
                                to="#"
                                className="pointer"
                                title={i18n.t(k.PORTFOLIO_DELETE)}
                                onClick={() => {
                                    that.confirmDelete(d);
                                }}
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ];
    }

    render() {
        let that = this;
        return (
            <div>
                <br />
                <div className="container-fluid"></div>
                <CardWrapperComponent
                    header={true}
                    footer={true}
                    card_title={i18n.t(k.ADD_VIDEO)}
                >
                    <form className="ml-33" style={{ marginLeft: 370 }}>
                        <div className="form-group w-50">
                            <input
                                id="vName"
                                className="form-control"
                                placeholder="Video name"
                            />
                        </div>
                        <div className="form-group w-50">
                            <input
                                id="instruct"
                                className="form-control"
                                placeholder="Instruction to next page"
                            />
                        </div>
                        <div className="form-group w-50">
                            <Upload />
                        </div>
                        <Button
                            onClick={() => {
                                this.props.history.push("/video/AddQuestions");
                            }}
                            style={{ marginLeft: 155 }}
                        >
                            Save
                        </Button>
                    </form>
                </CardWrapperComponent>
            </div>
        );
    }
}

export default AddVideo;
