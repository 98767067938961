import HonkioAPI from "../middlewares/HonkioAPI";

function dashboardSuccess(json) {
    return {
        type: "DASHBOARD_LOADED",
        dashboard: json.dashboard
    };
}

const dashboardError = err => ({
    type: "DASHBOARD_ERROR",
    err
});

function loadDashboard() {
    return async function (dispatch) {
        // dispatch(isLoadingOpenTimes());
        try {
            let key = `settings_${localStorage.getItem('merchant_id')}`
            let json = await HonkioAPI().userShop.userFetch('usersettingsget',
                {},
                message => {
                    return false;
                })

            if (json.settings && Object.keys(json.settings).length > 0 && Object.keys(json.settings[key]).length > 0) {
                return dispatch(dashboardSuccess({ 'dashboard': json.settings[key] }));
            } else {
                return dispatch(dashboardSuccess({
                    'dashboard': {
                        positions: [
                            { w: 3, h: 1, x: 0, y: 0, i: "0" },
                            { w: 3, h: 1, x: 3, y: 0, i: "1" },
                            { w: 3, h: 1, x: 6, y: 0, i: "2" },
                            { w: 3, h: 1, x: 12, y: 0, i: "3" },
                            { w: 9, h: 3, x: 0, y: 1, i: "4" },
                            { w: 3, h: 3, x: 9, y: 1, i: "5" }
                        ],
                        items: [
                            { type: "maturity", assetId: "none", name: "Average Maturity" },
                            { type: "tenants", assetId: "none", name: "Tenants" },
                            { type: "square", assetId: "none", name: "Rented square" },
                            { type: "rental_cost", assetId: "none", name: "Rental cost" },
                            { type: "building_invoices", assetId: "none", name: "Building cashflow" },
                            { type: "building_income_pie", assetId: "none", name: "Building income" },
                        ]
                    }
                }));
            }
            // return dispatch(dashboardSuccess({'dashboard':  ?
            //     json.settings : {positions: [], items: []}}));
        } catch (err) {
            return dispatch(dashboardError(err));
        }
    };
}

function saveDashboard(settings) {
    return async function (dispatch) {
        // dispatch(isLoadingOpenTimes());
        try {
            let key = `settings_${localStorage.getItem('merchant_id')}`
            let usersettingsget = await HonkioAPI().userShop.userFetch('usersettingsget',
                {},
                message => {
                    return false;
                })
            let current = {}
            if (usersettingsget.settings) {
                current = usersettingsget.settings
            }
            current[key] = settings

            let json = await HonkioAPI().userShop.userFetch('usersettingsset',
                { settings: current },
                message => {
                    return false;
                })
            return dispatch(dashboardSuccess({ 'dashboard': settings }))
            // return dispatch(dashboardSuccess({'dashboard': json.settings || {positions: [], items: []}}));
        } catch (err) {
            return dispatch(dashboardError(err));
        }
    };
}

export { loadDashboard, saveDashboard, dashboardSuccess };
