import { useEffect, useMemo, useState } from 'react';

const useLoader = (deps = [], delay = 300) => {
  const [progress, setProgress] = useState(0);
  const [done, setDone] = useState(false);
  const length = useMemo(() => deps.length, [deps]);
  useEffect(() => {
    const current = Math.floor(
      (100 *
        deps
          .map((item) => (Array.isArray(item) ? item.length : item))
          .filter(Boolean).length) /
        length
    );
    setProgress(current);
  }, [deps, length]);

  useEffect(() => {
    if (progress == 100){
      setDone(true);
    }
    // setTimeout(() => setDone(true), delay);
  }, [delay, progress]);
  return [progress, done, setDone];
};

export default useLoader;
