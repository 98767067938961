import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { Component } from "react";
import { connect } from "react-redux";
import { authUser } from "../../actions/auth/oauthActions";
import * as qs from "qs";
import CircularProgress from "@material-ui/core/CircularProgress";

class AuthorizedComponent extends Component {
    componentDidMount() {
        let code_from_url = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
        }).code;
        // console.log('CODE: ');
        console.log("code===>", code_from_url);
        this.props.authUser(code_from_url);
    }

    render() {
        return (
            <div className="container vh-100">
                <div className="row h-100 justify-content-center text-center align-items-center">
                    <span>
                        <CircularProgress size={75} />
                    </span>
                    <h3 className="ml-3">{i18n.t(k.LOADING)}</h3>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    authUser: (code) => dispatch(authUser(code)),
});

export default connect(null, mapDispatchToProps)(AuthorizedComponent);
