import React from 'react';
import i18n from 'i18next';
import k from "./../../i18n/keys";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import prepareToEdit from './helpers/prepareToEdit';
import prepareToPublish from './helpers/prepareToPublish';
import setMerchantUserInvite from './../../actions/api/roles/setMerchantUserInvite';
import SmartForm from './../form/SmartForm';
import TextareaSmart from './../form/elements/TextareaSmart';
import ButtonActionForm from './../form/elements/ButtonActionForm';
import InputFieldWithValidation from './../form/elements/InputFieldWithValidation';
import CardWrapperComponent from '../ui_utils/CardWrapperComponent';

const InvitationForRoleForm = (props) => {
  const { invitation } = props;
  const history = useHistory();
  const defaultValues = prepareToEdit(invitation);
  const pageTitle = `${i18n.t(k.INVITATION_FOR_ROLE)} ${invitation.role_name}`
  if (!(invitation.role_id)) return null;

  const onSubmit = (data) => {
    setMerchantUserInvite({ ...prepareToPublish(invitation.role_id, data) }).then(() => {
      history.goBack();
    })
  };

  return (
    <CardWrapperComponent
      header={true}
      footer={true}
      card_title={pageTitle}>
      <div className="container">
        <div className="row">
          <div className="col-md-8 order-md-1">
            <SmartForm
              defaultValues={defaultValues}
              onSubmit={onSubmit}>
              <div className="col-md-14">
                <InputFieldWithValidation
                  className="mb-3"
                  name="email"
                  label={i18n.t(k.EMAIL)}
                  type="text"
                  required="true"
                />
                <TextareaSmart
                  className="mb-3 ml-1"
                  name="message"
                  label={i18n.t(k.MESSAGE)}
                  style={{ minHeight: '16em' }}
                />
              </div>
              <hr className="mb-4" />
              <ButtonActionForm
                label={i18n.t(k.SEND_INVITATION)}
                name="active"
                value={true}
                type="submit"
              />
            </SmartForm>
          </div>
        </div>
      </div >
    </CardWrapperComponent>
  );
};

export default InvitationForRoleForm;
