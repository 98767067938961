import i18n from 'i18next';import k from "./../i18n/keys";import React from "react";
import PropTypes from "prop-types";
import { Container, Row } from "shards-react";


const MainFooter = ({ contained, menuItems, copyright }) =>
<footer className="d-flex py-2 pr-1 bg-white border-top fixed-bottom">
        <Container fluid>
            <Row>

                <span className="copyright ml-auto my-auto mr-2 text-muted">{copyright}</span>
            </Row>
        </Container>
    </footer>;


MainFooter.propTypes = {
  /**
   * Whether the content is contained, or not.
   */
  contained: PropTypes.bool,
  /**
   * The menu items array.
   */
  menuItems: PropTypes.array,
  /**
   * The copyright info.
   */
  copyright: PropTypes.string };


MainFooter.defaultProps = {
  contained: false,
  copyright: i18n.t(k.COPYRIGHT_HONKIO),
  menuItems: [
  {
    title: "Home",
    to: "#" },

  {
    title: "Services",
    to: "#" },

  {
    title: "About",
    to: "#" },

  {
    title: "Products",
    to: "#" },

  {
    title: "Blog",
    to: "#" }] };




export default MainFooter;