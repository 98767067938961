import { APP_ID, APP_PACKAGE, BACKEND_URL, ROLE_ID, SHOP_ID } from "../config";
import { NotificationManager } from "react-notifications";
import i18n from "i18next";
import k from "../i18n/keys";
import { post } from "axios";
import { STORAGE } from "./../actions/auth/oauthActions";

const HonkioAPI = function () {
    const defaultHeader = {
        Accept: "application/json",
        "Content-Type": "application/json",
    };

    const defaultErrorHandler = (message) => {
        NotificationManager.warning(
            message.description || i18n.t(k.UNKNOWN_ERROR),
            i18n.t(k.ERROR_API),
            5000
        );
        if (
            message.error &&
            message.error.length === 2 &&
            message.error[0] === 1 &&
            message.error[1] === 6
        ) {
            window.location = "/logout";
        }
    };
    const customFetch = async (command, parameters, errorHandler, args) => {
        if (!errorHandler) {
            errorHandler = (message) => console.error(message);
        }
        const role = args.role;
        let options = {
            method: "POST",
            headers: defaultHeader,
        };

        let file;
        if (
            ["usersetphoto", "merchantsetphoto", "fileupload"].indexOf(
                command
            ) >= 0 &&
            parameters &&
            parameters.hasOwnProperty("file")
        ) {
            file = parameters.file;
            delete parameters["file"];
        }

        let response = {};
        let data = {};

        if (parameters) {
            if (args && args.auth === true) {
                parameters["access_token"] = localStorage.getItem("token");
                if (localStorage.getItem("device")) {
                    parameters["identity_plugindata"] =
                        localStorage.getItem("device");
                }
            }
            if (args && args.userShop === true) {
                parameters["shop"] = SHOP_ID;
                parameters["shop_password"] =
                    localStorage.getItem("shop_password");
            }
            if (args.role) {
                parameters["role"] = ROLE_ID; //localStorage.getItem(STORAGE.ROLE_ID)
            }
            parameters["identity_client"] = `${APP_PACKAGE};web;2.0`;
            parameters["language"] = localStorage.getItem("i18nextLng") || "en";

            parameters["role"] = ROLE_ID; //localStorage.getItem("role")
            parameters["shop"] = SHOP_ID;
            parameters["appid"] = APP_ID;
            options["body"] = JSON.stringify(parameters);
            if (parameters["content-type"]) {
                options.headers["Content-Type"] = parameters["content-type"];
            }
        }

        if (file) {
            let formData = new FormData();
            formData.append(
                "message",
                new Blob([JSON.stringify(parameters)], {
                    type: "application/json",
                }),
                "message.json"
            );
            formData.append("file", file, file.name);

            options["headers"] = { "content-type": "multipart/form-data" };
            response = await post(
                BACKEND_URL + "/" + command,
                formData,
                options
            ).catch((err) => {
                defaultErrorHandler(err);
            });
            if (!response || !response.data || response.status !== 200) {
                try {
                    if (errorHandler != null) {
                        let errorHandled = errorHandler(response);
                        if (!errorHandled) {
                            defaultErrorHandler(response);
                        }
                    } else {
                        defaultErrorHandler(response);
                    }
                } catch (e) {
                    defaultErrorHandler(response);
                }
            }
            data = response?.data;
        } else {
            try {
                response = await fetch(
                    BACKEND_URL + "/" + command,
                    options
                ).catch((err) => defaultErrorHandler(err));
                data = await response.json();
            } catch (e) {
                console.log(`Error in HonkioAPI`, e);
            }
            if (!response || !response.ok) {
                NotificationManager.warning(
                    i18n.t(k.UNKNOWN_ERROR),
                    i18n.t(k.ERROR_API),
                    5000
                );
            }
        }
        if (data?.status !== "accept") {
            try {
                if (errorHandler != null) {
                    let errorHandled = errorHandler(data);
                    if (!errorHandled) {
                        defaultErrorHandler(data);
                    }
                } else {
                    defaultErrorHandler(data);
                }
            } catch (e) {
                defaultErrorHandler(data);
            }
        }
        return data;
    };

    const mainShop = {
        basicfetch: function (command, parameters, errorHandler) {
            return customFetch(command, parameters, errorHandler, {
                role: true,
            });
        },
        userFetch: function (command, parameters, errorHandler) {
            return customFetch(command, parameters, errorHandler, {
                auth: true,
                role: true,
            });
        },
    };

    const userShop = {
        basicfetch: function (command, parameters, errorHandler) {
            return customFetch(command, parameters, errorHandler, {
                userShop: true,
                role: true,
            });
        },
        userFetch: function (command, parameters, errorHandler) {
            return customFetch(command, parameters, errorHandler, {
                auth: true,
                userShop: true,
                role: true,
            });
        },
    };

    return { mainShop, userShop };
};
export default HonkioAPI;
