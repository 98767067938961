import i18n from "i18next";
import React, { Component } from "react";
import {
    Form,
    FormCheckbox,
    FormGroup,
    FormInput,
    FormSelect,
} from "shards-react";
import { DateTimePicker } from "react-widgets";
import k from "../../i18n/keys";
import SingleDatePickerComponent from "../ui_utils/SingleDatePickerComponent";

import moment from "moment";
import "moment/locale/fi";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

class FieldInputWizardStepComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    // componentWillReceiveProps(nextProps, nextContext){
    //     if (nextProps.tenant !== undefined) {
    //         this.setState({'tenant': nextProps.tenant !== null ? nextProps.tenant : {}})
    //     }
    // }

    handleChange(e) {
        this.setState({
            tenant: { ...this.state.tenant, [e.target.id]: e.target.value },
        });
    }

    mapComponent = (field) => {
        let input;
        let that = this;
        switch (field.type) {
            case "date":
                // alert(this.props.data.data && this.props.data.data[field.name] ?
                //          moment(this.props.data.data[field.name]).toDate() : 'ппппп')
                input = (
                    <SingleDatePickerComponent
                        id={field.name}
                        name={field.name}
                        value={
                            this.props.data.data &&
                            this.props.data.data[field.name]
                                ? this.props.data.data[field.name]
                                : ""
                        }
                        handleChangeEvent={that.props.handleChangeEvent}
                    />
                );
                break;
            case "number":
                // alert('num')
                input = (
                    <FormInput
                        id={field.name}
                        name={field.name}
                        pattern="\d*"
                        value={
                            this.props.data.data &&
                            this.props.data.data[field.name]
                                ? this.props.data.data[field.name]
                                : ""
                        }
                        onChange={this.props.handleChangeEvent}
                    />
                );

            default:
                input = (
                    <FormInput
                        id={field.name}
                        name={field.name}
                        value={
                            this.props.data.data &&
                            this.props.data.data[field.name]
                                ? this.props.data.data[field.name]
                                : ""
                        }
                        onChange={this.props.handleChangeEvent}
                    />
                );
        }
        return (
            <FormGroup>
                <label htmlFor={field.name}>{field.name}</label>
                {input}
            </FormGroup>
        );
    };

    render() {
        let that = this;
        // if (this.props.agreement.reservable === true) {
        //   return (
        //     <Form>
        //       <h4>{i18n.t(k.RESERVED_AREA_AMOUNT)}</h4>
        //       <hr/>
        //       <FormGroup>
        //         <label htmlFor="rent_amount">{i18n.t(k.AMOUNT)}</label>
        //         <FormInput id="rent_amount"
        //                    value={this.props.agreement ?
        //                      this.props.agreement.amount : ''}
        //                    onChange={e => this.props.handleChangeEvent(e, 'Amount')}/>
        //
        //       </FormGroup>
        //       <FormGroup>
        //         <label htmlFor="text">{i18n.t(k.CURRENCY)}</label>
        //         <FormSelect>
        //           <option value="EUR">{i18n.t(k.EUR)}</option>
        //           <option value="SEK">{i18n.t(k.SEK)}</option>
        //         </FormSelect>
        //       </FormGroup>
        //     </Form>);
        //
        // }
        // console.error('this.props.agreement.rules');
        // console.error(this.props.agreement.rules);
        return (
            <Form>
                <h4 className="text-capitalize mb-3">{this.props.title}</h4>
                {this.props.fields.map((field) => {
                    return this.mapComponent(field);
                })}
            </Form>
        );
    }
}

export default FieldInputWizardStepComponent;
