import HonkioAPI from '../../../middlewares/HonkioAPI';

const getAsset = (options = {}, errorHandler = {}) => {
    const { id, debug, query_parent_levels } = options;
    return HonkioAPI().mainShop.userFetch('assetget', {
        debug,
        id,
        query_parent_levels
    }, errorHandler);
};
export default getAsset;
