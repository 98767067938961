import React, { useState, useEffect } from "react";
import { BreadcrumbItem } from "shards-react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { gotoBreadcrumb } from "../../actions/directory";

const BreadcrumbsComponent = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, []);

    const onClickBreadcrumb = (e, _id, linkTo) => {
        e.preventDefault();
        if (_id) dispatch(gotoBreadcrumb(_id));
        history.push(linkTo);
    };

    const renderCrumb = ({ _id = null, name, linkTo }) => {
        if (linkTo) {
            return (
                <BreadcrumbItem key={`breadcrumb-item-${name}`}>
                    <Link
                        to={linkTo}
                        onClick={(e) => onClickBreadcrumb(e, _id, linkTo)}
                    >
                        {name}
                    </Link>
                </BreadcrumbItem>
            );
        } else {
            return (
                <BreadcrumbItem key={`breadcrumb-item-${name}`} active>
                    {name}
                </BreadcrumbItem>
            );
        }
    };

    const renderBreadcrumbs = () => {
        let breadcrumbs = [];
        for (const parent of props.parents) {
            breadcrumbs.push(renderCrumb(parent));
        }
        return breadcrumbs;
    };

    return <div className="d-flex">{renderBreadcrumbs()}</div>;
};

export default BreadcrumbsComponent;
