import React, { useMemo, useEffect, useState } from "react";
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import { useLocation } from "react-router-dom";
import ReactPlayer from "react-player";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const VideoPlayer = () => {
    const query = useQuery();
    const [videoUrl, setVideoUrl] = useState("");
    const [videoName, setVideoName] = useState("Name of the video");

    useEffect(() => {
        setVideoUrl(query.get("url"));
        setVideoName(query.get("name"));
    }, [query]);

    return (
        <div>
            <div className="container-fluid">
                <PageHeaderComponent title={`Video player`} />
            </div>
            <CardWrapperComponent
                header={true}
                footer={true}
                card_title={videoName}
            >
                <ReactPlayer
                    url={videoUrl}
                    controls
                    width="100%"
                    heigh="auto"
                />
                <a
                    href={videoUrl}
                    className="d-flex mt-4 text-center"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {videoUrl}
                </a>
            </CardWrapperComponent>
        </div>
    );
};

export default VideoPlayer;
