import i18n from "i18next";
import k from "./../../../i18n/keys";
import React, { Component } from "react";
import WidgetComponent from "./WidgetComponent";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import CircularProgress from "@material-ui/core/CircularProgress";

const baselineStyle = {
    line: {
        stroke: "gray",
        strokeWidth: 1,
    },
};

class TotalSquareWidgetComponent extends Component {
    constructor(props) {
        super(props);
        this.refreshData = this.refreshData.bind(this);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this.refreshData();
        let that = this;
        this.setContainerRef = (element) => {
            that.container = element;
        };
    }

    refreshData() {
        let that = this;
        that.setState({ loading: true });
        let merchantarealist = async function () {
            try {
                // TODO: Currently returns error - check if backend controller works incorrectly
                let response = await HonkioAPI().userShop.userFetch(
                    "merchantarealist",
                    { query_square: true },
                    (message) => {
                        console.error(message);
                        return true;
                    }
                );
                /*
                console.log(
                    `TotalSquareWidgetComponent: merchantarealist: response`,
                    response
                );
                */
                let square = response.square || 0;
                that.setState({ square: square, loading: false });
            } catch (error) {
                console.log(
                    "TotalSquareWidgetComponent: refreshData: error",
                    error
                );
            }
        };
        merchantarealist();
    }

    render() {
        const points = [];
        let line;
        let summary;
        let drawChart = (height, width) => {
            return (
                <div className="d-flex justify-content-center w-100 h-100">
                    <span className="justify-content-center align-self-center h5">
                        {this.state.square} {i18n.t(k.M)}
                    </span>
                </div>
            );
        };

        return (
            <WidgetComponent
                position={this.props.position}
                height={this.props.height}
                width={this.props.width}
                icon="fas fa-chart-area fa-2x"
                name={this.props.name}
                onRename={this.props.onRename}
                type={`${i18n.t(k.TOTAL)} ${i18n.t(k.SQUARE)}`}
                summary1={drawChart()}
                onRemoveClick={this.props.onRemoveClick}
                chart={
                    this.state.loading
                        ? () => <CircularProgress size={35} />
                        : drawChart
                }
            />
        );
    }
}

export default TotalSquareWidgetComponent;
