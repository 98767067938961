import i18n from 'i18next';import k from "./../../../i18n/keys";import React, { Component } from 'react';
import { Button, Form, FormGroup, FormInput, Modal, ModalBody, ModalHeader } from "shards-react";
import Select from "react-select";

const typeOptions = [
  {value: 1, label: i18n.t(k.OFFICE)},
  {value: 2, label: i18n.t(k.RETAIL)},
  {value: 3, label: i18n.t(k.STORAGE)},
  {value: 4, label: i18n.t(k.INDUSTRIAL)},
  {value: 5, label: i18n.t(k.HOTEL)},
  {value: 6, label: i18n.t(k.RESIDENTIAL)},
  {value: 7, label: i18n.t(k.OTHER)},
  {value: 8, label: i18n.t(k.OTHER_NO_GLA)},
  {value: 9, label: i18n.t(k.PARKING)},
  {value: 10, label: i18n.t(k.ADDITIONAL_RENT)},
  {value: 11, label: i18n.t(k.MAINTENANCE_CHARGE)},
  {value: 12, label: i18n.t(k.TURNOVER_RENT)},
]

export default class AreaEditModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      area: {
        rules: {} } };



  }


  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.area !== undefined) {
      this.setState({ "area": nextProps.area });
    }
  }

  handleChange(e) {
    this.setState({ area: { ...this.state.area, [e.target.id]: e.target.value } });
  }
  handleChangeTotalArea(e, label) {
    // console.log('EEE')
    // console.log(e)
    if (label) {
      this.setState({ area: { ...this.state.area, rules: { ...this.state.area.rules, [label]: e.value } } });
    }
    else {
      this.setState({ area: { ...this.state.area, rules: { ...this.state.area.rules, [e.target.id]: e.target.value } } });
    }
  }

  render() {
    let that = this;

    // console.log("EDIT AREA MODAL STATE");
    // console.log(this.state);
    let saveButton = <Button onClick={() => {that.props.onAreaEdited(that.state.area);}}>{i18n.t(k.CHOOSE)}</Button>;
    return (
      <Modal open={this.props.visible}
      toggle={this.props.toggle}
      onRequestClose={this.props.onRequestClose}
      className="w-100 modal-lg modal-dialog-centered modal-dialog-scrollable">
                <ModalHeader>{i18n.t(k.EDIT_AREA)}</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <FormGroup>
                                <label htmlFor="name">{i18n.t(k.NAME)}</label>
                                <FormInput
                id="name"
                value={this.state.area.name}
                onChange={e => this.handleChange(e)} />

                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="total_area">{i18n.t(k.TOTAL_AREA_M)}</label>
                                <FormInput
                id="total_area"
                value={this.state.area.rules.total_area}
                onChange={e => this.handleChangeTotalArea(e)} />

                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="market_rent">{i18n.t(k.MARKET_RENT_PRICE)}</label>
                                <FormInput
                id="market_rent"
                value={this.state.area.rules.market_rent}
                onChange={e => this.handleChangeTotalArea(e)} />

                            </FormGroup>
                            <FormGroup>
                        <label htmlFor="space_type">{i18n.t(k.SPACE_TYPE)}</label>
                        <Select id="space_type"
                                onChange={e => {
                                    this.handleChangeTotalArea(e, 'space_type');
                                }}
                                options={typeOptions}
                                value={this.state.area.rules.space_type ? typeOptions[this.state.area.rules.space_type - 1] : {}}/>

                    </FormGroup>
                        </FormGroup>
                        {saveButton}
                    </Form>
                </ModalBody>
            </Modal>);

  }}