import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { Component } from "react";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import NotificationManager from "react-notifications/lib/NotificationManager";
import HonkioAPI from "../../middlewares/HonkioAPI";
import StepWizard from "react-step-wizard";

import CenteredPageLoader from "../ui_utils/CenteredPageLoader";
import NetAssetWizardNavComponent from "./subcomponents/NetAssetWizardNavComponent";
import FieldInputWizardStepComponent from "./FieldInputWizardStepComponent";

import moment from "moment";
import "moment/locale/fi";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

const fields = [];
const context = require.context("./json/", true, /.json$/);
const all = {};
context.keys().forEach((key) => {
    const fileName = key.replace("./", "");
    const resource = require(`./json/${fileName}`);
    const namespace = fileName.replace(".json", "");
    fields[namespace] = JSON.parse(JSON.stringify(resource));
});

const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

class NetAssetDataInputWizardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: {
                data: {},
            },

            validation: {},
        };

        this.loadData = this.loadData.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
    }

    handleValidation(id, valid) {
        this.setState({
            validation: { [id]: valid },
        });
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        let that = this;
        let buildingId = this.props.match.params.buildingId;

        let load = async function () {
            let response = await HonkioAPI().userShop.userFetch(
                "merchantbuildingdatalist",
                {
                    query_building: buildingId,
                    query_type: "manual_input",
                    query_sort: [{ creation_date: -1 }],
                    query_count: 1,
                }
            );

            let data = {};
            if (response.data && response.data[0]) {
                data = response.data[0].data;
            }
            that.setState({ data: { data: data }, loading: false });
        };
        load();
    }

    onSaveClick() {
        let that = this;
        that.setState({ loading: true });
        let buildingId = this.props.match.params.buildingId;
        let saveData = async function () {
            let response = await HonkioAPI().userShop.userFetch(
                "merchantbuildingdataset",
                {
                    ...that.state.data,
                    building: buildingId,
                    type: "manual_input",
                }
            );
            NotificationManager.success(i18n.t(k.DATA_SAVED));
            that.props.history.push(`/data/${buildingId}`);
        };
        saveData();
    }

    onChange = (e) => {
        // alert('fff ' + e.target.value)
        this.setState({
            data: {
                ...this.state.data,
                data: {
                    ...this.state.data.data,
                    [e.target.id]: e.target.value,
                },
            },
        });
    };
    render() {
        let that = this;
        let templateComponent;
        if (this.state.loading) {
            return <CenteredPageLoader />;
        }
        return (
            <div>
                <div className="container-fluid">
                    {/*          <PageHeaderComponent*/}
                    {/*title={'i18n.t(k.AGREEMENT_CREATE)'}*/}
                    {/*subtitle={i18n.t(k.AGREEMENT_CREATE)} */}
                    {/*          />*/}
                </div>
                <CardWrapperComponent
                    header={true}
                    footer={true}
                    card_title={i18n.t(k.DATA_INPUT)}
                >
                    <StepWizard
                        className="wizard"
                        nav={
                            <NetAssetWizardNavComponent
                                validation={this.state.validation}
                                onSaveClick={this.onSaveClick}
                                agreement={this.state.agreement}
                            />
                        }
                    >
                        {Object.keys(fields).map((name) => {
                            let title = name.replace(/^\d+_/, "");
                            // alert(name + ' ' + title)
                            return (
                                <FieldInputWizardStepComponent
                                    fields={fields[name]}
                                    title={title}
                                    handleChangeEvent={this.onChange}
                                    data={this.state.data}
                                />
                            );
                        })}
                    </StepWizard>
                </CardWrapperComponent>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NetAssetDataInputWizardComponent);
