import i18n from "i18next";
import k from "./../../../i18n/keys";
import React, { Component } from "react";
import WidgetComponent from "./WidgetComponent";
import HonkioAPI from "../../../middlewares/HonkioAPI";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Index, TimeSeries } from "pondjs";
import {
    ChartContainer,
    ChartRow,
    Charts,
    EventMarker,
    styler,
    YAxis,
} from "react-timeseries-charts";
import { BarChart } from "@material-ui/icons";

import moment from "moment";
import "moment/locale/fi";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

const baselineStyle = {
    line: {
        stroke: i18n.t(k.GRAY),
        strokeWidth: 1,
    },
};

class ProximityWidgetComponent extends Component {
    constructor(props) {
        super(props);
        this.refreshData = this.refreshData.bind(this);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this.refreshData();
        let that = this;
        this.setContainerRef = (element) => {
            // alert(1)
            that.container = element;
            // alert(that.container)
        };
    }

    handleTrackerChanged = (t) => {
        if (t) {
            const e = this.state.touchSeries.atTime(t);
            const eventTime = new Date(
                e.begin().getTime() +
                    (e.end().getTime() - e.begin().getTime()) / 2
            );

            const eventValue = e.get("touch");

            const v = `${eventValue > 0 ? "+" : ""}${eventValue}°C`;
            this.setState({
                tracker: eventTime,
                trackerValue: v,
                trackerEvent: e,
            });
        } else {
            this.setState({
                tracker: null,
                trackerValue: null,
                trackerEvent: null,
            });
        }
    };
    renderMarker = () => {
        // if (!this.state.tracker) {
        //     return <NullMarker />;
        // }
        if (this.state.markerMode === "flag") {
            return (
                <EventMarker
                    type="flag"
                    axis="axis"
                    event={this.state.trackerEvent}
                    column="touch"
                    info={[
                        {
                            label: i18n.t(k.ANOMALY),
                            value: this.state.trackerValue,
                        },
                    ]}
                    infoTimeFormat="%Y"
                    infoWidth={120}
                    markerRadius={2}
                    markerStyle={{ fill: i18n.t(k.BLACK) }}
                />
            );
        } else {
            return (
                <EventMarker
                    type="point"
                    axis="axis"
                    event={this.state.trackerEvent}
                    column="touch"
                    markerLabel={this.state.trackerValue}
                    markerLabelAlign="left"
                    markerLabelStyle={{
                        fill: i18n.t(k.DB_D),
                        stroke: i18n.t(k.WHITE),
                    }}
                    markerRadius={3}
                    markerStyle={{ fill: i18n.t(k.DB_D) }}
                />
            );
        }
    };

    refreshData() {
        let that = this;
        that.setState({ loading: true });
        let load = async function () {
            let query = {
                // query_merchant: localStorage.getItem('merchant_id'),
                query_asset: that.props.assetId,
            };

            if (that.props.start_date) {
                query["query_start_date"] = that.props.start_date;
            }
            if (that.props.end_date) {
                query["query_end_date"] = that.props.end_date;
            }

            let response = await HonkioAPI().userShop.userFetch(
                "merchantasseteventlist",
                query
            );

            let present = 0;
            let notPresent = 0;
            let lastTouched;
            let points = [];
            response.events.forEach((val) => {
                const index = Index.getIndexString(
                    "1m",
                    moment(val.timestamp).toDate()
                );
                // alert(index)

                // const fiveyear = val.fiveyr;
                let present = val.data.VALUE === "PRESENT";
                points.push([index, present ? 1 : 0, present ? 1 : 0]);
                // if () {
                //     present += 1
                // } else {
                //     notPresent += 1
                // }
                if (moment(val.timestamp).toDate() > lastTouched)
                    lastTouched = moment(val.timestamp).toDate();
            });

            const touchSeries = new TimeSeries({
                name: i18n.t(k.TOUCH),
                columns: ["index", "present", "notPresent"],
                points: points,
            });

            that.setState({
                events: response.events,
                touchSeries: touchSeries,
                // min: min,
                // max: max,
                lastTouched: lastTouched,
                loading: false,
            });
        };
        load();
    }
    onResize = (a, b, c) => {
        console.error("dddddddddd");
        console.error(a);
        console.error(b);
        console.error(c);
    };
    render() {
        // if (this.container) alert(this.container.offsetHeight)
        const points = [];
        let line;
        let summary;
        let drawChart = (height, width) => {
            // alert(height + ' '+ width)
            const style = styler([{ key: "touch", color: "black", width: 1 }]);

            const axisStyle = {
                values: {
                    labelColor: "grey",
                    labelWeight: 100,
                    labelSize: 11,
                },

                axis: {
                    axisColor: "grey",
                    axisWidth: 1,
                },
            };

            const min = this.state.min;
            const max = this.state.max;
            return (
                <ChartContainer
                    timeRange={this.state.touchSeries.range()}
                    timeAxisStyle={axisStyle}
                    onTrackerChanged={this.handleTrackerChanged}
                    enablePanZoom={true}
                    // height={this.props.height * ROW_HEIGHT - 75}
                    // width={this.props.width * 100 }
                    // height={height}
                    // width={width}
                    width={width}
                    height={height}
                    // width={this.container.offsetWidth}
                >
                    <ChartRow
                        // height={this.props.height * ROW_HEIGHT - 75}
                        // width={this.props.width * 100 }
                        height={height}
                        width={width}
                        // width={this.container.offsetWidth}
                    >
                        <YAxis
                            id="axis"
                            label="Touch"
                            transition={300}
                            style={axisStyle}
                            labelOffset={0}
                            min={min}
                            max={max}
                            format=",.1f"
                            width="60"
                            type="linear"
                        />

                        <Charts>
                            <BarChart
                                axis="axis"
                                series={this.state.touchSeries}
                                columns={["touch"]}
                                style={style}
                            />

                            {/*<BarChart*/}
                            {/*    axis="axis"*/}
                            {/*    series={this.state.touchSeries}*/}
                            {/*    columns={["touch"]}*/}
                            {/*    style={style}*/}
                            {/*/>*/}
                            {/*<Baseline*/}
                            {/*    axis="axis"*/}
                            {/*    value={0.0}*/}
                            {/*    label=""*/}
                            {/*    style={baselineStyle}*/}
                            {/*/>*/}
                            {this.renderMarker()}
                        </Charts>
                    </ChartRow>
                </ChartContainer>
            );
        };
        if (!this.state.loading) {
            // console.error('height ' + this.container.offsetHeight)

            line = summary = (
                <div className="d-flex justify-content-center">
                    <div className="w-100 h-100">
                        {/*<div className="text-center">*/}
                        {/*    <span>13.1C</span>*/}
                        {/*    <br/>*/}
                        {/*    <span className="text-muted">1 minutes ago</span>*/}
                        {/*</div>*/}
                        {/*<hr/>*/}
                        <div className="text-center">
                            <span>
                                <span className="text-muted">
                                    {i18n.t(k.LAST_OPEN)}{" "}
                                </span>
                                <b>{moment(this.state.lastTouched).format()}</b>
                            </span>
                            <br />
                        </div>
                    </div>
                </div>
            );
        }
        // line =
        //
        //         {line}
        // line = <div style={{background: '#ff0000'}} >
        // {this.state.loading ? <CircularProgress size={35}/> : line}
        return (
            <WidgetComponent
                position={this.props.position}
                height={this.props.height}
                width={this.props.width}
                icon="fas fa-door-open fa-2x"
                name={this.props.name}
                onRename={this.props.onRename}
                type={i18n.t(k.PROXIMITY)}
                summary={summary}
                onRemoveClick={this.props.onRemoveClick}
                chart={
                    this.state.loading
                        ? () => <CircularProgress size={35} />
                        : drawChart
                }
            />
        );
    }
}

export default ProximityWidgetComponent;
