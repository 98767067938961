import React, { useState } from "react";
import {
    NotificationManager,
    NotificationContainer,
} from "react-notifications";

const BusinessId = ({
    name,
    label,
    k,
    required,
    businessInfo,
    className,
    onBusinessIdChanged,
}) => {
    const [currentBusinessInfo, setCurrentBusinessInfo] =
        useState(businessInfo);
    //     const [prevBusinessId, setPrevBusinessId] = useState(businessInfo.business_id);
    //     const [loading, setLoading] = useState(true);
    // console.log(name, label, k, required, businessInfo, className, onBusinessIdChanged,"---------------------")
    // function onBusinessDataCollected(data) {
    //     let info = {
    //         business_id: data.businessId,
    //         name: data.name,
    //         registration_date: data.registrationDate,
    //         addresses: data.addresses
    //     }
    //     setCurrentBusinessInfo(info)
    //     sendOnBusinessIdChanged(name, info)
    // }

    // const sendOnBusinessIdChanged = (name, data) => {
    //     setPrevBusinessId(data ? data.business_id : undefined)
    //     onBusinessIdChanged(name, data)
    // }

    // const onBusinessIdFieldLostFocus = async (data) => {
    //     if (data.target.value.trim() !== prevBusinessId) {
    //         setLoading(true)
    //         const INFO_URL = `https://avoindata.prh.fi/bis/v1/${data.target.value.trim()}`;
    //         let response = await fetch(INFO_URL);
    //         let result = await response.json();
    //         if (result.results.length > 0) {
    //             onBusinessDataCollected(result.results[0])
    //         } else {
    //             setPrevBusinessId('')
    //             NotificationManager.error(response.error_message || 'Business id not found', 'Error', 5000);
    //         }
    //         setLoading(false)
    //     }
    // }
    const onBusinessIdFieldChange = (data) => {
        setCurrentBusinessInfo({
            ...currentBusinessInfo,
            business_id: data.target.value,
        });
    };
    // console.log(currentBusinessInfo,"========================= currentBusinessInfo")
    return (
        <div className={className} key={k}>
            <NotificationContainer />
            <div key={k}>
                {label && <label htmlFor={name}>{label}</label>}
                <input
                    type={"text"}
                    className="form-control ml-2"
                    id={name}
                    name={name}
                    required={required}
                    value={currentBusinessInfo?.business_id}
                    // onBlur={onBusinessIdFieldLostFocus}
                    onChange={onBusinessIdFieldChange}
                />
            </div>
            {/* {currentBusinessInfo.name ? <label className='ml-2 mr-2 text-muted' htmlFor={currentBusinessInfo.name}>{currentBusinessInfo.name}</label>
            : loading ? <label htmlFor={'loading'}>loading</label> : <label htmlFor={'not_found'}>not found</label>} */}
            {/* {currentBusinessInfo.registration_date && <label className='mr-2 text-muted' htmlFor={currentBusinessInfo.registration_date}>{currentBusinessInfo.registration_date}</label>} */}
        </div>
    );
};

export default BusinessId;
