import React, { useEffect, useState, useContext } from 'react';
import { NotificationManager, NotificationContainer } from "react-notifications";
import AssetGroupEditForm from './AssetGroupEditForm';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardWrapperComponent from './../../ui_utils/CardWrapperComponent';
import i18n from 'i18next';
import k from "../../../i18n/keys";
import useSearchString from './../../../utils/useSearchString';
import { getAssetGroup, setAssetGroup } from './../../../actions/api/assets/assetGroup';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Circular = () =>
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>;

const AssetGroupEdit = () => {
    const { group } = useSearchString();
    const [assetGroupInfo, setAssetGroupInfo] = useState();
    const [loading, setLoading] = useState(true);
    const history = useHistory()
    let isMounted = true;
    useEffect(() => {
        return () => {
            isMounted = false
        };
    }, []);

    useEffect(() => {
        setLoading(true)
        const resultHandler = (data) => {
            setLoading(false)
            if (isMounted) {
                if (data?.status === 'accept' && (data?.asset_group)) {
                    setAssetGroupInfo({
                        ...data.asset_group,
                        assets: data.assets
                    })                  
                } else {
                    NotificationManager.error("Error")
                }
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000)
            setLoading(false)
        }

        getAssetGroup({ id: group, query_list_assets: true }, errorHandler)
            .then((data) => {
                return resultHandler(data)
            })
    }, [group]);

    const onSave = (data) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success("Group saved", "Success", 4000)
                history.goBack()
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000)
        }
        const assetIds = data.assets.map(asset => asset._id)
        const assetGroup = {
            ...data,
            assets: assetIds
        }
        setAssetGroup({ group: assetGroup, merchantId: localStorage.merchant_id }, errorHandler)
            .then(data => {
                return resultHandler(data)
            })
    };

    return <div className='mt-2'>
        <NotificationContainer />
        <CardWrapperComponent
            header={true}
            footer={true}
            card_title={`${i18n.t(k.EDIT_AREA)}`}>
            {loading ? <Circular />
                : (assetGroupInfo ? <AssetGroupEditForm
                    onSubmit={onSave}
                    defaultValues={assetGroupInfo}
                />
                    : 'not found')}

        </CardWrapperComponent>
    </div>
};

export default AssetGroupEdit;
