import i18n from 'i18next';import k from "./../../i18n/keys";import React, { Component } from 'react';
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataTable from "react-data-table-component";
import 'react-confirm-alert/src/react-confirm-alert.css';
import HonkioAPI from "../../middlewares/HonkioAPI";
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";

const Circular = () =>
// we need to add some padding to circular progress to keep it from activating our scrollbar
<div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>;


class SubscriptionPlanListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      subsPlan: [] };

    this.loadSubsPlan = this.loadSubsPlan.bind(this);
    this.totalPrice = this.totalPrice.bind(this);
  }

  componentDidMount() {
    this.loadSubsPlan();
  }

  loadSubsPlan() {
    let that = this;
    let merchantgetsubscriptionplan = async function () {
      let response = await HonkioAPI().userShop.userFetch('merchantsubscriptionplanlist', { merchant_id: localStorage.getItem('merchant_id') });

      let subsPlan = response.entities;
      that.setState({ 'subsPlan': subsPlan, 'loading': false });
    };
    merchantgetsubscriptionplan();
  }

  getColumns() {
    let that = this;
    return [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      style: `cursor: pointer;`,
      width: '16%' },

    {
      name: 'Description',
      selector: 'description',
      sortable: true,
      style: `cursor: pointer;`,
      width: '16%' },

    {
      name: 'Creation Date',
      selector: 'created_at',
      sortable: true,
      style: `cursor: pointer;`,
      width: '20%' },

    {
      name: 'Service Type',
      selector: 'type',
      sortable: true,
      style: `cursor: pointer;`,
      width: '16%' },

    {
      name: 'Service Price',
      selector: 'price',
      sortable: true,
      style: `cursor: pointer;`,
      width: '16%' },


    {
      name: 'Merchant Name',
      selector: 'merchant_name',
      sortable: true,
      style: `cursor: pointer;`,
      width: '16%' }];


  }

  totalPrice = () => {
    // Get all buildings and solution price and calculate total price. One property is free
    let totalPrice = 0;
    let i = 0;
    if (this.state.subsPlan) {
      for (i; i < this.state.subsPlan.length; i++) {
        // price in format like: "20 EUR" so slice 4 and make it int
        totalPrice += parseInt(this.state.subsPlan[i]['price'].slice(0, -4));
      }
      //remove 100 euro (Honkio Rental Solution)
      totalPrice -= 100;
      // if at least one property apply one is free
      if (totalPrice > 0) {
        totalPrice = totalPrice - 20;
      }
      //add back 100 euro
      return totalPrice + 100 + " EUR";
    }
    return "Calculation is failed, please, try again later.";
  };

  render() {
    return (
      <div>
                <div className="container-fluid">
                    <PageHeaderComponent
          title='Subscription Plan'
          subtitle={i18n.t(k.OVERVIEW)} />

                </div>
                <CardWrapperComponent
        header={true}
        footer={true}
        card_title={i18n.t(k.SUBSCRIPTION_PLAN)}>
                    <DataTable
          noHeader={true}
          autoWidth={true}
          columns={this.getColumns()}
          background="#ffffff00"
          data={this.state.subsPlan}
          noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
          paginationComponentOptions={
              { rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                rangeSeparatorText: i18n.t(k.ROWS_OF),
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: i18n.t(k.ROWS_ALL) }
            }
          progressPending={this.state.loading}
          progressComponent={<Circular />}
          onRowClicked={this.rowSelected} />


                    <p className="text-right mr-4">{i18n.t(k.TOTAL)} {this.totalPrice()}</p>
                </CardWrapperComponent>
            </div>);

  }}


function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = dispatch => ({});


export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPlanListComponent);
