import i18n from "i18next";
import k from "./../../i18n/keys";
import React, { Component } from "react";
import CardWrapperComponent from "../ui_utils/CardWrapperComponent";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataTable from "react-data-table-component";
import DeleteForever from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Button } from "shards-react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import NotificationManager from "react-notifications/lib/NotificationManager";
import HonkioAPI from "../../middlewares/HonkioAPI";
import TenantEditModalComponent from "../tenants/TenantEditModalComponent";
import PortfolioEditModalComponent from "./PortfolioEditModalComponent";
import PageHeaderComponent from "../ui_utils/PageHeaderComponent";
import { Link } from "react-router-dom";

const Circular = () => (
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

class PortfolioListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            portfolios: [],
            modal: {
                visible: false,
                portfolio: null,
            },
        };

        this.loadPortfolios = this.loadPortfolios.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.getAvgMaturity = this.getAvgMaturity.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        this.loadPortfolios();
    }

    getAvgMaturity(e) {
        let that = this;
        let merchantaveragematurity = async function () {
            let response = await HonkioAPI().userShop.userFetch(
                "merchantaveragematurity",
                { buildings_list: e.buildings }
            );
            let portfolio_id = e.id;
            that.setState({ [portfolio_id]: response.avg_maturity });
        };
        if (typeof this.state[e.id] !== "number") {
            merchantaveragematurity();
        }
    }

    confirmDelete(buidling) {
        let that = this;
        const options = {
            title: "Destructive action",
            message: "",
            childrenElement: () => (
                <p>
                    {i18n.t(k.ARE_YOU_SURE_WANT_TO_DELETE1)}{" "}
                    <b>{buidling.name}</b>
                    {i18n.t(k._4)}
                </p>
            ),
            buttons: [
                {
                    label: i18n.t(k.YES),
                    onClick: () => {
                        const del = async () => {
                            that.setState({ loading: true });
                            let response = await HonkioAPI().userShop.userFetch(
                                "merchantbuildingportfolioset",
                                {
                                    id: buidling.id,
                                    delete: true,
                                }
                            );

                            if (response.status === "accept") {
                                NotificationManager.success(
                                    i18n.t(k.LOADING),
                                    i18n.t(k.BUILDING_HAS_BEEN_REMOVED)
                                );
                            } else {
                                NotificationManager.success(
                                    `${response.description}`,
                                    i18n.t(k.DELETE_FAILED)
                                );
                            }
                            that.loadPortfolios();
                        };
                        del();
                    },
                },

                {
                    label: i18n.t(k.NO),
                    onClick: () => {},
                },
            ],

            closeOnEscape: true,
            closeOnClickOutside: true,
        };

        confirmAlert(options);
    }

    loadPortfolios() {
        let that = this;
        let merchantgetportfolios = async function () {
            let response = await HonkioAPI().userShop.userFetch(
                "merchantbuildingportfoliolist",
                { merchant_id: localStorage.getItem("merchant_id") }
            );
            let portfolios = response.portfolios;
            that.setState({ portfolios: portfolios, loading: false });
        };
        merchantgetportfolios();
    }

    getColumns() {
        let that = this;
        return [
            {
                name: i18n.t(k.NAME),
                selector: "name",
                sortable: true,
                style: `cursor: pointer;`,
            },

            {
                name: i18n.t(k.AVERAGE_MATURITY),
                sortable: true,
                style: `cursor: pointer;`,
                cell: function (e) {
                    if (typeof that.state[e.id] !== "number") {
                        that.getAvgMaturity(e);
                    }
                    return that.state[e.id];
                },
            },

            {
                name: i18n.t(k.DESCRIPTION),
                selector: "description",
                sortable: true,
                style: `cursor: pointer;`,
            },

            {
                name: i18n.t(k.BUILDINGS1),
                cell: function (e) {
                    return e.buildings_names.join(", ");
                },
                sortable: true,
                style: `cursor: pointer;`,
            },

            {
                name: i18n.t(k.ACTION),
                selector: "action",
                cell: function (d) {
                    return (
                        <span>
                            <Link
                                to="#"
                                className="mr-3 pointer"
                                title={i18n.t(k.PORTFOLIO_EDIT)}
                                onClick={() => {
                                    that.showModal(d);
                                }}
                            >
                                <EditIcon />
                            </Link>
                            <Link
                                to={`/portfolio/${d.id}`}
                                className="pointer"
                                title={i18n.t(k.PORTFOLIO_VIEW)}
                            >
                                <VisibilityIcon />
                            </Link>
                            <Link
                                to="#"
                                className="pointer"
                                title={i18n.t(k.PORTFOLIO_DELETE)}
                                onClick={() => {
                                    that.confirmDelete(d);
                                }}
                            >
                                <DeleteForever />
                            </Link>
                        </span>
                    );
                },
            },
        ];
    }

    hideModal() {
        this.setState({ modal: { ...this.state.modal, visible: false } });
    }

    showModal(d) {
        this.setState({
            modal: { ...this.state.modal, visible: true, portfolio: d },
        });
    }

    toggle() {
        this.setState({
            modal: { ...this.state.modal, visible: !this.state.modal.visible },
        });
    }

    render() {
        let that = this;
        return (
            <div>
                <div className="container-fluid">
                    {/*<PageHeaderComponent*/}
                    {/*title={i18n.t(k.PORTFOLIOS)} />*/}
                </div>
                <CardWrapperComponent
                    header={true}
                    footer={true}
                    card_title={i18n.t(k.PORTFOLIOS_LIST)}
                >
                    <Button
                        theme="info"
                        onClick={() => {
                            that.showModal(null);
                        }}
                    >
                        <AddIcon className="mr-2" />
                        {i18n.t(k.ADD)}
                    </Button>
                    <DataTable
                        autoWidth={true}
                        columns={this.getColumns()}
                        background="#ffffff00"
                        data={this.state.portfolios}
                        noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                        paginationComponentOptions={{
                            rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                            rangeSeparatorText: i18n.t(k.ROWS_OF),
                            noRowsPerPage: false,
                            selectAllRowsItem: false,
                            selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                        }}
                        progressPending={this.state.loading}
                        progressComponent={<Circular />}
                        onRowClicked={(e) =>
                            this.props.history.push(`/portfolio/${e.id}`)
                        }
                    />
                </CardWrapperComponent>
                <PortfolioEditModalComponent
                    visible={this.state.modal.visible}
                    open={this.hideModal}
                    toggle={this.toggle}
                    onRequestClose={this.props.onRequestClose}
                    portfolio={this.state.modal.portfolio}
                    onPortfolioSaved={() => {
                        that.setState({ loading: true });
                        that.hideModal();
                        that.loadPortfolios();
                    }}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PortfolioListComponent);
