import React, { Component } from "react";
import { connect } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import { format } from "d3-format";
import {
    BarChart,
    ChartContainer,
    ChartRow,
    Charts,
    EventMarker,
    Legend,
    LineChart,
    ScatterChart,
    styler,
    YAxis,
} from "react-timeseries-charts";
import { TimeRange, TimeSeries } from "pondjs";
import moment from "moment";
import "moment/locale/fi";
import CircularProgress from "@material-ui/core/CircularProgress";
import CenteredPageLoader from "../ui_utils/CenteredPageLoader";
import CenteredLoader from "../ui_utils/CenteredLoader";
import { Button, FormCheckbox } from "shards-react";
import i18n from "i18next";
import k from "../../i18n/keys";
import DataTable from "react-data-table-component";

const actualLocale = moment.locale(localStorage.getItem("i18nextLng") ?? "en");

const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: "24px" }}>
        <CircularProgress size={75} />
    </div>
);

class CostsTableComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            parsedData: [],
            costsSeries: null,
            timerange: new TimeRange([new Date(1, 1), new Date(1, 1, 1)]),
            total: {},
            options: {},
            mode: "month",
            costsMode: "total",
            chartType: "bars",
            areaData: [],
            columns: [],
            excludeColumns: ["Costs Forecast"],
            allColumns: [],
        };
        this.setContainerRef = (element) => {
            this.container = element;
        };
        this.parseData = this.parseData.bind(this);
        this.getColumns = this.getColumns.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let costs, areasData, options;

        this.setState({ costsSeries: null });
        if (nextProps.costs) {
            costs = nextProps.costs;
        }
        if (nextProps.areasData) {
            areasData = nextProps.areasData;
        }
        if (nextProps.options) {
            options = nextProps.options;
        }
    }

    componentDidMount() {
        this.parseData(this.props.costs);
    }

    parseData(costs) {
        let parsedData = [];
        costs.forEach((d) => {
            for (const key in d.data) {
                if (parseFloat(d.data[key])) {
                    d.data[key] = Math.round(parseFloat(d.data[key]));
                }
            }
            parsedData.push({
                date: d.updated_date,
                ...d.data,
            });
        });
        this.setState({ parsedData: parsedData });
        // console.log("PARSED DATAAAA");
        // console.log(parsedData);
    }

    getColumns() {
        const costsColumns = [];
        for (const item of this.state.parsedData) {
            for (const key in item) {
                if (key === "date") {
                    continue;
                }
                if (costsColumns.indexOf(key) == -1) {
                    costsColumns.push(key);
                }
            }
        }
        let columns = [
            {
                name: <span className="table-header">Date</span>,
                selector: "date",
                style: {
                    "font-size": "10px",
                },
            },
        ];
        for (const column of costsColumns) {
            columns.push({
                name: <span className="table-header">{column}</span>,
                selector: column,
                maxWidth: "150px",
                compact: true,
                style: {
                    "font-size": "10px",
                },
            });
        }
        return columns;
    }

    render() {
        let that = this;
        return (
            <div id="costs_chart" className="bigger-table-container">
                <DataTable
                    noHeader
                    autoWidth={true}
                    maxWidth={"60%"}
                    columns={this.getColumns()}
                    style={{ "font-size": "20px" }}
                    background="#ffffff00"
                    data={this.state.parsedData}
                    noDataComponent={<span>{i18n.t(k.EMPTY_LIST)}</span>}
                    paginationComponentOptions={{
                        rowsPerPageText: i18n.t(k.ROWS_PER_PAGE),
                        rangeSeparatorText: i18n.t(k.ROWS_OF),
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: i18n.t(k.ROWS_ALL),
                    }}
                    progressPending={this.state.loading}
                    progressComponent={<Circular />}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CostsTableComponent);
