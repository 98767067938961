import HonkioAPI from "../../middlewares/HonkioAPI";


const isLoadingOpenTimes = () => ({
  type: "OPENTIMES_LOADING" });


function opentimesSuccess(json) {
  return {
    type: "OPENTIMES_SUCCESS",
    opentimes: json.opentimes };

}

const opentimesError = err => ({
  type: "OPENTIMES_ERROR",
  err });


function getOpenTimes(areaId) {
  // console.log('Sending request to flask api!!!');
  // console.log(areaId);
  return async function (dispatch) {
    dispatch(isLoadingOpenTimes());
    try {
      let json = await HonkioAPI().userShop.userFetch('calendargetopentimes',
      { "area_id": areaId },
      message => {
        opentimesError(message.description);
        return false;
      });
      // console.log("JSON");
      // console.log(json);
      var opentimes = [];
      for (var i = 0; i < 7; i++) {
        var current = json.opentimes[i.toString()];
        var start = current[0].split(':'),
        end = current[1].split(':');
        opentimes.push([
        parseInt(start[0]) * 60 + parseInt(start[1]),
        parseInt(end[0]) * 60 + parseInt(end[1])]);

      }
      return dispatch(opentimesSuccess({ 'opentimes': opentimes }));
    } catch (err) {
      return dispatch(opentimesError(err));
    }
  };

}

export { getOpenTimes };